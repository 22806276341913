// import { array, bool, func } from 'prop-types';
import React, { useEffect } from 'react'
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { publishFirm, requestImageUpload, updateFirmNameInWeaviate } from '../../containers/BecomeInsightGigPartnerPage/BecomeInsightGigPartnerPage.duck';
import { manageDisableScrolling } from '../../ducks/UI.duck';
import { BecomeInsightGigPartnerForm } from '../../forms';
import routeConfiguration, { draftId, draftSlug } from '../../routeConfiguration';
import { ensureUser } from '../../util/data';
import { checkIfUserSubscribed, getCountry, getUserDetails, offlineSubscriptionObject, userSubscriptions } from '../../util/destructorHelpers';
import { createResourceLocatorString } from '../../util/routes';
import { createSlug } from '../../util/urlHelpers';
import Modal from '../Modal/Modal';
import css from './BecomeInsightGigPartnerPanel.module.css';
import { FormattedMessage } from '../../util/reactIntl';
import NamedLink from '../NamedLink/NamedLink';
import { storeFirmDetailsinMongo, storeKeyword, } from '../../util/api';
import { BACKGROUND_IMAGE, INVITE_STATUS_ACTIVE, LOGO } from '../../util/types';
import { types as sdkTypes } from '../../util/sdkLoader';
import { updateMetadata } from '../../containers/FirmPage/FirmPage.duck';
import moment from 'moment';
const { UUID } = sdkTypes;

// import SectionLinkedFirm from './SectionLinkedFirm';
const BecomeInsightPartnerPanel = (props) => {
  const {
    history,
    location,
    currentListing,
    isOwnListing,
    isCsm,
    isClient
  } = props;
  const dispatch = useDispatch();

  const state = useSelector(state => state);

  const { publishFirmInProgress, industryKeywords } = state?.BecomeInsightGigPartnerPage;
  const { currentUser = null } = state?.user;
  const ensuredCurrentUser = ensureUser(currentUser);
  const { title = '', publicData = {}, metadata = {} } = !!currentListing?.id && currentListing?.attributes;
  const { aboutUs, department, locations, firmSize, firmWebsite, industrySector, firmLogoId, firmBackgroundImageId, typeOfFirm, logoShape } = publicData;
  const offlineSubscriptionData = currentUser?.id && (offlineSubscriptionObject(currentUser) ?? {});

  const onlineSubscriptionData = ensuredCurrentUser?.id && userSubscriptions(ensuredCurrentUser)?.find(sub => sub.plan)?.plan;

  const isUserSubscribed = checkIfUserSubscribed(currentUser);
  const subscriptionNickname = offlineSubscriptionData?.planName;
  const subscriptionId = offlineSubscriptionData?.planId;

  const backgroundImage = currentListing?.images?.filter(d => d?.id?.uuid === firmBackgroundImageId)?.at(0)?.attributes?.variants?.['scaled-xlarge']?.url;
  const logo = currentListing?.images?.filter(d => d?.id?.uuid === firmLogoId)?.at(0)?.attributes?.variants?.['scaled-small']?.url;
  const firmData = !!currentListing?.id && metadata?.firmData;
  const fullName = currentUser?.id && getUserDetails(currentUser)?.fullName;
  const profileImage = currentUser?.id && getUserDetails(currentUser)?.profileImage;
  const email = currentUser?.id && getUserDetails(currentUser)?.email;

  const [images, setImages] = useState({});
  useEffect(() => {
    if (firmLogoId || firmBackgroundImageId)
      setImages({
        firmLogoId: firmLogoId ? new UUID(firmLogoId) : null,
        firmBackgroundImageId: firmBackgroundImageId ? new UUID(firmBackgroundImageId) : null,
      })
  }, [firmLogoId, firmBackgroundImageId])

  const initialValues = {
    industrySector: industrySector ?? [],
    firmTitle: title,
    aboutUs,
    department,
    locations,
    firmSize,
    firmWebsite,
    logo,
    backgroundImage,
    typeOfFirm,
    logoShape: logoShape ? logoShape : "circle"
  };

  const handleImageUpload = async (file, fieldName, form) => {
    const { id } = file && await dispatch(requestImageUpload({ id: `${file.name}_${Date.now()}`, file }));
    const imageId = id || null;
    switch (fieldName) {
      case LOGO:
        images.firmLogoId = imageId;
        break;
      case BACKGROUND_IMAGE:
        images.firmBackgroundImageId = imageId;
        break;
      default:
        setImages({
          ...images,
          [fieldName]: imageId,
        });
    }
  };

  const linkedToFirms = !!ensuredCurrentUser?.id && ensuredCurrentUser?.attributes?.profile?.publicData?.linkedToFirms;
  const firmName = Array.isArray(linkedToFirms) && linkedToFirms[0]?.firmName || ensuredCurrentUser?.attributes?.profile?.publicData?.firmListing?.firmTitle;
  const firmId = Array.isArray(linkedToFirms) && linkedToFirms[0]?.firmId || ensuredCurrentUser?.attributes?.profile?.publicData?.firmListing?.firmId;

  const authorObject = {
    userId: currentUser?.id?.uuid,
    email,
    fullName,
    profileImage,
    status: INVITE_STATUS_ACTIVE,
    isAdmin: true,
  }
  // Check if an object with the same userId already exists
  const isDuplicate = firmData?.length && firmData.some((item) => item.userId === authorObject.userId);

  // If it's not a duplicate, add the new object to the firmData array
  if (!isDuplicate && firmData?.length) {
    firmData.push(authorObject);
  }
  const handleSubmit = async (values) => {
    const { firmTitle = '', firmSize, firmWebsite, department, aboutUs,
      modifiedIndustryArray, customIndustrySectorArray, locations, typeOfFirm, logoShape } = values;
    const location = locations?.length && locations.map(item => item.Location.selectedPlace.address);
    const combinedCountries = location && location.map(item => item && getCountry(item));

    const firmDataObj = {
      firmTitle,
      nickname: subscriptionNickname,
      id: subscriptionId,
      backgroundImage: images?.firmBackgroundImageId,
      logo: images?.firmLogoId,
      firmSize,
      firmWebsite: firmWebsite ?? null,
      department,
      aboutUs,
      industrySector: customIndustrySectorArray,
      country: combinedCountries,
      countryKeyword: combinedCountries && combinedCountries.join(','),
      locations,
      typeOfFirm,
      firmId: currentListing?.id ?? null,
      isClient,
      logoShape
    }

    if (modifiedIndustryArray?.length) storeKeyword({ industryKeywords: modifiedIndustryArray });
    const response = await dispatch(publishFirm(firmDataObj));
    const { data: { data = {} } = {} } = response || {};

    if (data.id) {
      const firmDashboardObj = {
        firmDetails: {
          id: data?.id?.uuid,
          firmName: data?.attributes?.title,
        },
        newFirmUserDetails: [{
          id: ensuredCurrentUser?.id?.uuid,
          name: fullName,
          email,
          profilePicture: profileImage,
          joiningDate: moment().valueOf(),
          isAdmin: 1,
        }],
      }
      storeFirmDetailsinMongo({ firmDashboardObj })

      //Update firmlogo in experts listing
      const expertIds = !!firmData?.length ? firmData.filter(user => user.userId).map(user => user.userId) : [];
      if(!isClient && !!expertIds.length){
        dispatch(updateFirmNameInWeaviate({
          firmLogoId: images?.firmLogoId?.uuid, 
          firmId: currentListing?.id, 
          firmTitle: firmTitle,
          expertIds,
          shape: logoShape
        }))
      }
    }

    if (!firmId && data?.id) {
      //Insert admin details in the firm metadata
      const firmData = [{
        userId: currentUser?.id?.uuid,
        email,
        fullName,
        profileImage,
        status: INVITE_STATUS_ACTIVE,
        isAdmin: true,
      }]
      dispatch(updateMetadata(firmData, data?.id));

      const params = {
        id: data?.id?.uuid,
        slug: createSlug(data?.attributes?.title),
      };
      history.push(
        createResourceLocatorString('FirmDashBoardPage', routeConfiguration(), params)
      );
    }
  }

  return (
    <div className={css.organisationForm}>
      <div className={css.organisationTopBox}>
        <p className={css.organisationHeading}>
          <FormattedMessage id="BecomeInsightGigPartnerPanel.organisationHeading" />
        </p>
        {firmId ?
          <NamedLink
            className={css.viewProfile}
            name="EditBecomeInsightGigPartnerPageDetails"
            params={{
              id: firmId ?? draftId,
              slug: firmName ?? draftSlug,
            }}>
            <FormattedMessage id="BecomeInsightGigPartnerPanel.viewOrganisationProfile" />
          </NamedLink> : null}
      </div>
      <div className={css.partnerForm}>
        <BecomeInsightGigPartnerForm
          initialValues={initialValues}
          onSubmit={handleSubmit}
          updateInProgress={publishFirmInProgress}
          currentUser={ensuredCurrentUser}
          industryKeywords={industryKeywords}
          handleImageUpload={handleImageUpload}
          isOwnFirm={isOwnListing}
          firmId={firmId}
          isCsm={isCsm}
          images={images}
          setImages={setImages}
        />
      </div>
    </div>
  )
}

export default BecomeInsightPartnerPanel;