import React, { useEffect, useState } from 'react';
import { bool, func } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import arrayMutators from 'final-form-arrays';
// These relative imports need to point to correct directories
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import {
  Form,
  FieldTextInput,
  PrimaryButton,
  FieldCheckbox,
  IconArrowDown,
  FieldSelect,
  IconCard,
  NamedLink,
} from '../../components';
import { getUserDetails } from '../../util/destructorHelpers';
import { INVITE_STATUS_ACTIVE } from '../../util/types'

// Create this file using EditListingFeaturesForm.module.css
// as a template.
import css from './GoogleMeetForm.module.css';
import { FieldArray } from 'react-final-form-arrays';

export const GoogleMeetForm = props => (
  <FinalForm
    {...props}
    mutators={{
      ...arrayMutators,
    }}
    render={formRenderProps => {
      const {
        className,
        handleSubmit,
        updateError,
        updateInProgress,
        values,
        isOpen,
        form,
        collaborationMetaData,
        currentAuthor,
        currentProvider,
        currentUserEmail,
      } = formRenderProps;

      const [options, setOptions] = useState(() => {
        if (collaborationMetaData && collaborationMetaData.length > 0) {
          return [...collaborationMetaData];
        } else {
          return [];
        }
      });
      const [open, setOpen] = useState(false);

      const clientFullName = getUserDetails(currentAuthor)?.fullName;
      const clientEmail = getUserDetails(currentAuthor)?.email;
      const providerFullName = getUserDetails(currentProvider)?.fullName;
      const providerEmail = getUserDetails(currentProvider)?.email;
      
      useEffect(() => {
        if (clientFullName && clientEmail && providerFullName && providerEmail && Array.isArray(collaborationMetaData)) {
          setOptions([
            ...collaborationMetaData,
            { fullName: clientFullName, email: clientEmail, status: INVITE_STATUS_ACTIVE },
            { fullName: providerFullName, email: providerEmail, status: INVITE_STATUS_ACTIVE }
          ]);
        }
      }, [clientFullName, clientEmail, providerFullName, providerEmail, collaborationMetaData]);
      

      useEffect(() => {
        if (!isOpen) {
          form.reset();
        }
      }, [!isOpen]);
      // const errorMessage = updateError ? (
      //   <p className={css.error}>
      //     <FormattedMessage id="GoogleMeetForm.updateFailed" />
      //   </p>
      // ) : null;

      const classes = classNames(css.root, className);
      const submitInProgress = updateInProgress;
      const submitDisabled =
        submitInProgress ||
        !values.date ||
        !values.endTime ||
        !values.summary ||
        (options?.length && !values?.collaborators?.length);

      const printHourStrings = h => (h > 9 ? `${h}:00` : `0${h}:00`);

      // const HOURS = Array(24).fill();
      // const ALL_START_HOURS = [...HOURS].map((v, i) => printHourStrings(i));
      // const ALL_END_HOURS = [...HOURS].map((v, i) => printHourStrings(i + 1));
      // console.log(ALL_START_HOURS, '&&&  &&& => ALL_START_HOURS');
      const currentHour = new Number(new Date().toTimeString().slice(0, 2));
      const currentMinute = new Date().toTimeString().slice(3, 5);
      const isTodayDateSelected = values.date === new Date().toISOString().slice(0, 10)

      function generateTimes(){
        const times = [];
        let minute = new Number(currentMinute);
        let minutesArray = [0, 15, 30, 45]
        let updatedMinute = isTodayDateSelected ? (minutesArray.find(e => e > minute) || 0) : 0;
        let hour = isTodayDateSelected 
          ? minutesArray.find(e => e > minute) === undefined ? currentHour+1 : currentHour
          : 0;
        
        let count = isTodayDateSelected ? ((24-hour) * 4 - minutesArray.findIndex(e => e === updatedMinute)) : 96 ;
        

        for (let i = 0; i < count; i++) {
          const time = `${hour?.toString().padStart(2, '0')}:${updatedMinute?.toString().padStart(2, '0')}`;
          times.push(time);

          updatedMinute += 15;
          if (updatedMinute === 60) {
            hour += 1;
            updatedMinute = 0;
          }
        }

        return times;
      }
      const times = generateTimes();
      const filteredTimeslots = times.findIndex((st) => st == values.startTime);
      const newTimeSlots = filteredTimeslots >= 0 ? times.slice(filteredTimeslots + 1) : times;

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          <h2 className={css.modalHeadingName}>
            <FormattedMessage id="GoogleMeetForm.scheduleHeading" />
          </h2>
          <FieldTextInput
            className={css.inputBox}
            type="text"
            id="summary"
            name="summary"
            label={'Title'}
            placeholder={'Title'}
          //   validate={nameRequired}
          />
          <FieldTextInput
            className={css.inputBox}
            type="textarea"
            id="description"
            name="description"
            label={'Description'}
            placeholder={'description'}
          //   validate={validators.composeValidators(emailRequired, emailValid)}
          />
          {/* <FieldTextInput
            className={css.inputBox}
            type="text"
            id="location"
            name="location"
            label={'Location'}
            placeholder={'location'}
          /> */}
          <div className={css.rowBox}>
            <FieldTextInput
              className={css.inputBox}
              type="date"
              id="date"
              name="date"
              label={'Starts'}
              placeholder={'startDateTime'}
              min={new Date().toISOString().slice(0, 10)}
            />
            <div className={css.formRow}>
              <div className={css.startField}>
                <span className={css.timeIcon}>
                  <IconCard brand="time" />
                </span>
                <FieldSelect
                  id={"startTime"}
                  name={"startTime"}
                  label={"Start"}
                  selectClassName={css.fieldSelect}
                  disabled={!values.date}
                >
                  <option disabled value="">
                    09:00
                  </option>
                  {times.map((s,i) => (
                      <option value={s} key={i}>
                        {s}
                      </option>
                    )
                  )}
                </FieldSelect>
              </div>
              <div className={css.field}>
                <span className={css.timeIcon}>
                  <IconCard brand="time" />
                </span>
                <FieldSelect
                  id={"endTime"}
                  name={"endTime"}
                  label={"Ends"}
                  selectClassName={css.fieldSelect}
                  disabled={!values.startTime}
                >
                  <option disabled value="">
                    10:00
                  </option>
                  {newTimeSlots.map((s, i) => (
                    <option value={s} key={i}>
                      {s}
                    </option>
                  ))}
                </FieldSelect>
              </div>
            </div>
          </div>
          {options?.length ? (
      
            <div>
              <p className={css.conditionLabel} onClick={() => setOpen(!open)}>
                <FormattedMessage id="GoogleMeetForm.collaborators" />
              </p>
              <div className={css.conditionInputWrapper}>
                <div className={css.conditionDropDown}>
                  {options?.length &&
                    options?.map(option => option.email !== currentUserEmail && option.status === INVITE_STATUS_ACTIVE &&
                      <div key={option?.email}>
                      <FieldCheckbox
                        id={option.email}
                        className={css.dropdownListBox}
                        name={'collaborators'}
                        label={option.fullName}
                        value={option.email}
                        />
                        </div>
                    )}
                </div>
              </div>
            </div>
          ) : null
          }
          <div className={css.bottomWrapper}>
            <p
              className={css.buttonCancel}
            // onClick={onClose}
            >
              <FormattedMessage id="GoogleMeetForm.cancel" />
            </p>
            <PrimaryButton
              type="submit"
              inProgress={submitInProgress}
              disabled={submitDisabled}
            >
              <FormattedMessage id="GoogleMeetForm.createMeeting" />
            </PrimaryButton>
          </div>
        </Form>
      );
    }}
  />
);

GoogleMeetForm.defaultProps = {
  selectedPlace: null,
  updateError: null,
};

GoogleMeetForm.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  updateInProgress: bool.isRequired,
};

export default compose(injectIntl)(GoogleMeetForm);
