import React, { useEffect, useState } from 'react';
import { func } from 'prop-types';
import { compose } from 'redux';
import { Field, Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
// These relative imports need to point to correct directories
import {
  intlShape,
  injectIntl,
  FormattedMessage,
} from '../../util/reactIntl';
import { Form, Button, FieldTextInput } from '../../components';

// as a template.
import css from './AffiliatedFirmForm.module.css';
import * as validators from '../../util/validators';
import { getAffiliatedFirm, getOwnFirm, updateAffiliatedFirm } from '../../util/api';
import { AFFILIATED_FIRM_INVITE_PENDING, AFFILIATED_FIRM_INVITE_RECEIVED, AFFILIATED_FIRM_INVITE_SENT } from '../../util/types';


export const AffiliatedFirmForm = props => {
  return (
    <FinalForm
      {...props}
      mutators={{
        ...arrayMutators,
      }}
      render={formRenderProps => {
        const {
          disabled,
          handleSubmit,
          intl,
          invalid,
          pristine,
          updated,
          formId,
          updateError,
          values,
          form,
          firmId,
          ownFirm,
          setFirmCollection,
          affiliatedListingIds,
          firmCollection
        } = formRenderProps;
        const [updateInProgress, setUpdateInProgress] = useState(false);
        //   firmLabel 
        const firmLabel = intl.formatMessage({
          id: "AffiliatedFirmForm.firmLabel",
        });

        // firm placeholder 
        const firmPlaceholder = intl.formatMessage({
          id: "AffiliatedFirmForm.firmPlaceholder"
        });

        // firmRequiredMessage 
        const firmRequiredMessage = intl.formatMessage({
          id: "AffiliatedFirmForm.firmRequiredMessage"
        });
        const firmRequired = validators.required(firmRequiredMessage);
        const errorMessage = updateError ? (
          <p className={css.error}>
            <FormattedMessage id="AffiliatedFirmForm.updateFailed" />
          </p>
        ) : null;



        const sendRequestButton = intl.formatMessage({
          id: "AffiliatedFirmForm.sendRequestButton",
        });

        const submitReady = updated && pristine;
        const submitInProgress = updateInProgress;

        const AutosuggestList = () => {
          const [inputValue, setInputValue] = useState('');
          const [isDropdownVisible, setDropdownVisible] = useState(false);
          const [filteredSuggestions, setFilteredSuggestions] = useState([]);
          const [firms, setFirms] = useState([]);
          const submitDisabled = !inputValue || ownFirm?.firmName === inputValue || !filteredSuggestions?.length || filteredSuggestions[0]?.firmName === ownFirm?.firmName ;

          // Retrieve the existing affiliatedFirm array from the filteredSuggestions array
          const existingAffiliatedFirm = filteredSuggestions[0]?.affiliatedFirm;

          if (filteredSuggestions?.length) {
            const { firmId, firmName, firmLogo } = ownFirm || {};
            const newAffiliateFirmObject = {
              firmId,
              firmName,
              firmLogo,
              inviteStatus: AFFILIATED_FIRM_INVITE_RECEIVED,
              status: AFFILIATED_FIRM_INVITE_RECEIVED

            }
            // Check if the newAffiliateFirmObject already exists in the array
            const isFirmExisted = existingAffiliatedFirm.some(
              (firm) => firm.firmId === newAffiliateFirmObject.firmId
            );
            if (!isFirmExisted) {
              // If it's not a duplicate, push the newAffiliateFirmObject
              existingAffiliatedFirm.push(newAffiliateFirmObject);
            }

            // Update the data array with the updated affiliatedFirm array
            const updatedData = [...filteredSuggestions];
            updatedData[0].affiliatedFirm = existingAffiliatedFirm;

            // existingAffiliatedFirm.push(newAffiliateFirmObject);
            // // Update the data array with the updated affiliatedFirm array
            // const updatedData = [...filteredSuggestions];
            // updatedData[0].affiliatedFirm = existingAffiliatedFirm;

            const ownAffiliatedFirmObject = {
              firmId: filteredSuggestions[0]?.firmId,
              firmName: filteredSuggestions[0]?.firmName,
              firmLogo: filteredSuggestions[0]?.firmLogo,
              inviteStatus: AFFILIATED_FIRM_INVITE_SENT,
              status: AFFILIATED_FIRM_INVITE_PENDING
            }
            const ownAffiliatedFirm = ownFirm?.affiliatedFirm ?? [];


            // Check if the new affiliated firm already exists in the array
            const isDuplicate = ownAffiliatedFirm?.some(item => item.firmId === filteredSuggestions[0]?.firmId);

            if (!isDuplicate && filteredSuggestions[0].firmName === inputValue) {
              ownAffiliatedFirm.push(ownAffiliatedFirmObject);
            }

            const updatedOwnFirm = { ...ownFirm };

            updatedOwnFirm.affiliatedFirm = ownAffiliatedFirm;
          }
          const filteredSuggestionFirmId = filteredSuggestions?.length && [filteredSuggestions[0]?.firmId, firmId]

          if (filteredSuggestionFirmId && Array.isArray(filteredSuggestionFirmId)) {
            filteredSuggestionFirmId.forEach((id) => {
              if (!affiliatedListingIds?.includes(id)) {
                affiliatedListingIds.push(id);
              }
            });
          }

          useEffect(() => {
            if (Array.isArray(filteredSuggestions)) {
              setFirms([...filteredSuggestions, ownFirm]);
            }
          }, [filteredSuggestions?.length, ownFirm?.firmId]);
          useEffect(() => {
            const delayTimer = setTimeout(() => {
              handleSearchQueryChange(inputValue);
            }, 500); // Adjust the debounce delay as needed

            return () => clearTimeout(delayTimer);
          }, [inputValue]);

          const handleInputChange = (event) => {
            setInputValue(event.target.value);
            setDropdownVisible(true);

          };

          const handleSearchQueryChange = async (value) => {

            try {
              const response = value && await getAffiliatedFirm(value);
              const filteredResponse = response.filter(firm => firm.firmId !== ownFirm?.firmId);
              if (filteredResponse?.length) {
                setFilteredSuggestions(response);
              }
            } catch (error) {
            }
          };

          const handleSuggestionClick = (firmName) => {
            setInputValue(firmName);
            if (inputValue) {
              setDropdownVisible(false);
            }

          };
          const updateFirm = async () => {
            try {
              setUpdateInProgress(true);
              const response = await updateAffiliatedFirm({ firms });
              if (response.status === 'ok') {
                setUpdateInProgress(false);
                setFirmCollection(firms)
              }

            } catch (error) {
              setUpdateInProgress(false);

            }
          }
          return (
            <div className={css.firmWrapper}>
              <div className={css.firmSerachBox}>
                <input
                  className={css.firmInput}
                  type="text"
                  id="firmTitle"
                  name={firmLabel}
                  label={firmLabel}
                  placeholder={firmPlaceholder}
                  value={inputValue}
                  onChange={handleInputChange}
                  autoComplete="off"
                />
                {isDropdownVisible && inputValue ? (
                  <ul className={css.filteredList}>
                    {Array.isArray(filteredSuggestions) && filteredSuggestions?.filter(firm => firm?.firmId !== firmId).map((firm) => (
                      <li
                        className={css.suggestionList}
                        key={firm._id}
                        onClick={() => handleSuggestionClick(firm.firmName)}
                      >
                        {firm.firmName}
                      </li>
                    ))}
                  </ul>
                ) : null}
              </div>
              <Button
                className={css.submitButton}
                type="button"
                inProgress={submitInProgress}
                disabled={submitDisabled}
                ready={submitReady}
                onClick={updateFirm}
              >
                {sendRequestButton}
              </Button>
            </div>
          );
        };


        return (
          <Form className={css.affiliatedFirmForm} onSubmit={handleSubmit} >
            {errorMessage}
            <div className={css.container}>
              <Field name="firmName" component={AutosuggestList} />
            </div>
          </Form>
        );
      }}
    />
  )
}


AffiliatedFirmForm.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  //   updateInProgress: bool.isRequired,
};

export default compose(injectIntl)(AffiliatedFirmForm);