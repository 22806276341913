import React from 'react';
import { func } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
// These relative imports need to point to correct directories
import {
  intlShape,
  injectIntl,
  FormattedMessage,
} from '../../util/reactIntl';
import { Form, Button, FieldTextInput } from '../../components';

// as a template.
import css from './GigAppTransactionForm.module.css';
import * as validators from '../../util/validators';


export const AskAndChatSectionForm = props => {
  return (
    <FinalForm
      {...props}
      mutators={{
        ...arrayMutators,
      }}
      render={formRenderProps => {
        const {
          disabled,
          handleSubmit,
          intl,
          invalid,
          pristine,
          updated,
          formId,
          updateError,
          updateInProgress,
        } = formRenderProps;

        // messageLabel 

        const messageLabel = intl.formatMessage({
          id: "AskAndChatSectionForm.messageLabel",
        });

        // messagePlaceholder  
        const messagePlaceholder = intl.formatMessage({
          id: "AskAndChatSectionForm.messagePlaceholder"
        });
        const requiredMessage = intl.formatMessage({
          id: 'AskAndChatSectionForm.requiredMessage',
        });
        const messageRequired = validators.required(requiredMessage);

        const firmRequiredMessage = intl.formatMessage({
          id: "AskAndChatSectionForm.firmRequiredMessage"
        });
        const firmRequired = validators.required(firmRequiredMessage);
        const errorMessage = updateError ? (
          <p className={css.error}>
            <FormattedMessage id="AskAndChatSectionForm.updateFailed" />
          </p>
        ) : null;



        const sendMessageButton = intl.formatMessage({
          id: "AskAndChatSectionForm.sendMessageButton",
        });

        const submitReady = updated && pristine;
        const submitInProgress = updateInProgress;
        const submitDisabled = invalid || disabled || submitInProgress;

        return (
          <Form className={css.packagesForm} onSubmit={handleSubmit} >
            {errorMessage}
            <div className={css.chatBottomMessageBar}>
              <FieldTextInput
                inputRootClass={css.textarea}
                type="textarea"
                id={formId ? `${formId}.message` : 'message'}
                name="message"
                placeholder={messagePlaceholder}
                validate={messageRequired}
                label={messageLabel}
              />

            </div>
            <Button
              className={css.submitButton}
              type="submit"
              inProgress={submitInProgress}
              disabled={submitDisabled}
              ready={submitReady}
            >
              {sendMessageButton}
            </Button>
          </Form>
        );
      }}
    />
  )
}

AskAndChatSectionForm.defaultProps = {
  selectedPlace: null,
  updateError: null,
};

AskAndChatSectionForm.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  //   updateInProgress: bool.isRequired,
};

export default compose(injectIntl)(AskAndChatSectionForm);