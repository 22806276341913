import { useEffect } from 'react'
import { discourseLogin } from '../../util/api';
import { getUserDetails } from '../../util/destructorHelpers';

const DiscourseCommunityForumPanel = (props) => {
  const {currentUser} = props;
  const currentUserEmail = getUserDetails(currentUser)?.email;
  const currentUserName = getUserDetails(currentUser)?.fullName;
 
  const params = new URLSearchParams(window.location.search);
  const isSigInParams  = !!params.get('sig')

  const getSSOFromURL = () => {
    return params.get('sso');
  };

  const getSigFromURL = () => {
    return params.get('sig');
  };
  const discourseUserObject ={
    currentUserName,
    currentUserEmail,
    external_id: currentUser?.id?.uuid,
    username: currentUser?.attributes.profile.displayName,
  }
  
  const handleSsoLogin = async (sso, sig) => {
    try {
      const response = await discourseLogin({
        ...discourseUserObject,
        sig,
        payload: sso,
      });
      if (response.url) {
        window.location.href = response.url;
      }
    } catch (error) {
      // console.error('Error initiating SSO login:', error.message);
    }
  };
  useEffect(() => {
    if (isSigInParams && currentUserName) {
      const sso = getSSOFromURL();
      const sig = getSigFromURL();
      handleSsoLogin(sso, sig);
    } else {
      // window.location.href = process.env.REACT_APP_DISCOURSE_URL;
    }
  }, [currentUser?.id,isSigInParams,currentUserName]);

  return null;
}

export default DiscourseCommunityForumPanel