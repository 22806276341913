import React from 'react'
import { useState } from 'react';
import css from './OpenAIResearchInstrumentForm.module.css'
import { IconCard, Menu, MenuContent, MenuItem, MenuLabel, Modal, PrimaryButton } from '../../components';
import { FormattedMessage } from 'react-intl';

const REMOVE_POINT_ALERT = 'removeQuestionAlert';

const OptionMenu = (props) => {
    const { list, index, setList, manageDisableScrolling,form } = props;
    const [alertModal, setAlertModal] = useState(false);

    const handleResetPoint = () => {
        let clonedList = [...list]
        clonedList.splice(index, 1, { originalItem: list[index].originalItem, modifiedItem: list[index].originalItem })
        setList(clonedList)
        let updatedList = clonedList.map(item=>item.modifiedItem)
        form.change("updatedList",updatedList)
    }

    const handleAddNewRow = () => {
        let clonedList = [...list]
        clonedList.splice(index + 1, 0, { originalItem: "", modifiedItem: "" })
        setList(clonedList)
        let updatedList = clonedList.map(item=>item.modifiedItem)
        form.change("updatedList",updatedList)
    }
    
    const handleRemovePoint = ()=>{
        let clonedList = [...list]
        clonedList.splice(index, 1)
        setList(clonedList)
        let updatedList = clonedList.map(item=>item.modifiedItem)
        form.change("updatedList",updatedList)
        setAlertModal(false)
    }

    return (
        <div className={css.optionMenuContainer}>
            <Menu contentPlacementOffset={14}>
                <MenuLabel className={css.subMenuLabel}>
                    <span>
                        <IconCard brand="horizontalDots" />
                    </span>
                </MenuLabel>
                <MenuContent rootClassName={css.optionMenu} >
                    <MenuItem key='selectPoint' className={css.firstChild}>
                        <FormattedMessage id='OpenAIResearchInstrumentForm.selectPoint' />
                    </MenuItem>
                    <MenuItem key='resetPoint'>
                        <span onClick={handleResetPoint}>
                            <FormattedMessage id='OpenAIResearchInstrumentForm.resetPoint' />
                        </span>
                    </MenuItem>
                    <MenuItem key='addNewPoint'>
                        <span onClick={handleAddNewRow}>
                            <FormattedMessage id='OpenAIResearchInstrumentForm.addNewPoint' />
                        </span>
                    </MenuItem>
                    <MenuItem key='removeQuestion'>
                        <span onClick={() => setAlertModal(REMOVE_POINT_ALERT)}>
                            <FormattedMessage id='OpenAIResearchInstrumentForm.removePoint' />
                        </span>
                    </MenuItem>
                </MenuContent>
            </Menu>
            <Modal
                id="alertModal"
                contentClassName={css.containerClassName}
                isOpen={!!alertModal}
                onClose={() => setAlertModal(false)}
                onManageDisableScrolling={manageDisableScrolling}
            >
                <div className={css.alertModal}>
                    {alertModal === REMOVE_POINT_ALERT && (
                        <>
                            <h2 style={{ textAlign: 'center' }}><FormattedMessage id='OpenAIContentAnalysisForm.removeQuestionAlert' /></h2>
                            <p><FormattedMessage id='OpenAIContentAnalysisForm.removeQuestionWarning' /></p>
                        </>
                    )}
                    <div className={css.buttonWrapper}>
                        <div className={css.cancelButton} onClick={() => setAlertModal(false)}>
                          Cancel
                        </div>
                        <PrimaryButton
                          type='button'
                          className={css.submitButton}
                          onClick={handleRemovePoint}
                        >
                          Yes
                        </PrimaryButton>
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default OptionMenu