import React, { useCallback, useEffect, useState } from 'react';
import { compose } from 'redux';
import { Form as FinalForm, Field } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
// These relative imports need to point to correct directories
import {
  injectIntl,
  FormattedMessage,
} from '../../util/reactIntl';
import { Form, Button, FieldTextInput, FieldSelect, LocationAutocompleteInputField, IconCard, FieldRadioButton } from '../../components';
// as a template.
import css from './BecomeInsightGigPartnerForm.module.css';
import * as validators from '../../util/validators';
import { isEmailVerified } from '../../util/destructorHelpers';
import { useDispatch, useSelector } from 'react-redux';
import { customFirmSizeRange, typeCompanyArr } from '../../marketplace-custom-config';
import { FieldArray } from 'react-final-form-arrays';
import { findOptionsForSelectFilter } from '../../util/search';
import config from '../../config';
const identity = v => v;
import { BACKGROUND_IMAGE, GENERATE_NOW, LOGO, UPLOAD_NOW } from '../../util/types';
import classNames from 'classnames';
import CustomCategorySelectFieldMaybe from '../EditListingDescriptionForm/CustomCertificateSelectFieldMaybe';
import ImageSourceForm from '../EditListingPhotosForm/ImageSourceForm';
import EditListingOpenAIForm from '../EditListingPhotosForm/EditListingOpenAIForm';

export const BecomeInsightGigPartnerForm = props => {

  return (

    <FinalForm
      {...props}
      mutators={{
        ...arrayMutators,
      }}
      keepDirtyOnReinitialize={true}
      render={formRenderProps => {
        const {
          disabled,
          handleSubmit,
          intl,
          invalid,
          pristine,
          updated,
          formId,
          updateError,
          updateInProgress,
          values,
          form: {
            mutators: { push, pop },
          },
          currentUser,
          form,
          publicDataValues,
          industryKeywords,
          initialValues,
          dirty,
          handleImageUpload,
          isOwnFirm,
          firmId,
          isCsm,
          images,
          setImages,
        } = formRenderProps;
        
        const filterConfig = config.custom.filters;
        const ACCEPT_FILE = "image/png, image/jpeg, image/gif, image/webp, image/bmp, image/vnd.microsoft.icon, image/x-icon";
        const industrySector = findOptionsForSelectFilter('industry', filterConfig);
        const [selectedIndustry, setSelectedIndustry] = useState([]);
        const [showIndustryButton, setShowIndustryButton] = useState(true);
        const [updatedIndustrySector, setUpdatedIndustrySector] = useState([]);
        
        const [logo, setLogo] = useState(null);
        const [backgroundImage, setBackgroundImage] = useState(null);
        const [selectOption, setSelectOption] = useState(UPLOAD_NOW);
        const checkEmailVerified = !!currentUser?.id && isEmailVerified(currentUser);
        // const { images } = useSelector(state => state?.BecomeInsightGigPartnerPage)
        const { generateImageInProgess, generateImageError, generatedImage} = useSelector(state => state?.EditListingPage);

        useEffect(() => {
          setUpdatedIndustrySector([...industrySector, ...industryKeywords])
        }, [industryKeywords, industrySector]);

        const firmLabel = intl.formatMessage({ id: "BecomeInsightGigPartnerForm.firmLabel" });
        const firmPlaceholder = intl.formatMessage({ id: "BecomeInsightGigPartnerForm.firmPlaceholder" });
        const firmRequiredMessage = intl.formatMessage({ id: "BecomeInsightGigPartnerForm.firmRequiredMessage" });
        const firmRequired = validators.required(firmRequiredMessage);

        const aboutUsLabel = intl.formatMessage({ id: "BecomeInsightGigPartnerForm.aboutUsLabel"});
        const aboutUsPlaceholder = intl.formatMessage({ id: "BecomeInsightGigPartnerForm.aboutUsPlaceholder" });
        const aboutUsRequiredMessage = intl.formatMessage({ id: "BecomeInsightGigPartnerForm.aboutUsRequiredMessage" });
        const aboutUsMaxChars = validators.maxLength(aboutUsRequiredMessage, 1000);

        const typeOfFirmLabel = intl.formatMessage({ id: "BecomeInsightGigPartnerForm.typeOfFirmLabel"});
        const typeOfFirmPlaceholder = intl.formatMessage({ id: "BecomeInsightGigPartnerForm.typeOfFirmPlaceholder" });
        const typeOfFirmRequiredMessage = intl.formatMessage({ id: "BecomeInsightGigPartnerForm.typeOfFirmRequiredMessage" });
        const typeOfFirmRequired = validators.required(typeOfFirmRequiredMessage);

        const firmWebsiteLabel = intl.formatMessage({ id: "BecomeInsightGigPartnerForm.firmWebsiteLabel" });
        const firmWebsitePlaceholder = intl.formatMessage({ id: "BecomeInsightGigPartnerForm.firmWebsitePlaceholder" });
        const firmWebsiteRequiredMessage = intl.formatMessage({ id: "BecomeInsightGigPartnerForm.firmWebsiteRequiredMessage" });
        const firmWebsiteRequired = validators.required(firmWebsiteRequiredMessage);

        const departmentLabel = intl.formatMessage({ id: "BecomeInsightGigPartnerForm.departmentLabel" });
        const departmentPlaceholder = intl.formatMessage({ id: "BecomeInsightGigPartnerForm.departmentPlaceholder" });
        const departmentRequiredMessage = intl.formatMessage({ id: "BecomeInsightGigPartnerForm.departmentRequiredMessage" });
        const departmentRequired = validators.required(departmentRequiredMessage);

        const firmSizeLabel = intl.formatMessage({ id: "BecomeInsightGigPartnerForm.firmSizeLabel" });
        const firmSizePlaceholder = intl.formatMessage({ id: "BecomeInsightGigPartnerForm.firmSizePlaceholder" });
        const firmSizeRequiredMessage = intl.formatMessage({ id: "BecomeInsightGigPartnerForm.firmSizeRequiredMessage" });
        const firmSizeRequired = validators.required(firmSizeRequiredMessage);

        const industrySectorPlaceholder = intl.formatMessage({ id: "BecomeInsightGigPartnerForm.industrySectorPlaceholder" });

        // const addressPlaceholderMessage = intl.formatMessage({
        //   id: 'EditListingLocationForm.addressPlaceholder',
        // });

        const locationRequiredMessage = intl.formatMessage({ id: 'BecomeInsightGigPartnerForm.locationRequiredMessage'});

        const locationLabel = intl.formatMessage({ id: 'BecomeInsightGigPartnerForm.locationLabel'});
        const locationPlaceholder = intl.formatMessage({ id: 'BecomeInsightGigPartnerForm.locationPlaceholder'});
        const saveFirmButton = intl.formatMessage({ id: "BecomeInsightGigPartnerForm.saveFirmButton" });

        const errorMessage = updateError ? (
          <p className={css.error}>
            <FormattedMessage id="BecomeInsightGigPartnerForm.updateFailed" />
          </p>
        ) : null;

        const submitReady = updated && pristine;
        const submitInProgress = updateInProgress;
        const submitDisabled = invalid || submitInProgress || !values?.firmTitle || (!isOwnFirm && firmId);

        const handleFileChange = async (event, fieldName, setFile) => {
          const reader = new FileReader();
          const file = event.target.files[0]
          if (file.size < 5000000) handleImageUpload(file, fieldName, form)
          reader.onload = () => {
            setFile(reader.result);
          };
          reader.readAsDataURL(file);
        };

        const handleDeleteClick = (event, fieldName, setFile) => {
          setFile(null);
          form.change(fieldName, null)
          handleImageUpload(false, fieldName, form)
        };

        const handleDrop = (event, fieldName, setFile) => {
          event.preventDefault();
          const reader = new FileReader();
          const file = event.dataTransfer.files[0]

          if (file.size < 500000) {
            handleImageUpload(file, fieldName, form)
          }

          reader.onload = () => {
            setFile(reader.result);
          };
          reader.readAsDataURL(file);
        };

        const handleIndustry = (item) => {
          if (selectedIndustry.includes(item)) {
            const industryArray = [...selectedIndustry];
            const industory = industryArray.findIndex(e => e === item)
            industryArray.splice(industory, 1);
            setSelectedIndustry(prevIndustryArray => {
              const combinedArray = [...prevIndustryArray, ...industryArray];
              const uniqueArray = [...new Set(combinedArray)];
              return uniqueArray;
            });
          } else {
            selectedIndustry.push(item)
          }
          values.industrySector = '';
          setShowIndustryButton(true)
        }

        const removeIndustry = (item) => {
          if (selectedIndustry.includes(item)) {
            const industryArray = [...selectedIndustry];
            const industory = industryArray.findIndex(e => e === item)
            industryArray.splice(industory, 1);
            setSelectedIndustry(industryArray);
          }
        }

        const renderArray = ({ fields }) => (
          <div className={css.inputLocation}>
            {fields.map(name => (
              <div className={css.locationBoxWraper} key={`${name}.Location`}>
                <LocationAutocompleteInputField
                  className={css.locationInput}
                  inputClassName={css.locationAutocompleteInput}
                  iconClassName={css.locationAutocompleteInputIcon}
                  predictionsClassName={css.predictionsRoot}
                  validClassName={css.validLocation}
                  // autoFocus
                  name={`${name}.Location`}
                  // label={locationLabel}
                  placeholder={locationPlaceholder}
                  useDefaultPredictions={false}
                  format={identity}
                  validate={validators.composeValidators(
                    validators.autocompleteSearchRequired(locationRequiredMessage)
                    // autocompletePlaceSelected(addressNotRecognizedMessage)
                  )}
                />
                <span className={css.removeLocation} onClick={() => pop('locations')}>
                  <IconCard brand="cross" />
                </span>
              </div>
            ))}
          </div>
        );
        const uniqueIndustrySet = new Set(updatedIndustrySector.filter(obj => selectedIndustry.includes(obj?.key)));
        const uniqueIndustryValues = [...uniqueIndustrySet];
        const modifiedIndustryArray = uniqueIndustryValues.map(val => ({
          key: val?.key.toLowerCase(),
          label: val?.label
        }));

        const handleChange = useCallback(
          (modifiedIndustryArray, selectedIndustry) => {
            form.change('modifiedIndustryArray', modifiedIndustryArray);
            form.change('customIndustrySectorArray', selectedIndustry);
          },
          [form]
        );

        useEffect(() => {
          handleChange(modifiedIndustryArray, selectedIndustry);
        }, [modifiedIndustryArray?.length, selectedIndustry?.length]);

        useEffect(() => {
          if (initialValues) {
            const { industrySector } = initialValues;
            if (industrySector) setSelectedIndustry(industrySector);
          }
        }, [initialValues]);

        const baseClass = css.packagesForm;
        const shouldDisablePointerEvents = !isOwnFirm && firmId && !isCsm;
        const pointerEventsClass = shouldDisablePointerEvents ? css.pointerEvents : '';

        const logosArray = [
          {key:"Circle",value:"circle"},
          {key:"Square",value:"square"},
          {key:"Rectangle",value:"rectangle"}
        ]
        return (
          <Form className={classNames(baseClass, pointerEventsClass)} onSubmit={(e) => {
            e.preventDefault()
            handleSubmit()
          }}
          >
            {errorMessage}
            <div className={css.packageBox}>
              <FieldTextInput
                className={css.firstNameRoot}
                type="text"
                id={formId ? `${formId}.firmTitle` : 'firmTitle'}
                name="firmTitle"
                label={firmLabel}
                placeholder={firmPlaceholder}
                validate={firmRequired}
              />
              <div className={css.logoUpload}>
                <div className={css.logoLable}>
                  <FormattedMessage id="BecomeInsightGigPartnerForm.logoLabel" />
                </div>
                <div className={css.logoShapeContainer}>
                  <label>What is the shape of your logo?</label>
                  {logosArray.map((item,index)=>(
                    <FieldRadioButton
                      id={item.key}
                      name="logoShape"
                      label={item.key}
                      value={item.value}
                      key={index}
                    />
                  ))}
                </div>
                <div className={css.logoFullUpload}>
                  <div className={css.uploadBoxMain}
                    onDrop={(event) => handleDrop(event, LOGO, setLogo)}
                    onDragOver={(event) => event.preventDefault()}>
                    <label htmlFor="logo" className={css.uploadBox} style={{borderRadius: (values.logoShape === "circle") ? '50%' : '0', width: (values.logoShape==='rectangle') ? '170px' : '115px', opacity : (!values.logoShape) ? '40%' : '100%', cursor: (!values.logoShape) ? 'not-allowed' : 'pointer' }}>
                      <Field name="logoImage" component="input" type="hidden" className={css.browseInput} />
                      {(values?.logo || logo) ? (
                        <img 
                          src={logo || values?.logo} 
                          alt="logo Image" 
                          style={{ width: values.logoShape==='rectangle' ? '170px' : '115px', height: '115px', borderRadius: values.logoShape === "circle" ? '50%' : '0' }} 
                        />
                      ) : (
                        <span className={css.dragDrop}>
                          <FormattedMessage id="BecomeInsightGigPartnerForm.backgroundImageDrag" />
                        </span>
                      )}
                      <input
                        type="file"
                        disabled={!values.logoShape}
                        id={LOGO}
                        accept={ACCEPT_FILE}
                        onChange={(event) => {
                          // event.preventDefault(); // Prevent the default behavior
                          handleFileChange(event, LOGO, setLogo);
                        }}
                        style={{ display: 'none' }}
                      />
                    </label>
                    {!(values?.logo || logo) && (
                      <span className={css.uploadExtension}>
                        <FormattedMessage id="BecomeInsightGigPartnerForm.supportExtension" />
                      </span>
                    )}
                  </div>
                  {(values?.logo || logo) && (
                    <span
                      onClick={(e) => handleDeleteClick(e, LOGO, setLogo)}
                      className={css.replaceButton}
                    >
                      <FormattedMessage id="BecomeInsightGigPartnerForm.replace" />
                    </span>
                  )}
                </div>
              </div>
              <div className={css.backgroundImage}>
                <div className={css.logoLable}>
                  <FormattedMessage id="BecomeInsightGigPartnerForm.backgroundImageLabel" />
                </div>
                <ImageSourceForm
                  initialValues={{ 'imageSource': 'gallery' }}
                  setSelectOption={setSelectOption}
                />
                {selectOption === UPLOAD_NOW ? (
                  <div className={css.mainBackgroundBox}>
                    {(values?.backgroundImage || backgroundImage) ? (
                      <img 
                        src={backgroundImage || values?.backgroundImage} 
                        alt="Background Image" 
                        className={css.backgroundImageUploaded} 
                      />
                    ) : (
                      <label htmlFor="backgroundImage" className={css.logoBox}>
                        <Field name="normalImageType" component="input" type="hidden" />
                        <div
                          onDrop={(event) => handleDrop(event, BACKGROUND_IMAGE, setBackgroundImage)}
                          onDragOver={(event) => event.preventDefault()}
                        >
                          <div className={css.beforeUpload}>
                            <div className={css.uploadIcon}>
                              <IconCard brand="upload" />
                            </div>
                            <div className={css.dragText}><FormattedMessage id="BecomeInsightGigPartnerForm.backgroundImageDrag" /></div>
                            <div className={css.extension}>
                              <FormattedMessage id="BecomeInsightGigPartnerForm.supportExtension" />
                            </div>
                          </div>
                          <input
                            type="file"
                            id={BACKGROUND_IMAGE}
                            accept={ACCEPT_FILE}
                            onChange={(event) => handleFileChange(event, BACKGROUND_IMAGE, setBackgroundImage)}
                            style={{ display: 'none' }}
                          />
                        </div>
                      </label>
                    )}
                    {(values?.backgroundImage || backgroundImage) && (
                      <span className={css.replaceBack}
                        onClick={(e) => handleDeleteClick(e, BACKGROUND_IMAGE, setBackgroundImage)}
                      >
                        <FormattedMessage id="BecomeInsightGigPartnerForm.replace" />
                      </span>
                    )}
                  </div>
                ) : (
                  <>
                    {(values?.backgroundImage || backgroundImage) && (
                      <img 
                        src={backgroundImage || values?.backgroundImage} 
                        alt="Background Image" 
                        className={css.backgroundImageUploaded} 
                      />
                    )}
                    <EditListingOpenAIForm
                      className={css.inputWrapperBox}
                      inputClassName={css.inputBox}
                      initialValues={{ image_url: backgroundImage || values?.backgroundImage, imageText: '' }}
                      onSubmit={values => {}}
                      updateInProgress={generateImageInProgess}
                      handleSubmitInProgess={updateInProgress}
                      generateImageError={generateImageError}
                      isFirmPage={true}
                      setOpenAIImages={(buffer, id) => {
                        setBackgroundImage(buffer)
                        setImages({...images, firmBackgroundImageId: id})
                      }}
                    />
                  </>
                )}
                
              </div>
              <div className={css.topInputsBox}>
                <FieldTextInput
                  className={css.inputBox}
                  id={formId ? `${formId}.aboutUs` : 'aboutUs'}
                  name="aboutUs"
                  type="textarea"
                  // autoComplete="given-name"
                  label={aboutUsLabel}
                  placeholder={aboutUsPlaceholder}
                  validate={aboutUsMaxChars}
                />
                <FieldSelect
                  name="typeOfFirm"
                  id="typeOfFirm"
                  className={css.inputBox}
                  label={typeOfFirmLabel}
                // validate={typeOfFirmRequired}
                >
                  <option disabled value="">
                    {typeOfFirmPlaceholder}
                    {'Select the type of firm...'}
                  </option>
                  {typeCompanyArr && typeCompanyArr.map(c => (
                    <option key={c.key} value={c.label}>
                      {c.label}
                    </option>
                  ))}
                </FieldSelect>
              </div>
              <div className={css.locationInputWrap}>
                <label>{locationLabel}</label>
                <FieldArray name="locations">{renderArray}</FieldArray>
                <div className={css.countryButtons}>
                  <button
                    className={css.addServicesButton}
                    type="button"
                    onClick={() => push('locations', undefined)}
                    disabled={(!isOwnFirm && firmId)}
                  >
                    <FormattedMessage id="BecomeInsightGigPartnerForm.addLocation" />
                  </button>
                </div>
              </div>

              <div className={css.firmWebsite}>
                <FieldTextInput
                  className={css.firstNameRoot}
                  id={formId ? `${formId}.firmWebsite` : 'firmWebsite'}
                  name="firmWebsite"
                  label={firmWebsiteLabel}
                  placeholder={firmWebsitePlaceholder}
                // validate={firmWebsiteRequired}
                />
              </div>
              <div className={css.selectedQueries}>
                <div className={css.serviceHeading}>
                  <FormattedMessage id="BecomeInsightGigPartnerForm.industrySector" />
                </div>
                <div className={css.releventService}>
                  <FormattedMessage id="BecomeInsightGigPartnerForm.pleaseAddRelevantIndustry" />
                </div>
                <div className={css.inputSelectCard}>
                  {Array.isArray(selectedIndustry) && selectedIndustry.map(item =>
                    <span className={css.industrySelected} key={Math.random().toString(36).substring(2, 15)}>
                      {item}
                      <span className={css.closeItem} onClick={() => removeIndustry(item)}>
                        <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M1 1L9.5 9.5" stroke="#E49800" strokeLinecap="round" />
                          <path d="M9.5 1L1 9.5" stroke="#E49800" strokeLinecap="round" />
                        </svg>
                      </span>
                    </span>
                  )}
                </div>
                {showIndustryButton ? (
                  <button onClick={() => setShowIndustryButton(false)} className={css.addServicesButton}>
                    <FormattedMessage id="BecomeInsightGigPartnerForm.addIndustry" />
                  </button>
                ) : (
                  <div className={css.inputSelect}>
                    <FieldTextInput
                      id='industrySector'
                      name='industrySector'
                      type="text"
                      onKeyPress={e => {
                        if (e.key === "Enter") {
                          selectedIndustry.push(e.target.value)
                          updatedIndustrySector.push({
                            key: new String(e.target.value).toLowerCase(),
                            label: e.target.value.toUpperCase()
                          })
                          values.industrySector = '';
                          setShowIndustryButton(true)
                        }
                      }}
                      placeholder={industrySectorPlaceholder}
                      autoComplete="off"
                      autoFocus
                    />
                    <ul >
                      {values.industrySector?.length && !!updatedIndustrySector?.length ?
                        updatedIndustrySector
                          // Filter out duplicates based on the 'key' property
                          .filter((item, index, array) => array.findIndex((i) => i.key === item.key) === index)
                          .map((item) => {
                            if ((Array.isArray(values?.industrySector) && values.industrySector.some(v => item?.key.includes(v))) || item?.key?.includes(values?.industrySector)) {
                              return (
                                <li
                                  key={item.key}
                                  onClick={() => handleIndustry(item.label)}
                                  className={selectedIndustry.includes(item.label) && css.activeSelect}
                                >
                                  {item.label}
                                </li>
                              );
                            }
                          }) : null}
                    </ul>
                  </div>
                )}
              </div>
              <div className={css.departmentName}>
                <FieldTextInput
                  className={css.firstNameRoot}
                  id={formId ? `${formId}.department` : 'department'}
                  name="department"
                  label={departmentLabel}
                  placeholder={departmentPlaceholder}
                // validate={departmentRequired}
                />
              </div>
              <div className={css.employeeSize}>
              
                <CustomCategorySelectFieldMaybe
                  id={"firmSize"}
                  name={"firmSize"}
                  label={firmSizeLabel}
                  selectClassName={css.fieldSelect}
                  categories={customFirmSizeRange}
                  intl={intl}
                  placeholder={firmSizePlaceholder}
                />

                <div className={css.employeeRight}>
                  <FormattedMessage id="BecomeInsightGigPartnerForm.numberOfEmployees" />
                </div>
              </div>

            </div>
            <Button
              className={css.submitButton}
              type="submit"
              inProgress={submitInProgress}
              disabled={submitDisabled || !checkEmailVerified}
              ready={submitReady}
            >
              {saveFirmButton}
            </Button>
          </Form>
        );
      }}
    />
  )
}

export default compose(injectIntl)(BecomeInsightGigPartnerForm);