import React from 'react';
import { func } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
// These relative imports need to point to correct directories
import {
  intlShape,
  injectIntl,
  FormattedMessage,
} from '../../util/reactIntl';
import { Form, PrimaryButton, FieldRadioButton, IconCard } from '../../components';

// as a template.
import css from './BriefShareForm.module.css';
import { CROSS_SIGN } from '../../util/types';


export const BriefShareForm = props => {
  return (
    <FinalForm
      {...props}
      mutators={{
        ...arrayMutators,
      }}
      render={formRenderProps => {
        const {
          disabled,
          handleSubmit,
          intl,
          invalid,
          pristine,
          updated,
          formId,
          updateError,
          updateInProgress,
          firmData,
          values,
          onClose,
          isCsm
        } = formRenderProps;
        const errorMessage = updateError ? (
          <p className={css.error}>
            <FormattedMessage id="BriefShareForm.shareBriefFailed" />
          </p>
        ) : null;

        const shareBriefButtonText = intl.formatMessage({ id: "BriefShareForm.shareBriefButtonText" });
        const shareBriefCrossButtonText = intl.formatMessage({ id: "BriefShareForm.shareBriefCrossButtonText" });

        const submitReady = updated && pristine;
        const submitInProgress = updateInProgress;
        const submitDisabled = invalid || disabled || submitInProgress;

        return (
          <Form className={css.packagesForm} onSubmit={handleSubmit} >
            {errorMessage}
            <div className={css.closeIcon} onClick={() => onClose()}>
              <div>
                {shareBriefCrossButtonText}
                <IconCard brand={CROSS_SIGN} />
              </div>
            </div>
            <h2 className={css.title}>
              <FormattedMessage id='BriefShareForm.title' />
            </h2>
            <label><FormattedMessage id='BriefShareForm.subTitle' /></label>
            <div className={css.packageBox}>
              {firmData?.length && firmData?.map(user => {
                const { userId, fullName } = user || {};
                return (
                  <FieldRadioButton
                    isCsm={isCsm}
                    id={userId}
                    name="firmUserId"
                    label={fullName}
                    value={userId}
                  />
                )
              })}
            </div>
            <div className={css.buttonWrapper}>
              <span className={css.buttonCancel} onClick={onClose}>
                <FormattedMessage id='BriefShareForm.cancelButton' />
              </span>
              <PrimaryButton
                type="submit"
                className={css.buttonSubmit}
                inProgress={submitInProgress}
                disabled={submitDisabled}
                ready={submitReady}
              >
                {shareBriefButtonText}
              </PrimaryButton>
            </div>
          </Form>
        );
      }}
    />
  )
}

BriefShareForm.defaultProps = {
  selectedPlace: null,
  updateError: null,
};

BriefShareForm.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  //   updateInProgress: bool.isRequired,
};

export default compose(injectIntl)(BriefShareForm);