/**
 *  TopbarMobileMenu prints the menu content for authenticated user or
 * shows login actions for those who are not authenticated.
 */
import React from 'react';
import { bool, func, number, oneOfType, string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { ACCOUNT_SETTINGS_PAGES, draftId, draftSlug } from '../../routeConfiguration';
import { propTypes, INBOX_TAB_BRIEFS, USER_ROLE_CSM } from '../../util/types';
import { ensureCurrentUser } from '../../util/data';
import {
  AvatarLarge,
  CsmAvatar,
  ExternalLink,
  InlineTextButton,
  ListingLink,
  NamedLink,
  NotificationBadge,
  OwnListingLink,
  PrimaryButton,
  UserRoleSwitcher,
} from '../../components';
import moment from 'moment';
import { getUserRole } from '../../util/userRole';
// import offerClipArt from '../../assets/offerClipArt.png';
import css from './TopbarMobileMenu.module.css';
import { USER_ROLE_CLIENT, USER_ROLE_PARTNER } from '../../util/types';
import { checkDiscourseUser, freePlan, getFirmId, offlineSubscriptionObject, smallTeamPlan, userSubscriptions } from '../../util/destructorHelpers';
import { ROLE } from '../EditListingBriefWizard/EditListingBriefWizardTab';
const TopbarMobileMenu = props => {
  const {
    isAuthenticated,
    currentPage,
    currentUserHasListings,
    currentUserListing,
    currentUserListingFetched,
    currentUser,
    notificationCount,
    onLogout,
    typePartner,
    showRoleSwitcher,
    location,
  } = props;

  const typeUser = getUserRole(currentUser);
  const createdAt = !!currentUser?.id && currentUser?.attributes?.createdAt;
  const offerEndDate = moment(createdAt).isBefore('2023/03/31');
  const isFreePlanUsed = !!currentUser?.id && freePlan(currentUser);
  const locationFrom = location.state && location.state.from ? location.state.from : null;

  const ensuredCurrentUser = ensureCurrentUser(currentUser);
  const linkedToFirms = !!ensuredCurrentUser?.id && ensuredCurrentUser?.attributes?.profile?.publicData?.linkedToFirms
    || getFirmId(ensuredCurrentUser);

  const firmId = !!ensuredCurrentUser?.id && (Array.isArray(linkedToFirms) && linkedToFirms[0]?.firmId || getFirmId(ensuredCurrentUser));
  const firmName = !!ensuredCurrentUser?.id && (Array.isArray(linkedToFirms) && linkedToFirms[0]?.firmName
    || ensuredCurrentUser?.attributes?.profile?.publicData?.firmListing?.firmTitle);
  const isOwnFirm = !!ensuredCurrentUser?.id && !!ensuredCurrentUser?.attributes?.profile?.publicData?.firmId;
  const userSubscription = !!userSubscriptions(ensuredCurrentUser)?.length;
  const offlineSubscriptionData = currentUser?.id && (offlineSubscriptionObject(currentUser) ?? {});
  const isOfflineSubscriptionPaid = offlineSubscriptionData?.isOfflineSubscriptionPaid;
  const isDiscourseUser = currentUser?.id && !!checkDiscourseUser(ensuredCurrentUser);
  const onlineSubscriptionData = ensuredCurrentUser?.id && userSubscriptions(ensuredCurrentUser)?.find(sub => sub.plan)?.plan;
  const shouldRender = (onlineSubscriptionData?.id || isOfflineSubscriptionPaid || !!linkedToFirms?.length) ?? false;
  const smallTeamPlanUsed = !!currentUser?.id && smallTeamPlan(currentUser);
  const teamSubscription = currentUser?.id && currentUser?.attributes?.profile?.protectedData?.teamSubscription;
  const isClient = typePartner !== USER_ROLE_PARTNER;
  const subscriptionPageName = (userSubscription && !linkedToFirms?.length) || offlineSubscriptionData?.planId || teamSubscription ? "ManageSubscriptionPage" : "StripeSubscriptionPage"
  if (!isAuthenticated) {
    const signup = (
      <NamedLink name="SignupPage" className={css.signupLink}>
        <FormattedMessage id="TopbarMobileMenu.signupLink" />
      </NamedLink>
    );

    const login = (
      <NamedLink name="LoginPage" className={css.loginLink}>
        <FormattedMessage id="TopbarMobileMenu.loginLink" />
      </NamedLink>
    );

    const signupOrLogin = (
      <span className={css.authenticationLinks}>
        <FormattedMessage id="TopbarMobileMenu.signupOrLogin" values={{ login }} />
      </span>
    );

    return (
      <div className={css.root}>
        <div className={css.content}>
          <div className={css.authenticationGreeting}>
            <FormattedMessage
              id="TopbarMobileMenu.unauthorizedGreeting"
              values={{ lineBreak: <br />, signupOrLogin }}
            />
          </div>
        </div>
        {/* <div className={css.footer}>
          <NamedLink className={css.createNewListingLink} name="LoginPage" to={{ state: { from: locationFrom, becomeAnExpert: true } }}>
            <FormattedMessage id="TopbarDesktop.becomeAnExpert" />
          </NamedLink>

          <NamedLink className={css.createNewListingLink} name="LoginPage" to={{ state: { from: locationFrom, findAnExpert: true } }}>
            <FormattedMessage id="TopbarDesktop.findAnExpert" />
          </NamedLink>
        </div> */}
      </div>
    );
  }

  const notificationCountBadge =
    notificationCount > 0 ? (
      <NotificationBadge className={css.notificationBadge} count={notificationCount} />
    ) : null;

  const displayName = currentUser ? currentUser.attributes.profile.firstName : '';
  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
  };

  const createListingLink = isClient ? (
    <NamedLink name="NewListingPage" className={classNames(css.navigationLink, currentPageClass('NewListingPage'))}>
      <FormattedMessage id="TopbarMobileMenu.newListingLinkClient" />
    </NamedLink>
  ) : currentUserListing ? (
    <ListingLink className={classNames(css.navigationLink, currentPageClass('ListingPage'))} listing={currentUserListing}>
      <FormattedMessage id="TopbarMobileMenu.yourListingsLinkExpert" />
    </ListingLink>
  ) : (
    <NamedLink
      name={"NewListingPage"}
      className={classNames(css.navigationLink, currentPageClass("NewListingPage"))}
    >
      <FormattedMessage id={"TopbarMobileMenu.newListingLinkPartner"} />
    </NamedLink>
  )

  const renderFirmMenuItem = (
    <NamedLink
      className={classNames(css.navigationLink, currentPageClass('FirmDashBoardPage'))}
      name="FirmDashBoardPage"
      params={{
        id: firmId ?? draftId,
        slug: firmName ?? draftSlug,
      }}
    >
      <FormattedMessage id="TopbarMobileMenu.firmPage" />
    </NamedLink>
  )
  const renderBecomePartnerMenuItem = (
    <NamedLink
      className={classNames(css.navigationLink, currentPageClass('BecomeInsightGigPartnerPage'))}
      name="BecomeInsightGigPartnerPage"
    >
      {isOwnFirm
        ? <FormattedMessage id="TopbarMobileMenu.firmPage" />
        : <FormattedMessage id="TopbarMobileMenu.becomeInsightGigPartnerPage" />
      }
    </NamedLink>
  )
  const isPartner = typeUser === USER_ROLE_PARTNER
  const hasLinkedFirms = linkedToFirms?.length > 0;
  const hasFirmId = !!firmId;

  let renderFirmItems = null;

  if (isPartner || hasLinkedFirms || hasFirmId || smallTeamPlanUsed) {
    if (hasLinkedFirms || hasFirmId || smallTeamPlanUsed || !hasFirmId) {
      const shouldRenderBecomePartnerMenuItem = (!isPartner && smallTeamPlanUsed && !hasFirmId) || (isPartner && !hasFirmId);
      renderFirmItems = shouldRenderBecomePartnerMenuItem ? renderBecomePartnerMenuItem : renderFirmMenuItem;
    }
  }


  return (
    <div className={css.root}>
      {typeUser === USER_ROLE_CSM ?
        <CsmAvatar user={currentUser} typeUser={typeUser} isMobile={true} /> :
        <AvatarLarge className={css.avatar} user={currentUser} />
      }
      <div className={css.content}>
        <span className={css.greeting}>
          <FormattedMessage id="TopbarMobileMenu.greeting" values={{ displayName }} />
        </span>
        <NamedLink
          className={classNames(css.inbox, currentPageClass('InboxPage'))}
          name="InboxPage"
          params={{ tab: INBOX_TAB_BRIEFS, usertype: typePartner }}
        >
          <FormattedMessage id="TopbarMobileMenu.inboxLink" />
          {notificationCountBadge}
        </NamedLink>
        {createListingLink}
        {isClient && (
          <>
            <NamedLink
              name="ManageListingsPage"
              params={{ listingType: 'client' }}
              className={classNames(css.navigationLink, currentPageClass('ManageListingsPage'))}
            >
              <FormattedMessage id="TopbarMobileMenu.manageBriefs" />
            </NamedLink>
            <NamedLink
              className={classNames(css.navigationLink, currentPageClass('EditJobListingPage'))}
              name="EditJobListingPage"
              params={{ slug: draftSlug, id: draftId, type: 'new', tab: ROLE }}
            >
              <FormattedMessage id="TopbarDesktop.createJobDescription" />
            </NamedLink>
            <NamedLink
              name="ManageListingsPage"
              params={{ listingType: 'job' }}
              className={classNames(css.navigationLink, currentPageClass('ManageListingsPage'))}
            >
              <FormattedMessage id="TopbarDesktop.manageJobDescription" />
            </NamedLink>
          </>
        )}
        {/* {!!(firmId && firmName) && (
          <NamedLink
            className={classNames(css.navigationLink, currentPageClass('IntegrationPage'))}
            name="IntegrationPage"
            params={{
              slug: firmName ?? draftSlug,
              id: firmId ?? draftId,
            }}
          >
            <FormattedMessage id="TopbarDesktop.integrationPage" />
          </NamedLink>
        )} */}
        {(typeUser === USER_ROLE_PARTNER || (typeUser === USER_ROLE_CLIENT && !shouldRender)) ? null :
          <NamedLink
            className={classNames(css.navigationLink, currentPageClass('FavouritesPage'))}
            name="FavouritesPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarMobileMenu.myExperts" />
          </NamedLink>
        }
        {renderFirmItems}
        <ExternalLink
          href={process.env.REACT_APP_DISCOURSE_URL}
          className={classNames(css.navigationLink, currentPageClass('DiscourseCommunityForumPage'))}
          name='DiscourseCommunityForumPage'
        >
          {!isDiscourseUser
            ? <FormattedMessage id="TopbarDesktop.discourseCommunityForumPage" />
            : <FormattedMessage id="TopbarDesktop.discourseCommunityForumPageInsightCafe" />
          }
        </ExternalLink>
        {([USER_ROLE_CLIENT, USER_ROLE_CSM].includes(typePartner) && !shouldRender) ? null :
          <NamedLink
            className={classNames(css.navigationLink, currentPageClass('GigAppsPage'))}
            name="GigAppsPage"
          >
            <FormattedMessage id="TopbarMobileMenu.gigAppsPage" />
          </NamedLink>
        }

        <NamedLink
          className={classNames(css.navigationLink, currentPageClass(subscriptionPageName))}
          name={subscriptionPageName}
        >
          <FormattedMessage id="TopbarMobileMenu.stripeSubscriptionPage" />
        </NamedLink>
        <NamedLink
          className={classNames(css.navigationLink, currentPageClass('ProfileSettingsPage'))}
          name="ProfileSettingsPage"
        >
          <FormattedMessage id="TopbarMobileMenu.accountSettingsLink" />
        </NamedLink>

        <ExternalLink
          className={classNames(css.navigationLink, currentPageClass('FirmPage'))}
          href={`https://docs.insightgig.com/`}
        >
          <FormattedMessage id="TopbarDesktop.insightGigDocumentLink" />
        </ExternalLink>
        <PrimaryButton className={css.logoutButton} onClick={onLogout}>
          <FormattedMessage id="TopbarMobileMenu.logoutLink" />
        </PrimaryButton>
      </div>
      {/* <div className={css.footer}>{listing}</div> */}
    </div>
  );
};

TopbarMobileMenu.defaultProps = {
  currentUser: null,
  notificationCount: 0,
  currentPage: null,
  currentUserListing: null,
  currentUserListingFetched: false,
};

TopbarMobileMenu.propTypes = {
  isAuthenticated: bool.isRequired,
  currentUserHasListings: bool.isRequired,
  currentUserListing: oneOfType([propTypes.listing, propTypes.ownListing]),
  currentUserListingFetched: bool,
  currentUser: propTypes.currentUser,
  currentPage: string,
  notificationCount: number,
  onLogout: func.isRequired,
};

export default TopbarMobileMenu;

