import reverse from 'lodash/reverse';
import sortBy from 'lodash/sortBy';
import { storableError } from '../../util/errors';
import { parse } from '../../util/urlHelpers';
import {
  TRANSITIONS_BRIEF,
  TRANSITIONS,
  TRANSITION_BRIEF_CREATE_PROPOSAL,
  TRANSITION_OFFER_BRIEF,
  TRANSITIONS_FINAL,
  isCustomerTranstion,
  isExpertTranstion,
  TRANSITION_COMPLETE_USING_STRIPE,
  TRANSITION_BRIEF_UPDATE_PROPOSAL,
  TRANSITION_FINAL_PAYMENT_COMPLETED,
  TRANSITIONS_APPLY_JOB,
  TRANSITIONS_JOB_DESCRIPTION,
  TRANSITIONS_GIG_APPS,
} from '../../util/transaction';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import {
  userTypes,
  USER_ROLE_CLIENT,
  USER_ROLE_PARTNER,
  INBOX_TAB_PROPOSALS,
  INBOX_TAB_BRIEFS,
  INBOX_TAB_COLLABORATION,
  TRANSACTION_BRIEF_PROCESS,
  INVITE_STATUS_ACTIVE,
  PROJECT_BASED_KEY,
  SECONDMENT_KEY,
  TRANSACTION_PROPOSAL_PROCESS,
  TRANSACTION_FINAL_PAYMENT_PROCESS,
  TRANSACTION_APPLY_JOB_PROCESS,
  TRANSACTION_JOB_DESCRIPTION_PROCESS,
  TRANSACTION_PREPAYMENT_INDIA_PROCESS,
  TRANSACTION_GIG_APPS_PROCESS,
  GIG_APP_KEY,
} from '../../util/types';
import { types as sdkTypes } from '../../util/sdkLoader';
import moment from 'moment';
import { getTransaction, updateUserProfile } from '../../util/api';
import { fetchCurrentUser } from '../../ducks/user.duck';
import { filteredTransactions } from '../../util/destructorHelpers';

const { UUID } = sdkTypes;

const sortedTransactions = txs =>
  reverse(
    sortBy(txs, tx => {
      return tx.attributes ? tx.attributes.lastTransitionedAt : null;
    })
  );

// ================ Action types ================ //

export const FETCH_ORDERS_OR_SALES_REQUEST = 'app/InboxPage/FETCH_ORDERS_OR_SALES_REQUEST';
export const FETCH_ORDERS_OR_SALES_SUCCESS = 'app/InboxPage/FETCH_ORDERS_OR_SALES_SUCCESS';
export const FETCH_ORDERS_OR_SALES_ERROR = 'app/InboxPage/FETCH_ORDERS_OR_SALES_ERROR';

export const FETCH_TOTAL_TRANSACTION_SUCCESS = 'app/InboxPage/FETCH_TOTAL_TRANSACTION_SUCCESS';

export const FETCH_CURRENT_USER_NOTIFICATIONS_REQUEST =
  'app/InboxPage/FETCH_CURRENT_USER_NOTIFICATIONS_REQUEST';
export const FETCH_CURRENT_USER_NOTIFICATIONS_SUCCESS =
  'app/InboxPage/FETCH_CURRENT_USER_NOTIFICATIONS_SUCCESS';
export const FETCH_CURRENT_USER_NOTIFICATIONS_ERROR =
  'app/InboxPage/FETCH_CURRENT_USER_NOTIFICATIONS_ERROR';

// ================ Reducer ================ //

const entityRefs = entities =>
  entities.map(entity => ({
    id: entity.id,
    type: entity.type,
  }));

const initialState = {
  fetchInProgress: false,
  fetchOrdersOrSalesError: null,
  pagination: null,
  transactionRefs: [],
  currentUserNotificationCount: 0,
  currentUserNotificationCountError: null,
  totalTransactions: 0,
};

export default function checkoutPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_ORDERS_OR_SALES_REQUEST:
      return { ...state, fetchInProgress: true, fetchOrdersOrSalesError: null };
    case FETCH_ORDERS_OR_SALES_SUCCESS: {
      const transactions = sortedTransactions(payload.data.data);
      return {
        ...state,
        fetchInProgress: false,
        transactionRefs: entityRefs(transactions),
        pagination: payload.data.meta,
      };
    }
    case FETCH_ORDERS_OR_SALES_ERROR:
      return { ...state, fetchInProgress: false, fetchOrdersOrSalesError: payload };

    case FETCH_CURRENT_USER_NOTIFICATIONS_REQUEST:
      return { ...state, currentUserNotificationCountError: null };
    case FETCH_CURRENT_USER_NOTIFICATIONS_SUCCESS:
      return { ...state, currentUserNotificationCount: payload.transactions.length };
    case FETCH_CURRENT_USER_NOTIFICATIONS_ERROR:
      return { ...state, currentUserNotificationCountError: payload };
    case FETCH_TOTAL_TRANSACTION_SUCCESS:
      return { ...state, totalTransactions: payload };

    default:
      return state;
  }
}

// ================ Action creators ================ //

const fetchOrdersOrSalesRequest = () => ({ type: FETCH_ORDERS_OR_SALES_REQUEST });
const fetchOrdersOrSalesSuccess = response => ({
  type: FETCH_ORDERS_OR_SALES_SUCCESS,
  payload: response,
});
const fetchOrdersOrSalesError = e => ({
  type: FETCH_ORDERS_OR_SALES_ERROR,
  error: true,
  payload: e,
});

const fetchCurrentUserNotificationsRequest = () => ({
  type: FETCH_CURRENT_USER_NOTIFICATIONS_REQUEST,
});

export const fetchCurrentUserNotificationsSuccess = transactions => ({
  type: FETCH_CURRENT_USER_NOTIFICATIONS_SUCCESS,
  payload: { transactions },
});

const fetchCurrentUserNotificationsError = e => ({
  type: FETCH_CURRENT_USER_NOTIFICATIONS_ERROR,
  error: true,
  payload: e,
});

const fetchTotalTransactionSuccess = total => ({
  type: FETCH_TOTAL_TRANSACTION_SUCCESS,
  payload: total,
});
// ================ Thunks ================ //

const INBOX_PAGE_SIZE = 20;

const getTransitions = (transactions, transition) => {
  const filteredTx =
    transactions &&
    transactions.filter(
      item =>
        item?.attributes?.transitions?.length &&
        item?.attributes?.transitions[0]?.transition === transition
    );
  return filteredTx;
};

const getTransactions = response => {
  try {
    const currentPath =
      typeof window !== 'undefined' &&
      window.location &&
      window.location.pathname &&
      window.location.pathname;
    const transactions = response.data.data;
    if (currentPath === '/inbox/partner/proposals' || currentPath === '/inbox/client/proposals') {
      const txs = getTransitions(transactions, TRANSITION_BRIEF_CREATE_PROPOSAL);
      Object.assign(response.data, { data: txs });
      return response;
    } else if (currentPath === '/inbox/partner/briefs' || currentPath === '/inbox/client/briefs') {
      const txs = getTransitions(transactions, TRANSITION_OFFER_BRIEF);
      Object.assign(response.data, { data: txs });
      return response;
    }
  } catch (e) {
    dispatch(fetchOrdersOrSalesError(e));
  }
};

export const fetchTransaction = txId => async (dispatch, getState, sdk) => {
  dispatch(fetchOrdersOrSalesRequest());
  try {
    const response = await getTransaction({ txId });
    dispatch(fetchOrdersOrSalesSuccess(response));
    return response;
  } catch (error) {
    dispatch(fetchOrdersOrSalesError(storableError(error)));
  }
};

export const updateUserMetadata = params => async (dispatch, getState, sdk) => {
  const { metadata, currentUser } = params;

  try {
    const response = await updateUserProfile({
      data: {
        id: new UUID(currentUser?.id?.uuid),
        metadata: metadata,
      },
    });
    dispatch(fetchCurrentUser());
  } catch (error) {}
};

export const loadData = (params, search) => async (dispatch, getState, sdk) => {
  await dispatch(fetchCurrentUser());
  try {
    const { tab, usertype } = params;
    const tabs = [INBOX_TAB_PROPOSALS, INBOX_TAB_BRIEFS, INBOX_TAB_COLLABORATION];
    const currentUser = getState()?.user?.currentUser ?? {};
    const collaborationTransactionDetails =
      !!currentUser?.id &&
      currentUser?.attributes?.profile?.metadata?.collaborationTransactionDetails;
    const { page = 1, employmentType = '' } = parse(search);

    if (!tabs.find(t => t === tab)) {
      return Promise.reject(new Error(`Invalid tab and userType for InboxPage: ${tab}`));
    }

    if (tab === INBOX_TAB_COLLABORATION) {
      let response = {
        data: {
          data: [],
          included: [],
        },
      };
      if (collaborationTransactionDetails?.length) {
        dispatch(fetchOrdersOrSalesRequest());
        const result = await Promise.all(
          collaborationTransactionDetails?.map(async tx => {
            if (tx.inviteStatus !== INVITE_STATUS_ACTIVE) return;
            const res = await dispatch(fetchTransaction(tx?.id));
            response.data.data.push(res.data.data);
            if (res.data.included?.length) response.data.included.push(...res.data.included);
            return res;
          })
        );
      }

      dispatch(addMarketplaceEntities(response));
      dispatch(fetchOrdersOrSalesSuccess(response));
    } else {
      dispatch(fetchOrdersOrSalesRequest());
      let lastTransitions = [
        ...TRANSITIONS,
        ...TRANSITIONS_BRIEF,
        ...TRANSITIONS_FINAL,
        ...TRANSITIONS_APPLY_JOB,
        ...TRANSITIONS_JOB_DESCRIPTION,
        ...TRANSITIONS_GIG_APPS,
      ];
      let processNames = [
        TRANSACTION_BRIEF_PROCESS,
        TRANSACTION_PROPOSAL_PROCESS,
        TRANSACTION_FINAL_PAYMENT_PROCESS,
        TRANSACTION_APPLY_JOB_PROCESS,
        TRANSACTION_JOB_DESCRIPTION_PROCESS,
        TRANSACTION_PREPAYMENT_INDIA_PROCESS,
        TRANSACTION_GIG_APPS_PROCESS,
      ];

      if (employmentType === PROJECT_BASED_KEY) {
        processNames = [
          TRANSACTION_BRIEF_PROCESS,
          TRANSACTION_PROPOSAL_PROCESS,
          TRANSACTION_FINAL_PAYMENT_PROCESS,
          TRANSACTION_PREPAYMENT_INDIA_PROCESS,
        ];
      } else if (employmentType === SECONDMENT_KEY) {
        processNames = [TRANSACTION_APPLY_JOB_PROCESS, TRANSACTION_JOB_DESCRIPTION_PROCESS];
      } else if(employmentType === GIG_APP_KEY) {
        processNames = [TRANSACTION_GIG_APPS_PROCESS]
      }

      const apiQueryParams = {
        // only: onlyFilter,
        lastTransitions,
        include: [
          'provider',
          'provider.profileImage',
          'customer',
          'customer.profileImage',
          'booking',
          'listing',
        ],
        'fields.transaction': [
          'lastTransition',
          'processName',
          'lastTransitionedAt',
          'transitions',
          'payinTotal',
          'payoutTotal',
          'metadata',
          'protectedData',
        ],
        // 'fields.user': ['profile.displayName', 'profile.abbreviatedName'],
        'fields.image': ['variants.square-small', 'variants.square-small2x'],
        page,
        per_page: INBOX_PAGE_SIZE,
        processNames,
      };
      const response = await sdk.transactions.query(apiQueryParams);
      dispatch(fetchCurrentUserNotificationsRequest());
      const data = response?.data?.data || [];
      const totalTransactionsResponse = await sdk.transactions.query({ processNames });
      dispatch(
        fetchTotalTransactionSuccess(
          filteredTransactions(totalTransactionsResponse?.data?.data)?.length
        )
      );

      const promises = [];
      data.forEach(transaction => {
        const chainedTransactionId = transaction?.attributes?.protectedData?.chainedTransactionId;
        const finalPaymentTxId = transaction?.attributes?.metadata?.finalPaymentTxId;

        if (chainedTransactionId) {
          promises.push(
            sdk.transactions.show({ id: new UUID(chainedTransactionId) }).then(childTransaction => {
              Object.assign(transaction.attributes, {
                // lastTransition: childTransaction?.data?.data?.attributes?.lastTransition,
                transitions: [
                  ...transaction.attributes.transitions,
                  ...childTransaction?.data?.data?.attributes?.transitions,
                ].sort((a, b) => moment(b.createdAt).unix() - moment(a.createdAt).unix()),
              });
            })
          );
        }
        if (finalPaymentTxId) {
          const isFinalPaymentCompleted = transaction?.attributes?.transitions?.findIndex(
            e => e.transition === TRANSITION_FINAL_PAYMENT_COMPLETED
          );

          promises.push(
            sdk.transactions
              .show({ id: new UUID(finalPaymentTxId) })
              .then(finalPaymentTransaction => {
                const lastTransition =
                  finalPaymentTransaction?.data?.data?.attributes?.lastTransition;
                Object.assign(transaction.attributes, {
                  lastTransition:
                    isFinalPaymentCompleted === -1
                      ? lastTransition
                      : transaction.attributes.lastTransition,
                  transitions: [
                    ...transaction.attributes.transitions,
                    ...finalPaymentTransaction?.data?.data?.attributes?.transitions,
                  ].sort((a, b) => moment(b.createdAt).unix() - moment(a.createdAt).unix()),
                });
              })
          );
        }
      });
      Promise.all(promises).then(() => {
        const filteredTxn = filteredTransactions(data);
        const txNotifications = filteredTxn?.filter(txn => {
          const isClient = txn?.relationships?.customer?.data?.id?.uuid === currentUser?.id?.uuid;
          const lastTransition = txn?.id && txn?.attributes?.lastTransition;
          const isBriefTransaction = txn?.attributes?.processName === TRANSACTION_BRIEF_PROCESS;

          if (!txn?.attributes?.metadata?.isTransactionRead) {
            if (isBriefTransaction) {
              return isClient
                ? isCustomerTranstion(lastTransition, isBriefTransaction) && txn
                : isExpertTranstion(lastTransition, isBriefTransaction) && txn;
            } else {
              return isClient
                ? isCustomerTranstion(lastTransition) && txn
                : isExpertTranstion(lastTransition) && txn;
            }
          }
        });
        dispatch(fetchCurrentUserNotificationsSuccess(txNotifications));
        dispatch(addMarketplaceEntities(response));
        dispatch(fetchOrdersOrSalesSuccess(response));
        // dispatch(fetchCurrentUser());
      });
      return response;
    }
  } catch (e) {
    dispatch(fetchOrdersOrSalesError(e));
    dispatch(fetchCurrentUserNotificationsError(storableError(e)));
  }
};
