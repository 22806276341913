import React from 'react'
import css from './OpenAIAppsPanel.module.css'
import { FormattedMessage } from 'react-intl';

const OpenAICategoryPanel = (props) => {
	const { tags, category, appRoute, pricing } = props;

	return (
		<div className={css.categories}>
			{/* Mobile Layout */}
			<div className={css.mobileCategory}>
				<label><FormattedMessage id='OpenAiCategoryPanel.categorylabel' /></label>
				<div className={css.categoriesWrapper}>
					{tags?.map(tag => <span key={tag}>{tag}</span>)}
				</div>
			</div>
			<div className={css.mobileCategory}>
				<label><FormattedMessage id='OpenAiCategoryPanel.researchlabel' /></label>
				<div className={css.categoriesWrapper}>
					{category?.map(cat => <span key={`appRoute-${cat}`} style={{ letterSpacing: '-0.5px' }}>{cat}</span>)}
				</div>
			</div>
			<div className={css.mobileCategory}>
				<label><FormattedMessage id='OpenAiCategoryPanel.pricinglabel' /></label>
				<ul className={css.pricing}>
					{pricing?.map(el => <li key={el}>{el}</li>)}
				</ul>
			</div>
			<div className={css.mobileCategory}>
				<label><FormattedMessage id='OpenAiCategoryPanel.languagelabel' /></label>
				<div className={css.categoriesWrapper}>
					<span>EN</span>
				</div>
			</div>
			{/* Desktop Layout */}
			<section>
				<label><FormattedMessage id='OpenAiCategoryPanel.categorylabel' /></label>
				<div className={css.categoriesWrapper}>
					{tags?.map(tag => <span key={tag} className={css.tabName}>{tag}</span>)}
				</div>
			</section>
			<section>
				<label><FormattedMessage id='OpenAiCategoryPanel.researchlabel' /></label>
				<div className={css.categoriesWrapper}>
					{category?.map(cat => <span key={`appRoute-${cat}`} className={css.catName}>{cat}</span>)}
				</div>
			</section>
			<section>
				<label><FormattedMessage id='OpenAiCategoryPanel.pricinglabel' /></label>
				<div >
					<ul className={css.pricing}>
						{pricing?.map(el => <li key={el}>{el}</li>)}
					</ul>
				</div>
			</section>
			<section>
				<label><FormattedMessage id='OpenAiCategoryPanel.languagelabel' /></label>
				<div className={css.categoriesWrapper}>
					<span className={css.languageBadge}>EN</span>
				</div>
			</section>
		</div>
	)
}

export default OpenAICategoryPanel