import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { fetchCurrentUser } from '../../ducks/user.duck';
import { closeUserListing, fetchUserListings, openUserListing } from '../../util/api';
import { updatedEntities, denormalisedEntities, denormalisedResponseEntities } from '../../util/data';
import { getUserDetails, sharedBriefIds } from '../../util/destructorHelpers';
import { storableError } from '../../util/errors';
import { parse } from '../../util/urlHelpers';
import { getFirmListing } from '../SearchPage/SearchPage.duck';

// Pagination page size might need to be dynamic on responsive page layouts
// Current design has max 3 columns 42 is divisible by 2 and 3
// So, there's enough cards to fill all columns on full pagination pages
const RESULT_PAGE_SIZE = 20;

// ================ Action types ================ //

export const FETCH_LISTINGS_REQUEST = 'app/ManageListingsPage/FETCH_LISTINGS_REQUEST';
export const FETCH_LISTINGS_SUCCESS = 'app/ManageListingsPage/FETCH_LISTINGS_SUCCESS';
export const FETCH_SHARED_LISTINGS_SUCCESS = 'app/ManageListingsPage/FETCH_SHARED_LISTINGS_SUCCESS';
export const FETCH_LISTINGS_ERROR = 'app/ManageListingsPage/FETCH_LISTINGS_ERROR';

export const OPEN_LISTING_REQUEST = 'app/ManageListingsPage/OPEN_LISTING_REQUEST';
export const OPEN_LISTING_SUCCESS = 'app/ManageListingsPage/OPEN_LISTING_SUCCESS';
export const OPEN_LISTING_ERROR = 'app/ManageListingsPage/OPEN_LISTING_ERROR';

export const CLOSE_LISTING_REQUEST = 'app/ManageListingsPage/CLOSE_LISTING_REQUEST';
export const CLOSE_LISTING_SUCCESS = 'app/ManageListingsPage/CLOSE_LISTING_SUCCESS';
export const CLOSE_LISTING_ERROR = 'app/ManageListingsPage/CLOSE_LISTING_ERROR';

export const ADD_OWN_ENTITIES = 'app/ManageListingsPage/ADD_OWN_ENTITIES';

export const FETCH_REVIEWS_REQUEST = 'app/ManageListingsPage/FETCH_REVIEWS_REQUEST';
export const FETCH_REVIEWS_SUCCESS = 'app/ManageListingsPage/FETCH_REVIEWS_SUCCESS';
export const FETCH_REVIEWS_ERROR = 'app/ManageListingsPage/FETCH_REVIEWS_ERROR';

export const FETCH_FIRM_LISTING_SUCCESS = 'app/ManageListingsPage/FETCH_FIRM_LISTING_SUCCESS';

// ================ Reducer ================ //

const initialState = {
  pagination: null,
  queryParams: null,
  queryInProgress: false,
  queryListingsError: null,
  currentPageResultIds: [],
  ownEntities: {},
  openingListing: null,
  openingListingError: null,
  closingListing: null,
  closingListingError: null,
  reviews: [],
  fetchReviewsError: null,
  sharedListings:[],
  firmListing: {},
};

const resultIds = data => data.data.map(l => l.id);

const merge = (state, sdkResponse) => {
  const apiResponse = sdkResponse.data;
  return {
    ...state,
    ownEntities: updatedEntities({ ...state.ownEntities }, apiResponse),
  };
};

const updateListingAttributes = (state, listingEntity) => {
  const oldListing = state.ownEntities.ownListing[listingEntity.id.uuid];
  const updatedListing = { ...oldListing, attributes: listingEntity.attributes };
  const ownListingEntities = {
    ...state.ownEntities.ownListing,
    [listingEntity.id.uuid]: updatedListing,
  };
  return {
    ...state,
    ownEntities: { ...state.ownEntities, ownListing: ownListingEntities },
  };
};

const manageListingsPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_LISTINGS_REQUEST:
      return {
        ...state,
        queryParams: payload.queryParams,
        queryInProgress: true,
        queryListingsError: null,
        currentPageResultIds: [],
      };
    case FETCH_LISTINGS_SUCCESS:
      return {
        ...state,
        currentPageResultIds: resultIds(payload.data),
        pagination: payload.data.meta,
        queryInProgress: false,
      };
    // case FETCH_SHARED_LISTINGS_SUCCESS:
    //   return {
    //     ...state,
    //     sharedListings: payload,
    //     queryInProgress: false,
    //   };
    case FETCH_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, queryInProgress: false, queryListingsError: payload };

    case OPEN_LISTING_REQUEST:
      return {
        ...state,
        openingListing: payload.listingId,
        openingListingError: null,
      };
    case OPEN_LISTING_SUCCESS:
      return {
        ...updateListingAttributes(state, payload.data),
        openingListing: null,
      };
    case OPEN_LISTING_ERROR: {
      // eslint-disable-next-line no-console
      console.error(payload);
      return {
        ...state,
        openingListing: null,
        openingListingError: {
          listingId: state.openingListing,
          error: payload,
        },
      };
    }

    case CLOSE_LISTING_REQUEST:
      return {
        ...state,
        closingListing: payload.listingId,
        closingListingError: null,
      };
    case CLOSE_LISTING_SUCCESS:
      return {
        ...updateListingAttributes(state, payload.data),
        closingListing: null,
      };
    case CLOSE_LISTING_ERROR: {
      // eslint-disable-next-line no-console
      console.error(payload);
      return {
        ...state,
        closingListing: null,
        closingListingError: {
          listingId: state.closingListing,
          error: payload,
        },
      };
    }

    case ADD_OWN_ENTITIES:
      return merge(state, payload);

    case FETCH_REVIEWS_REQUEST:
      return { ...state, fetchReviewsError: null };
    case FETCH_REVIEWS_SUCCESS:
      return { ...state, reviews: payload };
    case FETCH_REVIEWS_ERROR:
      return { ...state, fetchReviewsError: payload };
    case FETCH_FIRM_LISTING_SUCCESS:
      return { ...state, firmListing: payload };
    default:
      return state;
  }
};

export default manageListingsPageReducer;

// ================ Selectors ================ //

/**
 * Get the denormalised own listing entities with the given IDs
 *
 * @param {Object} state the full Redux store
 * @param {Array<UUID>} listingIds listing IDs to select from the store
 */
export const getOwnListingsById = (state, listingIds) => {
  const { ownEntities } = state.ManageListingsPage;
  const resources = listingIds.map(id => ({
    id,
    type: 'ownListing',
  }));
  const throwIfNotFound = false;
  return denormalisedEntities(ownEntities, resources, throwIfNotFound);
};

// ================ Action creators ================ //

// This works the same way as addMarketplaceEntities,
// but we don't want to mix own listings with searched listings
// (own listings data contains different info - e.g. exact location etc.)
export const addOwnEntities = sdkResponse => ({
  type: ADD_OWN_ENTITIES,
  payload: sdkResponse,
});

export const openListingRequest = listingId => ({
  type: OPEN_LISTING_REQUEST,
  payload: { listingId },
});

export const openListingSuccess = response => ({
  type: OPEN_LISTING_SUCCESS,
  payload: response.data,
});

export const openListingError = e => ({
  type: OPEN_LISTING_ERROR,
  error: true,
  payload: e,
});

export const closeListingRequest = listingId => ({
  type: CLOSE_LISTING_REQUEST,
  payload: { listingId },
});

export const closeListingSuccess = response => ({
  type: CLOSE_LISTING_SUCCESS,
  payload: response.data,
});

export const closeListingError = e => ({
  type: CLOSE_LISTING_ERROR,
  error: true,
  payload: e,
});

export const queryListingsRequest = queryParams => ({
  type: FETCH_LISTINGS_REQUEST,
  payload: { queryParams },
});

export const queryListingsSuccess = response => ({
  type: FETCH_LISTINGS_SUCCESS,
  payload: { data: response.data },
});
// export const querySharedListingsSuccess = response => ({
//   type: FETCH_SHARED_LISTINGS_SUCCESS,
//   payload: response,
// });

export const queryListingsError = e => ({
  type: FETCH_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const fetchReviewsRequest = () => ({ type: FETCH_REVIEWS_REQUEST });
export const fetchReviewsSuccess = reviews => ({ type: FETCH_REVIEWS_SUCCESS, payload: reviews });
export const fetchReviewsError = error => ({
  type: FETCH_REVIEWS_ERROR,
  error: true,
  payload: error,
});
export const fetchFirmListingSuccess = listing => ({ type: FETCH_FIRM_LISTING_SUCCESS, payload: listing });

// Throwing error for new (loadData may need that info)
export const queryOwnListings = queryParams => async (dispatch, getState, sdk) => {
  try{
    dispatch(queryListingsRequest(queryParams));

    await  dispatch(fetchCurrentUser())
    const currentUser = getState().user.currentUser;
    const sharedBriefIdsList = currentUser?.id && sharedBriefIds(currentUser);
    const authorId = getUserDetails(currentUser)?.id;
    const linkedToFirmId = currentUser?.id && getUserDetails(currentUser)?.firmId;
    dispatch(fetchFirmListing(linkedToFirmId));
    const { perPage, listingType, ...rest } = queryParams;
    const commonParams = { ...rest, per_page: perPage, 'pub_listingType': listingType };

    const params = { ...commonParams, authorId };
    const sharedListingParams = { ...commonParams, ids:sharedBriefIdsList };
    const response = await fetchUserListings({ params, sharedListingParams });

    if(Array.isArray(response?.data?.data) && response?.data?.data.length){
      for(const data of response?.data?.data){
        data.type = 'ownListing'
      }
    }

    dispatch(addOwnEntities(response));
    dispatch(queryListingsSuccess(response));
    return response;
  } catch (e) {
    dispatch(queryListingsError(e));
  }
};


export const closeListing = listingId => (dispatch, getState, sdk) => {
  dispatch(closeListingRequest(listingId));

  return sdk.ownListings
    .close({ id: listingId }, { expand: true })
    .then(response => {
      dispatch(closeListingSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(closeListingError(storableError(e)));
    });
};

export const closeSharedBriefThroughIsdk = listingId => async (dispatch, getState, sdk) => {
  dispatch(closeListingRequest(listingId));
  try{
    const response = await closeUserListing({ firmId: listingId });
    if(response?.data?.data){
      dispatch(closeListingSuccess(response));
      return response;
    }
  }catch(e){
    dispatch(closeListingError(storableError(e)));
  }
};

export const openListing = listingId => (dispatch, getState, sdk) => {
  dispatch(openListingRequest(listingId));

  return sdk.ownListings
    .open({ id: listingId }, { expand: true })
    .then(response => {
      dispatch(openListingSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(openListingError(storableError(e)));
    });
};

export const openSharedBriefThroughIsdk = listingId => async (dispatch, getState, sdk) => {
  dispatch(openListingRequest(listingId));
  try{
    const response = await openUserListing({ listingId });
    if(response?.data?.data){
      dispatch(openListingSuccess(response));
      return response;
    }
  }catch(e){
    dispatch(openListingError(storableError(e)));
  }
};

export const fetchReviews = userId => (dispatch, getState, sdk) => {
  dispatch(fetchReviewsRequest());

  sdk.reviews.query({
      subjectId: userId,
      state: 'public',
      include: ['author', 'author.profileImage'],
      'fields.image': ['variants.square-small', 'variants.square-small2x'],
  })
  .then(response => {
    const reviews = denormalisedResponseEntities(response);
    dispatch(fetchReviewsSuccess(reviews));
  })
  .catch(e => {
    dispatch(fetchReviewsError(storableError(e)));
  });
};

export const fetchFirmListing = (linkedToFirmId) => async (dispatch, getState, sdk) => {
  try{
    if (linkedToFirmId) {
      const response = await dispatch(getFirmListing(linkedToFirmId));
      dispatch(fetchFirmListingSuccess(response))
    }
  }catch(error){

  }
}

export const loadData = (params, search) => {
  try{
    const {listingType} = params;

    const queryParams = parse(search);
    const page = queryParams.page || 1;
    return queryOwnListings({
      ...queryParams,
      page,
      perPage: RESULT_PAGE_SIZE,
      listingType,
      include: ['images'],
      'fields.image': ['variants.landscape-crop', 'variants.landscape-crop2x'],
      'limit.images': 1,
    });
  }catch(e){
    dispatch(openListingError(e))
    return Promise.all([])
  }
};