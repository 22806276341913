import { ensureTransaction } from './data';
import config from '../config';
import {
  ADD_MILESTONE,
  COMPLETE_MILESTONE,
  DELETE_MILESTONE,
  EDIT_MILESTONE,
  INCOMPLETE_MILESTONE,
} from './types';

/**
 * Transitions
 *
 * These strings must sync with values defined in Flex API,
 * since transaction objects given by API contain info about last transitions.
 * All the actions in API side happen in transitions,
 * so we need to understand what those strings mean.
 */

//BRIEF TRANSITIONS
export const TRANSITION_BRIEF_CREATE_PROPOSAL = 'transition/brief-create-proposal';
export const TRANSITION_BRIEF_UPDATE_PROPOSAL = 'transition/brief-update-proposal';
export const TRANSITION_BRIEF_ACCEPT_PROPOSAL = 'transition/brief-accept-proposal';
export const TRANSITION_BRIEF_EXPIRE_PROPOSAL = 'transition/brief-expire-proposal';
export const TRANSITION_BRIEF_DECLINE_PROPOSAL = 'transition/brief-decline-proposal';
export const TRANSITION_BRIEF_CANCEL_PROPOSAL = 'transition/brief-cancel-proposal';

//  GIG APPS TRANSITIONS
export const TRANSITION_INITIATED_ASSISTED_FLOW = 'transition/initiated-assisted-flow';
export const TRANSITION_REQUESTED_ASSISTED_FLOW = 'transition/requested-assisted-flow';
export const TRANSITION_ACCEPTED_ASSISTED_FLOW = 'transition/accepted-assisted-flow';
export const TRANSITION_REJECTED_ASSISTED_FLOW = 'transition/rejected-assisted-flow';
export const TRANSITION_MILESTONE_CREATED_GIG_APPS = 'transition/gig-apps-milestone-created';
export const TRANSITION_MILESTONE_CREATED_ASSISTED_GIG_APPS = 'transition/gig-apps-milestone-created-assisted';
export const TRANSITION_MILESTONE_CREATED_OPERATOR_GIG_APPS = 'transition/gig-apps-milestone-created-operator';
export const TRANSITION_MILESTONE_UPDATED_GIG_APPS = 'transition/gig-apps-milestone-updated';
export const TRANSITION_MILESTONE_UPDATED_OPERATOR_GIG_APPS = 'transition/gig-apps-milestone-updated-operator';
export const TRANSITION_MILESTONE_DELETED_GIG_APPS = 'transition/gig-apps-milestone-deleted';
export const TRANSITION_MILESTONE_DELETED_OPERATOR_GIG_APPS = 'transition/gig-apps-milestone-deleted-operator';
export const TRANSITION_MILESTONE_COMPLETED_GIG_APPS = 'transition/gig-apps-milestone-complete';
export const TRANSITION_MILESTONE_COMPLETED_OPERATOR_GIG_APPS = 'transition/gig-apps-milestone-complete-operator';
export const TRANSITION_MILESTONE_INCOMPLETED_GIG_APPS = 'transition/gig-apps-milestone-incomplete';
export const TRANSITION_MILESTONE_INCOMPLETED_OPERATOR_GIG_APPS = 'transition/gig-apps-milestone-incomplete-operator';
export const TRANSITION_REQUEST_TRANSACTION_COMPLETE_CUSTOMER_ASSISTED = 'transition/request-transaction-complete-customer-assisted';
export const TRANSITION_REQUEST_TRANSACTION_COMPLETE_CUSTOMER_MILESTONE = 'transition/request-transaction-complete-customer-milestone';
export const TRANSITION_REQUEST_TRANSACTION_COMPLETE_PROVIDER_MILESTONE = 'transition/request-transaction-complete-provider-milestone';
export const TRANSITION_REQUEST_TRANSACTION_COMPLETE_PROVIDER_ASSISTED = 'transition/request-transaction-complete-provider-assisted';
export const TRANSITION_MARK_TRANSACTION_COMPLETE = 'transition/gig-apps-mark-transaction-complete';


//PREPAY TRANSITIONS

export const TRANSITION_PREPAY_REQUEST = 'transition/request-prepay';
export const TRANSITION_PREPAY_EXPIRE = 'transition/expire-prepay';
export const TRANSITION_PREPAY_CONFIRM = 'transition/confirm-prepay';

//PROPOSAL TRANSITIONS

export const TRANSITION_ACCEPT_PROPOSAL_AFTER_BRIEF = 'transition/accept-proposal-after-brief';
export const TRANSITION_CSM_INVITE_ACCEPT_PROPOSAL_OPERATOR =
  'transition/csm-invite-accept-proposal-operator';
export const TRANSITION_PROPOSAL_ACCEPT_AFTER_CSM_INVITE_OPERATOR =
  'transition/proposal-accept-after-csm-invite-operator';
export const TRANSITION_PROPOSAL_DECLINE_AFTER_CSM_INVITE_OPERATOR =
  'transition/proposal-decline-after-csm-invite-operator';
export const TRANSITION_OFFER_BRIEF = 'transition/offer-brief';
export const TRANSITION_DECLINE_BRIEF = 'transition/decline-brief';
export const TRANSITION_CREATE_PROPOSAL = 'transition/create-proposal';
export const TRANSITION_CSM_PRIVATE_BRIEF_INVITE = 'transition/csm-private-brief-invite';
export const TRANSITION_ACCEPT_PROPOSAL = 'transition/accept-proposal';
export const TRANSITION_ACCEPT_PROPOSAL_OPERATOR = 'transition/accept-proposal-operator';
export const TRANSITION_OFFLINE_PAYMENT_REQUESTED = 'transition/offline-payment-requested';
export const TRANSITION_EXPIRE_PROPOSAL = 'transition/expire-proposal';
export const TRANSITION_DECLINE_PROPOSAL = 'transition/decline-proposal';
export const TRANSITION_PROPOSAL_DECLINE_CSM_INVITE_OPERATOR =
  'transition/proposal-decline-csm-invite-operator';
export const TRANSITION_CANCEL_PROPOSAL = 'transition/cancel-proposal';
export const TRANSITION_REQUEST_PAYMENT = 'transition/request-payment';
export const TRANSITION_REQUEST_PREPAYMENT = 'transition/prepayment-completed';
export const TRANSITION_REQUEST_PAYMENT_AFTER_PROPOSAL_ACCEPTED =
  'transition/request-payment-after-proposal-accepted';
export const TRANSITION_CONFIRM_PAYMENT_AFTER_PROPOSAL_ACCEPTED =
  'transition/confirm-payment-after-proposal-accepted';

export const TRANSITION_REQUEST_PREPAYMENT_USING_STRIPE =
  'transition/prepayment-completed-using-stripe';
export const TRANSITION_COMPLETE_USING_STRIPE = 'transition/payment-completed-using-stripe';
export const TRANSITION_REQUEST_PAYMENT_AFTER_PREPAYMENT =
  'transition/request-payment-after-prepayment';
export const TRANSITION_REQUEST_PREPAYMENT_IF_PAYMENT_DUE_UPON_PROJECT_COMPLETION =
  'transition/prepayment-completed-if-payment-due';
export const TRANSITION_REQUEST_FINAL_PAYMENT_IF_PAYMENT_DUE_UPON_PROJECT_COMPLETION =
  'transition/request-final-payment-if-payment-due';
export const TRANSITION_REQUEST_CONFIRM_PAYMENT_IF_PAYMENT_DUE_UPON_PROJECT_COMPLETION =
  'transition/confirm-final-payment-if-payment-due';
export const TRANSITION_FINAL_PAYMENT_COMPLETED_IF_PAYMENT_DUE_UPON_PROJECT_COMPLETION =
  'transition/final-payment-completed-if-payment-due';
export const TRANSITION_REQUEST_MARK_COMPLETE = 'transition/request-mark-complete';
export const TRANSITION_REQUEST_MARK_COMPLETE_OPERATOR =
  'transition/request-mark-complete-operator';
export const TRANSITION_EXPIRE_PAYMENT = 'transition/expire-payment';
export const TRANSITION_REQUEST_PAYMENT_AGAIN = 'transition/request-payment-again';
export const TRANSITION_CONFIRM_PAYMENT = 'transition/confirm-payment';
export const TRANSITION_REQUEST_MARK_COMPLETE_AFTER_PREPAYMENT =
  'transition/request-mark-complete-after-prepayment';
export const TRANSITION_CONFIRM_MARK_COMPLETE_AFTER_PREPAYMENT =
  'transition/confirm-mark-complete-after-prepayment';
export const TRANSITION_COMPLETE = 'transition/payment-completed';
export const TRANSITION_FINAL_PAYMENT_COMPLETED = 'transition/final-payment-completed';
export const TRANSITION_FINAL_PAYMENT_COMPLETED_IF_FULL_AMOUNT_IS_ALREADY_PAID =
  'transition/final-payment-completed-if-full-amount-is-paid';
export const TRANSITION_REVIEW_1_BY_PROVIDER = 'transition/review-1-by-provider';
export const TRANSITION_REVIEW_2_BY_PROVIDER = 'transition/review-2-by-provider';
export const TRANSITION_REVIEW_1_BY_CUSTOMER = 'transition/review-1-by-customer';
export const TRANSITION_REVIEW_2_BY_CUSTOMER = 'transition/review-2-by-customer';
export const TRANSITION_EXPIRE_CUSTOMER_REVIEW_PERIOD = 'transition/expire-customer-review-period';
export const TRANSITION_EXPIRE_PROVIDER_REVIEW_PERIOD = 'transition/expire-provider-review-period';
export const TRANSITION_EXPIRE_REVIEW_PERIOD = 'transition/expire-review-period';

/* MILESTONE TRANSITIONS */
export const TRANSITION_CREATE_MILESTONE = 'transition/milestone-added';
export const TRANSITION_CREATE_MILESTONE_IF_PAYMENT_IS_DUE_UPON_PROJECT_COMPLETION =
  'transition/milestone-added-if-payment-due';
export const TRANSITION_CREATE_MILESTONE_AFTER_PREPAYMENT =
  'transition/milestone-added-after-prepayment';
export const TRANSITION_CREATE_MILESTONE_OPERATOR = 'transition/milestone-added-operator';
export const TRANSITION_CREATE_MILESTONE_OPERATOR_IF_PAYMENT_IS_DUE_UPON_PROJECT_COMPLETION =
  'transition/milestone-added-operator-if-payment-due';
export const TRANSITION_CREATE_MILESTONE_OPERATOR_AFTER_PREPAYMENT =
  'transition/milestone-added-operator-after-prepayment';
export const TRANSITION_UPDATE_MILESTONE = 'transition/milestone-updated';
export const TRANSITION_UPDATE_MILESTONE_AFTER_PREPAYMENT =
  'transition/milestone-updated-after-prepayment';
export const TRANSITION_UPDATE_MILESTONE_AFTER_PREPAYMENT_IF_PAYMENT_IS_DUE_UPON_PROJECT_COMPLETION =
  'transition/milestone-updated-after-prepayment-if-payment-due';
export const TRANSITION_UPDATE_MILESTONE_OPERATOR = 'transition/milestone-updated-operator';
export const TRANSITION_UPDATE_MILESTONE_OPERATOR_AFTER_PREPAYMENT =
  'transition/milestone-updated-operator-after-prepayment';
export const TRANSITION_UPDATE_MILESTONE_OPERATOR_AFTER_PREPAYMENT_IF_PAYMENT_IS_DUE_UPON_PROJECT_COMPLETION =
  'transition/milestone-updated-operator-after-prepayment-if-payment-due';
export const TRANSITION_DELETE_MILESTONE = 'transition/milestone-deleted';
export const TRANSITION_DELETE_MILESTONE_AFTER_PREPAYMENT =
  'transition/milestone-deleted-after-prepayment';
export const TRANSITION_DELETE_MILESTONE_AFTER_PREPAYMENT_IF_PAYMENT_IS_DUE_UPON_PROJECT_COMPLETION =
  'transition/milestone-deleted-after-prepayment-if-payment-due';
export const TRANSITION_DELETE_MILESTONE_OPERATOR = 'transition/milestone-deleted-operator';
export const TRANSITION_DELETE_MILESTONE_OPERATOR_AFTER_PREPAYMENT =
  'transition/milestone-deleted-operator-after-prepayment';
export const TRANSITION_DELETE_MILESTONE_OPERATOR_AFTER_PREPAYMENT_IF_PAYMENT_IS_DUE_UPON_PROJECT_COMPLETION =
  'transition/milestone-deleted-operator-after-prepayment-if-payment-due';
export const TRANSITION_COMPLETE_MILESTONE = 'transition/milestone-completed';
export const TRANSITION_COMPLETE_MILESTONE_OPERATOR = 'transition/milestone-completed-operator';
export const TRANSITION_COMPLETE_MILESTONE_AFTER_PREPAYMENT =
  'transition/milestone-completed-after-prepayment';
export const TRANSITION_COMPLETE_MILESTONE_AFTER_PREPAYMENT_IF_PAYMENT_IS_DUE_UPON_PROJECT_COMPLETION =
  'transition/milestone-completed-after-prepayment-if-payment-due';
export const TRANSITION_COMPLETE_MILESTONE_OPERATOR_AFTER_PREPAYMENT =
  'transition/milestone-completed-operator-after-prepayment';
export const TRANSITION_COMPLETE_MILESTONE_OPERATOR_AFTER_PREPAYMENT_IF_PAYMENT_IS_DUE_UPON_PROJECT_COMPLETION =
  'transition/milestone-completed-operator-after-prepayment-if-payment-due';
export const TRANSITION_UNFINISHED_MILESTONE = 'transition/milestone-unfinished';
export const TRANSITION_UNFINISHED_MILESTONE_OPERATOR = 'transition/milestone-unfinished-operator';
export const TRANSITION_UNFINISHED_MILESTONE_AFTER_PREPAYMENT =
  'transition/milestone-unfinished-after-prepayment';
export const TRANSITION_UNFINISHED_MILESTONE_AFTER_PREPAYMENT_IF_PAYMENT_IS_DUE_UPON_PROJECT_COMPLETION =
  'transition/milestone-unfinished-after-prepayment-if-payment-due';
export const TRANSITION_UNFINISHED_MILESTONE_OPERATOR_AFTER_PREPAYMENT =
  'transition/milestone-unfinished-operator-after-prepayment';
export const TRANSITION_UNFINISHED_MILESTONE_OPERATOR_AFTER_PREPAYMENT_IF_PAYMENT_IS_DUE_UPON_PROJECT_COMPLETION =
  'transition/milestone-unfinished-operator-after-prepayment-if-payment-due';

// APPLY JOB TRANSITIONS
export const TRANSITION_SEND_JOB_APPLICATION = 'transition/send-job-application';
export const TRANSITION_UPDATE_JOB_APPLICATION = 'transition/update-job-application';
export const TRANSITION_ACCEPT_JOB_APPLICATION = 'transition/accept-job-application';
export const TRANSITION_EXPIRE_JOB_APPLICATION = 'transition/expire-job-application';
export const TRANSITION_DECLINE_JOB_APPLICATION = 'transition/decline-job-application';

// JOB DESCRIPTION TRANSITIONS
export const TRANSITION_SEND_JOB_DESCRIPTION = 'transition/send-job-description'
export const TRANSITION_DECLINE_JOB_DESCRIPTION = 'transition/decline-job-description'
export const TRANSITION_EXPIRE_JOB_DESCRIPTION = 'transition/expire-job-description'
export const TRANSITION_DECLINE_APPLICATION_AFTER_JOB = 'transition/decline-application-after-job'
export const TRANSITION_ACCEPT_JOB_APPLICATION_AFTER_JOB = 'transition/accept-job-application-after-job'
export const TRANSITION_REQUEST_FIRST_INTERVIEW = 'transition/request-first-interview'
export const TRANSITION_CONFIRM_FIRST_INTERVIEW = 'transition/confirm-first-interview'
export const TRANSITION_DECLINE_FIRST_INTERVIEW = 'transition/decline-first-interview'
export const TRANSITION_REQUEST_SECOND_INTERVIEW = 'transition/request-second-interview'
export const TRANSITION_CONFIRM_SECOND_INTERVIEW = 'transition/confirm-second-interview'
export const TRANSITION_DECLINE_SECOND_INTERVIEW = 'transition/decline-second-interview'
export const TRANSITION_REQUEST_CASE_STUDY = 'transition/request-case-study'
export const TRANSITION_SUBMIT_CASE_STUDY = 'transition/submitted-case-study'
export const TRANSITION_DECLINE_CASE_STUDY = 'transition/decline-case-study'
export const TRANSITION_SEND_JOB_OFFER = 'transition/send-job-offer'
export const TRANSITION_ACCEPT_JOB_OFFER = 'transition/accept-job-offer'
export const TRANSITION_DECLINE_JOB_OFFER = 'transition/decline-job-offer'
export const TRANSITION_REQUEST_CONTRACT_SIGNATURE = 'transition/request-contract-signature'
export const TRANSITION_COMFIRM_CONTRACT_SIGNATURE = 'transition/confirm-contract-signature'
export const TRANSITION_DECLINE_CONTRACT_SIGNATURE = 'transition/decline-contract-signature'
export const TRANSITION_REQUEST_OFFLINE_PAYMENT = 'transition/request-offline-payment'
export const TRANSITION_REQUEST_OFFLINE_PAYMENT_WITHOUT_CONTRACT = 'transition/request-offline-payment-without-contract'
export const TRANSITION_CONFIRM_OFFLINE_PAYMENT = 'transition/confirm-offline-payment'
export const TRANSITION_REJECT_JOB_INVITE = 'transition/reject-job-invite'
export const TRANSITION_REJECT_JOB_AFTER_APPLICATION = 'transition/reject-job-after-application'

/**
 * Actors
 *
 * There are 4 different actors that might initiate transitions:
 */

// Roles of actors that perform transaction transitions
export const TX_TRANSITION_ACTOR_CUSTOMER = 'customer';
export const TX_TRANSITION_ACTOR_PROVIDER = 'provider';
export const TX_TRANSITION_ACTOR_SYSTEM = 'system';
export const TX_TRANSITION_ACTOR_OPERATOR = 'operator';

export const TX_TRANSITION_ACTORS = [
  TX_TRANSITION_ACTOR_CUSTOMER,
  TX_TRANSITION_ACTOR_PROVIDER,
  TX_TRANSITION_ACTOR_SYSTEM,
  TX_TRANSITION_ACTOR_OPERATOR,
];

const STATE_INITIAL = 'initial';

// GIG APPS STATES
export const STATE_ASSISTED_FLOW_INITIATED = 'state/assisted-flow-initiated';
export const STATE_ASSISTED_FLOW_REQUESTED = 'state/assisted-flow-requested';
export const STATE_ASSISTED_FLOW_ACCEPTED = 'state/assisted-flow-accepted';
export const STATE_ASSISTED_FLOW_REJECTED = 'state/assisted-flow-rejected';
export const STATE_REQUEST_TRANSITION_COMPLETE = 'state/request-transaction-complete';
export const STATE_MARK_TRANSITION_COMPLETE = 'state/mark-transaction-complete';
export const STATE_MILESTONE_CREATED = 'state/milestone-state-created';

//BRIEF STATES
const STATE_BRIEF_PENDING_PROPOSAL = 'brief-pending-proposal';
const STATE_BRIEF_ACCEPTED_PROPOSAL = 'brief-accepted-proposal';
const STATE_BRIEF_EXPIRED_PROPOSAL = 'brief-expired-proposal';
const STATE_BRIEF_DECLINED_PROPOSAL = 'brief-declined-proposal';
const STATE_BRIEF_CANCELLED_PROPOSAL = 'brief-cancelled-proposal';

//PREPAY STATES

const STATE_PREPAY_REQUESTED = 'prepay-requested';
const STATE_PREPAY_EXPIRED = 'prepay-expired';
const STATE_PREPAY_COMPLETED = 'prepay-completed';

//PROPOSAL STATES

const STATE_BRIEF_OFFERED = 'brief-offered';
const STATE_BRIEF_DECLINED = 'brief-declined';
const STATE_PENDING_PROPOSAL = 'pending-proposal';
const STATE_PROPOSAL_ACCEPTED = 'proposal-accepted';
const STATE_CSM_INVITE_ACCEPT_PROPOSAL_OPERATOR = 'csm-invite-accept-proposal-operator';
const STATE_PROPOSAL_EXPIRED = 'proposal-expired';
const STATE_PROPOSAL_DECLINED = 'proposal-declined';
const STATE_PROPOSAL_CANCELLED = 'proposal-cancelled';
const STATE_PENDING_PAYMENT = 'pending-payment';
const STATE_PENDING_PAYMENT_AFTER_PROPOSAL_ACCEPTED = 'pending-payment-after-proposal-accepted';
const STATE_CONFIRM_PAYMENT_AFTER_PROPOSAL_ACCEPTED = 'confirm-payment-after-proposal-accepted';
const STATE_MARK_COMPLETE = 'mark-complete';
const STATE_PREPAYMENT_COMPLETED = 'prepayment-completed';
const STATE_PREPAYMENT_COMPLETED_USING_STRIPE = 'prepayment-completed-using-stripe';
const STATE_REQUEST_MARK_COMPLETED_USING_STRIPE = 'request-mark-completed';
const STATE_CONFIRM_MARK_COMPLETED_USING_STRIPE = 'confirm-mark-completed';
const STATE_PENDING_FINAL_PAYMENT_AFTER_PREPAYMENT_STRIPE =
  'pending-final-payment-after-prepayment';
const CONFIRM_FINAL_PAYYMENT_AFTER_PREPAYMENT_STRIPE = 'confirm-final-payment-after-prepayment';
const STATE_PAYMENT_FAILED = 'payment-failed';
const STATE_INPROGRESS = 'inprogress';
const STATE_COMPLETED = 'completed';
const STATE_REVIEWED = 'reviewed';
const STATE_REVIEWED_BY_CUSTOMER = 'reviewed-by-customer';
const STATE_REVIEWED_BY_PROVIDER = 'reviewed-by-provider';

// APPLY JOB STATES
const STATE_PENDING_JOB_APPLICATION = 'pending-job-application';
const STATE_ACCEPTED_JOB_APPLICATION = 'accepted-job-application';
const STATE_EXPIRED_JOB_APPLICATION = 'expired-job-application';
const STATE_DECLINE_JOB_APPLICATION = 'declined-job-application';

// JOB DESCRIPTION STATES
const STATE_PENDING_JOB_DESCRIPTION = 'pending-job-description';
const STATE_DECLINE_JOB_DESCRIPTION = 'declined-job-description';
const STATE_ACCEPTED_JOB_DESCRIPTION = 'accepted-job-description';
const STATE_DECLINE_INTERVIEW = 'accepted-decline-interview';
const STATE_EXPIRED_JOB_DESCRIPTION = 'expired-job-description';
const STATE_CASE_STUDY_PENDING = 'case-study-pending';
const STATE_DECLINED_CASE_STUDY = 'declined-case-study';
const STATE_JOB_OFFER_PENDING = 'job-offer-pending';
const STATE_ACCEPTED_JOB_OFFER = 'accepted-job-offer';
const STATE_DECLINED_JOB_OFFER = 'declined-job-offer';
const STATE_PENDING_CONTRACT_SIGNATURE = 'pending-contract-signature';
const STATE_CONFIRMED_CONTRACT_SIGNATURE = 'confirmed-contract-signature';
const STATE_DECLINED_CONTRACT_SIGNATURE = 'declined-contract-signature';
const STATE_PENDING_OFFLINE_PAYMENT = 'pending-offline-payment';
const STATE_CONFIRMED_OFFLINE_PAYMENT = 'confirmed-offline-payment';
const STATE_REJECTED_JOB_INVITE = 'rejected-job-invite';

/**
 * Description of transaction process
 *
 * You should keep this in sync with transaction process defined in Marketplace API
 *
 * Note: we don't use yet any state machine library,
 *       but this description format is following Xstate (FSM library)
 *       https://xstate.js.org/docs/
 */

const briefStateDescription = {
  id: config.briefProcessAlias,

  // This 'initial' state is a starting point for new transaction
  initial: STATE_INITIAL,

  // States
  states: {
    [STATE_INITIAL]: {
      on: {
        [TRANSITION_BRIEF_CREATE_PROPOSAL]: STATE_BRIEF_PENDING_PROPOSAL,
        [TRANSITION_BRIEF_UPDATE_PROPOSAL]: STATE_BRIEF_PENDING_PROPOSAL,
      },
    },
    [STATE_BRIEF_OFFERED]: {
      on: {
        [TRANSITION_BRIEF_ACCEPT_PROPOSAL]: STATE_BRIEF_ACCEPTED_PROPOSAL,
        [TRANSITION_BRIEF_EXPIRE_PROPOSAL]: STATE_BRIEF_EXPIRED_PROPOSAL,
        [TRANSITION_BRIEF_DECLINE_PROPOSAL]: STATE_BRIEF_DECLINED_PROPOSAL,
        [TRANSITION_BRIEF_CANCEL_PROPOSAL]: STATE_BRIEF_CANCELLED_PROPOSAL,
      },
    },
    [STATE_BRIEF_EXPIRED_PROPOSAL]: {},
    [STATE_BRIEF_DECLINED_PROPOSAL]: {},
    [STATE_BRIEF_CANCELLED_PROPOSAL]: {},
    [STATE_BRIEF_ACCEPTED_PROPOSAL]: { type: 'final' },
  },
};

const gigApps = {
  id: config.gigAppProcess,

  // This 'initial' state is a starting point for new transaction
  initial: STATE_INITIAL,

  // States
  states: {
    [STATE_INITIAL]: {
      on: {
        [TRANSITION_INITIATED_ASSISTED_FLOW]: STATE_ASSISTED_FLOW_INITIATED,
      },
    },
    [STATE_ASSISTED_FLOW_INITIATED]: {
      on: {
        [TRANSITION_REQUESTED_ASSISTED_FLOW]: STATE_ASSISTED_FLOW_REQUESTED,
        [TRANSITION_REQUEST_TRANSACTION_COMPLETE_CUSTOMER_ASSISTED]: STATE_REQUEST_TRANSITION_COMPLETE,
        [TRANSITION_REQUEST_TRANSACTION_COMPLETE_PROVIDER_ASSISTED]: STATE_REQUEST_TRANSITION_COMPLETE,
        [TRANSITION_MILESTONE_CREATED_ASSISTED_GIG_APPS]: STATE_MILESTONE_CREATED,
      },
    },
    [STATE_MILESTONE_CREATED]: {
      on: {
        [TRANSITION_MILESTONE_CREATED_GIG_APPS]: STATE_MILESTONE_CREATED,
        [TRANSITION_MILESTONE_UPDATED_GIG_APPS]: STATE_MILESTONE_CREATED,
        [TRANSITION_MILESTONE_DELETED_GIG_APPS]: STATE_MILESTONE_CREATED,
        [TRANSITION_MILESTONE_COMPLETED_GIG_APPS]: STATE_MILESTONE_CREATED,
        [TRANSITION_MILESTONE_INCOMPLETED_GIG_APPS]: STATE_MILESTONE_CREATED,
      },
    },
    [STATE_REQUEST_TRANSITION_COMPLETE]: {
      on: {
        [TRANSITION_MARK_TRANSACTION_COMPLETE]: STATE_MARK_TRANSITION_COMPLETE,
      },
    },

    [STATE_MARK_TRANSITION_COMPLETE]: { type: 'final' },
  },
};
const prepayStateDescription = {
  id: config.prepayProcessAlias,

  // This 'initial' state is a starting point for new transaction
  initial: STATE_INITIAL,

  // States
  states: {
    [STATE_INITIAL]: {
      on: {
        [TRANSITION_PREPAY_REQUEST]: STATE_PREPAY_REQUESTED,
      },
    },
    [STATE_PREPAY_REQUESTED]: {
      on: {
        [TRANSITION_PREPAY_EXPIRE]: STATE_PREPAY_EXPIRED,
        [TRANSITION_PREPAY_CONFIRM]: STATE_PREPAY_COMPLETED,
      },
    },
    [STATE_PREPAY_EXPIRED]: {},
    [STATE_PREPAY_COMPLETED]: { type: 'final' },
  },
};

const stateDescription = {
  // id is defined only to support Xstate format.
  // However if you have multiple transaction processes defined,
  // it is best to keep them in sync with transaction process aliases.
  id: config.proposalProcessAlias,

  // This 'initial' state is a starting point for new transaction
  initial: STATE_INITIAL,

  // States
  states: {
    [STATE_INITIAL]: {
      on: {
        [TRANSITION_OFFER_BRIEF]: STATE_BRIEF_OFFERED,
        [TRANSITION_ACCEPT_PROPOSAL_AFTER_BRIEF]: STATE_PROPOSAL_ACCEPTED,
        [TRANSITION_CSM_INVITE_ACCEPT_PROPOSAL_OPERATOR]: STATE_CSM_INVITE_ACCEPT_PROPOSAL_OPERATOR,
        [TRANSITION_BRIEF_UPDATE_PROPOSAL]: STATE_PENDING_PROPOSAL,
      },
    },
    [STATE_CSM_INVITE_ACCEPT_PROPOSAL_OPERATOR]: {
      [TRANSITION_ACCEPT_PROPOSAL_AFTER_BRIEF]: STATE_PROPOSAL_ACCEPTED,
      [TRANSITION_PROPOSAL_ACCEPT_AFTER_CSM_INVITE_OPERATOR]: STATE_PROPOSAL_ACCEPTED,
      [TRANSITION_PROPOSAL_DECLINE_AFTER_CSM_INVITE_OPERATOR]: STATE_PROPOSAL_DECLINED,
    },
    [STATE_BRIEF_OFFERED]: {
      on: {
        [TRANSITION_DECLINE_BRIEF]: STATE_BRIEF_DECLINED,
        [TRANSITION_CREATE_PROPOSAL]: STATE_PENDING_PROPOSAL,
        [TRANSITION_CSM_PRIVATE_BRIEF_INVITE]: STATE_PENDING_PROPOSAL,
        [TRANSITION_BRIEF_UPDATE_PROPOSAL]: STATE_PENDING_PROPOSAL,
      },
    },
    [STATE_BRIEF_DECLINED]: {},
    [STATE_PENDING_PROPOSAL]: {
      on: {
        [TRANSITION_ACCEPT_PROPOSAL]: STATE_PROPOSAL_ACCEPTED,
        [TRANSITION_ACCEPT_PROPOSAL_OPERATOR]: STATE_PROPOSAL_ACCEPTED,
        [TRANSITION_EXPIRE_PROPOSAL]: STATE_PROPOSAL_EXPIRED,
        [TRANSITION_DECLINE_PROPOSAL]: STATE_PROPOSAL_DECLINED,
        [TRANSITION_PROPOSAL_DECLINE_CSM_INVITE_OPERATOR]: STATE_PROPOSAL_DECLINED,
        [TRANSITION_PROPOSAL_DECLINE_AFTER_CSM_INVITE_OPERATOR]: STATE_PROPOSAL_DECLINED,
        [TRANSITION_CANCEL_PROPOSAL]: STATE_PROPOSAL_CANCELLED,
        [TRANSITION_BRIEF_UPDATE_PROPOSAL]: STATE_PROPOSAL_ACCEPTED,
      },
    },
    [STATE_PROPOSAL_EXPIRED]: {},
    [STATE_PROPOSAL_DECLINED]: {},
    [STATE_PROPOSAL_CANCELLED]: {},

    [STATE_PROPOSAL_ACCEPTED]: {
      on: {
        [TRANSITION_PROPOSAL_ACCEPT_AFTER_CSM_INVITE_OPERATOR]: STATE_PROPOSAL_ACCEPTED,
        [TRANSITION_OFFLINE_PAYMENT_REQUESTED]: STATE_PROPOSAL_ACCEPTED,
        [TRANSITION_REQUEST_PREPAYMENT]: STATE_PREPAYMENT_COMPLETED,
        [TRANSITION_REQUEST_PREPAYMENT_IF_PAYMENT_DUE_UPON_PROJECT_COMPLETION]: STATE_PREPAYMENT_COMPLETED,
        [TRANSITION_REQUEST_PREPAYMENT_USING_STRIPE]: STATE_PREPAYMENT_COMPLETED_USING_STRIPE,
        [TRANSITION_REQUEST_PAYMENT_AFTER_PROPOSAL_ACCEPTED]: STATE_PENDING_PAYMENT_AFTER_PROPOSAL_ACCEPTED,
        [TRANSITION_CREATE_MILESTONE]: STATE_PREPAYMENT_COMPLETED,
        [TRANSITION_CREATE_MILESTONE_IF_PAYMENT_IS_DUE_UPON_PROJECT_COMPLETION]: STATE_PREPAYMENT_COMPLETED,
        [TRANSITION_CREATE_MILESTONE_OPERATOR]: STATE_PREPAYMENT_COMPLETED,
        [TRANSITION_CREATE_MILESTONE_OPERATOR_IF_PAYMENT_IS_DUE_UPON_PROJECT_COMPLETION]: STATE_PREPAYMENT_COMPLETED,
        [TRANSITION_UPDATE_MILESTONE]: STATE_PREPAYMENT_COMPLETED,
        [TRANSITION_UPDATE_MILESTONE_OPERATOR]: STATE_PREPAYMENT_COMPLETED,
        [TRANSITION_DELETE_MILESTONE]: STATE_PREPAYMENT_COMPLETED,
        [TRANSITION_DELETE_MILESTONE_OPERATOR]: STATE_PREPAYMENT_COMPLETED,
        [TRANSITION_COMPLETE_MILESTONE]: STATE_PREPAYMENT_COMPLETED,
        [TRANSITION_COMPLETE_MILESTONE_OPERATOR]: STATE_PREPAYMENT_COMPLETED,
        [TRANSITION_UNFINISHED_MILESTONE]: STATE_PREPAYMENT_COMPLETED,
        [TRANSITION_UNFINISHED_MILESTONE_OPERATOR]: STATE_PREPAYMENT_COMPLETED,
      },
    },

    [STATE_PENDING_PAYMENT_AFTER_PROPOSAL_ACCEPTED]: {
      on: {
        // [TRANSITION_REQUEST_PAYMENT_AFTER_PROPOSAL_ACCEPTED]:STATE_PENDING_PAYMENT_AFTER_PROPOSAL_ACCEPTED,
        [TRANSITION_CONFIRM_PAYMENT_AFTER_PROPOSAL_ACCEPTED]: STATE_CONFIRM_PAYMENT_AFTER_PROPOSAL_ACCEPTED,
        // [TRANSITION_CREATE_MILESTONE_AFTER_PREPAYMENT]: STATE_PREPAYMENT_COMPLETED_USING_STRIPE,
        // [TRANSITION_CREATE_MILESTONE_OPERATOR_AFTER_PREPAYMENT]: STATE_PREPAYMENT_COMPLETED_USING_STRIPE,
        // [TRANSITION_UPDATE_MILESTONE_AFTER_PREPAYMENT]: STATE_PREPAYMENT_COMPLETED_USING_STRIPE,
        // [TRANSITION_UPDATE_MILESTONE_OPERATOR_AFTER_PREPAYMENT]: STATE_PREPAYMENT_COMPLETED_USING_STRIPE,
        // [TRANSITION_DELETE_MILESTONE_AFTER_PREPAYMENT]: STATE_PREPAYMENT_COMPLETED_USING_STRIPE,
        // [TRANSITION_DELETE_MILESTONE_OPERATOR_AFTER_PREPAYMENT]: STATE_PREPAYMENT_COMPLETED_USING_STRIPE,
        // [TRANSITION_COMPLETE_MILESTONE_AFTER_PREPAYMENT]: STATE_PREPAYMENT_COMPLETED_USING_STRIPE,
        // [TRANSITION_UNFINISHED_MILESTONE_AFTER_PREPAYMENT]: STATE_PREPAYMENT_COMPLETED_USING_STRIPE,
        // [TRANSITION_COMPLETE_MILESTONE_OPERATOR_AFTER_PREPAYMENT]: STATE_PREPAYMENT_COMPLETED_USING_STRIPE,
        // [TRANSITION_UNFINISHED_MILESTONE_OPERATOR_AFTER_PREPAYMENT]: STATE_PREPAYMENT_COMPLETED_USING_STRIPE,
        // [TRANSITION_REQUEST_PREPAYMENT_USING_STRIPE]: STATE_PREPAYMENT_COMPLETED_USING_STRIPE,
        // [TRANSITION_REQUEST_MARK_COMPLETE_AFTER_PREPAYMENT]: STATE_REQUEST_MARK_COMPLETED_USING_STRIPE,
      },
    },
    [STATE_CONFIRM_PAYMENT_AFTER_PROPOSAL_ACCEPTED]: {
      on: {
        [TRANSITION_REQUEST_PAYMENT_AFTER_PROPOSAL_ACCEPTED]: STATE_PENDING_PAYMENT_AFTER_PROPOSAL_ACCEPTED,
        [TRANSITION_CONFIRM_PAYMENT_AFTER_PROPOSAL_ACCEPTED]: STATE_CONFIRM_PAYMENT_AFTER_PROPOSAL_ACCEPTED,
        [TRANSITION_CREATE_MILESTONE_AFTER_PREPAYMENT]: STATE_PREPAYMENT_COMPLETED_USING_STRIPE,
        [TRANSITION_CREATE_MILESTONE_OPERATOR_AFTER_PREPAYMENT]: STATE_PREPAYMENT_COMPLETED_USING_STRIPE,
        [TRANSITION_UPDATE_MILESTONE_AFTER_PREPAYMENT]: STATE_PREPAYMENT_COMPLETED_USING_STRIPE,
        [TRANSITION_UPDATE_MILESTONE_OPERATOR_AFTER_PREPAYMENT]: STATE_PREPAYMENT_COMPLETED_USING_STRIPE,
        [TRANSITION_DELETE_MILESTONE_AFTER_PREPAYMENT]: STATE_PREPAYMENT_COMPLETED_USING_STRIPE,
        [TRANSITION_DELETE_MILESTONE_AFTER_PREPAYMENT_IF_PAYMENT_IS_DUE_UPON_PROJECT_COMPLETION]: STATE_PREPAYMENT_COMPLETED_USING_STRIPE,
        [TRANSITION_DELETE_MILESTONE_OPERATOR_AFTER_PREPAYMENT]: STATE_PREPAYMENT_COMPLETED_USING_STRIPE,
        [TRANSITION_COMPLETE_MILESTONE_AFTER_PREPAYMENT]: STATE_PREPAYMENT_COMPLETED_USING_STRIPE,
        [TRANSITION_UNFINISHED_MILESTONE_AFTER_PREPAYMENT]: STATE_PREPAYMENT_COMPLETED_USING_STRIPE,
        [TRANSITION_COMPLETE_MILESTONE_OPERATOR_AFTER_PREPAYMENT]: STATE_PREPAYMENT_COMPLETED_USING_STRIPE,
        [TRANSITION_UNFINISHED_MILESTONE_OPERATOR_AFTER_PREPAYMENT]: STATE_PREPAYMENT_COMPLETED_USING_STRIPE,
        [TRANSITION_REQUEST_PREPAYMENT_USING_STRIPE]: STATE_PREPAYMENT_COMPLETED_USING_STRIPE,
        [TRANSITION_REQUEST_MARK_COMPLETE_AFTER_PREPAYMENT]: STATE_REQUEST_MARK_COMPLETED_USING_STRIPE,
        [TRANSITION_CONFIRM_MARK_COMPLETE_AFTER_PREPAYMENT]: STATE_REQUEST_MARK_COMPLETED_USING_STRIPE,
      },
    },
    [STATE_PREPAYMENT_COMPLETED_USING_STRIPE]: {
      on: {
        [TRANSITION_REQUEST_FINAL_PAYMENT_IF_PAYMENT_DUE_UPON_PROJECT_COMPLETION]: [
          STATE_PENDING_FINAL_PAYMENT_AFTER_PREPAYMENT_STRIPE,
        ],
        [TRANSITION_FINAL_PAYMENT_COMPLETED]: STATE_COMPLETED,
        [TRANSITION_FINAL_PAYMENT_COMPLETED_IF_FULL_AMOUNT_IS_ALREADY_PAID]: STATE_COMPLETED,
      },
    },
    [STATE_PENDING_FINAL_PAYMENT_AFTER_PREPAYMENT_STRIPE]: {
      on: {
        [TRANSITION_REQUEST_CONFIRM_PAYMENT_IF_PAYMENT_DUE_UPON_PROJECT_COMPLETION]: CONFIRM_FINAL_PAYYMENT_AFTER_PREPAYMENT_STRIPE,
      },
    },
    [CONFIRM_FINAL_PAYYMENT_AFTER_PREPAYMENT_STRIPE]: {
      on: {
        [TRANSITION_FINAL_PAYMENT_COMPLETED_IF_PAYMENT_DUE_UPON_PROJECT_COMPLETION]: STATE_COMPLETED,
      },
    },
    [STATE_REQUEST_MARK_COMPLETED_USING_STRIPE]: {
      on: {
        [TRANSITION_CONFIRM_MARK_COMPLETE_AFTER_PREPAYMENT]: STATE_PENDING_PAYMENT,
      },
    },
    [STATE_CONFIRM_MARK_COMPLETED_USING_STRIPE]: {
      on: {
        [TRANSITION_REQUEST_MARK_COMPLETE]: STATE_MARK_COMPLETE,
        [TRANSITION_REQUEST_MARK_COMPLETE_OPERATOR]: STATE_MARK_COMPLETE,
        [TRANSITION_COMPLETE_USING_STRIPE]: STATE_REQUEST_MARK_COMPLETED_USING_STRIPE,
        [TRANSITION_FINAL_PAYMENT_COMPLETED]: STATE_COMPLETED,
        [TRANSITION_FINAL_PAYMENT_COMPLETED_IF_FULL_AMOUNT_IS_ALREADY_PAID]: STATE_COMPLETED,
      },
    },
    [STATE_PREPAYMENT_COMPLETED]: {
      on: {
        [TRANSITION_REQUEST_MARK_COMPLETE]: STATE_MARK_COMPLETE,
      },
    },
    [STATE_MARK_COMPLETE]: {
      on: {
        [TRANSITION_COMPLETE]: STATE_COMPLETED,
        [TRANSITION_COMPLETE_USING_STRIPE]: STATE_MARK_COMPLETE,
      },
    },
    [STATE_COMPLETED]: {
      on: {
        [TRANSITION_EXPIRE_REVIEW_PERIOD]: STATE_REVIEWED,
        [TRANSITION_REVIEW_1_BY_CUSTOMER]: STATE_REVIEWED_BY_CUSTOMER,
        [TRANSITION_REVIEW_1_BY_PROVIDER]: STATE_REVIEWED_BY_PROVIDER,
      },
    },

    [STATE_REVIEWED_BY_CUSTOMER]: {
      on: {
        [TRANSITION_REVIEW_2_BY_PROVIDER]: STATE_REVIEWED,
        [TRANSITION_EXPIRE_PROVIDER_REVIEW_PERIOD]: STATE_REVIEWED,
      },
    },
    [STATE_REVIEWED_BY_PROVIDER]: {
      on: {
        [TRANSITION_REVIEW_2_BY_CUSTOMER]: STATE_REVIEWED,
        [TRANSITION_EXPIRE_CUSTOMER_REVIEW_PERIOD]: STATE_REVIEWED,
      },
    },
    [STATE_REVIEWED]: { type: 'final' },
  },
};

const finalProcessStateDescription = {
  id: config.finalPaymentProcess,

  // This 'initial' state is a starting point for new transaction
  initial: STATE_INITIAL,

  // States
  states: {
    [STATE_REQUEST_MARK_COMPLETED_USING_STRIPE]: {
      on: {
        [TRANSITION_REQUEST_MARK_COMPLETE_AFTER_PREPAYMENT]: STATE_REQUEST_MARK_COMPLETED_USING_STRIPE,
        [TRANSITION_CONFIRM_MARK_COMPLETE_AFTER_PREPAYMENT]: STATE_CONFIRM_MARK_COMPLETED_USING_STRIPE,
      },
    },
    [STATE_CONFIRM_MARK_COMPLETED_USING_STRIPE]: {
      on: {
        [TRANSITION_COMPLETE_USING_STRIPE]: STATE_COMPLETED,
      },
    },
    [STATE_COMPLETED]: { type: 'final' },
  },
};

const applyJobStateDescription = {
  id: config.applyJobProcess,
  initial: STATE_INITIAL,
  states: {
    [STATE_INITIAL]: {
      on: {
        [TRANSITION_SEND_JOB_APPLICATION]: STATE_PENDING_JOB_APPLICATION,
      },
    },
    [STATE_PENDING_JOB_APPLICATION]: {
      on: {
        [TRANSITION_UPDATE_JOB_APPLICATION]: STATE_PENDING_JOB_APPLICATION,
        [TRANSITION_ACCEPT_JOB_APPLICATION]: STATE_PENDING_JOB_APPLICATION,
        [TRANSITION_ACCEPT_JOB_APPLICATION_AFTER_JOB]: STATE_PENDING_JOB_APPLICATION,
        [TRANSITION_EXPIRE_JOB_APPLICATION]: STATE_EXPIRED_JOB_APPLICATION,
        [TRANSITION_DECLINE_JOB_APPLICATION]: STATE_DECLINE_JOB_APPLICATION,
      },
    },
    [STATE_EXPIRED_JOB_APPLICATION]: {},
    [STATE_DECLINE_JOB_APPLICATION]: {},
    [STATE_ACCEPTED_JOB_APPLICATION]: { type: 'final' },
  },
};

const jobDescriptionStateDescription = {
  id: config.jobDescriptionProcess,
  initial: STATE_INITIAL,
  states: {
    [STATE_INITIAL]: {
      on: {
        [TRANSITION_SEND_JOB_DESCRIPTION]: STATE_PENDING_JOB_DESCRIPTION,
      },
    },
    [STATE_PENDING_JOB_DESCRIPTION]: {
      on: {
        [TRANSITION_DECLINE_JOB_DESCRIPTION]: STATE_DECLINE_JOB_DESCRIPTION,
        [TRANSITION_EXPIRE_JOB_DESCRIPTION]: STATE_EXPIRED_JOB_DESCRIPTION,
        [TRANSITION_SEND_JOB_APPLICATION]: STATE_ACCEPTED_JOB_DESCRIPTION,
      },
    },
    [STATE_DECLINE_JOB_DESCRIPTION]: {},
    [STATE_EXPIRED_JOB_DESCRIPTION]: {},
    [STATE_ACCEPTED_JOB_DESCRIPTION]: {
      on: {
        [TRANSITION_UPDATE_JOB_APPLICATION]: STATE_ACCEPTED_JOB_DESCRIPTION,
        [TRANSITION_EXPIRE_JOB_APPLICATION]: STATE_EXPIRED_JOB_APPLICATION,
        [TRANSITION_DECLINE_APPLICATION_AFTER_JOB]: STATE_DECLINE_JOB_APPLICATION,
        [TRANSITION_ACCEPT_JOB_APPLICATION]: STATE_ACCEPTED_JOB_APPLICATION,
      },
    },
    [STATE_ACCEPTED_JOB_APPLICATION]: {
      on: {
        [TRANSITION_REJECT_JOB_INVITE]: STATE_REJECTED_JOB_INVITE,
        [TRANSITION_REJECT_JOB_AFTER_APPLICATION]: STATE_REJECTED_JOB_INVITE,
        [TRANSITION_REQUEST_FIRST_INTERVIEW]: STATE_ACCEPTED_JOB_APPLICATION,
        [TRANSITION_CONFIRM_FIRST_INTERVIEW]: STATE_ACCEPTED_JOB_APPLICATION,
        [TRANSITION_DECLINE_FIRST_INTERVIEW]: STATE_DECLINE_INTERVIEW,
        [TRANSITION_REQUEST_SECOND_INTERVIEW]: STATE_ACCEPTED_JOB_APPLICATION,
        [TRANSITION_CONFIRM_SECOND_INTERVIEW]: STATE_ACCEPTED_JOB_APPLICATION,
        [TRANSITION_DECLINE_SECOND_INTERVIEW]: STATE_DECLINE_INTERVIEW,
        [TRANSITION_REQUEST_CASE_STUDY]: STATE_CASE_STUDY_PENDING,
        [TRANSITION_SUBMIT_CASE_STUDY]: STATE_CASE_STUDY_PENDING,
        [TRANSITION_DECLINE_CASE_STUDY]: STATE_DECLINED_CASE_STUDY,
        [TRANSITION_SEND_JOB_OFFER]: STATE_JOB_OFFER_PENDING,
        [TRANSITION_DECLINE_JOB_APPLICATION]: STATE_DECLINE_JOB_APPLICATION,
      },
    },
    [STATE_JOB_OFFER_PENDING]: {
      on: {
        [TRANSITION_ACCEPT_JOB_OFFER]: STATE_ACCEPTED_JOB_OFFER,
        [TRANSITION_DECLINE_JOB_OFFER]: STATE_DECLINED_JOB_OFFER,
      },
    },
    [STATE_ACCEPTED_JOB_OFFER]: {
      on: {
        [TRANSITION_REQUEST_CONTRACT_SIGNATURE]: STATE_PENDING_CONTRACT_SIGNATURE,
        [TRANSITION_REQUEST_OFFLINE_PAYMENT_WITHOUT_CONTRACT]: STATE_PENDING_OFFLINE_PAYMENT,
      },
    },
    [STATE_PENDING_CONTRACT_SIGNATURE]: {
      on: {
        [TRANSITION_DECLINE_CONTRACT_SIGNATURE]: STATE_DECLINED_CONTRACT_SIGNATURE,
        [TRANSITION_COMFIRM_CONTRACT_SIGNATURE]: STATE_CONFIRMED_CONTRACT_SIGNATURE,
      },
    },
    [STATE_CONFIRMED_CONTRACT_SIGNATURE]: {
      on: {
        [TRANSITION_REQUEST_OFFLINE_PAYMENT]: STATE_PENDING_OFFLINE_PAYMENT,
      },
    },
    [STATE_PENDING_OFFLINE_PAYMENT]: {
      on: {
        [TRANSITION_CONFIRM_OFFLINE_PAYMENT]: STATE_CONFIRMED_OFFLINE_PAYMENT,
      },
    },
    [STATE_CONFIRMED_OFFLINE_PAYMENT]: { type: 'final' },
    // [STATE_CONFIRMED_OFFLINE_PAYMENT]: {
    //   on: {
    //     [TRANSITION_REVIEW_1_BY_PROVIDER]: STATE_REVIEWED_BY_PROVIDER,
    //     [TRANSITION_REVIEW_1_BY_CUSTOMER]: STATE_REVIEWED_BY_CUSTOMER,
    //   },
    // },
    // [STATE_REVIEWED_BY_CUSTOMER]: {
    //   on: {
    //     [TRANSITION_REVIEW_2_BY_PROVIDER]: STATE_REVIEWED,
    //   },
    // },
    // [STATE_REVIEWED_BY_PROVIDER]: {
    //   on: {
    //     [TRANSITION_REVIEW_2_BY_CUSTOMER]: STATE_REVIEWED,
    //   },
    // },
    // [STATE_REVIEWED]: { type: 'final' },
  },
};

// Note: currently we assume that state description doesn't contain nested states.
const statesFromStateDescription = description => description.states || {};

// Get all the transitions from states object in an array
const getTransitions = states => {
  const stateNames = Object.keys(states);

  const transitionsReducer = (transitionArray, name) => {
    const stateTransitions = states[name] && states[name].on;
    const transitionKeys = stateTransitions ? Object.keys(stateTransitions) : [];
    return [
      ...transitionArray,
      ...transitionKeys.map(key => ({ key, value: stateTransitions[key] })),
    ];
  };

  return stateNames.reduce(transitionsReducer, []);
};

// This is a list of all the transitions that this app should be able to handle.
export const TRANSITIONS_BRIEF = getTransitions(
  statesFromStateDescription(briefStateDescription)
).map(t => t.key);

export const TRANSITIONS_GIG_APPS = getTransitions(statesFromStateDescription(gigApps)).map(
  t => t.key
);

export const TRANSITIONS_PREPAY = getTransitions(
  statesFromStateDescription(prepayStateDescription)
).map(t => t.key);

export const TRANSITIONS = getTransitions(statesFromStateDescription(stateDescription)).map(
  t => t.key
);
export const TRANSITIONS_FINAL = getTransitions(
  statesFromStateDescription(finalProcessStateDescription)
).map(t => t.key);

export const TRANSITIONS_APPLY_JOB = getTransitions(
  statesFromStateDescription(applyJobStateDescription)
).map(t => t.key);

export const TRANSITIONS_JOB_DESCRIPTION = getTransitions(
  statesFromStateDescription(jobDescriptionStateDescription)
).map(t => t.key);

// This function returns a function that has given stateDesc in scope chain.
const getTransitionsToStateFn = stateDesc => state =>
  getTransitions(statesFromStateDescription(stateDesc))
    .filter(t => t.value === state)
    .map(t => t.key);

// Get all the transitions that lead to specified state.
const getGigAppsTransitionsToState = getTransitionsToStateFn(gigApps);
const getTransitionsToState = getTransitionsToStateFn(stateDescription);
const getBriefTransitionsToState = getTransitionsToStateFn(briefStateDescription);
const getPrepayTransitionsToState = getTransitionsToStateFn(prepayStateDescription);
const getJobTransitionsToState = getTransitionsToStateFn(applyJobStateDescription);
const getJobDescTransitionsToState = getTransitionsToStateFn(jobDescriptionStateDescription);

/**
 * Helper functions to figure out if transaction is in a specific state.
 * State is based on lastTransition given by transaction object and state description.
 */

export const txLastTransition = tx => ensureTransaction(tx).attributes.lastTransition;

export const txIsBriefOffered = tx =>
  getTransitionsToState(STATE_BRIEF_OFFERED).includes(txLastTransition(tx));

export const txIsBriefDeclined = tx =>
  getTransitionsToState(STATE_BRIEF_DECLINED).includes(txLastTransition(tx));

export const txIsProposalDeclined = tx => {
  return (
    getTransitionsToState(STATE_PROPOSAL_EXPIRED).includes(txLastTransition(tx)) ||
    getTransitionsToState(STATE_PROPOSAL_DECLINED).includes(txLastTransition(tx))
  );
};

export const acceptedProposaltransitions = [
  TRANSITION_ACCEPT_PROPOSAL,
  TRANSITION_ACCEPT_PROPOSAL_AFTER_BRIEF,
  TRANSITION_PROPOSAL_ACCEPT_AFTER_CSM_INVITE_OPERATOR,
  TRANSITION_ACCEPT_PROPOSAL_OPERATOR,
];
export const txIsPrepaymentPending = tx => {
  return acceptedProposaltransitions.includes(txLastTransition(tx));
};
export const txIsCsmInvitePending = tx => {
  return getTransitionsToState(STATE_CSM_INVITE_ACCEPT_PROPOSAL_OPERATOR).includes(
    txLastTransition(tx)
  );
};

export const txIsPrepaymentApproved = tx => {
  return txLastTransition(tx) === TRANSITION_OFFLINE_PAYMENT_REQUESTED ? true : false;
};

export const txIsToBriefProposalDeclined = tx =>
  getBriefTransitionsToState(STATE_BRIEF_EXPIRED_PROPOSAL).includes(txLastTransition(tx)) ||
  getBriefTransitionsToState(STATE_BRIEF_DECLINED_PROPOSAL).includes(txLastTransition(tx));

export const txIsProposalCanceled = tx =>
  getTransitionsToState(STATE_PROPOSAL_CANCELLED).includes(txLastTransition(tx));

export const txIsToBriefProposalCanceled = tx =>
  getBriefTransitionsToState(STATE_BRIEF_CANCELLED_PROPOSAL).includes(txLastTransition(tx));

export const txIsPendingProposal = tx =>
  getTransitionsToState(STATE_PENDING_PROPOSAL).includes(txLastTransition(tx));

export const txIsToBriefPendingProposal = tx =>
  getBriefTransitionsToState(STATE_BRIEF_PENDING_PROPOSAL).includes(txLastTransition(tx));

export const txIsProposalAccepted = tx =>
  getTransitionsToState(STATE_PROPOSAL_ACCEPTED).includes(txLastTransition(tx));
export const txIsProposalAcceptedByOperator = tx => {
  getTransitionsToState(STATE_PROPOSAL_ACCEPTED).includes(txLastTransition(tx));
};

// Gig-Apps transaction

export const txIsInitiatedForGigApp = tx => {
  return getGigAppsTransitionsToState(STATE_ASSISTED_FLOW_INITIATED).includes(txLastTransition(tx));
};
export const txMilestoneForGigApp = tx => {
  return getGigAppsTransitionsToState(STATE_MILESTONE_CREATED).includes(txLastTransition(tx));
};

export const txIsRequsetMarkComplete = tx => {
  return getGigAppsTransitionsToState(STATE_REQUEST_TRANSITION_COMPLETE).includes(txLastTransition(tx));
};

export const txIsGigAppCompleted = tx => {
  return getGigAppsTransitionsToState(STATE_MARK_TRANSITION_COMPLETE).includes(txLastTransition(tx));
};

export const txIsToBriefProposalAccepted = tx =>
  getBriefTransitionsToState(STATE_BRIEF_ACCEPTED_PROPOSAL).includes(txLastTransition(tx));

export const txIsPrepaid = tx =>
  getTransitionsToState(STATE_PREPAYMENT_COMPLETED).includes(txLastTransition(tx));

export const txIsPaymentPending = tx => {
  return getTransitionsToState(STATE_MARK_COMPLETE).includes(txLastTransition(tx));
};

export const txIsPrepaidUsingStripe = tx =>
  getTransitionsToState(STATE_PREPAYMENT_COMPLETED_USING_STRIPE).includes(txLastTransition(tx));

export const txIsPendingPayment = tx =>
  getTransitionsToState(STATE_PENDING_PAYMENT).includes(txLastTransition(tx));

export const txIsPendingPaymentAfterProposalAccepted = tx =>
  getTransitionsToState(STATE_PENDING_PAYMENT_AFTER_PROPOSAL_ACCEPTED).includes(
    txLastTransition(tx)
  );
export const txConfirmedPaymentAfterProposalAccepted = tx =>
  getTransitionsToState(STATE_CONFIRM_PAYMENT_AFTER_PROPOSAL_ACCEPTED).includes(
    txLastTransition(tx)
  );

export const txIsPaymentFailed = tx =>
  getTransitionsToState(STATE_PAYMENT_FAILED).includes(txLastTransition(tx));

export const txIsInProgress = tx =>
  getTransitionsToState(STATE_INPROGRESS).includes(txLastTransition(tx));

export const txIsMarkComplete = tx =>
  getTransitionsToState(STATE_MARK_COMPLETE).includes(txLastTransition(tx));

export const txIsCompleted = tx =>
  getTransitionsToState(STATE_COMPLETED).includes(txLastTransition(tx));
export const finalTxIsCompleted = tx =>
  getTransitionsToState(STATE_COMPLETED).includes(txLastTransition(tx));

const firstReviewTransitions = [
  ...getTransitionsToState(STATE_REVIEWED_BY_CUSTOMER),
  ...getTransitionsToState(STATE_REVIEWED_BY_PROVIDER),
];
export const txIsInFirstReview = tx => firstReviewTransitions.includes(txLastTransition(tx));

export const txIsInFirstReviewBy = (tx, isCustomer) =>
  isCustomer
    ? getTransitionsToState(STATE_REVIEWED_BY_CUSTOMER).includes(txLastTransition(tx))
    : getTransitionsToState(STATE_REVIEWED_BY_PROVIDER).includes(txLastTransition(tx));

export const txIsReviewed = tx =>
  getTransitionsToState(STATE_REVIEWED).includes(txLastTransition(tx));

/**
 * Helper functions to figure out if transaction has passed a given state.
 * This is based on transitions history given by transaction object.
 */
export const txIsMilestoneAdded = tx =>
  ensureTransaction(tx).attributes.lastTransition == TRANSITION_CREATE_MILESTONE;
export const txIsMilestoneAddedByOperator = tx =>
  ensureTransaction(tx).attributes.lastTransition == TRANSITION_CREATE_MILESTONE_OPERATOR;
export const txIsMilestoneUpdated = tx =>
  ensureTransaction(tx).attributes.lastTransition == TRANSITION_UPDATE_MILESTONE;
export const txIsMilestoneDeleted = tx =>
  ensureTransaction(tx).attributes.lastTransition == TRANSITION_DELETE_MILESTONE;
export const txIsMilestoneCompleted = tx =>
  ensureTransaction(tx).attributes.lastTransition == TRANSITION_COMPLETE_MILESTONE;
export const txIsMilestoneUnfinished = tx =>
  ensureTransaction(tx).attributes.lastTransition == TRANSITION_UNFINISHED_MILESTONE;

const txTransitions = tx => ensureTransaction(tx).attributes.transitions || [];
const hasPassedTransition = (transitionName, tx) =>
  !!txTransitions(tx).find(t => t.transition === transitionName);

const hasPassedStateFn = state => tx => {
  return getTransitionsToState(state).includes(txLastTransition(tx));
};
export const txIsFinalPaymentPending = tx => {
  return getTransitionsToState(STATE_REQUEST_MARK_COMPLETED_USING_STRIPE).includes(
    txLastTransition(tx)
  );
};
export const txIsFinalPaymentPendingIsPaymentIsDueUponProjectCompletion = tx => {
  return getTransitionsToState(CONFIRM_FINAL_PAYYMENT_AFTER_PREPAYMENT_STRIPE).includes(
    txLastTransition(tx)
  );
};

export const txHasBeenDelivered = hasPassedStateFn(STATE_COMPLETED);
export const txIsPaid = hasPassedStateFn(STATE_PREPAYMENT_COMPLETED);
export const txIsPaidUsingStripe = hasPassedStateFn(STATE_PREPAYMENT_COMPLETED_USING_STRIPE);
export const txIsPaidAndCondirmUsingStripe = hasPassedStateFn(
  STATE_CONFIRM_PAYMENT_AFTER_PROPOSAL_ACCEPTED
);
/**
 * Other transaction related utility functions
 */

export const transitionIsReviewed = transition =>
  getTransitionsToState(STATE_REVIEWED).includes(transition);

export const transitionIsFirstReviewedBy = (transition, isCustomer) =>
  isCustomer
    ? getTransitionsToState(STATE_REVIEWED_BY_CUSTOMER).includes(transition)
    : getTransitionsToState(STATE_REVIEWED_BY_PROVIDER).includes(transition);

export const getReview1Transition = isCustomer =>
  isCustomer ? TRANSITION_REVIEW_1_BY_CUSTOMER : TRANSITION_REVIEW_1_BY_PROVIDER;

export const getReview2Transition = isCustomer =>
  isCustomer ? TRANSITION_REVIEW_2_BY_CUSTOMER : TRANSITION_REVIEW_2_BY_PROVIDER;

// Check if a transition is the kind that should be rendered
// when showing transition history (e.g. ActivityFeed)
// The first transition and most of the expiration transitions made by system are not relevant
export const isRelevantPastTransition = transition => {
  return [
    TRANSITION_OFFER_BRIEF,
    TRANSITION_DECLINE_BRIEF,
    TRANSITION_CREATE_PROPOSAL,
    TRANSITION_CSM_PRIVATE_BRIEF_INVITE,
    TRANSITION_ACCEPT_PROPOSAL,
    TRANSITION_ACCEPT_PROPOSAL_OPERATOR,
    TRANSITION_OFFLINE_PAYMENT_REQUESTED,
    TRANSITION_INITIATED_ASSISTED_FLOW,
    TRANSITION_ACCEPT_PROPOSAL_AFTER_BRIEF,
    TRANSITION_CSM_INVITE_ACCEPT_PROPOSAL_OPERATOR,
    TRANSITION_PROPOSAL_ACCEPT_AFTER_CSM_INVITE_OPERATOR,
    TRANSITION_PROPOSAL_DECLINE_AFTER_CSM_INVITE_OPERATOR,
    TRANSITION_REQUEST_PAYMENT_AFTER_PROPOSAL_ACCEPTED,
    TRANSITION_DECLINE_PROPOSAL,
    TRANSITION_PROPOSAL_DECLINE_CSM_INVITE_OPERATOR,
    TRANSITION_CANCEL_PROPOSAL,
    TRANSITION_REQUEST_PREPAYMENT,
    TRANSITION_REQUEST_PREPAYMENT_IF_PAYMENT_DUE_UPON_PROJECT_COMPLETION,
    TRANSITION_REQUEST_CONFIRM_PAYMENT_IF_PAYMENT_DUE_UPON_PROJECT_COMPLETION,
    TRANSITION_REQUEST_FINAL_PAYMENT_IF_PAYMENT_DUE_UPON_PROJECT_COMPLETION,
    TRANSITION_REQUEST_PREPAYMENT_USING_STRIPE,
    TRANSITION_REQUEST_MARK_COMPLETE,
    TRANSITION_REQUEST_MARK_COMPLETE_OPERATOR,
    TRANSITION_REQUEST_MARK_COMPLETE_AFTER_PREPAYMENT,
    TRANSITION_CONFIRM_MARK_COMPLETE_AFTER_PREPAYMENT,
    TRANSITION_REQUEST_PAYMENT_AFTER_PROPOSAL_ACCEPTED,
    TRANSITION_CONFIRM_PAYMENT_AFTER_PROPOSAL_ACCEPTED,
    TRANSITION_COMPLETE_USING_STRIPE,
    // TRANSITION_CONFIRM_PAYMENT,
    TRANSITION_COMPLETE,
    TRANSITION_FINAL_PAYMENT_COMPLETED,
    TRANSITION_FINAL_PAYMENT_COMPLETED_IF_FULL_AMOUNT_IS_ALREADY_PAID,
    TRANSITION_COMPLETE_USING_STRIPE,
    TRANSITION_REVIEW_1_BY_CUSTOMER,
    TRANSITION_REVIEW_1_BY_PROVIDER,
    TRANSITION_REVIEW_2_BY_CUSTOMER,
    TRANSITION_REVIEW_2_BY_PROVIDER,
  ].includes(transition);
};

export const isRelevantPastBriefTransition = transition => {
  return [
    TRANSITION_BRIEF_CREATE_PROPOSAL,
    TRANSITION_BRIEF_CREATE_PROPOSAL,
    TRANSITION_BRIEF_ACCEPT_PROPOSAL,
    TRANSITION_BRIEF_EXPIRE_PROPOSAL,
    TRANSITION_BRIEF_DECLINE_PROPOSAL,
    TRANSITION_BRIEF_CANCEL_PROPOSAL,
  ].includes(transition);
};

export const isCustomerReview = transition => {
  return [TRANSITION_REVIEW_1_BY_CUSTOMER, TRANSITION_REVIEW_2_BY_CUSTOMER].includes(transition);
};

export const isProviderReview = transition => {
  return [TRANSITION_REVIEW_1_BY_PROVIDER, TRANSITION_REVIEW_2_BY_PROVIDER].includes(transition);
};

export const txIsJobApplicationSent = tx => {
  return getJobTransitionsToState(STATE_PENDING_JOB_APPLICATION).includes(txLastTransition(tx));
};

export const txIsRejectedJob = tx => {
  return getJobDescTransitionsToState(STATE_REJECTED_JOB_INVITE).includes(txLastTransition(tx));
};

export const txIsJobDescriptionSent = tx => {
  return getJobDescTransitionsToState(STATE_PENDING_JOB_DESCRIPTION).includes(txLastTransition(tx));
};
export const txIsJobApplicationExpire = tx => {
  return getJobTransitionsToState(STATE_EXPIRED_JOB_APPLICATION).includes(txLastTransition(tx));
}
export const txIsJobDescriptionExpire = tx => {
  return getJobDescTransitionsToState(STATE_EXPIRED_JOB_DESCRIPTION).includes(txLastTransition(tx));
}

export const txIsJobDescriptionDeclined = tx => {
  return getJobDescTransitionsToState(STATE_DECLINE_JOB_DESCRIPTION).includes(txLastTransition(tx));
};

export const txIsJobApplicationDeclined = tx => {
  return getJobDescTransitionsToState(STATE_DECLINE_JOB_APPLICATION).includes(txLastTransition(tx));
};

export const txIsRequestFirstInteview = tx => {
  return txLastTransition(tx) === TRANSITION_REQUEST_FIRST_INTERVIEW;
};

export const txIsConfirmFirstInteview = tx => {
  return txLastTransition(tx) === TRANSITION_CONFIRM_FIRST_INTERVIEW;
};

export const txIsRequestSecondInteview = tx => {
  return txLastTransition(tx) === TRANSITION_REQUEST_SECOND_INTERVIEW;
};

export const txIsConfirmSecondInteview = tx => {
  return txLastTransition(tx) === TRANSITION_CONFIRM_SECOND_INTERVIEW;
};

export const txIsDeclinedInteview = tx => {
  return getJobDescTransitionsToState(STATE_DECLINE_INTERVIEW).includes(txLastTransition(tx));
};

export const txIsDeclinedCaseStudy = tx => {
  return txLastTransition(tx) === TRANSITION_DECLINE_CASE_STUDY;
};

export const txIsRequestCaseStudy = tx => {
  return txLastTransition(tx) === TRANSITION_REQUEST_CASE_STUDY;
};

export const txIsSubmittedCaseStudy = tx => {
  return txLastTransition(tx) === TRANSITION_SUBMIT_CASE_STUDY;
};

export const txIsJobOfferSend = tx => {
  return txLastTransition(tx) === TRANSITION_SEND_JOB_OFFER;
};

export const txIsJobOfferAccepted = tx => {
  return txLastTransition(tx) === TRANSITION_ACCEPT_JOB_OFFER;
};

export const txIsJobOfferDeclined = tx => {
  return txLastTransition(tx) === TRANSITION_DECLINE_JOB_OFFER;
};

export const txIsContractSend = tx => {
  return txLastTransition(tx) === TRANSITION_REQUEST_CONTRACT_SIGNATURE;
};

export const txIsContractSigned = tx => {
  return txLastTransition(tx) === TRANSITION_COMFIRM_CONTRACT_SIGNATURE;
};

export const txIsRequestedOfflinePayment = tx => {
  return txLastTransition(tx) === TRANSITION_REQUEST_OFFLINE_PAYMENT
    || txLastTransition(tx) === TRANSITION_REQUEST_OFFLINE_PAYMENT_WITHOUT_CONTRACT;
}

export const txIsConfirmOfflinePayment = tx => {
  return txLastTransition(tx) === TRANSITION_CONFIRM_OFFLINE_PAYMENT;
};

export const txIsOneReviewGiven = tx => {
  return [TRANSITION_REVIEW_1_BY_PROVIDER, TRANSITION_REVIEW_1_BY_CUSTOMER].includes(
    txLastTransition(tx)
  );
};

export const txIsBothReviewGiven = tx => {
  return [TRANSITION_REVIEW_2_BY_PROVIDER, TRANSITION_REVIEW_2_BY_CUSTOMER].includes(
    txLastTransition(tx)
  );
};

export const getUserTxRole = (currentUserId, transaction) => {
  try {
    const tx = ensureTransaction(transaction);
    const customer = tx.customer;
    const provider = tx.provider;
    if (!!currentUserId && currentUserId.uuid && tx.id && customer.id) {
      // user can be either customer or provider
      return currentUserId?.uuid === customer?.id?.uuid
        ? TX_TRANSITION_ACTOR_CUSTOMER
        : currentUserId?.uuid === provider?.id?.uuid
          ? TX_TRANSITION_ACTOR_PROVIDER
          : TX_TRANSITION_ACTOR_OPERATOR;
    }
  } catch (error) {
    throw new Error(`Parameters for "userIsCustomer" function were wrong.
      currentUserId: ${currentUserId}, transaction: ${transaction}`);
  }
};

export const txRoleIsProvider = userRole => userRole === TX_TRANSITION_ACTOR_PROVIDER;
export const txRoleIsCustomer = userRole => userRole === TX_TRANSITION_ACTOR_CUSTOMER;
export const txRoleIsPartner = userRole => userRole === TX_TRANSITION_ACTOR_OPERATOR;

const customerTransitionArray = [
  TRANSITION_DECLINE_BRIEF,
  TRANSITION_CREATE_PROPOSAL,
  TRANSITION_CSM_PRIVATE_BRIEF_INVITE,
  TRANSITION_BRIEF_UPDATE_PROPOSAL,
  TRANSITION_CREATE_MILESTONE,
  TRANSITION_CREATE_MILESTONE_AFTER_PREPAYMENT,
  TRANSITION_CREATE_MILESTONE_IF_PAYMENT_IS_DUE_UPON_PROJECT_COMPLETION,
  TRANSITION_CREATE_MILESTONE_AFTER_PREPAYMENT,
  TRANSITION_CREATE_MILESTONE_OPERATOR,
  TRANSITION_CREATE_MILESTONE_OPERATOR_AFTER_PREPAYMENT,
  TRANSITION_CREATE_MILESTONE_OPERATOR_IF_PAYMENT_IS_DUE_UPON_PROJECT_COMPLETION,
  TRANSITION_CREATE_MILESTONE_OPERATOR_AFTER_PREPAYMENT,
  TRANSITION_DELETE_MILESTONE,
  TRANSITION_DELETE_MILESTONE_AFTER_PREPAYMENT,
  TRANSITION_DELETE_MILESTONE,
  TRANSITION_DELETE_MILESTONE_AFTER_PREPAYMENT_IF_PAYMENT_IS_DUE_UPON_PROJECT_COMPLETION,
  TRANSITION_DELETE_MILESTONE_OPERATOR,
  TRANSITION_DELETE_MILESTONE_OPERATOR_AFTER_PREPAYMENT,
  TRANSITION_UPDATE_MILESTONE,
  TRANSITION_UPDATE_MILESTONE_AFTER_PREPAYMENT,
  TRANSITION_UPDATE_MILESTONE_OPERATOR,
  TRANSITION_UPDATE_MILESTONE_OPERATOR_AFTER_PREPAYMENT,
  TRANSITION_COMPLETE_MILESTONE,
  TRANSITION_COMPLETE_MILESTONE_AFTER_PREPAYMENT,
  TRANSITION_COMPLETE_MILESTONE_OPERATOR,
  TRANSITION_COMPLETE_MILESTONE_OPERATOR_AFTER_PREPAYMENT,
  TRANSITION_REVIEW_1_BY_PROVIDER,
  TRANSITION_REVIEW_2_BY_PROVIDER,
  TRANSITION_DECLINE_JOB_DESCRIPTION,
  TRANSITION_SEND_JOB_APPLICATION,
  TRANSITION_DECLINE_FIRST_INTERVIEW,
  TRANSITION_DECLINE_SECOND_INTERVIEW,
  TRANSITION_SUBMIT_CASE_STUDY,
  TRANSITION_DECLINE_CASE_STUDY,
  TRANSITION_ACCEPT_JOB_OFFER,
  TRANSITION_DECLINE_JOB_OFFER,
  TRANSITION_REJECT_JOB_INVITE,
  TRANSITION_REJECT_JOB_AFTER_APPLICATION,
  TRANSITION_PROPOSAL_DECLINE_AFTER_CSM_INVITE_OPERATOR,
  TRANSITION_PROPOSAL_ACCEPT_AFTER_CSM_INVITE_OPERATOR,
  TRANSITION_REQUEST_MARK_COMPLETE,
  TRANSITION_REQUEST_MARK_COMPLETE_OPERATOR,
];

const expertTransitionArray = [
  TRANSITION_BRIEF_CREATE_PROPOSAL,
  TRANSITION_OFFER_BRIEF,
  TRANSITION_ACCEPT_PROPOSAL,
  TRANSITION_ACCEPT_PROPOSAL_AFTER_BRIEF,
  TRANSITION_DECLINE_PROPOSAL,
  TRANSITION_BRIEF_ACCEPT_PROPOSAL,
  TRANSITION_OFFLINE_PAYMENT_REQUESTED,
  TRANSITION_REQUEST_PREPAYMENT,
  TRANSITION_REQUEST_PREPAYMENT_IF_PAYMENT_DUE_UPON_PROJECT_COMPLETION,
  TRANSITION_CONFIRM_PAYMENT_AFTER_PROPOSAL_ACCEPTED,
  TRANSITION_REQUEST_PREPAYMENT_USING_STRIPE,
  TRANSITION_REQUEST_MARK_COMPLETE,
  TRANSITION_REQUEST_MARK_COMPLETE_OPERATOR,
  TRANSITION_CONFIRM_MARK_COMPLETE_AFTER_PREPAYMENT,
  TRANSITION_COMPLETE,
  TRANSITION_REVIEW_1_BY_CUSTOMER,
  TRANSITION_REVIEW_2_BY_CUSTOMER,
  TRANSITION_SEND_JOB_DESCRIPTION,
  TRANSITION_DECLINE_JOB_APPLICATION,
  TRANSITION_REQUEST_FIRST_INTERVIEW,
  TRANSITION_CONFIRM_FIRST_INTERVIEW,
  TRANSITION_REQUEST_SECOND_INTERVIEW,
  TRANSITION_CONFIRM_SECOND_INTERVIEW,
  TRANSITION_REQUEST_CASE_STUDY,
  TRANSITION_SEND_JOB_OFFER,
  TRANSITION_REQUEST_CONTRACT_SIGNATURE,
  TRANSITION_COMFIRM_CONTRACT_SIGNATURE,
  TRANSITION_REQUEST_OFFLINE_PAYMENT,
  TRANSITION_REQUEST_OFFLINE_PAYMENT_WITHOUT_CONTRACT,
  TRANSITION_CONFIRM_OFFLINE_PAYMENT,
];

export const isCustomerTranstion = (lastTransition, isBriefTransaction) => {
  const isSameTransition = lastTransition === TRANSITION_BRIEF_UPDATE_PROPOSAL;
  if (isBriefTransaction && isSameTransition) {
    return customerTransitionArray.includes(lastTransition) ? false : true;
  }
  return customerTransitionArray.includes(lastTransition);
};

export const isExpertTranstion = (lastTransition, isBriefTransaction) => {
  const isSameTransition = lastTransition === TRANSITION_BRIEF_UPDATE_PROPOSAL;
  if (isBriefTransaction && isSameTransition) {
    return expertTransitionArray.includes(lastTransition) ? false : true;
  }
  return expertTransitionArray.includes(lastTransition);
};

// Check if the given transition is privileged.
//
// Privileged transitions need to be handled from a secure context,
// i.e. the backend. This helper is used to check if the transition
// should go through the local API endpoints, or if using JS SDK is
// enough.
export const isPrivileged = transition => {
  return [
    TRANSITION_BRIEF_CREATE_PROPOSAL,
    TRANSITION_INITIATED_ASSISTED_FLOW,
    TRANSITION_REQUEST_TRANSACTION_COMPLETE_CUSTOMER_ASSISTED,
    TRANSITION_REQUEST_TRANSACTION_COMPLETE_PROVIDER_ASSISTED,
    TRANSITION_PREPAY_REQUEST,
    TRANSITION_ACCEPT_PROPOSAL_AFTER_BRIEF,
    TRANSITION_CSM_INVITE_ACCEPT_PROPOSAL_OPERATOR,
    TRANSITION_ACCEPT_PROPOSAL,
    TRANSITION_REQUEST_PAYMENT_AFTER_PROPOSAL_ACCEPTED,
    TRANSITION_REQUEST_MARK_COMPLETE_AFTER_PREPAYMENT,
  ].includes(transition);
};

export const isBriefProposalTransaction = tx => {
  return TRANSITIONS_BRIEF.includes(txLastTransition(tx));
};

export const handleTransactionHistory = (tx, record) => {
  const history = tx?.attributes?.metadata?.history || [];
  const { content, sender, data = null } = record;
  return [
    ...history,
    {
      type: 'history',
      attributes: {
        content,
        createdAt: new Date().toString(),
        data,
      },
      sender: {
        id: {
          uuid: sender,
        },
      },
    },
  ];
};

// get transition

export const getTransition = (type, lastTransition, isCollaborator) => {
  console.log(type, lastTransition, 'lastTransitionlastTransition')
  const transitionsMap = {
    [ADD_MILESTONE]: {
      [TRANSITION_REQUEST_PREPAYMENT]: isCollaborator
        ? TRANSITION_CREATE_MILESTONE_OPERATOR
        : TRANSITION_CREATE_MILESTONE,
      [TRANSITION_INITIATED_ASSISTED_FLOW]: isCollaborator
        ? TRANSITION_MILESTONE_CREATED_OPERATOR_GIG_APPS
        : TRANSITION_MILESTONE_CREATED_ASSISTED_GIG_APPS,
      [TRANSITION_MILESTONE_CREATED_ASSISTED_GIG_APPS]: isCollaborator
        ? TRANSITION_MILESTONE_CREATED_OPERATOR_GIG_APPS
        : TRANSITION_MILESTONE_CREATED_GIG_APPS,
      [TRANSITION_REQUEST_PREPAYMENT_IF_PAYMENT_DUE_UPON_PROJECT_COMPLETION]: isCollaborator
        ? TRANSITION_CREATE_MILESTONE_OPERATOR_IF_PAYMENT_IS_DUE_UPON_PROJECT_COMPLETION
        : TRANSITION_CREATE_MILESTONE_IF_PAYMENT_IS_DUE_UPON_PROJECT_COMPLETION,
      [TRANSITION_UPDATE_MILESTONE_AFTER_PREPAYMENT_IF_PAYMENT_IS_DUE_UPON_PROJECT_COMPLETION]: isCollaborator
        ? TRANSITION_CREATE_MILESTONE_OPERATOR_IF_PAYMENT_IS_DUE_UPON_PROJECT_COMPLETION
        : TRANSITION_CREATE_MILESTONE_IF_PAYMENT_IS_DUE_UPON_PROJECT_COMPLETION,
      [TRANSITION_CREATE_MILESTONE_IF_PAYMENT_IS_DUE_UPON_PROJECT_COMPLETION]: isCollaborator
        ? TRANSITION_CREATE_MILESTONE_OPERATOR_AFTER_PREPAYMENT
        : TRANSITION_CREATE_MILESTONE_AFTER_PREPAYMENT,
      [TRANSITION_DELETE_MILESTONE_AFTER_PREPAYMENT_IF_PAYMENT_IS_DUE_UPON_PROJECT_COMPLETION]: isCollaborator
        ? TRANSITION_CREATE_MILESTONE_OPERATOR_AFTER_PREPAYMENT
        : TRANSITION_CREATE_MILESTONE_AFTER_PREPAYMENT,
      [TRANSITION_REQUEST_PREPAYMENT_USING_STRIPE]: isCollaborator
        ? TRANSITION_COMPLETE_MILESTONE_OPERATOR_AFTER_PREPAYMENT
        : TRANSITION_COMPLETE_MILESTONE_AFTER_PREPAYMENT,
      [TRANSITION_COMPLETE_MILESTONE]: isCollaborator
        ? TRANSITION_CREATE_MILESTONE_OPERATOR
        : TRANSITION_CREATE_MILESTONE,
      [TRANSITION_COMPLETE_MILESTONE_OPERATOR]: isCollaborator
        ? TRANSITION_CREATE_MILESTONE_OPERATOR
        : TRANSITION_CREATE_MILESTONE,
      [TRANSITION_COMPLETE_MILESTONE_AFTER_PREPAYMENT]: isCollaborator
        ? TRANSITION_CREATE_MILESTONE_OPERATOR_AFTER_PREPAYMENT
        : TRANSITION_CREATE_MILESTONE_AFTER_PREPAYMENT,
      [TRANSITION_COMPLETE_MILESTONE_OPERATOR_AFTER_PREPAYMENT]: isCollaborator
        ? TRANSITION_CREATE_MILESTONE_OPERATOR_AFTER_PREPAYMENT
        : TRANSITION_CREATE_MILESTONE_AFTER_PREPAYMENT,

      [TRANSITION_UNFINISHED_MILESTONE]: isCollaborator
        ? TRANSITION_CREATE_MILESTONE_OPERATOR
        : TRANSITION_CREATE_MILESTONE,

      [TRANSITION_MILESTONE_CREATED_ASSISTED_GIG_APPS]: isCollaborator
        ? TRANSITION_MILESTONE_COMPLETED_OPERATOR_GIG_APPS
        : TRANSITION_MILESTONE_COMPLETED_GIG_APPS,
      [TRANSITION_UNFINISHED_MILESTONE_OPERATOR]: isCollaborator
        ? TRANSITION_CREATE_MILESTONE_OPERATOR
        : TRANSITION_CREATE_MILESTONE,
      [TRANSITION_UNFINISHED_MILESTONE_AFTER_PREPAYMENT]: isCollaborator
        ? TRANSITION_CREATE_MILESTONE_OPERATOR_AFTER_PREPAYMENT
        : TRANSITION_CREATE_MILESTONE_AFTER_PREPAYMENT,
      [TRANSITION_UNFINISHED_MILESTONE_OPERATOR_AFTER_PREPAYMENT]: isCollaborator
        ? TRANSITION_CREATE_MILESTONE_OPERATOR_AFTER_PREPAYMENT
        : TRANSITION_CREATE_MILESTONE_AFTER_PREPAYMENT,

      [TRANSITION_CREATE_MILESTONE]: isCollaborator
        ? TRANSITION_CREATE_MILESTONE_OPERATOR
        : TRANSITION_CREATE_MILESTONE,

      [TRANSITION_MILESTONE_INCOMPLETED_GIG_APPS]: isCollaborator
        ? TRANSITION_MILESTONE_CREATED_OPERATOR_GIG_APPS
        : TRANSITION_MILESTONE_CREATED_GIG_APPS,
      [TRANSITION_CREATE_MILESTONE_OPERATOR]: isCollaborator
        ? TRANSITION_CREATE_MILESTONE_OPERATOR
        : TRANSITION_CREATE_MILESTONE,
      [TRANSITION_CREATE_MILESTONE_AFTER_PREPAYMENT]: isCollaborator
        ? TRANSITION_CREATE_MILESTONE_OPERATOR_AFTER_PREPAYMENT
        : TRANSITION_CREATE_MILESTONE_AFTER_PREPAYMENT,
      [TRANSITION_CREATE_MILESTONE_OPERATOR_AFTER_PREPAYMENT]: isCollaborator
        ? TRANSITION_CREATE_MILESTONE_OPERATOR_AFTER_PREPAYMENT
        : TRANSITION_CREATE_MILESTONE_AFTER_PREPAYMENT,

      [TRANSITION_UPDATE_MILESTONE]: isCollaborator
        ? TRANSITION_CREATE_MILESTONE_OPERATOR
        : TRANSITION_CREATE_MILESTONE,
      [TRANSITION_MILESTONE_UPDATED_GIG_APPS]: isCollaborator
        ? TRANSITION_MILESTONE_CREATED_OPERATOR_GIG_APPS
        : TRANSITION_MILESTONE_CREATED_GIG_APPS,


      [TRANSITION_UPDATE_MILESTONE_OPERATOR]: isCollaborator
        ? TRANSITION_CREATE_MILESTONE_OPERATOR
        : TRANSITION_CREATE_MILESTONE,

      [TRANSITION_UPDATE_MILESTONE_AFTER_PREPAYMENT]: isCollaborator
        ? TRANSITION_CREATE_MILESTONE_OPERATOR_AFTER_PREPAYMENT
        : TRANSITION_CREATE_MILESTONE_AFTER_PREPAYMENT,
      [TRANSITION_UPDATE_MILESTONE_OPERATOR_AFTER_PREPAYMENT]: isCollaborator
        ? TRANSITION_CREATE_MILESTONE_OPERATOR_AFTER_PREPAYMENT
        : TRANSITION_CREATE_MILESTONE_AFTER_PREPAYMENT,

      [TRANSITION_DELETE_MILESTONE]: isCollaborator
        ? TRANSITION_CREATE_MILESTONE_OPERATOR
        : TRANSITION_CREATE_MILESTONE,
      [TRANSITION_MILESTONE_DELETED_GIG_APPS]: isCollaborator
        ? TRANSITION_MILESTONE_CREATED_OPERATOR_GIG_APPS
        : TRANSITION_MILESTONE_CREATED_GIG_APPS,
      [TRANSITION_DELETE_MILESTONE_OPERATOR]: isCollaborator
        ? TRANSITION_CREATE_MILESTONE_OPERATOR
        : TRANSITION_CREATE_MILESTONE,

      [TRANSITION_DELETE_MILESTONE_AFTER_PREPAYMENT]: isCollaborator
        ? TRANSITION_CREATE_MILESTONE_OPERATOR_AFTER_PREPAYMENT
        : TRANSITION_CREATE_MILESTONE_AFTER_PREPAYMENT,
      [TRANSITION_DELETE_MILESTONE_OPERATOR_AFTER_PREPAYMENT]: isCollaborator
        ? TRANSITION_CREATE_MILESTONE_OPERATOR_AFTER_PREPAYMENT
        : TRANSITION_CREATE_MILESTONE_AFTER_PREPAYMENT,
    },
    [EDIT_MILESTONE]: {
      [TRANSITION_COMPLETE_MILESTONE]: isCollaborator
        ? TRANSITION_UPDATE_MILESTONE_OPERATOR
        : TRANSITION_UPDATE_MILESTONE,
      [TRANSITION_MILESTONE_COMPLETED_GIG_APPS]: isCollaborator
        ? TRANSITION_MILESTONE_UPDATED_OPERATOR_GIG_APPS
        : TRANSITION_MILESTONE_UPDATED_GIG_APPS,
      [TRANSITION_COMPLETE_MILESTONE_OPERATOR]: isCollaborator
        ? TRANSITION_UPDATE_MILESTONE_OPERATOR
        : TRANSITION_UPDATE_MILESTONE,
      [TRANSITION_CREATE_MILESTONE_IF_PAYMENT_IS_DUE_UPON_PROJECT_COMPLETION]: isCollaborator
        ? TRANSITION_UPDATE_MILESTONE_OPERATOR_AFTER_PREPAYMENT_IF_PAYMENT_IS_DUE_UPON_PROJECT_COMPLETION
        : TRANSITION_UPDATE_MILESTONE_AFTER_PREPAYMENT_IF_PAYMENT_IS_DUE_UPON_PROJECT_COMPLETION,
      [TRANSITION_COMPLETE_MILESTONE_AFTER_PREPAYMENT]: isCollaborator
        ? TRANSITION_UPDATE_MILESTONE_OPERATOR_AFTER_PREPAYMENT
        : TRANSITION_UPDATE_MILESTONE_AFTER_PREPAYMENT,
      [TRANSITION_CREATE_MILESTONE_OPERATOR_IF_PAYMENT_IS_DUE_UPON_PROJECT_COMPLETION]: isCollaborator
        ? TRANSITION_UPDATE_MILESTONE_OPERATOR_AFTER_PREPAYMENT
        : TRANSITION_UPDATE_MILESTONE_AFTER_PREPAYMENT,
      [TRANSITION_COMPLETE_MILESTONE_OPERATOR_AFTER_PREPAYMENT]: isCollaborator
        ? TRANSITION_UPDATE_MILESTONE_OPERATOR_AFTER_PREPAYMENT
        : TRANSITION_UPDATE_MILESTONE_AFTER_PREPAYMENT,

      [TRANSITION_UNFINISHED_MILESTONE]: isCollaborator
        ? TRANSITION_UPDATE_MILESTONE_OPERATOR
        : TRANSITION_UPDATE_MILESTONE,
      [TRANSITION_UNFINISHED_MILESTONE_OPERATOR]: isCollaborator
        ? TRANSITION_UPDATE_MILESTONE_OPERATOR
        : TRANSITION_UPDATE_MILESTONE,
      [TRANSITION_UNFINISHED_MILESTONE_AFTER_PREPAYMENT]: TRANSITION_UPDATE_MILESTONE_OPERATOR_AFTER_PREPAYMENT,
      [TRANSITION_UNFINISHED_MILESTONE_OPERATOR_AFTER_PREPAYMENT]: TRANSITION_UPDATE_MILESTONE_OPERATOR_AFTER_PREPAYMENT,

      [TRANSITION_CREATE_MILESTONE]: isCollaborator
        ? TRANSITION_UPDATE_MILESTONE_OPERATOR
        : TRANSITION_UPDATE_MILESTONE,
      [TRANSITION_MILESTONE_CREATED_GIG_APPS]: isCollaborator
        ? TRANSITION_MILESTONE_UPDATED_OPERATOR_GIG_APPS
        : TRANSITION_MILESTONE_UPDATED_GIG_APPS,
      [TRANSITION_CREATE_MILESTONE_OPERATOR_IF_PAYMENT_IS_DUE_UPON_PROJECT_COMPLETION]: isCollaborator
        ? TRANSITION_UPDATE_MILESTONE_OPERATOR
        : TRANSITION_UPDATE_MILESTONE,
      [TRANSITION_CREATE_MILESTONE_OPERATOR]: isCollaborator
        ? TRANSITION_UPDATE_MILESTONE_OPERATOR
        : TRANSITION_UPDATE_MILESTONE,
      [TRANSITION_UNFINISHED_MILESTONE_AFTER_PREPAYMENT]: isCollaborator
        ? TRANSITION_UPDATE_MILESTONE_OPERATOR
        : TRANSITION_UPDATE_MILESTONE,
      [TRANSITION_UNFINISHED_MILESTONE_AFTER_PREPAYMENT]: isCollaborator
        ? TRANSITION_UPDATE_MILESTONE_OPERATOR_AFTER_PREPAYMENT
        : TRANSITION_UPDATE_MILESTONE_AFTER_PREPAYMENT,
      [TRANSITION_CREATE_MILESTONE_AFTER_PREPAYMENT]: isCollaborator
        ? TRANSITION_UPDATE_MILESTONE_OPERATOR_AFTER_PREPAYMENT
        : TRANSITION_UPDATE_MILESTONE_AFTER_PREPAYMENT,
      [TRANSITION_CREATE_MILESTONE_OPERATOR_AFTER_PREPAYMENT]: isCollaborator
        ? TRANSITION_UPDATE_MILESTONE_OPERATOR_AFTER_PREPAYMENT
        : TRANSITION_UPDATE_MILESTONE_AFTER_PREPAYMENT,

      [TRANSITION_UPDATE_MILESTONE]: isCollaborator
        ? TRANSITION_UPDATE_MILESTONE_OPERATOR
        : TRANSITION_UPDATE_MILESTONE,

      [TRANSITION_UPDATE_MILESTONE_OPERATOR]: isCollaborator
        ? TRANSITION_UPDATE_MILESTONE_OPERATOR
        : TRANSITION_UPDATE_MILESTONE,

      [TRANSITION_UPDATE_MILESTONE_AFTER_PREPAYMENT]: isCollaborator
        ? TRANSITION_UPDATE_MILESTONE_OPERATOR_AFTER_PREPAYMENT
        : TRANSITION_UPDATE_MILESTONE_AFTER_PREPAYMENT,
      [TRANSITION_UPDATE_MILESTONE_OPERATOR_AFTER_PREPAYMENT]: isCollaborator
        ? TRANSITION_UPDATE_MILESTONE_OPERATOR_AFTER_PREPAYMENT
        : TRANSITION_UPDATE_MILESTONE_AFTER_PREPAYMENT,

      [TRANSITION_DELETE_MILESTONE]: isCollaborator
        ? TRANSITION_UPDATE_MILESTONE_OPERATOR
        : TRANSITION_UPDATE_MILESTONE,
      [TRANSITION_DELETE_MILESTONE_OPERATOR]: isCollaborator
        ? TRANSITION_UPDATE_MILESTONE_OPERATOR
        : TRANSITION_UPDATE_MILESTONE,
      [TRANSITION_DELETE_MILESTONE_AFTER_PREPAYMENT]: isCollaborator
        ? TRANSITION_UPDATE_MILESTONE_OPERATOR_AFTER_PREPAYMENT
        : TRANSITION_UPDATE_MILESTONE_AFTER_PREPAYMENT,
      [TRANSITION_DELETE_MILESTONE_OPERATOR_AFTER_PREPAYMENT]: isCollaborator
        ? TRANSITION_UPDATE_MILESTONE_OPERATOR_AFTER_PREPAYMENT
        : TRANSITION_UPDATE_MILESTONE_AFTER_PREPAYMENT,
    },
    [DELETE_MILESTONE]: {
      [TRANSITION_COMPLETE_MILESTONE]: isCollaborator
        ? TRANSITION_DELETE_MILESTONE_OPERATOR
        : TRANSITION_DELETE_MILESTONE,
      [TRANSITION_COMPLETE_MILESTONE_OPERATOR]: isCollaborator
        ? TRANSITION_DELETE_MILESTONE_OPERATOR
        : TRANSITION_DELETE_MILESTONE,
      [TRANSITION_COMPLETE_MILESTONE_AFTER_PREPAYMENT]: isCollaborator
        ? TRANSITION_DELETE_MILESTONE_OPERATOR_AFTER_PREPAYMENT
        : TRANSITION_DELETE_MILESTONE_AFTER_PREPAYMENT,
      [TRANSITION_CREATE_MILESTONE_IF_PAYMENT_IS_DUE_UPON_PROJECT_COMPLETION]: isCollaborator
        ? TRANSITION_DELETE_MILESTONE_OPERATOR_AFTER_PREPAYMENT_IF_PAYMENT_IS_DUE_UPON_PROJECT_COMPLETION
        : TRANSITION_DELETE_MILESTONE_AFTER_PREPAYMENT_IF_PAYMENT_IS_DUE_UPON_PROJECT_COMPLETION,
      [TRANSITION_COMPLETE_MILESTONE_OPERATOR_AFTER_PREPAYMENT]: isCollaborator
        ? TRANSITION_DELETE_MILESTONE_OPERATOR_AFTER_PREPAYMENT
        : TRANSITION_DELETE_MILESTONE_AFTER_PREPAYMENT,
      [TRANSITION_MILESTONE_COMPLETED_GIG_APPS]: isCollaborator
        ? TRANSITION_MILESTONE_DELETED_OPERATOR_GIG_APPS
        : TRANSITION_MILESTONE_DELETED_GIG_APPS,
      [TRANSITION_UNFINISHED_MILESTONE]: isCollaborator
        ? TRANSITION_DELETE_MILESTONE_OPERATOR
        : TRANSITION_DELETE_MILESTONE,
      [TRANSITION_UNFINISHED_MILESTONE_OPERATOR]: isCollaborator
        ? TRANSITION_DELETE_MILESTONE_OPERATOR
        : TRANSITION_DELETE_MILESTONE,
      [TRANSITION_UNFINISHED_MILESTONE_AFTER_PREPAYMENT]: isCollaborator
        ? TRANSITION_DELETE_MILESTONE_OPERATOR_AFTER_PREPAYMENT
        : TRANSITION_DELETE_MILESTONE_AFTER_PREPAYMENT,
      [TRANSITION_UNFINISHED_MILESTONE_OPERATOR_AFTER_PREPAYMENT]: isCollaborator
        ? TRANSITION_DELETE_MILESTONE_OPERATOR_AFTER_PREPAYMENT
        : TRANSITION_DELETE_MILESTONE_AFTER_PREPAYMENT,
      [TRANSITION_UPDATE_MILESTONE_AFTER_PREPAYMENT_IF_PAYMENT_IS_DUE_UPON_PROJECT_COMPLETION]: isCollaborator
        ? TRANSITION_DELETE_MILESTONE_OPERATOR_AFTER_PREPAYMENT
        : TRANSITION_DELETE_MILESTONE_AFTER_PREPAYMENT,

      [TRANSITION_CREATE_MILESTONE]: isCollaborator
        ? TRANSITION_DELETE_MILESTONE_OPERATOR
        : TRANSITION_DELETE_MILESTONE,
      [TRANSITION_MILESTONE_CREATED_GIG_APPS]: isCollaborator
        ? TRANSITION_MILESTONE_DELETED_OPERATOR_GIG_APPS
        : TRANSITION_MILESTONE_DELETED_GIG_APPS,
      [TRANSITION_CREATE_MILESTONE_OPERATOR]: isCollaborator
        ? TRANSITION_DELETE_MILESTONE_OPERATOR
        : TRANSITION_DELETE_MILESTONE,
      [TRANSITION_CREATE_MILESTONE_AFTER_PREPAYMENT]: isCollaborator
        ? TRANSITION_DELETE_MILESTONE_OPERATOR_AFTER_PREPAYMENT
        : TRANSITION_DELETE_MILESTONE_AFTER_PREPAYMENT,
      [TRANSITION_CREATE_MILESTONE_OPERATOR_AFTER_PREPAYMENT]: isCollaborator
        ? TRANSITION_DELETE_MILESTONE_OPERATOR_AFTER_PREPAYMENT
        : TRANSITION_DELETE_MILESTONE_AFTER_PREPAYMENT,

      [TRANSITION_UPDATE_MILESTONE]: isCollaborator
        ? TRANSITION_DELETE_MILESTONE_OPERATOR
        : TRANSITION_DELETE_MILESTONE,
      [TRANSITION_UPDATE_MILESTONE_OPERATOR]: isCollaborator
        ? TRANSITION_DELETE_MILESTONE_OPERATOR
        : TRANSITION_DELETE_MILESTONE,
      [TRANSITION_UPDATE_MILESTONE_AFTER_PREPAYMENT]: isCollaborator
        ? TRANSITION_DELETE_MILESTONE_OPERATOR_AFTER_PREPAYMENT
        : TRANSITION_DELETE_MILESTONE_AFTER_PREPAYMENT,
      [TRANSITION_UPDATE_MILESTONE_OPERATOR_AFTER_PREPAYMENT]: isCollaborator
        ? TRANSITION_DELETE_MILESTONE_OPERATOR_AFTER_PREPAYMENT
        : TRANSITION_DELETE_MILESTONE_AFTER_PREPAYMENT,

      [TRANSITION_DELETE_MILESTONE]: isCollaborator
        ? TRANSITION_DELETE_MILESTONE_OPERATOR
        : TRANSITION_DELETE_MILESTONE,
      [TRANSITION_MILESTONE_CREATED_ASSISTED_GIG_APPS]: isCollaborator
        ? TRANSITION_MILESTONE_DELETED_OPERATOR_GIG_APPS
        : TRANSITION_MILESTONE_DELETED_GIG_APPS,
      [TRANSITION_DELETE_MILESTONE_OPERATOR]: isCollaborator
        ? TRANSITION_DELETE_MILESTONE_OPERATOR
        : TRANSITION_DELETE_MILESTONE,
      [TRANSITION_DELETE_MILESTONE_AFTER_PREPAYMENT]: isCollaborator
        ? TRANSITION_DELETE_MILESTONE_OPERATOR_AFTER_PREPAYMENT
        : TRANSITION_DELETE_MILESTONE_AFTER_PREPAYMENT,
      [TRANSITION_DELETE_MILESTONE_OPERATOR_AFTER_PREPAYMENT]: isCollaborator
        ? TRANSITION_DELETE_MILESTONE_OPERATOR_AFTER_PREPAYMENT
        : TRANSITION_DELETE_MILESTONE_AFTER_PREPAYMENT,
    },
    [COMPLETE_MILESTONE]: {
      [TRANSITION_MILESTONE_CREATED_ASSISTED_GIG_APPS]: isCollaborator
        ? TRANSITION_MILESTONE_COMPLETED_OPERATOR_GIG_APPS
        : TRANSITION_MILESTONE_COMPLETED_GIG_APPS,
      [TRANSITION_COMPLETE_MILESTONE]: isCollaborator
        ? TRANSITION_COMPLETE_MILESTONE_OPERATOR
        : TRANSITION_COMPLETE_MILESTONE,
      [TRANSITION_COMPLETE_MILESTONE_OPERATOR]: isCollaborator
        ? TRANSITION_COMPLETE_MILESTONE_OPERATOR
        : TRANSITION_COMPLETE_MILESTONE,
      [TRANSITION_COMPLETE_MILESTONE_AFTER_PREPAYMENT]: isCollaborator
        ? TRANSITION_COMPLETE_MILESTONE_OPERATOR_AFTER_PREPAYMENT
        : TRANSITION_COMPLETE_MILESTONE_AFTER_PREPAYMENT,
      [TRANSITION_CREATE_MILESTONE_IF_PAYMENT_IS_DUE_UPON_PROJECT_COMPLETION]: isCollaborator
        ? TRANSITION_COMPLETE_MILESTONE_OPERATOR_AFTER_PREPAYMENT_IF_PAYMENT_IS_DUE_UPON_PROJECT_COMPLETION
        : TRANSITION_COMPLETE_MILESTONE_AFTER_PREPAYMENT_IF_PAYMENT_IS_DUE_UPON_PROJECT_COMPLETION,
      [TRANSITION_COMPLETE_MILESTONE_OPERATOR_AFTER_PREPAYMENT]: isCollaborator
        ? TRANSITION_COMPLETE_MILESTONE_OPERATOR_AFTER_PREPAYMENT
        : TRANSITION_COMPLETE_MILESTONE_AFTER_PREPAYMENT,

      [TRANSITION_MILESTONE_COMPLETED_GIG_APPS]: isCollaborator
        ? TRANSITION_MILESTONE_COMPLETED_OPERATOR_GIG_APPS
        : TRANSITION_MILESTONE_COMPLETED_GIG_APPS,

      [TRANSITION_UNFINISHED_MILESTONE]: isCollaborator
        ? TRANSITION_COMPLETE_MILESTONE_OPERATOR
        : TRANSITION_COMPLETE_MILESTONE,

      [TRANSITION_UNFINISHED_MILESTONE_OPERATOR]: isCollaborator
        ? TRANSITION_COMPLETE_MILESTONE_OPERATOR
        : TRANSITION_COMPLETE_MILESTONE,
      [TRANSITION_UNFINISHED_MILESTONE_AFTER_PREPAYMENT]: isCollaborator
        ? TRANSITION_COMPLETE_MILESTONE_OPERATOR_AFTER_PREPAYMENT
        : TRANSITION_COMPLETE_MILESTONE_AFTER_PREPAYMENT,
      [TRANSITION_UNFINISHED_MILESTONE_OPERATOR_AFTER_PREPAYMENT]: isCollaborator
        ? TRANSITION_COMPLETE_MILESTONE_OPERATOR_AFTER_PREPAYMENT
        : TRANSITION_COMPLETE_MILESTONE_AFTER_PREPAYMENT,

      [TRANSITION_MILESTONE_INCOMPLETED_GIG_APPS]: isCollaborator
        ? TRANSITION_MILESTONE_COMPLETED_OPERATOR_GIG_APPS
        : TRANSITION_MILESTONE_COMPLETED_GIG_APPS,

      [TRANSITION_CREATE_MILESTONE]: isCollaborator
        ? TRANSITION_COMPLETE_MILESTONE_OPERATOR
        : TRANSITION_COMPLETE_MILESTONE,
      [TRANSITION_MILESTONE_CREATED_GIG_APPS]: isCollaborator
        ? TRANSITION_MILESTONE_COMPLETED_OPERATOR_GIG_APPS
        : TRANSITION_MILESTONE_COMPLETED_GIG_APPS,
      [TRANSITION_CREATE_MILESTONE_OPERATOR]: isCollaborator
        ? TRANSITION_COMPLETE_MILESTONE_OPERATOR
        : TRANSITION_COMPLETE_MILESTONE,
      [TRANSITION_CREATE_MILESTONE_AFTER_PREPAYMENT]: isCollaborator
        ? TRANSITION_COMPLETE_MILESTONE_OPERATOR_AFTER_PREPAYMENT
        : TRANSITION_COMPLETE_MILESTONE_AFTER_PREPAYMENT,
      [TRANSITION_CREATE_MILESTONE_OPERATOR_AFTER_PREPAYMENT]: isCollaborator
        ? TRANSITION_COMPLETE_MILESTONE_OPERATOR_AFTER_PREPAYMENT
        : TRANSITION_COMPLETE_MILESTONE_AFTER_PREPAYMENT,

      [TRANSITION_UPDATE_MILESTONE]: isCollaborator
        ? TRANSITION_COMPLETE_MILESTONE_OPERATOR
        : TRANSITION_COMPLETE_MILESTONE,

      [TRANSITION_MILESTONE_UPDATED_GIG_APPS]: isCollaborator
        ? TRANSITION_MILESTONE_COMPLETED_OPERATOR_GIG_APPS
        : TRANSITION_MILESTONE_COMPLETED_GIG_APPS,
      [TRANSITION_UPDATE_MILESTONE_OPERATOR]: isCollaborator
        ? TRANSITION_COMPLETE_MILESTONE_OPERATOR
        : TRANSITION_COMPLETE_MILESTONE,
      [TRANSITION_UPDATE_MILESTONE_AFTER_PREPAYMENT]: isCollaborator
        ? TRANSITION_COMPLETE_MILESTONE_OPERATOR_AFTER_PREPAYMENT
        : TRANSITION_COMPLETE_MILESTONE_AFTER_PREPAYMENT,
      [TRANSITION_UPDATE_MILESTONE_OPERATOR_AFTER_PREPAYMENT]: isCollaborator
        ? TRANSITION_COMPLETE_MILESTONE_OPERATOR_AFTER_PREPAYMENT
        : TRANSITION_COMPLETE_MILESTONE_AFTER_PREPAYMENT,

      [TRANSITION_DELETE_MILESTONE]: isCollaborator
        ? TRANSITION_COMPLETE_MILESTONE_OPERATOR
        : TRANSITION_COMPLETE_MILESTONE,
      [TRANSITION_MILESTONE_DELETED_GIG_APPS]: isCollaborator
        ? TRANSITION_MILESTONE_COMPLETED_OPERATOR_GIG_APPS
        : TRANSITION_MILESTONE_COMPLETED_GIG_APPS,
      [TRANSITION_DELETE_MILESTONE_OPERATOR]: isCollaborator
        ? TRANSITION_COMPLETE_MILESTONE_OPERATOR
        : TRANSITION_COMPLETE_MILESTONE,
      [TRANSITION_DELETE_MILESTONE_AFTER_PREPAYMENT]: isCollaborator
        ? TRANSITION_COMPLETE_MILESTONE_OPERATOR_AFTER_PREPAYMENT
        : TRANSITION_COMPLETE_MILESTONE_AFTER_PREPAYMENT,
      [TRANSITION_DELETE_MILESTONE_OPERATOR_AFTER_PREPAYMENT]: isCollaborator
        ? TRANSITION_COMPLETE_MILESTONE_OPERATOR_AFTER_PREPAYMENT
        : TRANSITION_COMPLETE_MILESTONE_AFTER_PREPAYMENT,
    },
    [INCOMPLETE_MILESTONE]: {
      [TRANSITION_COMPLETE_MILESTONE]: isCollaborator
        ? TRANSITION_UNFINISHED_MILESTONE_OPERATOR
        : TRANSITION_UNFINISHED_MILESTONE,
      [TRANSITION_COMPLETE_MILESTONE_OPERATOR]: isCollaborator
        ? TRANSITION_UNFINISHED_MILESTONE_OPERATOR
        : TRANSITION_UNFINISHED_MILESTONE,
      [TRANSITION_COMPLETE_MILESTONE_AFTER_PREPAYMENT]: isCollaborator
        ? TRANSITION_UNFINISHED_MILESTONE_OPERATOR_AFTER_PREPAYMENT
        : TRANSITION_UNFINISHED_MILESTONE_AFTER_PREPAYMENT,
      [TRANSITION_COMPLETE_MILESTONE_AFTER_PREPAYMENT_IF_PAYMENT_IS_DUE_UPON_PROJECT_COMPLETION]: isCollaborator
        ? TRANSITION_UNFINISHED_MILESTONE_OPERATOR_AFTER_PREPAYMENT_IF_PAYMENT_IS_DUE_UPON_PROJECT_COMPLETION
        : TRANSITION_UNFINISHED_MILESTONE_AFTER_PREPAYMENT_IF_PAYMENT_IS_DUE_UPON_PROJECT_COMPLETION,
      [TRANSITION_COMPLETE_MILESTONE_OPERATOR_AFTER_PREPAYMENT]: isCollaborator
        ? TRANSITION_UNFINISHED_MILESTONE_OPERATOR_AFTER_PREPAYMENT
        : TRANSITION_UNFINISHED_MILESTONE_AFTER_PREPAYMENT,

      [TRANSITION_MILESTONE_COMPLETED_GIG_APPS]: isCollaborator
        ? TRANSITION_MILESTONE_INCOMPLETED_OPERATOR_GIG_APPS
        : TRANSITION_MILESTONE_INCOMPLETED_GIG_APPS,

      [TRANSITION_UNFINISHED_MILESTONE]: isCollaborator
        ? TRANSITION_UNFINISHED_MILESTONE_OPERATOR
        : TRANSITION_UNFINISHED_MILESTONE,
      [TRANSITION_UNFINISHED_MILESTONE_OPERATOR]: isCollaborator
        ? TRANSITION_UNFINISHED_MILESTONE_OPERATOR
        : TRANSITION_UNFINISHED_MILESTONE,
      [TRANSITION_UNFINISHED_MILESTONE_AFTER_PREPAYMENT]: isCollaborator
        ? TRANSITION_UNFINISHED_MILESTONE_OPERATOR_AFTER_PREPAYMENT
        : TRANSITION_UNFINISHED_MILESTONE_AFTER_PREPAYMENT,
      [TRANSITION_UNFINISHED_MILESTONE_OPERATOR_AFTER_PREPAYMENT]: isCollaborator
        ? TRANSITION_UNFINISHED_MILESTONE_OPERATOR_AFTER_PREPAYMENT
        : TRANSITION_UNFINISHED_MILESTONE_AFTER_PREPAYMENT,

      [TRANSITION_MILESTONE_INCOMPLETED_GIG_APPS]: isCollaborator
        ? TRANSITION_MILESTONE_CREATED_OPERATOR_GIG_APPS
        : TRANSITION_MILESTONE_CREATED_GIG_APPS,

      [TRANSITION_MILESTONE_INCOMPLETED_GIG_APPS]: isCollaborator
        ? TRANSITION_MILESTONE_INCOMPLETED_OPERATOR_GIG_APPS
        : TRANSITION_MILESTONE_INCOMPLETED_GIG_APPS,

      [TRANSITION_CREATE_MILESTONE]: isCollaborator
        ? TRANSITION_UNFINISHED_MILESTONE_OPERATOR
        : TRANSITION_UNFINISHED_MILESTONE,
      [TRANSITION_CREATE_MILESTONE_OPERATOR]: isCollaborator
        ? TRANSITION_UNFINISHED_MILESTONE_OPERATOR
        : TRANSITION_UNFINISHED_MILESTONE,
      [TRANSITION_CREATE_MILESTONE_AFTER_PREPAYMENT]: isCollaborator
        ? TRANSITION_UNFINISHED_MILESTONE_OPERATOR_AFTER_PREPAYMENT
        : TRANSITION_UNFINISHED_MILESTONE_AFTER_PREPAYMENT,
      [TRANSITION_CREATE_MILESTONE_OPERATOR_AFTER_PREPAYMENT]: isCollaborator
        ? TRANSITION_UNFINISHED_MILESTONE_OPERATOR_AFTER_PREPAYMENT
        : TRANSITION_UNFINISHED_MILESTONE_AFTER_PREPAYMENT,

      [TRANSITION_UPDATE_MILESTONE]: isCollaborator
        ? TRANSITION_UNFINISHED_MILESTONE_OPERATOR
        : TRANSITION_UNFINISHED_MILESTONE,
      [TRANSITION_UPDATE_MILESTONE_OPERATOR]: isCollaborator
        ? TRANSITION_UNFINISHED_MILESTONE_OPERATOR
        : TRANSITION_UNFINISHED_MILESTONE,
      [TRANSITION_UPDATE_MILESTONE_AFTER_PREPAYMENT]: isCollaborator
        ? TRANSITION_UNFINISHED_MILESTONE_OPERATOR_AFTER_PREPAYMENT
        : TRANSITION_UNFINISHED_MILESTONE_AFTER_PREPAYMENT,
      [TRANSITION_UPDATE_MILESTONE_OPERATOR_AFTER_PREPAYMENT]: isCollaborator
        ? TRANSITION_UNFINISHED_MILESTONE_OPERATOR_AFTER_PREPAYMENT
        : TRANSITION_UNFINISHED_MILESTONE_AFTER_PREPAYMENT,

      [TRANSITION_DELETE_MILESTONE]: isCollaborator
        ? TRANSITION_UNFINISHED_MILESTONE_OPERATOR
        : TRANSITION_UNFINISHED_MILESTONE,

      [TRANSITION_MILESTONE_DELETED_GIG_APPS]: isCollaborator
        ? TRANSITION_MILESTONE_INCOMPLETED_OPERATOR_GIG_APPS
        : TRANSITION_MILESTONE_INCOMPLETED_GIG_APPS,
      [TRANSITION_DELETE_MILESTONE_OPERATOR]: isCollaborator
        ? TRANSITION_UNFINISHED_MILESTONE_OPERATOR
        : TRANSITION_UNFINISHED_MILESTONE,
      [TRANSITION_DELETE_MILESTONE_AFTER_PREPAYMENT]: isCollaborator
        ? TRANSITION_UNFINISHED_MILESTONE_OPERATOR_AFTER_PREPAYMENT
        : TRANSITION_UNFINISHED_MILESTONE_AFTER_PREPAYMENT,
      [TRANSITION_DELETE_MILESTONE_OPERATOR_AFTER_PREPAYMENT]: isCollaborator
        ? TRANSITION_UNFINISHED_MILESTONE_OPERATOR_AFTER_PREPAYMENT
        : TRANSITION_UNFINISHED_MILESTONE_AFTER_PREPAYMENT,
    },
  };

  return transitionsMap[type]?.[lastTransition] ?? null;
};
