import React, { useCallback, useState } from 'react';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Field, Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { BLOG, WEBSITE, propTypes } from '../../util/types';
import { maxLength, required, composeValidators, autocompleteSearchRequired } from '../../util/validators';
import { Form, Button, FieldTextInput, LocationAutocompleteInputField, OutsideClickHandler, IconCard, ExternalLink } from '../../components';
import { v4 as uuidv4 } from 'uuid';
import css from './EditListingDescriptionForm.module.css';
import CustomCategorySelectFieldMaybe from "./CustomCertificateSelectFieldMaybe";
import { expertType } from "../../marketplace-custom-config";
import { searchLanguage, fetchURLPreviews } from '../../util/api';
import { CROSS } from '../../components/IconCard/IconCard';
import { ensureHttpsPrefix, isValidWebsiteUrl } from '../../util/destructorHelpers';

const identity = v => v;
const TITLE_MAX_LENGTH = 60;
const EditListingDescriptionFormComponent = props => (
  <FinalForm
    {...props}
    render={formRenderProps => {
      const {
        className,
        disabled,
        ready,
        handleSubmit,
        intl,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        values,
        form,
      } = formRenderProps;

      // title
      const titleMessage = intl.formatMessage({ id: 'EditListingDescriptionForm.title' });
      const languageValidationMessage = intl.formatMessage({id:'EditListingDescriptionForm.languageValidationMessage'})
      const titlePlaceholderMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.titlePlaceholder',
      });
      const titleRequiredMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.titleRequired',
      });
      const maxLengthMessage = intl.formatMessage(
        { id: 'EditListingDescriptionForm.maxLength' },
        {
          maxLength: TITLE_MAX_LENGTH,
        }
      );

      // description

      const descriptionMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.description',
      });
      const descriptionPlaceholderMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.descriptionPlaceholder',
      });
      const maxLength60Message = maxLength(maxLengthMessage, TITLE_MAX_LENGTH);
      const descriptionRequiredMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.descriptionRequired',
      });

      const { updateListingError, createListingDraftError, showListingsError } = fetchErrors || {};
      const errorMessageUpdateListing = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingDescriptionForm.updateFailed" />
        </p>
      ) : null;



      // category
      const categoryLabel = intl.formatMessage({
        id: 'EditListingDescriptionForm.categoryLabel',
      });
      const categoryPlaceholder = intl.formatMessage({
        id: 'EditListingDescriptionForm.categoryPlaceholder',
      });
      const categoryRequired = intl.formatMessage({
        id: 'EditListingDescriptionForm.categoryRequired',
      })


      // websiteInputLabel
      const websiteInputLabel = intl.formatMessage({
        id: 'EditListingDescriptionPanel.websiteInputLabel',
      });
      const websiteInputPlaceholder = intl.formatMessage({
        id: 'EditListingDescriptionPanel.websiteInputPlaceholder',
      });


      // blogInputLabel
      const blogInputLabel = intl.formatMessage({
        id: 'EditListingDescriptionPanel.blogInputLabel',
      });
      const blogInputPlaceholder = intl.formatMessage({
        id: 'EditListingDescriptionPanel.blogInputPlaceholder',
      });

      const locationPlaceholder = intl.formatMessage({
        id: 'EditListingAdditionalForm.locationPlaceHolder',
      });
      const addressRequiredMessage = intl.formatMessage({
        id: 'EditListingLocationForm.addressRequired',
      });

      // Languages spoken

      const languagePlaceholder = intl.formatMessage({
        id: 'EditListingLocationForm.languagePlaceholder',
      });
      // This error happens only on first tab (of EditListingWizard)
      const errorMessageCreateListingDraft = createListingDraftError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingDescriptionForm.createListingDraftError" />
        </p>
      ) : null;

      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingBriefDescriptionForm.showListingFailed" />
        </p>
      ) : null;

      const checkLanguageArrayLength = ()=>{
        if(!values.spokenLanguages || !values?.spokenLanguages?.length){
          return true
        }
      }

      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress || checkLanguageArrayLength() || (values.title === '...enter your title here');

      const [isDropdownOpen, setIsDropdownOpen] = useState(false);
      const [isUlVisible, setIsUlVisible] = useState(false);

      const resetFormFields = () => {
        form.batch(() => {
          form.change('language', null);
          form.change('searchResults', null);
          form.change('searchKeyword', null);
        });
      }
      const toggleDropdown = () => {
        setIsDropdownOpen(prevIsDropdownOpen => !prevIsDropdownOpen);
      };

      const handleRemoveLanguage = (languageToRemove) => {
        // Get the current array of spoken languages from the form state
        const currentLanguages = values?.spokenLanguages || [];

        // Filter out the language to be removed
        const updatedLanguages = currentLanguages.filter(
          (language) => language !== languageToRemove
        );

        // Update the 'spokenLanguages' field in the form with the updated array
        form.change('spokenLanguages', updatedLanguages);
      };

      // HandleSearchChange
      const handleSearchChange = async (value, category = false) => {
        try {
          const normalizedUrl = value.startsWith('https://') ? value : `https://${value}`;
          if ((category === WEBSITE && isValidWebsiteUrl(normalizedUrl)) || (category === BLOG && isValidWebsiteUrl(normalizedUrl))) {
            const previewResponse = await fetchURLPreviews(normalizedUrl);
            const formKey = category === WEBSITE ? 'websitePreviewResponse' : 'blogPreviewResponse';
            form.change(formKey, previewResponse);
          } else {
            const response = await searchLanguage(value);
            form.change('searchResults', response);
            setIsUlVisible(true);
          }
        } catch (error) {
          return;
        }
      };
      const debounce = (func, delay) => {
        let timerId;
        return (...args) => {
          clearTimeout(timerId);
          timerId = setTimeout(() => {
            func(...args);
          }, delay);
        };
      };
      const debouncedSearch = useCallback(debounce(handleSearchChange, 300), []);


      //Handle clear 
      const handleClearClick = () => {
        resetFormFields();
        setIsUlVisible(false);
        setIsDropdownOpen(false);
      };
      const { searchResults = [] } = values || {};


      const handleSelectLanguage = (language) => {
        {
          const selectedLanguage = { key: language.name.toLowerCase(), label: language.name };
          const currentLanguages = form.getState().values.spokenLanguages || [];

          // Check if the selected language is not already in the array
          if (!currentLanguages.some(lang => lang.key === selectedLanguage.key)) {
            // If it's not a duplicate, add it to the array
            form.change('spokenLanguages', [...currentLanguages, selectedLanguage]);

          }
        }
        form.batch(() => {
          form.change('language', null);
          form.change('searchResults', null);
          form.change('searchKeyword', null);
        });
        setIsDropdownOpen(false);

      }

      const handleLanguageKeyPress = (e) => {
        const { value } = e.target;
        if (e.key === 'Enter' && value.trim() !== '') {
          const selectedLanguage = {
            key: value.toLowerCase(),
            label: value.charAt(0).toUpperCase() + value.slice(1).toLowerCase()
          };
          const currentLanguages = form.getState().values.spokenLanguages || []
          if (!currentLanguages.some(lang => lang.key === selectedLanguage.key)) {
            form.change('spokenLanguages', [...currentLanguages, selectedLanguage])
          }
          resetFormFields();
          setIsUlVisible(false);
          setIsDropdownOpen(false);
        }
      };

      const { websitePreviewResponse = {}, blogPreviewResponse = {} } = values || {};
      const modifiedWebsiteUrl = ensureHttpsPrefix(values?.website);
      const modifiedBlogUrl = ensureHttpsPrefix(values?.blogUrl);

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {errorMessageCreateListingDraft}
          {errorMessageUpdateListing}
          {errorMessageShowListing}
          <FieldTextInput
            id="title"
            name="title"
            className={css.title}
            type="text"
            label={titleMessage}
            placeholder={titlePlaceholderMessage}
            maxLength={TITLE_MAX_LENGTH}
            validate={composeValidators(required(titleRequiredMessage), maxLength60Message)}
            autoFocus
          />
          {values.title === '...enter your title here' && (
            <span className={css.profileHeadlineAlert}>Please edit your 'Profile Headline' to proceed</span>
          )}
          <FieldTextInput
            id="description"
            name="description"
            className={css.description}
            type="text"
            label={descriptionMessage}
            placeholder={descriptionPlaceholderMessage}
            validate={composeValidators(required(descriptionRequiredMessage))}
          />

          <CustomCategorySelectFieldMaybe
            className={css.category}
            id="category"
            name="category"
            categories={expertType}
            intl={intl}
            label={categoryLabel}
            placeholder={categoryPlaceholder}
            validate={categoryRequired}
          />

          <LocationAutocompleteInputField
            className={css.currencySelector}
            inputClassName={css.locationAutocompleteInput}
            iconClassName={css.locationAutocompleteInputIcon}
            predictionsClassName={css.predictionsRoot}
            validClassName={css.validLocation}
            // autoFocus
            name="location"
            label={"Country"}
            placeholder={locationPlaceholder}
            useDefaultPredictions={false}
            format={identity}
            valueFromForm={values.location}
            validate={composeValidators(
              autocompleteSearchRequired(addressRequiredMessage),
              // autocompletePlaceSelected(addressNotRecognizedMessage)
            )}
          />

          <div className={css.languagesContainer}>
            <label>
              <FormattedMessage id="EditListingDescriptionForm.spokenLanguagesHeading" />
            </label>
            <p><FormattedMessage id="EditListingDescriptionForm.spokenLanguagesSubHeading" /></p>
            <div className={classNames(css.languagesList)}>
              {(form.getState().values.spokenLanguages || [])?.map((language) => (
                <span key={uuidv4()} className={css.languageBadge}>
                  {language.label}
                  <span
                    className={css.removeBadge}
                    onClick={() => handleRemoveLanguage(language)}
                  >
                    &times;
                  </span>
                </span>
              ))}
            </div>

            <button type='button' onClick={() => toggleDropdown()} className={css.addLanguagesButton}>
              <FormattedMessage id="EditListingDescriptionForm.addLanguagesButtonText" />
            </button>
            {isDropdownOpen ? (
              <OutsideClickHandler onOutsideClick={handleClearClick} className={css.languageOutsideContainer}>
                <div className={css.inputSelect}>
                  <Field name="searchKeyword">
                    {({ input }) => (
                      <div className={css.searchContainer}>
                        {/* <IconCollection icon='SearchIcon' /> */}
                        <input
                          {...input}
                          type="text"
                          autoComplete='off'
                          placeholder={languagePlaceholder}
                          onChange={(e) => {
                            input.onChange(e);
                            debouncedSearch(e.target.value);
                          }}
                          onKeyPress={handleLanguageKeyPress}
                          autoFocus
                        />

                        <button
                          type="button"
                          className={css.clearSearchButton}
                          onClick={() => handleClearClick()}
                        >
                          <IconCard brand={CROSS} />
                        </button>

                      </div>
                    )}
                  </Field>
                  {isUlVisible && searchResults?.length !== 0 && (
                    <ul className={css.languagesDropdownBox}>
                      {searchResults?.map(item => (
                        <li
                          className={values?.spokenLanguages?.some(lang => lang.label === item.name) ? css.selectedList : null}
                          key={item._id}
                          onClick={() => handleSelectLanguage(item)}
                        >
                          {item?.name}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              </OutsideClickHandler>
            ) : null}
              {checkLanguageArrayLength() && <h4 className={css.validationMessage}>{languageValidationMessage}</h4>}
            <div className={css.websiteInput}>
              <Field name="website">
                {({ input }) => (
                  <div className={css.searchContainer}>
                    <label>{websiteInputLabel}</label>
                    {/* <IconCollection icon='SearchIcon' /> */}
                    <input
                      {...input}
                      type="text"
                      autoComplete='off'
                      placeholder={websiteInputPlaceholder}
                      onChange={(e) => {
                        input.onChange(e);
                        debouncedSearch(e.target.value, WEBSITE);

                      }}
                    />
                    {websitePreviewResponse?.title ?
                      <div className={css.previewCard}>
                        <ExternalLink href={modifiedWebsiteUrl}>
                          <div className={css.previewCardContent}>
                            <h2 className={css.previewCardTitle}>
                              {websitePreviewResponse?.title}
                            </h2>
                            <p className={css.previewCardDescription}>
                              {websitePreviewResponse?.description}
                            </p>
                          </div>
                          {websitePreviewResponse?.image ?
                            <img
                              src={websitePreviewResponse?.image}
                              alt={websitePreviewResponse?.title}
                              className={css.previewCardImage}
                            />
                            : <></>}
                        </ExternalLink>
                      </div>
                      : null}
                  </div>
                )}
              </Field>
            </div>

            <div className={css.websiteInput}>

              <Field name="blogUrl" >
                {({ input }) => (
                  <div className={css.searchContainer}>
                    <label>{blogInputLabel}</label>
                    {/* <IconCollection icon='SearchIcon' /> */}
                    <input
                      {...input}
                      type="text"
                      autoComplete='off'
                      placeholder={blogInputPlaceholder}
                      onChange={(e) => {
                        input.onChange(e);
                        debouncedSearch(e.target.value, BLOG);

                      }}
                    />
                    {blogPreviewResponse?.title ?
                      <div className={css.previewCard}>
                        <ExternalLink href={modifiedBlogUrl}>
                          <div className={css.previewCardContent}>
                            <h2 className={css.previewCardTitle}>
                              {blogPreviewResponse?.title}
                            </h2>
                            <p className={css.previewCardDescription}>
                              {blogPreviewResponse?.description}
                            </p>
                          </div>
                          {blogPreviewResponse?.image ?
                            <img
                              src={blogPreviewResponse?.image}
                              alt={blogPreviewResponse?.title}
                              className={css.previewCardImage}
                            />
                            : <></>}
                        </ExternalLink>

                      </div>
                      : null}

                  </div>

                )}
              </Field>
            </div>
          </div>

          <Button
            className={css.submitButton}
            type="button"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
            onClick={handleSubmit}
          >
            {saveActionMsg}
          </Button>
        </Form>
      );
    }}
  />
);

EditListingDescriptionFormComponent.defaultProps = { className: null, fetchErrors: null };

EditListingDescriptionFormComponent.propTypes = {
  className: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    createListingDraftError: propTypes.error,
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  categories: arrayOf(
    shape({
      key: string.isRequired,
      label: string.isRequired,
    })
  ),
};

export default compose(injectIntl)(EditListingDescriptionFormComponent);
