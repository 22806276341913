import {  Form as FinalForm } from 'react-final-form';
import { FieldRadioButton, Form } from '../../components';
import { GENERATE_NOW, UPLOAD_NOW } from '../../util/types';
import css from './EditListingPhotosForm.module.css'
import { injectIntl, FormattedMessage } from '../../util/reactIntl';
import { compose } from 'redux';

const ImageSourceForm = (props) => {
  return (
    <FinalForm
      {...props}
      onSubmit={() => { }}
      render={renderProps => {
        const { handleSubmit, setSelectOption, form } = renderProps;

        return (
          <div style={{ marginBottom: '27px' }}>
            <Form onSubmit={handleSubmit}>
              <FieldRadioButton
                id="gallery"
                name="imageSource"
                label={'Upload your own image'}
                value="gallery"
                onChange={e => {
                  form.change('imageSource', 'gallery')
                  setSelectOption(UPLOAD_NOW)
                }}
              />
              <div className={css.openaiRadio}>
                <FieldRadioButton
                  id="openai"
                  name="imageSource"
                  label={'Use AI to generate an image'}
                  value="openai"
                  onChange={e => {
                    form.change('imageSource', 'openai')
                    setSelectOption(GENERATE_NOW)
                  }}
                />
                <span className={css.newText}><FormattedMessage id="EditListingPhotosForm.openAiNew" /></span>
              </div>
            </Form>
          </div>
        )
      }}
    />
  )
}

export default compose(injectIntl)(ImageSourceForm)