import React from 'react';
import css from '../LandingPageTabsPanel.module.css';
import { draftId, draftSlug } from '../../../routeConfiguration';
import { USER_ROLE_CLIENT, USER_ROLE_PARTNER } from '../../../util/types';
import { ExternalLink, IconCardHomePage, NamedLink } from '../..';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

const LandingPageFifthTab = props => {
  const { userRole, firmId, firmName, currentUser, handleAdminEmailModal, isApprovedUser,checkName } = props;
  const isPartner = userRole === USER_ROLE_PARTNER;

  const profileList = [
    {
      icon: <IconCardHomePage type="personalprofile" />,
      heading: isPartner ? 'Manage your personal profile on InsightGig' : "Update your personal profile",
      subHeading: "You have control over a variety of settings on your individual profile such as the display photo.",
      link: (
        <NamedLink className={classNames(css.linkName,(!isApprovedUser && currentUser) ? css.disabled :{})} name={checkName("ProfileSettingsPage")} >
          <FormattedMessage id="LandingPageTabsPanel.manageProfileLinkText" />
        </NamedLink>
      ),
      externalLink: (currentUser?.id ? (
        <ExternalLink href='https://docs.insightgig.com/updating-account-settings' className={classNames(css.questionMark,(!isApprovedUser && currentUser) ? css.disabled :{})}>
          <IconCardHomePage type="questionmark" />
        </ExternalLink> 
        ) : (
        <NamedLink name='LoginPage' className={classNames(css.questionMark,(!isApprovedUser && currentUser) ? css.disabled :{})} >
          <IconCardHomePage type="questionmark" />
        </NamedLink>
      )
      )
    },
    {
      icon: <IconCardHomePage type="managefirm" />,
      heading: "Manage your firm's profile on InsightGig",
      subHeading: "If you are an Admin of a firm on InsightGig, you can manage your firm account easily. Other members in the team also have access to some of the features.",
      link: (
        <NamedLink
          className={classNames(css.linkName,(!isApprovedUser && currentUser) ? css.disabled :{})}
          name={checkName("FirmDashBoardPage")}
          params={{
            id: firmId ? firmId : draftId,
            slug: firmName ? firmName.toLowerCase() : draftSlug,
          }}
        >
          <FormattedMessage id="LandingPageTabsPanel.firmProfileLinkText" />
        </NamedLink>
      ),
      externalLink: currentUser?.id ? (
        <ExternalLink className={classNames(css.questionMark,(!isApprovedUser && currentUser) ? css.disabled :{})} href="https://docs.insightgig.com/adding-a-firm" >
          <IconCardHomePage type="questionmark" />
        </ExternalLink>
      ) : (
        <NamedLink name='LoginPage' className={classNames(css.questionMark,(!isApprovedUser && currentUser) ? css.disabled :{})} >
          <IconCardHomePage type="questionmark" />
        </NamedLink>
      )
    },
    {
      icon: <IconCardHomePage type="dollar" />,
      heading: "Manage your payment details on InsightGig",
      subHeading: "You can set up or modify your online payout details on the platform.",
      link: (
        <NamedLink
          className={classNames(css.linkName,(!isApprovedUser && currentUser) ? css.disabled :{})}
          name={checkName(userRole === USER_ROLE_PARTNER ? 'StripePayoutPage' : 'PaymentMethodsPage')}
        >
          {userRole === USER_ROLE_PARTNER ? (
            <FormattedMessage id="LandingPageTabsPanel.managePayoutLinkText" />
          ) : (
            <FormattedMessage id="LandingPageTabsPanel.payoutDetailsLinkText" />
          )}
        </NamedLink>
      ),
      openModal: isApprovedUser && !!currentUser?.id,
      externalLink: currentUser?.id ? (
        <div className={classNames(css.questionMark,(!isApprovedUser && currentUser) ? css.disabled :{})}>
          <IconCardHomePage type="questionmark" />
        </div>
      ) : (
        <NamedLink name='LoginPage' className={classNames(css.questionMark,(!isApprovedUser && currentUser) ? css.disabled :{})} >
          <IconCardHomePage type="questionmark" />
        </NamedLink>
      )
    },
    !isPartner && {
      icon: <IconCardHomePage type="dollar" />,
      heading: "Manage your subscription plan on InsightGig",
      subHeading: "You can manage your subscription on the InsightGig platform easily from the Subscription section.",
      link: (
        <NamedLink className={classNames(css.linkName,(!isApprovedUser && currentUser) ? css.disabled :{})} name={checkName("StripeSubscriptionPage")}>
          <FormattedMessage id="LandingPageTabsPanel.subscriptionLinkText" />
        </NamedLink>
      ),
      openModal: isApprovedUser && !!currentUser?.id,
      externalLink: (
        <div className={classNames(css.questionMark,(!isApprovedUser && currentUser) ? css.disabled :{})}>
          <IconCardHomePage type="questionmark" />
        </div>
      )
    },
  ]

  return (
    <div className={css.updateProfile}>
      <div className={css.profileHeading}>
        <FormattedMessage id="LandingPage.updateYourProfile" />
      </div>
      {profileList?.map((item) => {
        if(!item) return
        const {heading, icon, subHeading, link, externalLink, openModal} = item;
        return (
          <div className={css.profileList} key={heading}>
            <div className={css.profileIcon}>{icon}</div>
            <div className={css.profileRight}>
              <div className={css.profileMainHeading}>{heading}</div>
              <div className={css.profileSubHeading}>{subHeading}</div>
              <div className={css.profileBottomLink}>
                {link}
                <span onClick={() => openModal && handleAdminEmailModal({cardTitle: heading, cardDescription: subHeading})}>
                  {externalLink}
                </span>
              </div>
            </div>
          </div>
        )
      })}
    </div>
  );
};

export default LandingPageFifthTab;
