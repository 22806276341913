import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from '../../util/reactIntl';
import {
  sendFirmInvite,
  sendFirmInviteClearSuccessToaster,
  updateMetadata,
  transferFirmRole,
  showListing,
  updateProfile,
  updateFirmAuthorData,
} from '../../containers/FirmPage/FirmPage.duck';
import { manageDisableScrolling } from '../../ducks/UI.duck';
import { InviteFirmMemberForm } from '../../forms';
import { ensureUser } from '../../util/data';
import {
  FIRM_INVITE,
  INVITE_STATUS_ACTIVE,
  INVITE_STATUS_EXPIRE,
  INVITE_STATUS_PENDING,
  MAX_COLLABORATORS_LIMIT,
  SMALL_TEAMS_MONTHLY,
  SMALL_TEAMS_YEARLY,
  USER_ROLE_CLIENT,
  USER_ROLE_CSM,
  USER_ROLE_EDITOR,
  USER_ROLE_OWNER,
  USER_ROLE_PARTNER,
  USER_TYPE_CLIENT,
} from '../../util/types';
import Modal from '../Modal/Modal';
import css from './FirmPanel.module.css';
import OrganizationMemberCard from './OrganizationMemberCard';
import { useHistory } from 'react-router-dom';
import { NamedLink, PrimaryButton, TosterMessage } from '../../components'
import { ordinal_suffix_of } from '../../util/typeHelpers'
import { getUserDetails} from '../../util/destructorHelpers';
import { getUserRole } from '../../util/userRole';
import { storeFirmDetailsinMongo, updateListingPublicData } from '../../util/api';


const FirmPanel = (props) => {
  const {currentListing} = props;
  const currentPath = typeof window !== 'undefined' && window.location.href;
  const dispatch = useDispatch();
  const [inviteModal, setInviteModal] = useState(false);
  const [inviteSuccessToaster, setInviteSuccessToaster] = useState(false);
  const [openMaxCollaboratorLimitModal, setOpenMaxCollaboratorLimitModal] = useState(false);
  const [stripeSubscriptionRedirectModal, setStripeSubscriptionRedirectModal] = useState(false);
  const [invitationExpiredModal, setInvitationExpiredModal] = useState(false);
  const [successToaster, setSuccessToaster] = useState(false);
  const [toasterMessage, setToasterMessage] = useState('');
  const { sendFirmInviteInProgress, id, invitationSent, addNewAuthorInProgress } = useSelector(state => state.FirmPage);
  const state = useSelector(state => state);
  const { currentUser = {}, currentUserListing } = state?.user;
  const currentUserListingId = currentUserListing?.id.uuid;
  const history = useHistory();

  const firmLogoId = currentListing && currentListing?.attributes?.publicData?.firmLogoId;
  const firmLogoUrl = currentListing?.images?.find(d => d?.id?.uuid === firmLogoId)?.attributes?.variants?.['square-small2x']?.url;

  const newAuthor = currentListing && currentListing?.attributes?.publicData?.newAuthor;
  const ownerNumber = currentListing && currentListing?.attributes?.publicData?.ownerType?.substr(0, 1);
  const { publicData = {}, title = '', metadata = {} } = !!currentListing.id && currentListing.attributes;

  const { profile = {} } = !!currentListing.id && newAuthor ? newAuthor?.attributes :
    !!currentListing?.author?.attributes && currentListing?.author?.attributes;

  const { firstName = '', lastName = '' } = profile;
  const email = profile?.publicData?.email || currentListing?.author?.attributes?.email;
  const firmAuthorId = !!currentListing.id && newAuthor ? newAuthor?.id : !!currentListing?.author?.attributes && currentListing?.author?.id.uuid;
  const firmData = Array.isArray(metadata?.firmData) ? metadata?.firmData : [];
  const currentAuthor = newAuthor ? newAuthor : currentListing.id && currentListing?.author;
  const ensuredAuthor = ensureUser(currentAuthor);
  const ensuredCurrentUser = ensureUser(currentUser);
  const authorName = ensuredAuthor?.attributes?.profile?.publicData?.fullName
    ? ensuredAuthor?.attributes?.profile?.publicData?.fullName.split(' ').at(0)
    : firstName;
  const isOwnListing = !!ensuredCurrentUser && !!ensuredAuthor && firmAuthorId === ensuredCurrentUser?.id?.uuid;
  const userLinkedToFirm =
    !!ensuredCurrentUser?.id && ensuredCurrentUser?.attributes?.profile?.publicData?.linkedToFirms || []

  const isUserAlreadyLinkedToFirm =
    Array.isArray(userLinkedToFirm) && !!userLinkedToFirm.find(firm => firm?.firmName === title);
  const userRole = isOwnListing ? USER_ROLE_OWNER : USER_ROLE_EDITOR;

  const authorType =
    !!ensuredAuthor?.id && ensuredAuthor?.attributes?.profile?.protectedData?.Role
      ? ensuredAuthor?.attributes?.profile?.protectedData?.Role
      : ensuredAuthor?.attributes?.profile?.publicData?.role;
  const userType = !!ensuredCurrentUser?.id && ensuredCurrentUser?.attributes?.profile?.protectedData?.Role;
  const inviteData = !!firmData?.length && firmData.find(data => data.email === ensuredCurrentUser.attributes.email);
  const isInviteAlreadyAccepted = currentListing.id && !!inviteData?.email && inviteData.status === INVITE_STATUS_ACTIVE;
  const isInviteAccepted = !!inviteData?.email && inviteData.status === INVITE_STATUS_ACTIVE;
  const isInviteExpired = !!inviteData?.invitationExpiredAt && moment().isAfter(moment(inviteData?.invitationExpiredAt));
  const currentUserGoogleDisplayPicture = currentUser?.attributes?.profile?.publicData?.picture;
  const currentUserDisplayPicture = currentUser?.profileImage?.attributes?.variants?.['square-small2x']?.url;
  const checkUserRole = currentUser?.id && getUserRole(currentUser);
  const isCsm = checkUserRole?.includes(USER_ROLE_CSM)

  useEffect(() => {
    isInviteExpired && setInvitationExpiredModal(true);

    const myTimeout = isInviteExpired && setTimeout(() => {
      setInvitationExpiredModal(false);
      history.push('/')
    }, 3000);

    return () => clearTimeout(myTimeout);
  }, [isInviteExpired])

  const firmDashboardObj = {
    firmDetails:{
      id: id?.uuid,
      firmName: title,
    },
    newFirmUserDetails:[{
      id: ensuredCurrentUser?.id?.uuid,
      name:getUserDetails(ensuredCurrentUser)?.fullName,
      email:getUserDetails(ensuredCurrentUser)?.email,
      profilePicture : currentUserDisplayPicture ?? currentUserGoogleDisplayPicture,
      joiningDate: moment().valueOf(),
      isAdmin : isOwnListing || isCsm? 1 : 0,
      isCsm ,
    }],
  }
  
  useEffect(() => {
    const index = Array.isArray(firmData) && !!firmData?.length &&
      firmData.findIndex(profile => profile?.email === inviteData?.email);

    if(!isOwnListing && firmData?.length && currentListing.id && !isInviteAlreadyAccepted){
      firmData.at(index).status = INVITE_STATUS_ACTIVE;
      firmData.at(index).userId = ensuredCurrentUser?.id?.uuid;
      firmData.at(index).fullName = ensuredCurrentUser?.attributes?.profile?.publicData?.fullName;
      firmData.at(index).profileImage = ensuredCurrentUser?.profileImage?.attributes?.variants?.['square-small2x']?.url ?? currentUserGoogleDisplayPicture;
      firmData.at(index).invitationExpiredAt = null;

      //To update Firm listing metadata 
      dispatch(updateMetadata(firmData, id));
      storeFirmDetailsinMongo({firmDashboardObj})

      //To update currentuser profile with linkedToFirm details
      const data = {
        firmId: id?.uuid,
        firmOwner: profile?.displayName,
        firmName: title,
        authorId: firmAuthorId,
      };
      userLinkedToFirm.push(data);
      const linkedToFirms = Array.from(new Set(userLinkedToFirm));
      dispatch(updateProfile({
        publicData: { linkedToFirms: linkedToFirms },
      }));
      //Store firmLogo in user listing publicData
      if(firmLogoUrl){
        updateListingPublicData({ id: currentUserListingId, publicData: {firmLogoUrl: firmLogoUrl} });
      }

      //Update FirmAuthor profile data 
      // dispatch(updateFirmAuthorData({authorId: firmAuthorId, numOfMembers: firmData?.length}))
    }

    if (isInviteExpired) {
      firmData.at(index).status = INVITE_STATUS_EXPIRE;
      dispatch(updateMetadata(firmData, id));
    }
  }, [isInviteAccepted, isInviteExpired,dispatch,currentUser?.id,firmData?.length]);

  useEffect(() => {
    if (invitationSent) {
      setInviteSuccessToaster(true);
      const timer = setTimeout(() => {
        setInviteSuccessToaster(false);
        dispatch(sendFirmInviteClearSuccessToaster());
      }, 3000);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [invitationSent]);
  
  // useEffect(()=>{
  //  if(ensuredAuthor?.id) {
  //    storeFirmDetailsinMongo({firmDashboardObj})
  //  }
  // },[dispatch,ensuredAuthor?.id])

  const handleTransferRole = async (firmUser, setConfirmModal) => {
    const params = {
      firmUser,
      selectedUserId: firmUser.userId,
      firmId: id,
      firmData,
      firmTitle: title,
      newOwnerNumber: ordinal_suffix_of(new Number(ownerNumber) + 1),
      authorName,
      ensuredCurrentUser,
    }
    const response = await dispatch(transferFirmRole(params));
    if (response.status) {
      dispatch(showListing(id))
      setConfirmModal(false);
      setSuccessToaster(true);
      setToasterMessage(`You have successfully transferred ownership to ${firmUser.fullName}`);
    }
  }

  async function handleSendInvitation(values){
    const { userEmail,memberRole } = values;
    const desiredDomain = 'insightgig.com';
    const isDesiredDomainMatch = userEmail?.endsWith('@' + desiredDomain);
    const params = {
      authorId: firmAuthorId,
      userEmail,
      authorEmail: email,
      authorName: authorName,
      firmName: title,
      currentPath,
      step:FIRM_INVITE,
      subject: `You have an invitation from  ${authorName} to join ${{firmName:title}}`
    };
    const isUserExist = firmData?.find(firm => firm.email === userEmail);
    if (!isUserExist) {
      firmData.push({
        fullName: userEmail.split('@')[0],
        email: userEmail?.toLowerCase(),
        status: INVITE_STATUS_PENDING,
        invitationExpiredAt: moment()
          .add(14, 'days')
          .format('YYYY-MM-DD HH:mm:ss'),
        isAdmin : isDesiredDomainMatch && memberRole === USER_ROLE_CSM ? true : false,
        isCsm : isDesiredDomainMatch && memberRole === USER_ROLE_CSM ? true : false,
        
      });
    }
    const response = await dispatch(sendFirmInvite({ ...params }, firmData, id));
    setInviteModal(false);
  }

  const handleOpenInviteModal = () => {
    if(firmData?.length > MAX_COLLABORATORS_LIMIT){
      setOpenMaxCollaboratorLimitModal(true)
      return
    }
    setInviteModal(true)
  }

  return (
    <div className={css.firmPanel}>
      <Modal
        id="InvitationExpiredModal"
        className={css.disableModalBorder}
        contentClassName={css.containerClassName}
        isOpen={invitationExpiredModal}
        onClose={() => history.push('/')}
        usePortal
        onManageDisableScrolling={(componentId, disableScrolling) =>
          dispatch(manageDisableScrolling(componentId, disableScrolling))
        }
      >
        <h2>Invitation Expired!!</h2>
      </Modal>

      {successToaster && 
        <TosterMessage
          message={toasterMessage}
          onClose={() => setSuccessToaster(false)}
        />
      }
      {inviteSuccessToaster ? (
        <TosterMessage
          message={"Invite sent successfully"}
          onClose={() => setSuccessToaster(false)}
        />
      ) : null}
      <OrganizationMemberCard
        isInviteModalOpen={handleOpenInviteModal}
        isOwnListing={isOwnListing}
        firmData={firmData}
        userRole={userRole}
        currentAuthor={ensuredAuthor}
        id={id}
        authorEmail={email}
        isUserAlreadyLinkedToFirm={isUserAlreadyLinkedToFirm}
        firmName={title}
        currentPath={currentPath}
        authorName={authorName}
        currentListing={currentListing}
        ownerNumber={ownerNumber}
        handleTransferRole={handleTransferRole}
        firmTitle={title}
        userType={userType}
        successToaster={successToaster}
        setSuccessToaster={setSuccessToaster}
        setToasterMessage={setToasterMessage}
        isCsm={isCsm}
        currentUser={currentUser}
      />
      <Modal
        id="FirmPanelInviteMemberModal"
        className={css.disableModalBorder}
        contentClassName={css.containerClassName}
        isOpen={inviteModal}
        onClose={() => setInviteModal(false)}
        usePortal
        onManageDisableScrolling={(componentId, disableScrolling) =>
          dispatch(manageDisableScrolling(componentId, disableScrolling))
        }
      >
        <InviteFirmMemberForm
          onSubmit={values => handleSendInvitation(values)}
          updateInProgress={sendFirmInviteInProgress}
          onCloseModal={() => setInviteModal(false)}
          isOpen={inviteModal}
          firmData={firmData}
          authorEmail={email}
          authorType={authorType}
          currentUser={ensuredCurrentUser}
          isCustomer={userType === USER_TYPE_CLIENT}
          isExpert={userType === USER_ROLE_PARTNER}
        />
      </Modal>
      <Modal
        id="maxCollaboratorLimitModal"
        className={css.disableModalBorder}
        contentClassName={css.containerClassName}
        isOpen={openMaxCollaboratorLimitModal}
        onClose={() => setOpenMaxCollaboratorLimitModal(false)}
        usePortal
        onManageDisableScrolling={(componentId, disableScrolling) =>
          dispatch(manageDisableScrolling(componentId, disableScrolling))
        }
      >
        <div className={css.alertModal}>
          <h2><FormattedMessage id="CollaborationPanel.maxCollaboratorsLimitHeading" /></h2>
          <p><FormattedMessage id="CollaborationPanel.maxCollaboratorsLimitMessage" /></p>
          <div className={css.buttonWrapper}>
            <PrimaryButton onClick={() => setOpenMaxCollaboratorLimitModal(false)}>
              Okay
            </PrimaryButton>
          </div>
        </div>
      </Modal>

      <Modal
        id="subsciptionRedirectModal"
        className={css.disableModalBorder}
        contentClassName={css.containerClassName}
        isOpen={stripeSubscriptionRedirectModal}
        onClose={() => setStripeSubscriptionRedirectModal(false)}
        usePortal
        onManageDisableScrolling={(componentId, disableScrolling) =>
          dispatch(manageDisableScrolling(componentId, disableScrolling))
        }
      >
        <p className={css.stripeSubscriptionRedirectModalHeading}>
          <FormattedMessage id="FirmPanel.stripeSubscriptionRedirectModalHeading" />
        </p>
        <NamedLink name="StripeSubscriptionPage" className={css.stripeSubscriptionRedirectModalButton}>
          <FormattedMessage id="FirmPanel.stripeSubscriptionRedirectModalButton" />
        </NamedLink>
      </Modal>
    </div >
  );
};

export default FirmPanel;
