import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Field, Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import * as validators from '../../util/validators';
import {
  Form,
  PrimaryButton,
  FieldTextInput,
  FieldRadioButton,
  FieldCheckbox,
  FieldSelect,
} from '../../components';

import css from './ConfirmSignupForm.module.css';
import IconCard, { BACK_ARROW } from '../../components/IconCard/IconCard';

const KEY_CODE_ENTER = 13;

const ConfirmSignupFormComponent = props => (
  <FinalForm
    {...props}
    render={formRenderProps => {
      const {
        rootClassName,
        className,
        formId,
        handleSubmit,
        inProgress,
        invalid,
        intl,
        onOpenTermsOfUse,
        authInfo,
        idp,
        values,
        form,
        handleNextStep,
        handlePreviousStep,
        currentStep,
        desiredDomains,
      } = formRenderProps;

      // email
      const emailLabel = intl.formatMessage({
        id: 'ConfirmSignupForm.emailLabel',
      });
      const emailPlaceholder = intl.formatMessage({
        id: 'ConfirmSignupForm.emailPlaceholder',
      });
      const emailRequiredMessage = intl.formatMessage({
        id: 'ConfirmSignupForm.emailRequired',
      });
      const emailRequired = validators.required(emailRequiredMessage);
      const emailInvalidMessage = intl.formatMessage({
        id: 'ConfirmSignupForm.emailInvalid',
      });
      const emailValid = validators.emailFormatValid(emailInvalidMessage);

      // firstName
      const firstNameLabel = intl.formatMessage({
        id: 'ConfirmSignupForm.firstNameLabel',
      });
      const firstNamePlaceholder = intl.formatMessage({
        id: 'ConfirmSignupForm.firstNamePlaceholder',
      });
      const firstNameRequiredMessage = intl.formatMessage({
        id: 'ConfirmSignupForm.firstNameRequired',
      });
      const firstNameRequired = validators.required(firstNameRequiredMessage);

      // lastName
      const lastNameLabel = intl.formatMessage({
        id: 'ConfirmSignupForm.lastNameLabel',
      });
      const lastNamePlaceholder = intl.formatMessage({
        id: 'ConfirmSignupForm.lastNamePlaceholder',
      });
      const lastNameRequiredMessage = intl.formatMessage({
        id: 'ConfirmSignupForm.lastNameRequired',
      });
      const lastNameRequired = validators.required(lastNameRequiredMessage);

      // Phone
      const phoneLabel = intl.formatMessage({
        id: 'ConfirmSignupForm.phoneLabel',
      });
      const phonePlaceholder = intl.formatMessage({
        id: 'ConfirmSignupForm.phonePlaceholder',
      });

      // Linkedin
      const linkedinLabel = intl.formatMessage({
        id: 'ConfirmSignupForm.linkedinLabel',
      });
      const linkedinPlaceholder = intl.formatMessage({
        id: 'ConfirmSignupForm.linkedinPlaceholder',
      });
      const finishSignupTitle = intl.formatMessage({
        id: 'ConfirmSignupForm.finishSignupTitle',
      });
      const confirmSignupTitle = intl.formatMessage({
        id: 'ConfirmSignupForm.confirmSignupTitle',
      });

      //Hear about us
      const hearAboutUsLabel = intl.formatMessage({
        id: 'ConfirmSignupForm.hearAboutUsLabel',
      });

      const businessColleagues = intl.formatMessage({
        id: 'ConfirmSignupForm.businessColleagues',
      });
      const friends = intl.formatMessage({
        id: 'ConfirmSignupForm.friends',
      });
      const internetSearch = intl.formatMessage({
        id: 'ConfirmSignupForm.internetSearch',
      });
      const other = intl.formatMessage({
        id: 'ConfirmSignupForm.other',
      });
      const linkedIn = intl.formatMessage({
        id: 'ConfirmSignupForm.linkedIn',
      });

      const callLabel = intl.formatMessage({
        id: 'ConfirmSignupForm.callLabel',
      });
      const messageLabel = intl.formatMessage({
        id: 'ConfirmSignupForm.messageLabel',
      });

      // Expert
      const clientLabel = intl.formatMessage({
        id: 'SignupForm.clientLabel',
      });
      const insightPartnerLabel = intl.formatMessage({
        id: 'SignupForm.insightPartnerLabel',
      });
      const csmLabel = intl.formatMessage({
        id: 'SignupForm.csmLabel',
      });
      const rolesLabel = intl.formatMessage({
        id: 'SignupForm.rolesLabel',
      });
      const RolesRequiredMessage = validators.required(
        intl.formatMessage({
          id: 'SignupForm.rolesRequired',
        })
      );

      const submitRole = values.Role;

      const classes = classNames(rootClassName || css.root, className);
      const submitInProgress = inProgress;
      const submitDisabled = invalid || submitInProgress || !submitRole;

      const handleTermsKeyUp = e => {
        // Allow click action with keyboard like with normal links
        if (e.keyCode === KEY_CODE_ENTER) {
          onOpenTermsOfUse();
        }
      };
      const termsLink = (
        <span
          className={css.termsLink}
          onClick={onOpenTermsOfUse}
          role="button"
          tabIndex="0"
          onKeyUp={handleTermsKeyUp}
        >
          <FormattedMessage id="ConfirmSignupForm.termsAndConditionsLinkText" />
        </span>
      );

      // If authInfo is not available we should not show the ConfirmForm
      if (!authInfo) {
        return;
      }

      // Initial values from idp provider
      const { email, firstName, lastName } = authInfo || {};
      const emailDomain = email?.split('@')[1];
      const options = [
        { id: 'businessColleagues', label: businessColleagues, value: businessColleagues },
        { id: 'friends', label: friends, value: friends },
        { id: 'linkedIn', label: linkedIn, value: linkedIn },
        { id: 'internetSearch', label: internetSearch, value: internetSearch },
        { id: 'other', label: other, value: other },
      ];
      return (
        <Form className={classes} onSubmit={{}}>
          <div>
            {/* {currentStep > 0 ? <div className={css.backArrow} onClick={() => handlePreviousStep(form)}>
              <IconCard brand={BACK_ARROW} />
            </div>
              : null} */}
            <div className={css.loginTopbar}>
              {currentStep > 0 ? (
                <div className={css.backArrow} onClick={() => handlePreviousStep(form)}>
                  <IconCard brand={BACK_ARROW} />
                </div>
              ) : null}
              <div className={css.loginTitle}>
                {currentStep === 0 ? confirmSignupTitle : finishSignupTitle}{' '}
              </div>
            </div>
            {currentStep === 0 ? (
              <div className={css.confirmSteps}>
                <FieldTextInput
                  className={css.password}
                  type="text"
                  id={formId ? `${formId}.firstName` : 'firstName'}
                  name="firstName"
                  autoComplete="given-name"
                  label={firstNameLabel}
                  placeholder={firstNamePlaceholder}
                  initialValue={firstName}
                  validate={firstNameRequired}
                />
                <FieldTextInput
                  className={css.password}
                  type="text"
                  id={formId ? `${formId}.lastName` : 'lastName'}
                  name="lastName"
                  autoComplete="family-name"
                  label={lastNameLabel}
                  placeholder={lastNamePlaceholder}
                  initialValue={lastName}
                  validate={lastNameRequired}
                />
                <FieldTextInput
                  className={css.password}
                  type="email"
                  id={formId ? `${formId}.email` : 'email'}
                  name="email"
                  autoComplete="email"
                  label={emailLabel}
                  placeholder={emailPlaceholder}
                  initialValue={email}
                  validate={validators.composeValidators(emailRequired, emailValid)}
                />
                <div className={css.googleText}>
                  <FormattedMessage id="ConfirmSignupForm.comeFromGoogle" />
                </div>
                <div className={css.roleWrapper}>
                  <label className={css.roleLabel}>{rolesLabel}</label>
                  <div className={css.roleRadioBox}>
                    <span className={css.tooltip}>
                      <FieldRadioButton
                        id="client"
                        name="Role"
                        // label={clientLabel}
                        value="client"
                        showAsRequired={RolesRequiredMessage}
                      />
                      <label
                        htmlFor="client"
                        className={css.tooltipText}
                        data-tooltip={'Choose this if you require insight related services.'}
                      >
                        {clientLabel}
                      </label>
                    </span>
                    <span className={css.tooltip}>
                      <FieldRadioButton
                        id="partner"
                        name="Role"
                        // label={insightPartnerLabel}
                        value="partner"
                        showAsRequired={RolesRequiredMessage}
                      />
                      <label
                        htmlFor="partner"
                        className={css.tooltipText}
                        data-tooltip={'Choose this if you provide insight related service.'}
                      >
                        {insightPartnerLabel}
                      </label>
                    </span>

                    {desiredDomains.includes(emailDomain) ? (
                      <FieldRadioButton
                        id="csm"
                        name="Role"
                        label={csmLabel}
                        value="csm"
                        showAsRequired={RolesRequiredMessage}
                      />
                    ) : null}
                  </div>
                </div>
                <PrimaryButton
                  type="button"
                  inProgress={submitInProgress}
                  disabled={submitDisabled}
                  onClick={handleNextStep}
                >
                  <FormattedMessage id="ConfirmSignupForm.continueButtonText" />
                </PrimaryButton>
              </div>
            ) : currentStep === 1 ? (
              <div>
                <FieldTextInput
                  className={css.password}
                  type="number"
                  id={formId ? `${formId}.mobileNumber` : 'mobileNumber'}
                  name="mobileNumber"
                  label={phoneLabel}
                  placeholder={phonePlaceholder}
                  // validate={lastNameRequired}
                />
                {!values?.mobileNumber ? null : (
                  <div className={css.contactPreference}>
                    <h3>
                      <FormattedMessage id="ConfirmSignupForm.contactPreference" />
                    </h3>
                    <div className={css.contactCheck}>
                      <FieldCheckbox
                        id="call"
                        name="call"
                        value="call"
                        label={callLabel}
                        // validate={privacyRequired}
                      />
                      <FieldCheckbox
                        id="message"
                        name="message"
                        value="message"
                        label={messageLabel}
                        // validate={privacyRequired}
                      />
                    </div>
                  </div>
                )}
                <FieldTextInput
                  className={css.password}
                  type="text"
                  id={formId ? `${formId}.linkedinLink` : 'linkedinLink'}
                  name="linkedinLink"
                  label={linkedinLabel}
                  placeholder={linkedinPlaceholder}
                  // validate={lastNameRequired}
                />

                <FieldSelect
                  className={css.hearAboutUs}
                  id="hearAboutUsFrom"
                  name="hearAboutUsFrom"
                  type="radio"
                  label={hearAboutUsLabel}
                >
                  <option value="" selected disabled>
                    Select
                  </option>
                  {options &&
                    options.map(c => (
                      <option key={c.id} value={c.label}>
                        {c.label}
                      </option>
                    ))}
                </FieldSelect>
                <div className={css.bottomWrapper}>
                  <p className={css.bottomWrapperText}>
                    <span className={css.termsText}>
                      <FormattedMessage
                        id="ConfirmSignupForm.termsAndConditionsAcceptText"
                        values={{ termsLink }}
                      />
                    </span>
                  </p>
                </div>
                <PrimaryButton
                  type="button"
                  inProgress={submitInProgress}
                  disabled={submitDisabled}
                  onClick={handleSubmit}
                >
                  <FormattedMessage id="ConfirmSignupForm.signUp" values={{ idp: idp }} />
                </PrimaryButton>
              </div>
            ) : null}
          </div>
        </Form>
      );
    }}
  />
);

ConfirmSignupFormComponent.defaultProps = { inProgress: false };

const { bool, func } = PropTypes;

ConfirmSignupFormComponent.propTypes = {
  inProgress: bool,

  onOpenTermsOfUse: func.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const ConfirmSignupForm = compose(injectIntl)(ConfirmSignupFormComponent);
ConfirmSignupForm.displayName = 'ConfirmSignupForm';

export default ConfirmSignupForm;
