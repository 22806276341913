import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import css from './AddFileLinks.module.css';
import { compose } from 'redux';
import { withViewport } from '../../util/contextHelpers';
const { array, 
  // node, 
  string } = PropTypes; 

import { Document, Page, pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const AddFileLinks = props => {
  const {
    className,
    linkClassName,
    fileLinks,
    viewport,
    onRemoveFileLinks,
  } = props;

  const classes = classNames(css.root, className);

  return (
    <div className={classes}>
      {fileLinks && fileLinks.map((file, index) => {
        return (
          <div className={css.container} index={index} key={(file.type || file.id) + index}>
            <div className={css.certificateImage}>
              {file.link && file.link.split('.')[file.link.split('.').length - 1] == 'pdf' ? (
                <Document
                  className={css.pdfContainer}
                  file={file.link}
                  onLoadError={e => console.log("pdf loading error", e)}
                  loading="loading file"
                >
                  <Page pageNumber={1} width={viewport.width >= 1280 ?
                    400 : 300} />
                </Document> 
              ) : (
                <img src={file.link} alt="certificate image" />
              )}
              <a
                className={classNames(linkClassName, css.overlayContainer)}
                href={file.link}
                target="default"
              >
                View
              </a>
            </div>
                <h2 className={css.title}>{file.type}</h2>
                <div
                  onClick={e => {
                    e.stopPropagation();
                    onRemoveFileLinks(index, fileLinks);
                  }}
                >
                  <img src="https://img.icons8.com/cotton/44/000000/delete-sign--v2.png"/>
                </div>
          </div>
        );
      })}
    </div>
  );
};

AddFileLinks.defaultProps = { className: null, fileClassName: null, fileLinks: [] };

AddFileLinks.propTypes = {
  fileLinks: array,
  // children: node.isRequired,
  className: string,
  fileClassName: string,
  // onRemoveFileLinks: func.isRequired,
};

const AddFileLinksComponent = compose(withViewport)(AddFileLinks);

export default AddFileLinksComponent;
