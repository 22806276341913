import React, { useEffect, useState } from 'react';
import { func } from 'prop-types';
import { compose } from 'redux';
import { Field, Form as FinalForm } from 'react-final-form';
// These relative imports need to point to correct directories
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import {
    Form,
    FieldTextInput,
    OutsideClickHandler,
    FieldSelect,
    PrimaryButton,
} from '../../components';

// as a template.
import css from './MilestoneForm.module.css';
import * as validators from '../../util/validators';
import classNames from 'classnames';
import moment from 'moment';
import { truncateOptionText } from '../../util/destructorHelpers';
import { SingleDatePicker } from 'react-dates';
// import 'react-dates/initialize';
// import 'react-dates/lib/css/_datepicker.css';
import './datepicker.css';
import { CUSTOMER_INVITEE } from '../../util/types';

export const MilestoneForm = props => {
    return (
        <FinalForm
            {...props}
            render={formRenderProps => {
                const {
                    className,
                    disabled,
                    handleSubmit,
                    intl,
                    invalid,
                    updateInProgress,
                    milestones,
                    values,
                    form,
                    actionInProgress,
                    collaborationMetaData,
                    dateFormat,
                    editedMilestoneId,
                    currentUserObject,
                } = formRenderProps;
                const filterCollaboratorMetaData = !!collaborationMetaData?.length && collaborationMetaData.filter(data => data.invitedByUserRole !== CUSTOMER_INVITEE)|| [];
                const [focused, setFocused] = useState(false);
                const [dependentDatePopup, setDependentPopup] = useState(false);

                const filteredMilestones =
                    !!milestones?.length &&
                    !!values.date &&
                    milestones.filter(milestone => {
                        const milestoneDate = moment.unix(milestone?.date)
                        return milestone.id !== values?.id && milestoneDate < moment.unix(values.date)
                    });
                const [isOpen, setIsOpen] = useState(false);
                const [selectedOption, setSelectedOption] = useState(null);
                // title

                const milestoneTitle = intl.formatMessage({ id: 'TransactionPanel.milestoneTitle' });
                const milestoneTitlePlaceholder = intl.formatMessage({
                    id: 'TransactionPanel.milestoneTitlePlaceholder',
                });

                // milestoneDescription

                const milestoneDescription = intl.formatMessage({
                    id: 'TransactionPanel.milestoneDescription',
                });
                const milestoneDescriptionPlaceholder = intl.formatMessage({
                    id: 'TransactionPanel.milestoneDescriptionPlaceholder',
                });

                // Owner
                const ownerLabel = intl.formatMessage({ id: 'TransactionPanel.ownerLabel' });
                const ownerPlaceholder = intl.formatMessage({ id: 'TransactionPanel.ownerPlaceholder' });

                // Dependencies
                const dependencyLabel = intl.formatMessage({ id: 'TransactionPanel.dependencyLabel' });
                const dependencyPlaceholder = intl.formatMessage({
                    id: 'TransactionPanel.dependencyPlaceholder',
                });
                const dependencyNoteLabel = intl.formatMessage({
                    id: 'TransactionPanel.dependencyNoteLabel',
                });
                const dependencyNoteDescription = intl.formatMessage({
                    id: 'TransactionPanel.dependencyNoteDescription',
                });

                // date

                const dateTitle = intl.formatMessage({ id: 'TransactionPanel.dateTitle' });
                const dateRequired = intl.formatMessage({ id: 'TransactionPanel.dateRequired' });
                const classes = classNames(css.root, className);
                const [duplicateTitle, setDuplicateTitle] = useState(false);

                useEffect(() => {
                    const duplicateMilestone = milestones?.find(el => el.title === values.title);
                    if (duplicateMilestone?.id && values?.id !== duplicateMilestone.id) {
                        setDuplicateTitle(true);
                    } else setDuplicateTitle(false);
                }, [values.title]);

                useEffect(() => {
                    setDuplicateTitle(false);
                }, []);
                const submitDisabled =
                    invalid || disabled || updateInProgress || duplicateTitle || actionInProgress;

                const handleOptionClick = option => {
                    if (option?.collaboratorID) {
                        const ownerInfo = {
                            profileImage: option?.profileImage,
                            id: option?.collaboratorID,
                            fullName: option?.fullName,
                        };
                        form.change('selectOwner', ownerInfo);
                        setIsOpen(false);
                    }
                };
                const CustomDropdown = ({ options, placeholder }) => {
                    return (
                        <div className={css.customDropDown}>
                            <div
                                className={` ${isOpen ? css.customDropDownInput : css.customDropDownInput}`}
                                onClick={() => setIsOpen(!isOpen)}
                            >
                                {values?.selectOwner?.profileImage ? (
                                    <img
                                        src={values?.selectOwner?.profileImage}
                                        alt={'profileImage'}
                                        className={css.profileImage}
                                    />
                                ) : !!values?.selectOwner?.fullName ? (
                                    <div className={css.noImageWithText}>
                                        {!!values?.selectOwner?.fullName &&
                                            values?.selectOwner?.fullName
                                                .match(/(\b\S)?/g)
                                                .join('')
                                                .toUpperCase()}
                                    </div>
                                ) : null}
                                <span className={css.fullNameOwner}>
                                    {values?.selectOwner ? values?.selectOwner?.fullName : ownerPlaceholder}
                                </span>
                                <span className={!isOpen ? css.upArrow : css.downArrow}>
                                </span>
                            </div>
                            {isOpen && (
                                <ul className={css.customDropDownOptions}>
                                    {[...filterCollaboratorMetaData, currentUserObject]?.map(option => (
                                        <li
                                            key={option.collaboratorID}
                                            className={selectedOption === option ? css.active : ''}
                                            onClick={() => handleOptionClick(option)}
                                        >
                                            {option?.profileImage ? (
                                                <img
                                                    src={option?.profileImage}
                                                    alt={'profileImage'}
                                                    className={css.profileImage}
                                                />
                                            ) : (
                                                <div className={css.noImageWithText}>
                                                    {!!option?.fullName &&
                                                        option?.fullName
                                                            .match(/(\b\S)?/g)
                                                            .join('')
                                                            .toUpperCase()}
                                                </div>
                                            )}
                                            <div className={css.fullName}>{option?.fullName}</div>
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </div>
                    );
                };

                const renderDatePicker = ({ input }) => {

                    const selectedDate = input.value ? moment.unix(input.value) : null;
                    const handleDateChange = newDate => {

                        const currentMilestoneDate = newDate ? newDate.startOf('day').unix() : null;

                        // Check if the current milestone date is valid
                        if (
                            currentMilestoneDate &&
                            milestones.some(milestone => milestone.date && moment(milestone.date).startOf('day').unix() > values.date)
                        ) {
                            form.change('date', currentMilestoneDate)
                            // input.onChange(currentMilestoneDate);
                        } else {
                            // input.onChange(currentMilestoneDate);
                            form.change('date', currentMilestoneDate)

                        }
                    };

                    const handleFocusChange = ({ focused }) => {
                        setFocused(focused);
                    };

                    const isDateDisabled = date => {
                        const dependentMilestone = milestones?.find(milestone => milestone.dependency && JSON.parse(milestone.dependency).id === values.id);
                        if (!dependentMilestone || !dependentMilestone.date) {
                            // Handle the case where dependentMilestone or its date is undefined/null
                            return false;
                        }
                        const dependentMilestoneDate = moment(dependentMilestone.date * 1000).startOf('day');
                        const selectedDate = moment(date).startOf('day');

                        if (selectedDate.isAfter(dependentMilestoneDate)) {
                            // Disable all dates after the dependent milestone date
                            setDependentPopup(true);
                            return true;
                        }
                        // Enable all other dates
                        return false;
                    };

                    return (
                        <div className={!values?.date ? css.blankDate : css.fillDate}>
                            <label htmlFor={dateTitle}>{dateTitle}</label>
                            <div className={css.pickerBox}>
                                <SingleDatePicker
                                    date={selectedDate}
                                    onDateChange={handleDateChange}
                                    focused={focused}
                                    onFocusChange={handleFocusChange}
                                    id={dateTitle}
                                    numberOfMonths={1}
                                    label={dateTitle}
                                    placeholder={moment().format(dateFormat)}
                                    isDayBlocked={date => isDateDisabled(date)}
                                    readOnly={true}
                                    hideKeyboardShortcutsPanel={true}
                                    displayFormat={dateFormat}
                                />
                            </div>
                        </div>
                    );
                };
                // const customDateInput
                return (
                    <Form className={classes} onSubmit={handleSubmit}>
                        <div className={css.wrapperModalAddMilestone}>
                            {dependentDatePopup ? <div className={css.warningAlert}>
                                <FormattedMessage id="TransactionPanel.dependentPopupMessage" />
                            </div> : null}
                            <div className={css.titleInputBox}>
                                <FieldTextInput
                                    id="title"
                                    name="title"
                                    // className={css.milestoneDescription}
                                    type="text"
                                    maxLength={80}
                                    label={milestoneTitle}
                                    placeholder={milestoneTitlePlaceholder}
                                    validate={validators.required(milestoneTitlePlaceholder)}
                                    disabled={actionInProgress}
                                />
                            </div>
                            <FieldTextInput
                                id="description"
                                name="description"
                                className={css.fillDescription}
                                type="textarea"
                                maxLength={250}
                                label={milestoneDescription}
                                placeholder={milestoneDescriptionPlaceholder}
                                disabled={actionInProgress}
                            />
                            <OutsideClickHandler
                                onOutsideClick={() => setIsOpen(false)}
                                className={
                                    !values?.selectOwner?.fullName?.length > 0 ? css.blankDropdown : css.dropDownBox
                                }
                            >
                                <label htmlFor="ownerLabel">{ownerLabel}</label>
                                <Field
                                    className={css.selectOwnerDropdown}
                                    name="selectOwner"
                                    id="selectOwner"
                                    placeholder={ownerPlaceholder}
                                    component={CustomDropdown}
                                />
                            </OutsideClickHandler>

                            <Field
                                name="date"
                                component={renderDatePicker}
                                validate={validators.required(dateRequired)}
                            />

                            {/* <Field name="selected" defaultValue={'false'} component={props => <input {...props} type={"hidden"} />} />
                            <Field name="id" defaultValue={''} component={props => <input {...props} type={"hidden"} />} /> */}
                            <FieldSelect
                                name="dependency"
                                id="dependency"
                                className={!values.dependency > 0 ? css.dateBox : css.fillDate}
                                label={dependencyLabel}
                            >
                                <option value="" disabled hidden>
                                    {dependencyPlaceholder}
                                </option>
                                {filteredMilestones?.length &&
                                    filteredMilestones?.map(milestone => (
                                        <option key={milestone?.id} value={JSON.stringify(milestone)}>
                                            {truncateOptionText(milestone?.title)}
                                        </option>
                                    ))}
                            </FieldSelect>
                            <p className={css.dependencyNote}>
                                {dependencyNoteLabel}
                                {dependencyNoteDescription}
                            </p>
                        </div>

                        {duplicateTitle && (
                            <p className={css.errorTitle}>
                                <FormattedMessage id="TransactionPanel.errorDuplicateTitle" />
                            </p>
                        )}

                        <PrimaryButton
                            className={css.addMilestoneButton}
                            type="submit"
                            inProgress={updateInProgress}
                            disabled={submitDisabled}
                        >
                            {editedMilestoneId ? (
                                <FormattedMessage id="TransactionPanel.saveMilestone" />
                            ) : (
                                <FormattedMessage id="TransactionPanel.addMilestone" />
                            )}
                        </PrimaryButton>
                    </Form>
                );
            }}
        />
    );
};

MilestoneForm.defaultProps = {
    selectedPlace: null,
    updateError: null,
};

MilestoneForm.propTypes = {
    intl: intlShape.isRequired,
    onSubmit: func.isRequired,
    //   updateInProgress: bool.isRequired,
};

export default compose(injectIntl)(MilestoneForm);
