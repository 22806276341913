import React from 'react';
import { string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { NamedLink } from '../../components';
import tablet from './tablet.png'
import css from './SectionHero.module.css';
import { getUserDetails } from '../../util/destructorHelpers';

const SectionHero = props => {
  const { rootClassName, className,currentUser } = props;

  const classes = classNames(rootClassName || css.root, className);
const userFirstName = currentUser?.id && getUserDetails(currentUser)?.firstName;
  return (
      <div className={css.heroContentText}>
        <h1 className={css.heroMainTitle}>
          <FormattedMessage id="SectionHero.title" values={{userFirstName}}/>
        </h1>
        <p className={css.heroSubTitle}>
          <FormattedMessage id="SectionHero.subTitle" />
        </p>
    </div>
  );
};

SectionHero.defaultProps = { rootClassName: null, className: null };

SectionHero.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionHero;
