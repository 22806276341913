import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './NonDisclosureAgreement.module.css';

const NonDisclosureAgreement = props => {
  const { rootClassName, className } = props;
  console.log(props, 'props');
  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      {/* <p className={css.lastUpdated}>Last updated: January 1, 2022</p> */}
      <p>
        <strong>THIS CONFIDENTIALITY AND NON-DISCLOSURE AGREEMENT (“Agreement”)</strong> is with
        respect to obligations of confidentiality and non-disclosure of Confidential Information to
        the extent provided by a user of www.insightgig.com and/or all affiliated websites,
        applications, mobile websites and mobile application (collectively{' '}
        <strong>“Platform”</strong> and such user of the Platform hereinafter referred to as{' '}
        <strong>“User”</strong>) to another User, for the purpose of entering into a business
        relationship for availing professional services (the <strong>“Purpose”</strong>). The Users
        shall be collectively referred to as <strong>“Parties”</strong> and individually as{' '}
        <storng>“Party”</storng>.
        <p>
          This document is an electronic record in terms of Information Technology Act, 2000 and
          rules there under as applicable and the amended provisions pertaining to electronic
          records in various statutes as amended by the Information Technology Act, 2000 (together
          with any statutory amendments or modifications thereof). This electronic record is
          generated by a computer system and does not require any physical or digital signatures.
        </p>
      </p>

      <ol>
        <li>
          <h2 className={css.subTitle}>Definitions. For the purpose of this Agreement:</h2>
          <p>
          1.1. <strong>“Confidential Information”</strong> means any data or information, tangible or
            intangible, that is of value to the Disclosing Party and is not generally known in the
            industry or to competitors of the Disclosing Party. Confidential Information shall
            include: (i) tangible information, marked by the Disclosing Party with the word
            “Confidential” or otherwise identified by an appropriate stamp or legend indicating its
            confidential nature; (ii) Confidential Information disclosed orally or visually and
            identified by the Disclosing Party as confidential when disclosed, and confirmed by the
            Disclosing Party in a written notice within 30 (thirty) days following disclosure, which
            notice shall include markings similar to those outlined in (i) above; and (iii) all
            other information that, notwithstanding the absence of markings or designations, would
            be understood by the Parties, exercising reasonable business judgment, to be
            confidential. Confidential Information further includes, but is not limited to: (a)
            technical, financial and business information and models, information relevant to the
            current or proposed business plans of the Disclosing Party, reports, market projections,
            analyses, working papers, comparisons, studies, or other documents which contain such
            information; (b) information disclosed either directly, in oral or tangible form
            (including but not limited to, magnetic or electronic form), or indirectly, by
            permitting the Receiving Party or its employees to observe various operations or
            processes conducted by the Disclosing Party; (c) information of the Disclosing Party’s
            Representatives; and (d) any material or documents prepared by the Receiving Party that
            is based on or contains any information disclosed by the Disclosing Party.
          </p>
          <p>
            1.2. <strong>“Disclosing Party”</strong> means a Party to this Agreement when it
            provides Confidential Information.
          </p>
          <p>
            1.3. <strong>“Receiving Party”</strong> means a Party to this Agreement when it receives
            Confidential Information.
          </p>
          <p>
            1.4. <strong>“Representatives”</strong> of a Party means the directors, officers,
            employees, consultants and agents of a Party and employees of an affiliate of that
            Party.
          </p>
          <p>
            1.5. <strong>“Unauthorized Access”</strong> means any access to or disclosure or use of
            any of Disclosing Party’s Confidential Information in violation of this Agreement or
            applicable law.
          </p>
          <p>
            1.6.The term “Confidential Information” shall not include information, technical data,
            or know-how that (i) the Receiving Party can demonstrably prove was in its possession
            prior to the time of the disclosure by the Disclosing Party or (ii) is currently in the
            public domain or (iii) subsequently becomes part of the public domain by any means
            except an unauthorized act or omission or breach of this Agreement on the part of
            Receiving Party, its employees, or agents or (iv) is received from a third Party without
            similar restrictions and without breach of the Agreement, or (v) is approved for release
            by written authorization of the Disclosing Party subsequent to the Agreement, or (vi) is
            independently developed by Receiving Party without the use of any Confidential
            Information.
          </p>
        </li>
        <li>
          <p>
            <strong>Non-Disclosure of Confidential Information.</strong>Receiving Party shall (a)
            hold all: ; Confidential Information of Disclosing Party in the strictest confidence,
            and protect it in accordance with a standard of care which shall be no less than the
            care it uses to protect its own information of like importance but in no event with less
            than reasonable care; (b) implement and maintain appropriate security measures to
            safeguard Disclosing Party’s Confidential Information; (c) not use, copy or disclose or
            permit any Unauthorized Access to Disclosing Party’s Confidential Information to any
            person or entity except Receiving Party’s Representatives on a need-to-know basis.
            Provided however that, Receiving Party shall be responsible for any breach of this
            Agreement by its Representatives; and (d) not use Disclosing Party’s Confidential
            Information for any purpose other than that for which it is disclosed.
          </p>
        </li>
        <li>
          <p>
            <strong>Legally Compelled Disclosure.</strong>Except as otherwise provided in this
            Agreement, the Receiving Party shall not disclose the Confidential Information of the
            Disclosing Party except where (i) the disclosure is made pursuant to a directive or
            order of a government entity or statutory authority or any judicial or governmental
            agency provided however that the Receiving Party shall promptly notify the Disclosing
            Party so as to enable the Disclosing Party to seek a protective order or other
            appropriate remedy; (ii) the disclosure is made to any arbitrator or expert appointed to
            resolve disputes under any final agreement entered into between the Parties on the basis
            of the negotiations and discussions in respect of the Transaction; or (iii) the
            disclosure is made pursuant to any applicable laws, rules or regulations or direction of
            statutory or regulatory authority or stock exchange or order or a relevant court of law.
            The Receiving Party shall inform the Disclosing Party of any disclosure to third
            Parties, as above, prior to any such disclosure.
          </p>
        </li>
        <li>
          <p>
            <strong>Ownership of Confidential Information.</strong> All Confidential Information and
            any derivative thereof remains the property of Disclosing Party and no license,
            intellectual property rights or other rights to Confidential Information are granted or
            implied hereby other than as set forth in this Agreement. Confidential Information
            disclosed by Disclosing Party hereunder may include Confidential Information of a third
            Party and, in such event this Agreement shall apply equally to any third Party
            Confidential Information and shall inure to the benefit of such third Party.
            Notwithstanding anything contained herein, the Parties explicitly acknowledge and agree
            that in the event the Disclosing Party is a User intending to commission the services of
            an Insight Partner through the Platform (“Client”), then the ownership and intellectual
            property rights or other rights to all information, insights, raw data, etc. provided by
            the Client to the Insight Partner shall solely belong to the Client. Further, the Client
            acknowledges that a User providing freelancing services to a Client through the Platform
            (“Insight Partner”), may as part of provided freelancing services use analytical
            framework, business methods, ideas, concepts, designs, techniques, inventions,
            procedures, methods, utilities, tools, discoveries or improvements that are conceived of
            or reduced to practice by Insight Partner or by one or more of its employees or agents,
            and all pre-existing computer programs, documents and coding, in each case, whether
            patentable or not, which shall be considered as intellectual property of the Insight
            Partner and the Insight Partner shall retain sole and exclusive ownership thereof.
          </p>
        </li>
        <li>
          <p>
            <strong>Return of Materials.</strong> All documents (whether in physical or electronic
            mode) and other tangible objects containing or representing Confidential Information
            that have been disclosed by Disclosing Party to Receiving Party, and all copies or
            extracts thereof or notes derived therefrom that are in the possession of Receiving
            Party, shall be and remain the property of Disclosing Party and shall be promptly
            returned to Disclosing Party or destroyed (with proof of such destruction), each upon
            Disclosing Party’s written request. Notwithstanding the preceding sentence, Receiving
            Party may retain one copy of Confidential Information for record retention purposes and
            as required under applicable law only and such retained Confidential Information shall
            continue to be subject to the obligations herein.
          </p>
        </li>
        <li>
          <p>
            <strong>Injunctive Relief.</strong> Receiving Party hereby acknowledges that
            unauthorized disclosure or use of the Confidential Information could cause Disclosing
            Party irreparable harm and significant injury that may be difficult to ascertain and for
            which Disclosing Party would not have an adequate remedy in monetary damages.
            Accordingly, Receiving Party agrees that Disclosing Party would have the right to seek
            and obtain immediate injunctive relief to enforce obligations under this Agreement in
            addition to any other rights and remedies Disclosing Party may have under applicable
            laws.
          </p>
        </li>
        <li>
          <p>
            <strong>No Warranty.</strong> ALL CONFIDENTIAL INFORMATION IS PROVIDED “AS IS.” NEITHER
            PARTY MAKES ANY WARRANTIES, EXPRESS, IMPLIED OR OTHERWISE, REGARDING THE ACCURACY,
            COMPLETENESS OR PERFORMANCE OF ANY CONFIDENTIAL INFORMATION, OR WITH RESPECT TO
            NON-INFRINGEMENT OR OTHER VIOLATION OF ANY INTELLECTUAL PROPERTY RIGHTS OF A THIRD PARTY
            OR OF RECEIVING PARTY.
          </p>
        </li>
        <li>
          <strong>Governing Law.</strong>This Agreement is made under and shall be construed
          according to the laws of [●], without regard to its conflict of law rules, and the Parties
          agree that it is subject to the jurisdiction of the courts in [●] should any action be
          brought to enforce or interpret its terms.
        </li>
        <li>
          <h4>Miscellaneous.</h4>
          <p>
            9.1. <strong>No Publicity.</strong>This Agreement is delivered upon the express
            condition that neither Party hereto will make any disclosures about the existence or
            contents of this Agreement or cause to be publicized in any manner whatsoever by way of
            interviews, responses to questions or inquiries, press releases or otherwise, any aspect
            or proposed aspect of a transaction between the Parties without prior notice to and
            approval of the other Party hereto, except as may otherwise be required by law.
          </p>
          <p>
            9.2. <strong>Indemnity.</strong> In the event that either Party breaches any obligation
            or representation under this Agreement, such Party shall indemnify and hold harmless the
            other Party from any loss, liability, damage, or expense (including reasonable
            attorneys’ fees and expenses) which such other Party may incur as a result of such
            breach.
          </p>
          <p>
            9.3. <strong>Assignments.</strong> Neither Party shall assign or transfer this Agreement
            without the prior written consent of the other Party.
          </p>
          <p>
            9.4. <strong>Waiver.</strong> Waiver of any provision in this Agreement in any instance
            shall not be construed as a waiver in any other instance. This Agreement, or any
            provision hereof, shall not be waived, changed, or terminated except by a writing signed
            by an authorized officer of both Parties.
          </p>
          <p>
            9.5. <strong>Relationship of the Parties.</strong> The Parties hereto are unrelated in
            any way, and nothing herein shall be construed to create an agency, joint venture,
            partnership, or other form of business association between the Parties hereto.
          </p>
          <p>
            9.6. <strong>Entire Agreement.</strong>This Agreement sets forth the entire agreement
            with respect to the Confidential Information disclosed and supersedes all prior or
            contemporaneous, oral, or written agreements concerning such Confidential Information.
            This Agreement may not be amended except by the written agreement signed by authorized
            representatives of both Parties.
          </p>
        </li>
      </ol>
    </div>
  );
};

NonDisclosureAgreement.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

NonDisclosureAgreement.propTypes = {
  rootClassName: string,
  className: string,
};

export default NonDisclosureAgreement;
