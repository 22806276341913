import React, { useEffect } from 'react'
import { BriefShareForm } from '../../forms'
import css from './CustomBriefShareModal.module.css'
const CustomBriefShareModal = (props) => {
    const { onSubmit, firmData, updateInProgress, onClose, shareBriefModalState ,isCsm} = props;
    useEffect(() => {
        const preventScroll = (event) => {
            if (shareBriefModalState) {
                event.preventDefault();
            }
        };

        // Disable overall page scroll when the modal opens
        if (shareBriefModalState) {
            window.addEventListener('scroll', preventScroll);
            document.body.style.overflow = 'hidden';
        } else {
            // Enable overall page scroll when the modal is closed
            window.removeEventListener('scroll', preventScroll);
            document.body.style.overflow = 'auto';
        }

        // Clean up the event listener and reset body overflow on component unmount
        return () => {
            window.removeEventListener('scroll', preventScroll);
            document.body.style.overflow = 'auto';
        };
    }, [shareBriefModalState]);
    return (
        <div className={`modal ${shareBriefModalState ? css.open : ''}`}>
            <div className={css.modalContent}>
                <BriefShareForm
                    onSubmit={onSubmit}
                    firmData={firmData}
                    updateInProgress={updateInProgress}
                    onClose={onClose}
                    isCsm={isCsm}
                />
            </div>
        </div>
    )
}

export default CustomBriefShareModal