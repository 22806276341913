import React from 'react';
import { func } from 'prop-types';
import { compose } from 'redux';
import { Field, Form as FinalForm } from 'react-final-form';
// These relative imports need to point to correct directories
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import { Form, FieldTextInput, PrimaryButton, } from '../../components';

// as a template.
import css from './OpenAISuggestAnswerOptionAppForm.module.css';
import classNames from 'classnames';
import { composeValidators, required } from '../../util/validators';

export const OpenAISuggestAnswerOptionAppForm = props => {
  return (
    <FinalForm
      {...props}
      render={formRenderProps => {
        const {
          disabled,
          handleSubmit,
          intl,
          pristine,
          updated,
          formId,
          updateError,
          values,
          form,
          openaiRequest,
        } = formRenderProps;
        const submitDisabled = !values.questionInput;

        // Input label
        const yourInputLabel = intl.formatMessage({
          id: 'OpenAISuggestAnswerOptionAppForm.yourInputFieldLabel',
        });
        const yourInputPlaceholder = intl.formatMessage({
          id: 'OpenAISuggestAnswerOptionAppForm.yourInputFieldPlaceholder',
        });

        // contextOfTheQuestion
        const contextOfTheQuestionLabel = intl.formatMessage({
          id: 'OpenAISuggestAnswerOptionAppForm.contextOfTheQuestionLabel',
        });
        const contextOfTheQuestionPlaceholder = intl.formatMessage({
          id: 'OpenAISuggestAnswerOptionAppForm.contextOfTheQuestionPlaceholder',
        });

        // expectedRange
        const expectedRangeLabel = intl.formatMessage({
          id: 'OpenAISuggestAnswerOptionAppForm.expectedRangeLabel',
        });

        // MinAndMax
        const minimumValueLabel = intl.formatMessage({
          id: 'OpenAISuggestAnswerOptionAppForm.minimumValueLabel',
        });
        const minmumValuePlaceholder = intl.formatMessage({
          id: 'OpenAISuggestAnswerOptionAppForm.minmumValuePlaceholder',
        });
        const maximumValueLabel = intl.formatMessage({
          id: 'OpenAISuggestAnswerOptionAppForm.maximumValueLabel',
        });
        const maximumValuePlaceholder = intl.formatMessage({
          id: 'OpenAISuggestAnswerOptionAppForm.maximumValuePlaceholder',
        });

        // Output
        const outputHeading = intl.formatMessage({
          id: 'OpenAISuggestAnswerOptionAppForm.outputHeading',
        });

        const handleToggleState = () => {
          form.change('isAnswerInRange', !form.getState().values.isAnswerInRange);
        };
        const { isAnswerInRange = false } = values || {}

        const questionInputRequiredMessage = intl.formatMessage({
          id: 'OpenAISuggestAnswerOptionAppForm.questionInputRequiredMessage',
        });

        return (
          <Form onSubmit={handleSubmit}>
            <div className={css.briefGeneratorForm}>
              <div className={css.container}>
                <div className={css.briefGenerator}>
                  <span className={css.generatorTitle}>
                    <FormattedMessage id="OpenAISuggestAnswerOptionAppForm.heading" />
                  </span>
                </div>
                <div className={css.textareaBox}>
                  <label>{yourInputLabel}</label>
                  <FieldTextInput
                    className={css.inputBox}
                    type="textarea"
                    id={formId ? `${formId}.questionInput` : 'questionInput'}
                    name="questionInput"
                    autoComplete="given-name"
                    placeholder={yourInputPlaceholder}
                    validate={composeValidators(required(questionInputRequiredMessage))}
                    vibrate={true}
                  />
                </div>
                <div className={css.textareaBox}>
                  <label>{contextOfTheQuestionLabel}</label>
                  <FieldTextInput
                    className={css.inputBox}
                    type="textarea"
                    id={formId ? `${formId}.contextOfTheQuestion` : 'contextOfTheQuestion'}
                    name="contextOfTheQuestion"
                    placeholder={contextOfTheQuestionPlaceholder}
                  />
                </div>
                <div className={css.fieldCheckboxRange}>
                  <Field name="isAnswerInRange" type="checkbox">
                    {({ input }) => (
                      <div>
                        <label className={css.rangeLabel}>
                          {expectedRangeLabel}
                          <div className={classNames(css.checkboxWrapper, input.checked && css.toggled)} onClick={handleToggleState}>
                            <div className={classNames(css.ball, input.checked && css.toggled)}></div>
                          </div>
                        </label>
                      </div>
                    )}
                  </Field>
                </div>
                {isAnswerInRange ?
                  <>
                  <label className={css.minMaxLabel}>
                    <FormattedMessage id="OpenAISuggestAnswerOptionAppForm.provideMeTheMinimumAndMaximumValues" />
                  </label>
                  <div className={css.textareaBoxMinMax}>
                    <div className={css.rowBox}>
                      <label>{minimumValueLabel}</label>
                      <FieldTextInput
                        className={css.inputBox}
                        id={formId ? `${formId}.minimumValue` : 'minimumValue'}
                        name="minimumValue"
                        placeholder={minmumValuePlaceholder}
                      />
                    </div>
                    <div className={css.rowBox}>
                      <label>{maximumValueLabel}</label>
                      <FieldTextInput
                        className={css.inputBox}
                        id={formId ? `${formId}.maximumValue` : 'maximumValue'}
                        name="maximumValue"
                        placeholder={maximumValuePlaceholder}
                      />
                    </div>
                  </div>
                </>:null}

                <div>
                  <h4>{outputHeading}</h4>
                  {values?.suggestedAnswer?.length > 0 ?
                    <div className={css.suggestedAnswerBox} >
                      {values?.suggestedAnswer?.map(answer => (
                        <div className={css.answerOutput} key={answer?.answerId}>
                          <h3>{answer?.answerContent}</h3>
                        </div>
                      ))}
                    </div>
                    : null}
                  <div className={css.actionButtons}>
                    <PrimaryButton
                      type="submit"
                      className={classNames(submitDisabled ? css.disabledSubmitButton : css.submitButton )}
                      inProgress={openaiRequest}
                    >
                      <FormattedMessage id="OpenAIContractForm.submitButtonText" />
                    </PrimaryButton>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        );
      }}
    />
  );
};

OpenAISuggestAnswerOptionAppForm.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  //   updateInProgress: bool.isRequired,
};

export default compose(injectIntl)(OpenAISuggestAnswerOptionAppForm);
