import React, { useState } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl';
import { compose } from 'redux';
import { FieldCheckbox, FieldSelect, FieldTextInput, Form, PrimaryButton, RangeSlider } from '../../components';
import css from './EngagementForm.module.css'
import { Field, Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import { currencyFeeTemp } from '../../marketplace-custom-config';
import { required } from '../../util/validators';
import { FULLTIME_KEY, FULL_TIME_EMPLOYMENT, PROJECT_BASED, PROJECT_BASED_KEY, SECONDMENT, SECONDMENT_KEY } from '../../util/types';

const MIN_VALUE_PLACEHOLDER = "10,000";
const MAX_VALUE_PLACEHOLDER = "15,000";

const parseMin = (min, currentMax) => value => {
  const parsedValue = Number.parseInt(value, 10);
  if (isNaN(parsedValue)) {
    return '';
  }
  return parsedValue < min ? min : parsedValue > currentMax ? currentMax : parsedValue;
};
const parseMax = (max, currentMin) => value => {
  const parsedValue = Number.parseInt(value, 10);
  if (isNaN(parsedValue)) {
    return '';
  }
  return parsedValue > max ? max : parsedValue;
  // < currentMin ? currentMin : parsedValue > max ? max : parsedValue;
};

const EngagementForm = (props) => {
  return (
    <FinalForm
      {...props}
      render={formRenderProps => {
        const {
          className,
          form,
          values,
          handleSubmit,
          updateInProgress,
          submitButtonText,
          ready,
          pristine,
          updated,
        } = formRenderProps;

        const classes = classNames(css.root, className);
        const submitReady = (updated && pristine) || ready;
        const submitInProgress = updateInProgress;
        const submitDisabled = pristine || !values.engagement?.length || submitInProgress ||
          values.engagement?.length && values.engagement?.includes("secondment") &&
          (!values.secondMentMin || !values.secondMentMax) ||
          values.engagement?.length && values.engagement?.includes("fullTimeEmployment") &&
          (!values.fullTimeMin || !values.fullTimeMax);

        const minRequiredMessage = <FormattedMessage id='EditListingAdditionalForm.minRequiredMessage' />
        const maxRequiredMessage = <FormattedMessage id='EditListingAdditionalForm.maxRequiredMessage' />;

        const customField = (currencyFee, min, max, rangeTitle) => {
          const maxPrice = typeof values[max] === 'string' ? Number(values[max]?.replace(/\,/g, '')) : values[max]
          const minPrice = typeof values[min] === 'string' ? Number(values[min]?.replace(/\,/g, '')) : values[min]

          const handleMaxPrice = (e) => {
            if (maxPrice < minPrice) {
              form.change(max, minPrice.toLocaleString("en-US"));
            }
          }

          const handleMoneyFormat = (value, key) => {
            const modifiedValue = value.toLocaleString("en-US")
            if (Number.isInteger(value)) form.change(key, modifiedValue)
          }

          return (
            <div className={css.contentWrapper}>
              <div className={css.label}>{rangeTitle}</div>
              <div className={css.inputsWrapper}>
                <FieldSelect
                  id={currencyFee}
                  name={currencyFee}
                  className={css.currencySelector}
                  defaultValue={currencyFeeTemp[0].key}
                  label="Currency"
                >
                  {currencyFeeTemp.map(({ sym, key }) => (
                    <option key={key} value={key}>
                      {` ${sym} ( ${key} )`}
                    </option>
                  ))}
                </FieldSelect>
                <FieldTextInput
                  onChange={(e) => {
                    const value = Number(e.target.value.replace(/\,/g, ''))
                    handleMoneyFormat(value, min)

                    if (value > maxPrice) form.change(min, maxPrice.toLocaleString("en-US"))
                  }}
                  className={classNames(css.minPrice)}
                  type="text"
                  id={min}
                  name={min}
                  label="From"
                  placeholder={MIN_VALUE_PLACEHOLDER}
                  validate={required(minRequiredMessage)}
                />
                <span className={css.priceSeparator}></span>
                <FieldTextInput
                  onChange={(e) => {
                    const value = Number(e.target.value.replace(/\,/g, ''))
                    handleMoneyFormat(value, max)
                  }}
                  onBlur={handleMaxPrice}
                  className={classNames(css.maxPrice)}
                  type="text"
                  id={max}
                  name={max}
                  label="To"
                  placeholder={MAX_VALUE_PLACEHOLDER}
                  validate={required(maxRequiredMessage)}
                />
              </div>
            </div>
          )
        }

        return (
          <Form onSubmit={handleSubmit}>
            <div className={css.projectBased}>
              <FieldCheckbox
                id={PROJECT_BASED_KEY}
                name='engagement'
                label={PROJECT_BASED}
                value={PROJECT_BASED_KEY}
              />
              <span className={classNames(css.toolTip, css.projectBasedText)}>
                <FormattedMessage id='EngagementForm.projectBasedText' />
              </span>
            </div>
            <div className={css.bottomBorder}></div>
            <div className={css.secondMent}>
              <FieldCheckbox
                id={SECONDMENT_KEY}
                name='engagement'
                label={SECONDMENT}
                value={SECONDMENT_KEY}
              />
              {values?.engagement && values?.engagement.includes(SECONDMENT_KEY) &&
                customField('secondMentCurrency', 'secondMentMin', 'secondMentMax', 'What is your expected monthly remuneration for secondment?')
              }
              <span className={classNames(css.toolTip, css.secondMentText)}>
                <FormattedMessage id='EngagementForm.secondMentText' />
              </span>
            </div>
            <div className={css.bottomBorder}></div>
            <div className={css.fullTime}>
              <FieldCheckbox
                id={FULLTIME_KEY}
                name='engagement'
                label={FULL_TIME_EMPLOYMENT}
                value={FULLTIME_KEY}
              />
              {values?.engagement && values?.engagement.includes(FULLTIME_KEY) &&
                customField('fullTimeCurrency', 'fullTimeMin', 'fullTimeMax', 'What is your expected annual remuneration (CTC) for employment?')
              }
              <span className={classNames(css.toolTip, css.fullTimeText)}>
                <FormattedMessage id='EngagementForm.fullTimeText' />
              </span>
            </div>
            <PrimaryButton
              className={css.submitButton}
              type="submit"
              inProgress={submitInProgress}
              disabled={submitDisabled}
              ready={submitReady}
            >
              {submitButtonText}
            </PrimaryButton>
          </Form>
        )
      }}
    />
  )
}

export default compose(injectIntl)(EngagementForm);