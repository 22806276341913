import { ModalApplicationForm } from "../../forms";
import Modal from "../Modal/Modal"
import css from '../../forms/ModalApplicationForm/ModalApplicationForm.module.css'
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { userDisplayNameAsString } from "../../util/data";
import { getTotalWorkYears } from "../../util/destructorHelpers";

const ModalApplication = (props) => {
  const { id, isOpen, onClose, onSubmit, onManageDisableScrolling, inProgress, isFullTimeJob} = props;
  const {currentUser, currentUserListing} = useSelector(state => state.user)
  const {
    Experience, userLocation, engagementRoles, Education, Certification, domainExpertise, industrySector
  } = currentUserListing?.attributes?.publicData ?? {};
  const {title, description} = currentUserListing?.attributes ?? {};
  const {firmListing, typeCompany} = currentUser?.id ? currentUser.attributes.profile.publicData : {};

  const params = {
    name: userDisplayNameAsString(currentUser, ''),
    firmName: firmListing?.firmTitle,
    typeCompany: typeCompany,
    experience: getTotalWorkYears(Experience),
    address: userLocation && userLocation?.selectedPlace.address,
    openTo: engagementRoles,
    profileHeadline: title,
    summary: description,
    workExperience: Experience,
    education: Education,
    certification: Certification,
    domain: domainExpertise,
    industry: industrySector
  }

  return(
    <Modal
      id={id}
      containerClassName={css.modalContainer}
      isOpen={isOpen}
      onClose={onClose}
      usePortal
      onManageDisableScrolling={onManageDisableScrolling}
    >
      <h2 className={css.title}>
        <FormattedMessage id="ModalApplicationForm.title"/>
      </h2>
      <ModalApplicationForm
        initialValues={{
          lastSalaryCurrency: 'INR',
          expectedSalaryCurrency: 'INR'
        }} 
        onSubmit={onSubmit}
        onClose={onClose}
        params={params}
        inProgress={inProgress}
        isSecondMent={!isFullTimeJob}
        listingId={currentUserListing?.id.uuid}
      />
    </Modal>
  )
}

export default ModalApplication