import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form as FinalForm, Field } from 'react-final-form';
import classNames from 'classnames';
import { intlShape, injectIntl } from '../../util/reactIntl';
import { FieldTextInput, Form, IconCard, LocationAutocompleteInput, OutsideClickHandler} from '../../components';

import css from './TopbarSearchForm.module.css';
import IconHourGlass from "../../components/LocationAutocompleteInput/IconHourGlass";
import { JOB, PROJECT_BASED, SECONDMENT_EMPLOYEMENT, USER_ROLE_CLIENT, USER_ROLE_PARTNER } from '../../util/types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { fetchListingType, initialSearchListings } from '../../containers/SearchPage/SearchPage.duck';

const identity = v => v;

class TopbarSearchFormComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showOptions: false,
    }
    this.onSubmit = this.onSubmit.bind(this);
    this.searchInput = React.createRef();
    this.handleSelectOption = this.handleSelectOption.bind(this);
  }

  onSubmit(values) {
    this.props.onSubmit({ keywords: values.keywords });
    // blur search input to hide software keyboard
    if (this.searchInput.current) {
      this.searchInput.current.blur();
    }
  }

  handleSelectOption(type){
    const {onFetchListingType, onSearchListings} = this.props;
    this.setState({showOptions: false})
    onFetchListingType(type)
    onSearchListings('', type)
  }

  render() {
    return (
      <FinalForm
        {...this.props}
        onSubmit={this.onSubmit}
        render={formRenderProps => {
          const {
            rootClassName,
            className,
            intl,
            isMobile,
            mobileIcon,
            handleSubmit,
            typeUser,
            listingType
          } = formRenderProps;

          const classes = classNames(rootClassName, className, css.root);
          return (
            <Form  className={classes} onSubmit={handleSubmit}>
              {typeUser && typeUser === USER_ROLE_PARTNER && (
                <OutsideClickHandler 
                  onOutsideClick={() => this.setState({showOptions: false})}
                  className={css.outSideWrapper}
                >
                  <div 
                    className={classNames(css.listingSelect, isMobile && css.listingSelectMobile)} 
                    onClick={() => this.setState({showOptions: true})}
                  >
                    <p>{listingType === USER_ROLE_CLIENT ? PROJECT_BASED : SECONDMENT_EMPLOYEMENT}</p>
                    <IconCard brand='closearrow' />
                  </div>
                  {this.state.showOptions && (
                    <div className={classNames(css.listingOptions, isMobile && css.listingOptionsMobile)}>
                      <p onClick={() => this.handleSelectOption(USER_ROLE_CLIENT)}>{PROJECT_BASED}</p>
                      <p onClick={() => this.handleSelectOption(JOB)}>{SECONDMENT_EMPLOYEMENT}</p>
                    </div>
                  )}
                </OutsideClickHandler>
              )}
              <div className={css.searchWrapper}>
                <IconHourGlass />
                <Field
                  name="keywords"
                  render={({ input, meta }) => {
                    return (
                        <input
                          className={
                            isMobile
                              ? css.mobileInputRoot
                              : css.desktopInputRoot
                          }
                          {...input}
                          id="keyword-search"
                          ref={this.searchInput}
                          type="text"
                          placeholder={this.props.placeholder}
                          autoComplete="off"
                        />
                    );
                  }}
                />
              </div>
            </Form>
          );
        }}
      />
    );
  }
}

const { func, string, bool } = PropTypes;

TopbarSearchFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  desktopInputRoot: null,
  isMobile: false,
};

TopbarSearchFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  desktopInputRoot: string,
  onSubmit: func.isRequired,
  isMobile: bool,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { listingType } = state.SearchPage;
  return {listingType}
};

const mapDispatchToProps = dispatch => ({
  onFetchListingType: type => dispatch(fetchListingType(type)),
  onSearchListings: (demo, type) => dispatch(initialSearchListings(demo, type)),
});

const TopbarSearchForm = compose(
  injectIntl,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(TopbarSearchFormComponent);

export default TopbarSearchForm;
