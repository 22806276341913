import { FormattedMessage } from "react-intl";
import css from './FirmDashboardPanel.module.css'
import { getCustomDaysCount, getTotalCount } from "../../util/destructorHelpers";
import { ADMIN, GIG_APP_COMPLETED, GIG_APP_USAGE, GIG_APP_VIEWS, LOGIN, PRIVATELY_SHARED_PROJECT, PRIVATE_PROPOSAL, PROJECT_COMMISSIONED, PROJECT_COMPLETED, PROJECT_UNDERWAY, PUBLIC_POSTED_JOB_APPLICATIONS, PUBLIC_POSTED_JOB, PUBLIC_POSTED_PROJECT, PUBLIC_PROPOSAL, PRIVATELY_SHARED_JOB_APPLICATIONS, RECRUITMENTS_UNDERWAY, RECRUITMENTS_DONE } from "../../util/types";

const emptySpace = '-';

const UserStatsPanel = (props) => {
  const { sortedUsers, isClient, firmName, isUserTable, isGigappTable, selectedFilter, isEmploymentTable } = props;

  const csmTooltipText = firmName 
    ? `This user is a Customer Success Manager working on behalf of ${firmName} on the InsightGig platform.` 
    : 'This user is a Customer Success Manager (CSM) on the InsightGig platform. CSMs work on behalf of their clients.';

  const TableItem = ({itemClassName, children}) => {
    return(
      <th className={itemClassName}>
        <div className={css.headingBox}>
          <div className={css.nameHeading}>
            {children}
          </div>
        </div>
      </th>
    )
  }

  return (
    <div className={css.userStatesTable}>
      {/* <div className={css.tableHeading}>
        <FormattedMessage id="FirmDashboardPanel.userStats" />
      </div> */}
      <div className={css.statsTable}>
        <table>
          <thead>
            <tr>
              <TableItem itemClassName={css.firstBox}>
                <FormattedMessage id="FirmDashboardPanel.name" />
              </TableItem>
              {isUserTable ? (
                <TableItem itemClassName={css.gigAppBox}>
                  <FormattedMessage id="FirmDashboardPanel.logins" /> (last 30 days)
                </TableItem>
              ) : isGigappTable ? (
                <>
                  <TableItem itemClassName={css.gigAppBox}>
                    <FormattedMessage id="FirmDashboardPanel.detailsPageLabel" />
                  </TableItem>
                  <TableItem itemClassName={css.gigAppBox}>
                    <FormattedMessage id="FirmDashboardPanel.appInitiatedLabel" />
                  </TableItem>
                  <TableItem itemClassName={css.gigAppBox}>
                    <FormattedMessage id="FirmDashboardPanel.outputGeneratedLabel" />
                  </TableItem>
                </>
              ) : isEmploymentTable ? (
                isClient ? 
                <>
                  <TableItem itemClassName={css.gigAppBox}>
                    <FormattedMessage id="FirmDashboardPanel.publiclyPostedJobs" /> 
                  </TableItem>
                  <TableItem itemClassName={css.gigAppBox}>
                    <FormattedMessage id="FirmDashboardPanel.privatelySharedJobs" />
                  </TableItem>
                  <TableItem itemClassName={css.gigAppBox}>
                    <FormattedMessage id="FirmDashboardPanel.recruitmentsUnderway" /> (last 90 days)
                  </TableItem>
                  <TableItem itemClassName={css.gigAppBox}>
                    <FormattedMessage id="FirmDashboardPanel.recruitmentsDone" /> (last 90 days)
                  </TableItem>
                </>
                :
                <>
                  <TableItem itemClassName={css.gigAppBox}>
                    <FormattedMessage id="FirmDashboardPanel.applicationsForPubliclyPostedJobs" />
                  </TableItem>
                  <TableItem itemClassName={css.gigAppBox}>
                    <FormattedMessage id="FirmDashboardPanel.applicationsForPrivatelySharedJobs" />
                  </TableItem>
                </>
              ) : (
                <>
                  <TableItem itemClassName={css.secondBox}>
                    {isClient
                      ? <FormattedMessage id="FirmDashboardPanel.publiclyPostedProjects" />
                      : <FormattedMessage id="FirmDashboardPanel.bidsForPubliclyPostedProjects" />
                    }
                  </TableItem>
                  <TableItem itemClassName={css.secondBox}>
                    {isClient
                        ? <FormattedMessage id="FirmDashboardPanel.privatelySharedProjects" />
                        : <FormattedMessage id="FirmDashboardPanel.bidsForPrivatelySharedProjects" />
                      }
                  </TableItem>
                  <TableItem itemClassName={css.secondBox}>
                    <FormattedMessage id="FirmDashboardPanel.projectCurrentlyUnderway" />
                  </TableItem>
                  <TableItem itemClassName={css.secondBox}>
                    <FormattedMessage id="FirmDashboardPanel.projectsCommissioned" /> (last 90 days)
                  </TableItem>
                  <TableItem itemClassName={css.secondBox}>
                    <FormattedMessage id="FirmDashboardPanel.projectsCompleted" /> (last 90 days)
                  </TableItem>
                  <TableItem itemClassName={css.secondBox}>
                    <FormattedMessage id="FirmDashboardPanel.averageRating" />
                  </TableItem>
                </>
              )}
              
            </tr>
          </thead>
          <tbody>
            {sortedUsers?.length && sortedUsers?.map((user) => {
              const totalReviews = user?.averageRating?.length;
              const loginCount = getCustomDaysCount([user], LOGIN, 30) ?? emptySpace;
              const publiclyPostedProjectCount = getTotalCount([user], PUBLIC_POSTED_PROJECT) ?? emptySpace;
              const privatelySharedProjectCount = getTotalCount([user], PRIVATELY_SHARED_PROJECT) ?? emptySpace;
              const privateProposalCount = getTotalCount([user], PRIVATE_PROPOSAL) ?? emptySpace;
              const publicProposalCount = getTotalCount([user], PUBLIC_PROPOSAL) ?? emptySpace;
              const projectUnderwayCount = getTotalCount([user], PROJECT_UNDERWAY) ?? emptySpace;
              const projectCommisionedCount = getCustomDaysCount([user], PROJECT_COMMISSIONED, 90) ?? emptySpace;
              const projectCompletedCount = getCustomDaysCount([user], PROJECT_COMPLETED, 90) ?? emptySpace;
              const gigAppViewsCount = getTotalCount([user], GIG_APP_VIEWS, selectedFilter) ?? emptySpace;
              const gigAppUsageCount = getTotalCount([user], GIG_APP_USAGE, selectedFilter) ?? emptySpace;
              const gigAppCompletedCount = getTotalCount([user], GIG_APP_COMPLETED, selectedFilter) ?? emptySpace;
              const publiclyPostedJobsCount = getTotalCount([user], PUBLIC_POSTED_JOB) ?? emptySpace;
              const privatelySharedJobsCount = getTotalCount([user], PRIVATELY_SHARED_JOB_APPLICATIONS) ?? emptySpace;
              const recruitmentsUnderwayCount = getCustomDaysCount([user], RECRUITMENTS_UNDERWAY,90, 'recruitmentUnderway') ?? emptySpace;
              const recruitmentsDoneCount = getCustomDaysCount([user], RECRUITMENTS_UNDERWAY, 90, 'recruitmentDone') ?? emptySpace;
              const publiclyPostedJobsApplicationsCount = getTotalCount([user], PUBLIC_POSTED_JOB_APPLICATIONS) ?? emptySpace;
              const privatelySharedJobsApplicationsCount = getTotalCount([user], PRIVATELY_SHARED_JOB_APPLICATIONS) ?? emptySpace;
              const averageRatingCount = totalReviews === 0 ? 0 :
                (user?.averageRating?.reduce((acc, curr) => acc + curr?.rating, 0) / totalReviews).toFixed(2);

              return (
                <tr key={user.id}>
                  <td>
                    <div className={css.panelProfile}>
                      <div className={user?.isCsm ? css.csmProfileIcon : css.normalUser} data-tooltip={csmTooltipText}>
                        {user.profilePicture ? (
                          <div className={css.profileImage}>
                            <img src={user.profilePicture} alt="Profile Picture" />
                          </div>
                        ) : (
                          <div className={css.noImageWithText}>
                            {!!user?.name && user?.name.match(/(\b\S)?/g).join('').toUpperCase()}
                          </div>
                        )}
                      </div>
                      <div className={css.userName}>
                        <span className={css.userNameHeading}>{`${user.name}`}</span>
                        {user.isAdmin === 1 && <span className={css.adminName}>{ADMIN}</span>}
                      </div>
                    </div>
                  </td>
                  {isUserTable ? (
                    <td><div className={css.count}>{loginCount}</div></td>
                  ) : isGigappTable ? (
                      <>
                      <td><div className={css.count}>{gigAppViewsCount}</div></td>
                      <td><div className={css.count}>{gigAppUsageCount}</div></td>
                      <td><div className={css.count}>{gigAppCompletedCount}</div></td>
                      </>
                  ) : isEmploymentTable ? (
                    <>
                      {isClient ? 
                        <>
                          <td><div className={css.count}>{publiclyPostedJobsCount}</div></td>
                          <td><div className={css.count}>{privatelySharedJobsCount}</div></td>
                          <td><div className={css.count}>{recruitmentsUnderwayCount}</div></td>
                          <td><div className={css.count}>{recruitmentsDoneCount}</div></td>
                        </>
                        :
                        <>
                          <td><div className={css.count}>{publiclyPostedJobsApplicationsCount}</div></td>
                          <td><div className={css.count}>{privatelySharedJobsApplicationsCount}</div></td>
                        </>
                      }
                    </>
                  ) : (
                    <>
                      <td>
                        <div className={css.count}>
                          {isClient ? publiclyPostedProjectCount : publicProposalCount}
                        </div>
                      </td>
                      <td>
                        <div className={css.count}>
                          {isClient ? privatelySharedProjectCount : privateProposalCount}
                        </div>
                      </td>
                      <td><div className={css.count}>{projectUnderwayCount}</div></td>
                      <td><div className={css.count}>{projectCommisionedCount}</div></td>
                      <td><div className={css.count}>{projectCompletedCount}</div></td>
                      <td><div className={css.count}>{averageRatingCount}</div></td>
                    </>
                  )}
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default UserStatsPanel