import React from 'react'
import css from './FieldValidationErrorMessage.module.css'
import classNames from 'classnames'

const FieldValidationErrorMessage = ({message, vibrate}) => {
  return (
    <p className={classNames(css.errorMessage, vibrate && css.vibrate)}>{message}</p>
  )
}

export default FieldValidationErrorMessage