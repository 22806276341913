import { isScrollingDisabled } from '../../ducks/UI.duck';
import { fetchCurrentUser } from '../../ducks/user.duck';
import {
  getTransaction,
  updateMetadata,
  updateUserProfile,
} from '../../util/api';
import { storableError } from '../../util/errors';
import { types as sdkTypes } from '../../util/sdkLoader';
import { INVITE_STATUS_ACTIVE, INVITE_STATUS_CANCEL, INVITE_STATUS_REJECTED, USER_TYPE_PARTNER } from '../../util/types';
import { fetchUserRole } from '../../util/userRole';
import { showListing } from '../FirmPage/FirmPage.duck';
import { sendZohoDocument } from '../TransactionPage/TransactionPage.duck';
const { UUID } = sdkTypes;

//Action types

export const FETCH_TRANSACTION_REQUEST = 'app/InvitationPage/FETCH_TRANSACTION_REQUEST';
export const FETCH_TRANSACTION_SUCCESS = 'app/InvitationPage/FETCH_TRANSACTION_SUCCESS';
export const FETCH_TRANSACTION_ERROR = 'app/InvitationPage/FETCH_TRANSACTION_ERROR';
export const INVITE_ACCEPTED_REQUEST = 'app/InvitationPage/INVITE_ACCEPTED_REQUEST';
export const INVITE_ACCEPTED_SUCCESS = 'app/InvitationPage/INVITE_ACCEPTED_SUCCESS';
export const INVITE_REJECTED_REQUEST = 'app/InvitationPage/INVITE_REJECTED_REQUEST';
export const INVITE_REJECTED_SUCCESS = 'app/InvitationPage/INVITE_REJECTED_SUCCESS';

export const INVITATION_ACCEPTED = 'Invitation Accepted';
export const INVITATION_REJECTED = 'Invitation Rejected';

//InitialState
const initialState = {
  fetchTransactionInProgress: false,
  fetchTransactionError: null,
  
};

//reducer function
export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_TRANSACTION_REQUEST:
      return { ...state, fetchTransactionInProgress: true, fetchTransactionError: null };
    case FETCH_TRANSACTION_SUCCESS: {
      return { ...state, fetchTransactionInProgress: false, transactions: payload };
    }
    case FETCH_TRANSACTION_ERROR:
      // console.error(payload); // eslint-disable-line
      return { ...state, fetchTransactionInProgress: false, fetchTransactionError: payload };
   

    default:
      return state;
  }
}

//Selectors

export const checkScrollingDisabled = state => isScrollingDisabled(state);

// ================ Action creators ================ //

const fetchTransactionRequest = () => ({ type: FETCH_TRANSACTION_REQUEST });
const fetchTransactionSuccess = response => ({
  type: FETCH_TRANSACTION_SUCCESS,
  payload: response,
});
const fetchTransactionError = e => ({ type: FETCH_TRANSACTION_ERROR, error: true, payload: e });


// ================ Thunk ================ //




//LoadData
export const loadData = params => (dispatch, getState, sdk) => {
  try {
    const listingId = new UUID(params.id);
    return Promise.all([dispatch(fetchCurrentUser()),dispatch(showListing(listingId))]);
  } catch (e) {
    console.log(e)
  }
};
