import React, { useState, useEffect } from 'react';
import { createHashValueForTawkTo } from '../../util/api';
import { getUserDetails } from '../../util/destructorHelpers';

const TawkTo = ({ widgetId, currentUser }) => {
  const [tawkToLoaded, setTawkToLoaded] = useState(false);

  useEffect(() => {
    if (!tawkToLoaded) {
      setTimeout(() => {
        const tawkToScript = document.createElement('script');
        const headElement = document.getElementsByTagName('head')[0];

        tawkToScript.async = true;
        tawkToScript.src = `https://embed.tawk.to/${widgetId}`;
        tawkToScript.setAttribute('crossorigin', '*');

        // Handle the script loading event
        tawkToScript.onload = handleOnLoad;

        headElement.appendChild(tawkToScript);
      }, 60000); // Delays the script loading for 60000 milliseconds (1 minute)
    }
  }, [widgetId, currentUser?.id]);

  const handleOnLoad = () => {
    if (currentUser) {
      updateVisitorAttributes();
    }

    setTawkToLoaded(true);
  };

  const updateVisitorAttributes = async () => {
    if (currentUser) {
      const userDetails = getUserDetails(currentUser);
      const email = userDetails ? userDetails.email : '';
      try {
        const hash = await createHashValueForTawkTo({ email });
        window.Tawk_API.setAttributes(
          {
            name: userDetails ? userDetails.fullName : '',
            email,
            hash
          },
          function (error) {
            if (error) {
              // Handle error if necessary
            }
          }
        );
      } catch (error) {
        // console.error('Error creating hash value:', error);
      }
    }
  };

  return null;
};

export default TawkTo;
