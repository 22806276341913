import { useEffect } from "react"
import css from './Box.module.css'
import { createBoxCollaborator } from "../../util/api";
import { updateTransactionMetaData } from "../../containers/TransactionPage/TransactionPage.duck";
import { useDispatch } from "react-redux";
import { FormattedMessage } from "react-intl";

const BoxComponent = (props) => {
  const {transaction, customerEmail, providerEmail} = props;
  const dispatch = useDispatch();

  const txId = transaction?.id.uuid;
  const boxFolder = transaction?.id && transaction?.attributes?.metadata?.boxFolder;
  const {folderId, boxSharedLink, isCollaboratorsAdded} = boxFolder ?? {};


  //To add the expert and client as collaborator on that folder
  useEffect(() => {
    const addBoxCollaborator = async () => {
      const param = {
        emails: [customerEmail, providerEmail],
        folderId
      }
      const response = await createBoxCollaborator(param)
      if(response?.status === 'success'){
        const bodyParam = { 
          boxFolder: { ...boxFolder, isCollaboratorsAdded: true } 
        }
        dispatch(updateTransactionMetaData({txId, bodyParam}))
      }
    }

    if(folderId && !isCollaboratorsAdded) addBoxCollaborator()
  },[folderId, isCollaboratorsAdded])

  function handleFolderRefresh(){
    let iframe = document.getElementById('folderFrame');
    iframe.src = iframe.src;
  }

  return(
    <>
      {boxSharedLink ? 
        <div className={css.folderContainer}>
          <button onClick={handleFolderRefresh}><FormattedMessage id="BoxFolder.goToFoler" /></button>
          <iframe 
            id='folderFrame'
            src={`https://app.box.com/embed/s/${boxSharedLink}?showItemFeedActions=false&showParentPath=false&sortColumn=date`} 
            width="1000" height="550" 
            frameborder="0" 
            allowfullscreen webkitallowfullscreen msallowfullscreen 
          />
        </div> : 
        <div className={css.box}><FormattedMessage id="BoxFolder.noContent" /></div>
      }
    </>
  )
}

export default BoxComponent