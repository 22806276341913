/**
 * This module defines custom PropTypes shared within the application.
 *
 * To learn about validating React component props with PropTypes, see:
 *
 *     https://facebook.github.io/react/docs/typechecking-with-proptypes.html
 *
 * For component specific PropTypes, it's perfectly ok to inline them
 * to the component itself. If the type is shared or external (SDK or
 * API), however, it should be in this file for sharing with other
 * components.
 *
 * PropTypes should usually be validated only at the lowest level
 * where the props are used, not along the way in parents that pass
 * along the props to their children. Those parents should usually
 * just validate the presense of the prop key and that the value is
 * defined. This way we get the validation errors only in the most
 * specific place and avoid duplicate errros.
 */
import {
  arrayOf,
  bool,
  func,
  instanceOf,
  node,
  number,
  object,
  objectOf,
  oneOf,
  oneOfType,
  shape,
  string,
} from 'prop-types';
import Decimal from 'decimal.js';
import { types as sdkTypes } from './sdkLoader';
import { TRANSITIONS, TX_TRANSITION_ACTORS } from './transaction';

const { UUID, LatLng, LatLngBounds, Money } = sdkTypes;

const propTypes = {};

// Fixed value
propTypes.value = val => oneOf([val]);

// SDK type instances
propTypes.uuid = instanceOf(UUID);
propTypes.latlng = instanceOf(LatLng);
propTypes.latlngBounds = instanceOf(LatLngBounds);
propTypes.money = instanceOf(Money);

// Configuration for currency formatting
propTypes.currencyConfig = shape({
  style: string.isRequired,
  currency: string.isRequired,
  currencyDisplay: string,
  useGrouping: bool,
  minimumFractionDigits: number,
  maximumFractionDigits: number,
});

// Configuration for a single route
propTypes.route = shape({
  name: string.isRequired,
  path: string.isRequired,
  exact: bool,
  strict: bool,
  component: oneOfType([object, func]).isRequired,
  loadData: func,
});

// Place object from LocationAutocompleteInput
propTypes.place = shape({
  address: string.isRequired,
  origin: propTypes.latlng,
  bounds: propTypes.latlngBounds, // optional viewport bounds
});

// Denormalised image object
propTypes.image = shape({
  id: propTypes.uuid.isRequired,
  type: propTypes.value('image').isRequired,
  attributes: shape({
    variants: objectOf(
      shape({
        width: number.isRequired,
        height: number.isRequired,
        url: string.isRequired,
      })
    ),
  }),
});

// Denormalised user object
propTypes.currentUser = shape({
  id: propTypes.uuid.isRequired,
  type: propTypes.value('currentUser').isRequired,
  attributes: shape({
    banned: bool.isRequired,
    email: string.isRequired,
    emailVerified: bool.isRequired,
    profile: shape({
      firstName: string.isRequired,
      lastName: string.isRequired,
      displayName: string.isRequired,
      abbreviatedName: string.isRequired,
      bio: string,
    }).isRequired,
    stripeConnected: bool,
  }),
  profileImage: propTypes.image,
});

const userAttributes = shape({
  banned: propTypes.value(false).isRequired,
  deleted: propTypes.value(false).isRequired,
  profile: shape({
    displayName: string.isRequired,
    abbreviatedName: string.isRequired,
    bio: string,
  }),
});

// Listing queries can include author.
// Banned and deleted are not relevant then
// since banned and deleted users can't have listings.
const authorAttributes = shape({
  profile: shape({
    displayName: string.isRequired,
    abbreviatedName: string.isRequired,
    bio: string,
  }),
});

const deletedUserAttributes = shape({
  deleted: propTypes.value(true).isRequired,
});

const bannedUserAttributes = shape({
  banned: propTypes.value(true).isRequired,
});

// Denormalised user object
propTypes.user = shape({
  id: propTypes.uuid.isRequired,
  type: propTypes.value('user').isRequired,
  attributes: oneOfType([
    userAttributes,
    authorAttributes,
    deletedUserAttributes,
    bannedUserAttributes,
  ]).isRequired,
  profileImage: propTypes.image,
});

export const LISTING_STATE_DRAFT = 'draft';
export const LISTING_STATE_PENDING_APPROVAL = 'pendingApproval';
export const LISTING_STATE_PUBLISHED = 'published';
export const LISTING_STATE_CLOSED = 'closed';

export const LISTING_OWN_LISTING = 'ownListing';
export const LISTING_LISTING = 'listing';

const LISTING_STATES = [
  LISTING_STATE_DRAFT,
  LISTING_STATE_PENDING_APPROVAL,
  LISTING_STATE_PUBLISHED,
  LISTING_STATE_CLOSED,
];

const LISTING_TYPE = [LISTING_OWN_LISTING, LISTING_LISTING];

const listingAttributes = shape({
  title: string.isRequired,
  description: string,
  geolocation: propTypes.latlng,
  deleted: propTypes.value(false),
  state: oneOf(LISTING_STATES),
  price: propTypes.money,
  publicData: object,
});

const AVAILABILITY_PLAN_DAY = 'availability-plan/day';
const AVAILABILITY_PLAN_TIME = 'availability-plan/time';
export const DAYS_OF_WEEK = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];

const availabilityPlan = shape({
  type: oneOf([AVAILABILITY_PLAN_DAY, AVAILABILITY_PLAN_TIME]).isRequired,
  timezone: string,
  entries: arrayOf(
    shape({
      dayOfWeek: oneOf(DAYS_OF_WEEK).isRequired,
      seats: number.isRequired,
      start: string,
      end: string,
    })
  ),
});

propTypes.availabilityPlan = availabilityPlan;

const ownListingAttributes = shape({
  title: string.isRequired,
  description: string,
  geolocation: propTypes.latlng,
  deleted: propTypes.value(false).isRequired,
  state: oneOf(LISTING_STATES).isRequired,
  price: propTypes.money,
  availabilityPlan: availabilityPlan,
  publicData: object.isRequired,
});

const deletedListingAttributes = shape({
  deleted: propTypes.value(true).isRequired,
});

// Denormalised listing object
propTypes.listing = shape({
  id: propTypes.uuid.isRequired,
  type: propTypes.value('listing').isRequired,
  attributes: oneOfType([listingAttributes, deletedListingAttributes]).isRequired,
  author: propTypes.user,
  images: arrayOf(propTypes.image),
});

// Denormalised ownListing object
propTypes.ownListing = shape({
  id: propTypes.uuid.isRequired,
  type: propTypes.value('ownListing').isRequired,
  attributes: oneOfType([ownListingAttributes, deletedListingAttributes]).isRequired,
  author: propTypes.currentUser,
  images: arrayOf(propTypes.image),
});

export const BOOKING_STATE_PENDING = 'pending';
export const BOOKING_STATE_ACCEPTED = 'accepted';
export const BOOKING_STATE_DECLINED = 'declined';
export const BOOKING_STATE_CANCELLED = 'cancelled';
export const BOOKING_STATES = [
  BOOKING_STATE_PENDING,
  BOOKING_STATE_ACCEPTED,
  BOOKING_STATE_DECLINED,
  BOOKING_STATE_CANCELLED,
];

// Denormalised booking object
propTypes.booking = shape({
  id: propTypes.uuid.isRequired,
  type: propTypes.value('booking').isRequired,
  attributes: shape({
    end: instanceOf(Date).isRequired,
    start: instanceOf(Date).isRequired,
    displayStart: instanceOf(Date),
    displayEnd: instanceOf(Date),
    state: oneOf(BOOKING_STATES),
  }),
});

// A time slot that covers one day, having a start and end date.
export const TIME_SLOT_DAY = 'time-slot/day';
export const TIME_SLOT_TIME = 'time-slot/time';

// Denormalised time slot object
propTypes.timeSlot = shape({
  id: propTypes.uuid.isRequired,
  type: propTypes.value('timeSlot').isRequired,
  attributes: shape({
    type: oneOf([TIME_SLOT_DAY, TIME_SLOT_TIME]).isRequired,
    end: instanceOf(Date).isRequired,
    start: instanceOf(Date).isRequired,
  }),
});

// Denormalised availability exception object
propTypes.availabilityException = shape({
  id: propTypes.uuid.isRequired,
  type: propTypes.value('availabilityException').isRequired,
  attributes: shape({
    end: instanceOf(Date).isRequired,
    seats: number.isRequired,
    start: instanceOf(Date).isRequired,
  }),
});

propTypes.transition = shape({
  createdAt: instanceOf(Date).isRequired,
  by: oneOf(TX_TRANSITION_ACTORS).isRequired,
  transition: oneOf(TRANSITIONS).isRequired,
});

// Possible amount of stars in a review
export const REVIEW_RATINGS = [1, 2, 3, 4, 5];

// Review types: review of a provider and of a customer
export const REVIEW_TYPE_OF_PROVIDER = 'ofProvider';
export const REVIEW_TYPE_OF_CUSTOMER = 'ofCustomer';

// A review on a user
propTypes.review = shape({
  id: propTypes.uuid.isRequired,
  attributes: shape({
    createdAt: instanceOf(Date).isRequired,
    content: string,
    rating: oneOf(REVIEW_RATINGS),
    state: string.isRequired,
    type: oneOf([REVIEW_TYPE_OF_PROVIDER, REVIEW_TYPE_OF_CUSTOMER]).isRequired,
  }).isRequired,
  author: propTypes.user,
  subject: propTypes.user,
});

// A Stripe account entity
propTypes.stripeAccount = shape({
  id: propTypes.uuid.isRequired,
  type: propTypes.value('stripeAccount').isRequired,
  attributes: shape({
    stripeAccountId: string.isRequired,
    stripeAccountData: object,
  }),
});

propTypes.defaultPaymentMethod = shape({
  id: propTypes.uuid.isRequired,
  type: propTypes.value('stripePaymentMethod').isRequired,
  attributes: shape({
    type: propTypes.value('stripe-payment-method/card').isRequired,
    stripePaymentMethodId: string.isRequired,
    card: shape({
      brand: string.isRequired,
      expirationMonth: number.isRequired,
      expirationYear: number.isRequired,
      last4Digits: string.isRequired,
    }).isRequired,
  }),
});

export const LINE_ITEM_NIGHT = 'line-item/night';
export const LINE_ITEM_DAY = 'line-item/day';
export const LINE_ITEM_UNITS = 'line-item/units';
export const LINE_ITEM_CUSTOMER_COMMISSION = 'line-item/customer-commission';
export const LINE_ITEM_PROVIDER_COMMISSION = 'line-item/provider-commission';
export const LINE_ITEM_SERVICE_FEE = 'line-item/service-fee';

export const LINE_ITEMS = [
  LINE_ITEM_NIGHT,
  LINE_ITEM_DAY,
  LINE_ITEM_UNITS,
  LINE_ITEM_CUSTOMER_COMMISSION,
  LINE_ITEM_PROVIDER_COMMISSION,
  LINE_ITEM_SERVICE_FEE
];

propTypes.bookingUnitType = oneOf([LINE_ITEM_NIGHT, LINE_ITEM_DAY, LINE_ITEM_UNITS]);

const requiredLineItemPropType = (props, propName, componentName) => {
  const prop = props[propName];

  if (!prop || prop === '') {
    return new Error(`Missing line item code prop from ${componentName}.`);
  }
  if (!/^line-item\/.+/.test(prop)) {
    return new Error(`Invalid line item code value ${prop} passed to ${componentName}.`);
  }
};

// Denormalised transaction object
propTypes.transaction = shape({
  id: propTypes.uuid.isRequired,
  type: propTypes.value('transaction').isRequired,
  attributes: shape({
    createdAt: instanceOf(Date),
    lastTransitionedAt: instanceOf(Date).isRequired,
    lastTransition: oneOf(TRANSITIONS).isRequired,

    // An enquiry won't need a total sum nor a booking so these are
    // optional.
    payinTotal: propTypes.money,
    payoutTotal: propTypes.money,

    lineItems: arrayOf(
      shape({
        code: requiredLineItemPropType,
        includeFor: arrayOf(oneOf(['customer', 'provider'])).isRequired,
        quantity: instanceOf(Decimal),
        unitPrice: propTypes.money.isRequired,
        lineTotal: propTypes.money.isRequired,
        reversal: bool.isRequired,
      })
    ),
    transitions: arrayOf(propTypes.transition).isRequired,
  }),
  booking: propTypes.booking,
  listing: propTypes.listing,
  customer: propTypes.user,
  provider: propTypes.user,
  reviews: arrayOf(propTypes.review),
});

// Denormalised transaction message
propTypes.message = shape({
  id: propTypes.uuid.isRequired,
  type: propTypes.value('message').isRequired,
  attributes: shape({
    createdAt: instanceOf(Date).isRequired,
    content: string.isRequired,
  }).isRequired,
  sender: propTypes.user,
});

// Pagination information in the response meta
propTypes.pagination = shape({
  page: number.isRequired,
  perPage: number.isRequired,
  paginationUnsupported: bool,
  totalItems: number,
  totalPages: number,
});

// Search filter definition
propTypes.filterConfig = arrayOf(
  shape({
    id: string.isRequired,
    label: node,
    type: string.isRequired,
    group: oneOf(['primary', 'secondary']).isRequired,
    queryParamNames: arrayOf(string).isRequired,
    config: object,
  }).isRequired
);

propTypes.sortConfig = shape({
  active: bool,
  queryParamName: oneOf(['sort']).isRequired,
  relevanceKey: string.isRequired,
  conflictingFilters: arrayOf(string),
  options: arrayOf(
    shape({
      key: oneOf(['createdAt', '-createdAt', 'price', '-price', 'relevance']).isRequired,
      label: string.isRequired,
      longLabel: string,
    })
  ),
});

export const ERROR_CODE_TRANSACTION_LISTING_NOT_FOUND = 'transaction-listing-not-found';
export const ERROR_CODE_TRANSACTION_INVALID_TRANSITION = 'transaction-invalid-transition';
export const ERROR_CODE_TRANSACTION_ALREADY_REVIEWED_BY_CUSTOMER =
  'transaction-already-reviewed-by-customer';
export const ERROR_CODE_TRANSACTION_ALREADY_REVIEWED_BY_PROVIDER =
  'transaction-already-reviewed-by-provider';
export const ERROR_CODE_TRANSACTION_BOOKING_TIME_NOT_AVAILABLE =
  'transaction-booking-time-not-available';
export const ERROR_CODE_PAYMENT_FAILED = 'transaction-payment-failed';
export const ERROR_CODE_CHARGE_ZERO_PAYIN = 'transaction-charge-zero-payin';
export const ERROR_CODE_CHARGE_ZERO_PAYOUT = 'transaction-charge-zero-payout';
export const ERROR_CODE_EMAIL_TAKEN = 'email-taken';
export const ERROR_CODE_EMAIL_NOT_FOUND = 'email-not-found';
export const ERROR_CODE_TOO_MANY_VERIFICATION_REQUESTS = 'email-too-many-verification-requests';
export const ERROR_CODE_UPLOAD_OVER_LIMIT = 'request-upload-over-limit';
export const ERROR_CODE_VALIDATION_INVALID_PARAMS = 'validation-invalid-params';
export const ERROR_CODE_VALIDATION_INVALID_VALUE = 'validation-invalid-value';
export const ERROR_CODE_NOT_FOUND = 'not-found';
export const ERROR_CODE_FORBIDDEN = 'forbidden';
export const ERROR_CODE_MISSING_STRIPE_ACCOUNT = 'transaction-missing-stripe-account';

const ERROR_CODES = [
  ERROR_CODE_TRANSACTION_LISTING_NOT_FOUND,
  ERROR_CODE_TRANSACTION_INVALID_TRANSITION,
  ERROR_CODE_TRANSACTION_ALREADY_REVIEWED_BY_CUSTOMER,
  ERROR_CODE_TRANSACTION_ALREADY_REVIEWED_BY_PROVIDER,
  ERROR_CODE_PAYMENT_FAILED,
  ERROR_CODE_CHARGE_ZERO_PAYIN,
  ERROR_CODE_CHARGE_ZERO_PAYOUT,
  ERROR_CODE_EMAIL_TAKEN,
  ERROR_CODE_EMAIL_NOT_FOUND,
  ERROR_CODE_TOO_MANY_VERIFICATION_REQUESTS,
  ERROR_CODE_UPLOAD_OVER_LIMIT,
  ERROR_CODE_VALIDATION_INVALID_PARAMS,
  ERROR_CODE_VALIDATION_INVALID_VALUE,
  ERROR_CODE_NOT_FOUND,
  ERROR_CODE_FORBIDDEN,
  ERROR_CODE_MISSING_STRIPE_ACCOUNT,
];

// API error
// TODO this is likely to change soonish
propTypes.apiError = shape({
  id: propTypes.uuid.isRequired,
  status: number.isRequired,
  code: oneOf(ERROR_CODES).isRequired,
  title: string.isRequired,
  meta: object,
});

// Storable error prop type. (Error object should not be stored as it is.)
propTypes.error = shape({
  type: propTypes.value('error').isRequired,
  name: string.isRequired,
  message: string,
  status: number,
  statusText: string,
  apiErrors: arrayOf(propTypes.apiError),
});

// Options for showing just date or date and time on BookingTimeInfo and BookingBreakdown
export const DATE_TYPE_DATE = 'date';
export const DATE_TYPE_DATETIME = 'datetime';

propTypes.dateType = oneOf([DATE_TYPE_DATE, DATE_TYPE_DATETIME]);

export { propTypes };

export const USER_ROLE_CLIENT = 'client';
export const USER_ROLE_PARTNER = 'partner';
export const USER_ROLE_CSM = 'csm';
export const INSIGHT_GIG = 'InsightGig';


export const userTypes = [USER_ROLE_CLIENT, USER_ROLE_PARTNER];
export const INBOX_TAB_PROPOSALS = 'proposals';
export const INBOX_TAB_BRIEFS = 'briefs';
export const INBOX_TAB_COLLABORATION = 'collaboration';
export const INBOX_TAB_PARTNER_COLLABORATION_PATHNAME = '/inbox/partner/collaboration';
export const INBOX_TAB_CLIENT_COLLABORATION_PATHNAME = '/inbox/client/collaboration';

//history types
export const HISTORY_PROPOSAL_UPDATED = 'history-proposal-updated';
export const HISTORY_MILESTONES_CREATED = 'history-milestones-added';
export const HISTORY_MILESTONES_CREATED_OPERATOR = 'history-milestones-added-operator';
export const HISTORY_MILESTONES_CREATED_AFTER_PREPAYMENT = 'history-milestones-added-after-prepayment';
export const HISTORY_MILESTONES_CREATED_AFTER_PREPAYMENT_OPERATOR = 'history-milestones-added-operator-after-prepayment';
export const HISTORY_MILESTONES_UPDATED = 'history-milestones-updated';
export const HISTORY_MILESTONES_UPDATED_OPERATOR = 'history-milestones-updated-operator';
export const HISTORY_MILESTONES_UPDATED_AFTER_PREPAYMENT = 'history-milestones-updated-after-prepayment';
export const HISTORY_MILESTONES_UPDATED_AFTER_PREPAYMENT_OPERATOR = 'history-milestones-updated-operator-after-prepayment';
export const HISTORY_MILESTONES_COMPLETED = 'history-milestones-completed';
export const HISTORY_MILESTONES_COMPLETED_OPERATOR = 'history-milestones-completed-operator';
export const HISTORY_MILESTONES_COMPLETED_AFTER_PREPAYMENT = 'history-milestones-completed-after-prepayment';
export const HISTORY_MILESTONES_COMPLETED_AFTER_PREPAYMENT_OPERATOR = 'history-milestones-completed-operator-after-prepayment';
export const HISTORY_MILESTONES_UNCOMPLETED = 'history-milestones-unfinished';
export const HISTORY_MILESTONES_UNCOMPLETED_OPERATOR = 'history-milestones-unfinished-operator';
export const HISTORY_MILESTONES_UNFINISHED_AFTER_PREPAYMENT = 'history-milestones-unfinished-after-prepayment';
export const HISTORY_MILESTONES_UNFINISHED_AFTER_PREPAYMENT_OPERATOR = 'history-milestones-unfinished-operator-after-prepayment';
export const HISTORY_MILESTONES_DELETED = 'history-milestones-deleted';
export const HISTORY_MILESTONES_DELETED_OPERATOR = 'history-milestones-deleted-operator';
export const HISTORY_MILESTONES_DELETED_AFTER_PREPAYMENT = 'history-milestones-deleted-after-prepayment';
export const HISTORY_MILESTONES_DELETED_AFTER_PREPAYMENT_OPERATOR = 'history-milestones-deleted-operator-after-prepayment';

//Pages
export const BecomeInsightGigPartnerPage = 'BecomeInsightGigPartnerPage';
export const ContactDetailsPage = 'ContactDetailsPage';
export const ProfileSettingsPage = 'ProfileSettingsPage';
export const FirmPage = 'FirmPage';
export const StripeSubscriptionPage = 'StripeSubscriptionPage';

//Roles
export const USER_ROLE_OWNER = 'Admin';
export const USER_ROLE_EDITOR = 'Team Member';
export const USER_ROLE_OWNER_STATUS = 'Active';

//InviteStatus

export const INVITE_STATUS_PENDING = 'Pending';
export const INVITE_STATUS_ACTIVE = 'Active';
export const INVITE_STATUS_EXPIRE = 'Expired';
export const INVITE_STATUS_REJECTED = 'Rejected';
export const INVITE_STATUS_CANCEL = 'Cancel';

// User Type
export const USER_TYPE_PARTNER = 'partner';
export const USER_TYPE_CLIENT = 'client';

//Listing type
export const LISTING_TYPE_FIRM = 'firm';
export const LISTING_TYPE_FIRM_PATHNAME = '/firm-user-management/';
export const DETAILS = '/details';
export const FIRM_NEW = '/firm/new';
export const JOB = 'job';

//Transaction process
export const TRANSACTION_BRIEF_PROCESS = 'brief-process';
export const TRANSACTION_PROPOSAL_PROCESS = 'proposal-process';
export const TRANSACTION_FINAL_PAYMENT_PROCESS = 'final-payment-process';
export const TRANSACTION_APPLY_JOB_PROCESS = 'apply-job-process';
export const TRANSACTION_JOB_DESCRIPTION_PROCESS = 'job-description-process';
export const TRANSACTION_PREPAYMENT_INDIA_PROCESS = 'prepayment-process-India';
export const TRANSACTION_GIG_APPS_PROCESS = 'gig-apps';

//Milestones

export const ADD_MILESTONE = 'addMilestone';
export const EDIT_MILESTONE = 'editMilestone';
export const DELETE_MILESTONE = 'deleteMilestone';
export const SELECT_MILESTONE = 'selectMilestone';
export const COMPLETE_MILESTONE = 'completeMilestone';
export const INCOMPLETE_MILESTONE = 'incompleteMilestone';
export const SELECTED = 'selected';

// Not Found
export const NOT_FOUND = 'Not Found';

//User Type
export const PROVIDER = 'Expert Lead';
export const CUSTOMER = 'Client Lead';
export const CUSTOMER_INVITEE = 'Client Invitee';
export const PROVIDER_INVITEE = 'Expert Invitee';
export const CSM_INVITEE = 'Csm Invitee';
export const THIRD_PARTY_EXPERT = 'Third Party Expert';
export const DELETED_USER = 'Deleted user';

//Message
export const MESSAGE = 'message';

//History
export const HISTORY = 'history'

//Packages
export const PACKAGES = 'packages';

//EngagementRoles
export const ENGAGEMENTROLES = "engagementRoles";

//TotalExperience
export const TOTALEXPERIENCE = "totalExperience";

//Primary Group
export const PRIMARY = 'primary';

//Subscription Names
export const FREE_PLAN = 'free-plan';
export const INSIGHT_GIG_ONE_PLAN_MONTHLY = 'InsightGig-One-Plan-Monthly';
export const INSIGHT_GIG_ONE_PLAN_YEARLY = 'InsightGig-One-Plan-Yearly';
export const PROFESSIONAL_MONTHLY = 'Professional-Monthly';
export const PROFESSIONAL_YEARLY = 'Professional-Yearly';
export const SMALL_TEAMS_MONTHLY = 'Small-Teams-Monthly';
export const SMALL_TEAMS_YEARLY = 'Small-Teams-Yearly';
export const SUBSCRIPTION_STATUS_ACTIVE = 'active';
export const SUBSCRIPTION_STATUS_CANCELLED = 'canceled';
export const MAX_BRIEF_LIMIT = 12;
export const MAX_GIG_APPS_LIMIT = 15;
export const MAX_COLLABORATORS_LIMIT = 9;

//PATH types
export const PUBLIC_BRIEF = 'Public Brief';
export const PRIVATE_BRIEF = 'Private Brief';
export const COLLABORATION_PATH_NAME = '/collaboration';
export const SUBSCRIPTION_PAGE_ROUTE = '/account/subscription';
export const PREFERRED_INSIGHTGIG_PARTNER_PAGE_PATH_NAME = '/favourites';
export const LISTING_PAGE = 'ListingPage';
export const GOOGLE_MEET_REDIRECT_PAGE = 'GoogleMeetRedirectPage';
export const EDIT_LISTING_PAGE = 'EditListingPage';
export const LISTING_TYPE_PRIVATE = 'private';
export const LISTING_TYPE_PUBLIC = 'public';
export const SIGNUP_PAGE = 'SignupPage'
export const SIGNUP_PAGE_PATHNAME = '/signup';
export const LOGIN_PAGE_PATHNAME = '/login';

export const IMAGE = 'image';
export const VIDEO = 'video';

//Route name
export const EDIT = '/edit/';
export const NEW = '/new/';
export const ONBOARD_USER_SUCCESS = '/onboard-user-success'
export const DISCOURSE_COMMUNITY_FORUM_PENDING_APPROVAL = '/account-status';
export const JOB_DESCRIPTION = '/job/';

//Gig apps
export const AI_TRANSCRIPTION = 'ai-transcription';
export const AI_BRIEF_GENERATOR = 'ai-brief-generator';
export const AI_QUESTIONNAIRE = 'ai-questionnaire';
export const AI_CONTRACT = 'ai-contract';
export const AI_PROPOSAL = 'ai-proposal';
export const AI_JOB_DESCRIPTION = 'ai-job-description';
export const AI_CONTENT_ANALYSIS = 'ai-content-analysis';
export const AI_DISCUSSION_GUIDE = 'ai-discussion-guide';
export const AI_QUERY_REPORT = 'ai-query-report';
export const AI_REPORT_GENERATOR = 'ai-report-generator';
export const AI_ANALYZE_RAW_QUANTITATIVE_CHAT = 'ai-analyze-raw-quantitative-chat';
export const AI_ASSISTED_CONTENT_ANALYSIS = 'ai-assisted-content-analysis';
export const AI_PERFECT_QUESTION = 'ai-perfect-question';
export const AI_SUGGEST_ANSWER_OPTION = 'suggest-answer-option';
export const AI_PRE_TEXT_PROCESSING = 'pre-text-processing';
export const AI_KEYWORD_EXTRACTION = 'ai-keyword-extraction';
export const AI_RESEARCH_INSTRUMENT_COVERAGE_INDICATOR = "ai-research-instrument-coverage-indicator";
export const AI_ANALYZE_RAW_QUANTITATIVE_DATA = "ai-analyze-raw-quantitative-data";
export const AI_TOPIC_MODELING = 'ai-topic-modeling';
export const AI_SENTIMENT_ANALYSIS = 'ai-sentiment-analysis';
export const AI_COMBINED_TOPIC_SENTIMENT = 'ai-combined_topic_sentiment';
export const AI_TEXTUAL_CONCEPT_CREATER = 'ai-concept-creator';
export const AI_RESEARCH_INSTRUMENT_TRANSLATOR = 'ai-reasearch-instrument-translator';
export const AI_OPEN_ENDED_RESPONSE_TRANSLATOR = 'ai-open-ended-response-translator';
export const AI_TRANSLATE_TRANSCRIPT = 'ai-translate-transcript';
export const AI_EXTRACT_QUESTIONS_FROM_TRANSCRIPTS = 'ai-extract-questions-from-transcripts';

export const ACCESS_LONG_TERM_TALENT = 'access-long-term-talent';
export const GET_STUFF_DONE = 'get-stuff-done';
export const CONNECT_WITH_PEERS = 'connect-with-peers';
export const TWEAK_YOUR_PROFILE = 'tweak-your-profile';
export const EXECUTIVE_INSIGHT_PROJECTS = 'execute-insight-projects';
export const ACCESS_LONG_TERM_INSIGHTS_TALENT = 'access-long-term-talent';
export const AD_HOC_INSIGHT_PROJECTS = 'ad-hoc-insight-projects';
export const LONG_TERM_INSIGHT_OPPORTUNITY = 'long-term-insight-opportunity';

//Button name
export const PRIVATE_BRIEF_BUTTON = 'Share Brief Privately';
export const PUBLIC_BRIEF_BUTTON = 'Post Brief Publicly';


//DestructorHelpers types

export const MONTH = 'month';
export const FREE_PLANS = 'freePlan';
export const PRIVATE_BRIEF_COUNT = 'privateBriefCount';
export const PUBLIC_BRIEF_COUNT = 'publicBriefCount';
export const PROFESSIONAL_INDVIDUAL_PLAN = 'professionIndividualPlan';
export const SMALL_TEAM_PLAN = 'smallTeamPlan';

// Engagement Roles
export const PROJECT_BASED = 'Ad-hoc projects';
export const SECONDMENT = 'Secondment';
export const FULL_TIME_EMPLOYMENT = 'Full-time employment';
export const SECONDMENT_EMPLOYEMENT = 'Employment / Secondment';
export const GIG_APP_BASED = "GigApps"

export const PROJECT_BASED_KEY = "projects";
export const SECONDMENT_KEY = "secondment";
export const GIG_APP_KEY = "gigapps"
export const FULLTIME_KEY = "fullTimeEmployment";

// Payment charge options

export const ONETIME_PAYMENT = 'ONETIME_PAYMENT';
export const PAY_AND_SAVE_FOR_LATER_USE = 'PAY_AND_SAVE_FOR_LATER_USE';
export const USE_SAVED_CARD = 'USE_SAVED_CARD';
export const STORAGE_KEY = 'CheckoutPage';

// Stripe PaymentIntent statuses, where user actions are already completed
// https://stripe.com/docs/payments/payment-intents/status
export const STRIPE_PI_USER_ACTIONS_DONE_STATUSES = ['processing', 'requires_capture', 'succeeded'];


//User role provider
export const USER_ROLE_PROVIDER = 'provider';
export const USER_ROLE_CUSTOMER = 'customer';

//Payment mode
export const ONLINE = 'online';
export const OFFLINE = 'offline';
export const SKIP_CONTRACT = 'skipContract';

//Pages Name
export const FAVOURITES_PAGE = 'FavouritesPage';
export const FIRM_EXPERT_LISTING_PAGE = 'FirmMemberExpertsPage';
export const SEARCH_PAGE = 'SearchPage';
export const TRANSACTION_DETAILS_PAGE = 'TransactionDetailsPage';
export const GIG_APPS_TRANSACTION_PAGE = 'GigAppsTransactionPage';
export const FIRM_DASHBOARD_PAGE = 'FirmDashBoardPage';
export const ZOOM_MEETING_FORM = 'zoomMeetingForm';
export const SUPPORT_PAGE = 'SupportPage';
export const DOCUMENTATION_PAGE = 'DocumentationPage';

//Apps Name
export const OPEN_AI_BRIEF_GENERATOR = 'AI Brief Generator';
export const OPEN_AI_QUESTIONNAIRE_GENERATOR = 'AI Questionnaire Generator';
export const OPEN_AI_TRANSCRIPTION_GENERATOR = 'AI Transcription';
export const OPEN_AI_CONTRACT_GENERATOR = 'AI Contract Generator';

// BackgroundImage,Logo
export const BACKGROUND_IMAGE = 'backgroundImage';
export const LOGO = 'logo';

//Listing Status
export const CLOSED = 'closed';

//TransactionPanel Tabs
export const OVERVIEW = "Overview";
export const CHAT = "Chat";
export const ACTIVITY = "Activity";
export const MILESTONES = "Milestones";
export const COLLABORATORS = "Collaborators";
export const TOOLS = "Tools";
export const FILES = "Files";

// Steps
export const OFFLINE_PAYMENT_STEP = 'prePaymentOffline'
export const OFFLINE_FINAL_PAYMENT_STEP = 'finalPaymentOffline'
export const PROJECT_COMPLETE_ALERT = 'complete'
export const PREPAYMENT_PAYMENT_STEP = 'prePaymentUsingStripe'
export const FINAL_PAYMENT_STEP = 'finalPaymentUsingStripe'
export const FIRM_INVITE = 'firmInvite'
export const REMOVE_FIRM_MEMBER = 'removeMemberFromFirm'
export const REMOVE_FIRM_MEMBER_FROM_TRANSACTION = 'removeMemberFromTransaction'
export const ACCEPT_PROPOSAL = 'acceptProposal'
export const OFFLINE_SUBSCRIPTION = 'offlineSubscription'
export const QUERY_REPORT = 'query-report'
export const COLLABORATOR_INVITE_STEP = 'collaboratorInvite'
export const CONTACT_US = 'contactUs'
export const NEW_USER = 'newUser'
export const CHAT_NOTIFICATION = 'chatNotification'
export const HOMEPAGE_NOTIFICATION = 'homePageNotification'
export const CSM_BANNER_PAGE_NOTIFICATION = 'csmBannerPageNotification'

//Email subject
export const OFFLINE_PAYMENT_SUBJECT = 'Proposal Accepted - Offline Payment - Action Required';
export const OFFLINE_FINAL_PAYMENT_SUBJECT = 'Project Completed - Offline Payment - Action Required';
export const PREPAYMENT_PAYMENT_SUBJECT = 'Proposal Accepted – Online Payment - Action Required';
export const FINAL_PAYMENT_SUBJECT = 'Project Completed – Online Payment - Action Required';
export const OFFER_SUPPORT = 'offerSupport'

//Pending Verification
export const PENDING_VERIFICATION = 'pending_verification';


// Stripe Country Types
export const INDIA = 'India';
export const OTHERS = 'Rest of the world';

//Card
export const CARD = 'card';

//Numbers
export const TWENTY = 20;
export const TEN = 10;


// Payment Intent authentication 
export const PAYMENT_INTENT_AUTHENTICATION_FAILURE = 'payment_intent_authentication_failure'
export const PAYMENT_INTENT = 'payment_intent';

//Actions Names
export const IS_FINAL = 'isFinal'
export const LOGIN = 'login';
export const SEND_PUBLIC_PROPOSAL = 'sendPublicProposal';
export const PUBLIC_PROPOSAL = 'publicProposal';
export const SEND_PRIVATE_PROPOSAL = 'sendPrivateProposal';
export const PRIVATE_PROPOSAL = 'privateProposal';
export const PROJECT_UNDERWAY = 'projectUnderway';
export const PROJECT_COMMISSIONED = 'projectCommissioned';
export const AVERAGE_RATING = 'averageRating';
export const PROJECT_COMPLETED = 'projectCompleted';
export const PUBLIC_POSTED_PROJECT = 'publiclyPostedProject';
export const PRIVATELY_SHARED_PROJECT = 'privatelySharedProject';

// Job Descripton Actions
export const PUBLIC_POSTED_JOB = 'publiclyPostedJobs';
export const PUBLIC_POSTED_JOB_APPLICATIONS = 'publiclyPostedJobApplications';
export const PRIVATELY_SHARED_JOB = 'privatelySharedJobs';
export const PRIVATELY_SHARED_JOB_APPLICATIONS = 'privatelySharedJobApplications';
export const RECRUITMENTS_UNDERWAY = 'recruitmentsUnderway';
export const RECRUITMENTS_DONE = 'recruitmentsDone';
export const PROFILE_IMAGE = 'profileImage';
export const GIG_APP_USAGE = 'gigAppUsage';
export const GIG_APP_VIEWS = 'gigAppViews';
export const GIG_APP_COMPLETED = 'gigAppCompleted';

export const UPLOAD_NOW = 'uploadNow';
export const GENERATE_NOW = 'generateNow';

export const CROSS_SIGN = 'crossSign';

export const ADMIN = 'Admin';
export const ZOOM_MEET = 'zoomMeet';
export const GOOGLE_MEET = 'googleMeet';

//Employement Types
export const FULL_TIME = 'Full-Time';
export const PART_TIME = 'Part-time';
export const FREELANCE = 'Independent Consultant';

//Affiliation Type
export const AFFILIATED_FIRM_INVITE_SENT = 'Invite sent';
export const AFFILIATED_FIRM_INVITE_PENDING = 'Invite pending';
export const AFFILIATED_FIRM_INVITE_RECEIVED = 'Invite received';
export const AFFILIATED_FIRM_INVITE_APPROVED = 'Invite approved';
export const AFFILIATED_FIRM_INVITE_REJECTED = 'Invite rejected';

//Charts Types
export const USERS = 'Users';
export const LOGINS = 'Logins';
export const AVERAGERATING = 'Average Rating';
export const COMMISSIONED_PROJECTS = 'Project Commissioned';
export const COMPLETED_PROJECTS = 'Project Completed';
export const UNDERWAY_PROJECTS = 'Project Underway';
export const PUBLIC_PROJECTS = 'Public Projects';
export const PRIVATE_PROJECTS = 'Private Projects';
export const PUBLIC_JOBS = 'Public Jobs';
export const PRIVATE_JOBS = 'Private Jobs';
export const APPLIED_PUBLIC_JOBS = 'Applications for Public Jobs'
export const APPLIED_PRIVATE_JOBS = 'Applications for Private Jobs'
export const ONGOING_RECRUITMENTS = 'Ongoing recruitments';
export const COMPLETED_RECRUITMENTS = 'Completed recruitments';
export const APPS_VIEWED = 'Gig apps viewed'
export const APPS_INITIATED = 'Gig apps Initiated'
export const APPS_COMPLETED = 'Gig apps Completed'

//Files types for Job Description Process
export const ACCEPT_FILE = 'application/pdf,.doc,.docx,.ppt,.pptx';


// Currency
export const INR = 'INR';
export const USD = 'USD';

//Currency symbol
export const INR_SYMBOL = '₹';
export const USD_SYMBOL = ' $';

//Image Object fit
export const OBJECT_FIT_CENTER = 'center';
export const OBJECT_FIT_FILL = 'fill';


// Notification Types

export const MILESTONE_NOTIFICATION = 'milestoneNotification';
export const TRANSACTION_NOTIFICATION = 'transactionNotification';
export const SCHEDULE_MEETING_NOTIFICATION = 'scheduleMeetingNotification';
export const COLLABORATOR_NOTIFICATION = 'collaboratorNotification';
export const JOB_NOTIFICATION = 'jobNotification';

//Transaction tabs
export const TRANSACTION_DETAIL_PAGE = 'details';
export const TRANSACTION_CHAT_PAGE = 'chat';
export const TRANSACTION_ACTIVITY_PAGE = 'activity';
export const TRANSACTION_MILESTONE_PAGE = 'milestone';
export const TRANSACTION_FILES_PAGE = 'files';
export const TRANSACTION_COLLABORATORS_PAGE = 'collaborators';


//Service categories
export const SERVICE_CATEGORY = 'service';
export const SOFTWARE_CATEGORY = 'software';
export const INDUSTRY_CATEGORY = 'industry';
export const INSIGHT_TECHNIQUES_OR_MODLES_CATEGORY = 'insightTechniquesOrModels';

// Website & Blog
export const WEBSITE = 'website';
export const BLOG = 'blog';

export const DRAFT_ID = '00000000-0000-0000-0000-000000000000';



// GigAppsTransactionPage
export const GIG_APPS_TRANSACTION_PAGE_GIG_APP_TAB = 'gig-app';
export const GIG_APPS_TRANSACTION_PAGE_CHAT_TAB = 'ask-and-chat';
export const GIG_APPS_TRANSACTION_PAGE_BOX_TAB = 'box-files';
export const GIG_APPS_TRANSACTION_PAGE_OVERVIEW_TAB = 'overview';
export const GIG_APPS_TRANSACTION_PAGE_MILESTONE_TAB = 'milestone';
export const GIG_APPS_TRANSACTION_PAGE_COLLABORATOR_TAB = 'collaborator';
export const GIG_APPS_TRANSACTION_PAGE_AI_ASSISTANT_TAB = 'ai-assist';


// Payment To
export const BOTH_EXPERT_AND_INSIGHTGIG = 'bothExpert&Insightgig';
export const ONLY_INSIGHTGIG = 'onlyInsightgig';