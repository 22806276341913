import React from 'react';
import ContentLoader from 'react-content-loader';

const SkeletonLoaderFirmModal = props => {
  const { viewport, isMobile, noOfSlides, isInboxPage } = props;

  let slideWidth = viewport && noOfSlides ? viewport.width / noOfSlides - 18 : 276;
  const slideViewBoxWidth = viewport && noOfSlides && viewport.width / noOfSlides;
  const LayoutMobile = typeof window !== 'undefined' && window.innerWidth < 576;

  if (slideWidth < 0) {
    slideWidth = 462
  };

  return (
    <ContentLoader
      speed={4}
      width={450}
      height={100}
      viewBox={`0 0 ${slideViewBoxWidth} 334`}
      backgroundColor="#f0f0f0"
      foregroundColor="#dedede"
      {...props}
    >
      <rect x="0" y="0" rx="8" ry="8" width="450" height="30" />
      <rect x="0" y="50" rx="8" ry="8" width="100" height="20" />
      <rect x="110" y="50" rx="8" ry="8" width="100" height="20" />
      <rect x="220" y="50" rx="8" ry="8" width="100" height="20" />
      <rect x="340" y="50" rx="8" ry="8" width="100" height="20" />
    </ContentLoader>
  )
};

export default SkeletonLoaderFirmModal;
