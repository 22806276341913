import React, { useCallback, useState } from 'react'
// import css from './EditListingFeaturesForm.module.css'
import classNames from 'classnames';
import { v4 as uuidv4 } from 'uuid';
import { handleClearClick, handleKeyPress, handleRemoveOption, handleSelectChanges, toggleDropdown } from './helperFunction';
import IconCard, { CROSS } from '../../components/IconCard/IconCard';
import { getSoftwareInsight } from '../../util/api';
import { Field } from 'react-final-form';
import { SOFTWARE_CATEGORY } from '../../util/types';

const SoftwareInsights = (props) => {
  const { values, form, intl, css } = props;
  const { searchSoftware = [] } = values || {};

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isUlVisible, setIsUlVisible] = useState(false);

  // HandleSearchChange
  const handleSearchChange = async (value) => {
    try {
      const response = await getSoftwareInsight({ value, category: SOFTWARE_CATEGORY });
      form.change('searchSoftware', response)
      setIsUlVisible(true);
    } catch (error) {
    }
  };
  const debounce = (func, delay) => {
    let timerId;
    return (...args) => {
      clearTimeout(timerId);
      timerId = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };
  const debouncedSearch = useCallback(debounce(handleSearchChange, 300), []);


  // sectionHeading  
  const sectionHeading = intl.formatMessage({
    id: "SoftwareInsights.softwareSectionHeading",
  });

  // inputPlaceholder  
  const inputPlaceholder = intl.formatMessage({
    id: "SoftwareInsights.softwareSectionPlaceholder"
  });
  // addButtonText  
  const addButtonText = intl.formatMessage({
    id: "SoftwareInsights.addSoftwareButtonText"
  });
  // softwareSectionDescription  
  const industrySectionDescription = intl.formatMessage({
    id: "SoftwareInsights.softwareSectionDescription"
  });
  // noRecordFound  
  const noRecordFoundText = intl.formatMessage({
    id: "SoftwareInsights.noRecordFoundText"
  });

  return (
    <div className={css.softwareInsightsContainer}>
      <div className={css.serviceHeading}>
        {sectionHeading}
      </div>
      <div className={css.releventService}>
        {industrySectionDescription}
      </div>

      <div className={classNames(css.inputSelectCard)}>
        {(values?.software || [])?.map((item) => (
          <span key={uuidv4()} className={css.industrySelected}>
            {item.label}
            <span
              className={css.closeItem}
              onClick={() => handleRemoveOption({ item, values, form, category: SOFTWARE_CATEGORY })}
            >
              &times;
            </span>
          </span>
        ))}
      </div>
      <button type='button' onClick={() => toggleDropdown(setIsDropdownOpen)} className={css.addSoftwareButton}>
        {addButtonText}
      </button>
      {isDropdownOpen ? (
        // <OutsideClickHandler onOutsideClick={handleClearClick} className={css.languageOutsideContainer}>
        <div className={css.inputSelect}>
          <Field name="softwareSearchKeyword">
            {({ input }) => (
              <div className={css.searchContainer}>
                {/* <IconCollection icon='SearchIcon' /> */}
                <input
                  {...input}
                  type="text"
                  autoComplete='off'
                  placeholder={inputPlaceholder}
                  onChange={(e) => {
                    input.onChange(e);
                    debouncedSearch(e.target.value);
                  }}
                  onKeyPress={(e) => handleKeyPress({ e, form, setIsUlVisible, setIsDropdownOpen, category: SOFTWARE_CATEGORY })}
                  autoFocus
                />

                <button
                  type="button"
                  className={css.clearSearchButton}
                  onClick={() => handleClearClick({
                    form,
                    setIsUlVisible,
                    setIsDropdownOpen
                  })}
                >
                  <IconCard brand={CROSS} />
                </button>
              </div>
            )}
          </Field>
          {isUlVisible && (
            <ul className={css.languagesDropdownBox}>
              {searchSoftware?.length > 0 ? searchSoftware?.map(item => (
                <li
                  className={values?.software?.some(val => val.label === item.name) ? css.selectedList : null}
                  key={item._id}
                  onClick={() =>
                    handleSelectChanges({ item: item.software, form, setIsUlVisible, setIsDropdownOpen, category: SOFTWARE_CATEGORY })}
                >
                  {item?.software}
                </li>
              ))
              : <li>{noRecordFoundText}</li>}
            </ul>
          )}
        </div>
        // </OutsideClickHandler>
      ) : null}
    </div>
  )
}

export default SoftwareInsights