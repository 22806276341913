import React, { useEffect, useState } from 'react';
import { bool, func } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import arrayMutators from 'final-form-arrays';
// These relative imports need to point to correct directories
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import {
  Form,
  FieldTextInput,
  PrimaryButton,
  FieldCheckbox,
  IconArrowDown,
  FieldSelect,
  IconCard,
  NamedLink,
  Modal,
} from '../../components';
import { getUserDetails, hasGoogleAccessToken } from '../../util/destructorHelpers';
import { GOOGLE_MEET_REDIRECT_PAGE, INVITE_STATUS_ACTIVE, ZOOM_MEETING_FORM } from '../../util/types'
import googleLoginIcon from '../../assets/btn_google_signin_light_normal_web@2x.png';

// Create this file using EditListingFeaturesForm.module.css
// as a template.
import css from './ZoomMeetForm.module.css';
import { FieldArray } from 'react-final-form-arrays';
import { getDefaultTimeZoneOnBrowser } from '../../util/dates';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';

 const ZoomMeetForm = props => (
  <FinalForm
    {...props}
    mutators={{
      ...arrayMutators,
    }}
    render={formRenderProps => {
      const {
        className,
        handleSubmit,
        updateInProgress,
        values,
        isOpen,
        form,
        collaborationMetaData,
        currentUserEmail,
        allDurations,
        onManageDisableScrolling,
        history,
        params,
        currentUser,
        currentTransaction
      } = formRenderProps;

      const [open, setOpen] = useState(false);
      const [openGoogleMeetModal,setOpenGoogleMeetModal] = useState(false);
      const [options, setOptions] = useState(() => {
        if (collaborationMetaData && collaborationMetaData.length > 0) {
          return [...collaborationMetaData];
        } else {
          return [];
        }
      });
      const classes = classNames(css.root, className);
      const submitInProgress = updateInProgress;
      const clientFullName = getUserDetails(currentTransaction?.customer)?.fullName;
      const clientEmail = getUserDetails(currentTransaction?.customer)?.email;
      const providerFullName = getUserDetails(currentTransaction?.provider)?.fullName;
      const providerEmail = getUserDetails(currentTransaction?.provider)?.email;
      const submitDisabled =  !values.title || !values.description || !values.date || !values.startTime || !values?.endTime 
      || (options?.length && !values.collaborators) || !hasGoogleAccessToken(currentUser);

      const currentHour = new Number(new Date().toTimeString().slice(0, 2));
      const currentMinute = new Date().toTimeString().slice(3, 5);
      const isTodayDateSelected = values.date === new Date().toISOString().slice(0, 10)

      function generateTimes(){
        const times = [];
        let minute = new Number(currentMinute);
        let minutesArray = [0, 15, 30, 45]
        let updatedMinute = isTodayDateSelected ? (minutesArray.find(e => e > minute) || 0) : 0;
        let hour = isTodayDateSelected 
          ? minutesArray.find(e => e > minute) === undefined ? currentHour+1 : currentHour
          : 0;
        
        let count = isTodayDateSelected ? ((24-hour) * 4 - minutesArray.findIndex(e => e === updatedMinute)) : 96 ;
        

        for (let i = 0; i < count; i++) {
          const time = `${hour?.toString().padStart(2, '0')}:${updatedMinute?.toString().padStart(2, '0')}`;
          times.push(time);

          updatedMinute += 15;
          if (updatedMinute === 60) {
            hour += 1;
            updatedMinute = 0;
          }
        }

        return times;
      }
      const times = generateTimes();
      const filteredTimeslots = times.findIndex((st) => st == values.startTime);
      const newTimeSlots = filteredTimeslots >= 0 ? times.slice(filteredTimeslots + 1, filteredTimeslots + 5) : times;
      useEffect(() => {
        if ( clientFullName && clientEmail && providerFullName && providerEmail) {
          setOptions([...options,
            { fullName: clientFullName, email: clientEmail, status: INVITE_STATUS_ACTIVE },
            { fullName: providerFullName, email: providerEmail, status: INVITE_STATUS_ACTIVE }
          ]);
        }
      }, [clientFullName, clientEmail, providerFullName, providerEmail]);
      
      useEffect(() => {
          if (!isOpen) {
            form.reset();
          }
        }, [!isOpen]);

      return (
        <Form className={classes} onSubmit={handleSubmit}>
        <h2 className={css.modalHeadingName}>
          <FormattedMessage id="ZoomMeetForm.scheduleHeading" />
        </h2>
        <FieldTextInput
          className={css.inputBox}
          type="text"
          id="title"
          name="title"
          label={'Title'}
          placeholder={'Enter the title of your calendar invite'}
        />
        <FieldTextInput
          className={css.inputBox}
          type="text"
          id="description"
          name="description"
          label={'Description'}
          placeholder={'Enter the purpose of the meeting'}
        />
        <div className={css.rowBox}>
          <FieldTextInput
            className={css.inputBox}
            type="date"
            id="date"
            name="date"
            label={'Starts'}
            placeholder={'startDateTime'}
            min={new Date().toISOString().slice(0, 10)}
          />
          <div className={css.formRow}>
            <div className={css.startField}>
              <span className={css.timeIcon}>
                <IconCard brand="time" />
              </span>
              <FieldSelect
                id={"startTime"}
                name={"startTime"}
                label={"Start"}
                selectClassName={css.fieldSelect}
                disabled={!values.date}
              >
                <option disabled value="">
                  09:00
                </option>
                {times.map((s,i) => (
                    <option value={s} key={i}>
                      {s}
                    </option>
                  )
                )}
              </FieldSelect>
            </div>
            <div className={css.field}>
              <span className={css.timeIcon}>
                <IconCard brand="time" />
              </span>
              <FieldSelect
                id={"endTime"}
                name={"endTime"}
                label={"Ends"}
                selectClassName={css.fieldSelect}
                disabled={!values.startTime}
              >
                <option disabled value="">
                  10:00
                </option>
                {newTimeSlots.map((s, i) => (
                  <option value={s} key={i}>
                    {s}
                  </option>
                ))}
              </FieldSelect>
            </div>
          </div>
        </div>
        {/* <div className={css.duration}>
          <FieldSelect
            className={css.fieldSelect}
            name="duration"
            id="duration"
            label="Duration"
            disabled={!values.startDateTime}
          >
            {allDurations &&
              allDurations.length &&
              allDurations.map(duration => (
                <option key={duration} value={duration}>
                  {duration} minutes
                </option>
              ))}
          </FieldSelect>
         
        </div> */}
        {options?.length ? (
          <div>
            <p className={css.conditionLabel} onClick={() => setOpen(!open)}>
              <FormattedMessage id="ZoomMeetForm.collaborators" />
            </p>
            <div className={css.conditionInputWrapper}>
              <div className={css.conditionDropDown}>
                {options?.length &&
                  options?.map(option => option.email !== currentUserEmail && option.status === INVITE_STATUS_ACTIVE &&
                    <FieldCheckbox
                      id={option.email}
                      className={css.dropdownListBox}
                      name={'collaborators'}
                      label={option.fullName}
                      value={option.email}
                    />
                  )}
              </div>
            </div>
          </div>
          ) : null
          }
        <div className={css.bottomWrapper}>
          <span className={css.buttonCancel} onClick={() => closeZoomMeetingModel()}>
            <FormattedMessage id="ZoomMeetPanel.cancelButton" />
          </span>
          <PrimaryButton
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
          >
            <FormattedMessage id="ZoomMeetForm.createMeeting" />
          </PrimaryButton>
        </div>
        <Modal 
          isOpen={openGoogleMeetModal}
          onClose={()=> setOpenGoogleMeetModal(false)}
          onManageDisableScrolling={onManageDisableScrolling}
          >
          <div className={css.panelHeading}>
            <img
              className={css.googleLoginIcon}
              src={googleLoginIcon}
              onClick={() => {
                typeof window !== 'undefined' &&  window.sessionStorage.setItem('params', JSON.stringify({...params, currentForm:ZOOM_MEETING_FORM}));
                history.push(
                  createResourceLocatorString(GOOGLE_MEET_REDIRECT_PAGE, routeConfiguration(), {})
                );
              }} 
            />
          </div>
        </Modal>
      </Form>
      );
    }}
  />
);

ZoomMeetForm.defaultProps = {
  selectedPlace: null,
  updateError: null,
};

ZoomMeetForm.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  updateInProgress: bool.isRequired,
};

export default compose(injectIntl)(ZoomMeetForm);
