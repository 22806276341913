import React from 'react'
import css from './OpenAIContentAnalysisForm.module.css';
import OptionMenu from './OptionMenu';
import { IconCard } from '../../components';
import { FormattedMessage } from 'react-intl';

function MappedQuestions(props) {
  const {questions, setQuestions, manageDisableScrolling, appRoute} = props;

  async function handleInputChange(e, index, name){
    const modifiedQuestions = questions?.length ? [...questions] : [];
    modifiedQuestions[index][name] = e.target.value;
    setQuestions(modifiedQuestions)
  }

  const handleDragStart = (e, index) => {
    e.dataTransfer.effectAllowed = 'move';
    e.dataTransfer.setData('index', index)
    const dragElement = e.target.firstChild;
    dragElement.style = 'cursor: grabbing';
  }

  const handleDragEnter = (e) => {
    e.currentTarget.style = 'background: rgba(0, 0, 0, 0.2)'
  }

  const handleDragLeave = (e) => {
    e.currentTarget.style = 'background: white'
  }
  
  const handleDrop = (e, dropIndex) => {
    e.preventDefault();
    const dragIndex = e.dataTransfer.getData('index');
    const newQuestions = [...questions];
    const [draggedQuestion] = newQuestions.splice(dragIndex, 1);
    newQuestions.splice(dropIndex, 0, draggedQuestion);
    setQuestions(newQuestions);
    e.currentTarget.style = 'background: white'
  };

  const isAnalyzeApp = appRoute==="ai-analyze-raw-quantitative-data"

  return (
    <>
      <div className={css.questionsHeading}>
        <h3><FormattedMessage id={isAnalyzeApp ? 'OpenAIAnalyzeRawDataForm.questionsHeadingLabel' : 'OpenAIContentAnalysisForm.questionsHeadingLabel'} /></h3>
        <h3><FormattedMessage id={isAnalyzeApp ? 'OpenAIAnalyzeRawDataForm.generatedHeadingLabel' : 'OpenAIContentAnalysisForm.generatedHeadingLabel'} /></h3>
        <h3><FormattedMessage id={isAnalyzeApp ? 'OpenAIAnalyzeRawDataForm.editableFieldLabel' : 'OpenAIContentAnalysisForm.editableFieldLabel'} /></h3>
      </div>
      <div className={css.questionsWrapper}>	
        {questions?.map((item, index) => {
          const {originalQuestion, modifiedQuestion, generatedQuestion=''} = item;
          return(
            <div key={index} className={css.row}>
              <div 
                className={css.left}
                draggable
                onDragStart={(e) => handleDragStart(e, index)}
                onDragOver={(e) => e.preventDefault()}
                onDragEnter={e => handleDragEnter(e)}
                onDragLeave={e => handleDragLeave(e)}
                onDrop={(e) => handleDrop(e, index,)}
              >
                <div className={css.dragIconWrapper}>
                  <span className={css.dragDots}>
                    <IconCard brand="dragDots" />
                  </span>
                  <span className={css.count}>{`${index+1}.`}</span>
                </div>
                {isAnalyzeApp 
                  ? 
                  <textarea
                    className={css.textareaHeight}
                    onChange={(e) => handleInputChange(e, index, 'originalQuestion')} 
                    value={originalQuestion}
                  ></textarea>
                  : 
                  <div className={css.question}>{originalQuestion}</div>
                }
              </div>
              {isAnalyzeApp 
              ? 
              <textarea
                className={css.textareaHeight}
                onChange={(e) => handleInputChange(e, index, 'generatedQuestion')} 
                value={generatedQuestion}
              ></textarea>
              :
              <div className={css.question}>{generatedQuestion}</div>
              }
              <textarea 
                className={isAnalyzeApp ? css.textareaHeight :""}
                onChange={(e) => handleInputChange(e, index, 'modifiedQuestion')} 
                value={modifiedQuestion}
              >
              </textarea>
              <OptionMenu 
                questions={questions} 
                index={index} 
                setQuestions={setQuestions}
                manageDisableScrolling={manageDisableScrolling}
                isAnalyzeApp={isAnalyzeApp}
              />
            </div>
          )
        })}
      </div>
    </>
  )
}

export default MappedQuestions