import React, { useState } from 'react';
import Modal from '../Modal/Modal';
import { manageDisableScrolling } from '../../ducks/UI.duck';
import { useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { handleUserModeData } from '../../containers/OpenAIAppsPage/openAIAppsPageHelperFunction';
import css from './OpenAIAppsPanel.module.css';
import Button, { SecondaryButton } from '../Button/Button';
import { AI_TRANSCRIPTION, GIG_APPS_TRANSACTION_PAGE_GIG_APP_TAB, } from '../../util/types';

const OpenAIDIYAndAssitedModal = props => {
  const {
    isOpenAIAppsAssistedModal,
    setIsOpenAIAppsAssistedModal,
    currentAppData,
    isClient,
    isCsm,
    history,
    isUserSubscribed,
    assistedFlowInProgress,
    currentUser,
    setOpenAlertModal,
    appRoute,
    hasMacroAppAccess,
  } = props;

  const [isAssistedMode, setAssistedMode] = useState(false);
  const { name: title, description, listingId } = currentAppData || {};
  const dispatch = useDispatch();
  
  const handleChange = event => {
    setAssistedMode(event.target.value === 'assisted');
  };

  const handleSubmit = (e) => {
    handleUserModeData({
      isAssistedMode,
      title,
      description,
      isClient,
      isCsm,
      appRoute,
      isUserSubscribed,
      history,
      dispatch,
      listingId,
      tab: GIG_APPS_TRANSACTION_PAGE_GIG_APP_TAB,
      currentUser,
      setOpenAlertModal,
      hasMacroAppAccess,
      setIsOpenAIAppsAssistedModal,
    })
  }

  return (
    <div>
      <Modal
        id="openAIAppsAssistedModal"
        className={css.disableModalBorder}
        contentClassName={css.containerClassName}
        isOpen={isOpenAIAppsAssistedModal}
        onClose={() => setIsOpenAIAppsAssistedModal(false)}
        usePortal
        onManageDisableScrolling={(componentId, disableScrolling) =>
          dispatch(manageDisableScrolling(componentId, disableScrolling))
        }
      >
        <div className={css.changePlanContent}>
          <h2 className={css.headingTitle}>
            <FormattedMessage id="OpenAiAppsPage.openAIAppsAssistedModalHeading" />
          </h2>
          <p className={css.downgradeHeading}>
            <FormattedMessage id="OpenAiAppsPage.openAIAppsAssistedModalDescription" />
          </p>
          <div className={css.radioWrapper}>
            <div className={css.radioButton}>
              <input
                id="diy"
                type="radio"
                value="diy"
                checked={!isAssistedMode}
                onChange={handleChange}
                className={css.radioCheck}
              />
            </div>
            <div className={css.radioDetails}>
              <label htmlFor="diy">
                <FormattedMessage id="OpenAiAppsPage.diyLabel" />
              </label>
              <div className={css.radioDescription}>
                <FormattedMessage id="OpenAiAppsPage.diyDescription" />
              </div>
            </div>
          </div>
          <div className={css.radioWrapper}>
            <div className={css.radioButton}>
              <input
                id="assisted"
                type="radio"
                value="assisted"
                checked={isAssistedMode}
                onChange={handleChange}
                className={css.radioCheck}
              />
            </div>
            <div className={css.radioDetails}>
              <label htmlFor="assisted">
                <FormattedMessage id="OpenAiAppsPage.assistedLabel" />
              </label>
              <div className={css.radioDescription}>
                <FormattedMessage id="OpenAiAppsPage.assistedDescription" />
              </div>
            </div>
          </div>
        </div>
        <div className={css.openAIAppsAssistedButtonActions}>
          <SecondaryButton
            onClick={() => setIsOpenAIAppsAssistedModal(false)}
            className={css.cancelLink}
          >
            <FormattedMessage id="OpenAiAppsPage.closeButtonText" />
          </SecondaryButton>
          <Button
            className={css.continueButton}
            inProgress={assistedFlowInProgress}
            onClick={handleSubmit}
          // disabled={!paymentType}
          >
            <FormattedMessage id="OpenAiAppsPage.continueButtonText" />
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default OpenAIDIYAndAssitedModal;
