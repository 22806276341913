import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// import config from '../../config.js';
import { FormattedMessage } from '../../util/reactIntl';
import { ensureOwnListing } from '../../util/data';
// import { findOptionsForSelectFilter } from '../../util/search';

import { EditListingHintTooltip, EditListingProgress, ListingLink } from '../../components';
// import { EditListingCapacityForm } from '../../forms';

// Create this file using EditListingDescriptionPanel.module.css
// as a template.
import css from './AdditionalInformationPanel.module.css';
// import EditListingPackagesForm from '../../forms/EditListingPackagesForm/EditListingPackagesForm.js';
import { AttachmentForm } from '../../forms/index.js';
import { useDispatch } from 'react-redux';

const AdditionalInformationPanel = props => {
  const {
    className,
    rootClassName,
    listing,
    onSubmit,
    ready,
    // onChange,
    submitButtonText,
    // panelUpdated,
    updateInProgress,
    // errors,
    completedTabsPer,
    isListingPublished,
    currentUserName,
    onUpdateListing,
    firmName,
  } = props;

  const dispatch = useDispatch();
  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);
  const { title, description, publicData } = currentListing.attributes;
  const {attachmentsUrls} = publicData || {};
  
  const panelTitle = currentListing.id ? (
    <FormattedMessage
      id="AdditionalInformationPanel.title"
      values={{ listingTitle: <ListingLink listing={listing} /> }}
    />
  ) : (
    <FormattedMessage id="AdditionalInformationPanel.createListingTitle" />
  );

  return (
    <div className={classes}>
      {/* <EditListingProgress progressCount={completedTabsPer} value={completedTabsPer} /> */}
      <h2 className={css.title}>{panelTitle}</h2>
      <EditListingHintTooltip
        tooltipText={<FormattedMessage id="AdditionalInformationPanel.UploadEngagingDocuments" />}
      />
      <p><FormattedMessage id='AdditionalInformationPanel.description' /></p>
      <AttachmentForm
        initialValues={{
          attachmentsUrls,
        }}
        onSubmit={values => {
          const { attachmentsUrls } = values;
          const updatedValues = {
            publicData: { attachmentsUrls: attachmentsUrls },
          };
          onSubmit(updatedValues)
        }}
        submitButtonText={submitButtonText}
        attachmentsUrls={attachmentsUrls?.length ? attachmentsUrls : []}
        updateInProgress={updateInProgress}
        ready={ready}
      />
    </div>
  );
};

const { func, object, string, bool } = PropTypes;

AdditionalInformationPanel.defaultProps = {
  className: null,
  rootClassName: null,
  listing: null,
};

AdditionalInformationPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default AdditionalInformationPanel;