import React, { useEffect, useRef, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import css from './GigAppTransactionTabPanel.module.css'
import FileView from '../../FileView/FileView'
import LoadingDots from '../../LoadingDots/LoadingDots'
import classNames from 'classnames'
import { PrimaryButton } from '../../Button/Button'
import { AI_CONTENT_ANALYSIS, AI_REPORT_GENERATOR } from '../../../util/types';
import { generateQuestionPills, getFolderAnalyis, handleWeavaiteDatabase } from '../../../util/api'
import { getFolderAnalysis, handleQueryFromOpenai, handleStopStreamResponse, loadViewerComponent } from '../../OpenAIAppsPanel/gigAppsHelperFunction'
import LinesLoader from '../../LinesLoader/LinesLoader'
import useDownloadContent from '../../../hooks/useDownloadContent'
import IconCard from '../../IconCard/IconCard';
import useFetchQueryReportData from '../../../hooks/useFetchQueryReportData'
import useTypeWriterEffect from '../../../hooks/useTypeWriterEffect';
import moment from 'moment';
import IconSpinner from '../../IconSpinner/IconSpinner'

const Loader = ({fetch, type, id}) => {
  if(fetch?.type === type && !!fetch?.isLoading){
    return <IconSpinner strokeColor='orange'/>
  }
  else {
    return <FormattedMessage id={id} />
  }
}

const GigAppQueryReportSection = (props) => {
  const { params, currentUser, gigAppInputData} = props;
  const {tab, fileId: id } = params || {};

  const storedChats = localStorage.getItem(id);
  const previousChats = storedChats && Array.isArray(JSON.parse(storedChats))
    ? JSON.parse(storedChats)
    : [];  
  
  const [question, setQuestion] = useState('');
  const [chatResponse, setChatResponse] = useState(previousChats);
  const [showInput, setShowInput] = useState(!chatResponse?.length);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const viewerRef = useRef(null);
  const answerRef = useRef(null);
  const chatContainerRef = useRef(null);
  const [convoQuestions, setConvoQuestions] = useState([]);
  const [showConvoQuestion, setShowConvoQuestion] = useState(false);
  const convoQuestionsRef = useRef();
  const [lastChatItem, setChatLastItem] = useState({});

  const isContentAnalysisApp = tab === AI_REPORT_GENERATOR;
  const appTitle = isContentAnalysisApp ? "Report Idea Generator" : "Query a Report"
  const [fetch, downloadFetchContent] = useDownloadContent();

  const [sessionData, fetchData] = useFetchQueryReportData(handleError);

  const {uploadedFiles = [], uploadedFolder = [], multipleFolders, createdAt: createdDate, runName = '', expiresAt: expiryDate, email } = sessionData || {};
  const files = isContentAnalysisApp ? uploadedFiles?.slice(0, 1) : uploadedFiles;

  const [currentText, setCurrentText, isRunningRef, questionIndex, charIndex, handleTypeWriter] = useTypeWriterEffect()

  function loadOldChats(){
    const oldChats = chatResponse?.length 
      ? chatResponse.map(({ question, answer }) => `___You___\n${question}\n\n___Response___\n${answer}`).join('\n___\n') : '';
      
    if (viewerRef.current && chatContainerRef.current) {
      viewerRef.current.setMarkdown(oldChats, false);

      chatContainerRef.current.scrollTo({
        top: chatContainerRef.current.scrollHeight,
        behavior: 'smooth'
      });
    }
  }

  useEffect(() => {
    try {
      async function fetchQuestionPills(data){
        if(!data?.summary) return //Return if summary is not present
        sessionStorage.setItem('proposalSummary', data.summary)

        const response = await generateQuestionPills({id, isTransaction: true, email: data.email, proposal: data.summary})
        if(response.status === 'success'){
          setConvoQuestions(response.questions)
          setShowConvoQuestion('show')
        }
      }

      fetchData(id, true).then(response => {
        fetchQuestionPills(response);
      });

      const script = loadViewerComponent(viewerRef, answerRef, loadOldChats);

      return () => {
        // Cleanup: Remove the script when the component unmounts
        document.body.removeChild(script);
        sessionStorage.removeItem('proposalSummary')
      };
    }
    catch (error) {
      console.log(error)
    }
  }, []);

  function handleError(message) {
    setError(message)
    setTimeout(() => setError(false), 3000)
  }

  const fetchSimilarContext = async (question) => {
    const filteredFiles = uploadedFiles.filter((file, index) => !!file.fileName && (!isContentAnalysisApp || index !== 0));

    const promises = filteredFiles.map(async (file) => {
      const { fileName } = file || {};

      const { response, error } = await handleWeavaiteDatabase({
        actionType: 'fetchSimilarContext',
        query: question,
        sessionId: id,
        fileName: fileName,
      });
      if (error) throw new Error(error);
      const chunks = response.data.Get.ReportChunks;
      const modifiedChunks = chunks.map(item => item.chunk).join('\n');
      return `${fileName} - ${modifiedChunks}`;
    });

    return await Promise.all(promises);
  };

  function handleLoadingNextQuestion(question){
    setShowConvoQuestion(false);
    setIsLoading(true);
    setQuestion('');
    setChatLastItem({question: question, answer: `<div class=${css.loader}></div>`});
    setShowInput(false);
  }

  async function generateExpansionQuestions(proposalSummary, question){
    const response = await generateQuestionPills({id, proposal: proposalSummary, query: question, isContentAnalysisApp, email})
    if(response.status === 'success' && Array.isArray(response?.questions)){
      return [...response.questions, question]
    }
  }

  function handleGenerateConversationPills(proposalSummary, question){
    generateQuestionPills({id, proposal: proposalSummary, lastQuery: question, isContentAnalysisApp, email}).then(response => {
      if(response.status === 'success'){
        convoQuestionsRef.current = response.questions?.toSpliced(4);
        setConvoQuestions(response.questions?.toSpliced(4))
      }
    })
  }

  function addLastItemToChatResponse(value){
    setChatResponse(prev => {
      const modifiedChat = [...prev, value];

      const content = modifiedChat.map(({ question, answer }) => (
        `___You___\n${question}\n\n___Response___\n${answer}`
      )).join('\n___\n');

      if (viewerRef.current) {
        answerRef.current.setMarkdown('', false);
        viewerRef.current.setMarkdown(content, false);
      }
      
      //Scroll the chat container to bottom
      const lastItemIndex = modifiedChat?.length - 1;
      const data = modifiedChat[lastItemIndex].answer;
      if (chatContainerRef.current && ((data?.length > 0) || data.includes('<div'))) {
        chatContainerRef.current.scrollTo({
          top: chatContainerRef.current.scrollHeight,
          behavior: 'smooth'
        });
      }

      localStorage.setItem(id, JSON.stringify(modifiedChat))
      return modifiedChat
    })
  }

  async function handleQueryQuestion(question) {
    if(isLoading) return
    try{
      handleLoadingNextQuestion(question);

      const proposalSummary = sessionStorage.getItem('proposalSummary');
      if (!proposalSummary?.length) {
        throw new Error('Proposal summary not found!')
      }
      //Fetch query expansion questions
      const expandedQuestions = await generateExpansionQuestions(proposalSummary, question);

      //Re-fetch the conversation pills questions
      handleGenerateConversationPills(proposalSummary, question)

      const result = await Promise.all(
        expandedQuestions?.map(async (question) => await fetchSimilarContext(question))
      );

      let type, modifiedData; 
      if(uploadedFiles?.length === 1){
        type = tab
        modifiedData = result.flat()
      }
      else if(uploadedFiles?.length > 1){    
        const contextData = expandedQuestions.reduce((acc, curr) => {
          const fileData = result[expandedQuestions.indexOf(curr)];
          
          const modifiedFilesData = fileData.map(data => {
            const fileName = data.split('-')?.[0];
            const context = data.slice(0);
            return {fileName, context}
          })
          acc[curr] = modifiedFilesData
          return acc
        }, {});

        type = 'ai-query-multiple-report'
        modifiedData = contextData  
      }
  
      await handleQueryFromOpenai({
        id, email,
        type,
        modifiedData,
        chatResponse,
        question: expandedQuestions,
        summary: proposalSummary,
        setChatLastItem,
        setIsLoading,
        addLastItemToChatResponse,
        title: appTitle
      })
      isRunningRef.current = true
      setShowConvoQuestion(true)
      handleTypeWriter(convoQuestionsRef.current)
    }catch(error){
      handleError(error.message)
    }
  }

  async function handleConsolidatedQueryQuestion(question) {
    if(isLoading) return
    try{
      handleLoadingNextQuestion(question);

      const proposalSummary = sessionStorage.getItem('proposalSummary');
      if (!proposalSummary?.length) {
        throw new Error('Proposal summary not found!')
      }
      
      //Re-fetch the conversation pills questions
      handleGenerateConversationPills(proposalSummary, question)

      const promise = uploadedFolder.map(async item =>{
        const {folderName, files, summary:folderSummary}= item
        const extendedQuestions =  await generateExpansionQuestions(folderSummary, question)
    
        const filesAnalysis = await Promise.all(
          extendedQuestions?.map(async (question) => await fetchSimilarContext(question, files))
        );

        const contextData = extendedQuestions.reduce((acc, curr) => {
          const fileData = filesAnalysis[extendedQuestions.indexOf(curr)];
          const modifiedFilesData = fileData.map(data => {
            const fileName = data.split('-')?.[0];
            const context = data.slice(0);
            return {fileName, context}
          })
          acc[curr] = modifiedFilesData
          return acc
        }, {});

        const folderAnalysis = await getFolderAnalysis({
          summary: folderSummary, 
          questions: extendedQuestions, 
          contextData, id, appRoute: "query-report-folder-analysis", 
          email, title: appTitle
        })
        const responseData = await folderAnalysis.json();
        return {folderName, analysis: responseData}
      })
      const modifiedData = await Promise.all(promise);
      
      await handleQueryFromOpenai({
        id,
        type: "ai-query-multi-folder-consolidation",
        modifiedData,
        chatResponse,
        question,
        setChatLastItem,
        setIsLoading,
        addLastItemToChatResponse,
        consolidation: true,
        title: appTitle
      })
      isRunningRef.current = true
      handleTypeWriter(convoQuestionsRef.current)
      setShowConvoQuestion(true)
    }catch(error){
      console.log(error);
      handleError(error.message)
    }
  }
  
  async function handleContentAnalysisQuery(question) {
    if(isLoading) return
    try{
      handleLoadingNextQuestion(question);

      const proposalSummary = sessionStorage.getItem('proposalSummary');
      let modifiedData, type, input;

      if (proposalSummary?.length) {
        //Fetch query expansion questions
        const expandedQuestions = await generateExpansionQuestions(proposalSummary, question);

        //Re-fetch the conversation pills questions
        handleGenerateConversationPills(proposalSummary, question)

        modifiedData = await Promise.all(
          expandedQuestions?.map(async (question) => await fetchSimilarContext(question))
        );
        type = tab;
        input = expandedQuestions;
      } 
      else {
        const finalContent = await fetchSimilarContext(question);
        modifiedData = finalContent.join('\n');
        type = 'ai-report-generator-without-proposal';
        input = [question];
      }
      
      await handleQueryFromOpenai({
        id, email, 
        type,
        question: input,
        modifiedData,
        chatResponse,
        setChatLastItem,
        addLastItemToChatResponse,
        setIsLoading,
        title: appTitle
      });
      isRunningRef.current = true
      setShowConvoQuestion(true)
      handleTypeWriter(convoQuestionsRef.current)
    } catch (error) {
      console.log(error, 'error submitting question!');
      handleError(error.message);
    }
  }

  const handleSubmit = isContentAnalysisApp ? handleContentAnalysisQuery : (multipleFolders ? handleConsolidatedQueryQuestion : handleQueryQuestion);

  useEffect(() => {
    try{
      if(!!Object.entries(lastChatItem)?.length && answerRef.current){
        const content = `___You___\n${lastChatItem?.question}\n\n___Response___\n${lastChatItem?.answer}`  
  
        answerRef.current.setMarkdown(content, false);
  
        if ((lastChatItem?.answer?.length % 10 === 0) || lastChatItem?.answer.includes('<div')) {
          chatContainerRef.current.scrollTo({
            top: chatContainerRef.current.scrollHeight,
            behavior: 'smooth'
          });
        }
      }
    }catch(e){
      // console.log(e)
    }
  }, [lastChatItem])

  //Download chat in pdf or doc
  async function handleDownloadContent(type) {
    const filesName = files.map((file, index) => {
      const fileName = file.fileName;
      return `- ${fileName}`
    })
    const filesNameMarkdown = `**Date:** ${moment().format('LLL')}\n\n**Uploaded Files:**\n\n${filesName.join('\n')}\n\n`;

    const content = chatResponse.map(({question, answer}) => (
      `___You___\n\n${question}\n\n___Response___\n\n${answer}`    
    )).join('\n\n___\n\n');

    const result = filesNameMarkdown + content;
    downloadFetchContent(type, result)
  }

  const handleConvoQuestionClick = (question) => {
    setQuestion(question)
    handleSubmit(question)
  }

  //Stop stream and show Conversation pills transition
  const handleStartPillTransition = () => {
    handleStopStreamResponse(id)
    setIsLoading(false)
    isRunningRef.current = true
    setShowConvoQuestion(true)
    handleTypeWriter(convoQuestionsRef.current)
  }
  
  //Stop conversation pills transition
  const handleStopPillTransition = () => {
    questionIndex.current = 0
    charIndex.current = 0
    isRunningRef.current = false
    setShowConvoQuestion('show')
    setCurrentText('')
  }

  // Delete stored chat in history
  const handleDeleteHistory = () => {
    localStorage.removeItem(id);
    setChatResponse([])
    if (viewerRef.current) {
      viewerRef.current.setMarkdown('', false);
      setShowInput(true);
      setShowConvoQuestion('show');
    }
  }

  const displayFiles = (folders) => {
    let isMasterFolder = folders?.some(item => item.folderName === "masterFolder")
    let masterFolderFiles = isMasterFolder && folders.find(item => item.folderName === "masterFolder").files
    let isSubFolders = folders?.length > (isMasterFolder ? 1 : 0)
    let subFolderFiles = isSubFolders && folders.filter(item => item.folderName !== "masterFolder")

    return (
      <div>
        {isMasterFolder && <h3>Master Folder</h3>}
        {isMasterFolder && masterFolderFiles?.map((file, index) => (
          <div className={css.subFolderdiv} key={file.name}>
            <span>
              <FileView file={{name: file.fileName, link: '', size: file.size, date: file.createdAt}} />
            </span>
          </div>
        ))}
        {isSubFolders && <h3>Sub folders</h3>}
        {isSubFolders && subFolderFiles?.map((item) => (
          <div key={item.folderName} className={css.subFolderdiv}>
            <p>{item.folderName}</p>
            {item.files?.map((file, index) => (
              <span key={file.name}>
                <FileView file={{name: file.fileName, link: '', size: file.size, date: file.createdAt}}/>
              </span>
            ))}
          </div>
        ))}
      </div>
    );
  };

  return (
    <div>
      <div className={css.briefGenerator}>
        <span className={css.generatorTitle}>
          <FormattedMessage id={
            isContentAnalysisApp ? 'OpenAIQueryReportPage.analysisAppHeading' : 'OpenAIQueryReportPage.reportAppHeading'}
          />
          {runName && `: ${runName}`}
        </span>
      </div>
      <div className={css.files}>
        {multipleFolders ? 
          (uploadedFolder.length ? displayFiles(uploadedFolder) : (<LinesLoader />)) 
        :
          files?.length > 0 ? files?.map((file, index) => {
            const {fileName, size, createdAt} = file;
            return(
              <React.Fragment key={fileName}>
                <FileView 
                  file={{name: fileName, link: '', size: size, date: createdAt}} 
                  className={css.fileView}
                />
              </React.Fragment>
            )
          }) : <LinesLoader />
        }
      </div>
      {!!chatResponse?.length && !isLoading && (
        <div className={css.exportButtons}>
          <button onClick={() => handleDownloadContent('docx')}>
            <Loader type='docx' id="TextEditor.exportDocButton" fetch={fetch}/>
          </button>
          <button onClick={() => handleDownloadContent('pdf')}>
            <Loader type='pdf' id="TextEditor.exportPdfButton" fetch={fetch}/>
          </button>
          <button onClick={handleDeleteHistory}>Delete History</button>
        </div>
      )}
      <div className={classNames(css.fontStyling, (!!chatResponse?.length || isLoading) && css.chatContainer)} ref={chatContainerRef}>
        <div ref={viewerRef} id="viewer"></div>
        <div ref={answerRef} id="answer"></div>
      </div>
      {error && <p className={css.error}><FormattedMessage id='OpenAiAppsPanel.errorMessage'/></p>}
      {isLoading && (
        <div className={css.stopButton} onClick={handleStartPillTransition}>
          <div><span></span></div>
        </div>
      )}
      {!!convoQuestions?.length && showConvoQuestion === 'show' &&(
        <div className={css.convoQuestionsContainer}>
          {convoQuestions.map(question => {
            return(
              <div key={question} onClick={() => handleConvoQuestionClick(question)}>
                {question}
                <span className={css.downArrow} onClick={(e) => {
                  e.stopPropagation()
                  setQuestion(question)
                  setShowInput(true)
                }}>
                  <IconCard brand='downArrow'/>
                </span>
              </div>
            )
          })}
        </div>
      )}
      {!showInput ? chatResponse?.length > 0 && (
        <div className={css.buttonWrapper}>
          {(showConvoQuestion === true) ? (
            <div className={css.typedtext} onClick={handleStopPillTransition}>
              {currentText}
            </div>
          ) : <div></div>}
          {!isLoading && (
            <div className={css.chatButton}>
              <button
                type='button' 
                className={css.clearButton} 
                onClick={() => {
                  handleStopPillTransition()
                  setChatResponse([])
                  setShowInput(true)
                }}
              >
                Clear
              </button>
              <PrimaryButton
                type='button' 
                className={css.askQuestionButton} 
                onClick={() => {
                  handleStopPillTransition()
                  setShowInput(true)
                }}
                disabled={isLoading || showInput}
              >
                Ask another question
              </PrimaryButton>
            </div>
          )}
        </div>
      ) : (
        <>
          <div className={css.questionInputWrapper}>
            <label><FormattedMessage id='OpenAIQueryReportPage.questionLabel' /></label>
            <div className={css.questionBar}>
              <textarea
                className={classNames(css.textarea, isLoading && css.disableInput)} 
                id='question'
                name='question'
                disabled={isLoading}
                value={question}
                onChange={e => {
                  const textarea = document.getElementById('question');

                  textarea.addEventListener('input', function() {
                    if(question === '') this.style.minHeight = 'unset';
                    this.style.maxHeight = '40px'; 
                    this.style.minHeight = this.scrollHeight + 'px';
                  });
                  setQuestion(e.target.value)
                }}
                onKeyPress={e => e.key === 'Enter' && handleSubmit(question)}
                row={1}
              ></textarea>
              <PrimaryButton
                type='button' 
                className={css.submitButton} 
                disabled={!question}
                onClick={() => handleSubmit(question)}
              >
                {!isLoading 
                  ? <FormattedMessage id="OpenAIContractForm.submitButtonText" />
                  : <LoadingDots className={css.loadingDots}/>
                }
              </PrimaryButton>
            </div>
            <div className={css.chatButton}>
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default GigAppQueryReportSection