import React from 'react';
import { string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { NamedLink } from '../../components';

import logo from './images/bigLogo.png';
import css from './SectionNetwork.module.css';
import advantage1 from './images/advantage1.jpg';
import advantage2 from './images/advantage2.jpg';
import advantage3 from './images/advantage3.jpg';
import advantage4 from './images/advantage4.jpg';
import galleryImage1 from './images/galleryImage1.jpg';
import galleryImage2 from './images/galleryImage2.jpg';
import galleryImage3 from './images/galleryImage3.jpg';
import galleryImage4 from './images/galleryImage4.jpg';

const SectionNetwork = props => {
  const { rootClassName, className } = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div className={css.content}>
        <div className={css.about}>
          <div className={css.logo}>
            <img src={logo} alt="logo" />
          </div>
          <h2 className={css.title}>
            <FormattedMessage id="SectionNetwork.title" />
          </h2>
          <p className={css.paragraph}>
            <FormattedMessage id="SectionNetwork.text" />
          </p>
          <NamedLink name="SignupPage" className={css.signupLink}>
            <FormattedMessage id="SectionNetwork.signupLink" />
          </NamedLink>
        </div>
        <div className={css.contentBlock}>
          <div className={css.contentBlockText}>
            <h4 className={css.blockTitle}>
              <FormattedMessage id="SectionNetwork.blockTitle1" />
            </h4>
            <div className={css.advantage}>
              <FormattedMessage id="SectionNetwork.advantage1" />
            </div>
            <div className={css.advantage}>
              <FormattedMessage id="SectionNetwork.advantage2" />
            </div>
            <div className={css.advantage}>
              <FormattedMessage id="SectionNetwork.advantage3" />
            </div>
          </div>
          <div className={css.contentBlockImage}>
            <img src={advantage1} alt="advantage" />
          </div>
        </div>
        <div className={css.contentBlock}>
          <div className={css.contentBlockText}>
            <h4 className={css.blockTitle}>
              <FormattedMessage id="SectionNetwork.blockTitle2" />
            </h4>
            <div className={css.advantage}>
              <FormattedMessage id="SectionNetwork.advantage4" />
            </div>
            <div className={css.advantage}>
              <FormattedMessage id="SectionNetwork.advantage5" />
            </div>
            <div className={css.advantage}>
              <FormattedMessage id="SectionNetwork.advantage6" />
            </div>
            <div className={css.advantage}>
              <FormattedMessage id="SectionNetwork.advantage7" />
            </div>
            <div className={css.advantage}>
              <FormattedMessage id="SectionNetwork.advantage8" />
            </div>
            <NamedLink name="SearchPage" to={{ search: ' ' }} className={css.learnMore}>
              <FormattedMessage id="SectionNetwork.learnMore" />
            </NamedLink>
          </div>
          <div className={css.contentBlockImageRevers}>
            <img src={advantage2} alt="advantage" />
          </div>
        </div>
        <div className={css.getStarted}>
          <h4 className={css.getStartedTitle}>
            <FormattedMessage id="SectionNetwork.getStartedTitle" />
          </h4>
          <p className={css.getStartedText}>
            <FormattedMessage id="SectionNetwork.getStartedText" />
          </p>
          <div className={css.gallery}>
            <div className={css.galleryImage}>
              <img src={galleryImage1} alt="basket" />
            </div>
            <div className={css.galleryImage}>
              <img src={galleryImage2} alt="laptop" />
            </div>
            <div className={css.galleryImage}>
              <img src={galleryImage3} alt="phone" />
            </div>
            <div className={css.galleryImage}>
              <img src={galleryImage4} alt="sea" />
            </div>
          </div>
          <NamedLink
            name="SearchPage"
            to={{ search: ' ' }}
            className={classNames(css.signupLink, css.signupLinkPage)}
          >
            <FormattedMessage id="SectionNetwork.letsSolve" />
          </NamedLink>
        </div>
        <div className={css.contentBlock}>
          <div className={css.contentBlockText}>
            <h4 className={css.blockTitle}>
              <FormattedMessage id="SectionNetwork.blockTitle3" />
            </h4>
            <div className={css.advantage}>
              <FormattedMessage id="SectionNetwork.advantage9" />
            </div>
            <div className={classNames(css.noPaddingAdvantage, css.yellowAdvantage)}>
              <FormattedMessage id="SectionNetwork.advantage10" />
            </div>
            <div className={classNames(css.noPaddingAdvantage, css.blackAdvantage)}>
              <FormattedMessage id="SectionNetwork.advantage11" />
            </div>
            <NamedLink name="SignupPage" className={css.learnMore}>
              <FormattedMessage id="SectionNetwork.getStarted" />
            </NamedLink>
          </div>
          <div className={css.contentBlockImage}>
            <img src={advantage3} alt="advantage" />
          </div>
        </div>
        <div className={classNames(css.contentBlock, css.contentBlockNoMargin)}>
          <div className={css.contentBlockText}>
            <h4 className={css.blockTitle}>
              <FormattedMessage id="SectionNetwork.blockTitle4" />
            </h4>
            <div className={css.advantage}>
              <FormattedMessage id="SectionNetwork.advantage12" />
            </div>
            <div className={css.advantage}>
              <FormattedMessage id="SectionNetwork.advantage13" />
            </div>
            <div className={css.advantage}>
              <FormattedMessage id="SectionNetwork.advantage14" />
            </div>
            <div className={css.advantage}>
              <FormattedMessage id="SectionNetwork.advantage15" />
            </div>
            <NamedLink name="SignupPage" className={css.learnMore}>
              <FormattedMessage id="SectionNetwork.learnMore" />
            </NamedLink>
          </div>
          <div className={css.contentBlockImageRevers}>
            <img src={advantage4} alt="advantage" />
          </div>
        </div>
      </div>
    </div>
  );
};

SectionNetwork.defaultProps = { rootClassName: null, className: null };

SectionNetwork.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionNetwork;
