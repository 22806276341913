import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { ACCOUNT_SETTINGS_PAGES } from '../../routeConfiguration';
import { LinkTabNavHorizontal } from '../../components';
import { ensureCurrentUser, ensureOwnListing, ensureUser } from '../../util/data';
import {
  BecomeInsightGigPartnerPage,
  ContactDetailsPage,
  FirmPage,
  LISTING_STATE_DRAFT,
  ProfileSettingsPage,
  StripeSubscriptionPage,
  USER_ROLE_CLIENT,
} from '../../util/types';
import { getListingType, createSlug } from '../../util/urlHelpers';

import css from './UserNav.module.css';
import { useSelector } from 'react-redux';
import { getUserRole } from '../../util/userRole';

const listingTab = (listing, selectedPageName, typePartner) => {
  if (!listing) {
    let text =
      typePartner === 'partner' ? (
        <FormattedMessage id="UserNav.newListing" />
      ) : (
        <FormattedMessage id="UserNav.newBrief" />
      );
    return {
      text: text,
      selected: selectedPageName === 'NewListingPage',
      linkProps: {
        name: 'NewListingPage',
      },
    };
  }
  const currentListing = ensureOwnListing(listing);
  const id = currentListing.id.uuid;
  const { title = '', state } = currentListing.attributes;
  const slug = createSlug(title);
  const isDraft = state === LISTING_STATE_DRAFT;
  let text =
    typePartner === 'partner' ? (
      <FormattedMessage id="UserNav.editListing" />
    ) : (
      <FormattedMessage id="UserNav.editBrief" />
    );
  return {
    text: text,
    selected: selectedPageName === 'EditListingPage',
    linkProps: {
      name: 'EditListingPage',
      params: {
        id,
        slug,
        type: getListingType(isDraft),
        tab: 'photos',
      },
    },
  };
};

const UserNav = props => {
  // const currentPath = typeof window !== 'undefined' && window.location.href;
  const { currentUser = {} } = useSelector(state => state.user);
  const ensuredCurrentUser = ensureCurrentUser(currentUser);
  const linkedToFirms =
    !!ensuredCurrentUser?.id && ensuredCurrentUser?.attributes?.profile?.publicData?.linkedToFirms;
  const firmId = Array.isArray(linkedToFirms) && linkedToFirms[0]?.firmId;
  const firmName = Array.isArray(linkedToFirms) && linkedToFirms[0]?.firmName;
  const isOwnFirm = !!ensuredCurrentUser?.id && !!ensuredCurrentUser?.attributes?.profile?.publicData?.firmId;
  const { className, rootClassName, selectedPageName, listing, typePartner } = props;
  const classes = classNames(rootClassName || css.root, className);
  const typeUser = getUserRole(currentUser);
  const tabs = [
    {
      ...listingTab(listing, selectedPageName, typePartner),
    },
    // {
    //   text: <FormattedMessage id="UserNav.profileSettingsPage" />,
    //   selected: selectedPageName === ProfileSettingsPage,
    //   disabled: false,
    //   linkProps: {
    //     name: ProfileSettingsPage,
    //   },
    // },
    {
      text: <FormattedMessage id="UserNav.contactDetailsPage" />,
      selected: ACCOUNT_SETTINGS_PAGES.includes(selectedPageName),
      disabled: false,
      linkProps: {
        name: ContactDetailsPage,
      },
    },
    typeUser === USER_ROLE_CLIENT && {
      text: <FormattedMessage id="UserNav.stripeSubscriptionPage" />,
      selected: selectedPageName === StripeSubscriptionPage,
      disabled: false,
      linkProps: {
        name: StripeSubscriptionPage,
      },

    },
    linkedToFirms?.length > 0
      ? {
        text: <FormattedMessage id="UserNav.firmPage" />,
        selected: selectedPageName === FirmPage,
        disabled: false,
        linkProps: {
          name: FirmPage,
          params: {
            id: firmId,
            slug: firmName,
          },
        },
      }
      : {
        text: isOwnFirm ? <FormattedMessage id="UserNav.firmPage" /> : <FormattedMessage id="UserNav.becomeInsightGigPartnerPage" />,
        selected: selectedPageName === BecomeInsightGigPartnerPage,
        disabled: false,
        linkProps: {
          name: BecomeInsightGigPartnerPage,
        },
      },
  ];
  const filteredTab = tabs.filter(Boolean);
  return (
    <LinkTabNavHorizontal className={classes} tabRootClassName={css.tab} tabs={filteredTab} skin="dark" />
  );
};

UserNav.defaultProps = {
  className: null,
  rootClassName: null,
};

const { string } = PropTypes;

UserNav.propTypes = {
  className: string,
  rootClassName: string,
  selectedPageName: string.isRequired,
};

export default UserNav;
