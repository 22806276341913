import React, { useEffect, useState, forwardRef } from 'react';
import { func } from 'prop-types';
import { compose } from 'redux';
import { Field, Form as FinalForm } from 'react-final-form';
// These relative imports need to point to correct directories
import {
  intlShape,
  injectIntl,
  FormattedMessage,
} from '../../util/reactIntl';
import {
  Form, FieldTextInput, IconSearch, PrimaryButton, SecondaryButton, FieldDateInput,
  IconCard, FieldCheckbox, OutsideClickHandler, IconSpinner, Modal
} from '../../components';
import css from './OpenAIContractForm.module.css';
import moment from 'moment';
import FileView from '../../components/FileView/FileView';
import { handleFileUpload } from '../../util/api';
import classNames from 'classnames';
import { composeValidators, required } from '../../util/validators';
import useHandleFileExtensionError from '../../hooks/useHandleFileExtensionError';

const ACCEPT_FILE = '.doc,.docx,application/msword,application/pdf,.ppt,.pptx';
const FILE_EXTENSION_ERROR_MESSAGE = `Please select files in PDF, DOC, DOCX, PPT, or PPTX format only.`
const FILE_SIZE_LIMIT = 25 * 1000 * 1000;

const fields = [
  { key: 'signature', label: 'Signature' },
  { key: 'signDate', label: 'Sign date' },
  { key: 'email', label: 'Email' },
  { key: 'initial', label: 'Initial' },
  { key: 'firstName', label: 'First Name' },
  { key: 'lastName', label: 'Last Name' },
  { key: 'fullName', label: 'Full Name' },
  { key: 'company', label: 'Company' },
  { key: 'jobTitle', label: 'Job Title' },
  { key: 'customDate', label: 'Custom Date' },
  { key: 'attachment', label: 'Attachment' }
]


export const OpenAIContractForm = props => {
  return (
    <FinalForm
      {...props}
      render={formRenderProps => {
        const {
          disabled,
          handleSubmit,
          intl,
          pristine,
          updated,
          formId,
          updateError,
          values,
          form,
          openaiRequest,
          initialText,
          manageDisableScrolling
        } = formRenderProps;

        const [file, setFile] = useState();
        const [isOpen, setIsOpen] = useState(false);
        const [isLoading, setIsLoading] = useState(false);
        const [invalidModal, setInvalidModal] = useState(false);
        const [fileExtensionError, checkForFileExtension] = useHandleFileExtensionError();
        const [displayErrorMessage, setDisplayErrorMessage] = useState(false)
        const submitDisabled = !values.type || !values.numberOfParties || !values?.field?.length;
        const isDayBlocked = day => values && values.startDate && moment(values.startDate.date).isSameOrAfter(moment(day));

        const contractTypePlaceholder = intl.formatMessage({ id: "OpenAIContractForm.contractTypePlaceholder" });
        const keyInformationPlaceholder = intl.formatMessage({ id: "OpenAIContractForm.keyInformationPlaceholder" });
        const startDateLabel = intl.formatMessage({ id: "OpenAIContractForm.startDateLabel" });
        const endDateLabel = intl.formatMessage({ id: "OpenAIContractForm.endDateLabel" });

        useEffect(() => {
          if (initialText) {
            form.reset();
          }
        }, [initialText])

        async function handleAttachment(file) {
          setIsLoading(true);
          try {
            const allowedTypes = ["application/pdf", "application/msword", "application/vnd.openxmlformats-officedocument.wordprocessingml.document", "application/vnd.ms-powerpoint", "application/vnd.openxmlformats-officedocument.presentationml.presentation"];
            if (!allowedTypes.includes(file.type)) {
              setInvalidModal(true)
              throw new Error("Unsupported file type. Please upload PDF, DOC, DOCX, PPT, or PPTX files.");
            }
        
            const response = await handleFileUpload('get-parsed-document', { file });
        
            if (response?.data) {
              setFile(file);
              form.change('file',file)
              if (response?.data) Object.assign(values, { attachmentText: response?.data });
              setIsLoading(false);
            }
          } catch (error) {
            setIsLoading(false);
            console.error("Error:", error);
          }
        }
        

        function handleRemoveField(e, fieldValue) {
          e.stopPropagation();
          const modifiedFields = values?.field?.length && values?.field?.filter(field => field !== fieldValue);
          form.change('field', modifiedFields);
        }

        const contractTypeRequiredMessage = intl.formatMessage({
          id: 'OpenAIContractForm.contractTypeRequiredMessage',
        });
        const numberOfPartiesRequiredMessage = intl.formatMessage({
          id: 'OpenAIContractForm.numberOfPartiesRequiredMessage',
        });

        return (
          <Form className={css.contractForm} onSubmit={handleSubmit} >
            <div className={css.container}>
              <div className={css.briefGenerator}>
                <span className={css.generatorTitle}>
                  <FormattedMessage id="OpenAIContractForm.heading" />
                </span>
              </div>
              <div className={css.row}>
                <label><FormattedMessage id="OpenAIContractForm.contractTypeLabel" /></label>
                <FieldTextInput
                  className={css.textAreaBox}
                  type="textarea"
                  id={'type'}
                  name="type"
                  placeholder={contractTypePlaceholder}
                  validate={composeValidators(required(contractTypeRequiredMessage))}
                  vibrate={true}
                />
              </div>
              <div className={css.row}>
                <label><FormattedMessage id="OpenAIContractForm.numberOfPartyLabel" /></label>
                <FieldTextInput
                  className={css.inputBox}
                  type="number"
                  id={'numberOfParties'}
                  name="numberOfParties"
                  validate={composeValidators(required(numberOfPartiesRequiredMessage))}
                  vibrate={true}
                />
              </div>
              <div className={css.row}>
                <label><FormattedMessage id="OpenAIContractForm.keyInfomationLabel" /></label>
                <FieldTextInput
                  className={css.textAreaBox}
                  type="textarea"
                  cols="50"
                  id={'keyInformation'}
                  name="keyInformation"
                  placeholder={keyInformationPlaceholder}
                />
              </div>
              <div className={css.fieldsWrapper}>
                <label><FormattedMessage id="OpenAIContractForm.signatureLabel" /></label>
                <div className={classNames(css.fieldsContainer,(displayErrorMessage && (!values.field || values?.field?.length===0) && !isOpen) ? css.requiredFieldBorder : '' )} onClick={() => {
                    setDisplayErrorMessage(true)
                    setIsOpen(!isOpen)
                  }}>
                  {(values?.field && values?.field?.length !== 0) ? values?.field?.map(field => {
                    return (
                      <div className={css.fieldsItem} key={field}>
                        {field}
                        <span className={css.removeIcon} onClick={(e) => handleRemoveField(e, field)}>
                          <IconCard brand='crossSign' />
                        </span>
                      </div>
                    )
                  }) : (
                    <p>Select</p>
                  )}
                  <span className={css.toggleIcon}>
                    <IconCard brand={!isOpen ? 'closearrow' : 'openarrow'} />
                  </span>
                </div>
                {isOpen && (
                  <OutsideClickHandler onOutsideClick={() => setIsOpen(false)} className={css.fieldsCheckbox}>
                    {fields.map(field => {
                      return (
                        <FieldCheckbox
                        className={css.checkedBox}
                        key={field.key}
                        id={field.key}
                        name='field'
                        label={field.label}
                        value={field.label}
                        />
                        )
                      })}
                  </OutsideClickHandler>
                )}
                <div className={css.vibrate}>
                  {displayErrorMessage && (!values.field || values?.field?.length===0) && !isOpen ? <span className={css.extensionError}>Select signature fields</span> : ''}
                </div>
              </div>
              <div className={css.row}>
                <label><FormattedMessage id="OpenAIContractForm.attachmentLabel" /></label>
                <div className={css.attachment}>
                  {file ?
                    <FileView file={file}>
                      <span className={css.close} onClick={() => setFile(null)}>
                        <IconCard brand='cross' />
                      </span>
                    </FileView>
                    : (
                      <Field
                        id="file"
                        name="file"
                        accept={ACCEPT_FILE}
                        type="file"
                      >
                        {fieldprops => {
                          const { accept, input, disabled: fieldDisabled } = fieldprops;
                          const { name, type } = input;
                          const onChange = async e => {
                            const file = e.target.files[0];
                            const allowedExtensions = /(\.pdf|\.docx?|\.pptx?)$/i
                            let extensionError = checkForFileExtension(allowedExtensions, file, FILE_EXTENSION_ERROR_MESSAGE)
                            if(extensionError) return
                            if (file && file.name && file.size < FILE_SIZE_LIMIT) {
                              handleAttachment(file)
                            }
                          };

                          const inputProps = { accept, id: name, name, onChange, type };
                          return (
                            <div>
                              {fieldDisabled ? null : (
                                <input {...inputProps} className={css.addImageInput} />
                              )}
                              <label type='button' htmlFor={name} className={css.attachmentLabel}>
                                {isLoading ? <IconSpinner strokeColor='#0B96F5' /> : <IconCard brand="upload" />}
                                <span className={css.dragDrop}><FormattedMessage id="GigAppsPage.singleFileUpload" /></span>
                                <span className={css.docType}>
                                  <FormattedMessage id="JobListing.documentAllowList" />
                                </span>
                              </label>
                              {fileExtensionError && <span className={css.extensionError}>{fileExtensionError}</span>}
                            </div>
                          );
                        }}
                      </Field>
                    )}
                </div>
              </div>
              <div className={css.datesRow}>
                <FieldDateInput
                  id="startDate"
                  name="startDate"
                  className={css.fieldDateInput}
                  label={startDateLabel}
                  placeholderText={'Select Date'}
                />
                <FieldDateInput
                  id="endDate"
                  name="endDate"
                  className={css.fieldDateInput}
                  label={endDateLabel}
                  placeholderText={'Select Date'}
                  isDayBlocked={isDayBlocked}
                //   validate={required(startDateRequiredMessage)}
                />
              </div>
              <div className={css.actionButtons}>
                <SecondaryButton
                  type='button'
                  className={css.resetButton}
                  onClick={() => {
                    form.reset()
                    setFile(null)
                  }}
                  disabled={openaiRequest}
                >
                  <FormattedMessage id="OpenAIContractForm.resetButtonText" />
                </SecondaryButton>
                <PrimaryButton
                  type='submit'
                  onClick={()=>{
                    if(!values?.field){
                      setDisplayErrorMessage(true)
                    }
                  }}
                  className={classNames(submitDisabled ? css.disabledSubmitButton : css.submitButton )}
                  inProgress={openaiRequest}
                >
                  <FormattedMessage id="OpenAIContractForm.submitButtonText" />
                </PrimaryButton>
              </div>
            </div>
            <Modal onManageDisableScrolling={manageDisableScrolling} isOpen={invalidModal} onClose={()=>setInvalidModal(false)}>
              <div>
                <div>
                  <span className={css.modalTitle}><FormattedMessage id='OpenAIContractForm.invalidFormatLabel'/></span>
                </div>
                <span className={css.modalContext}><FormattedMessage id="OpenAIContractForm.invalidFormatBody"/></span>
                <div className={css.modalbtnDiv}>
                  <button 
                  className={css.okbtn}
                  onClick={(e)=>{
                    e.preventDefault()
                    setInvalidModal(false)
                  }}>
                    OK
                  </button>
                </div>
              </div>
            </Modal>
          </Form>
        );
      }}
    />
  )
}


OpenAIContractForm.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  //   updateInProgress: bool.isRequired,
};

export default compose(injectIntl)(OpenAIContractForm);