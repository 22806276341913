import React, { useState } from 'react';
import { compose } from 'redux';
import { Field, Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { injectIntl, FormattedMessage } from '../../util/reactIntl';
import { Form, FieldTextInput, PrimaryButton, IconCard, IconSpinner, Button, SecondaryButton } from '../../components';
import * as validators from '../../util/validators';
import css from './EditListingPhotosForm.module.css';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { createImageUsingAI, generateImageUsingOpenAISuccess } from '../../containers/EditListingPage/EditListingPage.duck';
import { CROSS_SIGN } from '../../util/types';
import { requestImageUpload } from '../../containers/BecomeInsightGigPartnerPage/BecomeInsightGigPartnerPage.duck';
import classNames from 'classnames';

export const EditListingOpenAIForm = props => {
  const dispatch = useDispatch();
  return (
    <FinalForm
      {...props}
      mutators={{
        ...arrayMutators,
      }}
      render={formRenderProps => {
        const {
          handleSubmit,
          intl,
          pristine,
          updateInProgress,
          values,
          form,
          handleSubmitInProgess,
          generateImageError,
          isFirmPage,
          className,
          inputClassName,
          setOpenAIImages,
        } = formRenderProps;

        const submitInProgress = updateInProgress;
        const submitDisabled = submitInProgress || handleSubmitInProgess || !values?.imageText || !values?.image_url;

        const imageInputTextLabel = intl.formatMessage({ 
          id: isFirmPage ? 'BecomeInsightGigPartnerForm.imageInputTextLabel' : 'EditListingOpenAIForm.imageInputTextLabel'
        });
        const imageInputTextPlaceholder = intl.formatMessage({ id: 'EditListingOpenAIForm.imageInputTextPlaceholder'});
        const imageInputTextRequiredMessage = intl.formatMessage({ id: 'EditListingOpenAIForm.imageInputTextRequiredMessage'});
        const imageInputRequiredMessage = validators.required(imageInputTextRequiredMessage);

        const handleOpenAIImageData = async () => {
          try {
            const { dataUrl, fileName } = await dispatch(createImageUsingAI({ promptText: values?.imageText }));
            form.change('image_url', dataUrl)

            function createFileObjectFromDataURI(dataURI, fileName) {
              const mimeType = dataURI.split(':')[1].split(';')[0]; // Extract the MIME type from the data URI
              const base64Data = dataURI.split(',')[1]; // Extract the base64-encoded image data
              const buffer = Buffer.from(base64Data, 'base64'); // Decode the base64 data
              // Create the File object
              const file = new File([buffer], fileName, { type: mimeType });
              return file;
            }

            const file = createFileObjectFromDataURI(dataUrl, fileName);
            const { id } = file && await dispatch(requestImageUpload({ id: `${file.name}_${Date.now()}`, file }));

            if (id) {
              isFirmPage ? setOpenAIImages(dataUrl, id) :  form.change('openAIBackgroundImageId', id)
              dispatch(generateImageUsingOpenAISuccess({ dataUrl, fileName }))
            }
          } catch (error) {
            return;
          }
        }

        const ImageField = ({ input }) => {
          return input?.value ?
            <div className={css.imageContainer}>
              <div className={css.uploadImageBox}>
                <span className={css.closeButton} onClick={() => form.change('image_url', null)}>
                  <IconCard brand={CROSS_SIGN} />
                </span>
                <img src={values?.image_url} className={css.aiImage} alt="Image" />
              </div>
              {generateImageError ?
                <h3>
                  {intl.formatMessage({
                    id: 'EditListingOpenAIForm.generateImageError',
                  })}
                </h3> : null}
            </div>
            : null;
        };

        return (
          <Form className={css.form} onSubmit={e => {
            e.preventDefault();
            values?.image_url && handleSubmit()
          }}>
            <div className={classNames(className ?? css.inputWrapperBox)}>
              <FieldTextInput
                className={inputClassName ?? css.inputBox}
                type="text"
                id="imageText"
                name="imageText"
                label={imageInputTextLabel}
                placeholder={imageInputTextPlaceholder}
                // validate={imageInputRequiredMessage}
              />
              <SecondaryButton
                type="button"
                disabled={!values?.imageText}
                className={!values.imageText ? css.disabled : css.imageGenrateButton}
                onClick={() => handleOpenAIImageData()}
                inProgress={updateInProgress}
              >
                {submitInProgress
                  ? <IconSpinner strokeColor={'#0B96F5'} />
                  : <FormattedMessage id="EditListingOpenAIForm.generateImageButton" />
                }
              </SecondaryButton>
            </div>
            {!isFirmPage && (
              <>
                <div>
                  <Field name="image_url" id="image_url" component={ImageField} />
                </div>
                <PrimaryButton
                  className={css.submitButton}
                  type="submit"
                  inProgress={handleSubmitInProgess}
                  disabled={submitDisabled}
                >
                  <FormattedMessage id="EditListingOpenAIForm.saveButton" />
                </PrimaryButton>
              </>
            )}
          </Form>
        );
      }}
    />
  );
};



export default compose(injectIntl, withRouter)(EditListingOpenAIForm);