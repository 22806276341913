import axios from 'axios';
import {
  apiBaseUrl,
  generateAppDataFromOpenAI,
  handleFileUpload,
  questionnaireGenerator,
  updateFirmUserDetails,
  updateSubscriptionData,
  sendEmailUsingZepto,
  storeOpenAIData,
  generateTopicWiseConsolidation,
  generateTranscriptDiscussionGuide,
} from '../../util/api';
import moment from 'moment';
import { AI_COMBINED_TOPIC_SENTIMENT, AI_KEYWORD_EXTRACTION, AI_PRE_TEXT_PROCESSING, AI_SENTIMENT_ANALYSIS, AI_TOPIC_MODELING, GIG_APP_COMPLETED } from '../../util/types';
import io from 'socket.io-client';
import { paymentTermsFields } from '../../forms/OpenAIProposalForm/OpenAIProposalForm';
import { v4 as uuidv4 } from 'uuid';

const URL = process.env.REACT_APP_CANONICAL_ROOT_URL; //For staging
// const URL = process.env.REACT_APP_SOCKET_URL; //For local
const socket = io(URL);

function chunkString(str, size) {
  const numChunks = Math.ceil(str.length / size);
  const chunks = new Array(numChunks);

  for (let i = 0, o = 0; i < numChunks; ++i, o += size) {
    chunks[i] = str.substr(o, size);
  }

  return chunks;
}

async function handleOpenaiStreamRequest(params) {
  const { values, id, setOpenaiRequest, setInitialText, appRoute, email } = params;
  const maxChunkSize = 2048;
  let result = '';
  try {
    if (socket) {
      const responseString = `openaiStreamResponse_${id}`
      // Remove existing event listeners
      socket.removeAllListeners(responseString);

      setInitialText({ isOpen: true })

      const valueString = JSON.stringify(values);
      const chunks = chunkString(valueString, maxChunkSize);

      const emitChunks = async () => {
        for (const chunk of chunks) {
          await new Promise((resolve) => {
            socket.emit('openaiStreamRequestChunk', { chunk, id, appRoute, responseString }, () => {
              resolve();
            });
          });
        }
        // Signal that all chunks have been sent
        socket.emit('openaiStreamRequestEnd', { id, appRoute, responseString, email });
      };

      await emitChunks();

      socket.on(responseString, (data) => {
        if (!!data?.error) {
          throw new Error('Error getting response!')
        }
        else if (data?.response === 'stream-response-end') {
          setOpenaiRequest(false);
          setInitialText((prev) => ({ ...prev, isCompleted: true }));
          updateFirmUserDetails({ action: GIG_APP_COMPLETED, userEmail: email, appName: appRoute })
          return
        }
        result += data?.response;
        setInitialText((pre) => ({ ...pre, content: result.replace(/```[\w]*|```/g, '') }))
      });
    }
  }
  catch (error) {
    return error
  }
}

//1. Handle Brief Generation
export async function handleBriefGeneration(params) {
  const { values, storeUserInput, setOpenaiRequest, handleError, email, setInitialText, title } = params;
  try {
    const { attachmentText, isBudgetRequired, isTimelineRequired, file, ...filteredData } = values
    isBudgetRequired?.length > 0 && (delete filteredData.budget && delete filteredData.currencyFee)
    isTimelineRequired?.length > 0 && (delete filteredData.timeline)
    
    setOpenaiRequest(true);
    storeUserInput(filteredData);
    handleOpenaiStreamRequest({...params, values: {...values, title}})
    file?.name && handleFileUpload('upload-gigapp-input-file-aws', {file : file, email: email})
  } catch (error) {
    handleError(error.message);
  }
}

//2. Handle Job Description Generation
export async function handleJobDescriptionGeneration(params) {
  const { values, storeUserInput, setOpenaiRequest, handleError, title} = params;
  try {
    const { remoteLocation, ctc, currency, employementRequired, experienceRequired, budgetRequired, minYear, maxYear } = values;

    const param = {
      ...values,
      title,
      annualCTC: `${currency} ${ctc}`,
      isRemote: !!remoteLocation?.length,
      isEmpLengthRequired: !employementRequired?.length,
      freshersAllowed: !!experienceRequired?.length,
      isExperienceRequired: !experienceRequired?.length,
      experience: minYear && maxYear && `${minYear}-${maxYear} years`,
    };
    remoteLocation?.length>0 && delete values.location
    employementRequired?.length>0 && delete values.employmentLength
    experienceRequired?.length>0 && (delete values.maxYear && delete values.minYear)
    budgetRequired?.length>0 && (delete values.ctc && delete values.currency)
    
    storeUserInput(values);
    setOpenaiRequest(true);
    handleOpenaiStreamRequest({ ...params, values: param })
  } catch (error) {
    handleError(error.message);
  }
}

//3. Handle Proposal Generation
export async function handleProposalGeneration(params) {
  const { values, storeUserInput, setOpenaiRequest, handleError, email, title } = params;
  try {
    const { brief , credentials, documentFormat, gigAppInputFiles, hiddenFields, ...filteredData} = values
    storeUserInput(filteredData);

    const { paymentTerms, fee, feeCurrency, startDate, endDate, content } = filteredData;
    const param = {
      ...values,
      title,
      email,
      content: (!hiddenFields || !hiddenFields.includes('content')) ? content : "",
      fee: (!hiddenFields || !hiddenFields.includes('fee')) ? `${feeCurrency} ${fee}` : "",
      timeline: ((!hiddenFields || !hiddenFields.includes('timeline')) && (startDate && endDate)) ? `${startDate.date} to ${endDate.date}` : "",
      paymentTerms: (paymentTerms && (!hiddenFields || !hiddenFields.includes('paymentTerms')))
        ? paymentTermsFields.find(term => term.key === paymentTerms).label
        : "",
    };
    handleOpenaiStreamRequest({ ...params, values: param });

    gigAppInputFiles.map(async (file, index) => {
      await handleFileUpload('upload-gigapp-input-file-aws', {file : file, email: email})
    })
  } catch (error) {
    handleError(error.message);
  }
}

//4. Handle Contract Generation
export async function handleContractGeneration(params) {
  const { values, storeUserInput, setOpenaiRequest, handleError, email, title } = params;
  try {
    const { field, startDate = {}, endDate = {}, attachmentText, file, ...filteredData } = values;
    const param = {
      ...values,
      title,
      fields: field?.join(','),
      timeline: `${startDate.date} to ${endDate.date}`,
    };
    
    storeUserInput({fields: param.fields, timeline: param.timeline, ...filteredData});
    setOpenaiRequest(true);
    handleOpenaiStreamRequest({ ...params, values: param })
    
    file?.name && handleFileUpload('upload-gigapp-input-file-aws', {file : file, email: email})
  } catch (error) {
    handleError(error.message);
  }
}

//5. Handle Transcription Generator
export const handleTranscriptionGenerator = async params => {
  const { id, values, storeUserInput, setOpenaiRequest, handleError, email, firstName, setOpenSuccessModal, title, appRoute, timezone } = params;
  try {
    const { file, input, runName } = values || {}
    const chunkSize = 500 * 1024 * 1024; // 500 MB chunks
    setOpenaiRequest(true);
    storeUserInput({...values, fileName: file?.name}, runName);
    file?.name && handleFileUpload('upload-gigapp-input-file-aws', {file : file, email: email})

    const totalChunks = Math.ceil(file.size / chunkSize);
    let start = 0;
    let end = Math.min(chunkSize, file.size);

    for (let i = 1; i <= totalChunks; i++) {
      const formData = new FormData();
      formData.append('file', file.slice(start, end));
      formData.append('chunk', i);
      formData.append('totalChunks', totalChunks);
      formData.append('fileName', file?.name);
      formData.append('inputText', input);
      formData.append('email', email);
      formData.append('username', firstName);
      formData.append('firstName', firstName);
      formData.append('appRoute', appRoute);
      formData.append('sessionId', id);
      formData.append('appName', title);
      formData.append('runName', runName);
      formData.append('timezone', timezone);

      const response = axios.post(`${process.env.REACT_APP_DJANGO_BACKEND_API}/transcribe_file`,
        formData,
        { headers: { 'Content-Type': 'multipart/form-data' } }
      );

      start = end;
      end = Math.min(start + chunkSize, file.size);
    }

    setTimeout(()=>{
      setOpenSuccessModal(true)
      setOpenaiRequest(false)
    },1000)
  } catch (error) {
    console.log(error, 'error')
    setOpenaiRequest(false);
    handleError(error.message);
  }
};

//6. Handle Questionnaire Generation
export async function handleQuestionnaireGeneration(params) {
  const { values, storeUserInput, setOpenaiRequest, handleError, setQuestionnaireResponseModal, email, id, firstName, appRoute, title } = params;
  try {
    const {step, ...filteredValues} = values; 
    setOpenaiRequest(true);

    if(step === 3){
      storeUserInput(filteredValues);
      questionnaireGenerator({ ...values, email, id, firstName, appRoute, title })
      setTimeout(() => {
        setQuestionnaireResponseModal(true)
        setOpenaiRequest(false);
      }, 3000)
    }
  } catch (error) {
    handleError(error.message);
  }
}

export async function storeRawQuantitativeChat(params){
  try{
    let response = await fetch(`${apiBaseUrl()}/api/store-raw-quantitative-chat`,{
      method: 'POST',
      headers: {
        'Content-Type': 'text/plain',
        'Transfer-Encoding': 'chunked',
      },
      body: JSON.stringify(params)
    })
    return response.json()
  }catch(error){
  }
}

export async function handleConvertHTMLtoPdf(params){
  try{
    let response = await fetch(`${apiBaseUrl()}/api/convertHTMLtoPDF`,{
      method: 'POST',
      headers: {
        'Content-Type': 'text/plain',
        'Transfer-Encoding': 'chunked',
      },
      body: JSON.stringify(params)
    })
    return response.json()
  }catch(error){
  }
}

export async function handleConvertHTMLtoDoc(params){
  try{
    let response = await fetch(`${apiBaseUrl()}/api/convertHTMLtoDoc`,{
      method: 'POST',
      headers: {
        'Content-Type': 'text/plain',
        'Transfer-Encoding': 'chunked',
      },
      body: JSON.stringify(params)
    })
    return response.json()
  }catch(error){
  }
}


//7. Handle Discussion Guide
export async function handleDiscussionGuideGeneration(params) {
  const { values, storeUserInput, setOpenaiRequest, handleError, email, title } = params;
  try {
    const {attachmentText, file, ...filteredData} = values
    storeUserInput(filteredData);
    setOpenaiRequest(true);
    handleOpenaiStreamRequest({...params, values: {...values, title}})
    file?.name && handleFileUpload('upload-gigapp-input-file-aws', {file : file, email: email})
  } catch (error) {
    handleError(error.message);
  }
}

//8. Handle Suggested Answer Generator
export async function handleSuggestedAnswerGenerator(params) {
  const { id, setOpenaiRequest, appRoute, values, form, handleError, email, storeUserInput, firstName, title } = params;
  try {
    storeUserInput(values)
    setOpenaiRequest(true);

    const { status, response, error } = await generateAppDataFromOpenAI({
      email,
      firstName,
      type: appRoute,
      data: {id, title, ...values},
    });
    if (status === 200) {
      setOpenaiRequest(false);
      // Ensure the response has the expected structure
      const parsedData = response && typeof response === 'string' ? JSON.parse(response) : null;
      form.change('suggestedAnswer', parsedData?.SuggestedAnswers)
      updateFirmUserDetails({ action: GIG_APP_COMPLETED, userEmail: email, appName: appRoute })
    } else {
      // Log an error if the response status is not 200
      handleError(`Failed to fetch data from OpenAI. Status: ${status}`);
    }
  } catch (error) {
    // Log and handle unexpected errors
    handleError('An unexpected error occurred');
  }
}

//9. Handle Perfect Question AppData
export async function handlePerfectQuestionAppData(params) {
  const { setOpenaiRequest, appRoute, values, form, handleError, email, storeUserInput, firstName, title } = params;

  try {
    setOpenaiRequest(true);
    storeUserInput(values);

    const { status, response, error } = await generateAppDataFromOpenAI({
      email,
      firstName,
      type: appRoute,
      data: {...values, title},
    });

    if (status === 200) {
      setOpenaiRequest(false);

      // Ensure the response has the expected structure
      const parsedData = response && typeof response === 'string' ? JSON.parse(response) : null;

      if (parsedData && parsedData.Questions) {
        form.change('questions', parsedData.Questions);
        updateFirmUserDetails({ action: GIG_APP_COMPLETED, userEmail: email, appName: appRoute })
      } else {
        // Log an error if the response structure is unexpected
        handleError('Invalid response structure from OpenAI');
      }
    } else {
      // Log an error if the response status is not 200
      handleError(`Failed to fetch data from OpenAI. Status: ${status}`);
    }
  } catch (error) {
    // Log and handle unexpected errors
    handleError('An unexpected error occurred');
  }
}

//10. Handle Text Preprocessing
export async function handleDjangoRequest(params) {
  const { id, appRoute, values, email, handleError, firstName, storeUserInput, setOpenaiRequest, form, title, timezone } = params;
  try {
    const apiRoute = appRoute === AI_KEYWORD_EXTRACTION ? 'keywords_extraction_app'
      : appRoute === AI_TOPIC_MODELING ? 'topic_modeling'
        : appRoute === AI_SENTIMENT_ANALYSIS ? 'sentiment_analysis'
          : appRoute === AI_COMBINED_TOPIC_SENTIMENT ? 'combined_topic_sentiment'
            : appRoute === AI_PRE_TEXT_PROCESSING && 'pre_text_processing';

    const { file, keywordRange, runName } = values || {};
    setOpenaiRequest(true);
    storeUserInput({fileName: file.name}, runName)

    const formData = new FormData();
    formData.append('file', file);
    formData.append('fileName', file?.name);
    formData.append('email', email);
    formData.append('firstName', firstName)
    formData.append('sessionId', id)
    formData.append('appName', title)
    formData.append('appRoute', appRoute)
    formData.append('runName', runName)
    formData.append('timezone', timezone)
    if (!!keywordRange) formData.append('keywordRange', keywordRange)

    axios.post(`${process.env.REACT_APP_DJANGO_BACKEND_API}/${apiRoute}`,
      formData,
      { headers: { 'Content-Type': 'multipart/form-data' } }
    );

    setTimeout(() => {
      form.change('openSuccessModal', true)
      setOpenaiRequest(false);
    }, [2000])

  } catch (error) {
    // Log and handle unexpected errors
    handleError('An unexpected error occurred');
  }
}

//11. Handle Report Idea Generation
export async function handleReportIdeaGeneration(params) {
  const {
    setOpenaiRequest,
    appRoute,
    values,
    handleError,
    currentUserEmail,
    uniqueInputId,
    setOpenSuccessModal,
    storeUserInput,
    firstName,
    title
  } = params;
  try {
    setOpenaiRequest(true);
    const {transcription: file, proposalDocument, runName} = values || {};
    const proposalText = values?.proposalDocumentText || '';
    const expiresAt = new Date(Date.now() + 1000 * 60 * 60 * 24 * 30) //add 30 days 
    
    storeUserInput({}, runName, expiresAt)
    const params = { file, email: currentUserEmail, id: uniqueInputId, proposalText, firstName, appRoute, runName, title };

    setTimeout(() => {
      setOpenSuccessModal(true)
      setOpenaiRequest(false);
    }, 5000)
    
    await handleFileUpload('handle-content-analysis-report', params);
    file?.name && handleFileUpload('upload-gigapp-input-file-aws', {file : file, email: currentUserEmail})
    proposalDocument?.name && handleFileUpload('upload-gigapp-input-file-aws', {file : proposalDocument, email: currentUserEmail})
    
  } catch (error) {
    handleError(error.message);
  }
}

//12. Handle Research Instrument Generator
export async function handleResearchInstrumentGenerator(params) {
  const { values, storeUserInput, setOpenaiRequest, handleError, email, setShowResearchModal, uniqueInputId, appRoute, formStep, setFormStep, firstName, timeZone, title, id } = params;
  try{
    const {proposalDocument, researchDocuments, updatedList, gigAppInputFiles } = values;
    setOpenaiRequest(true);
    const param = {
      proposalDocument,
      researchDocuments,
      email,
      updatedList,
      uniqueInputId,
      appRoute,
      formStep,
      id,
      name: firstName,
      proposalDocumentFile: { name: values.proposalDocumentFile?.name, size:values.proposalDocumentFile?.size},
      timeZone, title
    };
    if (formStep === 1 || formStep === 2) {
      let response = fetch(`${apiBaseUrl()}/api/researchInstrumentProposalGenerator`, {
        method: 'POST',
        headers: {
          'Content-Type': 'text/plain',
          'Transfer-Encoding': 'chunked',
        },
        body: JSON.stringify(param)
      })
      setTimeout(()=>{
        if(formStep === 2){
          setFormStep(formStep + 1)
        }else{
          storeUserInput({})
          gigAppInputFiles.map(async (file, index) => {
            handleFileUpload('upload-gigapp-input-file-aws', { file: file, email: email })
          })
          setShowResearchModal(true)
        }
      },500)
    }
    if (formStep === 3) {
      let docResponse = fetch(`${apiBaseUrl()}/api/researchInstrumentDocumentGenerator`, {
        method: 'POST',
        headers: {
          'Content-Type': 'text/plain',
          'Transfer-Encoding': 'chunked',
        },
        body: JSON.stringify(param)
      })
      setTimeout(() => {
        setShowResearchModal(true)
        gigAppInputFiles.map(async (file, index) => {
          const response = await handleFileUpload('upload-gigapp-input-file-aws', { file: file, email: email })
        })
      }, 500)
    }
    setOpenaiRequest(false);
  }catch(error){
    handleError(error.message);
  }
}

//13. Handle Analyze Raw Data - step 1
export async function handleAnalyzeRawData(params){
  const {values, form, handleError, email, setOpenaiRequest, setAnalyzeFileError,firstName, id, appRoute, title} = params;
  try{
    setOpenaiRequest(true)
    const inputFile = values.gigAppInputFiles
    const { file, questionnaireDoc, step, questionnaireFile, runName } = values || {};

    const expiresAt = new Date(Date.now() + 1000 * 60 * 60 * 24 * 30); //expire after 30 days
    await storeOpenAIData({
      id,
      email,
      runName,
      expiresAt,
      type: appRoute,
      data: {
        questionnaireFile : {name: questionnaireFile.name,size: questionnaireFile.size, createdAt: Date.now()},
        xlsxFile: {name: file.name,size:file.size, createdAt: Date.now()},
        queryData: [],
        inputsUsed:false,
        firstName,
      }
    })

    setTimeout(() => {
      setAnalyzeFileError(false)
      setOpenaiRequest(false)
      form.change('showModal', true)
    }, 3000)
    
    // Call backend api to process file and create new thread
    handleFileUpload('analyzeRawQuantitativeDataGenerator', {
      contentFile: file,
      removeFile: true,
      questionnaireFile,
      email,
      questionnaireDoc,
      step,
      id,
      firstName,
      appRoute,
      runName,
      title
    });

    inputFile?.name && handleFileUpload('upload-gigapp-input-file-aws', {file : inputFile, email: email, isRemoveEnabled: true})
    file?.name && handleFileUpload('upload-gigapp-input-file-aws', {file : file, email: email, isRemoveEnabled: true})
  }catch(error){
    handleError(error.message);
  }
}

//13. Handle Analyze Raw Question list - step 2
export async function handleAnalyzeRawFinalMerge(params){
  const {form, handleError, email, setOpenaiRequest, firstName, appRoute, values} = params;
  
  try{
    setOpenaiRequest(true)
    const { questionsList, paramsId, threadId, fileExtension, runName } = values || {};

    const param = {
      questionsList,
      firstName,
      paramsId,
      email,
      threadId,
      fileExtension,
      appRoute,
      runName,
    }

    const response = await fetch(`${apiBaseUrl()}/api/analyzeRawQuantitativeThreadGenerator`,{
      method: 'POST',
      headers: {
        'Content-Type': 'text/plain',
        'Transfer-Encoding': 'chunked',
      },
      body: JSON.stringify(param)
    })

    if(response?.status === 200){
      form.change('showModal', true)
      setOpenaiRequest(false)
      updateFirmUserDetails({ action: GIG_APP_COMPLETED, userEmail: email, appName: appRoute })
    }
  }
  catch(error){
    console.log(error, 'Error generating thread!')
    handleError(error)
  }
}

//14. Handle Query Report
export async function handleQueryReport(params) {
  const {
    values,
    setOpenaiRequest,
    setOpenSuccessModal,
    handleError,
    currentUserEmail,
    uniqueInputId,
    appRoute,
    storeUserInput,
    firstName,
    title
  } = params;
  try{
    setOpenaiRequest(true);
    const formData = new FormData()
    const expiresAt = new Date(Date.now() + 1000 * 60 * 60 * 24 * 30) //add 30 days 
    const { folders, runName } = values || {};

    const payload = await Promise.all(
      folders.map(async item => {
        const fetchedContentFolders = await Promise.all(
          item.folderFiles.map(async file => {
            const response = await handleFileUpload('get-parsed-document', { file });
            await handleFileUpload('upload-gigapp-input-file-aws', {file: file, email: currentUserEmail});
            formData.append('files', file);
            return {
              context: response?.data,
              file: {
                fileName: file.name,
                size: file.size,
                createdAt: file.lastModified,
              },
            };
          })
        );
        return {
          folderName: item.folderName,
          folderFiles: fetchedContentFolders,
        };
      })
    );

    const transformFiles = (folder) => folder.folderFiles.map(file => ({
      fileName: file.name,
      size: file.size,
      createdAt: file.lastModified,
    }));

    // Store session data in mongoDb
    if (folders.length > 1) {
      const transformedFoldersData = folders.map(folder => ({
        folderName: folder.folderName,
        files: transformFiles(folder),
      }));
      storeUserInput({ multipleFolders: true, folders: transformedFoldersData }, runName, expiresAt);
    } 
    else {
      const dataFiles = folders.flatMap(folder => transformFiles(folder));;
      storeUserInput({ multipleFolders: false, files: dataFiles }, runName, expiresAt);
    }
    setOpenaiRequest(false);
    setOpenSuccessModal(true);

    // Generate summary of uploaded document text and store it in session
    fetch(`${apiBaseUrl()}/api/query-report-summary`,{
      method: 'POST',
      headers: {
        'Content-Type': 'text/plain',
        'Transfer-Encoding': 'chunked',
      },
      credentials: 'include',
      body: JSON.stringify({payload, id: uniqueInputId, email: currentUserEmail, title})
    })

    // Send file to django server for processing
    formData.append('email', currentUserEmail)
    formData.append('firstName', firstName)
    formData.append('sessionId', uniqueInputId)
    formData.append('appName', title)
    formData.append('appRoute', appRoute)
    formData.append('runName', runName)
    
    axios.post(`${process.env.REACT_APP_DJANGO_BACKEND_API}/query_report`,
      formData,
      { headers: { 'Content-Type': 'multipart/form-data' } }
    );
  } catch (error) {
    console.log(error, 'Error in Query Report App!!')
    handleError(error.message);
  }
}

//Handle Query Content Analysis Gig-app
export async function handleQueryFromOpenai(params) {
  const { type, question, modifiedData, chatResponse, setChatLastItem, setIsLoading, id, addLastItemToChatResponse, summary="", email, consolidation, title } = params;
  let answer = '';

  try {
    if (socket) {
      return new Promise(async (res, rej) => {
        const responseString = `openaiStreamResponse_${id}`
        socket.removeAllListeners(responseString);

        const chatString = JSON.stringify(chatResponse);
        const chunks = chunkString(chatString, 2048);
        const sessionId = uuidv4();

        const emitChunks = async () => {
          for (const chunk of chunks) {
            await new Promise((resolve) => {
              socket.emit('openaiStreamRequestChunk', { chunk, id: sessionId }, () => {
                resolve();
              });
            });
          }
          // Signal that all chunks have been sent
          socket.emit('openaiStreamRequest', { id: sessionId, appRoute: type, responseString, question, modifiedData, summary, email, title });
        };

        await emitChunks();
  
        socket.on(responseString, (data) => {
          if (!!data?.error) {
            throw new Error('Error getting response!', data?.error)
          }
          else if (data?.response === 'stream-response-end') {
            setIsLoading(false);
            setChatLastItem({})
            addLastItemToChatResponse({question: consolidation ? question : question.at(-1), answer: answer})
            return res(true)
          }
  
          setChatLastItem((pre) => {
            answer += data?.response;
            return {...pre, answer: answer.replace(/```[\w]*|```/g, '')}
          })
        });
      })
    }

  } catch (error) {
    console.log(error, 'Error')
    handleError(error.message);
  }
}

//Stop Stream Response
export async function handleStopStreamResponse(streamId){
  try{
    const responseString = `openaiStreamResponse_${streamId}`;
    if(socket){
      socket.removeAllListeners(responseString);
    }
  }
  catch(error){
    console.log(error, 'Error stopping stream!')
  }
}

//15. Handle Textual Concept Creater
export async function handleTextualConceptGeneration(params) {
  const { values, storeUserInput, setOpenaiRequest, handleError, email, title } = params;
  try {
    const {file, proposalContent, ...filteredData} = values

    setOpenaiRequest(true);
    storeUserInput(filteredData);
    handleOpenaiStreamRequest({...params, values: {...values, title}})
    
    file?.name && handleFileUpload('upload-gigapp-input-file-aws', {file: file, email: email})
  } catch (error) {
    handleError(error.message);
  }
}

//15. Handle research instrument and Open ended response translator
export async function handleResearchInstrumentTranslator(params){
  const {id, values, storeUserInput, setOpenaiRequest, handleError, email, form, isResponseTranslatorApp, isTranslateTranscriptApp, firstName, appRoute, timezone} = params
  try{
    const {language, files, gigAppInputFiles} = values;
    setOpenaiRequest(true)
    const modifiedFilesData = files.map(file => ({fileName: file.name, size: file.size}))
    storeUserInput({files: modifiedFilesData, language})

    let formData = new FormData(), apiRoute = '';
    formData.append('email', email)
    formData.append('firstName', firstName)
    formData.append('appRoute', appRoute)
    formData.append('sessionId', id)
    formData.append('timezone', timezone)

    if(isResponseTranslatorApp){
      apiRoute = 'open_ended_response_translator'
      formData.append('file', files[0])
      formData.append('language', language)
      formData.append('appName', 'Open Ended Response Translator')
    }
    else if(isTranslateTranscriptApp){
      apiRoute = 'handle_translate_transcript'
      formData.append('appName', 'Translate a Transcript')
      formData.append('language', language)
      for (let key in files) {
        formData.append('files', files[key]);
      }
    }
    else{
      apiRoute = 'research_instrument_translator'
      formData.append('appName','Research Instrument Translator')
      language.map(item => formData.append('languages',item))
      files.map(item => formData.append('file',item))
    }
    
    setTimeout(() => {
      setOpenaiRequest(false)
      form.change('success',true)
    }, 3000)
    
    await axios.post(`${process.env.REACT_APP_DJANGO_BACKEND_API}/${apiRoute}`,
      formData,
      { headers: { 'Content-Type': 'multipart/form-data' } }
    );

    gigAppInputFiles.map(async (file)=>{
      await handleFileUpload('upload-gigapp-input-file-aws', {file : file, email: email})
    })

  }catch(error){
    setOpenaiRequest(false)
    handleError(error.message)
  }
}

// 16. Create a Discussion Guide From Transcripts
export async function handleExtractQuestionsFromTranscripts(params) {
  const { values, firstName, email, id, handleError, title, appRoute, storeUserInput, form, setOpenaiRequest } = params
  let { transcriptDocuments, discussionGuideFormat, selectedTranscripts = [] } = values;
  try {
    setOpenaiRequest(true)
    storeUserInput({})
    setTimeout(()=>{
      setOpenaiRequest(false)
      form.change("success",true)
    },1500)

    const updatedTranscriptDocuments = transcriptDocuments.map(item=>{
      handleFileUpload('upload-gigapp-input-file-aws', {file : item.file, email: email})
      return {content: item?.content, file: {name: item?.file?.name, size: item?.file?.size}}
    })

    await handleFileUpload('upload-gigapp-input-file-aws', {file : discussionGuideFormat, email: email})
    const response = await handleFileUpload('get-parsed-document', { file: discussionGuideFormat });

    const payload = {
      transcriptDocuments: updatedTranscriptDocuments,
      discussionGuideFormat: response?.data,
      selectedTranscripts,
      id,
      email,
      appRoute,
      firstName,
      title
    }

    await fetch(`${apiBaseUrl()}/api/extract-transcript-questions`,{
      method: 'POST',
      headers: {
        'Content-Type': 'text/plain',
        'Transfer-Encoding': 'chunked',
      },
      body: JSON.stringify(payload)
    })
  }catch(err){
    console.log(err)
  }
}

export const handleSendAdminEmail = async (currentUserName, currentUserEmail, closeModal) => {
  const emailParams = {
    step: 'maxMiniAppUsageLimit',
    userName: currentUserName,
    userEmail: currentUserEmail,
  }
  const response = await sendEmailUsingZepto(emailParams)
  if (response.message === 'Success') {
    closeModal()
  }
}

export const loadViewerComponent = (viewerRef, answerRef, loadOldChats) => {
  const script = document.createElement('script');
  script.src = 'https://uicdn.toast.com/editor/latest/toastui-editor-viewer.min.js';
  script.async = true;
  script.addEventListener('load', () => {
    // Script has loaded, now you can use toastui
    viewerRef.current = new toastui.Editor({
      el: document.querySelector("#viewer"),
      height: "auto",
      viewer: true,
    });
    if(loadOldChats) loadOldChats()
    
    if(answerRef){
      answerRef.current = new toastui.Editor({
        el: document.querySelector("#answer"),
        height: "auto",
        viewer: true,
      });
    }
  });
  document.body.appendChild(script);
  return script
}

export async function getFolderAnalysis(data){
  return await fetch(`${apiBaseUrl()}/api/get-folder-analysis`,{
    method: 'POST',
    headers: {
      'Content-Type': 'text/plain',
      'Transfer-Encoding': 'chunked',
    },
    credentials: 'include',
    body: JSON.stringify(data)
  })
}