import moment from "moment";
import { addMarketplaceEntities } from "../../ducks/marketplaceData.duck";
import { isScrollingDisabled } from "../../ducks/UI.duck";
import { fetchCurrentUser } from "../../ducks/user.duck";
import { getFirmUserDetails, updateFirmUserDetails } from "../../util/api";
import { storableError } from "../../util/errors";
import { types as sdkTypes } from '../../util/sdkLoader';
import { AVERAGE_RATING, USER_ROLE_CLIENT } from "../../util/types";
import { showListing } from "../FirmPage/FirmPage.duck";
import { queryUserReviews } from "../TransactionPage/TransactionPage.duck";
const { UUID } = sdkTypes;

export const RESULT_PAGE_SIZE = 5;
const resultIds = data => data.map(l => l.id);

//Action types

export const FETCH_FIRM_REQUEST = 'app/FirmDashBoardPage/FETCH_FIRM_REQUEST';
export const FETCH_FIRM_SUCCESS = 'app/FirmDashBoardPage/FETCH_FIRM_SUCCESS';
export const FETCH_FIRM_ERROR = 'app/FirmDashBoardPage/FETCH_FIRM_ERROR';

export const QUERY_LISTINGS_REQUEST = 'app/FirmDashBoardPage/QUERY_LISTINGS_REQUEST';
export const QUERY_LISTINGS_SUCCESS = 'app/FirmDashBoardPage/QUERY_LISTINGS_SUCCESS';
export const QUERY_LISTINGS_ERROR = 'app/FirmDashBoardPage/QUERY_LISTINGS_ERROR';

export const UPLOAD_IMAGE_REQUEST = 'app/FirmDashBoardPage/UPLOAD_IMAGE_REQUEST';
export const UPLOAD_IMAGE_SUCCESS = 'app/FirmDashBoardPage/UPLOAD_IMAGE_SUCCESS';
export const UPLOAD_IMAGE_ERROR = 'app/FirmDashBoardPage/UPLOAD_IMAGE_ERROR';

export const UPDATE_LISTING_REQUEST = 'app/FirmDashBoardPage/UPDATE_LISTING_REQUEST';
export const UPDATE_LISTING_SUCCESS = 'app/FirmDashBoardPage/UPDATE_LISTING_SUCCESS';
export const UPDATE_LISTING_ERROR = 'app/FirmDashBoardPage/UPDATE_LISTING_ERROR';

export const UPDATE_IMAGE_ORDER = 'app/FirmDashBoardPage/UPDATE_IMAGE_ORDER';

export const REMOVE_LISTING_IMAGE = 'app/FirmDashBoardPage/REMOVE_LISTING_IMAGE';
//InitialState
const initialState = {
  images: {},
  imageOrder: [],
  removedImageIds: [],
  uploadImageError: null,
  pagination: null,
  searchParams: null,
  currentPageResultIds: [],
  queryReviewsError: null,
  queryListingInProgress: false,
  queryListingsError: null,
  updateListingInProgress: false,
  updateListingError: null,
  affiliatedFirmIds: {},
  fetchFirmRequest: false,
  firm: {},
  fetchFirmError: false
};


//reducer function
export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {

    case QUERY_LISTINGS_REQUEST:
      return {
        ...state,
        searchParams: payload.searchParams,
        queryListingInProgress: true,
        queryListingsError: null,
      };
    case QUERY_LISTINGS_SUCCESS:
      return {
        ...state,
        currentPageResultIds: resultIds(payload.data),
        pagination: payload.meta,
        queryListingInProgress: false,
      };
    case QUERY_LISTINGS_ERROR:
      return {
        ...state,
        queryListingInProgress: false,
        queryListingsError: payload
      };
    case UPLOAD_IMAGE_REQUEST: {
      // payload.params: { id: 'tempId', file }
      const images = {
        ...state.images,
        [payload.params.id]: { ...payload.params },
      };
      return {
        ...state,
        images,
        imageOrder: state.imageOrder.concat([payload.params.id]),
        uploadImageError: null,
      };
    };
    case UPDATE_LISTING_REQUEST:
      return {
        ...state,
        updateListingInProgress: true,
        updateListingError: null,
      };
    case UPDATE_LISTING_SUCCESS:
      return {
        ...state,
        updateListingInProgress: false,
        updateListingError: null,
        affiliatedFirmIds: [],
      };
    case UPDATE_LISTING_ERROR:
      return {
        ...state,
        updateListingInProgress: false,
        updateListingError: payload,
      };
    case FETCH_FIRM_REQUEST:
      return { ...state, fetchFirmRequest: true }

    case FETCH_FIRM_SUCCESS:
      return { ...state, fetchFirmRequest: false, firm: payload }

    case FETCH_FIRM_ERROR:
      return { ...state, fetchFirmRequest: false, fetchFirmError: true }

    default:
      return state;
  }
}


//Selectors

export const checkScrollingDisabled = state => isScrollingDisabled(state);

// ================ Action creators ================ //
const requestAction = actionType => params => ({ type: actionType, payload: { params } });
const successAction = actionType => result => ({ type: actionType, payload: result.data });

const errorAction = actionType => error => ({ type: actionType, payload: error, error: true });


export const queryListingsRequest = searchParams => ({
  type: QUERY_LISTINGS_REQUEST,
  payload: { searchParams },
});

export const queryListingsSuccess = response => ({
  type: QUERY_LISTINGS_SUCCESS,
  payload: response,
});

export const queryListingsError = e => ({
  type: QUERY_LISTINGS_ERROR,
  error: true,
  payload: e,
});
export const updateListingsRequest = () => ({
  type: UPDATE_LISTING_REQUEST,

});

export const updateListingsSuccess = id => ({
  type: UPDATE_LISTING_SUCCESS,
  payload: { id },
});

export const updateListingsError = e => ({
  type: UPDATE_LISTING_ERROR,
  error: true,
  payload: e,
});

export const fetchFirmRequest = () => ({
  type: FETCH_FIRM_REQUEST
})

export const fetchFirmSuccess = data => ({
  type: FETCH_FIRM_SUCCESS,
  payload: data
})

export const fetchFirmError = () => ({
  type: FETCH_FIRM_ERROR
})

export const queryUserListings = ids => (dispatch, getState, sdk) => {

  dispatch(queryListingsRequest());
  const params = {
    ids,
    per_page: RESULT_PAGE_SIZE,
    pub_listingType: USER_ROLE_CLIENT,
    include: ['author', 'images'],

    'limit.images': 1,
  };
  return sdk.listings
    .query(params)
    .then(response => {
      // Pick only the id and type properties from the response listings
      dispatch(addMarketplaceEntities(response));
      dispatch(queryListingsSuccess(response.data));
      return response;
    })
    .catch(e => dispatch(queryListingsError(storableError(e))));
};

export const updateUserRating = (firm) => async (dispatch, getState, sdk) => {
  const userDetails = firm.newFirmUserDetails;
  const firmId = firm.firmDetails.id;
  const firmCreatedAt = moment(firm.createdAt).valueOf();
  try {
    await Promise.all(
      userDetails?.map(async (user) => {
        if (!user?.id) return;
        const reviews = await dispatch(queryUserReviews({ userId: new UUID(user?.id) }));
        const userTotalRatings = user?.averageRating?.length;
        const filteredRatings = reviews?.filter(review => moment(review.attributes.createdAt).valueOf() > firmCreatedAt);

        const ratings = filteredRatings?.map(review => {
          return {
            id: review.id.uuid,
            date: new Date(review.attributes.createdAt).getTime(),
            rating: review.attributes.rating,
            content: review.attributes.content
          }
        })

        if (userTotalRatings !== ratings?.length) {
          updateFirmUserDetails({
            action: AVERAGE_RATING,
            userEmail: user?.email,
            ratings
          })
          dispatch(getFirmUserData(firmId))
        }
      })
    );
  } catch (error) {
    console.log(error)
  }
};

export const getFirmUserData = firmId => async (dispatch, getState, sdk) => {
  dispatch(fetchFirmRequest())
  try {
    const firm = await getFirmUserDetails({ firmId });
    if (firm) {
      dispatch(fetchFirmSuccess(firm))

      const projects = firm.newFirmUserDetails.reduce((project, user) => {
        return project = [...project, ...user.publiclyPostedProject.map(p => p.projectId)]
      }, []);
      const ids = projects?.length && projects.join(',');
      if (ids) dispatch(queryUserListings(ids));
      dispatch(updateUserRating(firm))
    }
  } catch (e) {
    dispatch(fetchFirmError())
    return;
  }
};

//LoadData
export const loadData = params => async (dispatch, getState, sdk) => {
  try {
    const listingId = new UUID(params.id);
    return Promise.all([
      dispatch(fetchCurrentUser()),
      dispatch(showListing(listingId)),
      dispatch(getFirmUserData(params.id))
    ]);
  } catch (e) {
    return;
  }
};