import React, { useEffect, useState } from 'react';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Field, Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { maxLength, required, composeValidators } from '../../util/validators';
import { Form, Button, FieldTextInput, FieldCheckbox, IconCard, FieldSelect, IconSpinner } from '../../components';
import css from './EditListingJobCompanyForm.module.css';
import { currencyFeeTemp } from '../../marketplace-custom-config';
import config from '../../config';
import { useDispatch } from 'react-redux';
import { getFirmListing } from '../../containers/SearchPage/SearchPage.duck';

const EditListingJobCompanyForm = props => (
  <FinalForm
    {...props}
    render={formRenderProps => {
      const {
        className,
        disabled,
        ready,
        handleSubmit,
        intl,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        values,
        form,
        currentUser,
        isNewListingFlow,
        updateInProgressPrivate,
        hasCurrentMonthLimitReached,
        isListingPublished,
      } = formRenderProps;
      
      const firmId = currentUser?.id && currentUser?.attributes?.profile?.publicData?.firmListing?.firmId;
      const dispatch = useDispatch();
      const [showIndustryButton, setShowIndustryButton] = useState(true);
      const [fetchFirmRequest, setFetchFirmRequest] = useState(false);

      const {selectedIndustry, suggestion} = values || {};

      async function fetchUserFirmDetails(){
        try{
          if(firmId){
            setFetchFirmRequest(true)
            const firm = await dispatch(getFirmListing(firmId))
            
            if(firm?.id){
              const aboutUs = firm?.attributes?.publicData?.aboutUs;
              form.change('aboutCompany', aboutUs)
              setFetchFirmRequest(false)
            }
          }
        }catch(error){
          setFetchFirmRequest(false)
          // console.log(error)
        }
      }

      const companyLabel = intl.formatMessage({ id: 'EditListingJobCompanyForm.companyLabel'});
      const companyPlaceholderMessage = intl.formatMessage({id: 'EditListingJobCompanyForm.companyPlaceholderMessage'});
      const companyRequiredMessage = intl.formatMessage({id: 'EditListingJobCompanyForm.companyRequiredMessage'});
      
    
      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress 
        || !(values.maxPrice && values.minPrice) || !values?.budget || (!isListingPublished && hasCurrentMonthLimitReached);

      const {maxPrice: max, minPrice: min} = values;
      const maxPrice = typeof max === 'string' ? Number(max?.replace(/\,/g,'')) : max
      const minPrice = typeof min === 'string' ? Number(min?.replace(/\,/g,'')) : min

      function handleSuggestion(item){
        if(selectedIndustry.includes(item)) {
          const updateIndustry = selectedIndustry.filter(industry => industry !== item)
          form.change('selectedIndustry', updateIndustry)
        } else {
          form.change('selectedIndustry', [...selectedIndustry, item])
        }
        form.change('industryService', '')
        setShowIndustryButton(true)
      }

      const handleMaxPrice = (e) => {
        if(maxPrice < minPrice){
          form.change('maxPrice', minPrice.toLocaleString("en-US"));
        }
      }

      const handleMoneyFormat = (value, key) => {
        const modifiedValue = value.toLocaleString("en-US")
        if(Number.isInteger(value)) form.change(key, modifiedValue)
      }

      const handleCloseInput = () => {
        setShowIndustryButton(true)
        form.change('industryService', '')
      }

      const handleKeyPressEnter = (e) => {
        if (e.key === "Enter") {
          form.change('selectedIndustry', [...selectedIndustry, e.target.value]);
          form.change('industryService', '');
          setShowIndustryButton(true)
        }
      }

      return (
        <Form className={classes} onSubmit={(e) => handleSubmit(e)}>
          <div className={css.titleWrapper}>
            <FieldTextInput
              id="aboutCompany"
              name="aboutCompany"
              className={css.description}
              type="textarea"
              label={companyLabel}
              placeholder={companyPlaceholderMessage}
              validate={composeValidators(required(companyRequiredMessage))}
            />
            {firmId && (
              <div className={css.copyText}>
                <span onClick={fetchUserFirmDetails}>
                  {fetchFirmRequest ? (
                    <IconSpinner />
                  ) : (
                    <FormattedMessage id='EditListingJobCompanyForm.copyText' />
                  )}
                </span>
                <p>i</p>
              </div>
            )}
          </div>
          <div className={css.serviceContainer}>
            <div className={css.serviceHeading}>
              <FormattedMessage id="EditListingBriefFeaturesForm.industrySector" />
            </div>
            <div className={css.releventService}>
              <FormattedMessage id="EditListingBriefFeaturesForm.pleaseAddRelevantIndustry" />
            </div>
            <div className={css.inputSelectCard}>
              {Array.isArray(selectedIndustry) && selectedIndustry.map(item =>
                <span className={css.servicesSelected} key={item}>
                  {item}
                  <span className={css.closeItem} onClick={() => handleSuggestion(item)}>
                    <IconCard brand="crossSign"/>
                  </span>
                </span>
              )}
            </div>
            {showIndustryButton ? (
              <button onClick={() => setShowIndustryButton(false)} className={css.addServicesButton}>
                <FormattedMessage id="EditListingBriefFeaturesForm.addIndustry" />
              </button>
            ) : (
              <>
                <div className={css.inputSelect}>
                  <FieldTextInput
                    classNam={css.input}
                    id='industryService'
                    name='industryService'
                    type="text"
                    onKeyPress={handleKeyPressEnter}
                    placeholder={"Enter a industry..."}
                    autoComplete="off"
                    autoFocus
                  />
                  <span onClick={handleCloseInput}><IconCard brand="cross"/></span>
                </div>
                <ul>
                  {values.industryService && suggestion?.length &&
                    suggestion.filter((item, index, array) => array.findIndex((i) => i.key === item.key) === index)
                      .map((item) => {
                        if (item.key.includes(values.industryService?.toLowerCase())) {
                          return (
                            <li
                              key={item.key}
                              onClick={() => handleSuggestion(item.label)}
                              className={selectedIndustry.includes(item.label) && css.activeSelect}
                            >
                              {item.label}
                            </li>
                          );
                        }
                      })}
                </ul>
              </>
            )}
          </div>
          <div className={css.inputsWrapper}>
            <FieldSelect
              id='budget'
              name='budget'
              className={css.currencySelector}
              defaultValue={currencyFeeTemp[0].key}
              label="CTC Budget"
            >
              <option>Select</option>
              {currencyFeeTemp.map(({ sym, key }) => (
                <option key={key} value={key}>
                  {` ${sym} ( ${key} )`}
                </option>
              ))}
            </FieldSelect>
            <FieldTextInput
              onChange={(e) => {
                const value = Number(e.target.value.replace(/\,/g,''))
                handleMoneyFormat(value, 'minPrice')
                if(value > maxPrice) form.change('minPrice', maxPrice.toLocaleString("en-US"))
              }}
              className={classNames(css.minPrice)}
              type="text"
              id='minPrice'
              name='minPrice'
              // label="From"
              placeholder={'0'}
              // validate={required(minRequiredMessage)}
            />
            <span className={css.priceSeparator}></span>
            <FieldTextInput
              onChange={(e) => {
                const value = Number(e.target.value.replace(/\,/g,''))
                handleMoneyFormat(value, 'maxPrice')
              }}
              onBlur={handleMaxPrice}
              className={classNames(css.maxPrice)}
              type="text"
              id='maxPrice'
              name='maxPrice'
              // label="To"
              placeholder={'0'}
              // validate={required(maxRequiredMessage)}
            />
          </div>
          <div className={css.buttonsWrapper}>
            <Button
              className={css.submitButton}
              type="button"
              onClick={() => {
                form.change('type', 'public');
                handleSubmit(values);
              }}
              inProgress={submitInProgress}
              disabled={submitDisabled}
              ready={submitReady}
            >
              {saveActionMsg}
            </Button>
            {isNewListingFlow && (
              <Button
                className={css.submitButton}
                type="button"
                onClick={() => {
                  form.change('type', 'private');
                  handleSubmit(values);
                }}
                inProgress={updateInProgressPrivate}
                disabled={submitDisabled}
                ready={submitReady}
              >
                <FormattedMessage id="EditListingJobCompanyForm.savePrivateButtonText" />
              </Button>
            )}
          </div>
        </Form>
      );
    }}
  />
);

EditListingJobCompanyForm.defaultProps = { className: null, fetchErrors: null };

EditListingJobCompanyForm.propTypes = {
  className: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    createListingDraftError: propTypes.error,
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  })
};

export default compose(injectIntl)(EditListingJobCompanyForm);
