import React, { useEffect, useState } from 'react';
import { bool, func, object, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { ensureOwnListing } from '../../util/data';
import { findOptionsForSelectFilter } from '../../util/search';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { EditListingHintTooltip, NamedLink } from '../../components';
import { EditListingDescriptionForm } from '../../forms';
import config from '../../config';
import { getDefaultTimeZoneOnBrowser } from '../../util/dates';
import css from './EditListingDescriptionPanel.module.css';
import { checkExpertAvailability, isEmailVerified } from '../../util/destructorHelpers';
import { updateProfile } from '../../containers/ProfileSettingsPage/ProfileSettingsPage.duck';
import { useDispatch } from 'react-redux';
import { createLanguage, fetchURLPreviews } from '../../util/api';

const defaultTimeZone = () =>
  typeof window !== 'undefined' ? getDefaultTimeZoneOnBrowser() : 'Etc/UTC';

const defaultAvailabilityPlan = {
  type: 'availability-plan/time',
  timezone: defaultTimeZone(),
  entries: [
    { dayOfWeek: 'mon', startTime: '00:00', endTime: '00:00', seats: 1 },
    { dayOfWeek: 'tue', startTime: '00:00', endTime: '00:00', seats: 1 },
    { dayOfWeek: 'wed', startTime: '00:00', endTime: '00:00', seats: 1 },
    { dayOfWeek: 'thu', startTime: '00:00', endTime: '00:00', seats: 1 },
    { dayOfWeek: 'fri', startTime: '00:00', endTime: '00:00', seats: 1 },
    { dayOfWeek: 'sat', startTime: '00:00', endTime: '00:00', seats: 1 },
    { dayOfWeek: 'sun', startTime: '00:00', endTime: '00:00', seats: 1 },
  ],
};

const EditListingDescriptionPanel = props => {
  const {
    className,
    rootClassName,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
    isNewURI,
    currentUser,
    completedTabsPer,
  } = props;

  const dispatch = useDispatch();
  const [blogAndWebsitePreview, setBlogAndWebsitePreview] = useState({});
  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);
  const { description, title, publicData } = currentListing.attributes;
  const displayName = currentUser && currentUser.id && currentUser.attributes.profile?.displayName;
  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const checkEmailVerified = !!currentUser?.id && isEmailVerified(currentUser);
  const isExpertUnavailable = !!checkExpertAvailability(listing);
  const currentUserLocation = currentUser?.id && currentUser?.attributes?.profile?.publicData?.location;
  const { category, country, experienceYears, userLocation, spokenLanguages, website, blogUrl } = publicData || {};
  const countryDropDown = findOptionsForSelectFilter('country', config.custom.filters);

  async function fetchBlogAndWebsitePreview(){
    if(website){
      const response = await fetchURLPreviews(website);
      setBlogAndWebsitePreview(preValue => ({...preValue, websitePreviewResponse: response}))
    }
    if(blogUrl){
      const response = await fetchURLPreviews(blogUrl);
      setBlogAndWebsitePreview(preValue => ({...preValue, blogPreviewResponse: response}))
    }
  }

  useEffect(() => {
    fetchBlogAndWebsitePreview();
  }, [website, blogUrl])

  const handleSubmit = async (values) => {
    const { title, description, category, experienceYears, location, spokenLanguages, website, blogUrl } = values;

    const city = location.search.replace(/\s/g, '').split(',').slice(0)[0].toLowerCase();
    const place = location.search.replace(/\s/g, '').split(',').slice(-1)[0].toLowerCase();
    const country = location.search.split(',').at(-1);
    const updateValues = {
      title: title.trim(),
      description,
      ...(isNewURI ? { availabilityPlan: defaultAvailabilityPlan } : {}),
      publicData: {
        category,
        experienceYears,
        country: country,
        countryKeyword: place + ',' + city,
        displayName,
        userLocation: location,
        type: 'partner',
        'expert-unavailability': isExpertUnavailable ? isExpertUnavailable : false,
        spokenLanguages,
        spokenLanguagesKeywords: spokenLanguages?.length && spokenLanguages?.map(item => item.label).join(', '),
        website,
        blogUrl
      },
    };

    if (currentUserLocation?.search !== location?.search){
      dispatch(updateProfile({
        publicData: { location }
      }));
    }
    onSubmit(updateValues);

    if (typeof spokenLanguages !== 'undefined' && spokenLanguages.length) {
      await createLanguage(spokenLanguages);
    }
  }

  return (
    <div className={classes}>
      <h2 className={css.title}>
        <FormattedMessage id="EditListingDescriptionPanel.title" />
      </h2>
      <EditListingHintTooltip
        tooltipText={<FormattedMessage id="EditListingDescriptionPanel.InThisSection" />}
      />
      {checkEmailVerified ?
        <EditListingDescriptionForm
          className={css.form}
          initialValues={{
            title,
            description,
            category,
            country,
            experienceYears,
            location: userLocation,
            spokenLanguages,
            website,
            blogUrl,
            ...blogAndWebsitePreview
          }}
          saveActionMsg={submitButtonText}
          onSubmit={handleSubmit}
          onChange={onChange}
          disabled={disabled}
          ready={ready}
          updated={panelUpdated}
          updateInProgress={updateInProgress}
          fetchErrors={errors}
          countryDropDown={countryDropDown}
        />
        : (
          <NamedLink name="AccountSettingsPage" className={css.verifyEmailMessageButton}>
            <FormattedMessage id="EditListinDescriptionPanel.verifyEmailMessage" />
          </NamedLink>
        )}
    </div>
  );
};

EditListingDescriptionPanel.defaultProps = {
  className: null,
  rootClassName: null,
  errors: null,
  listing: null,
};

EditListingDescriptionPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingDescriptionPanel;
