import React, {useState} from 'react';
import { bool, object, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import classNames from 'classnames';
import { FormattedMessage, injectIntl } from '../../util/reactIntl';
import * as validators from '../../util/validators';
import {
  FieldAntDatePickerInput, FieldCheckbox,
  FieldTextInput,
  Form,
  PrimaryButton,
} from '../../components';

import css from './EditListingWorkExperienceForm.module.css';

import { composeValidators } from "../../util/validators";
import {expertType, typeOfEmployment} from "../../marketplace-custom-config";
import CustomCategorySelectFieldMaybe from "../EditListingDescriptionForm/CustomCertificateSelectFieldMaybe";
import {fakeIntl} from "../../util/test-data";

const ContentWorkExperienceComponent = props => {
  const { onClose, intl, onSubmit, ...restOfProps } = props;

  // title
  const addExperience = intl.formatMessage({
    id: 'EditListingWorkExperienceFormComponent.addExperience',
  });

  // job
  const jobLabel = intl.formatMessage({
    id: 'EditListingWorkExperienceFormComponent.jobLabel',
  });
  const jobPlaceholder = intl.formatMessage({
    id: 'EditListingWorkExperienceFormComponent.jobPlaceholder',
  });
  const jobRequired = intl.formatMessage({
    id: 'EditListingWorkExperienceFormComponent.jobRequired',
  });

  // company
  const companyLabel = intl.formatMessage({
    id: 'EditListingWorkExperienceFormComponent.companyLabel',
  });
  const companyPlaceholder = intl.formatMessage({
    id: 'EditListingWorkExperienceFormComponent.companyPlaceholder',
  });
  const companyRequired = intl.formatMessage({
    id: 'EditListingWorkExperienceFormComponent.companyRequired',
  });

  // TypeEmployment

  const labelTypeEmployment = intl.formatMessage({
    id: 'EditListingWorkExperienceFormComponent.labelTypeEmployment',
  });
  const typeEmploymentPlaceholder = intl.formatMessage({
    id: 'EditListingWorkExperienceFormComponent.typeEmploymentPlaceholder',
  });

  // startDate endDate
  const startDate = intl.formatMessage({
    id: 'EditListingWorkExperienceFormComponent.startDate',
  });
  const endDate = intl.formatMessage({
    id: 'EditListingWorkExperienceFormComponent.endDate',
  });

  const datePlaceholder = fakeIntl.formatDateRevers(new Date())

  // checkbox

  const labelCheckbox = intl.formatMessage({ id: 'EditListingWorkExperienceFormComponent.checkboxIsWork' })

  // description

  const description = intl.formatMessage({
    id: 'EditListingWorkExperienceFormComponent.description',
  });
  const descriptionPlaceholder = intl.formatMessage({
    id: 'EditListingWorkExperienceFormComponent.descriptionPlaceholder',
  });
  const descriptionRequired = intl.formatMessage({
    id: 'EditListingWorkExperienceFormComponent.descriptionRequired',
  });

  const submit = values => onSubmit({ values: values })

  const handleDelete = values => {
    if(values.number) onSubmit({ type: values.number, values: values })
    onClose()
  }
  return (
    <FinalForm
      {...restOfProps}
      onSubmit={submit}
      mutators={{
        ...arrayMutators,
      }}
      render={fieldRenderProps => {
        const {
          rootClassName,
          className,
          formId,
          form,
          disabled,
          invalid,
          handleSubmit,
          inProgress,
          intl,
          values,
        } = fieldRenderProps;

        const classes = classNames(rootClassName || css.root, className);
        const submitInProgress = inProgress;
        const isWork = !!(values.isWork && values.isWork.length)
        const valueEndDate = values.endDate
        const valueStartDate = values.startDate
        const typeEmployment = values.typeEmployment && values.typeEmployment.length

        const submitDisabled = invalid || disabled || submitInProgress || !valueStartDate || !typeEmployment

        const minAgeRequired = validators.dateComparison('Invalid date', valueEndDate);
        const dateComparison = validators.dateComparison('Invalid date');
        const reversComparison = validators.dateComparison('Invalid date', valueStartDate, true);
        const validStartDate =  isWork || !valueEndDate
          ? composeValidators(dateComparison)
          : composeValidators(minAgeRequired, dateComparison)
        const validEndDate =  isWork || !valueStartDate
          ? composeValidators(dateComparison)
          : composeValidators(dateComparison, reversComparison)

        return (
          <Form id={formId} className={classes} onSubmit={ e => {
            handleSubmit(e)
            form.restart()
          }}>
            <h2 className={css.titleModal}>{addExperience}</h2>
            <FieldTextInput
              id="job"
              name="job"
              className={css.description}
              type="text"
              label={jobLabel}
              placeholder={jobPlaceholder}
              validate={validators.required(jobRequired)}
            />

            <FieldTextInput
              id="company"
              name="company"
              className={css.description}
              type="text"
              label={companyLabel}
              placeholder={companyPlaceholder}
              validate={validators.required(companyRequired)}
            />

            <CustomCategorySelectFieldMaybe
              className={css.description}
              id="typeEmployment"
              name="typeEmployment"
              categories={typeOfEmployment}
              intl={intl}
              label={labelTypeEmployment}
              placeholder={typeEmploymentPlaceholder}
            />

            <div className={css.dateContainer}>
              <FieldAntDatePickerInput
                className={css.inputDate}
                id="startDate"
                name="startDate"
                label={startDate}
                form={form}
                placeholder={datePlaceholder}
                validate={validStartDate}
              />
              {isWork ?
              <FieldAntDatePickerInput
                className={css.disabledInputDate}
                id="disabled"
                name="disabled"
                label={endDate}
                form={form}
                placeholder={datePlaceholder}
              /> :
                <FieldAntDatePickerInput
                  className={css.inputDate}
                  id="endDate"
                  name="endDate"
                  label={endDate}
                  form={form}
                  placeholder={datePlaceholder}
                  validate={validEndDate}
                />
              }
              <FieldCheckbox
                className={css.checkedBox}
                id='isWork'
                name='isWork'
                label={labelCheckbox}
                value='work'
                />
            </div>

            <FieldTextInput
              id="description"
              name="description"
              className={css.description}
              type="textarea"
              label={description}
              placeholder={descriptionPlaceholder}
              validate={validators.required(descriptionRequired)} 
            />

            <div className={css.buttonSection}>
              <PrimaryButton className={css.buttonDelete} onClick={()=> handleDelete(values)}>
                <FormattedMessage id="EditListingWorkExperienceFormComponent.deleteSchedule" />
              </PrimaryButton>

              <PrimaryButton className={css.buttonSave} type="submit" disabled={submitDisabled}>
                <FormattedMessage id="EditListingWorkExperienceFormComponent.saveSchedule" />
              </PrimaryButton>
            </div>
          </Form>
        );
      }}
    />
  );
};

const ContentWorkExperience = compose(injectIntl)(
  ContentWorkExperienceComponent
);


export default ContentWorkExperience;
