import React from 'react'
import css from '../LandingPageTabsPanel.module.css'
import { createResourceLocatorString } from '../../../util/routes';
import routeConfiguration, { draftId, draftSlug } from '../../../routeConfiguration';
import { AI_BRIEF_GENERATOR, AI_JOB_DESCRIPTION, INBOX_TAB_BRIEFS, JOB, LONG_TERM_INSIGHT_OPPORTUNITY, USER_ROLE_CLIENT, USER_ROLE_PARTNER } from '../../../util/types';
import { ExternalLink, IconCardHomePage, NamedLink } from '../..';
import { FormattedMessage } from 'react-intl';
import { fetchListingType } from '../../../containers/SearchPage/SearchPage.duck';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';

const LandingPageSecondTab = (props) => {
  const { userRole, handleAdminEmailModal, history, intl, isApprovedUser, currentUser, checkName } = props;
  const isPartner = userRole === USER_ROLE_PARTNER;
  const dispatch = useDispatch();

  function handleBriefAppRedirect(){
    history.push(
      createResourceLocatorString('OpenAiAppsPage', routeConfiguration(), {
        slug: AI_BRIEF_GENERATOR,
      })
    );
  }

  function handleJobAppRedirect(){
    history.push(
      createResourceLocatorString('OpenAiAppsPage', routeConfiguration(), {
        slug: AI_JOB_DESCRIPTION,
      })
    );
  }

  async function handleSearchPageRedirect(params){
    if(Object.values(params).length) await dispatch(fetchListingType(JOB))
    history.push(
      createResourceLocatorString(
        'SearchPage',
        routeConfiguration(),
        {},
        params
      )
    );
  }

  const addHocProject = [
    {
      icon: <IconCardHomePage type="search" />,
      heading: intl.formatMessage({id: isPartner 
        ? 'LandingPageTabsPanel.searchForProjectsHeading' 
        : 'LandingPageTabsPanel.createABriefCardHeading'
      }),
      subHeading: intl.formatMessage({id: isPartner 
        ? 'LandingPageTabsPanel.searchForProjectsDescription' 
        : 'LandingPageTabsPanel.createABriefCardDescription'
      }), 
      link: currentUser ? (isPartner ? (
        <NamedLink
          className={classNames(css.linkName,((!isApprovedUser  && currentUser) ? css.disabled : {}))}
          name="SearchPage"
        >
          <FormattedMessage id="LandingPageTabsPanel.searchForProjectsLinkText" />
        </NamedLink>
      ) : (
        <NamedLink
          className={classNames(css.linkName,(!isApprovedUser  && currentUser) ? css.disabled : {})}
          name="NewListingPage"
          params={{
            id: draftId,
            slug: draftSlug,
            type: 'new',
            tab: 'description',
          }}
        >
          <FormattedMessage id="LandingPageTabsPanel.createABriefCardLinkText" />
        </NamedLink>
      )) : (
        <NamedLink
          className={css.linkName}
          name="LoginPage"
        >
          <FormattedMessage id="LandingPageTabsPanel.searchForProjectsLinkText" />
        </NamedLink>
      ) ,
      openModal: isApprovedUser && !isPartner,
      externalLink: currentUser ? (isPartner ? (
        <ExternalLink href='https://docs.insightgig.com/searching-for-briefs' className={classNames(css.questionMark,(!isApprovedUser  && currentUser) ? css.disabled : {})}>
          <IconCardHomePage type="questionmark" />
        </ExternalLink>
      ) : (
        <div className={classNames(css.learnMore,(!isApprovedUser && currentUser) ? css.disabled : {})} onClick={handleBriefAppRedirect}>
          <IconCardHomePage type="questionmark" />
        </div>
      ))
      : 
      <div name="LoginPage" className={css.learnMore}>
        <IconCardHomePage type="questionmark" />
      </div>
    },
    {
      icon: <IconCardHomePage type="requirement" />,
      heading: intl.formatMessage({id: isPartner 
        ? 'LandingPageTabsPanel.sendProposalHeading' 
        : 'LandingPageTabsPanel.identifyAnExpertCardHeading'
      }),
      subHeading: intl.formatMessage({id: isPartner 
        ? 'LandingPageTabsPanel.sendProposalDescription' 
        : 'LandingPageTabsPanel.identifyAnExpertCardDescription'
      }),
      link: isPartner ? (
        <ExternalLink href='https://docs.insightgig.com/creating-proposals' className={classNames(css.questionMark,(!isApprovedUser  && currentUser) ? css.disabled : {})}>
          <FormattedMessage id='LandingPageTabsPanel.sendProposalLinkText' />
        </ExternalLink>
      ) : (
        <a className={classNames(css.linkName,(!isApprovedUser  && currentUser) ? css.disabled : {})} onClick={() => handleSearchPageRedirect({ pub_engagementRoles: 'has_any:projects' })}>
          <FormattedMessage id="LandingPageTabsPanel.identifyAnExpertCardLinkText" />
        </a>
      ),
      openModal: isApprovedUser && !!currentUser?.id,
      externalLink: (
        <div className={classNames(css.questionMark,(!isApprovedUser  && currentUser) ? css.disabled : {})}>
          <IconCardHomePage type="questionmark" />
        </div>
      )
    },
    {
      icon: <IconCardHomePage type="collaborators" />,
      heading: intl.formatMessage({id: 'LandingPageTabsPanel.collaborateInsightProject'}),
      subHeading: intl.formatMessage({id: 'LandingPageTabsPanel.collaborateInsightProjectDescription'}),
      link: (
        <NamedLink
          className={classNames(css.linkName,(!isApprovedUser  && currentUser) ? css.disabled : {})}
          name={checkName("InboxPage")}
          params={{ tab: INBOX_TAB_BRIEFS, usertype: USER_ROLE_PARTNER }}
        >
          <FormattedMessage id='LandingPageTabsPanel.collaborateInsightProjectLinkText' />
        </NamedLink>
      ),
      openModal: isApprovedUser && !!currentUser?.id,
      externalLink: (
        <div className={classNames(css.questionMark,(!isApprovedUser  && currentUser) ? css.disabled : {})}>
          <IconCardHomePage type="questionmark" />
        </div>
      )
    },
  ]

  const longTermOpportunities = [
    {
      icon: <IconCardHomePage type="multiuser" />,
      heading: intl.formatMessage({id: isPartner 
        ? 'LandingPageTabsPanel.jobSearchHeading' 
        : 'LandingPageTabsPanel.needForSecondmentHeading'
      }),
      subHeading: intl.formatMessage({id: isPartner 
        ? 'LandingPageTabsPanel.jobSearchDescription' 
        : 'LandingPageTabsPanel.needForSecondmentDescription'
      }),
      link: currentUser ? (isPartner ? (
        <div className={classNames(css.linkName,(!isApprovedUser  && currentUser) ? css.disabled : {})} onClick={() => handleSearchPageRedirect({})}>
          <FormattedMessage id='LandingPageTabsPanel.jobSearchLinkText' />
        </div>
      ) : (
        <NamedLink
          className={classNames(css.linkName,(!isApprovedUser  && currentUser) ? css.disabled : {})}
          name="EditJobListingPage"
          params={{
            id: draftId,
            slug: draftSlug,
            type: 'new',
            tab: 'role',
          }}
        >
          <FormattedMessage id="LandingPageTabsPanel.needForSecondmentLinkText" />
        </NamedLink>)
      ) : 
      <NamedLink className={css.linkName} name="LoginPage">
        <FormattedMessage id="LandingPageTabsPanel.needForSecondmentLinkText" />
      </NamedLink>,
      openModal: isApprovedUser && isPartner,
      externalLink: (
        <div className={classNames(css.questionMark,(!isApprovedUser  && currentUser) ? css.disabled : {})} onClick={() => !isPartner && handleJobAppRedirect()}>
          <IconCardHomePage type="questionmark" />
        </div>
      )
    },
    {
      icon: <IconCardHomePage type="publiclisting" />,
      heading: intl.formatMessage({id: isPartner 
        ? 'LandingPageTabsPanel.applyForAJobHeading' 
        : 'LandingPageTabsPanel.longTermInsightHeading'
      }),
      subHeading: intl.formatMessage({id: isPartner 
        ? 'LandingPageTabsPanel.applyForAJobDescription' 
        : 'LandingPageTabsPanel.jobSearchDescription'
      }),
      link: isPartner ? (
        <div className={classNames(css.linkName,(!isApprovedUser && currentUser) ? css.disabled : {})}>
          <FormattedMessage id='LandingPageTabsPanel.applyForAJobLinkText' />
        </div> 
      ) : (
        <a className={classNames(css.linkName,(!isApprovedUser && currentUser) ? css.disabled : {})} onClick={() => handleSearchPageRedirect({ pub_engagementRoles: 'has_any:secondment,fullTimeEmployment' })}>
          <FormattedMessage id='LandingPageTabsPanel.longTermInsightLinkText' />
        </a> 
      ),
      openModal: isApprovedUser && !!currentUser?.id,
      externalLink: (
        <div className={classNames(css.questionMark,(!isApprovedUser  && currentUser) ? css.disabled : {})}>
          <IconCardHomePage type="questionmark" />
        </div>
      )
    },
    {
      icon: <IconCardHomePage type="job" />,
      heading: intl.formatMessage({id: isPartner 
        ? 'LandingPageTabsPanel.selectionProcessHeading' 
        : 'LandingPageTabsPanel.candidateForInsightRoleHeading'
      }),
      subHeading: intl.formatMessage({id: isPartner 
        ? 'LandingPageTabsPanel.selectionProcessDescription' 
        : 'LandingPageTabsPanel.candidateForInsightRoleDescription'
      }),
      link: (
        <NamedLink
          className={classNames(css.linkName,(!isApprovedUser  && currentUser) ? css.disabled : {})}
          name={checkName("InboxPage")}
          params={{ tab: INBOX_TAB_BRIEFS, usertype: USER_ROLE_PARTNER }}
        >
          <FormattedMessage id='LandingPageTabsPanel.candidateForInsightLinkText' />
        </NamedLink>
      ),
      openModal: isApprovedUser && !!currentUser?.id,
      externalLink: (
        <div className={classNames(css.questionMark,(!isApprovedUser  && currentUser) ? css.disabled : {})}>
          <IconCardHomePage type="questionmark" />
        </div>
      )
    },
]

  return (
    <div className={css.marketPlaceHocs}>
      <div className={css.marketplaceRow}>
        <div className={css.addHocProject}>
          <div className={css.marketMainTitle}>
            <div className={css.bigTitle}>{isPartner ? 'Explore Opportunities' : 'Talent Marketplace'}</div>
            <div className={css.smallTitle}>Ad-hoc projects</div>
          </div>
          <div className={css.marketplaceArray}>
            {addHocProject?.map((item) => {
              const {heading, subHeading, icon, link, externalLink, openModal} = item;
              return (
                <div className={css.marketplaceList} key={heading}>
                  <div className={css.marketIcon}>{icon}</div>
                  <div className={css.marketDetail}>
                    <div className={css.marketHeading}>{heading}</div>
                    <div className={css.marketSubHeading}>{subHeading}</div>
                    <div className={css.marketBottom}>
                      {link}
                      <span onClick={() => openModal && handleAdminEmailModal({cardTitle: heading, cardDescription: subHeading})}>
                        {externalLink}
                      </span>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
        <div className={css.longTermProject}>
          <div className={css.marketMainTitle}>
            <div className={css.bigTitle}>{isPartner ? 'Explore Opportunities' : 'Talent Marketplace'}</div>
            <div className={css.smallTitle}>Long-term opportunities</div>
          </div>
          <div className={css.marketplaceArray}>
            {longTermOpportunities?.map((list) => {
              const {heading, subHeading, icon, link, externalLink, openModal} = list;
              return (
                <div className={css.marketplaceList} key={heading}>
                  <div className={css.marketIcon}>{icon}</div>
                  <div className={css.marketDetail}>
                    <div className={css.marketHeading}>{heading}</div>
                    <div className={css.marketSubHeading}>{subHeading}</div>
                    <div className={css.marketBottom}>
                      {link}
                      <span onClick={() => openModal && handleAdminEmailModal({cardTitle: heading, cardDescription: subHeading})}>
                        {externalLink}
                      </span>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

export default LandingPageSecondTab