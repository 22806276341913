import React from 'react';
import { connect } from 'react-redux';
import { Modal } from '../../components';
import { ModalSendBriefForm } from '../../forms';
import css from './ModalSendBrief.module.css';

const ModalSendBrief = (props) => {
  const {
    id,
    isOpen,
    onClose,
    onManageDisableScrolling,
    onSubmit,
    currentUserBriefs,
    inProgress,
    currentUser,
  } = props;
  
  return (
    <Modal
      id={id}
      className={css.disableModalBorder}
      containerClasses={css.containerClassName}
      isOpen={isOpen}
      onClose={onClose}
      usePortal
      onManageDisableScrolling={onManageDisableScrolling}
      closeButtonMessage={' '}
    >
      <ModalSendBriefForm
        options={currentUserBriefs}
        onClose={onClose}
        onSubmit={onSubmit}
        inProgress={inProgress}
        currentUser={currentUser}
        isJobListing={false}
      />
    </Modal>
  );
}

const mapStateToProps = state => {
  const {
    currentUserBriefs,
    currentUserBriefsInProgress,
    currentUserBriefsError,
    sharedListings

  } = state.user;
  const combinedListings = (sharedListings?.length ? [...(Array.isArray(currentUserBriefs) ? currentUserBriefs : []), ...sharedListings] : currentUserBriefs);

  return {
    currentUserBriefs: combinedListings,
    currentUserBriefsInProgress,
    currentUserBriefsError
  };
};

export default connect(mapStateToProps, {})(ModalSendBrief);

