import React, { useEffect, useState } from 'react'
import { stripeCreateChargeIndia } from '../../util/api';
import { freePlan, getPaymentTerms, getUserDetails, professionIndividualPlan, smallTeamPlan } from '../../util/destructorHelpers';
import css from './StripeIndiaPaymentPanel.module.css';
import { StripeIndiaPaymentForm } from '../../forms';
import { CARD, ONLINE, TEN, TWENTY } from '../../util/types';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { confirmCardPaymentStripeIn } from '../../containers/CheckoutPage/CheckoutPage.duck';
import Button from '../Button/Button';
import { FormattedMessage } from 'react-intl';


const StripeIndiaPaymentPanel = (props) => {
    const { currentTransaction, isFinal, onClose, intentAuthenticationFailureMessage, isOpen, orderParams, emailParams, handlePaymentState, stripeConnectAccountId, isProviderIndianOrigin, currentUser } = props;
    const dispatch = useDispatch();
    const [submitInProgress, setSubmitInProgress] = useState(false)
    const [stripe, setStripe] = useState(null);
    const isFreePlanUsed = freePlan(currentTransaction?.provider)
    const isProfessionalPlanUsed = professionIndividualPlan(currentTransaction?.provider)
    const isSmallTeamPlanUsed = smallTeamPlan(currentTransaction?.provider)
    const paymentTerms = getPaymentTerms(currentTransaction);
    const { amount, currency } = currentTransaction?.attributes?.metadata?.proposal?.paymentFee ?? {};
    const returnUrl = typeof window !== 'undefined' && window.location.href;
    // Calculate the application fee based on the plan being used
    // Free plan: 20 percent
    // Professional or Small Team plan: 10 percent
    const applicationFee = isFreePlanUsed ? TWENTY : (isProfessionalPlanUsed || isSmallTeamPlanUsed) ? TEN : TEN;
    const userName = currentUser && getUserDetails(currentUser)?.fullName;
    const userEmail = currentUser && getUserDetails(currentUser)?.email;
    useEffect(() => {
        const loadStripe = async () => {
            const stripe = await window.Stripe(process.env.REACT_APP_STRIPE_INDIA_PUBLISHABLE_KEY, {
                stripeAccount: stripeConnectAccountId
            });
            setStripe(stripe);
        };
        loadStripe();
    }, [stripeConnectAccountId])

    const paymentParams = {
        applicationFee,
        amount,
        currency,
        paymentTerms,
        stripeConnectAccountId,
        return_url: returnUrl,
        isFinal,
        txId: currentTransaction?.id,
        isProviderIndianOrigin,
        userName,
        userEmail
    }
    const handleSubmit = async (val) => {
        setSubmitInProgress(true)

        try {
            const { cardHolderName, addressLine1, addressLine2, city, state, postal, country, cardElement } = val;
            if (!val || !val.cardHolderName || !val.addressLine1 || !val.city || !val.postal || !val.country || !val.cardElement) {
                throw new Error('Missing required field(s) in form submission');
            }
            const { paymentMethod, error } = await stripe.createPaymentMethod({
                type: CARD,
                card: cardElement,
                billing_details: {
                    name: cardHolderName,
                    address: {
                        line1: addressLine1,
                        line2: addressLine2 ?? '',
                        city,
                        state,
                        postal_code: postal,
                        country,
                    },
                },
            }, {
                stripeAccount: stripeConnectAccountId
            });
            if (error) {
                // console.log(error);
                setSubmitInProgress(false);

            } else {
                Object.assign(paymentParams, { paymentMethodId: paymentMethod?.id })
                // Send paymentMethod.id and other details to your server for further processing

                const response = await stripeCreateChargeIndia(paymentParams);
                if (response) {
                    typeof window !== 'undefined' && (window.location.href = response?.url);
                    handlePaymentState(ONLINE)
                }

            }

        } catch (error) {
            setSubmitInProgress(false)
        }
    }
    const handleFinalPayment = async () => {
        const response = await dispatch(confirmCardPaymentStripeIn({ ...orderParams, ...emailParams }));
        onClose()
    }

    return (
        <div className={css.stripeIndiaPaymentPanel}>
            {stripe && Number(paymentTerms) === 1 && isFinal || (Number(paymentTerms) === 0 && !isFinal) ?
                <Button onClick={handleFinalPayment}>
                    <FormattedMessage id="StripeIndiaPaymentPanel.continueButton" />
                </Button> :
                stripe && <StripeIndiaPaymentForm
                    onSubmit={handleSubmit}
                    currentTransaction={currentTransaction}
                    stripe={stripe}
                    submitInProgress={submitInProgress}
                    isOpen={isOpen}
                />

            }
            <p className={css.intentAuthenticationFailureMessage}>{!isOpen ? null : intentAuthenticationFailureMessage}</p>
        </div>
    )
}

export default withRouter(StripeIndiaPaymentPanel)