import React from 'react';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import classNames from 'classnames';
import { FormattedMessage, injectIntl } from '../../util/reactIntl';
import * as validators from '../../util/validators';
import {
  FieldAntDatePickerInput, FieldCheckbox,
  FieldTextInput,
  Form,
  PrimaryButton,
} from '../../components';

import css from './EditListingCertificationForm.module.css';

import { composeValidators } from "../../util/validators";
import {fakeIntl} from "../../util/test-data";



const ContentCertificationComponent = props => {
  const { onClose, intl, onSubmit, ...restOfProps } = props;

  // title
  const addCertification = intl.formatMessage({
    id: 'EditListingCertificationPanel.addCertification',
  });

  // certification
  const certificationLabel = intl.formatMessage({
    id: 'EditListingCertificationPanel.certificationLabel',
  });
  const certificationPlaceholder = intl.formatMessage({
    id: 'EditListingCertificationPanel.certificationPlaceholder',
  });
  const certificationRequired = intl.formatMessage({
    id: 'EditListingCertificationPanel.certificationRequired',
  });

  // organisation
  const organisationLabel = intl.formatMessage({
    id: 'EditListingCertificationPanel.organisationLabel',
  });
  const organisationPlaceholder = intl.formatMessage({
    id: 'EditListingCertificationPanel.organisationPlaceholder',
  });
  const organisationRequired = intl.formatMessage({
    id: 'EditListingCertificationPanel.organisationRequired',
  });

  // startDate endDate
  const startDate = intl.formatMessage({
    id: 'EditListingCertificationPanel.startDate',
  });
  const endDate = intl.formatMessage({
    id: 'EditListingCertificationPanel.endDate',
  });
  const datePlaceholder = fakeIntl.formatDateRevers(new Date())

  // checkbox

  const labelCheckbox = intl.formatMessage({ id: 'EditListingCertificationPanel.checkboxNotExpire' })

  // description

  const certificationURLLabel = intl.formatMessage({
    id: 'EditListingCertificationPanel.certificationURLLabel',
  });
  const certificationURLPlaceholder = intl.formatMessage({
    id: 'EditListingCertificationPanel.certificationURLPlaceholder',
  });

  const submit = values => onSubmit({ values: values })

  const handleDelete = values => {
    if(values.number) onSubmit({ type: values.number, values: values })
    onClose()
  }

  return (
    <FinalForm
      {...restOfProps}
      onSubmit={submit}
      mutators={{
        ...arrayMutators,
      }}
      render={fieldRenderProps => {
        const {
          rootClassName,
          className,
          formId,
          form,
          disabled,
          invalid,
          handleSubmit,
          inProgress,
          values,
        } = fieldRenderProps;

        const classes = classNames(rootClassName || css.root, className);
        const submitInProgress = inProgress;
        const notExpire = !!(values.notExpire && values.notExpire.length)
        const valueEndDate = values.endDate
        const valueStartDate = values.startDate

        const submitDisabled = invalid || disabled || submitInProgress || !valueStartDate


        const minAgeRequired = validators.dateComparison('Invalid date', valueEndDate);
        const dateComparison = validators.dateComparison('Invalid date');
        const reversComparison = validators.dateComparison('Invalid date', valueStartDate, true);
        const validStartDate =  notExpire || !valueEndDate
          ? composeValidators(dateComparison)
          : composeValidators(minAgeRequired, dateComparison)
        const validEndDate =  notExpire || !valueStartDate
          ? composeValidators(dateComparison)
          : composeValidators(dateComparison, reversComparison)

        return (
          <Form id={formId} className={classes} onSubmit={ e => {
            handleSubmit(e)
            form.restart()
          }}>
            <h2 className={css.titleModal}>{addCertification}</h2>
            <FieldTextInput
              id="certification"
              name="certification"
              className={css.description}
              type="text"
              label={certificationLabel}
              placeholder={certificationPlaceholder}
              validate={validators.required(certificationRequired)}
            />

            <FieldTextInput
              id="organisation"
              name="organisation"
              className={css.description}
              type="text"
              label={organisationLabel}
              placeholder={organisationPlaceholder}
              validate={validators.required(organisationRequired)}
            />

            <div className={css.dateContainer}>
              <FieldAntDatePickerInput
                className={css.inputDate}
                id="startDate"
                name="startDate"
                label={startDate}
                form={form}
                placeholder={datePlaceholder}
                validate={validStartDate}
              />
              {notExpire ?
              <FieldAntDatePickerInput
                className={css.disabledInputDate}
                id="disabled"
                name="disabled"
                label={endDate}
                form={form}
                placeholder={datePlaceholder}
              /> :
                <FieldAntDatePickerInput
                  className={css.inputDate}
                  id="endDate"
                  name="endDate"
                  label={endDate}
                  form={form}
                  placeholder={datePlaceholder}
                  validate={validEndDate}
                />
              }
            <div className={css.containerCheckBox}>
              <FieldCheckbox
                id='notExpire'
                name='notExpire'
                label={labelCheckbox}
                value='work'
              />
            </div>
            </div>

            <FieldTextInput
              id="certificationURL"
              name="certificationURL"
              className={css.description}
              type="textarea"
              label={certificationURLLabel}
              placeholder={certificationURLPlaceholder}
            />

            <div className={css.buttonSection}>
              <PrimaryButton className={css.buttonDelete} onClick={()=> handleDelete(values)} type="button">
                <FormattedMessage id="EditListingWorkExperienceFormComponent.deleteSchedule" />
              </PrimaryButton>

              <PrimaryButton className={css.buttonSave} type="submit" disabled={submitDisabled}>
                <FormattedMessage id="EditListingWorkExperienceFormComponent.saveSchedule" />
              </PrimaryButton>
            </div>
          </Form>
        );
      }}
    />
  );
};

const ContentCertification = compose(injectIntl)(
  ContentCertificationComponent
);


export default ContentCertification;
