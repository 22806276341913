

//Action types

import { isScrollingDisabled } from "../../ducks/UI.duck";
import {  sendEmailUsingZepto } from "../../util/api";
import { storableError } from "../../util/errors";

export const SEND_CONTACT_EMAIL_REQUEST = 'app/FirmPage/SEND_CONTACT_EMAIL_REQUEST';
export const SEND_CONTACT_EMAIL_SUCCESS = 'app/FirmPage/SEND_CONTACT_EMAIL_SUCCESS';
export const SEND_CONTACT_EMAIL_ERROR = 'app/FirmPage/SEND_CONTACT_EMAIL_ERROR';

//InitialState
const initialState = {
    contactUsEmailSent: false,
    sendContactEmailInProgress: false,
    sendContactEmailError: null,
};

//Selectors

export const checkScrollingDisabled = state => isScrollingDisabled(state);

//reducer function
export default function reducer(state = initialState, action = {}) {
    const { type, payload } = action;
    switch (type) {
      case SEND_CONTACT_EMAIL_REQUEST:
      return { ...state, sendContactEmailInProgress: true, sendContactEmailError: null };
      case SEND_CONTACT_EMAIL_SUCCESS:
        return { ...state,contactUsEmailSent:true, sendContactEmailInProgress: false, sendContactEmailError: null };
      case SEND_CONTACT_EMAIL_ERROR:
        return { ...state, sendContactEmailInProgress: false, sendContactEmailError: payload };
     
      default:
        return state;
    }
  }
  

  // ================ Action creators ================ //

export const sendContactEmailRequest = () => ({
    type: SEND_CONTACT_EMAIL_REQUEST,
  });
  export const sendContactEmailSuccess = () => ({
    type: SEND_CONTACT_EMAIL_SUCCESS,
  });
  
  export const sendContactEmailError = e => ({
    type: SEND_CONTACT_EMAIL_ERROR,
    error: true,
    payload: e,
  });


// ================ Thunk ================ //

export const contactUsEmail = (params) => async (dispatch,getState,sdk) =>{
    dispatch(sendContactEmailRequest());
    try {
        const response = await sendEmailUsingZepto(params);
        if(response.message === 'Success') {
          dispatch(sendContactEmailSuccess());
        }
    } catch (error) {
        dispatch(sendContactEmailError(storableError(error)));
    }
}

//LoadData
// export const loadData = params => async (dispatch, getState, sdk) => {
//     try {
//       const currentUser = getState().user?.currentUser;
//       return Promise.all([dispatch(fetchCurrentUser())]);
//     } catch (e) {
//       console.log(e);
//       dispatch(showListingError(e));
//     }
//   };