// These helpers are calling FTW's own server-side routes
// so, they are not directly calling Marketplace API or Integration API.
// You can find these api endpoints from 'server/api/...' directory

import { types as sdkTypes, transit } from './sdkLoader';
import config from '../config';
import Decimal from 'decimal.js';
import axios from 'axios';

export const apiBaseUrl = () => {
  const port = process.env.REACT_APP_DEV_API_SERVER_PORT;
  const useDevApiServer = process.env.NODE_ENV === 'development' && !!port;

  // In development, the dev API server is running in a different port
  if (useDevApiServer) {
    return `http://localhost:${port}`;
  }

  // Otherwise, use the same domain and port as the frontend
  return typeof window === 'undefined' ? ' ' : `${window.location.origin}`;
};

// Application type handlers for JS SDK.
//
// NOTE: keep in sync with `typeHandlers` in `server/api-util/sdk.js`
export const typeHandlers = [
  // Use Decimal type instead of SDK's BigDecimal.
  {
    type: sdkTypes.BigDecimal,
    customType: Decimal,
    writer: v => new sdkTypes.BigDecimal(v.toString()),
    reader: v => new Decimal(v.value),
  },
];

const serialize = data => {
  return transit.write(data, { typeHandlers, verbose: config.sdk.transitVerbose });
};

const deserialize = str => {
  return transit.read(str, { typeHandlers });
};

const post = (path, body) => {
  const url = `${apiBaseUrl()}${path}`;
  const options = {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/transit+json',
    },
    body: serialize(body),
  };
  return typeof window === 'undefined'
    ? ' '
    : window.fetch(url, options).then(res => {
      const contentTypeHeader = res.headers.get('Content-Type');
      const contentType = contentTypeHeader ? contentTypeHeader.split(';')[0] : null;

      if (res.status >= 400) {
        return res.json().then(data => {
          let e = new Error();
          e = Object.assign(e, data);

          throw e;
        });
      }
      if (contentType === 'application/transit+json') {
        return res.text().then(deserialize);
      } else if (contentType === 'application/json') {
        return res.json();
      }
      return res.text();
    });
};

const get = (path, body) => {
  const url = `${apiBaseUrl()}${path}`;
  const options = {
    method: 'GET',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/transit+json',
    },
  };
  return typeof window === 'undefined'
    ? ' '
    : window.fetch(url, options).then(res => {
      const contentTypeHeader = res.headers.get('Content-Type');
      const contentType = contentTypeHeader ? contentTypeHeader.split(';')[0] : null;

      if (res.status >= 400) {
        return res.json().then(data => {
          let e = new Error();
          e = Object.assign(e, data);

          throw e;
        });
      }
      if (contentType === 'application/transit+json') {
        return res.text().then(deserialize);
      } else if (contentType === 'application/json') {
        return res.json();
      }
      return res.text();
    });
};

// Fetch transaction line items from the local API endpoint.
//
// See `server/api/transaction-line-items.js` to see what data should
// be sent in the body.
export const transactionLineItems = body => {
  return post('/api/transaction-line-items', body);
};

// Initiate a privileged transaction.
//
// With privileged transitions, the transactions need to be created
// from the backend. This endpoint enables sending the booking data to
// the local backend, and passing that to the Marketplace API.
//
// See `server/api/initiate-privileged.js` to see what data should be
// sent in the body.
export const initiatePrivileged = body => {
  return post('/api/initiate-privileged', body);
};
export const updateMetadata = body => {
  return post('/api/updateMetadata', body);
};

// Transition a transaction with a privileged transition.
//
// This is similar to the `initiatePrivileged` above. It will use the
// backend for the transition. The backend endpoint will add the
// payment line items to the transition params.
//
// See `server/api/transition-privileged.js` to see what data should
// be sent in the body.
export const transitionPrivileged = body => {
  return post('/api/transition-privileged', body);
};

// Create user with identity provider (e.g. Facebook or Google)
//
// If loginWithIdp api call fails and user can't authenticate to Flex with idp
// we will show option to create a new user with idp.
// For that user needs to confirm data fetched from the idp.
// After the confirmation, this endpoint is called to create a new user with confirmed data.
//
// See `server/api/auth/createUserWithIdp.js` to see what data should
// be sent in the body.
export const createUserWithIdp = body => {
  return post('/api/auth/create-user-with-idp', body);
};

export const makeRequestToCustomEndpoint = async (method, url, data) => {
  const options = {
    baseURL: apiBaseUrl(),
    method: method,
    url: url,
    data,
    headers: {
      'Content-Type': 'application/transit+json',
    },
  };
  let res = await axios(options);
  try {
    return res;
  } catch (e) {
    return new Error(e);
  }
};

export const updateMetadataRequest = body => {
  return post(`/api/transactions/update-metadata`, body);
};
export const updateMetadataThroughIsdk = body => {
  return post(`/api/update-metadata-through-isdk`, body);
};
export const automateBirthday = body => {
  return post(`/api/automated-birthday`, body);
};
export const getAccessToken = body => {
  return post(`/api/authenticate-google`, body);
};
export const scheduleGoogleMeeting = body => {
  return post(`/api/schedule-google-meeting`, body);
};
export const getRefreshToken = body => {
  return post(`/api/get-refresh-token`, body);
};
export const getAllTransactions = body => {
  return post(`/api/fetch-transaction`, body);
};

export const updateListingMetadata = body => {
  return post(`/api/update-listing-metadata`, body);
};
export const fetchFirmMember = body => {
  return post(`/api/fetch-firm-member`, body);
};
export const updatedFirmMemberProfile = body => {
  return post(`/api/update-firm-member-profile`, body);
};
export const deleteFirmMemberData = body => {
  return post(`/api/delete-firm-member-data`, body);
};
export const updateFirmAuthorProfile = body => {
  return post(`/api/update-firm-author-profile`, body);
};
export const getTransaction = body => {
  return post(`/api/get-transaction`, body);
};
export const updateUserProfile = body => {
  return post(`/api/update-user-profile`, body);
};

export const createZoomToken = body => {
  return post('/api/createZoomToken', body);
};

export const createMeeting = body => {
  return post('/api/createMeeting', body);
};

export const getZakCode = body => {
  return post('/api/getZakCode', body);
};

export const createUserSignature = body => {
  return post('/api/createUserSignature', body);
};
export const updateMilestone = body => {
  return post(`/api/update-milestone`, body);
};
export const completeProposalThroughIsdk = body => {
  return post(`/api/complete-proposal`, body);
};
export const showAndUpdateCollaboratorProfile = body => {
  return post(`/api/show-and-update-collaborator-profile`, body);
};

export const getPriceFromStripe = body => {
  return post(`/api/stripe-getPrice`, body);
};
export const getPriceFromMongo = body => {
  return post(`/api/stripe-price-mongo`, body);
};
export const createSubscriptionWithStripe = body => {
  return post(`/api/stripe-create-subscription`, body);
};
export const createStripeSubscriptionOffline = body => {
  return post(`/api/stripe-create-subscription-offline`, body);
};
export const updateSubscriptionWithStripe = body => {
  return post(`/api/stripe-update-subscription`, body);
};
export const getSubscriptionsFromStripe = body => {
  return post(`/api/stripe-get-user-subscriptions`, body);
};
export const getSubscriptionStatusFromStripe = body => {
  return post(`/api/stripe-get-user-subscriptions-status`, body);
};
export const stripeCustomerPortal = body => {
  return post(`/api/stripe-customer-portal`, body);
};

export const getUserData = body => {
  return post(`/api/get-user-data`, body);
};
export const updateListingPublicData = body => {
  return post(`/api/update-listing-publicdata`, body);
};
export const closeUserListing = body => {
  return post(`/api/close-user-listing`, body);
};
export const openUserListing = body => {
  return post(`/api/open-user-listing`, body);
};
export const getZohoAccessToken = body => {
  return post(`/api/getZohoAccessToken`, body);
};
export const sendDocument = body => {
  return post(`/api/sendDocument`, body);
};
export const fetchDocument = body => {
  return post(`/api/fetchDocument`, body);
};
export const sendReminder = body => {
  return post(`/api/sendReminder`, body);
};
export const createDocument = body => {
  return post(`/api/createDocument`, body);
};
export const storeKeyword = body => {
  return post(`/api/create-suggestion`, body);
};
export const getKeyword = body => {
  return post(`/api/get-suggestion`, body);
};
export const fetchTemplate = body => {
  return post(`/api/fetchTemplate`, body);
};
export const deleteTemplate = body => {
  return post(`/api/deleteTemplate`, body);
};
export const stripeOnboardIndianUser = body => {
  return post(`/api/stripe-onBoard-user-India`, body);
};
export const stripeRetrieveIndianUser = body => {
  return post(`/api/stripe-retrieve-user-India`, body);
};
export const stripeCreateAccountLinkIn = body => {
  return post(`/api/stripe-create-account-link-India`, body);
};
export const stripeCreateChargeIndia = body => {
  return post(`/api/stripe-create-charge-India`, body);
};
export const stripeRetrievePaymentIntentIndia = body => {
  return post(`/api/stripe-retrieve-payment-intent-India`, body);
};
export const storeFirmDetailsinMongo = body => {
  return post(`/api/store-firm-details`, body);
};
export const updateFirmUserDetails = body => {
  return post(`/api/update-firm-user-details`, body);
};
export const updateRecruitment = body => {
  return post(`/api/update-recruitment`, body);
};
export const deleteRecruitment = body => {
  return post(`/api/delete-recruitment`, body);
};
export const updateTransactionId = body => {
  return post(`/api/update-transaction-id`, body);
};
export const getFirmUserDetails = body => {
  return post(`/api/get-firm-user-details`, body);
};
export const deleteFirmUserDetails = body => {
  return post(`/api/delete-firm-user-details`, body);
};
export const deleteFirm = body => {
  return post(`/api/delete-firm`, body);
};
export const createAffiliatedFirm = body => {
  return post(`/api/create-affiliated-firm`, body);
};
export const getAffiliatedFirm = body => {
  return post(`/api/get-affiliated-firm`, body);
};
export const getFirmBasedOnIds = body => {
  return post(`/api/get-own-firm`, body);
};
export const updateAffiliatedFirm = body => {
  return post(`/api/update-affiliated-firm`, body);
};
export const deleteAffiliatedFirm = body => {
  return post(`/api/delete-affiliated-firm`, body);
};
export const addTag = body => {
  return post(`/api/add-tag`, body);
};
export const fetchTag = body => {
  return post(`/api/fetch-tag`, body);
};
export const createImageUsingOpenAI = body => {
  return post(`/api/create-image-using-open-ai`, body);
};
export const getEmbeddingsFromOpenAI = body => {
  return post(`/api/get-embeddings-opneai`, body);
};
export const getDocumentData = body => {
  return post(`/api/get-parsed-document`, body);
};
export const createHashValueForTawkTo = body => {
  return post(`/api/create-hash-tawk-to`, body);
};
export const handleExpertDetails = body => {
  return post(`/api/update-expert-details`, body);
};
export const getExpertDetails = body => {
  return post(`/api/fetch-expert-details`, body);
};
export const createFolder = body => {
  return post(`/api/create-box-folder`, body);
};
export const getBoxFolderInfo = body => {
  return post(`/api/get-box-folder`, body);
};
export const createBoxCollaborator = body => {
  return post(`/api/create-box-collaborator`, body);
};
export const discourseLogin = body => {
  return post(`/api/discourse-sso-login`, body);
};
export const getDiscourseUser = body => {
  return post(`/api/get-discourse-sso-user/${body}`);
};
export const generateAppDataFromOpenAI = body => {
  return post(`/api/openai-apps-generator`, body);
};
export const questionHeadingGenerator = body => {
  return post(`/api/question-heading-generator`, body);
};

export const storeOpenAIData = body => {
  return post(`/api/store-openai-data`, body);
};
export const updateOpenAIData = body => {
  return post(`/api/update-openai-data`, body);
};
export const showUser = body => {
  return post(`/api/show-user`, body);
};
export const generateResume = body => {
  return post(`/api/generate-resume`, body);
};

export const isdkTransactionsTransition = body => {
  return post(`/api/isdk-transactions-transition`, body);
};
export const fetchUserListings = body => {
  return post(`/api/fetch-user-listings`, body);
};

export const removeFileFromAzure = body => {
  return post(`/api/remove-file-aws`, body);
};
export const cancelStripeSubscription = body => {
  return post(`/api/cancel-stripe-subscription`, body);
};
export const sendEmailUsingZepto = body => {
  return post(`/api/emails/send-email-using-zepto`, body);
};
export const searchLanguage = body => {
  return get(`/api/search-language?q=${body}`);
};
export const createLanguage = body => {
  return post(`/api/create-language`, body);
};
export const handleFileUploadToBox = body => {
  return post(`/api/upload-file-boxFolder`, body);
};
export const getFirmUserExperts = body => {
  return post(`/api/get-firm-user-experts`, body);
};
export const storeNotification = body => {
  return post(`/api/store-notification`, body);
};
export const getAndDeleteNotifications = body => {
  return get(`/api/get-notification?userId=${body}`);
};
export const deleteNotificationFromDB = (body) => {
  return post(`/api/mark-as-read-notification`, body);
};


export const handleFileUpload = async (route, data) => {
  try {
    const url = apiBaseUrl() + `/api/${route}`;
    const formData = new FormData();

    for (let key in data) {
      if (key === 'files') {
        for (const file of data[key]) {
          formData.append('files', file);
        }
      } else formData.append(key, data[key]);
    }

    const response = await axios.post(url, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });

    if (response.status === 200 && response?.data) return response;
  } catch (error) {
    return error;
  }
};

export const fetchQueryReportData = body => {
  return post(`/api/fetch-query-data`, body);
};
export const handleWeavaiteDatabase = body => {
  return post(`/api/handle-weavaite-database`, body);
};
export const queryExpertFromWeaviate = body => {
  return post(`/api/query-expert-from-weaviate`, body);
};
export const decryptStripeConnectAccountID = body => {
  return post(`/api/decrypt-sca-id`, body);
};
export const fetchDataFromURL = body => {
  return post(`/api/get-data-from-url`, body);
};
export const storeSoftwareInsights = body => {
  return post(`/api/store-software-insight`, body);
};
export const getSoftwareInsight = body => {
  const { value, category } = body;
  return get(`/api/get-software-insight?searchTerm=${value}&category=${category}`);
};
export const fetchURLPreviews = body => {
  return get(`/api/get-previews?url=${body}`);
};
export const createAppListing = body => {
  return post(`/api/create-app-listing`, body);
}
export const sendOpenaiResponseOnEmail = body => {
  return post(`/api/send-openai-response`, body);
};
export const createGigAppBoxFolder = body => {
  return post(`/api/create-gig-app-box-folder`, body);
};
export const getGigAppBoxFiles = body => {
 const { rootFolderId } = body;
  return post(`/api/get-gig-app-box-files?rootFolderId=${rootFolderId}`);
};

export const getAppTransaction = body => {
  const { txId } = body;
  return get(`/api/get-app-transaction?txId=${txId}`);
};

export const addGigAppSession = body => {
  return post(`/api/add-gigapp-session`, body);
}

export const storeContractSession = body => {
  return post(`/api/store-contract-session`, body);
}

export const updateSubscriptionData = body => {
  return post(`/api/update-subscription-data`, body);
}

export const fetchSubscriptionData = body => {
  return post(`/api/fetch-subscription-data`, body);
}

export const questionnaireGenerator = body => {
  return post('/api/questionnaireGenerator',body);
}

export const generateQuestionPills = body => {
  return post('/api/generate-convo-pill', body);
}

export const getFolderAnalyis = body => {
  return post('/api/get-folder-analysis', body);
}

export const  generateExpansion = body => {
  return post('/api/generate-expansion', body);
}

export const  generateChronology = body => {
  return post('/api/generate-chronology', body);
}

export const deleteThreadMessages = body => {
  return post('/api/deleteThreadMessages',body)
}

export const fetchGigAppUsageData = body =>{
  return post('/api/fetch-gigapp-usage-data',body);
}

export const generateNewThread = body =>{
  return post('/api/generate-new-thread',body);
}

export const stopThreadRun = body =>{
  return post('/api/stop-thread-run',body);
}

export const extendQueryLinkExpiry = body =>{
  return post('/api/extend-query-link-expiry',body);
}

export const generateTopicWiseConsolidation = body =>{
  return post('/api/topic-wise-consolidation-generator',body);
}

export const generateTranscriptDiscussionGuide = body =>{
  return post('/api/transcript-discussion-guide-generator',body);
}
