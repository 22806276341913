import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Form, Field } from 'react-final-form';
import axios from 'axios';
import css from './SendMessageForm.module.css';
import sad from '../../assets/gifs/sad.gif';
import funny from '../../assets/gifs/funny.gif';
import trending from '../../assets/gifs/trending.gif';
import cute from '../../assets/gifs/cute.gif';
import angry from '../../assets/gifs/angry.gif';
import cry from '../../assets/gifs/cry.gif';
import sorry from '../../assets/gifs/kiss-love.gif';
import kiss from '../../assets/gifs/kiss.gif';
import ok from '../../assets/gifs/ok.gif';
import oops from '../../assets/gifs/oops.gif';
import sleepy from '../../assets/gifs/sleepy.gif';
import stressed from '../../assets/gifs/stressed.gif';
import thanks from '../../assets/gifs/thanks.gif';
import whatever from '../../assets/gifs/whatever.gif';
import IconSearch from '../../components/IconSearch/IconSearch';
import { IconCard, OutsideClickHandler } from '../../components';
import { CLOSEICON } from '../../components/IconCard/IconCard';

const GifSearch = (props) => {
  const { handleGif ,onCloseGifModal} = props;
  const [gifs, setGifs] = useState([]);
  const [selectedGif, setSelectedGif] = useState(null);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [isCategorySelected, setIsCategorySelected] = useState(false);
  const tenorAPI = process.env.REACT_APP_GIF_TENOR_API_KEY;

  const observer = useRef();
  const lastGifRef = useCallback(
    (node) => {
      if (isLoading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          setPage((prevPage) => prevPage + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [isLoading]
  );

  const debounce = (func, delay) => {
    let timerId;
    return (...args) => {
      clearTimeout(timerId);
      timerId = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };
  

  const handleSearch = async (searchTerm) => {
    if (searchTerm === selectedCategory) return;
  
    try {
      const apikey = tenorAPI;
      const clientkey = 'my_test_app';
      const lmt = 10;
      const pos = page * lmt;
  
      const searchUrl = `https://tenor.googleapis.com/v2/search?q=${searchTerm}&key=${apikey}&client_key=${clientkey}&limit=${lmt}&pos=${pos}`;
  
      setIsLoading(true);
      const response = await axios.get(searchUrl);
      const { results } = response.data;
  
      if (results && results.length > 0) {
        const filteredGifs = results.filter(
          (gif) =>
            gif.media_formats && gif.media_formats.gif && gif.media_formats.gif.url
        );
        setGifs(filteredGifs);
      } else {
        setGifs([]);
      }
  
      setSelectedCategory(searchTerm);
      setIsCategorySelected(true);
    } catch (error) {
    //   console.log('Error fetching GIFs:', error);
    } finally {
      setIsLoading(false);
    }
  };
  
  const debouncedSearch = useCallback(debounce(handleSearch, 300), []);

  const handleSelect = (gif) => {
    setSelectedGif(gif);
    handleGif(gif.media_formats.gif.url);
  };

  const handleSubmit = (values) => {
    // Handle form submission with selectedGif
    // console.log('Selected GIF:', selectedGif);
    // console.log('Form values:', values);
  };

  const handleClear = (form) => {
    setSelectedCategory('');
    setIsCategorySelected(false);
    setGifs([]);
    form.change('searchTerm', ''); // Clear the value of 'searchTerm' field
  };

  let categories = [
    { name: 'Trending', image: trending },
    { name: 'Funny', image: funny },
    { name: 'Cute', image: cute },
    { name: 'Sad', image: sad },
    { name: 'Angry', image: angry },
    { name: 'Cry', image: cry },
    { name: 'Sorry', image: sorry },
    { name: 'Kiss', image: kiss },
    { name: 'Ok', image: ok },
    { name: 'oops', image: oops },
    { name: 'Sleepy', image: sleepy },
    { name: 'Stressed', image: stressed },
    { name: 'Thanks', image: thanks },
    { name: 'Whatever', image: whatever },
  ];

  useEffect(() => {
    const handleScroll = () => {
      if (
        window.innerHeight + window.scrollY >= document.body.offsetHeight &&
        !isLoading
      ) {
        setPage((prevPage) => prevPage + 1);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isLoading]);

  useEffect(() => {
    const loadMoreGifs = async () => {
      try {
        setIsLoading(true);
        const apikey = tenorAPI;
        const clientkey = 'my_test_app';
        const lmt = 10;
        const pos = page * lmt;

        const categoryIndex = page - 1;
        if (categoryIndex >= 0 && categoryIndex < categories.length) {
          const searchUrl = `https://tenor.googleapis.com/v2/search?q=${categories[categoryIndex].name}&key=${apikey}&client_key=${clientkey}&limit=${lmt}&pos=${pos}`;

          const response = await axios.get(searchUrl);
          const { results } = response.data;

          if (results && results.length > 0) {
            const filteredGifs = results.filter(
              (gif) =>
                gif.media_formats && gif.media_formats.gif && gif.media_formats.gif.url
            );
            setGifs((prevGifs) => [...prevGifs, ...filteredGifs]);
          }
        }
      } catch (error) {
        // console.log('Error fetching GIFs:', error);
      } finally {
        setIsLoading(false);
      }
    };

    if (page > 1) {
      loadMoreGifs();
    }
  }, [page, categories?.length]);

  return (
    <div>
      <Form
        onSubmit={handleSubmit}
        render={({ handleSubmit ,form}) => (
          <form onSubmit={handleSubmit}>
                    <OutsideClickHandler onOutsideClick={onCloseGifModal}>
            <div className={css.gifWrapper}>
              <div className={css.searchBar}>
                <IconSearch />
                <Field
                  name="searchTerm"
                  render={({ input }) => (
                    <div className={css.searchInputWrapper}>
                          <input
                              {...input}
                              type="text"
                              placeholder="Search for a GIF"
                              value={isCategorySelected ? selectedCategory : input.value}
                              onChange={(event) => {
                                  input.onChange(event);
                                  setSelectedCategory('');
                                  setIsCategorySelected(false);
                                  debouncedSearch(event.target.value);
                              }}
                          />
                      
                        <button
                          type="button"
                          className={css.clearButton}
                          onClick={()=>handleClear(form)}
                        >
                      <IconCard brand={CLOSEICON}/>
                        </button>
                    </div>
                  )}
                />
              </div>
              <div className={css.gifLayout}>
                {gifs.length === 0 ? (
                  <div className={css.categories}>
                    <div className={css.categoriesList}>
                      {categories.map((category, index) => (
                        <button
                          key={category.name}
                          className={css.categoriesDummy}
                          type="button"
                          onClick={() => {
                            setSelectedCategory(category.name);
                            setIsCategorySelected(true);
                            setPage(1);
                            handleSearch(category.name);
                          }}
                        >
                          <div className={css.shadowText}>
                            <div className={css.catName}>{category.name}</div>
                          </div>
                          <img src={category.image} alt={category.name} />
                        </button>
                      ))}
                    </div>
                  </div>
                ) : (

                  <div className={css.gifResults}>
                    {gifs.map((gif, index) => {
                        if (gifs.length === index + 1) {
                            return (
                                <div
                                key={gif.id}
                                onClick={() => handleSelect(gif)}
                                className={css.gifList}
                                ref={lastGifRef}
                                >
                            <img
                              src={gif.media_formats.gif.url}
                              alt={gif.title}
                              />
                          </div>
                        );
                    } else {
                        return (
                            <div
                            key={gif.id}
                            onClick={() => handleSelect(gif)}
                            className={css.gifList}
                            >
                            <img
                              src={gif.media_formats.gif.url}
                              alt={gif.title}
                              />
                          </div>
                        );
                    }
                })}
                  </div>
                )}
              </div>
            </div>
                </OutsideClickHandler>
          </form>
        )}
      />
    </div>
  );
};

export default GifSearch;
