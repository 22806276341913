import React from 'react';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import classNames from 'classnames';
import { FormattedMessage, injectIntl } from '../../util/reactIntl';
import * as validators from '../../util/validators';
import { FieldSelect, Form, NamedLink, PrimaryButton } from '../../components';

import css from './ModalSendBriefForm.module.css';
import { ROLE } from '../../components/EditListingBriefWizard/EditListingBriefWizardTab';
import { draftId, draftSlug } from '../../routeConfiguration';

const ModalSendBriefFormComponent = props => {
  const { onClose, intl, onSubmit, options, currentUser, isJobListing, ...restOfProps } = props;

  const titleModalBookingInListing = intl.formatMessage({
    id: isJobListing
      ? 'BookingPanel.titleModalBookingJob'
      : 'BookingPanel.titleModalBookingInListing',
  });

  const labelListingSelect = intl.formatMessage({
    id: isJobListing ? 'BookingPanel.labelJobSelect' : 'BookingPanel.labelListingSelect',
  });
  const listingSelectPlaceholder = intl.formatMessage({
    id: isJobListing
      ? 'BookingPanel.jobSelectPlaceholder'
      : 'BookingPanel.listingSelectPlaceholder',
  });

  const exceedingPlanLimitErrorMessage = intl.formatMessage({
    id: 'BookingPanel.exceedingPlanLimitErrorMessage',
  });

  const handleSubmit = values => {
    const listing = options.find(l => l.id.uuid === values.briefId);
    onSubmit({
      values,
      listing,
    });
  };

  return (
    <FinalForm
      {...restOfProps}
      onSubmit={handleSubmit}
      mutators={{
        ...arrayMutators,
      }}
      render={fieldRenderProps => {
        const {
          rootClassName,
          className,
          formId,
          form,
          disabled,
          invalid,
          handleSubmit,
          inProgress,
        } = fieldRenderProps;

        const classes = classNames(rootClassName || css.root, className);
        const editListingParam = isJobListing
          ? { slug: draftSlug, id: draftId, type: 'new', tab: ROLE }
          : {};
        const submitDisabled = invalid || disabled || inProgress;
              

        return (
          <Form
            id={formId}
            className={classes}
            onSubmit={e => {
              handleSubmit(e);
              form.restart();
            }}
          >
            <h2 className={css.titleModal}>{titleModalBookingInListing}</h2>
            <FieldSelect
              className={className}
              name="briefId"
              id="briefId"
              disabled={!!options && !options.length}
              label={labelListingSelect}
              validate={validators.required(listingSelectPlaceholder)}
            >
              <option disabled value="">
                {listingSelectPlaceholder}
              </option>
              {options &&
                options.length &&
                options.map(el => (
                  <option key={el.id.uuid} value={el.id.uuid}>
                    {el.attributes.title}
                  </option>
                ))}
            </FieldSelect>

            <NamedLink
              name={isJobListing ? 'EditJobListingPage' : 'NewListingPage'}
              params={editListingParam}
              className={classNames({
                [css.noBrief]: options && options.length,
                [css.addBrief]: !options,
              })}
            >
              <p className={css.textRedirectToCreateBrief}>
                {isJobListing ? (
                  <FormattedMessage id="BookingPanel.maybeCreateJobDescription" />
                ) : (
                  <FormattedMessage id="BookingPanel.maybeCreateBrief" />
                )}
              </p>
            </NamedLink>

            <div className={css.buttonSection}>
              <p className={css.buttonCancel} onClick={onClose}>
                <FormattedMessage id="SavedCardDetails.cancel" />
              </p>
              <PrimaryButton
                className={css.buttonSubmit}
                type="submit"
                inProgress={inProgress}
                disabled={submitDisabled}
              >
                <FormattedMessage id="BookingPanel.buttonSubmit" />
              </PrimaryButton>
            </div>
          </Form>
        );
      }}
    />
  );
};

const ModalSendBriefForm = injectIntl(ModalSendBriefFormComponent);

export default ModalSendBriefForm;
