import { INDUSTRY_CATEGORY, INSIGHT_TECHNIQUES_OR_MODLES_CATEGORY, SERVICE_CATEGORY, SOFTWARE_CATEGORY } from "../../util/types";

export const resetFormFields = (form) => {
  form.batch(() => {
    // form.change('software', null);
    // form.change('insightTechniquesOrModels', null);
    form.change('serviceSearchKeywordField', null);
    form.change('industrySearchKeyword', null);
    form.change('softwareSearchKeyword', null);
    form.change('technologySearchKeywordField', null);
  });
}
export const toggleDropdown = (setIsDropdownOpen) => {
  setIsDropdownOpen(prevIsDropdownOpen => !prevIsDropdownOpen);
};

export const closeDropdown = (setIsDropdownOpen) => {
  setIsDropdownOpen(false);
};

export const handleRemoveOption = (params) => {
  const { item, form, category } = params;
  const field = {
    [SERVICE_CATEGORY]: SERVICE_CATEGORY,
    [SOFTWARE_CATEGORY]: SOFTWARE_CATEGORY,
    [INDUSTRY_CATEGORY]: INDUSTRY_CATEGORY,
    [INSIGHT_TECHNIQUES_OR_MODLES_CATEGORY]: INSIGHT_TECHNIQUES_OR_MODLES_CATEGORY,
  }[category]; // Map category to field using a concise object lookup


  // Access form state directly using optional chaining for safety
  const currentData = form.getState().values?.[field] || [];

  // Filter data concisely using array destructuring
  const [...updatedData] = currentData.filter((data) => data.key !== item.key);

  // Update form field directly
  form.change(field, updatedData);
};



//Handle clear 
export const handleClearClick = (params) => {
  const {
    setIsUlVisible,
    setIsDropdownOpen,
    form
  } = params;
  resetFormFields(form);
  setIsUlVisible(false);
  setIsDropdownOpen(false);
};


export const handleSelectChanges = (params) => {
  const { item, form, setIsUlVisible, setIsDropdownOpen, category } = params;
  const selectedField = { key: item.toLowerCase(), label: item };

  const field = {
    [SERVICE_CATEGORY]: SERVICE_CATEGORY,
    [SOFTWARE_CATEGORY]: SOFTWARE_CATEGORY,
    [INDUSTRY_CATEGORY]: INDUSTRY_CATEGORY,
    [INSIGHT_TECHNIQUES_OR_MODLES_CATEGORY]: INSIGHT_TECHNIQUES_OR_MODLES_CATEGORY,
  }[category]; // Map category to field using a concise object lookup

  form.batch(() => {
    try {
      // Prevent duplicates
      const currentData = form.getState().values?.[field] || [];
      if (!currentData.some((data) => data.key === selectedField.key)) {
        form.change(field, [...currentData, selectedField]);
      }

      // Clear search fields and reset form
      form.change('searchResults', null);
      form.change('searchKeyword', null);
      resetFormFields(form);

      // Update visibility flags
      setIsUlVisible(false);
      setIsDropdownOpen(false);
    } catch (error) {
      // Handle errors gracefully, e.g., display a user-friendly message
    }
  });
};

export const handleKeyPress = async (params) => {
  const { e, form, setIsUlVisible, setIsDropdownOpen, category } = params;
  const { value } = e.target;
  if (e.key === 'Enter' && value.trim() !== '') {
    const selectedValue = {
      key: value.toLowerCase(),
      label: value
    };
    const field = {
      [SERVICE_CATEGORY]: SERVICE_CATEGORY,
      [SOFTWARE_CATEGORY]: SOFTWARE_CATEGORY,
      [INDUSTRY_CATEGORY]: INDUSTRY_CATEGORY,
      [INSIGHT_TECHNIQUES_OR_MODLES_CATEGORY]: INSIGHT_TECHNIQUES_OR_MODLES_CATEGORY,
    }[category]; // Map category to field using a concise object lookup

    const currentInsightList = form.getState().values?.[field] || [];

    if (!currentInsightList?.some(lang => lang.key === selectedValue.key)) {
      form.change(field, [...currentInsightList, selectedValue]);
    }
    
    resetFormFields(form);
    setIsUlVisible(false);
    setIsDropdownOpen(false);
  }
  // await storeSoftwareInsights({ insightTechniquesOrModels: value })
};


export async function handleUSP(params) {
  const { form, values, item, isServiceSection } = params || {};
  const { service = [], industry = [] } = values || {};

  // Determine the array to update and its key for form change
  const arrayToUpdate = isServiceSection ? service : industry;
  const arrayKey = isServiceSection ? 'service' : 'industry';

  // Update the selected object with isUSP: true and others to false
  const updatedArray = arrayToUpdate.map((obj) =>
    obj.label === item.label ? { ...obj, isUSP: true } : { ...obj, isUSP: false }
  );

  // Update the form with the modified array
  form.change(arrayKey, updatedArray);
}
