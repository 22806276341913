import React from 'react'
import css from '../GigAppTransactionPanel.module.css';
import { FormattedMessage } from 'react-intl';

const BoxFileSection = (props) => {
  const { metadata, location, gigAppInputData } = props;
  const { boxFolder } = metadata
  const { folderId, boxSharedLink, isCollaboratorsAdded } = boxFolder ?? {};

  function handleFolderRefresh() {
    let iframe = document.getElementById('folderFrame');
    iframe.src = iframe.src;
  }

  return (
    <div className={css.boxFileSection}>
      {boxSharedLink ?
        <div className={css.folderContainer}>
          <button onClick={handleFolderRefresh}><FormattedMessage id="BoxFolder.goToFoler" /></button>
          <iframe
            id='folderFrame'
            src={`https://app.box.com/embed/s/${boxSharedLink}?showItemFeedActions=false&showParentPath=false&sortColumn=date`}
            width="920" height="550"
            frameborder="0"
            allowfullscreen webkitallowfullscreen msallowfullscreen
          />
        </div> :
        <div className={css.box}><FormattedMessage id="BoxFolder.noContent" /></div>}
    </div>
  )
}

export default BoxFileSection