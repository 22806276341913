import React from 'react'
import { MilestonesItem } from '../../TransactionPanel/TransactionPanel'
import css from '../GigAppTransactionPanel.module.css'
import { onChangeMilestone, onDeleteMilestone, onToggleMilestone } from './helperFunction';
import { useDispatch } from 'react-redux';
const MilestoneSection = (props) => {
  const {
    typeUser,
    transaction,
    intl,
    actionInProgress,
    stateData,
    currentUser,
    onManageDisableScrolling,
    collaborationMetaData,
    emails,
    isCollaborator,
    txId,
    emailParams
  } = props;
  const dispatch = useDispatch()
  return (
    <div className={css.milestoneSection}>
      <MilestonesItem
        typeUser={typeUser}
        transaction={transaction}
        onChange={(milestone) => onChangeMilestone({
          emails,
          transaction,
          currentUser,
          milestone,
          dispatch,
          isCollaborator,
          txId

        })}
        onDelete={(milestoneId) => onDeleteMilestone({
          milestoneId,
          transaction,
          currentUser,
          dispatch,
          emails,
          isCollaborator,
          txId

        })}
        onToggle={(milestoneId, isSelected) => onToggleMilestone({
          isCollaborator,
          transaction,
          txId,
          emailParams,
          milestoneId,
          isSelected,
          currentUser,
          dispatch
        })}
        intl={intl}
        actionInProgress={actionInProgress}
        editable={!!stateData?.editMilestones}
        currentUser={currentUser}
        onManageDisableScrolling={onManageDisableScrolling}
        collaborationMetaData={collaborationMetaData}
        isGigAppTransaction={true}
      />
    </div>
  )
}

export default MilestoneSection