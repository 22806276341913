import React, { useEffect, useState } from 'react';
import { bool, func, object, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { ensureOwnListing } from '../../util/data';
import css from './EditListingJobPanel.module.css';
import EditListingJobRoleForm from '../../forms/EditListingJobRoleForm/EditListingJobRoleForm';
import { COMPANY, QUALIFICATION, ROLE } from '../EditListingBriefWizard/EditListingBriefWizardTab';
import EditListingJobQualificationForm from '../../forms/EditListingJobQualificationForm/EditListingJobQualificationForm';
import EditListingJobCompanyForm from '../../forms/EditListingJobCompanyForm/EditListingJobCompanyForm';
import _ from 'lodash';
import { getKeyword, updateFirmUserDetails } from '../../util/api';
import { checkIfUserSubscribed, getUserDetails } from '../../util/destructorHelpers';
import { LISTING_STATE_PUBLISHED, LISTING_TYPE_PRIVATE, LISTING_TYPE_PUBLIC, MAX_BRIEF_LIMIT, PRIVATELY_SHARED_JOB, PUBLIC_POSTED_JOB } from '../../util/types';
import { handleUpdateUserSubscriptionData } from '../../containers/OpenAIAppsPage/openAIAppsPageHelperFunction';

const EditListingJobPanel = props => {
  const {
    className,
    rootClassName,
    listing,
    disabled,
    ready,
    onSubmit,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
    currentUser,
    tab,
    isNewListingFlow,
    updateInProgressPrivate
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);
  const [suggestion, setSuggestion] = useState([]);
  const { description, title, publicData } = currentListing.attributes;
  const {attachmentFile, seniority, jobLocation, isRemoteLocation, skill, responsibility, experience,
    isExperienceRequired, aboutCompany, ctc, industrySector=[], employmentLength, contractType
  } = publicData;

  const isListingPublished = listing.attributes.state === LISTING_STATE_PUBLISHED;
  const isUserSubscribed = checkIfUserSubscribed(currentUser);
  const briefsAndJobCount = currentUser?.id && currentUser?.subscriptionData?.briefsAndJobCount;
  const hasCurrentMonthLimitReached = isUserSubscribed && briefsAndJobCount >= MAX_BRIEF_LIMIT;
 
  // const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const panelTitle = () => {
    switch(tab){
      case 'role': 
        return 'EditListingJobPanel.rolePanelTitle'      
      case 'qualification': 
        return 'EditListingJobPanel.qualificationPanelTitle'      
      case 'company': 
        return 'EditListingJobPanel.companyPanelTitle'      
    }
  }

  useEffect(() => {
    getKeyword().then(res => {
      const {suggestions={}} = res;
      if(Object.values(suggestions)) setSuggestion(suggestions);
    })  
  }, []) 

  return (
    <div className={classes}>
      <h1 className={css.title}>
        <FormattedMessage id={panelTitle()} />
      </h1>
      {!isListingPublished && hasCurrentMonthLimitReached && tab === COMPANY && (
        <div className={css.error}>
          <FormattedMessage id='EditListingAdditionalForm.exceedingPlanLimitErrorMessage' />
        </div>
      )}
      {tab === ROLE ? (
        <EditListingJobRoleForm
          className={css.form}
          initialValues={{
            title,
            description,
            remoteLocation: !isRemoteLocation ? [] : [isRemoteLocation.toString()],
            seniority,
            location: jobLocation,
            employmentLength: employmentLength === 'Not applicable' ? 0 : employmentLength,
            employementRequired: employmentLength === 'Not applicable' ? ['true'] : [],
            contractType
          }}
          initialValuesEqual={(prev, curr) => _.isEqual(prev, curr)}
          onSubmit={values => {
            const { 
              title, description, seniority, location, remoteLocation, attachmentFile, employmentLength, employementRequired, 
              contractType
            } = values;
            const isRemoteLocation = !!remoteLocation?.length && !!remoteLocation[0];
            const isEmployementRequired = !employementRequired?.length;
            const updateValues = {
              title: title.trim(),
              description,
              publicData: { 
                seniority, attachmentFile, isRemoteLocation, contractType,
                employmentLength: isEmployementRequired ? employmentLength : 'Not applicable',
                jobLocation: !isRemoteLocation ? location : '' 
              },
            };
            onSubmit(updateValues);
          }}
          attachment={attachmentFile ?? {}}
          saveActionMsg={submitButtonText}
          disabled={disabled}
          ready={ready}
          updated={panelUpdated}
          updateInProgress={updateInProgress}
          fetchErrors={errors}
        />
      ) : tab === QUALIFICATION ? (
        <EditListingJobQualificationForm 
          className={css.form}
          initialValues={{
            responsibility,
            minExperience: experience?.split('-')[0],
            maxExperience: experience?.split('-')[1],
            experienceRequired: isExperienceRequired === false ? ['true'] : [],
            skillSuggestion: suggestion?.skills || [],
            selectedSkills: skill?.length ? skill : []
          }}
          initialValuesEqual={(prev, curr) => _.isEqual(prev, curr)}
          onSubmit={values => {
            const { selectedSkills, responsibility, experienceRequired, maxExperience, minExperience} = values;
            const isExperienceRequired = !!experienceRequired?.length && !!values.experienceRequired[0]
            const updateValues = {
              publicData: { 
                responsibility,
                skill: selectedSkills?.length ? selectedSkills : [], 
                isExperienceRequired: !isExperienceRequired,
                experience: !isExperienceRequired ? `${minExperience}-${maxExperience}` : ''
              },
            };
            onSubmit(updateValues);
          }}
          saveActionMsg={submitButtonText}
          disabled={disabled}
          ready={ready}
          updated={panelUpdated}
          updateInProgress={updateInProgress}
          fetchErrors={errors}
        />
      ) : (
        <EditListingJobCompanyForm
          className={css.form}
          initialValues={{
            aboutCompany,
            budget: ctc?.currency,
            minPrice: ctc?.minPrice,
            maxPrice: ctc?.maxPrice,
            selectedIndustry: industrySector?.length ? industrySector : [],
            suggestion: suggestion?.industry || [],
          }}
          initialValuesEqual={(prev, curr) => _.isEqual(prev, curr)}
          onSubmit={values => {
            const {budget, selectedIndustry, aboutCompany, minPrice, maxPrice, type} = values;
            const updateValues = {
              publicData: { 
                aboutCompany, type, 
                industrySector: selectedIndustry?.length ? selectedIndustry : [],
                ctc: {currency: budget, maxPrice, minPrice},
              },
            };
            
            if(!isListingPublished && type === LISTING_TYPE_PUBLIC){
              //Update user posted job description details only when subscribed
              handleUpdateUserSubscriptionData({
                isUserSubscribed, 
                email: getUserDetails(currentUser)?.email, 
                type: 'publicJobDescription', 
                appName: title,
              })
              updateFirmUserDetails({ 
                action: PUBLIC_POSTED_JOB ,
                userEmail: getUserDetails(currentUser)?.email,
                listingId: currentListing?.id
              })
            }

            onSubmit(updateValues);
          }}
          saveActionMsg={submitButtonText}
          disabled={disabled}
          ready={ready}
          updated={panelUpdated}
          updateInProgress={updateInProgress}
          fetchErrors={errors}
          currentUser={currentUser}
          isNewListingFlow={isNewListingFlow}
          updateInProgressPrivate={updateInProgressPrivate} 
          hasCurrentMonthLimitReached={hasCurrentMonthLimitReached}
          isListingPublished={isListingPublished}
        />
      )
      }
      
    </div>
  );
};

EditListingJobPanel.defaultProps = {
  className: null,
  rootClassName: null,
  errors: null,
  listing: null,
};

EditListingJobPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingJobPanel;
