import { useState, useEffect } from "react";

export default function useHandleFileExtensionError() {
  const [fileExtensionError, setFileExtensionError] = useState(false);

  function checkForFileExtension(allowedExtensions, filesObject, message) {
    let error = false;
    if (filesObject[0] || Array.isArray(filesObject)) {
      const arr = Object.values(filesObject);
      error = arr.some(file => !allowedExtensions.test(file.name));
    } else {
      error = !allowedExtensions.test(filesObject.name);
    }

    if (error) {
      setFileExtensionError(message || true);
    }

    return error;
  }

  // Optional: Cleanup for the timeout when the component using the hook unmounts
  useEffect(() => {
    let myInterval;

    if(fileExtensionError){
      myInterval = setTimeout(() => {
        setFileExtensionError(false);
      }, 3000);
    }
    return () => {
      // Clear timeouts if the component unmounts before 3 seconds
      clearTimeout(myInterval);
    };
  }, [fileExtensionError]);

  return [fileExtensionError, checkForFileExtension];
}
