import React from 'react';
import { bool, func, node, number, string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';

import css from './SearchFiltersPrimary.module.css';
// import { getUserRole } from '../../util/userRole';
import { USER_ROLE_CLIENT, USER_ROLE_PARTNER } from '../../util/types';

const SearchFiltersPrimaryComponent = props => {
  const {
    rootClassName,
    className,
    children,
    sortByComponent,
    listingsAreLoaded,
    resultsCount,
    searchInProgress,
    isSecondaryFiltersOpen,
    toggleSecondaryFiltersOpen,
    selectedSecondaryFiltersCount,
    listings,
    currentUser,
    typePartner,
    handleClearAllFilters,
    urlQueryParams,
  } = props;
  
  const newRole = typePartner === USER_ROLE_CLIENT ? USER_ROLE_PARTNER : USER_ROLE_CLIENT;

  const hasNoResult = listingsAreLoaded && resultsCount === 0;
  const classes = classNames(rootClassName || css.root, className);
// const typeUser = getUserRole(currentUser);
  const toggleSecondaryFiltersOpenButtonClasses =
    isSecondaryFiltersOpen || selectedSecondaryFiltersCount > 0
      ? css.searchFiltersPanelOpen
      : css.searchFiltersPanelClosed;
  const toggleSecondaryFiltersOpenButton =
  //   toggleSecondaryFiltersOpen ? (
  //   <button
  //     className={toggleSecondaryFiltersOpenButtonClasses}
  //     onClick={() => {
  //       toggleSecondaryFiltersOpen(!isSecondaryFiltersOpen);
  //     }}
  //   >
  //     <FormattedMessage
  //       id="SearchFiltersPrimary.moreFiltersButton"
  //       values={{ count: selectedSecondaryFiltersCount }}
  //     />
  //   </button>
  // ) :
      null;
  
  const clearFilterDisabled = !Object.values(urlQueryParams).length ? true 
    : Object.values(urlQueryParams).length === 1 ? urlQueryParams?.keywords : false;   

  return (
    <div className={classes}>
      <div className={css.searchOptions}>
        {listingsAreLoaded ? (
          <div className={css.searchResultSummary}>
            <span className={css.resultsFound}>
              <FormattedMessage
                id="SearchFiltersPrimary.foundResults"
                values={{ count: resultsCount }}
              />
            </span>
          </div>
        ) : null}
        {sortByComponent}
        <button 
          className={css.clearAllButton} 
          onClick={handleClearAllFilters} 
          disabled={clearFilterDisabled}
        >
          <FormattedMessage id='SearchFiltersPrimary.clearFilterButtonText' />
        </button>
      </div>

      <div className={css.filters}>
        {children}
        {toggleSecondaryFiltersOpenButton}
      </div>

      {hasNoResult ? (
        <div className={css.noSearchResults}>
          {newRole === 'partner' ?
           <FormattedMessage id="SearchFiltersPrimary.foundResultsForClient" />:

          <FormattedMessage id="SearchFiltersPrimary.noResults" />
        }
        </div>
      ) : null}

      {searchInProgress ? (
        <div className={css.loadingResults}>
          <FormattedMessage id="SearchFiltersPrimary.loadingResults" />
        </div>
      ) : null}
    </div>
  );
};

SearchFiltersPrimaryComponent.defaultProps = {
  rootClassName: null,
  className: null,
  resultsCount: null,
  searchInProgress: false,
  isSecondaryFiltersOpen: false,
  toggleSecondaryFiltersOpen: null,
  selectedSecondaryFiltersCount: 0,
  sortByComponent: null,
};

SearchFiltersPrimaryComponent.propTypes = {
  rootClassName: string,
  className: string,
  listingsAreLoaded: bool.isRequired,
  resultsCount: number,
  searchInProgress: bool,
  isSecondaryFiltersOpen: bool,
  toggleSecondaryFiltersOpen: func,
  selectedSecondaryFiltersCount: number,
  sortByComponent: node,
};

const SearchFiltersPrimary = SearchFiltersPrimaryComponent;

export default SearchFiltersPrimary;
