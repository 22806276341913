import React from 'react'
import css from './OpenAIAppsPanel.module.css'
import { FormattedMessage } from 'react-intl';
import ExternalLink from '../ExternalLink/ExternalLink';

function OpenAIPermissionTab(props) {
  const {nonOpenaiApps, appRoute} = props;
  return (
    <div>
      <div className={css.summaryBlock}>
        <h3><FormattedMessage id="OpenAiAppsPanel.permissionsLabel" /></h3>
        {!nonOpenaiApps.includes(appRoute) && (
          <p>
            <FormattedMessage
              id="OpenAiAppsPanel.permissionsMessage1"
              values={{
                link: (
                  <a href="https://openai.com/policies/api-data-usage-policies" target="_blank"> here. </a>
                ),
              }}
            />
          </p>
        )}
        <p><FormattedMessage id="OpenAiAppsPanel.permissionsMessage2" /></p>
        <p><FormattedMessage id="OpenAiAppsPanel.permissionsMessage3" /></p>
      </div>
      <div className={css.summaryBlock}>
        <h3><FormattedMessage id="OpenAiAppsPanel.securityLabel" /></h3>
        {!nonOpenaiApps.includes(appRoute) && (
          <p>
            <FormattedMessage
              id="OpenAiAppsPanel.securityMessage1"
              values={{
                link: (
                  <a href="https://openai.com/security" target="_blank"> {' '} here. </a>
                ),
              }}
            />
          </p>
        )}
        <p>
          <FormattedMessage
            id="OpenAiAppsPanel.securityMessage2"
            values={{
              link: (
                <a href="https://app.insightgig.com/terms-of-use" target="_blank"> {' '} here. </a>
              ),
            }}
          />
        </p>
      </div>
      <div className={css.summaryBlock}>
        <h3><FormattedMessage id="OpenAiAppsPanel.informationLabel" /></h3>
        <div className={css.informationBlock}>
          <div>
            <label><FormattedMessage id="OpenAiAppsPanel.companyLabel" /></label>
            <p><FormattedMessage id="OpenAiAppsPanel.insightgigText" /></p>
          </div>
          <div>
            <label><FormattedMessage id="OpenAiAppsPanel.websiteLabel" /></label>
            <ExternalLink href={'https://www.insightgig.com/'} className={css.link}>
              https://www.insightgig.com
            </ExternalLink>
          </div>
        </div>
        <div className={css.informationBlock}>
          <div>
            <label><FormattedMessage id="OpenAiAppsPanel.emailLabel" /></label>
            <ExternalLink href={'mailto:admin@insightgig.com'}>
              admin@insightgig.com
            </ExternalLink>
          </div>
          <div>
            <label><FormattedMessage id="OpenAiAppsPanel.developerLabel" /></label>
            <p>InsightGig</p>
          </div>
        </div>
        <div className={css.informationBlock}>
          <div>
            <label><FormattedMessage id="OpenAiAppsPanel.locationLabel" /></label>
            <p><FormattedMessage id="OpenAiAppsPanel.locationText" /></p>
          </div>
        </div>
        <ExternalLink href={'https://app.insightgig.com/privacy-policy'} className={css.link}>
          <FormattedMessage id="OpenAiAppsPanel.privacyPolicyLabel" />
        </ExternalLink>
      </div>
    </div>
  )
}

export default OpenAIPermissionTab