import React, { useState } from 'react'
import {Field, Form as FinalForm } from 'react-final-form';
import { Form, FieldTextInput, PrimaryButton, IconCard } from '../../components';
import classNames from 'classnames';
import css from './CaseStudyForm.module.css'
import { FormattedMessage, injectIntl } from 'react-intl';
import { compose } from 'redux';
import FileView from '../../components/FileView/FileView';
import { ACCEPT_FILE } from '../../util/types';

const validFileFormat = ['pdf','doc','docx','ppt','pptx'];

function CaseStudyForm(props) {
  return (
    <FinalForm
      {...props}
      render={formRenderProps => {
        const {
          className,
          disabled,
          ready,
          handleSubmit,
          intl,
          invalid,
          pristine,
          submitProgress,
          fetchErrors,
          values,
          form,
          closeModal
        } = formRenderProps;
        
        const [error, setError]= useState('');
        const classes = classNames(css.root, className);
        const submitDisabled = pristine || ready || !values?.file?.name || !values?.deadline;
        
        function handleError(errorMessage){
          setError(errorMessage)
          setTimeout(() => setError(''), 3000)
        }

        return(
          <Form className={classes} onSubmit={handleSubmit}>
            <h2 className={css.title}><FormattedMessage id="CaseStudyForm.title" /></h2>
            <div className={css.attachmentLabel}><FormattedMessage id="CaseStudyForm.attachmentLabel" /></div>
            {!values?.file?.name ? (
              <div className={css.inputSection}>
                <Field
                  id="attachment"
                  name="attachment"
                  accept={ACCEPT_FILE}
                  type="file"
                >
                {fieldprops => {
                  const { accept, input} = fieldprops;
                  const { name, type } = input;
                  const onChange = async (e) => {
                    const file = e.target.files[0];
                    const extension = file?.name?.split('.').at(-1);

                    if(!validFileFormat.includes(extension)){
                      handleError('Only PDF, DOC or PPT formats accepted.')
                      return
                    }
                    form.change('file', file)
                  };
                  const inputProps = { accept, id: name, name, onChange, type };
                  return <input {...inputProps} className={css.addImageInput} />
                }}
                </Field>
                <label htmlFor="attachment" className={css.inputLabel}>
                  <IconCard brand="upload" />
                  <span className={css.dragDrop}><FormattedMessage id="ZohoSign.dragAndDropUpload" /></span>
                  <span className={css.docType}>
                    <FormattedMessage id="JobListing.documentAllowList" />
                  </span>
                </label>
                {error && <p className={css.errorMessage}>{error}</p>}
              </div>
            ) : (
              <FileView file={values?.file}>
                <span className={css.removeFile} onClick={() => form.change('file', {})}>
                  <FormattedMessage id="ZohoSignForm.remove"/>
                </span>
              </FileView>
            )}
            <FieldTextInput
              className={css.dateField}
              type="datetime-local"
              id="deadline"
              name="deadline"
              label='Deadline'
              min={new Date().toISOString().slice(0, 16)}
            />
            <div className={css.buttonWrapper}>
              <span className={css.buttonCancel} onClick={() => closeModal()}>
                Cancel
              </span>
              <PrimaryButton
                className={css.buttonSubmit}
                type='sumbit'
                disabled={submitDisabled}
                inProgress={submitProgress}
              >
                Send
              </PrimaryButton>
            </div>
          </Form>
        )
      }}
    />
  )
}

export default compose(injectIntl)(CaseStudyForm)