import unionWith from 'lodash/unionWith';
import { storableError } from '../../util/errors';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { convertUnitToSubUnit, unitDivisor } from '../../util/currency';
import { formatDateStringToTz, getExclusiveEndDateWithTz } from '../../util/dates';
import { USER_ROLE_CLIENT, USER_ROLE_PARTNER } from '../../util/types'
import { parse } from '../../util/urlHelpers';
import config from '../../config';
import { types as sdkTypes } from '../../util/sdkLoader';
import { handleWeavaiteDatabase, queryExpertFromWeaviate } from '../../util/api'
import { getUserDetails } from '../../util/destructorHelpers';

const { UUID } = sdkTypes;

// Pagination page size might need to be dynamic on responsive page layouts
// Current design has max 3 columns 12 is divisible by 2 and 3
// So, there's enough cards to fill all columns on full pagination pages
export const RESULT_PAGE_SIZE = 50;

// ================ Action types ================ //

export const SEARCH_LISTINGS_REQUEST = 'app/SearchPage/SEARCH_LISTINGS_REQUEST';
export const SEARCH_LISTINGS_SUCCESS = 'app/SearchPage/SEARCH_LISTINGS_SUCCESS';
export const SEARCH_LISTINGS_ERROR = 'app/SearchPage/SEARCH_LISTINGS_ERROR';

export const SEARCH_MAP_LISTINGS_REQUEST = 'app/SearchPage/SEARCH_MAP_LISTINGS_REQUEST';
export const SEARCH_MAP_LISTINGS_SUCCESS = 'app/SearchPage/SEARCH_MAP_LISTINGS_SUCCESS';
export const SEARCH_MAP_LISTINGS_ERROR = 'app/SearchPage/SEARCH_MAP_LISTINGS_ERROR';

export const SEARCH_MAP_SET_ACTIVE_LISTING = 'app/SearchPage/SEARCH_MAP_SET_ACTIVE_LISTING';

export const LOAD_DATA_ERROR = 'app/SearchPage/LOAD_DATA_ERROR';
export const TOTAL_LISTINGS_COUNT = 'app/SearchPage/TOTAL_LISTINGS_COUNT';

export const FETCH_LISTING_TYPE = 'app/SearchPage/FETCH_LISTING_TYPE'
// ================ Reducer ================ //

const initialState = {
  pagination: null,
  searchParams: null,
  searchInProgress: false,
  searchListingsError: null,
  currentPageResultIds: [],
  searchMapListingIds: [],
  searchMapListingsError: null,
  loadDataError:null,
  totalListingsCount: 0,
  listingType: USER_ROLE_CLIENT
};

const resultIds = data => data.data.map(l => l.id);

const listingPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case SEARCH_LISTINGS_REQUEST:
      return {
        ...state,
        searchParams: payload.searchParams,
        searchInProgress: true,
        searchMapListingIds: [],
        searchListingsError: null,
      };
    case SEARCH_LISTINGS_SUCCESS:
      return {
        ...state,
        currentPageResultIds: resultIds(payload.data),
        pagination: payload.data.meta,
        searchInProgress: false,
      };
    case SEARCH_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      return { ...state, searchInProgress: false, searchListingsError: payload };

    case SEARCH_MAP_LISTINGS_REQUEST:
      return {
        ...state,
        searchMapListingsError: null,
      };
    case SEARCH_MAP_LISTINGS_SUCCESS: {
      const searchMapListingIds = unionWith(
        state.searchMapListingIds,
        resultIds(payload.data),
        (id1, id2) => id1.uuid === id2.uuid
      );
      return {
        ...state,
        searchMapListingIds,
      };
    }
    case SEARCH_MAP_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      return { ...state, searchMapListingsError: payload };

    case SEARCH_MAP_SET_ACTIVE_LISTING:
      return {
        ...state,
        activeListingId: payload,
      };
    case TOTAL_LISTINGS_COUNT:
      return {
        ...state,
        totalListingsCount: payload,
      };
    case LOAD_DATA_ERROR:
      return {...state,loadDataError:payload}
    case FETCH_LISTING_TYPE: 
      return {...state, listingType: payload}
    default:
      return state;
  }
};

export default listingPageReducer;

// ================ Action creators ================ //

export const totalListingsCount = lc => ({
  type: TOTAL_LISTINGS_COUNT,
  payload: lc,
});  

export const searchListingsRequest = searchParams => ({
  type: SEARCH_LISTINGS_REQUEST,
  payload: { searchParams },
});

export const searchListingsSuccess = response => ({
  type: SEARCH_LISTINGS_SUCCESS,
  payload: { data: response.data },
});

export const searchListingsError = e => ({
  type: SEARCH_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const searchMapListingsRequest = () => ({ type: SEARCH_MAP_LISTINGS_REQUEST });

export const searchMapListingsSuccess = response => ({
  type: SEARCH_MAP_LISTINGS_SUCCESS,
  payload: { data: response.data },
});

export const searchMapListingsError = e => ({
  type: SEARCH_MAP_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const loadDataError = e => {
  return {
    type: LOAD_DATA_ERROR,
    payload: e,
  }
}

export const setActiveListing = listingId => ({
  type: SEARCH_MAP_SET_ACTIVE_LISTING,
  payload: listingId,
});

export const fetchListingType = type => ({
  type: FETCH_LISTING_TYPE,
  payload: type
})

// ================ Thunks ================ //
export const searchListings = searchParams => async (dispatch, getState, sdk) => {
  try {
    dispatch(searchListingsRequest(searchParams));

    const { perPage, price, dates, type, ...rest } = searchParams;
    const isPartner = getState().user.typePartner === USER_ROLE_PARTNER;
    const listingType = isPartner ? type : USER_ROLE_PARTNER;

    const params = {
      // sort: isPartner ? 'pub_updatedAt': null,
      ...rest,
      pub_listingType: listingType,
      per_page: perPage,
      pub_type: isPartner ? 'public' : 'partner',
    };

    const response = await sdk.listings.query({ ...params });
    const { totalItems = 0 } = response?.data?.meta;

    if (response?.data?.meta) {
      dispatch(totalListingsCount(totalItems));
    }
    const listings = response.data.data;

    dispatch(addMarketplaceEntities(response));
    dispatch(searchListingsSuccess(response));
    return response;
  } catch (e) {
    dispatch(searchListingsError(storableError(e)));
    throw e;
  }
};


export const searchMapListings = searchParams => (dispatch, getState, sdk) => {
  try{
    dispatch(searchMapListingsRequest(searchParams));

    const { perPage, ...rest } = searchParams;
    const params = {
      ...rest,
      per_page: perPage,
    };

    return sdk.listings
      .query(params)
      .then(response => {
        dispatch(addMarketplaceEntities(response));
        dispatch(searchMapListingsSuccess(response));
        return response;
      })
      .catch(e => {
        dispatch(searchMapListingsError(storableError(e)));
        throw e;
      });
  }catch(e){
    dispatch(searchMapListingsError(e));
  }
};

export const getFirmListing = (firmId) => async (dispatch, getState, sdk) => {
  const response = await sdk.listings.show({
    id: new UUID(firmId),
    include: ['author', 'author.profileImage',]
  }, {})

  const authorDetails = response.data.included.find(item => item.type === 'user')
  const author = {
    ...authorDetails,
    profileImage: response.data.included.find(item => item.type === 'image')
  }

  const data = response.data.data;
  data['author'] = author;

  return data;
}

function constructExperienceFilter(filterValue = []){
  const result = filterValue.map(value => {
    const minValue = value.split('-')[0];
    const maxValue = value.split('-')[1];
    return [
      {
        operator: 'And',
        operands: [
          {
            path: ['totalExperience'],
            operator: 'GreaterThanEqual',
            valueInt: Number(minValue),
          },
          {
            path: ['totalExperience'],
            operator: 'LessThanEqual',
            valueInt: Number(maxValue),
          }
        ]
      }
    ] 
  })
  return {
    operator: 'Or',
    operands: result.flat()
  }
}

const handleSearchQuery = (searchParams, email) => async (dispatch, getState, sdk) => {
  const {keywords, page = 1, ...filters} = searchParams || {};

  const modifiedFilters = Object.entries(filters).length && Object.keys(filters).map(filter => {
    const key = filter.replace('pub_', '')
    const query = filters[filter].replace('has_any:', '').split(',')
    
    if(key === 'totalExperience'){
      return constructExperienceFilter(query)
    }
    return {
      path: [key],
      operator: 'ContainsAny',
      valueText: query,
    }
  }).flat()
  
  if(keywords || Object.values(filters).length){
    const {response = {}} = await queryExpertFromWeaviate({
      searchQuery: keywords,
      filters: modifiedFilters,
      page: page,
      itemsPerPage: RESULT_PAGE_SIZE,
      email
    });

    if(!response?.data) return dispatch(searchListingsError(storableError(error)));

    const { totalItems = 0 } = response?.data?.meta;
    dispatch(totalListingsCount(totalItems));
    dispatch(addMarketplaceEntities(response));
    dispatch(searchListingsSuccess(response));
  }
}

export const handleInitialSearch = (searchParams) => async (dispatch, getState) => {
  const {response} = await handleWeavaiteDatabase({
    actionType: 'fetchExpertData',
    searchParams: searchParams
  })

  const { totalItems = 0 } = response?.data?.meta;
  dispatch(totalListingsCount(totalItems));
  dispatch(addMarketplaceEntities(response));
  dispatch(searchListingsSuccess(response));

  return response;
}

export const initialSearchListings = (search) => async (dispatch, getState) => {  
  const {listingType} = getState().SearchPage;
  const isPartner = getState().user.typePartner === USER_ROLE_PARTNER;
  const email = getUserDetails(getState().user.currentUser)?.email;
  let updatedSearch = search;

  const filters = config.custom.filters.filter(f => f.id === 'domainExpertise' ||  f.id === 'industry').map(f => f.config.options).flat();
  const selectedFilters = filters.filter(filter => search.includes(filter.key));
  
  if(search.includes('myTags')){
    updatedSearch = updatedSearch.replace('myTags', 'tags')
  }
  if(search.includes('allTags')){
    updatedSearch = updatedSearch.replace('allTags', 'tags')
  }
  if(selectedFilters?.length){
    selectedFilters.map(filter => {
      updatedSearch = updatedSearch.replace(filter.key, filter.label)  
    })
  }

  try{
    dispatch(searchListingsRequest());
    const queryParams = parse(updatedSearch, {
      latlng: ['origin'],
      latlngBounds: ['bounds'],
    });
  
    const { page = 1, ...rest } = queryParams;
    
    if(!isPartner){
      if(Object.values(rest).length){
        dispatch(handleSearchQuery(queryParams, email))
      }else{
        dispatch(handleInitialSearch({...queryParams, itemsPerPage: RESULT_PAGE_SIZE}))
      }
    }
    else {
      return dispatch(searchListings({
        ...rest,
        page,
        perPage: RESULT_PAGE_SIZE,
        type: listingType,
        // 'limit.images': 1,
      }));
    }
    
  }catch(e){
    console.log(e)
    return dispatch(searchMapListingsError(e));
  }
}

export const loadData = (params, search) => (dispatch, getState) => {
  try{
    const { userRoleFetched } = getState().user;
    const { isAuthenticated } = getState().Auth;

    if (isAuthenticated && !userRoleFetched) {
      return Promise.resolve();
    }

  return dispatch(initialSearchListings(search));
  }catch(e){
    dispatch(loadDataError(e.message));
    return Promise.all([]);
  }
};