import React from 'react'
import { FormattedMessage } from '../../util/reactIntl'
import css from './IntegrationPanel.module.css'
import IntegrationCard from '../IntegrationCard/IntegrationCard';


const IntegrationPanel = (props) => {
  const { firmName } = props;

  return (
    <div className={css.integrationPanelWrapper}>
      <h2>
        <FormattedMessage id="IntegrationPanel.panelHeading" values={{ firmName: firmName }} />
      </h2>
      <div className={css.integrationBox}>
        <IntegrationCard />
      </div>
    </div>
  )
}

export default IntegrationPanel