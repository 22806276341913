import React, { useEffect, useState } from 'react';
import { Field } from 'react-final-form';
import classNames from 'classnames';
import { DatePicker } from 'antd';
import moment from 'moment';

import css from './FieldAntDatePickerInput.module.css';

import './antd.css'
import { ValidationError } from "../index";


const FieldAntDatePickerInputComponent = ({
  id,
  meta,
  className,
  rootClassName,
  label,
  placeholder,
  form,
  input: {
    value,
    name
  },
}) => {
  const dateFormat = 'DD/MM/YYYY';
  const [checkedDate, setCheckedDate] = useState(false)
  const { invalid, error } = meta;
  const [val, setVal] = useState('');
  const validValue = !!value && invalid && checkedDate
  const fieldMeta = { touched: validValue, error: error };

  const classes = classNames(rootClassName || css.fieldRoot, className);
  const getInitialValue = () => {
    let initial = value ? moment(moment(value).format(dateFormat), dateFormat) : ''
    setVal(initial)
  }

  useEffect(() => {
    getInitialValue()
  }, [value])

  const handleChange = (date) => {
    if (!checkedDate) setCheckedDate(true)
    date = date ? date : ''
    setVal(date)
    form.change(name, date.toString());
  }

  const inputClasses = classNames({
    [css.select]: !value,
    [css.selectSuccess]: value && !validValue,
    [css.selectError]: validValue,
  });

  return (
    <div className={classes}>
      {label && (
        <label className={css.label} htmlFor={id}>
          {label}
        </label>
      )}
      <DatePicker
        className={inputClasses}
        onChange={handleChange}
        format={dateFormat}
        value={val}
        placeholder={placeholder}
      />
      <ValidationError fieldMeta={fieldMeta} />
    </div>
  );
}

const FieldBirthdayInput = props => {
  return <Field component={FieldAntDatePickerInputComponent} {...props} type="hidden" />;
};

export default FieldBirthdayInput;
