import React from 'react'
import { getUserDetails } from '../../util/destructorHelpers';
import css from './CsmAvatar.module.css'

const CsmAvatar = (props) => {
    const { user, typeUser, isMobile } = props;
    const profileImage = user?.id && getUserDetails(user)?.profileImage;
    const fullName = user?.id && getUserDetails(user)?.fullName;

    return (
        <div className={isMobile ? css.csmMobile : css.csmAvatarIcon}>
            <span className={profileImage ? css.profileIcon : css.csmProfile}>
                {profileImage ? (
                    <>
                        <img src={profileImage} alt={'profileImage'} className={css.profileImage} />
                        <span className={css.csmLogo} />
                    </>
                ) : (
                    <div className={css.profileImage}>
                        {!!fullName
                            ? fullName.match(/(\b\S)?/g).join('').toUpperCase()
                            : null
                        }
                    </div>
                )}
            </span>
        </div>
    )
}

export default CsmAvatar