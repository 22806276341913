import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconSocialMediaFacebook.module.css';

const IconSocialMediaFacebook = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg
      className={classes}
      width="9"
      height="16"
      viewBox="0 0 11 22"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path fillRule="evenodd" clipRule="evenodd" d="M11 0.158654C10.6562 0.105769 9.47222 0 8.09722 0C5.21991 0 3.24653 1.82452 3.24653 5.16947V8.05168H0V11.9651H3.24653V22H7.14236V11.9651H10.3762L10.8727 8.05168H7.14236V5.55289C7.14236 4.42909 7.43518 3.64904 9.00116 3.64904H11V0.158654Z" fill="#3D3D3D"/>
    </svg>
  );
};

IconSocialMediaFacebook.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

IconSocialMediaFacebook.propTypes = { rootClassName: string, className: string };

export default IconSocialMediaFacebook;
