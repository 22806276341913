import React, { useCallback, useEffect, useState } from 'react';
import { func } from 'prop-types';
import { compose } from 'redux';
import { Field, Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
// These relative imports need to point to correct directories
import {
  intlShape,
  injectIntl,
} from '../../util/reactIntl';
import { Form, FieldTextInput, IconSearch, IconCard } from '../../components';

// as a template.
import css from './GigAppsSearchForm.module.css';
import { CROSS } from '../../components/IconCard/IconCard';


export const GigAppsSearchForm = props => {
  return (
    <FinalForm
      {...props}
      mutators={{
        ...arrayMutators,
      }}
      render={formRenderProps => {
        const {
          handleSubmit,
          intl,
          onValueChange,
          form,
          resetFilter,
          values,
        } = formRenderProps;

        // input placeholder 
        const searchInputFormPlaceholder = intl.formatMessage({
          id: "GigAppsSearchForm.searchInputFormPlaceholder"
        });

        const debounce = (func, delay) => {
          let timerId;
          return (...args) => {
            clearTimeout(timerId);
            timerId = setTimeout(() => {
              func(...args);
            }, delay);
          };
        };
        const debouncedSearch = useCallback(debounce(onValueChange, 300), []);

        return (
          <Form className={css.filterSearchForm} onSubmit={handleSubmit} >
            <div className={css.formWrapper}>
              <div className={css.formSerachBox}>
                <IconSearch />
                <Field
                  className={css.formInput}
                  name="searchTerm"
                  render={({ input }) => (
                    <FieldTextInput
                      {...input}
                      type="text"
                      placeholder={searchInputFormPlaceholder}
                      onChange={(e) => {
                        const inputValue = e.target.value;
                        input.onChange(inputValue);

                        debouncedSearch(e.target.value);
                      }}
                    />
                  )}
                />
                {values?.searchTerm && (
                  <span onClick={() => { form.reset(); resetFilter() }}>
                    <IconCard brand={CROSS} />
                  </span>
                )}
              </div>

            </div>
          </Form>
        );
      }}
    />
  )
}


GigAppsSearchForm.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  //   updateInProgress: bool.isRequired,
};

export default compose(injectIntl)(GigAppsSearchForm);