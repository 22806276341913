import React, { useEffect, useState } from 'react';
import { array, arrayOf, bool, func, object, oneOf, shape, string } from 'prop-types';
import { propTypes } from '../../util/types';
import { intlShape } from '../../util/reactIntl';
import routeConfiguration from '../../routeConfiguration';
import {
  LISTING_PAGE_PARAM_TYPE_DRAFT,
  LISTING_PAGE_PARAM_TYPE_NEW,
  LISTING_PAGE_PARAM_TYPES,
} from '../../util/urlHelpers';
import { ensureListing } from '../../util/data';
import { createResourceLocatorString } from '../../util/routes';
import {
  EditListingBriefDescriptionPanel,
  EditListingBriefFeaturesPanel,
  EditListingAdditionalPanel,
} from '../../components';

import css from './EditListingBriefWizard.module.css';
import { fetchOwnListing } from '../../ducks/user.duck';
import { useDispatch } from 'react-redux';
import { briefCounts, getListingType, sameMonthListings } from '../../util/destructorHelpers';
import EditListingJobPanel from '../EditListingJobPanel/EditListingJobPanel';

export const DESCRIPTION = 'description';
export const FEATURES = 'features';
export const ADDITIONAL = 'additional';
export const ROLE = 'role';
export const QUALIFICATION = 'qualification';
export const COMPANY = 'company';

// EditListingWizardTab component supports these tabs
export const SUPPORTED_TABS = [DESCRIPTION, FEATURES, ADDITIONAL];

const pathParamsToNextTab = (params, tab, marketplaceTabs) => {
  const nextTabIndex = marketplaceTabs.findIndex(s => s === tab) + 1;
  const nextTab =
    nextTabIndex < marketplaceTabs.length
      ? marketplaceTabs[nextTabIndex]
      : marketplaceTabs[marketplaceTabs.length - 1];
  return { ...params, tab: nextTab };
};

// When user has update draft listing, he should be redirected to next EditListingWizardTab
const redirectAfterDraftUpdate = (listingId, params, tab, marketplaceTabs, history, isJobListing) => {
  const pageName = isJobListing ? 'EditJobListingPage' : 'EditListingPage';
  const currentPathParams = {
    ...params,
    type: LISTING_PAGE_PARAM_TYPE_DRAFT,
    id: listingId,
  };
  const routes = routeConfiguration();

  // Replace current "new" path to "draft" path.
  // Browser's back button should lead to editing current draft instead of creating a new one.
  if (params.type === LISTING_PAGE_PARAM_TYPE_NEW) {
    const draftURI = createResourceLocatorString(pageName, routes, currentPathParams, {});
    history.replace(draftURI);
  }

  // Redirect to next tab
  const nextPathParams = pathParamsToNextTab(currentPathParams, tab, marketplaceTabs);

  const to = createResourceLocatorString(pageName, routes, nextPathParams, {});
  history.push(to);
};

const EditListingBriefWizardTab = props => {
  const {
    tab,
    marketplaceTabs,
    params,
    errors,
    fetchInProgress,
    newListingPublished,
    history,
    images,
    listing,
    handleCreateFlowTabScrolling,
    handlePublishListing,
    onAddAvailabilityException,
    onDeleteAvailabilityException,
    onUpdateListing,
    onCreateListingDraft,
    onImageUpload,
    onUpdateImageOrder,
    onRemoveImage,
    onChange,
    onManageDisableScrolling,
    updatedTab,
    updateInProgress,
    updateInProgressPrivate,
    intl,
    fetchExceptionsInProgress,
    availabilityExceptions,
    uploadError,
    uploadInProgress,
    documents,
    currentUser,
    isJobListing,
    onUpdateProfile,
    isSharedBrief
  } = props;
  const { type } = params;
  const isNewURI = type === LISTING_PAGE_PARAM_TYPE_NEW;
  const isDraftURI = type === LISTING_PAGE_PARAM_TYPE_DRAFT;
  const isNewListingFlow = isNewURI || isDraftURI;

  const currentListing = ensureListing(listing);
  const imageIds = images => {
    return images ? images.map(img => img.imageId || img.id) : null;
  };

  const onCompleteEditListingBriefWizardTab = (tab, updateValues, passThrownErrors = false) => {
    // Normalize images for API call
    const { images: updatedImages, ...otherValues } = updateValues;
    const imageProperty =
      typeof updatedImages !== 'undefined' ? { images: imageIds(updatedImages) } : {};
    const updateValuesWithImages = { ...otherValues, ...imageProperty };

    if (isNewListingFlow) {
      const onUpsertListingDraft = isNewURI
        ? (tab, updateValues) => onCreateListingDraft(updateValues)
        : onUpdateListing;

      const upsertValues = isNewURI ? updateValuesWithImages : { ...updateValuesWithImages, id: currentListing.id };

      return onUpsertListingDraft(tab, upsertValues)
        .then(r => {
          if (tab !== marketplaceTabs[marketplaceTabs.length - 1]) {
            // Create listing flow: smooth scrolling polyfill to scroll to correct tab
            handleCreateFlowTabScrolling(false);

            // After successful saving of draft data, user should be redirected to next tab
            redirectAfterDraftUpdate(r.data.data.id.uuid, params, tab, marketplaceTabs, history, isJobListing);
          } else if (tab === marketplaceTabs[marketplaceTabs.length - 1]) {
            handlePublishListing(currentListing.id);
          }
        })
        .catch(e => {
          if (passThrownErrors) {
            throw e;
          }
        });
    } else {
      return onUpdateListing(tab, { ...updateValuesWithImages, id: currentListing.id, },isSharedBrief);
    }
  };

  const panelProps = tab => {
    return {
      className: css.panel,
      errors,
      listing,
      onChange,
      panelUpdated: updatedTab === tab,
      updateInProgress,
      updateInProgressPrivate,
      onManageDisableScrolling,
      // newListingPublished and fetchInProgress are flags for the last wizard tab
      ready: newListingPublished,
      disabled: fetchInProgress,
    };
  };

  switch (tab) {
    case DESCRIPTION: {
      const submitButtonTranslationKey = isNewListingFlow
        ? 'EditListingWizard.saveNewDescription'
        : 'EditListingWizard.saveEditBriefDescription';
      return (
        <EditListingBriefDescriptionPanel
          {...panelProps(DESCRIPTION)}
          submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
          onSubmit={values => {
            onCompleteEditListingBriefWizardTab(tab, values);
          }}
          currentUser={currentUser}
        />
      );
    }
    case FEATURES: {
      const submitButtonTranslationKey = isNewListingFlow
        ? 'EditListingWizard.editBriefFeatures'
        : 'EditListingWizard.saveEditFeatures';
      return (
        <EditListingBriefFeaturesPanel
          {...panelProps(FEATURES)}
          submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
          onSubmit={values => {
            onCompleteEditListingBriefWizardTab(tab, values);
          }}
        />
      );
    }
    case ADDITIONAL: {
      const submitButtonTranslationKey = isNewListingFlow
        ? 'EditListingWizard.saveBriefNewPhotos'
        : 'EditListingWizard.saveEditPhotos';
      return (
        <EditListingAdditionalPanel
          {...panelProps(ADDITIONAL)}
          submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
          onImageUpload={onImageUpload}
          onRemoveImage={onRemoveImage}
          onSubmit={values => {
            onCompleteEditListingBriefWizardTab(tab, values);
          }}
          onUpdateImageOrder={onUpdateImageOrder}
          uploadInProgress={uploadInProgress}
          uploadError={uploadError}
          documents={documents}
          history={history}
          currentUser={currentUser}
          onUpdateProfile={onUpdateProfile}
        />
      );
    }
    case ROLE: {
      const submitButtonTranslationKey = isNewListingFlow
        ? 'EditListingWizard.saveRole'
        : 'EditListingWizard.saveEditRole';
      return (
        <EditListingJobPanel
          {...panelProps(ROLE)}
          submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
          onSubmit={values => {
            onCompleteEditListingBriefWizardTab(tab, values);
          }}
          currentUser={currentUser}
          tab={tab}
        />
      );
    }
    case QUALIFICATION: {
      const submitButtonTranslationKey = isNewListingFlow
        ? 'EditListingWizard.saveQualification'
        : 'EditListingWizard.saveEditQualification';
      return (
        <EditListingJobPanel
          {...panelProps(QUALIFICATION)}
          submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
          onSubmit={values => {
            onCompleteEditListingBriefWizardTab(tab, values);
          }}
          currentUser={currentUser}
          tab={tab}
        />
      );
    }
    case COMPANY: {
      const submitButtonTranslationKey = isNewListingFlow
        ? 'EditListingWizard.saveCompany'
        : 'EditListingWizard.saveEditCompany';
      return (
        <EditListingJobPanel
          {...panelProps(COMPANY)}
          submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
          onSubmit={values => {
            onCompleteEditListingBriefWizardTab(tab, values);
          }}
          currentUser={currentUser}
          tab={tab}
          isNewListingFlow={isNewListingFlow}
        />
      );
    }
    default:
      return null;
  }
};

EditListingBriefWizardTab.defaultProps = {
  listing: null,
  updatedTab: null,
  availabilityExceptions: [],
};

EditListingBriefWizardTab.propTypes = {
  params: shape({
    id: string.isRequired,
    slug: string.isRequired,
    type: oneOf(LISTING_PAGE_PARAM_TYPES).isRequired,
    tab: oneOf(SUPPORTED_TABS).isRequired,
  }).isRequired,
  availabilityExceptions: arrayOf(propTypes.availabilityException),
  errors: shape({
    createListingDraftError: object,
    publishListingError: object,
    updateListingError: object,
    showListingsError: object,
    uploadImageError: object,
    fetchExceptionsError: object,
    addExceptionError: object,
    deleteExceptionError: object,
  }).isRequired,
  fetchInProgress: bool.isRequired,
  fetchExceptionsInProgress: bool.isRequired,
  newListingPublished: bool.isRequired,
  history: shape({
    push: func.isRequired,
    replace: func.isRequired,
  }).isRequired,
  images: array.isRequired,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: shape({
    attributes: shape({
      publicData: object,
      description: string,
      geolocation: object,
      pricing: object,
      title: string,
    }),
    images: array,
  }),

  handleCreateFlowTabScrolling: func.isRequired,
  handlePublishListing: func.isRequired,
  onAddAvailabilityException: func.isRequired,
  onDeleteAvailabilityException: func.isRequired,
  onUpdateListing: func.isRequired,
  onCreateListingDraft: func.isRequired,
  onImageUpload: func.isRequired,
  onUpdateImageOrder: func.isRequired,
  onRemoveImage: func.isRequired,
  onChange: func.isRequired,
  updatedTab: string,
  updateInProgress: bool.isRequired,
  updateInProgressPrivate: bool.isRequired,

  intl: intlShape.isRequired,
};

export default EditListingBriefWizardTab;
