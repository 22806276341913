import React from 'react'
import css from './FileView.module.css'
import pdfimage from '../../assets/pdfimage.png';
import ppticon from '../../assets/ppticon.png';
import docicon from '../../assets/docImage.png';
import exlicon from '../../assets/exlImage.png';
import moment from 'moment';
import IconCard from '../IconCard/IconCard';
import classNames from 'classnames';

export const fileTypeMappings = {
  pdf: <img src={pdfimage} />,
  doc: <img src={docicon} />,
  docs: <img src={docicon} />,
  docx: <img src={docicon} />,
  exl: <img src={exlicon} />,
  xlsx: <img src={exlicon} />,
  ppt: <img src={ppticon} />,
  pptx: <img src={ppticon} />,
  csv: <IconCard brand="csv" />,
  zip: <IconCard brand="zip" />,
  mp3: <IconCard brand="audioFile" />,
  mp4: <IconCard brand="videoFile" />,
};

function FileView({file, children, className}) {
  if(!file?.name) return null

  const fileTypeExt = file?.name.split('.')?.at(-1);
  const fileTypeComponent = fileTypeMappings[fileTypeExt];
  return (
    <div className={classNames(css.files, className)} >
      <a className={css.pdfBox} href={file.link} target="_blank">
        <span className={css.pdfImage}>
        {fileTypeComponent}
        </span>
        <div className={css.pdfRight}>
          <div className={css.pdfName}>{file.name}</div>
          <div className={css.pdfDate}>
            {file.createdAt ? 
              moment.unix(file.createdAt).format('Do MMM YYYY [at] h:mm A')
            : 
            `${moment(file?.date).format("Do MMM YYYY")} at ${moment(file?.date).format("LT")}`
            }
            <span className={css.fileSize}>{(file.size/1000000).toFixed(2)} mb</span>
          </div>
        </div>
      </a>
      {children}
    </div>
  )
}

export default FileView