import React from 'react'
import {Form as FinalForm } from 'react-final-form';
import { Form, FieldTextInput, PrimaryButton } from '../../components';
import classNames from 'classnames';
import css from './RejectApplicationForm.module.css'
import { FormattedMessage, injectIntl } from 'react-intl';
import { compose } from 'redux';

function RejectApplicationForm(props) {
  return (
    <FinalForm
      {...props}
      render={formRenderProps => {
        const {
          className,
          disabled,
          ready,
          handleSubmit,
          intl,
          invalid,
          pristine,
          submitProgress,
          fetchErrors,
          values,
          form,
          isClient,
          closeModal,
          messageId,
          reasonLabelId,
        } = formRenderProps;
        const classes = classNames(css.root, className);
        const submitDisabled = pristine || ready;

        const titleMessageId = isClient ? 'RejectApplicationForm.titleClient' : 'RejectApplicationForm.titleExpert';
        const reasonLabel = reasonLabelId ? reasonLabelId : isClient ? 'RejectApplicationForm.reasonLabelClient' : 'RejectApplicationForm.reasonLabelExpert';
        const reasonPlaceholderMessage = intl.formatMessage({ 
          id: isClient ? 'RejectApplicationForm.reasonPlaceholderClient' : 'RejectApplicationForm.reasonPlaceholderExpert'
        });

        return(
          <Form className={classes} onSubmit={handleSubmit}>
            <h2 className={css.title}>
              <FormattedMessage id={messageId ?? titleMessageId} />
            </h2>
            <FieldTextInput
              id="reason"
              name="reason"
              className={css.title}
              type="input"
              label={intl.formatMessage({ id: reasonLabel })}
              placeholder={messageId ? '' : reasonPlaceholderMessage}
            />
            <div className={css.buttonWrapper}>
              <span className={css.buttonCancel} onClick={() => closeModal()}>
                Cancel
              </span>
              <PrimaryButton
                className={css.buttonSubmit}
                type='sumbit'
                disabled={submitDisabled}
                inProgress={submitProgress}
              >
                Submit
              </PrimaryButton>
            </div>
          </Form>
        )
      }}
    />
  )
}

export default compose(injectIntl)(RejectApplicationForm)