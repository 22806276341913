import React, { useEffect, useState } from 'react'
import { FormattedMessage } from '../../util/reactIntl';
import { ContactUsForm } from '../../forms';
import css from './ContactUsPanel.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { contactUsEmail } from '../../containers/ContactUsPage/ContactUsPage.duck';
import { CONTACT_US } from '../../util/types';

const ContactUsPanel = () => {
  const state = useSelector(state=>state.ContactUsPage);
  const { contactUsEmailSent, sendContactEmailError, sendContactEmailInProgress} = state;
  const dispatch = useDispatch();
  const [successToaster, setSuccessToaster] = useState(false);
  const [errorToaster, setErrorToaster] = useState(false);

  useEffect(() => {
    if(contactUsEmailSent){
      setSuccessToaster(true);
    }
    if(sendContactEmailError){
      setErrorToaster(true)
    }
    const interval = setInterval(()=>{
      setSuccessToaster(false);
      setErrorToaster(false);
    },3000);

    return () => {
      clearInterval(interval);
    }
  },[contactUsEmailSent, sendContactEmailError]);

  const handleContactDetails = async (values,form) =>{
    const sendContactDetails = await dispatch(contactUsEmail({...values,step:CONTACT_US}));
  }
  return (
    <div className={css.contactUsPanel}>
      <div className={css.contactUsPanelHeading}>
        <h2>
          <FormattedMessage id='ContactUsPanel.heading' />
        </h2>
      </div>
      <ContactUsForm
        onSubmit={(values,form) =>handleContactDetails(values,form)}
        updateInProgress={sendContactEmailInProgress}
        successToaster={successToaster}
        errorToaster={errorToaster}
      />
    </div>
  )
}

export default ContactUsPanel