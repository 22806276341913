import React, { useState } from 'react'
import css from './AffiliatedFirmPanel.module.css'
import { FormattedMessage } from 'react-intl';
import { AffiliatedFirmForm } from '../../forms';
import IconCard, { NOTIFICATION_RED_DOT } from '../IconCard/IconCard';
import { AFFILIATED_FIRM_INVITE_RECEIVED } from '../../util/types';
import Button, { InlineTextButton } from '../Button/Button';
import { AFFILIATED_FIRM_INVITE_APPROVED } from '../../util/types';
import { AFFILIATED_FIRM_INVITE_PENDING } from '../../util/types';
import Modal from '../Modal/Modal';
import { manageDisableScrolling } from '../../ducks/UI.duck';
import { useDispatch } from 'react-redux';
const AffiliatedFirmPanel = (props) => {
  const {
    firmName,
    firmId,
    state,
    firmCollection,
    setFirmCollection,
    ownFirm,
    affiliatedListingIds,
    updateFirmStatus,
    removeFirmModalState,
    setRemoveFirmModalState
  } = props;
  
  const dispatch = useDispatch()
  return (
    <div className={css.affiliatedFirmPanel}>
      <div className={css.affiliatedFirmPanelBox}>
        <h3 className={css.firmTitle}>
          <FormattedMessage id="AffiliatedFirmPanel.heading" />
        </h3>
        <p className={css.firmDescription}>
          <FormattedMessage id="AffiliatedFirmPanel.description" />
        </p>
        <AffiliatedFirmForm
          onSubmit={() => { }}
          firmId={firmId}
          ownFirm={ownFirm}
          affiliatedListingIds={affiliatedListingIds}
          setFirmCollection={setFirmCollection}
          firmCollection={firmCollection}
        />
        <div className={css.affiliatedFirmList}>
          <h3 className={css.affiliatedFirmTitle}>
            <FormattedMessage id="AffiliatedFirmPanel.firmListHeading" />
          </h3>
          <div className={css.tableRow}>
            <table>
              <thead>
                <tr>
                  <th>
                    <span>
                      <FormattedMessage id="AffiliatedFirmPanel.tableHeadingFirm" />
                    </span>
                  </th>
                  <th>
                    <span>
                      <FormattedMessage id="AffiliatedFirmPanel.tableHeadingInvite" />
                    </span>
                  </th>
                  <th>
                    <span>
                      <FormattedMessage id="AffiliatedFirmPanel.tableHeadingStatus" />
                    </span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {ownFirm?._id && ownFirm?.affiliatedFirm.map(firm => (
                  <tr key={firm?.firmId}>
                    <td>
                      <div className={css.imageAndFirmName}>
                        <img className={css.firmLogo} src={firm?.firmLogo} alt={firm?.firmLogo} />
                        <span className={css.firmBox}>
                          <span className={css.firmName}>
                            {firm?.firmName}
                          </span>
                         {firm.status === AFFILIATED_FIRM_INVITE_RECEIVED ? <span className={css.notificationsDot}>
                            <IconCard brand={NOTIFICATION_RED_DOT} />
                          </span>:null}
                        </span>
                      </div>
                    </td>
                    <td>
                      <div className={css.inviteStatus}>
                        {firm?.inviteStatus}
                      </div>
                    </td>
                    <td>
                      <div className={css.firmButton}>
                        {firm?.status === AFFILIATED_FIRM_INVITE_RECEIVED ?
                          <Button
                            onClick={() => updateFirmStatus(firm.status, firm.firmId)}
                            className={css.approveButton}>
                            <FormattedMessage id="AffiliatedFirmPanel.acceptButton" />
                          </Button> :
                          firm?.status === AFFILIATED_FIRM_INVITE_APPROVED || AFFILIATED_FIRM_INVITE_PENDING ?
                            <Button
                              onClick={() => setRemoveFirmModalState({ id: firm?.firmId, status: firm.status, firmName: firm.firmName })}
                              className={css.removeButton}
                            >
                              <FormattedMessage id="AffiliatedFirmPanel.removeButton" />
                            </Button> :
                            firm.status}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <Modal
        id="removeFirm"
        className={css.disableModalBorder}
        contentClassName={css.containerClassName}
        isOpen={removeFirmModalState}
        onClose={() => setRemoveFirmModalState(false)}
        usePortal
        onManageDisableScrolling={(componentId, disableScrolling) =>
          dispatch(manageDisableScrolling(componentId, disableScrolling))
        }
      >
        <h2 className={css.firmModalTitle}>
          <FormattedMessage id="AffiliatedFirmPanel.removeFirmModalHeading" values={{ firmName: removeFirmModalState.firmName }} />
        </h2>
        <div className={css.modalBottomButtons}>
          <Button
            className={css.cancelButton}
            onClick={() => setRemoveFirmModalState(false)}
          >
            <FormattedMessage id="AffiliatedFirmPanel.removeFirmCancelButton" />
          </Button>
          <Button
            className={css.yesButton}
            onClick={() => updateFirmStatus(removeFirmModalState.status, removeFirmModalState.id)}
          >
            <FormattedMessage id="AffiliatedFirmPanel.removeFirmButton" />
          </Button>
        </div>
      </Modal>

    </div>
  )
}

export default AffiliatedFirmPanel