import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { ensureOwnListing } from '../../util/data';
import css from './EngagementModelPanel.module.css';
import EngagementForm from '../../forms/EngagementForm/EngagementForm';
import { FULLTIME_KEY, PROJECT_BASED_KEY, SECONDMENT_KEY } from '../../util/types';
import moment from 'moment';
import NamedLink from '../NamedLink/NamedLink';
import { EditListingHintTooltip, EditListingProgress, IconCard } from '..';

const EngagementModelPanel = props => {
  const {
    className,
    rootClassName,
    listing,
    onSubmit,
    ready,
    // onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    // errors,
    completedTabsPer,
  } = props;


  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);
  const { title, publicData } = currentListing.attributes;
  const engagement = publicData && publicData.engagement || {};
  const Experience = publicData && publicData.Experience || [];
  const engagementRoles = publicData && publicData.engagementRoles || [];

  return (
    <div className={classes}>
      {/* <EditListingProgress progressCount={completedTabsPer} value={completedTabsPer} /> */}
      <h2 className={css.title}><FormattedMessage id="EngagementPanel.title" /></h2>

      <EditListingHintTooltip
        tooltipText={<FormattedMessage id="EngagementModelPanel.AsAnExpert" />}
      />
      <p className={css.description}><FormattedMessage id='EngagementPanel.description' /></p>
      <EngagementForm
        initialValues={{
          engagement: engagementRoles,
          secondMentMin: engagement?.secondMent?.minPrice,
          secondMentMax: engagement?.secondMent?.maxPrice,
          secondMentCurrency: engagement?.secondMent?.currency,
          fullTimeMin: engagement?.fullTime?.minPrice,
          fullTimeMax: engagement?.fullTime?.maxPrice,
          fullTimeCurrency: engagement?.fullTime?.currency,
        }}
        onSubmit={(values) => {
          const updateValues = {
            title: title ? title : '...enter your title here',
            publicData: {
              engagementRoles: values?.engagement,
              engagement: {
                projectBased: values?.engagement?.includes(PROJECT_BASED_KEY),
                secondMent: values?.engagement?.includes(SECONDMENT_KEY) && {
                  minPrice: values?.secondMentMin,
                  maxPrice: values?.secondMentMax,
                  currency: values?.secondMentCurrency
                },
                fullTime: values?.engagement?.includes(FULLTIME_KEY) && {
                  minPrice: values?.fullTimeMin,
                  maxPrice: values?.fullTimeMax,
                  currency: values?.fullTimeCurrency
                }
              }
            }
          };
          onSubmit(updateValues)
        }}
        submitButtonText={submitButtonText}
        updateInProgress={updateInProgress}
        ready={ready}
        updated={panelUpdated}
      />
    </div>
  );
};

const { func, object, string, bool } = PropTypes;

EngagementModelPanel.defaultProps = {
  className: null,
  rootClassName: null,
  listing: null,
};

EngagementModelPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EngagementModelPanel;