import React from 'react'
import { IconCard, Menu, MenuContent, MenuItem, MenuLabel } from '../../components'
import { FormattedMessage } from 'react-intl'
import css from './OpenAIQuestionnaireForm.module.css'
import classNames from 'classnames'

const OptionMenu = (props) => {
  const { name, setShowModal, sectionIndex, questionIndex, response } = props;

  const actions = {
    "titleMenu": [
      {
        label: "Edit title",
        handleClick: () => {
          setShowModal({ show: true, name: "editTitle" })
        }
      },
      {
        label: "CopyTitle",
        handleClick: () => {
          navigator.clipboard.writeText(response.title)
        }
      },
      {
        label: "Add section below",
        handleClick: () => {
          setShowModal({ show: true, name: 'addSection', sectionIndex: 0 })
        }
      }
    ],
    "sectionMenu": [
      {
        label: "Edit section heading",
        handleClick: () => {
          setShowModal({ show: true, name: "editSectionHeading", sectionIndex: sectionIndex })
        }
      },
      {
        label: "Copy section heading",
        handleClick: () => {
          navigator.clipboard.writeText(response.sections[sectionIndex].sectionTitle)
        }
      },
      {
        label: "Add question below",
        handleClick: () => {
          setShowModal({ show: true, name: "addQuestion", sectionIndex: sectionIndex, questionIndex: 0 })
        }
      },
      {
        label: "Add section below",
        handleClick: () => {
          setShowModal({ show: true, name: "addSection", sectionIndex: sectionIndex + 1 })
        }
      },
      {
        label: "Delete Section",
        handleClick:()=>{
          setShowModal({ show: true, name: "deleteSection", sectionIndex: sectionIndex})
        }
      }
    ],
    "questionMenu":[
      {
        label:"Edit question & answer",
        handleClick:()=>{
          setShowModal({ show: true, name: "editQuestion", sectionIndex: sectionIndex, questionIndex:questionIndex})
        }
      },
      {
        label:"Copy question",
        handleClick:()=>{
          navigator.clipboard.writeText(response?.sections[sectionIndex]?.questions[questionIndex]?.question)
        }
      },
      {
        label:"Add question below",
        handleClick:()=>{
          setShowModal({ show: true, name: "addQuestion", sectionIndex: sectionIndex, questionIndex:questionIndex+1})
        }
      },
      {
        label:"Add section below",
        handleClick:()=>{
          setShowModal({ show: true, name: "addSection", sectionIndex: sectionIndex + 1 })
        }
      },
      {
        label: "Delete Question",
        handleClick:()=>{
          setShowModal({ show: true, name: "deleteQuestion", sectionIndex: sectionIndex, questionIndex: questionIndex })
        }
      }
    ],
    "endSectionMenu":[
      {
        label:"Edit Section",
        handleClick:()=>{
          setShowModal({show:true,name:"editEndSection"})
        }
      }
    ]

  }

  return (
    <div className={classNames(css.optionMenuContainer,"removediv")}>
      <Menu contentPlacementOffset={14}>
        <MenuLabel className={css.subMenuLabel}>
          <span>
            <IconCard brand="horizontalDots" />
          </span>
        </MenuLabel>
        <MenuContent rootClassName={css.optionMenu} >
          <MenuItem key='selectAction' className={css.firstChild}>
            <FormattedMessage id='OpenAIContentAnalysisOption.selectQuestion' />
          </MenuItem>
          {name && actions[name].map((item, index) => (
            <MenuItem key={index}>
              <span onClick={item.handleClick}>
                {/* <FormattedMessage id={item.label} /> */}
                <span>{item.label}</span>
              </span>
            </MenuItem>
          ))}
        </MenuContent>
      </Menu>
    </div>
  )
}

export default OptionMenu