import { denormalisedResponseEntities, ensureOwnListing } from '../util/data';
import { storableError } from '../util/errors';
import {
  JOB,
  LISTING_STATE_DRAFT,
  LISTING_STATE_PUBLISHED,
  LISTING_TYPE_PRIVATE,
  LISTING_TYPE_PUBLIC,
  USER_ROLE_CLIENT,
  USER_ROLE_PARTNER,
} from '../util/types';
import * as log from '../util/log';
import { authInfo } from './Auth.duck';
import { stripeAccountCreateSuccess } from './stripeConnectAccount.duck';
import { util as sdkUtil } from '../util/sdkLoader';
import {  fetchSubscriptionData as onFetchSubscriptionData , getUserData, sendEmailUsingZepto } from '../util/api';
import { getStoredUserRole, setStoredUserRole } from '../util/userRole';
import { requestPublishListingDraft } from '../containers/EditListingPage/EditListingPage.duck';
import moment from 'moment';
import {
  briefCounts,
  checkIfUserSubscribed,
  filteredTransactions,
  freePlan,
  getListingType,
  getUserDetails,
  professionIndividualPlan,
  sharedBriefIds,
  smallTeamPlan,
} from '../util/destructorHelpers';
import { fetchReviews } from '../containers/ManageListingsPage/ManageListingsPage.duck';

// ================ Action types ================ //

export const CURRENT_USER_SHOW_REQUEST = 'app/user/CURRENT_USER_SHOW_REQUEST';
export const CURRENT_USER_SHOW_SUCCESS = 'app/user/CURRENT_USER_SHOW_SUCCESS';
export const CURRENT_USER_SHOW_ERROR = 'app/user/CURRENT_USER_SHOW_ERROR';

export const CLEAR_CURRENT_USER = 'app/user/CLEAR_CURRENT_USER';

export const TYPE_PARTNER_USER = 'app/user/TYPE_PARTNER_USER';

export const FETCH_CURRENT_USER_HAS_LISTINGS_REQUEST =
  'app/user/FETCH_CURRENT_USER_HAS_LISTINGS_REQUEST';
export const FETCH_CURRENT_USER_HAS_LISTINGS_SUCCESS =
  'app/user/FETCH_CURRENT_USER_HAS_LISTINGS_SUCCESS';
export const FETCH_CURRENT_USER_HAS_LISTINGS_ERROR =
  'app/user/FETCH_CURRENT_USER_HAS_LISTINGS_ERROR';

export const FETCH_CURRENT_USER_NOTIFICATIONS_REQUEST =
  'app/user/FETCH_CURRENT_USER_NOTIFICATIONS_REQUEST';
export const FETCH_CURRENT_USER_NOTIFICATIONS_SUCCESS =
  'app/user/FETCH_CURRENT_USER_NOTIFICATIONS_SUCCESS';
export const FETCH_CURRENT_USER_NOTIFICATIONS_ERROR =
  'app/user/FETCH_CURRENT_USER_NOTIFICATIONS_ERROR';

export const FETCH_CURRENT_USER_HAS_ORDERS_REQUEST =
  'app/user/FETCH_CURRENT_USER_HAS_ORDERS_REQUEST';
export const FETCH_CURRENT_USER_HAS_ORDERS_SUCCESS =
  'app/user/FETCH_CURRENT_USER_HAS_ORDERS_SUCCESS';
export const FETCH_CURRENT_USER_HAS_ORDERS_ERROR = 'app/user/FETCH_CURRENT_USER_HAS_ORDERS_ERROR';

export const SEND_VERIFICATION_EMAIL_REQUEST = 'app/user/SEND_VERIFICATION_EMAIL_REQUEST';
export const SEND_VERIFICATION_EMAIL_SUCCESS = 'app/user/SEND_VERIFICATION_EMAIL_SUCCESS';
export const SEND_VERIFICATION_EMAIL_ERROR = 'app/user/SEND_VERIFICATION_EMAIL_ERROR';

export const FETCH_CURRENT_USER_BRIEFS_REQUEST = 'app/user/FETCH_CURRENT_USER_BRIEFS_REQUEST';
export const FETCH_CURRENT_USER_BRIEFS_SUCCESS = 'app/user/FETCH_CURRENT_USER_BRIEFS_SUCCESS';
export const FETCH_SHARED_LISTINGS_SUCCESS = 'app/user/FETCH_SHARED_LISTINGS_SUCCESS';
export const FETCH_CURRENT_USER_JOB_SUCCESS = 'app/user/FETCH_CURRENT_USER_JOB_SUCCESS';
export const FETCH_CURRENT_USER_BRIEFS_ERROR = 'app/user/FETCH_CURRENT_USER_BRIEFS_ERROR';

export const FETCH_INFO_STATUS_RAI_REQUEST = 'app/user/FETCH_INFO_STATUS_RAI_REQUEST';
export const FETCH_INFO_STATUS_RAI_SUCCESS = 'app/user/FETCH_INFO_STATUS_RAI_SUCCESS';
export const FETCH_INFO_STATUS_RAI_ERROR = 'app/user/FETCH_INFO_STATUS_RAI_ERROR';
export const FETCH_FORM_RAI = 'app/user/FETCH_FORM_RAI';

export const FETCH_CURRENT_USER_PARTNER_PROFILE_REQUEST =
  'app/user/FETCH_CURRENT_USER_PARTNER_PROFILE_REQUEST';
export const FETCH_CURRENT_USER_PARTNER_PROFILE_SUCCESS =
  'app/user/FETCH_CURRENT_USER_PARTNER_PROFILE_SUCCESS';

export const FETCH_CONNECTED_USERS = 'app/user/FETCH_CONNECTED_USERS';
export const USER_LISTING_DATA_FOR_SUBSCRIPTION_PLAN =
  'app/user/USER_LISTING_DATA_FOR_SUBSCRIPTION_PLAN';

  const PER_PAGE = 20;
// ================ Reducer ================ //

const mergeCurrentUser = (oldCurrentUser, newCurrentUser) => {
  const { id: oId, type: oType, attributes: oAttr, ...oldRelationships } = oldCurrentUser || {};
  const { id, type, attributes, ...relationships } = newCurrentUser || {};

  // Passing null will remove currentUser entity.
  // Only relationships are merged.
  // TODO figure out if sparse fields handling needs a better handling.
  return newCurrentUser === null
    ? null
    : oldCurrentUser === null
      ? newCurrentUser
      : { id, type, attributes, ...oldRelationships, ...relationships };
};

const initialState = {
  typePartner: USER_ROLE_CLIENT,
  userRoleFetched: false,
  currentUserPartnerProfileInProgress: false,
  currentUserPartnerProfile: null,

  currentUser: null,
  currentUserShowError: null,
  currentUserHasListings: false,
  currentUserHasListingsError: null,
  currentUserNotificationCount: 0,
  currentUserNotificationCountError: null,
  currentUserHasOrders: null, // This is not fetched unless unverified emails exist
  currentUserHasOrdersError: null,
  sendVerificationEmailInProgress: false,
  sendVerificationEmailError: null,
  currentUserListing: null,
  currentUserListingFetched: false,
  currentUserBriefs: null,
  currentUserBriefsInProgress: false,
  currentUserBriefsError: null,
  infoStatusRAI: null,
  infoStatusRAIInProgress: false,
  infoStatusRAIError: null,
  connectedUsers: null,
  userListingDataForSubscriptionPlan: null,
  sharedListings:[],
  currentUserJobs: [],
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case CURRENT_USER_SHOW_REQUEST:
      return { ...state, currentUserShowError: null };
    case CURRENT_USER_SHOW_SUCCESS:
      return { ...state, currentUser: mergeCurrentUser(state.currentUser, payload) };
    case CURRENT_USER_SHOW_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, currentUserShowError: payload };

    case CLEAR_CURRENT_USER:
      return {
        ...state,
        currentUser: null,
        typePartner: USER_ROLE_CLIENT,
        userRoleFetched: false,
        currentUserShowError: null,
        currentUserHasListings: false,
        currentUserHasListingsError: null,
        currentUserNotificationCount: 0,
        currentUserNotificationCountError: null,
        currentUserListing: null,
        currentUserListingFetched: false,
      };

    case FETCH_CURRENT_USER_HAS_LISTINGS_REQUEST:
      return { ...state, currentUserHasListingsError: null };
    case FETCH_CURRENT_USER_HAS_LISTINGS_SUCCESS:
      return {
        ...state,
        currentUserHasListings: payload.hasListings,
        currentUserListing: payload.listing,
        currentUserListingFetched: true,
      };
    case FETCH_CURRENT_USER_HAS_LISTINGS_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, currentUserHasListingsError: payload };

    case FETCH_CURRENT_USER_NOTIFICATIONS_REQUEST:
      return { ...state, currentUserNotificationCountError: null };
    case FETCH_CURRENT_USER_NOTIFICATIONS_SUCCESS:
      return { ...state, currentUserNotificationCount: payload.transactions.length };
    case FETCH_CURRENT_USER_NOTIFICATIONS_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, currentUserNotificationCountError: payload };

    case FETCH_CURRENT_USER_HAS_ORDERS_REQUEST:
      return { ...state, currentUserHasOrdersError: null };
    case FETCH_CURRENT_USER_HAS_ORDERS_SUCCESS:
      return { ...state, currentUserHasOrders: payload.hasOrders };
    case FETCH_CURRENT_USER_HAS_ORDERS_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, currentUserHasOrdersError: payload };

    case SEND_VERIFICATION_EMAIL_REQUEST:
      return {
        ...state,
        sendVerificationEmailInProgress: true,
        sendVerificationEmailError: null,
      };
    case SEND_VERIFICATION_EMAIL_SUCCESS:
      return {
        ...state,
        sendVerificationEmailInProgress: false,
      };
    case SEND_VERIFICATION_EMAIL_ERROR:
      return {
        ...state,
        sendVerificationEmailInProgress: false,
        sendVerificationEmailError: payload,
      };

    case TYPE_PARTNER_USER:
      return {
        ...state,
        typePartner: payload,
        userRoleFetched: true,
      };

    case FETCH_CURRENT_USER_BRIEFS_REQUEST:
      return {
        ...state,
        currentUserBriefsInProgress: true,
        currentUserBriefsError: null,
      };
    case FETCH_CURRENT_USER_BRIEFS_SUCCESS:
      return {
        ...state,
        currentUserBriefsInProgress: false,
        currentUserBriefs: payload,
      };
    case FETCH_SHARED_LISTINGS_SUCCESS:
      return {
        ...state,
        sharedListings: payload,
        currentUserBriefsInProgress: false,
      };
    case FETCH_CURRENT_USER_BRIEFS_ERROR:
      console.error(payload);
      return {
        ...state,
        currentUserBriefsInProgress: false,
        currentUserBriefsError: payload,
      };

    case FETCH_INFO_STATUS_RAI_REQUEST:
      return {
        ...state,
        infoStatusRAI: 'pending',
        infoStatusRAIInProgress: true,
        infoStatusRAIError: null,
      };
    case FETCH_INFO_STATUS_RAI_SUCCESS:
      return {
        ...state,
        infoStatusRAIInProgress: false,
        infoStatusRAI: payload,
      };
    case FETCH_INFO_STATUS_RAI_ERROR:
      console.error(payload);
      return {
        ...state,
        infoStatusRAI: null,
        infoStatusRAIInProgress: false,
        infoStatusRAIError: payload,
      };

    case FETCH_CURRENT_USER_PARTNER_PROFILE_REQUEST:
      return {
        ...state,
        currentUserPartnerProfileInProgress: true,
      };
    case FETCH_CURRENT_USER_PARTNER_PROFILE_SUCCESS:
      return {
        ...state,
        currentUserPartnerProfileInProgress: false,
        currentUserPartnerProfile: payload,
      };
    case FETCH_CONNECTED_USERS:
      return {
        ...state,
        connectedUsers: payload,
      };
    case USER_LISTING_DATA_FOR_SUBSCRIPTION_PLAN:
      return {
        ...state,
        userListingDataForSubscriptionPlan: payload,
      };
    case FETCH_CURRENT_USER_JOB_SUCCESS:
      return {
        ...state,
        currentUserJobs: payload,
      };

    default:
      return state;
  }
}

// ================ Selectors ================ //

export const hasCurrentUserErrors = state => {
  const { user } = state;
  return (
    user.currentUserShowError ||
    user.currentUserHasListingsError ||
    user.currentUserNotificationCountError ||
    user.currentUserHasOrdersError
  );
};

export const verificationSendingInProgress = state => {
  return state.user.sendVerificationEmailInProgress;
};

// ================ Action creators ================ //

export const currentUserShowRequest = () => ({ type: CURRENT_USER_SHOW_REQUEST });

export const currentUserShowSuccess = user => ({
  type: CURRENT_USER_SHOW_SUCCESS,
  payload: user,
});

export const currentUserShowError = e => ({
  type: CURRENT_USER_SHOW_ERROR,
  payload: e,
  error: true,
});

export const clearCurrentUser = () => ({ type: CLEAR_CURRENT_USER });

const fetchCurrentUserHasListingsRequest = () => ({
  type: FETCH_CURRENT_USER_HAS_LISTINGS_REQUEST,
});

export const fetchCurrentUserHasListingsSuccess = (hasListings, listing) => ({
  type: FETCH_CURRENT_USER_HAS_LISTINGS_SUCCESS,
  payload: { hasListings, listing },
});

const fetchCurrentUserHasListingsError = e => ({
  type: FETCH_CURRENT_USER_HAS_LISTINGS_ERROR,
  error: true,
  payload: e,
});

const fetchCurrentUserNotificationsRequest = () => ({
  type: FETCH_CURRENT_USER_NOTIFICATIONS_REQUEST,
});

export const fetchCurrentUserNotificationsSuccess = transactions => ({
  type: FETCH_CURRENT_USER_NOTIFICATIONS_SUCCESS,
  payload: { transactions },
});

const fetchCurrentUserNotificationsError = e => ({
  type: FETCH_CURRENT_USER_NOTIFICATIONS_ERROR,
  error: true,
  payload: e,
});

const fetchCurrentUserHasOrdersRequest = () => ({
  type: FETCH_CURRENT_USER_HAS_ORDERS_REQUEST,
});

export const fetchCurrentUserHasOrdersSuccess = hasOrders => ({
  type: FETCH_CURRENT_USER_HAS_ORDERS_SUCCESS,
  payload: { hasOrders },
});

const fetchCurrentUserHasOrdersError = e => ({
  type: FETCH_CURRENT_USER_HAS_ORDERS_ERROR,
  error: true,
  payload: e,
});

export const sendVerificationEmailRequest = () => ({
  type: SEND_VERIFICATION_EMAIL_REQUEST,
});

export const sendVerificationEmailSuccess = () => ({
  type: SEND_VERIFICATION_EMAIL_SUCCESS,
});

export const sendVerificationEmailError = e => ({
  type: SEND_VERIFICATION_EMAIL_ERROR,
  error: true,
  payload: e,
});
export const setCurrentUserRole = payload => ({
  type: TYPE_PARTNER_USER,
  payload,
});

export const fetchCurrentUserBriefsRequest = () => ({
  type: FETCH_CURRENT_USER_BRIEFS_REQUEST,
});
export const fetchCurrentUserBriefsSuccess = payload => ({
  type: FETCH_CURRENT_USER_BRIEFS_SUCCESS,
  payload,
});
export const querySharedListingsSuccess = response => ({
  type: FETCH_SHARED_LISTINGS_SUCCESS,
  payload: response,
});
export const fetchCurrentUserBriefsError = e => ({
  type: FETCH_CURRENT_USER_BRIEFS_ERROR,
  error: true,
  payload: e,
});

export const fetchInfoStatusRAIRequest = () => ({
  type: FETCH_INFO_STATUS_RAI_REQUEST,
});
export const fetchInfoStatusRAISuccess = payload => ({
  type: FETCH_INFO_STATUS_RAI_SUCCESS,
  payload,
});
export const fetchInfoStatusRAIError = e => ({
  type: FETCH_INFO_STATUS_RAI_ERROR,
  error: true,
  payload: e,
});

export const fetchCurrentUserPartnerProfileRequest = () => ({
  type: FETCH_CURRENT_USER_PARTNER_PROFILE_REQUEST,
});
export const fetchCurrentUserPartnerProfileSuccess = payload => ({
  type: FETCH_CURRENT_USER_PARTNER_PROFILE_SUCCESS,
  payload,
});

export const updateConnectedUsers = payload => ({
  type: FETCH_CONNECTED_USERS,
  payload,
});
export const userListingDataForSubscriptionPlan = payload => ({
  type: USER_LISTING_DATA_FOR_SUBSCRIPTION_PLAN,
  payload,
});
export const fetchCurrentUserJobSuccess = payload => ({
  type: FETCH_CURRENT_USER_JOB_SUCCESS,
  payload,
});

// ================ Thunks ================ //

export const fetchCurrentUserHasListings = () => (dispatch, getState, sdk) => {
  dispatch(fetchCurrentUserHasListingsRequest());
  const { currentUser } = getState().user;

  if (!currentUser) {
    dispatch(fetchCurrentUserHasListingsSuccess(false));
    return Promise.resolve(null);
  }

  const params = {
    // Since we are only interested in if the user has
    // listings, we only need at most one result.
    // pub_listingType: "partner",
    // authorId: currentUser.id
    // page: 1,
    // per_page: 1,
  };

  return sdk.ownListings
    .query(params)
    .then(response => {
      const listings = response.data.data;
      const currentMonth = moment().month();
      const sameMonthListings =
        listings?.length &&
        listings?.filter(
          l =>
            l?.attributes?.publicData?.type === LISTING_TYPE_PRIVATE ||
            (l?.attributes?.publicData?.type === LISTING_TYPE_PUBLIC &&
              moment(l?.attributes?.createdAt).month() === currentMonth)
        );
      const listingType = getListingType(sameMonthListings);
      const isFreePlanUsed = freePlan(currentUser);
      const professionalPlanUsed = professionIndividualPlan(currentUser);
      const briefCount = briefCounts(listingType);
      const privateBriefCount = briefCount?.private;
      const publicBriefCount = briefCount?.public;
      if (
        isFreePlanUsed &&
        listingType && (listingType?.includes(LISTING_TYPE_PUBLIC) && listingType?.includes(LISTING_TYPE_PRIVATE))
      ) {
        dispatch(userListingDataForSubscriptionPlan(true));
      }
      if (
        professionalPlanUsed &&
        listingType > 6 &&
        privateBriefCount === 3 &&
        publicBriefCount === 3
      ) {
        dispatch(userListingDataForSubscriptionPlan(true));
      }

      const hasListings = response.data.data && response.data.data.length > 0;
      const listing = hasListings
        ? response?.data?.data.filter(
          el => el.attributes.publicData.listingType === USER_ROLE_PARTNER
        )[0]
        : null;

      const hasPublishedListings =
        hasListings &&
        ensureOwnListing(response.data.data[0]).attributes.state !== LISTING_STATE_DRAFT;
      dispatch(fetchCurrentUserHasListingsSuccess(!!hasPublishedListings, listing));
    })
    .catch(e => dispatch(fetchCurrentUserHasListingsError(storableError(e))));
};

export const fetchCurrentUserHasOrders = () => (dispatch, getState, sdk) => {
  dispatch(fetchCurrentUserHasOrdersRequest());

  if (!getState().user.currentUser) {
    dispatch(fetchCurrentUserHasOrdersSuccess(false));
    return Promise.resolve(null);
  }

  const params = {
    only: 'order',
    page: 1,
    per_page: 1,
  };

  return sdk.transactions
    .query(params)
    .then(response => {
      const hasOrders = response.data.data && response.data.data.length > 0;
      dispatch(fetchCurrentUserHasOrdersSuccess(!!hasOrders));
    })
    .catch(e => dispatch(fetchCurrentUserHasOrdersError(storableError(e))));
};

// Notificaiton page size is max (50 items on page)
const NOTIFICATION_PAGE_SIZE = 50;

export const fetchCurrentUserNotifications = () => (dispatch, getState, sdk) => {
//   dispatch(fetchCurrentUserNotificationsRequest());
// const currentUser = getState().user?.currentUser;
//   const apiQueryParams = {
//     page: 1,
//     per_page: NOTIFICATION_PAGE_SIZE,
//     include:['customer','provider']
//   };

//   sdk.transactions
//     .query(apiQueryParams)
//     .then(response => {
//       console.log(response,'respones')

//       const transactions = filteredTransactions(response.data.data).filter(txn=> txn?.relationships?.customer?.data?.id?.uuid !== currentUser?.id?.uuid && !txn?.attributes?.metadata?.isTransactionRead);
//       dispatch(fetchCurrentUserNotificationsSuccess(transactions));
//     })
//     .catch(e => dispatch(fetchCurrentUserNotificationsError(storableError(e))));
};

export const fetchCurrentUser = (params = null) => (dispatch, getState, sdk) => {
  dispatch(currentUserShowRequest());
  const { isAuthenticated } = getState().Auth;

  if (!isAuthenticated) {
    // Make sure current user is null
    dispatch(currentUserShowSuccess(null));
    return Promise.resolve({});
  }

  const {fetchSubscriptionData, ...filteredParams} = params || {};
  const parameters = Object.keys(filteredParams)?.length ? filteredParams : {
    include: ['profileImage', 'stripeAccount', 'stripeCustomer'],
    'fields.image': [
      'variants.square-small',
      'variants.square-small2x',
      'variants.square-xsmall',
      'variants.square-xsmall2x',
    ],
    'imageVariant.square-xsmall': sdkUtil.objectQueryString({
      w: 40,
      h: 40,
      fit: 'crop',
    }),
    'imageVariant.square-xsmall2x': sdkUtil.objectQueryString({
      w: 80,
      h: 80,
      fit: 'crop',
    }),
  };

  return sdk.currentUser
    .show(parameters)
    .then(async (response) => {
      const entities = denormalisedResponseEntities(response);
      if (entities.length !== 1) {
        throw new Error('Expected a resource in the sdk.currentUser.show response');
      }
      const currentUser = entities[0];

      // Save stripeAccount to store.stripe.stripeAccount if it exists
      if (currentUser.stripeAccount) {
        dispatch(stripeAccountCreateSuccess(currentUser.stripeAccount));
      }

      // Fetch user subscription data
      const isUserSubscribed = checkIfUserSubscribed(currentUser);
      if(!!fetchSubscriptionData && isUserSubscribed){
        const subscription = await onFetchSubscriptionData({email: getUserDetails(currentUser)?.email});
        currentUser.subscriptionData = subscription?.data;
      }

      // set current user id to the logger
      log.setUserId(currentUser.id.uuid);
      dispatch(currentUserShowSuccess(currentUser));
      return currentUser;
    })
    .then(async (currentUser) => {
      const linkedToFirms = !!currentUser?.id && (currentUser?.attributes?.profile?.publicData?.linkedToFirms || []);
      if(!!linkedToFirms?.length){
        const firmDetails = linkedToFirms[0];
        let {authorId, isOwnerHasSubscription} = firmDetails || {};

        if(window.location.pathname === '/' && authorId){
          const { data: { data } } = await getUserData({ id: authorId });
          const isUserSubscribed = checkIfUserSubscribed(data);

          if(!!isUserSubscribed !== !!isOwnerHasSubscription){
            firmDetails.isOwnerHasSubscription = isUserSubscribed;
            sdk.currentUser.updateProfile({publicData: {linkedToFirms: [firmDetails]}})
          }
        }
      }

      dispatch(fetchReviews(currentUser?.id))
      //get Razorpay status for payout details
      const statusRAI =
        (currentUser.attributes.profile.publicData &&
          currentUser.attributes.profile.publicData.statusRAI) ||
        '';
      dispatch(fetchInfoStatusRAISuccess(statusRAI));
      //get stored to localstorage role or basic role of currentUser
      if (!getState().user.userRoleFetched) {
        const storedRole = getStoredUserRole(currentUser);
        dispatch(setCurrentUserRole(storedRole));

        if (storedRole === USER_ROLE_PARTNER) {
          dispatch(fetchCurrentUserPartnerProfile());
        }
      }

      dispatch(fetchCurrentUserHasListings());
      dispatch(fetchCurrentUserNotifications());
      // if (!currentUser.attributes.emailVerified) {
      //   dispatch(fetchCurrentUserHasOrders());
      // }
      dispatch(fetchCurrentUserBriefs());

      // Make sure auth info is up to date
      dispatch(authInfo());
      return currentUser
    })
    .catch(e => {
      // Make sure auth info is up to date
      dispatch(authInfo());
      log.error(e, 'fetch-current-user-failed');
      dispatch(currentUserShowError(storableError(e)));
    });
};

export const sendVerificationEmail = () => (dispatch, getState, sdk) => {
  if (verificationSendingInProgress(getState())) {
    return Promise.reject(new Error('Verification email sending already in progress'));
  }
  dispatch(sendVerificationEmailRequest());
  return sdk.currentUser
    .sendVerificationEmail()
    .then(() => dispatch(sendVerificationEmailSuccess()))
    .catch(e => dispatch(sendVerificationEmailError(storableError(e))));
};

export const fetchCurrentUserBriefs = () => async(dispatch, getState, sdk) => {
  const currentUser = getState().user.currentUser;
   const sharedBriefId = currentUser?.id && sharedBriefIds(currentUser);
   if(sharedBriefId?.length) {
    const params = {ids:sharedBriefId,perPage:PER_PAGE,include: ['author', 'author.profileImage','images'],
    'fields.image': ['variants.square-small', 'variants.square-small2x'],}
        const {data:{data={}}={}} = await sdk.listings.query(params);
        dispatch(querySharedListingsSuccess(data))
  }
  dispatch(fetchCurrentUserBriefsRequest());
  return sdk.ownListings
    .query({per_page:PER_PAGE})
    .then(response => {
      const entities = denormalisedResponseEntities(response);
      const filteredEntries = entities?.filter(entry => {
        return (
          entry.attributes &&
          entry.attributes.state === LISTING_STATE_PUBLISHED &&
          entry.attributes.publicData &&
          entry.attributes.publicData.listingType === USER_ROLE_CLIENT
        );
      });
      dispatch(fetchCurrentUserBriefsSuccess(filteredEntries));

      const filteredJobs = entities?.filter(entry => {
        return (
          entry.attributes &&
          entry.attributes.state === LISTING_STATE_PUBLISHED &&
          entry.attributes.publicData &&
          entry.attributes.publicData.listingType === JOB
        )
      });
      dispatch(fetchCurrentUserJobSuccess(filteredJobs));
    })
    .catch(e => {
      dispatch(fetchCurrentUserBriefsError(storableError(e)));
    });
};

export const toggleUserRole = role => (dispatch, getState) => {
  const { currentUser } = getState().user;

  setStoredUserRole(currentUser, role);
  dispatch(setCurrentUserRole(role));
};

// fetch payout details to razorpay
// write data to publicData for show client

export const fetchInfoStatusRAI = (params, id) => (dispatch, getState, sdk) => {
  dispatch(fetchInfoStatusRAIRequest());

  return sendEmailUsingZepto(params)
    .then(response => {
      if (response.status === 200) {
        dispatch(updateStatusRAI('validated', params, id));
      }
    })
    .catch(e => {
      console.error(e, 'something is wrong');
      dispatch(fetchInfoStatusRAIError(storableError(e)));
    });
};

export const updateStatusRAI = (statusRAI, payoutForm, id) => (dispatch, getState, sdk) => {
  const { currentUser } = getState().user;
  let checkedRAI =
    currentUser.attributes.profile.publicData && currentUser.attributes.profile.publicData.RAI;
  let date = {
    statusRAI,
    payoutForm,
  };
  return sdk.currentUser
    .updateProfile({
      publicData: checkedRAI ? date : { ...date, RAI: 'field need to update' },
    })
    .then(response => {
      dispatch(fetchInfoStatusRAISuccess(statusRAI));
      if (id) dispatch(requestPublishListingDraft(id));
      dispatch(fetchCurrentUser());
    })
    .catch(e => {
      console.error(e);
    });
};

export const fetchPartnerProfile = authorId => async (dispatch, getState, sdk) => {
  try {
    const response = await sdk.listings.query({ authorId, pub_listingType: USER_ROLE_PARTNER });
    const entities = denormalisedResponseEntities(response);
    const partnerProfile = entities.at(-1);
    return partnerProfile || null;
  } catch (e) {
    throw e;
  }
};

export const fetchCurrentUserPartnerProfile = () => async (dispatch, getState) => {
  dispatch(fetchCurrentUserPartnerProfileRequest());
  try {
    const currentUserId = getState().user.currentUser.id.uuid;
    const partnerProfile = await dispatch(fetchPartnerProfile(currentUserId));
    dispatch(fetchCurrentUserPartnerProfileSuccess(partnerProfile));
  } catch (e) {
    dispatch(fetchCurrentUserPartnerProfileSuccess(null));
  }
};

export const collaborator = (user, tx) => {
  const currentUserId = user?.id && user?.id?.uuid;
  const providerId = !!tx?.id && tx?.provider?.id?.uuid;
  const customerId = !!tx?.id && tx?.customer?.id?.uuid;
  if (currentUserId === providerId || currentUserId === customerId) {
    return;
  }
  return user;
};


export const fetchOwnListing = () => async (dispatch, getState, sdk) => {
  try {
    const ownListingsResponse = await sdk.ownListings.query({per_page:PER_PAGE});
    return ownListingsResponse
  } catch (error) {
    return;
  }
}