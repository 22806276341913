import React, { useEffect, useState } from 'react';
import { string, arrayOf, bool, func, number } from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
// import dropWhile from 'lodash/dropWhile';
import classNames from 'classnames';
import {
  Avatar,
  InlineTextButton,
  ReviewRating,
  UserDisplayName,
  ExternalLink,
  IconCard,
  SkeletonLoader,
  Modal,
  SkeletonLoaderFirmModal,
  IconSpinner,
} from '../../components';
import { formatDate } from '../../util/dates';
import { ensureTransaction, ensureUser, ensureListing } from '../../util/data';
import {
  TRANSITION_BRIEF_CREATE_PROPOSAL,
  TRANSITION_BRIEF_ACCEPT_PROPOSAL,
  TRANSITION_BRIEF_EXPIRE_PROPOSAL,
  TRANSITION_BRIEF_DECLINE_PROPOSAL,
  // TRANSITION_BRIEF_CANCEL_PROPOSAL,
  TRANSITION_ACCEPT_PROPOSAL_AFTER_BRIEF,
  TRANSITION_OFFER_BRIEF,
  TRANSITION_DECLINE_BRIEF,
  TRANSITION_CREATE_PROPOSAL,
  TRANSITION_ACCEPT_PROPOSAL,
  TRANSITION_EXPIRE_PROPOSAL,
  TRANSITION_DECLINE_PROPOSAL,
  // TRANSITION_CANCEL_PROPOSAL,
  // TRANSITION_REQUEST_PAYMENT,
  TRANSITION_REQUEST_PREPAYMENT,
  TRANSITION_REQUEST_PREPAYMENT_USING_STRIPE,
  // TRANSITION_REQUEST_PAYMENT_AFTER_PREPAYMENT,
  TRANSITION_EXPIRE_PAYMENT,
  // TRANSITION_REQUEST_PAYMENT_AGAIN,
  TRANSITION_CONFIRM_PAYMENT,
  TRANSITION_COMPLETE,
  TRANSITION_FINAL_PAYMENT_COMPLETED,
  TRANSITION_FINAL_PAYMENT_COMPLETED_IF_FULL_AMOUNT_IS_ALREADY_PAID,
  TRANSITION_COMPLETE_MILESTONE_AFTER_PREPAYMENT,
  TRANSITION_REQUEST_MARK_COMPLETE,
  TRANSITION_REQUEST_MARK_COMPLETE_OPERATOR,
  TRANSITION_REVIEW_1_BY_CUSTOMER,
  TRANSITION_REVIEW_1_BY_PROVIDER,
  TRANSITION_REVIEW_2_BY_CUSTOMER,
  TRANSITION_REVIEW_2_BY_PROVIDER,
  transitionIsReviewed,
  txIsInFirstReviewBy,
  txIsReviewed,
  isCustomerReview,
  isProviderReview,
  txRoleIsProvider,
  txRoleIsCustomer,
  getUserTxRole,
  isRelevantPastTransition,
  isRelevantPastBriefTransition,
  txIsCompleted,
  txRoleIsPartner,
  TRANSITION_REQUEST_PAYMENT_AFTER_PROPOSAL_ACCEPTED,
  finalTxIsCompleted,
  TRANSITION_CONFIRM_PAYMENT_AFTER_PROPOSAL_ACCEPTED,
  TRANSITION_CREATE_MILESTONE_AFTER_PREPAYMENT,
  TRANSITION_REQUEST_MARK_COMPLETE_AFTER_PREPAYMENT,
  TRANSITION_CONFIRM_MARK_COMPLETE_AFTER_PREPAYMENT,
  TRANSITION_COMPLETE_USING_STRIPE,
  txLastTransition,
  TRANSITION_OFFLINE_PAYMENT_REQUESTED,
  TRANSITION_FINAL_PAYMENT_COMPLETED_IF_PAYMENT_DUE_UPON_PROJECT_COMPLETION,
  TRANSITION_REQUEST_CONFIRM_PAYMENT_IF_PAYMENT_DUE_UPON_PROJECT_COMPLETION,
  TRANSITION_REQUEST_PREPAYMENT_IF_PAYMENT_DUE_UPON_PROJECT_COMPLETION,
  TRANSITION_REQUEST_FINAL_PAYMENT_IF_PAYMENT_DUE_UPON_PROJECT_COMPLETION,
  TRANSITIONS_JOB_DESCRIPTION,
  TRANSITION_SEND_JOB_DESCRIPTION,
  TRANSITION_DECLINE_JOB_DESCRIPTION,
  TRANSITION_SEND_JOB_APPLICATION,
  TRANSITION_REQUEST_FIRST_INTERVIEW,
  TRANSITION_DECLINE_FIRST_INTERVIEW,
  TRANSITION_ACCEPT_JOB_APPLICATION,
  TRANSITION_REQUEST_CASE_STUDY,
  TRANSITION_DECLINE_CASE_STUDY,
  TRANSITION_SUBMIT_CASE_STUDY,
  TRANSITION_REQUEST_SECOND_INTERVIEW,
  TRANSITION_DECLINE_SECOND_INTERVIEW,
  TRANSITION_SEND_JOB_OFFER,
  TRANSITION_ACCEPT_JOB_OFFER,
  TRANSITION_DECLINE_JOB_OFFER,
  TRANSITION_REQUEST_CONTRACT_SIGNATURE,
  TRANSITION_COMFIRM_CONTRACT_SIGNATURE,
  TRANSITION_REQUEST_OFFLINE_PAYMENT,
  TRANSITION_CONFIRM_OFFLINE_PAYMENT,
  TRANSITION_DECLINE_JOB_APPLICATION,
  TRANSITIONS_APPLY_JOB,
  TRANSITION_CONFIRM_FIRST_INTERVIEW,
  TRANSITION_CONFIRM_SECOND_INTERVIEW,
  TRANSITION_CSM_PRIVATE_BRIEF_INVITE,
  TRANSITION_ACCEPT_PROPOSAL_OPERATOR,
  TRANSITION_CSM_INVITE_ACCEPT_PROPOSAL_OPERATOR,
  TRANSITION_PROPOSAL_ACCEPT_AFTER_CSM_INVITE_OPERATOR,
  TRANSITION_PROPOSAL_DECLINE_CSM_INVITE_OPERATOR,
  TRANSITION_PROPOSAL_DECLINE_AFTER_CSM_INVITE_OPERATOR,
  TRANSITION_REJECT_JOB_INVITE,
  TRANSITION_REJECT_JOB_AFTER_APPLICATION,
  TRANSITION_EXPIRE_JOB_APPLICATION,
  TRANSITION_EXPIRE_JOB_DESCRIPTION,
  TRANSITION_REQUEST_OFFLINE_PAYMENT_WITHOUT_CONTRACT,
} from '../../util/transaction';
import {
  propTypes,
  HISTORY_PROPOSAL_UPDATED,
  HISTORY_MILESTONES_CREATED,
  HISTORY_MILESTONES_UPDATED,
  HISTORY_MILESTONES_COMPLETED,
  HISTORY_MILESTONES_UNCOMPLETED,
  HISTORY_MILESTONES_DELETED,
  HISTORY,
  MESSAGE,
  IMAGE,
  VIDEO,
  HISTORY_MILESTONES_CREATED_AFTER_PREPAYMENT,
  HISTORY_MILESTONES_DELETED_AFTER_PREPAYMENT,
  HISTORY_MILESTONES_UNFINISHED_AFTER_PREPAYMENT,
  HISTORY_MILESTONES_CREATED_OPERATOR,
  HISTORY_MILESTONES_UPDATED_OPERATOR,
  HISTORY_MILESTONES_COMPLETED_OPERATOR,
  HISTORY_MILESTONES_UNCOMPLETED_OPERATOR,
  HISTORY_MILESTONES_DELETED_OPERATOR,
  HISTORY_MILESTONES_COMPLETED_AFTER_PREPAYMENT,
  HISTORY_MILESTONES_UPDATED_AFTER_PREPAYMENT,
  HISTORY_MILESTONES_CREATED_AFTER_PREPAYMENT_OPERATOR,
  HISTORY_MILESTONES_UPDATED_AFTER_PREPAYMENT_OPERATOR,
  HISTORY_MILESTONES_DELETED_AFTER_PREPAYMENT_OPERATOR,
  HISTORY_MILESTONES_COMPLETED_AFTER_PREPAYMENT_OPERATOR,
  HISTORY_MILESTONES_UNFINISHED_AFTER_PREPAYMENT_OPERATOR,
  OFFLINE,
  ONLINE,
  INVITE_STATUS_ACTIVE,
  USER_ROLE_CUSTOMER,
  TRANSACTION_CHAT_PAGE,
  TRANSACTION_ACTIVITY_PAGE,
  GIG_APPS_TRANSACTION_PAGE_CHAT_TAB,
} from '../../util/types';
import * as log from '../../util/log';
// import { Document, Page, pdfjs } from 'react-pdf';
// pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
import css from './ActivityFeed.module.css';
import { fileBrand, getAttachmentType, mediaCheck } from '../../util/typeHelpers';
import moment from 'moment';
import { sortBy } from 'lodash';
import { collaborator } from '../../ducks/user.duck';
import { getAuthorDisplayName } from '../../util/destructorHelpers';
import { getBoxFolderInfo, handleFileUploadToBox } from '../../util/api';

const History = props => {
  const { message, intl, isOwnMessage, displayName } = props;

  const todayString = intl.formatMessage({ id: 'ActivityFeed.today' });
  const { content, createdAt, data = { title: "Demo" } } = message.attributes;
  const getHistoryContent = () => {
    switch (content) {
      // TODO / dont show message
      case HISTORY_PROPOSAL_UPDATED:
        return isOwnMessage
          ? 'ActivityFeed.transitionOwnUpdateProposal'
          : 'ActivityFeed.transitionUpdateProposal';

      case HISTORY_MILESTONES_CREATED:
      case HISTORY_MILESTONES_CREATED_OPERATOR:
      case HISTORY_MILESTONES_CREATED_AFTER_PREPAYMENT:
      case HISTORY_MILESTONES_CREATED_AFTER_PREPAYMENT_OPERATOR:
        return isOwnMessage
          ? 'ActivityFeed.transitionOwnAddMilestone'
          : 'ActivityFeed.transitionAddMilestone';

      case HISTORY_MILESTONES_UPDATED:
      case HISTORY_MILESTONES_UPDATED_OPERATOR:
      case HISTORY_MILESTONES_UPDATED_AFTER_PREPAYMENT:
      case HISTORY_MILESTONES_UPDATED_AFTER_PREPAYMENT_OPERATOR:
        return isOwnMessage
          ? 'ActivityFeed.transitionOwnEditMilestone'
          : 'ActivityFeed.transitionEditMilestone';

      case HISTORY_MILESTONES_COMPLETED:
      case HISTORY_MILESTONES_COMPLETED_OPERATOR:
      case HISTORY_MILESTONES_COMPLETED_AFTER_PREPAYMENT:
      case HISTORY_MILESTONES_COMPLETED_AFTER_PREPAYMENT_OPERATOR:
        return isOwnMessage
          ? 'ActivityFeed.transitionOwnCompleteMilestone'
          : 'ActivityFeed.transitionCompleteMilestone';

      case HISTORY_MILESTONES_UNCOMPLETED:
      case HISTORY_MILESTONES_UNCOMPLETED_OPERATOR:
      case HISTORY_MILESTONES_UNFINISHED_AFTER_PREPAYMENT:
      case HISTORY_MILESTONES_UNFINISHED_AFTER_PREPAYMENT_OPERATOR:
        return isOwnMessage
          ? 'ActivityFeed.transitionOwnUncompleteMilestone'
          : 'ActivityFeed.transitionUncompleteMilestone';

      case HISTORY_MILESTONES_DELETED:
      case HISTORY_MILESTONES_DELETED_OPERATOR:
      case HISTORY_MILESTONES_DELETED_AFTER_PREPAYMENT:
      case HISTORY_MILESTONES_DELETED_AFTER_PREPAYMENT_OPERATOR:
        return isOwnMessage
          ? 'ActivityFeed.transitionOwnDeleteMilestone'
          : 'ActivityFeed.transitionDeleteMilestone';
      default:
        return 'ActivityFeed.wrongMessage'
    }
  };
  const title = !!data && data.title.trim()
  const historyMessage = (
    <FormattedMessage id={getHistoryContent()} values={{ displayName, title: title }} />
  );

  return (
    <div className={css.transition}>
      <div className={css.bullet}>
        <p className={css.transitionContent}>•</p>
      </div>
      <div>
        <p className={css.transitionContent}>{historyMessage}</p>
        <p className={css.transitionDate}>
          {formatDate(intl, todayString, new Date(createdAt))}
        </p>
      </div>
    </div>
  );
};

const Message = props => {
  const { message, intl, lastMessageRef, isOnline, connectedUsers } = props;
  const todayString = intl.formatMessage({ id: 'ActivityFeed.today' });
  const senderId = message?.sender?.id?.uuid;
  return (
    <div className={css.message} ref={lastMessageRef}>
      <span className={css.profileIcon}>
        {connectedUsers?.[senderId]?.userId || false ? (
          <span className={css.onlineDot}></span>
        ) : (
          <span className={css.offlineDot}></span>
        )}
        <Avatar className={css.avatar} user={message.sender} disableProfileLink />
      </span>
      <div>
        <p className={css.messageContent}>
          {message?.attributes?.content !== 'Attachment' ? message?.attributes?.content : null}
        </p>
        <p className={css.messageDate}>
          {formatDate(intl, todayString, new Date(message?.attributes?.createdAt))}
        </p>
      </div>
    </div>
  );
};

Message.propTypes = {
  message: propTypes.message.isRequired,
  intl: intlShape.isRequired,
};

const OwnMessage = props => {
  const { message, intl, fetchMessagesInProgress, lastMessageRef } = props;
  const todayString = intl.formatMessage({ id: 'ActivityFeed.today' });
  return (
    <>
      {fetchMessagesInProgress ? (
        <SkeletonLoader />
      ) : (
        <div className={css.ownMessage} ref={lastMessageRef}>
          <Avatar className={css.avatar} user={message.sender} disableProfileLink />
          <div className={css.ownMessageContentWrapper}>
            <p className={css.ownMessageContent}>
              {message.attributes.content !== 'Attachment' ? message.attributes.content : null}
            </p>
            <p className={css.ownMessageDate}>
              {formatDate(intl, todayString, new Date(message.attributes.createdAt))}
            </p>
            {/* <div ref={lastMessageRef} /> */}
          </div>
          {/* {scrollToMessage(message?.id)} */}
        </div>
      )}
    </>
  );
};

OwnMessage.propTypes = {
  message: propTypes.message.isRequired,
  intl: intlShape.isRequired,
};

const Review = props => {
  const { content, rating } = props;
  return (
    <div>
      <p className={css.reviewContent}>{content}</p>
      {rating ? (
        <ReviewRating
          reviewStarClassName={css.reviewStar}
          className={css.reviewStars}
          rating={rating}
        />
      ) : null}
    </div>
  );
};

Review.propTypes = {
  content: string.isRequired,
  rating: number.isRequired,
};

const hasUserLeftAReviewFirst = (userRole, transaction) => {
  // Because function txIsInFirstReviewBy uses isCustomer to check in which state the reviews are
  // we should also use isCustomer insted of isProvider
  const isCustomer = txRoleIsCustomer(userRole);
  if (
    txLastTransition(transaction) === TRANSITION_REVIEW_2_BY_PROVIDER ||
    txLastTransition(transaction) === TRANSITION_REVIEW_2_BY_CUSTOMER
  ) {
    return true
  }
  // return txIsInFirstReviewBy(transaction, isCustomer);
};

const resolveTransitionMessage = (
  transaction,
  transition,
  listingTitle,
  ownRole,
  otherUsersName,
  intl,
  onOpenReviewModal,
  currentUser,
  isJobListing
) => {
  const isOwnTransition = transition.by === ownRole;
  const isClient = ownRole === USER_ROLE_CUSTOMER;
  const currentTransition = transition.transition;
  const collaborationMetaData = transaction?.attributes?.metadata?.collaborationMetaData;
  const proposalCompletedByID = transaction?.attributes?.protectedData?.proposalCompletedBy;
  const clientInvitee = collaborationMetaData?.length &&
    collaborationMetaData?.find(cb => cb?.status === INVITE_STATUS_ACTIVE && cb?.collaboratorID === proposalCompletedByID);
  const clientInviteeName = clientInvitee?.fullName.split(' ').at(0);

  const displayName = otherUsersName;
  const isCollaborator = !!collaborator(currentUser, transaction);
  const customer = transaction?.id && transaction?.customer;
  const provider = transaction?.id && transaction?.provider;
  const customerName = !!customer?.id && getAuthorDisplayName(customer)?.split(' ')?.at(0);
  const providerName = !!provider?.id && getAuthorDisplayName(provider)?.split(' ')?.at(0);
  const chainedTransactionId = transaction?.attributes?.protectedData?.chainedTransactionId;
  const jobTransactionId = transaction?.attributes?.metadata?.jobTransactionId;
  const isOfflineContract = transaction?.attributes?.metadata?.contractDetails?.contractType === OFFLINE;
  const contractMode = isOfflineContract ? OFFLINE : ONLINE;
  const csmInvitee = collaborationMetaData?.length && collaborationMetaData?.find(cb => cb?.isCsmInvite)
  const isCsmInvitee = csmInvitee?.collaboratorID === currentUser?.id?.uuid;
  const csmInviteeName = csmInvitee?.fullName.split(' ').at(0);

  const getTranslation = () => {
    switch (currentTransition) {
      case TRANSITION_OFFER_BRIEF:
        return isOwnTransition
          ? { id: 'ActivityFeed.transitionOwnBriefOffered' }
          : { id: isCsmInvitee ? 'ActivityFeed.transitionBriefOfferedCollaborator' : 'ActivityFeed.transitionBriefOffered' };

      case TRANSITION_DECLINE_BRIEF:
        return isOwnTransition
          ? { id: 'ActivityFeed.transitionOwnBriefDeclined' }
          : { id: 'ActivityFeed.transitionBriefDeclined' };

      case TRANSITION_BRIEF_CREATE_PROPOSAL:
      case TRANSITION_CREATE_PROPOSAL:
        return isCsmInvitee ? { id: 'ActivityFeed.transitionCreateProposalCollaborator' } : !!chainedTransactionId
          ? { id: !isOwnTransition ? 'ActivityFeed.transitionOwnCreateProposal' : 'ActivityFeed.transitionCreateProposal' }
          : { id: isOwnTransition ? 'ActivityFeed.transitionOwnCreateProposal' : 'ActivityFeed.transitionCreateProposal' }

      case TRANSITION_CSM_PRIVATE_BRIEF_INVITE:
      case TRANSITION_CSM_INVITE_ACCEPT_PROPOSAL_OPERATOR:
        return isOwnTransition
          ? { id: 'ActivityFeed.transitionOwnCsmInvite' }
          : { id: isCollaborator ? 'ActivityFeed.transitionCollaboratorInvite' : 'ActivityFeed.transitionCsmInvite' };

      case TRANSITION_ACCEPT_PROPOSAL_OPERATOR:
      case TRANSITION_PROPOSAL_ACCEPT_AFTER_CSM_INVITE_OPERATOR:
        return isClient
          ? { id: 'ActivityFeed.transitionCollaboratorAcceptClient' }
          : { id: isCollaborator ? 'ActivityFeed.transitionCollaboratorOwnAccept' : 'ActivityFeed.transitionCollaboratorAccept' };

      case TRANSITION_PROPOSAL_DECLINE_CSM_INVITE_OPERATOR:
      case TRANSITION_PROPOSAL_DECLINE_AFTER_CSM_INVITE_OPERATOR:
        return isClient
          ? { id: 'ActivityFeed.transitionDeclineProposalClient' }
          : { id: isCollaborator ? 'ActivityFeed.transitionDeclineProposalCollaborator' : 'ActivityFeed.transitionDeclineProposalExpert' };

      ///TODO
      case TRANSITION_BRIEF_ACCEPT_PROPOSAL:
      case TRANSITION_CONFIRM_PAYMENT:
      case TRANSITION_ACCEPT_PROPOSAL_AFTER_BRIEF:
      case TRANSITION_ACCEPT_PROPOSAL:
        return isOwnTransition
          ? { id: 'ActivityFeed.transitionOwnAcceptProposal' }
          : { id: 'ActivityFeed.transitionAcceptProposal' };

      case TRANSITION_OFFLINE_PAYMENT_REQUESTED:
        return isOwnTransition
          ? { id: 'ActivityFeed.transitionOwnOfflinePaymentRequested' }
          : { id: 'ActivityFeed.transitionOfflinePaymentRequested' };

      ///TODO
      case TRANSITION_REQUEST_PAYMENT_AFTER_PROPOSAL_ACCEPTED:
      case TRANSITION_REQUEST_FINAL_PAYMENT_IF_PAYMENT_DUE_UPON_PROJECT_COMPLETION:
        return isOwnTransition
          ? { id: 'ActivityFeed.transitionOwnRequestPrepayment' }
          : { id: 'ActivityFeed.transitionRequestPrepayment' };
      case TRANSITION_CONFIRM_PAYMENT_AFTER_PROPOSAL_ACCEPTED:
        return isOwnTransition
          ? { id: 'ActivityFeed.transitionOwnConfirmPrepayment' }
          : { id: 'ActivityFeed.transitionConfirmPrepayment' };

      case TRANSITION_REQUEST_PREPAYMENT_USING_STRIPE:
      case TRANSITION_REQUEST_PREPAYMENT_IF_PAYMENT_DUE_UPON_PROJECT_COMPLETION:
        return isOwnTransition
          ? { id: 'ActivityFeed.transitionOwnPayotCreatedStripe' }
          : { id: 'ActivityFeed.transitionPayotCreatedStripe' };

      // SECOND PAYMENT REQUEST
      case TRANSITION_REQUEST_MARK_COMPLETE_AFTER_PREPAYMENT:
        return isOwnTransition
          ? { id: 'ActivityFeed.transitionOwnRequestPrepayment' }
          : { id: 'ActivityFeed.transitionRequestPrepayment' };
      case TRANSITION_CONFIRM_MARK_COMPLETE_AFTER_PREPAYMENT:
      case TRANSITION_REQUEST_CONFIRM_PAYMENT_IF_PAYMENT_DUE_UPON_PROJECT_COMPLETION:
        return isOwnTransition
          ? { id: 'ActivityFeed.transitionOwnConfirmPrepayment' }
          : { id: 'ActivityFeed.transitionConfirmPrepayment' };
      case TRANSITION_COMPLETE_USING_STRIPE:
        return isOwnTransition
          ? { id: 'ActivityFeed.transitionOwnPayotCreatedStripe' }
          : { id: 'ActivityFeed.transitionPayotCreatedStripe' };

      case TRANSITION_BRIEF_EXPIRE_PROPOSAL:
      case TRANSITION_EXPIRE_PROPOSAL:
      case TRANSITION_EXPIRE_PAYMENT:
        return isOwnTransition
          ? { id: 'ActivityFeed.transitionOwnExpireProposal' }
          : { id: 'ActivityFeed.transitionExpireProposal' };

      case TRANSITION_BRIEF_DECLINE_PROPOSAL:
      case TRANSITION_DECLINE_PROPOSAL:
        return isOwnTransition
          ? { id: 'ActivityFeed.transitionOwnDeclineProposal' }
          : { id: 'ActivityFeed.transitionDeclineProposal' };

      case TRANSITION_REQUEST_PREPAYMENT:
        // return isOwnTransition ? {id: 'ActivityFeed.transitionRequestMarkComplete'} : {id: 'ActivityFeed.transitionRequestMarkComplete'};
        return isOwnTransition
          ? { id: 'ActivityFeed.transitionRequestMarkComplete111' }
          : { id: 'ActivityFeed.transitionRequestMarkComplete111' };

      case TRANSITION_REQUEST_MARK_COMPLETE:
      case TRANSITION_REQUEST_MARK_COMPLETE_OPERATOR:
        return isOwnTransition
          ? { id: 'ActivityFeed.transitionOwnMarkComplete' }
          : {
            id: 'ActivityFeed.transitionMarkComplete',
            displayName: transition.by === USER_ROLE_CUSTOMER ? customerName : csmInviteeName ?? clientInviteeName
          };

      case TRANSITION_COMPLETE:
      case TRANSITION_FINAL_PAYMENT_COMPLETED:
      case TRANSITION_FINAL_PAYMENT_COMPLETED_IF_FULL_AMOUNT_IS_ALREADY_PAID:
      case TRANSITION_FINAL_PAYMENT_COMPLETED_IF_PAYMENT_DUE_UPON_PROJECT_COMPLETION:
        const reviewPeriodJustStarted = txIsCompleted(transaction);
        const reviewLink =
          reviewPeriodJustStarted && !isCollaborator ? (
            <InlineTextButton onClick={onOpenReviewModal}>
              <FormattedMessage
                id="ActivityFeed.leaveAReview"
                values={{ displayName: otherUsersName }}
              />
            </InlineTextButton>
          ) : null;

        return {
          id: 'ActivityFeed.transitionComplete',
          reviewLink,
        };

      case TRANSITION_REVIEW_1_BY_PROVIDER:
      case TRANSITION_REVIEW_1_BY_CUSTOMER: {
        const userName = currentTransition === TRANSITION_REVIEW_1_BY_PROVIDER ? providerName : customerName;
        if (isOwnTransition) {
          return { id: 'ActivityFeed.ownTransitionReview', displayName: otherUsersName };
        } else {
          // show the leave a review link if current user is not the first
          // one to leave a review
          const reviewPeriodIsOver = txIsCompleted(transaction);
          const userHasLeftAReview = hasUserLeftAReviewFirst(ownRole, transaction);
          const reviewAsSecondLink =
            !(reviewPeriodIsOver || userHasLeftAReview) && !isCollaborator ? (
              <InlineTextButton onClick={onOpenReviewModal}>
                <FormattedMessage
                  id="ActivityFeed.leaveAReviewSecond"
                  values={{ displayName: otherUsersName }}
                />
              </InlineTextButton>
            ) : null;

          return {
            id: 'ActivityFeed.transitionReview',
            displayName: isCollaborator ? userName : otherUsersName,
            reviewLink: reviewAsSecondLink,
          };
        }
      }
      case TRANSITION_REVIEW_2_BY_PROVIDER:
      case TRANSITION_REVIEW_2_BY_CUSTOMER: {
        const userName = currentTransition === TRANSITION_REVIEW_2_BY_PROVIDER ? providerName : customerName;
        if (isOwnTransition) {
          return { id: 'ActivityFeed.ownTransitionReview', displayName: otherUsersName };
        } else {
          return {
            id: 'ActivityFeed.transitionReview',
            displayName: isCollaborator ? userName : otherUsersName,
            reviewLink: null,
          };
        }
      }

      default:
        log.error(new Error('Unknown transaction transition type'), 'unknown-transition-type', {
          transitionType: currentTransition,
        });
        return {};
    }
  };

  const getJobTranslation = () => {
    switch (currentTransition) {
      case TRANSITION_SEND_JOB_DESCRIPTION:
        return { id: isOwnTransition ? 'ActivityFeed.transitionSentJD' : 'ActivityFeed.transitionReceiveJD' }
      case TRANSITION_DECLINE_JOB_DESCRIPTION:
        return { id: isOwnTransition ? 'ActivityFeed.transitionDeclineJDExpert' : 'ActivityFeed.transitionDeclineJDClient' }
      case TRANSITION_REJECT_JOB_INVITE:
      case TRANSITION_REJECT_JOB_AFTER_APPLICATION:
        return { id: isOwnTransition ? 'ActivityFeed.transitionRejectJobExpert' : 'ActivityFeed.transitionRejectJobClient' }
      case TRANSITION_SEND_JOB_APPLICATION:
        return !!jobTransactionId
          ? { id: !isOwnTransition ? 'ActivityFeed.transitionRecieveApplication' : 'ActivityFeed.transitionSentApplication' }
          : { id: isOwnTransition ? 'ActivityFeed.transitionRecieveApplication' : 'ActivityFeed.transitionSentApplication' }
      case TRANSITION_DECLINE_JOB_APPLICATION:
        return { id: isOwnTransition ? 'ActivityFeed.transitionDeclineApplicationClient' : 'ActivityFeed.transitionDeclineApplicationExpert' }
      case TRANSITION_REQUEST_FIRST_INTERVIEW:
        return { id: isOwnTransition ? 'ActivityFeed.transitionFirstInterviewClient' : 'ActivityFeed.transitionFirstInterviewExpert' }
      case TRANSITION_CONFIRM_FIRST_INTERVIEW:
        return { id: isOwnTransition ? 'ActivityFeed.transitionConfirmFirstInterviewClient' : 'ActivityFeed.transitionConfirmFirstInterviewExpert' }
      case TRANSITION_DECLINE_FIRST_INTERVIEW:
        return { id: isOwnTransition ? 'ActivityFeed.transitionDeclineFirstInterviewExpert' : 'ActivityFeed.transitionDeclineFirstInterviewClient' }
      case TRANSITION_REQUEST_CASE_STUDY:
        return { id: isOwnTransition ? 'ActivityFeed.transitionRequestCaseStudyClient' : 'ActivityFeed.transitionRequestCaseStudyExpert' }
      case TRANSITION_DECLINE_CASE_STUDY:
        return { id: isOwnTransition ? 'ActivityFeed.transitionDeclineCaseStudyExpert' : 'ActivityFeed.transitionDeclineCaseStudyClient' }
      case TRANSITION_SUBMIT_CASE_STUDY:
        return { id: isOwnTransition ? 'ActivityFeed.transitionSubmitCaseStudyExpert' : 'ActivityFeed.transitionSubmitCaseStudyClient' }
      case TRANSITION_REQUEST_SECOND_INTERVIEW:
        return { id: isOwnTransition ? 'ActivityFeed.transitionSecondInterviewClient' : 'ActivityFeed.transitionSecondInterviewExpert' }
      case TRANSITION_CONFIRM_SECOND_INTERVIEW:
        return { id: isOwnTransition ? 'ActivityFeed.transitionConfirmSecondInterviewClient' : 'ActivityFeed.transitionConfirmSecondInterviewExpert' }
      case TRANSITION_DECLINE_SECOND_INTERVIEW:
        return { id: isOwnTransition ? 'ActivityFeed.transitionDeclineSecondInterviewExpert' : 'ActivityFeed.transitionDeclineSecondInterviewClient' }
      case TRANSITION_SEND_JOB_OFFER:
        return { id: isOwnTransition ? 'ActivityFeed.transitionSendJobOffer' : 'ActivityFeed.transitionReceiveJobOffer' }
      case TRANSITION_ACCEPT_JOB_OFFER:
        return { id: isOwnTransition ? 'ActivityFeed.transitionAcceptJobExpert' : 'ActivityFeed.transitionAcceptJobClient' }
      case TRANSITION_DECLINE_JOB_OFFER:
        return { id: isOwnTransition ? 'ActivityFeed.transitionDeclineJobExpert' : 'ActivityFeed.transitionDeclineJobClient' }
      case TRANSITION_REQUEST_CONTRACT_SIGNATURE:
        return { id: isOwnTransition ? 'ActivityFeed.transitionRequestContractClient' : 'ActivityFeed.transitionRequestContractJobExpert' }
      case TRANSITION_COMFIRM_CONTRACT_SIGNATURE:
        return { id: 'ActivityFeed.transitionConfirmContract' }
      case TRANSITION_REQUEST_OFFLINE_PAYMENT:
      case TRANSITION_REQUEST_OFFLINE_PAYMENT_WITHOUT_CONTRACT:
        return { id: isOwnTransition && 'ActivityFeed.transitionRequestOfflinePayment' }
      case TRANSITION_CONFIRM_OFFLINE_PAYMENT:
        return { id: 'ActivityFeed.transitionConfirmOfflinePayment' }
      case TRANSITION_EXPIRE_JOB_APPLICATION:
        return { id: 'ActivityFeed.transitionApplicationExpired' }
      case TRANSITION_EXPIRE_JOB_DESCRIPTION:
        return { id: 'ActivityFeed.transitionJobDescriptionExpired' }
      case TRANSITION_REVIEW_1_BY_PROVIDER:
      case TRANSITION_REVIEW_2_BY_PROVIDER:
        return { id: isOwnTransition ? 'ActivityFeed.transitionReceivedReview' : 'ActivityFeed.transitionGivenReview' }
      case TRANSITION_REVIEW_1_BY_CUSTOMER:
      case TRANSITION_REVIEW_2_BY_CUSTOMER:
        return { id: isOwnTransition ? 'ActivityFeed.transitionReceivedReview' : 'ActivityFeed.transitionGivenReview' }
      default:
        return { id: 'TransactionPanel.loading' }
    }
  }

  const { id, ...params } = isJobListing ? getJobTranslation() : getTranslation();

  if (!id) {
    return null;
  }

  return <FormattedMessage id={id} values={{ displayName, contractMode, csmInviteeName, providerName, customerName, ...params }} />;
};

const reviewByAuthorId = (transaction, userId) => {
  return transaction.reviews.filter(
    r => !r.attributes.deleted && r.author.id.uuid === userId.uuid
  )[0];
};

const Transition = props => {
  const { transition, transaction, currentUser, intl, onOpenReviewModal, isJobListing } = props;

  const currentTransaction = ensureTransaction(transaction);
  const customer = currentTransaction.customer;
  const provider = currentTransaction.provider;

  const deletedListing = intl.formatMessage({ id: 'ActivityFeed.deletedListing' });
  const listingTitle = currentTransaction.listing.attributes.deleted
    ? deletedListing
    : currentTransaction.listing.attributes.title;
  const lastTransition = currentTransaction.attributes.lastTransition;

  const ownRole = getUserTxRole(currentUser.id, currentTransaction);

  const otherUsersName = txRoleIsProvider(ownRole) ? (
    <UserDisplayName user={customer} intl={intl} />
  ) : (
    <UserDisplayName user={provider} intl={intl} />
  );

  const transitionMessage = resolveTransitionMessage(
    transaction,
    transition,
    listingTitle,
    ownRole,
    otherUsersName,
    intl,
    onOpenReviewModal,
    currentUser,
    isJobListing,
  );
  const currentTransition = transition.transition;
  const deletedReviewContent = intl.formatMessage({ id: 'ActivityFeed.deletedReviewContent' });
  let reviewComponent = null;

  if (transitionIsReviewed(lastTransition)) {
    if (isCustomerReview(currentTransition)) {
      const review = reviewByAuthorId(currentTransaction, customer.id);
      reviewComponent = review ? (
        <Review content={review.attributes.content} rating={review.attributes.rating} />
      ) : (
        <Review content={deletedReviewContent} />
      );
    } else if (isProviderReview(currentTransition)) {
      const review = reviewByAuthorId(currentTransaction, provider.id);
      reviewComponent = review ? (
        <Review content={review.attributes.content} rating={review.attributes.rating} />
      ) : (
        <Review content={deletedReviewContent} />
      );
    }
  }

  const todayString = intl.formatMessage({ id: 'ActivityFeed.today' });

  return (
    <div className={css.transition}>
      <div className={css.bullet}>
        <p className={css.transitionContent}>•</p>
      </div>
      <div>
        <p className={css.transitionContent}>{transitionMessage}</p>
        <p className={css.transitionDate}>
          {formatDate(intl, todayString, new Date(transition.createdAt))}
        </p>
        {reviewComponent}
      </div>
    </div>
  );
};

Transition.propTypes = {
  // transition: propTypes.transition.isRequired,
  // transaction: propTypes.transaction.isRequired,
  currentUser: propTypes.currentUser.isRequired,
  intl: intlShape.isRequired,
  onOpenReviewModal: func.isRequired,
};

const EmptyTransition = () => {
  return (
    <div className={css.transition}>
      <div className={css.bullet}>
        <p className={css.transitionContent}>•</p>
      </div>
      <div>
        <p className={css.transitionContent} />
        <p className={css.transitionDate} />
      </div>
    </div>
  );
};

const isMessage = item => item && item.type === MESSAGE;
const isHistory = item => item && item.type === HISTORY;

// Compare function for sorting an array containing messages and transitions
const compareItems = (a, b) => {
  const itemDate = item =>
    isHistory(item)
      ? new Date(item.attributes.createdAt)
      : isMessage(item)
        ? item.attributes.createdAt
        : item.createdAt;
  return itemDate(a) - itemDate(b);
};

const organizedItems = (messages, transitions, history) => {
  const items = messages.concat(history, transitions).sort(compareItems);
  // if) {
  //   // Hide transitions that happened before the oldest message. Since
  //   // we have older items (messages) that we are not showing, seeing
  //   // old transitions would be confusing.
  // return dropWhile(items, i => !isMessage(i));
  // } else {
  return items;
  // }
};

export const ActivityFeedComponent = props => {
  const {
    rootClassName,
    className,
    messages,
    history,
    transaction,
    currentUser,
    hasOlderMessages,
    onOpenReviewModal,
    onShowOlderMessages,
    fetchMessagesInProgress,
    intl,
    lastMessageRef,
    connectedUsers,
    isOnline,
    isCustomer,
    isProvider,
    activeTab,
    isJobListing,
    onManageDisableScrolling,
    boxFolder,
  } = props;
  const classes = classNames(rootClassName || css.root, className);

  const [openFolderModal, setOpenFolderModal] = useState(false);
  const [file, setFile] = useState({ url: '', fileName: '' });
  const [foldersRequest, setFoldersRequest] = useState({ pending: false, folders: [] });
  const [folder, setFolder] = useState({ id: '', uploadingState: '' });

  const currentTransaction = ensureTransaction(transaction);
  const transitions = currentTransaction.attributes.transitions
    ? currentTransaction.attributes.transitions
    : [];
  const currentCustomer = ensureUser(currentTransaction.customer);
  const currentProvider = ensureUser(currentTransaction.provider);
  const currentListing = ensureListing(currentTransaction.listing);

  const hasValidData = currentUser && currentUser.id && currentTransaction && currentTransaction.id;
  const ownRole = hasValidData && getUserTxRole(currentUser.id, currentTransaction);
  const collaborationMetaData = currentTransaction?.attributes?.metadata?.collaborationMetaData;
  const collaboratorData =
    collaborationMetaData?.length &&
    collaborationMetaData?.filter(collaborator =>
      history.some(h => h?.sender?.id?.uuid === collaborator?.collaboratorID)
    );
  const otherUserDisplayName = txRoleIsProvider(ownRole) ? (
    <UserDisplayName user={currentCustomer} intl={intl} />
  ) : (
    <UserDisplayName user={currentProvider} intl={intl} />
  );

  const transitionsAvailable = !!(
    currentUser &&
    currentUser.id &&
    currentCustomer.id &&
    currentProvider.id &&
    currentListing.id
  );

  // combine messages and transaction transitions
  const items = organizedItems(
    messages,
    transitions,
    history,
    hasOlderMessages || fetchMessagesInProgress
  );
  const transitionComponent = transition => {
    if (transitionsAvailable) {
      return (
        <Transition
          transition={transition}
          transaction={transaction}
          currentUser={currentUser}
          intl={intl}
          onOpenReviewModal={onOpenReviewModal}
          isJobListing={isJobListing}
        />
      );
    } else {
      return <EmptyTransition />;
    }
  };
  const downloadFile = file => {
    fetch(file).then(response => {
      response.blob().then(blob => {
        let url = typeof window !== 'undefined' && window.URL.createObjectURL(blob);
        let a = typeof window !== 'undefined' && window.document.createElement('a');
        a.href = url;
        a.download = file.substring(file.lastIndexOf('/') + 1);
        a.click();
      });
      //window.location.href = response.url;
    });
  };

  const ImageAttachment = ({ attachmentType }) => {
    const { link: img, extension } = attachmentType;
    return (
      <div className={css.imageWrapper}>
        {extension !== 'gif' &&
          <div className={css.imageDownload} onClick={() => downloadFile(img)}>
            <IconCard brand="download" />
          </div>
        }
        <ExternalLink href={img} target="default">
          <img src={img} alt="attachment" />
        </ExternalLink>
      </div>
    );
  };

  async function handleOpenBoxFolders(url, fileName) {
    setFile({ url: url, fileName: fileName })
    setFolder({ id: '', uploadingState: '' })
    setOpenFolderModal(true)

    if (!foldersRequest.folders?.length) {
      setFoldersRequest({ ...foldersRequest, pending: true })
      const response = await getBoxFolderInfo({ boxFolderId: boxFolder.folderId })

      if (response.status === 'success') {
        setFoldersRequest({ ...foldersRequest, pending: false, folders: response.data.entries })
      }
    }
  }

  async function handleUploadFileToBox(folderId) {
    setFolder({ id: folderId, uploadingState: 'uploading' })
    const response = await handleFileUploadToBox({ folderId, url: file.url, fileName: file.fileName })

    if (response.status === 'success') {
      setFolder({ id: folderId, uploadingState: 'uploaded' })

      setTimeout(() => {
        setOpenFolderModal(false)
        setFolder({ id: '', uploadingState: '' })
      }, 2000);
    }
    else {
      setFolder({ id: folderId, uploadingState: 'error' })
    }
  }

  const messageComponent = message => {
    const attachmentType = mediaCheck(message?.attributes?.content) && getAttachmentType(message?.attributes?.content);
    const isOwnMessage = message?.sender?.id && currentUser?.id && message?.sender?.id?.uuid === currentUser?.id?.uuid;
    const fileName = message?.attributes?.fileName;

    if (attachmentType) {
      const isImage = attachmentType && attachmentType.type == IMAGE;
      const isVideo = attachmentType && attachmentType.type == VIDEO;
      const url = attachmentType.link;
      const fileExt = url && url.split('.').pop();

      const { displayName = '' } = !!message && message.id && message.sender.attributes?.profile;
      const todayString = intl.formatMessage({ id: 'ActivityFeed.today' });
      return (
        <div className={classNames(css.uploadWraper, isOwnMessage ? css.ownMessage : css.message)}>
          {isImage ? (
            <ImageAttachment attachmentType={attachmentType} />
          )
            : isVideo ? (
              <div className={css.videoWrapper}>
                <video src={attachmentType && attachmentType.link} controls />
              </div>
            )
              : (url && fileExt) && (
                <div className={css.attachmentUpload}>
                  <ExternalLink href={`https://docs.google.com/gview?url=${url}&embedded=true`}>
                    <div className={css.pdfUpload}>
                      <span>
                        <IconCard brand={fileBrand[fileExt]} />
                      </span>
                      <span className={css.urlName}>{fileName} </span>
                    </div>
                  </ExternalLink>
                  <span className={css.downloadButton} onClick={() => downloadFile(url)}>
                    <IconCard brand="download" />
                  </span>
                </div>
              )}
          <div className={css.bottomWrapper}>
            {boxFolder?.folderId && fileExt !== 'gif' && (
              <span onClick={() => handleOpenBoxFolders(url, fileName)}>
                <FormattedMessage id='ActivityFeed.saveFileLink' />
              </span>
            )}
            <p className={css.ownMessageDate}>
              {isOwnMessage ? `You sent a file • ` : `${displayName} sent a file • `}
              {formatDate(intl, todayString, new Date(message.attributes.createdAt))}
            </p>
          </div>

          <Modal
            id="ActivityFedd.folderModal"
            containerClassName={css.modal}
            isOpen={openFolderModal}
            onClose={() => setOpenFolderModal(false)}
            onManageDisableScrolling={onManageDisableScrolling}
            usePortal
          >
            <>
              {foldersRequest.pending ? (
                <SkeletonLoaderFirmModal />
              ) : (
                <div className={css.folderContainer}>
                  <h2><FormattedMessage id='ActivityFeed.folderHeading' /></h2>
                  <p><FormattedMessage id='ActivityFeed.folderSubHeading' /></p>
                  {foldersRequest.folders?.length !== 0 && foldersRequest.folders?.map(folderItem => {
                    const { type, id, name } = folderItem || {}
                    if (type !== 'folder') return
                    return (
                      <div className={css.folderItem} onClick={() => handleUploadFileToBox(id)}>
                        <IconCard brand='folder' />
                        {name}
                        {folder.id === id && (
                          <>
                            {folder.uploadingState === 'uploading' ? <p><IconSpinner strokeColor='orange' /></p>
                              : folder.uploadingState === 'uploaded' ? <p className={css.success}><FormattedMessage id='ActivityFeed.uploadSuccess' /></p>
                                : folder.uploadingState === 'error' && <p className={css.error}><FormattedMessage id='ActivityFeed.uploadError' /></p>
                            }
                          </>
                        )}
                      </div>
                    )
                  })}
                </div>
              )}
            </>
          </Modal>
        </div>
      )
    } else {
      if (isOwnMessage) {
        return (
          <OwnMessage
            message={message}
            intl={intl}
            fetchMessagesInProgress={fetchMessagesInProgress}
            lastMessageRef={lastMessageRef}
          />
        );
      }
      return (
        <Message
          message={message}
          intl={intl}
          fetchMessagesInProgress={fetchMessagesInProgress}
          lastMessageRef={lastMessageRef}
          connectedUsers={connectedUsers}
          isOnline={isOnline}
        />
      );
    }
  };

  const messageListItem = message => {
    return (
      <li id={`msg-${message.id.uuid}`} key={message?.socketId} className={css.messageItem}>
        {messageComponent(message)}
      </li>
    );
  };

  const historyComponent = message => {
    const isOwnMessage =
      message.sender &&
      message.sender.id &&
      currentUser &&
      currentUser.id &&
      message.sender.id.uuid === currentUser.id.uuid;

    const collaborator =
      collaboratorData?.length &&
      collaboratorData?.find(item => item?.collaboratorID === message?.sender?.id?.uuid);

    return (
      <History
        message={message}
        intl={intl}
        isOwnMessage={isOwnMessage}
        displayName={
          (collaborator && collaborator.fullName && collaborator.fullName.split(' ')?.at(0)) ||
          otherUserDisplayName
        }
      />
    );
  };

  const historyListItem = message => {
    const { createdAt } = message.attributes;
    const historyKey = String(+new Date(createdAt));
    return (
      <li id={`msg-${historyKey}`} key={historyKey} className={css.messageItem}>
        {historyComponent(message)}
      </li>
    );
  };

  const transitionListItem = transition => {
    if (
      isRelevantPastTransition(transition?.transition)
      || isRelevantPastBriefTransition(transition?.transition)
      || TRANSITIONS_JOB_DESCRIPTION?.includes(transition?.transition)
    ) {
      return (
        <li key={transition.createdAt} className={css.transitionItem}>
          {transitionComponent(transition)}
        </li>
      );
    } else {
      return null;
    }
  };

  const sortedTransactions = txs =>
    sortBy(txs, tx => {
      return tx ? moment(tx?.createdAt).unix() : null;
    });

  const sortedItems = items?.length && items?.map(item => {
    const isHistory = item?.type === HISTORY;
    const createdAt = item?.attributes?.createdAt;
    return isHistory ? { ...item, createdAt } : { ...item };
  });

  useEffect(() => {
    const activityFeedDiv = document.getElementById('toScroll');
    activityFeedDiv.scrollBy({
      top: 1000000000,
      left: 0,
      behavior: "smooth",
    });
  }, [messages, transitions, history])

  const txIgnoredByProvider = [
    TRANSITION_OFFLINE_PAYMENT_REQUESTED,
    TRANSITION_REQUEST_OFFLINE_PAYMENT,
    TRANSITION_REQUEST_OFFLINE_PAYMENT_WITHOUT_CONTRACT,
    TRANSITION_CONFIRM_OFFLINE_PAYMENT
  ];

  return (
    <ul className={classes} id="toScroll">
      {hasOlderMessages ? (
        <li className={css.showOlderWrapper} key="show-older-messages">
          <InlineTextButton className={css.showOlderButton} onClick={onShowOlderMessages}>
            <FormattedMessage id="ActivityFeed.showOlderMessages" />
          </InlineTextButton>
        </li>
      ) : null}

      {sortedTransactions(sortedItems).map(item => {
        if (
          isProvider && txIgnoredByProvider.includes(item.transition)
          || [TRANSITION_BRIEF_ACCEPT_PROPOSAL, TRANSITION_ACCEPT_JOB_APPLICATION].includes(item.transition)
        ) {
          return;
        }

        if (isMessage(item) && (activeTab === TRANSACTION_CHAT_PAGE || activeTab === GIG_APPS_TRANSACTION_PAGE_CHAT_TAB)) {
          return messageListItem(item);
        } else if (isHistory(item) && activeTab === TRANSACTION_ACTIVITY_PAGE) {
          return historyListItem(item);
        } else if (activeTab === TRANSACTION_ACTIVITY_PAGE) {
          return transitionListItem(item);
        }
      })}
    </ul>
  );
};

ActivityFeedComponent.defaultProps = {
  rootClassName: null,
  className: null,
};

ActivityFeedComponent.propTypes = {
  rootClassName: string,
  className: string,

  currentUser: propTypes.currentUser,
  // transaction: propTypes.transaction,
  messages: arrayOf(propTypes.message),
  hasOlderMessages: bool.isRequired,
  onOpenReviewModal: func.isRequired,
  onShowOlderMessages: func.isRequired,
  fetchMessagesInProgress: bool.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const ActivityFeed = injectIntl(ActivityFeedComponent);

export default ActivityFeed;
