import { useState } from "react";
import { fetchQueryReportData, handleWeavaiteDatabase } from "../util/api";

const useFetchQueryReportData = (handleError) => {
  const [sessionData, setSessionData] = useState({});

  async function fetchData(id, isTransaction){
    try{
      const response = await fetchQueryReportData({ id, isTransaction });
      if(response.status === 'notfound'){
        setSessionData({
          uploadedFiles: [],
          createdAt: null,
          expiresAt: 'expired'
        })
      }
      else if(response.status === 'success'){

        const {data: {folders = [], files = [], multipleFolders} = {}, createdAt, expiresAt, email, files: transactionFiles = [], summary} = response.data || {};
        const modifiedFilesData = !multipleFolders ? [...files, ...transactionFiles].map(file => ({...file, date: createdAt })) : [];
        setSessionData({
          uploadedFolder: folders,
          multipleFolders,
          uploadedFiles: modifiedFilesData,
          createdAt,
          expiresAt,
          email,
          summary,
        })
        return {email, summary}
      }
    }
    catch(error){
      console.log(error, 'Error fetching Query Report session!')
      handleError(error)
    }
  }

  return [sessionData, fetchData]
}

export default useFetchQueryReportData