import React from 'react';
import { array, arrayOf, bool, func, object, oneOf, shape, string } from 'prop-types';
import { propTypes } from '../../util/types';
import { intlShape } from '../../util/reactIntl';
import routeConfiguration from '../../routeConfiguration';
import {
  LISTING_PAGE_PARAM_TYPE_DRAFT,
  LISTING_PAGE_PARAM_TYPE_NEW,
  LISTING_PAGE_PARAM_TYPES,
} from '../../util/urlHelpers';
import { ensureListing } from '../../util/data';
import { createResourceLocatorString } from '../../util/routes';
import {
  AdditionalInformationPanel,
  EditListingAvailabilityPanel,
  EditListingDescriptionPanel,
  EditListingEducationPanel,
  EditListingFeaturesPanel,
  EditListingPhotosPanel,
  EditListingPoliciesPanel,
  EditListingPricingPanel,
  EditListingWorkExperiencePanel,
  EditListingLocationPanel,
  EditListingPackagesPanel,
} from '../../components';

import css from './EditListingWizard.module.css';
import EditListingCertificationPanel from '../EditListingCertificationPanel/EditListingCertificationPanel';
import EditListingSampleWorkPanel from '../EditListingSampleWorkPanel/EditListingSampleWorkPanel';
import EngagementModelPanel from '../EngagementModelPanel/EngagementModelPanel';
import { handleWeavaiteDatabase } from '../../util/api';
import { getDataInChunks, getUserDetails } from '../../util/destructorHelpers';
import { useDispatch } from 'react-redux';
import { updateListingError } from '../../containers/EditListingPage/EditListingPage.duck';
import { updateUserDetailsInWeaviate } from '../../containers/BecomeInsightGigPartnerPage/BecomeInsightGigPartnerPage.duck';

export const DESCRIPTION = 'description';
export const FEATURES = 'features';
export const PACKAGE = 'package';
export const EXPERIENCE = 'experience';
export const EDUCATION = 'education';
export const CERTIFICATION = 'certification';
export const SAMPLEWORK = 'samplework';
export const POLICY = 'policy';
export const LOCATION = 'location';
export const PRICING = 'pricing';
export const PHOTOS = 'photos';
export const ADDITIONAL_INFO = 'additional_info';
export const AVAILABILITY = 'availability';
export const ENGAGEMENT = 'engagement';

// EditListingWizardTab component supports these tabs
export const SUPPORTED_TABS = [
  DESCRIPTION,
  FEATURES,
  PACKAGE,
  EXPERIENCE,
  EDUCATION,
  CERTIFICATION,
  SAMPLEWORK,
  POLICY,
  LOCATION,
  PRICING,
  AVAILABILITY,
  PHOTOS,
  ADDITIONAL_INFO,
  ENGAGEMENT,
];

const pathParamsToNextTab = (params, tab, marketplaceTabs) => {
  const nextTabIndex = marketplaceTabs.findIndex(s => s === tab) + 1;
  const nextTab =
    nextTabIndex < marketplaceTabs.length
      ? marketplaceTabs[nextTabIndex]
      : marketplaceTabs[marketplaceTabs.length - 1];
  return { ...params, tab: nextTab };
};

// When user has update draft listing, he should be redirected to next EditListingWizardTab
const redirectAfterDraftUpdate = (listingId, params, tab, marketplaceTabs, history) => {
  const currentPathParams = {
    ...params,
    type: LISTING_PAGE_PARAM_TYPE_DRAFT,
    id: listingId,
  };
  const routes = routeConfiguration();

  // Replace current "new" path to "draft" path.
  // Browser's back button should lead to editing current draft instead of creating a new one.
  if (params.type === LISTING_PAGE_PARAM_TYPE_NEW) {
    const draftURI = createResourceLocatorString('EditListingPage', routes, currentPathParams, {});
    history.replace(draftURI);
  }

  // Redirect to next tab
  const nextPathParams = pathParamsToNextTab(currentPathParams, tab, marketplaceTabs);
  const to = createResourceLocatorString('EditListingPage', routes, nextPathParams, {});
  history.push(to);
};

const EditListingWizardTab = props => {
  const {
    tab,
    marketplaceTabs,
    handlePayoutModalOpen,
    infoStatusRAI,
    params,
    errors,
    fetchInProgress,
    newListingPublished,
    history,
    images,
    listing,
    handleCreateFlowTabScrolling,
    handlePublishListing,
    onAddAvailabilityException,
    onDeleteAvailabilityException,
    onUpdateListing,
    onCreateListingDraft,
    onImageUpload,
    onUpdateImageOrder,
    onRemoveImage,
    onChange,
    onManageDisableScrolling,
    updatedTab,
    updateInProgress,
    intl,
    fetchExceptionsInProgress,
    availabilityExceptions,
    currentUser,
    completedTabsPer,
  } = props;

  const { type } = params;
  const isNewURI = type === LISTING_PAGE_PARAM_TYPE_NEW;
  const isDraftURI = type === LISTING_PAGE_PARAM_TYPE_DRAFT;
  const isNewListingFlow = isNewURI || isDraftURI;
  const dispatch = useDispatch();

  const currentListing = ensureListing(listing);
  const imageIds = images => {
    if (!images) {
      return null;
    }
  
    const uniqueIds = new Set();
  
    return images.map(img => {
      const id = img.imageId || img.id || img;
      if (!uniqueIds.has(id?.uuid)) {
        uniqueIds.add(id?.uuid);
        return id;
      }
      return null;
    }).filter(id => id !== null);
  };
  const isNotPublish = listing.author && listing.author.attributes && listing.author.attributes.stripeConnected;
  const isListingPublished = currentListing.attributes.state === 'published';
  const currentUserName = getUserDetails(currentUser)?.fullName;
  const firmName = getUserDetails(currentUser)?.firmName;
  
  async function updateWeavaiteDatabase(){
    try{
      const {response, error} = await dispatch(updateUserDetailsInWeaviate({
        currentUser, 
        listingId: listing.id.uuid
      }))
      if(error) throw new Error(error)

    }catch(error){
      dispatch(updateListingError(error));
    }
  }

  const onCompleteEditListingWizardTab = (tab, updateValues, passThrownErrors = false) => {
    // Normalize images for API call
    const { images: updatedImages, noRedirect, ...otherValues } = updateValues;
    const imageProperty =
      typeof updatedImages !== 'undefined' ? { images: imageIds(updatedImages) } : {};
    const updateValuesWithImages = { ...otherValues, ...imageProperty };

    if (isNewListingFlow) {
      const onUpsertListingDraft = isNewURI
        ? (tab, updateValues) => onCreateListingDraft(updateValues)
        : onUpdateListing;

      const upsertValues = isNewURI
        ? updateValuesWithImages
        : { ...updateValuesWithImages, id: currentListing.id };

      return onUpsertListingDraft(tab, upsertValues)
        .then(r => {
          if (tab !== marketplaceTabs[marketplaceTabs.length - 1]) {
            // Create listing flow: smooth scrolling polyfill to scroll to correct tab
            handleCreateFlowTabScrolling(false);

            // After successful saving of draft data, user should be redirected to next tab
            if (!noRedirect) {
              redirectAfterDraftUpdate(r.data.data.id.uuid, params, tab, marketplaceTabs, history);
            }
            // } else if (tab === marketplaceTabs[marketplaceTabs.length - 1] && infoStatusRAI) {
            //   handlePublishListing(currentListing.id);
          } else if (tab === marketplaceTabs[marketplaceTabs.length - 1]) {
            // handlePayoutModalOpen()
            handlePublishListing(currentListing.id);
            if(currentListing?.id && updateValues){
              updateWeavaiteDatabase()
            }
          }
        })
        .catch(e => {
          if (passThrownErrors) {
            throw e;
          }
          // No need for extra actions
          // Error is logged in EditListingPage.duck file.
        });
    } else {
      return onUpdateListing(tab, { ...updateValuesWithImages, id: currentListing.id })
      .then(res => {
        if(isListingPublished && updateValues && ![PHOTOS, ADDITIONAL_INFO].includes(tab)){
          updateWeavaiteDatabase()
        }
      })
    }
  };

  const panelProps = tab => {
    return {
      className: css.panel,
      errors,
      listing,
      onChange,
      panelUpdated: updatedTab === tab,
      updateInProgress,
      onManageDisableScrolling,
      // newListingPublished and fetchInProgress are flags for the last wizard tab
      ready: newListingPublished,
      disabled: fetchInProgress,
      isNewURI,
    };
  };

  switch (tab) {
    case DESCRIPTION: {
      const submitButtonTranslationKey = isNewListingFlow
        ? 'EditListingWizard.saveNewDescription'
        : 'EditListingWizard.saveEditDescription';
      return (
        <EditListingDescriptionPanel
          {...panelProps(DESCRIPTION)}
          submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
          onSubmit={values => {
            onCompleteEditListingWizardTab(tab, values);
          }}
          currentUser={currentUser}
          completedTabsPer={completedTabsPer}
        />
      );
    }
    case FEATURES: {
      const submitButtonTranslationKey = isNewListingFlow
        ? 'EditListingWizard.saveNewFeatures'
        : 'EditListingWizard.saveEditFeatures';
      return (
        <EditListingFeaturesPanel
          {...panelProps(FEATURES)}
          submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
          onSubmit={values => {
            onCompleteEditListingWizardTab(tab, values);
          }}
          completedTabsPer={completedTabsPer}
        />
      );
    }
    case PACKAGE: {
      const submitButtonTranslationKey = isNewListingFlow
        ? 'EditListingWizard.publishListing'
        : 'EditListingWizard.saveEditPackages';
      return (
        <EditListingPackagesPanel
          {...panelProps(PACKAGE)}
          submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
          onSubmit={values => {
            onCompleteEditListingWizardTab(tab, values);
          }}
          onManageDisableScrolling={onManageDisableScrolling}
          completedTabsPer={completedTabsPer}
        />
      );
    }
    case EXPERIENCE: {
      const submitButtonTranslationKey = isNewListingFlow
        ? 'EditListingWizard.saveNewWorkExperience'
        : 'EditListingWizard.saveEditWorkExperience';
      return (
        <EditListingWorkExperiencePanel
          {...panelProps(EXPERIENCE)}
          submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
          onSubmit={values => {
            onCompleteEditListingWizardTab(tab, values);
          }}
          completedTabsPer={completedTabsPer}
        />
      );
    }
    case EDUCATION: {
      const submitButtonTranslationKey = isNewListingFlow
        ? 'EditListingWizard.saveNewEducation'
        : 'EditListingWizard.saveEditEducation';
      return (
        <EditListingEducationPanel
          {...panelProps(EDUCATION)}
          submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
          onSubmit={values => {
            onCompleteEditListingWizardTab(tab, values);
          }}
          completedTabsPer={completedTabsPer}
        />
      );
    }
    case CERTIFICATION: {
      const submitButtonTranslationKey = isNewListingFlow
        ? 'EditListingWizard.saveNewPricing'
        : 'EditListingWizard.saveEditCertification';
      return (
        <EditListingCertificationPanel
          {...panelProps(CERTIFICATION)}
          submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
          onSubmit={values => {
            onCompleteEditListingWizardTab(tab, values);
          }}
          completedTabsPer={completedTabsPer}
        />
      );
    }
    case SAMPLEWORK: {
      const submitButtonTranslationKey = isNewListingFlow
        ? 'EditListingWizard.saveNewSampleWork'
        : 'EditListingWizard.saveEditSampleWork';
      return (
        <EditListingSampleWorkPanel
          {...panelProps(SAMPLEWORK)}
          submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
          onSubmit={values => {
            onCompleteEditListingWizardTab(tab, values);
          }}
        />
      );
    }
    case POLICY: {
      const submitButtonTranslationKey = isNewListingFlow
        ? 'EditListingWizard.saveNewPolicies'
        : 'EditListingWizard.saveEditPolicies';
      return (
        <EditListingPoliciesPanel
          {...panelProps(POLICY)}
          submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
          onSubmit={values => {
            onCompleteEditListingWizardTab(tab, values);
          }}
        />
      );
    }
    case LOCATION: {
      const submitButtonTranslationKey = isNewListingFlow
        ? 'EditListingWizard.saveNewLocation'
        : 'EditListingWizard.saveEditLocation';
      return (
        <EditListingLocationPanel
          {...panelProps(LOCATION)}
          submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
          onSubmit={values => {
            onCompleteEditListingWizardTab(tab, values);
          }}
        />
      );
    }
    case PRICING: {
      const submitButtonTranslationKey = isNewListingFlow
        ? 'EditListingWizard.saveNewImage'
        : 'EditListingWizard.saveEditPricing';
      return (
        <EditListingPricingPanel
          {...panelProps(PRICING)}
          submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
          onSubmit={values => {
            onCompleteEditListingWizardTab(tab, values);
          }}
        />
      );
    }
    case AVAILABILITY: {
      const submitButtonTranslationKey = isNewListingFlow
        ? 'EditListingWizard.saveNewAdditionalInfo'
        : 'EditListingWizard.saveEditAvailability';
      return (
        <EditListingAvailabilityPanel
          {...panelProps(AVAILABILITY)}
          fetchExceptionsInProgress={fetchExceptionsInProgress}
          availabilityExceptions={availabilityExceptions}
          submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
          onAddAvailabilityException={onAddAvailabilityException}
          onDeleteAvailabilityException={onDeleteAvailabilityException}
          onSubmit={values => {
            // We want to return the Promise to the form,
            // so that it doesn't close its modal if an error is thrown.
            return onCompleteEditListingWizardTab(tab, values);
          }}
          onNextTab={() =>
            redirectAfterDraftUpdate(listing.id.uuid, params, tab, marketplaceTabs, history)
          }
          completedTabsPer={completedTabsPer}
        />
      );
    }
    case PHOTOS: {
      const submitButtonTranslationKey =
        isNewListingFlow && !isNotPublish
          ? 'EditListingWizard.publishListing'
          : isNewListingFlow && isNotPublish
          ? 'EditListingWizard.publishListing'
          : 'EditListingWizard.saveEditPartnerPhotos';
      return (
        <EditListingPhotosPanel
          {...panelProps(PHOTOS)}
          submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
          onImageUpload={onImageUpload}
          onRemoveImage={onRemoveImage}
          images={images}
          onSubmit={values => {
            onCompleteEditListingWizardTab(tab, values);
          }}
          onUpdateImageOrder={onUpdateImageOrder}
          completedTabsPer={completedTabsPer}
        />
      );
    }
    case ADDITIONAL_INFO: {
      const submitButtonTranslationKey =
        isNewListingFlow && !isNotPublish
          ? 'EditListingWizard.saveNewAdditionalInfo'
          : isNewListingFlow && isNotPublish
          ? 'EditListingWizard.publishListing'
          : 'EditListingWizard.saveEditPartnerAttachments';
      return (
        <AdditionalInformationPanel
          {...panelProps(ADDITIONAL_INFO)}
          submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
          onImageUpload={onImageUpload}
          onRemoveImage={onRemoveImage}
          images={images}
          onSubmit={values => {
            return onCompleteEditListingWizardTab(tab, values);
          }}
          onUpdateImageOrder={onUpdateImageOrder}
          updateInProgress={updateInProgress}
          completedTabsPer={completedTabsPer}
          isListingPublished={isListingPublished}
          currentUserName={currentUserName}
          onUpdateListing={onUpdateListing}
          firmName={firmName}
        />
      );
    }
    case ENGAGEMENT: {
      const submitButtonTranslationKey = isNewListingFlow
        ? 'EditListingWizard.saveNewEngagement'
        : 'EditListingWizard.saveEditEngagement';
      return (
        <EngagementModelPanel
          {...panelProps(ENGAGEMENT)}
          submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
          onSubmit={values => {
            onCompleteEditListingWizardTab(tab, values);
          }}
          onUpdateImageOrder={onUpdateImageOrder}
          updateInProgress={updateInProgress}
          completedTabsPer={completedTabsPer}
        />
      );
    }
    default:
      return null;
  }
};

EditListingWizardTab.defaultProps = {
  listing: null,
  updatedTab: null,
  availabilityExceptions: [],
};

EditListingWizardTab.propTypes = {
  params: shape({
    id: string.isRequired,
    slug: string.isRequired,
    type: oneOf(LISTING_PAGE_PARAM_TYPES).isRequired,
    tab: oneOf(SUPPORTED_TABS).isRequired,
  }).isRequired,
  availabilityExceptions: arrayOf(propTypes.availabilityException),
  errors: shape({
    createListingDraftError: object,
    publishListingError: object,
    updateListingError: object,
    showListingsError: object,
    uploadImageError: object,
    fetchExceptionsError: object,
    addExceptionError: object,
    deleteExceptionError: object,
  }).isRequired,
  fetchInProgress: bool.isRequired,
  fetchExceptionsInProgress: bool.isRequired,
  newListingPublished: bool.isRequired,
  history: shape({
    push: func.isRequired,
    replace: func.isRequired,
  }).isRequired,
  images: array.isRequired,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: shape({
    attributes: shape({
      publicData: object,
      description: string,
      geolocation: object,
      pricing: object,
      title: string,
    }),
    images: array,
  }),

  handleCreateFlowTabScrolling: func.isRequired,
  handlePublishListing: func.isRequired,
  onAddAvailabilityException: func.isRequired,
  onDeleteAvailabilityException: func.isRequired,
  onUpdateListing: func.isRequired,
  onCreateListingDraft: func.isRequired,
  onImageUpload: func.isRequired,
  onUpdateImageOrder: func.isRequired,
  onRemoveImage: func.isRequired,
  onChange: func.isRequired,
  updatedTab: string,
  updateInProgress: bool.isRequired,

  intl: intlShape.isRequired,
};

export default EditListingWizardTab;
