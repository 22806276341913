import { addMarketplaceEntities } from "../../ducks/marketplaceData.duck";
import { getExpertDetails } from "../../util/api";

// ================ Action types ================ //
export const ADD_EXPERT_FOR_COMPARISON = 'app/ComparisonPage/ADD_EXPERT_FOR_COMPARISON';
export const FETCH_LISTINGS_REQUEST = 'app/ComparisonPage/FETCH_LISTINGS_REQUEST';
export const FETCH_LISTINGS_SUCCESS = 'app/ComparisonPage/FETCH_LISTINGS_SUCCESS';
export const FETCH_EXPERT_DETAILS_SUCCESS = 'app/ComparisonPage/FETCH_EXPERT_DETAILS_SUCCESS';

// ================ Reducer ================ //
const initialState = {
  selectedExpertIds: [],
  selectedExpertListings: [],
  fetchListingsRequest: false,
  fetchListingsSuccess: false,
  currentPageResultIds: [],
  expertDetails: []
};
  
const resultIds = data => data.map(l => l.id);

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case ADD_EXPERT_FOR_COMPARISON:
      return { ...state, selectedExpertIds: payload.ids,  selectedExpertListings: payload.listings};
    case FETCH_LISTINGS_REQUEST:
      return { ...state, fetchListingsRequest: true,  fetchListingsSuccess: false};
    case FETCH_EXPERT_DETAILS_SUCCESS:
      return { ...state, expertDetails: payload};
    case FETCH_LISTINGS_SUCCESS:
      return { ...state, 
        fetchListingsRequest: false,  
        fetchListingsSuccess: true,
        currentPageResultIds: resultIds(payload), 
      };

    default: return state;
  }
}

// ================ Action creators ================ //
export const selectExpert = (data) => ({
  type: ADD_EXPERT_FOR_COMPARISON,
  payload: data
})

export const fetchListingsRequest = () => ({
  type: FETCH_LISTINGS_REQUEST
})

export const fetchListingsSuccess = (data) => ({
  type: FETCH_LISTINGS_SUCCESS,
  payload: data
})

export const fetchExpertDetailsSuccess = (data) => ({
  type: FETCH_EXPERT_DETAILS_SUCCESS,
  payload: data
})

export const getExpertListings = idsParams => async (dispatch, getState, sdk) => {
  dispatch(fetchListingsRequest())
  try{
    const response = await sdk.listings.query({
      ids: idsParams,
      include: ['author', 'author.profileImage', 'images'],
        'fields.listing': ['title', 'geolocation', 'price', 'description', 'publicData'],
        'fields.user': ['profile.displayName', 'profile.abbreviatedName', 'profile.publicData'],
        'fields.image': ['variants.landscape-crop', 'variants.landscape-crop2x', 'variants.square-small',
        'variants.square-small2x'],
        'limit.images': 1,
    })
    dispatch(addMarketplaceEntities(response));
    dispatch(fetchListingsSuccess(response.data.data))
  }catch(error){
    // console.log(error)
    throw error;
  }
};

//To Fetch Expert Details from Database
const getDetails = (ids) => async (dispatch) => {
  let expertDetails = [];

  ids?.length && ids?.map(async id => {
    const {response} = await getExpertDetails({expertId: id})
    const param = {id, details: response}
    expertDetails.push(param)
    dispatch(fetchExpertDetailsSuccess(expertDetails))
  })
}

//LoadData
export const loadData = params => async (dispatch, getState, sdk) => {
  const ids = params.id.split('_').join(',')
  dispatch(getExpertListings(ids))
  dispatch(getDetails(params.id.split('_')))
};