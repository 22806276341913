import React, { useState } from 'react';
import Modal from '../Modal/Modal';
import { manageDisableScrolling } from '../../ducks/UI.duck';
import css from './OpenAIReportSuccessModal.module.css'
import { FormattedMessage } from 'react-intl';
import { AI_TRANSCRIPTION } from '../../util/types';

const OpenAIReportSuccessModal = props => {
  const { openSuccessModal, setOpenSuccessModal, history, dispatch, isGigAppTransactionPage,appRoute } = props || {};
  return (
    <>
      <Modal
        id="openQueryReportModal"
        className={css.disableModalBorder}
        contentClassName={css.containerClassName}
        isOpen={openSuccessModal}
        onClose={() => {
          setOpenSuccessModal(false);
          !isGigAppTransactionPage && history.goBack();
        }}
        usePortal
        onManageDisableScrolling={(componentId, disableScrolling) =>
          dispatch(manageDisableScrolling(componentId, disableScrolling))
        }
      >
        <div className={css.successModalContainer}>
          <h2>
            <FormattedMessage id="OpenAiAppsPanel.successModalHeading" />
          </h2>
          <p>
            {appRoute === AI_TRANSCRIPTION ? (
              <FormattedMessage id="OpenAiAppsPanel.successModalMessageForTranscription" />
            ) : (
              <FormattedMessage
                id="OpenAiAppsPanel.successModalMessage"
                values={{ time: `1 hour` }}
              />
            )}
          </p>
        </div>
      </Modal>
    </>
  );
};

export default OpenAIReportSuccessModal;
