import React, { useEffect, useState } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import Button from '../Button/Button'
import css from './CsmSupportBanner.module.css'
import moment from 'moment'
import csmProfile from '../../assets/csm-profile.png';
import Modal from '../Modal/Modal'
import { manageDisableScrolling } from '../../ducks/UI.duck'
import { useDispatch, useSelector } from 'react-redux'
import { sendEmailUsingZepto } from '../../util/api'
import { CSM_BANNER_PAGE_NOTIFICATION } from '../../util/types'
import { getUserDetails } from '../../util/destructorHelpers'
import { getUserRole } from '../../util/userRole'
import { compose } from 'redux'

const CsmSupportBanner = (props) => {
  const { csmBannerTime , isLandingPage, isFirmDashboardPage, isGigAppsPage, intl} = props;
  const { landingPageTime = null, gigAppsPageTime = null, firmPageTime = null } = csmBannerTime || {};
  const [isVisible, setIsVisible] = useState(false);
  const [isScheduleACallModalOpen, setIsScheduleACallModalOpen] = useState(false);
  const { currentUser = {} } = useSelector(state => state.user);
  const userName = currentUser?.id && getUserDetails(currentUser).fullName
  const userEmail = currentUser?.id && getUserDetails(currentUser).email
  const userRole = currentUser?.id && getUserRole(currentUser);

  const dispatch = useDispatch()

  useEffect(() => {
    if(isLandingPage) setIsVisible(moment().unix() < landingPageTime)
    else if(isGigAppsPage) setIsVisible(moment().unix() < gigAppsPageTime)
    else if(isFirmDashboardPage) setIsVisible(moment().unix() < firmPageTime)

  }, [landingPageTime, gigAppsPageTime, gigAppsPageTime]);

  const handleScheduleACallModal = () => {
    setIsScheduleACallModalOpen(!isScheduleACallModalOpen)
  }

  const getCardData = () => {
    switch (true) {
      case isLandingPage:
        return intl.formatMessage({ id: "CsmSupportBanner.adminEmailLandingPageCardData" });
  
      case isFirmDashboardPage:
        return intl.formatMessage({ id: "CsmSupportBanner.adminEmailFirmDashboardPageCardData" });
  
      case isGigAppsPage:
        return intl.formatMessage({ id: "CsmSupportBanner.adminEmailGigAppsPageCardData" });
  
      default:
        return null;
    }
  };

  const handleScheduleACall = async () => {
    const csmBannerScheduleACallObject = {
      step: CSM_BANNER_PAGE_NOTIFICATION,
      userEmail,
      userName,
      cardData: getCardData()
    }
    
    const response = await sendEmailUsingZepto(csmBannerScheduleACallObject)
    if (response.message === 'Success') {
      setIsScheduleACallModalOpen(false)
    }
  }

  const  renderSupportBannerHeadingAndDescription = () => {
    switch (true) {
      case isLandingPage:
        return (
          <>
            <h2>
              <FormattedMessage id="CsmSupportBanner.adminEmailLandingPageHeading" />
            </h2>
            <p>
              <FormattedMessage id="CsmSupportBanner.adminEmailLandingPageDescription" />
            </p>
          </>
        );
  
      case isFirmDashboardPage:
        return (
          <>
            <h2>
              <FormattedMessage id="CsmSupportBanner.adminEmailFirmDashboardPageHeading" />
            </h2>
            <p>
              <FormattedMessage id="CsmSupportBanner.adminEmailFirmDashboardPagDescription" />
            </p>
          </>
        );
  
      case isGigAppsPage:
        return (
          <>
            <h2>
              <FormattedMessage id="CsmSupportBanner.adminEmailGigAppsPageHeading" />
            </h2>
            <p>
              <FormattedMessage id="CsmSupportBanner.adminEmailGigAppsPageDescription" />
            </p>
          </>
        );
  
      default:
        return null;
    }
  }

  if(!isVisible) return null
  
  return (
    <div className={css.supportBanner}>
      <div className={css.supportBannerWrapper}>
        <div className={css.profileIcon}>
          <img src={csmProfile} />
        </div>
        {renderSupportBannerHeadingAndDescription()}
        <Button onClick={handleScheduleACallModal}>
          <FormattedMessage id="LandingPage.scheduleACallButtonText" />
        </Button>
      </div>
      <Modal
        id="adminEmailModal"
        className={css.disableModalBorder}
        contentClassName={css.containerClassName}
        isOpen={isScheduleACallModalOpen}
        onClose={() => setIsScheduleACallModalOpen(false)}
        usePortal
        onManageDisableScrolling={(componentId, disableScrolling) =>
          dispatch(manageDisableScrolling(componentId, disableScrolling))
        }
        isAdminEmailModal={true}
      >
        <h2 className={css.emailModalHeading}>
          <FormattedMessage id="LandingPageTabsPanel.adminEmailModalHeading" />
        </h2>

        <div className={css.emailModalActionButtons}>
          <button onClick={() => setIsScheduleACallModalOpen(false)}>
            <FormattedMessage id="LandingPageTabsPanel.adminEmailModalClostButtonText" />
          </button>
          <Button
            onClick={() => handleScheduleACall()}
          >
            <FormattedMessage id="LandingPageTabsPanel.adminEmailModalSenEmailButtonText" />
          </Button>
        </div>
      </Modal>
      </div>
  )
}

export default compose(injectIntl)(CsmSupportBanner)