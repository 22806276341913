import React, { useMemo } from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { Modal } from '../../components';
import {ModalProposalForm} from '../../forms';
import css from './ModalProposal.module.css';

const ModalProposal = ({
  id,
  isOpen,
  onClose,
  onManageDisableScrolling,
  onSubmit,
  inProgress,
  listingId,
  documents,
  proposalData
}) => {
  
  function getProposalInitialValues() {
    if (!proposalData) {
      return {};
    }
    const {
      attachmentsUrls,
      reason,
      scopeOfWork,
      methodology,
      paymentFee: { amount, currency },
      paymentTerms,
      startDate,
      deadline,
    } = proposalData;
    function handleMoneyFormat(value, key) {
      const modifiedValue = (value/100).toLocaleString("en-US")
      if (Number.isInteger(value)) return modifiedValue
    }
    let formattedAmount = handleMoneyFormat(amount, 'amount')
    
    return {
      attachmentsUrls,
      reason,
      scopeOfWork,
      methodology,
      paymentTerms,
      startDate: { date: new Date(startDate) },
      deadline: { date: new Date(deadline) },
      currency,
      amount: formattedAmount
    };
  }

  return (
    <Modal
      id={id}
      containerClassName={css.modalProposal}
      isOpen={isOpen}
      onClose={onClose}
      usePortal
      onManageDisableScrolling={onManageDisableScrolling}
    >
      <h2 className={css.title}>
        <FormattedMessage id="ModalProposalForm.title"/>
      </h2>

      {isOpen && (
        <ModalProposalForm
         onSubmit={onSubmit}
         onClose={onClose}
         inProgress={inProgress}
         initialValues={useMemo(getProposalInitialValues,[proposalData])}
         listingId={listingId}
         documents={documents}
       />
      )}
    </Modal>
  );
}


export default ModalProposal;
