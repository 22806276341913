import React from 'react';
import css from './GigAppsPanel.module.css'
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

export const filters = [
  {key: 'stateOfResearch', label:'Stage of Research', 
    items: [
      {key: 'ideaGeneration', label: 'Idea generation'},
      {key: 'briefCreation', label: 'Brief (Requirement) creation'},
      {key: 'vendorSelection', label: 'Vendor selection'},
      {key: 'proposalDevelopent', label: 'Proposal development'},
      {key: 'contracting', label: 'Contracting'},
      {key: 'preFieldwork', label: 'Pre-fieldwork'},
      {key: 'field', label: 'Fieldwork'},
      {key: 'dataProcessing', label: 'Data processing'},
      {key: 'analysis', label: 'Analysis'},
      {key: 'reporting', label: 'Reporting'},
      {key: 'projectCompleted', label: 'Project completed'},
      {key: 'revisitProject', label: 'Revisit a project'},
    ]
  },
  {key: 'stageOfRecruitment', label:'Stage of Recruitment', 
    items: [
      {key: 'jobDescriptionDevelopment', label: 'Job description development'},
      {key: 'resumeCreation', label: 'Resume creation'},
      {key: 'caseStudyDevelopment', label: 'Case study development'},
      // {key: 'contracting', label: 'Contracting'},
    ]
  },
]

const FilterComponent = (props) => {
  const { 
    isModalClass, 
    closeFilter, 
    isFilterOpen, 
    setSelectedFilter,
    selectedFilter,
  } = props;

  function handleSelectFilter(e, item){
    if(e.target.checked) setSelectedFilter([...selectedFilter, item])
    else {
      const modifiedSelectedFilters = [...selectedFilter]?.filter(filter => filter.key !== e.target.name)
      setSelectedFilter(modifiedSelectedFilters)
    }
  }

  return (
    <div className={classNames(css.filterComponent, isModalClass && css.hideFilterComponent)}>
      <div>
        {filters.map(filter => (
          <div key={filter.key} className={css.filterGroup}> 
            <div className={css.filterGroupLabel}>{filter.label}</div>
            {filter.items.map(item => {
              const isSelected = selectedFilter.map(filter => filter.key)?.includes(item.key)
              return(
                <div key={item.key} className={css.filterItem}>
                  <input 
                    type='checkbox' 
                    id={item.key} 
                    name={item.key} 
                    checked={isSelected}
                    onChange={(e) => handleSelectFilter(e, item)}
                  />
                  <label htmlFor={item.key}>{item.label}</label>
                </div>
              )
            })}
          </div>
        ))}
      </div>
      {isFilterOpen && (
        <div className={css.bottomWrapper}>
          <span onClick={() => setSelectedFilter([])}>
            <FormattedMessage id='InboxFilter.clearButtonText' />
          </span>
          <span onClick={() => closeFilter()}>
            <FormattedMessage id='InboxFilter.cancelButtonText' />
          </span>
          <button 
            className={classNames(css.applyButton)}
            onClick={() => closeFilter()}
          >
            <FormattedMessage id='InboxFilter.applyButtonText' />
          </button>
        </div>
      )}
    </div>
  );
};

export default FilterComponent;
