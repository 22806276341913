import React, { useEffect, useState } from 'react';
import { bool, func } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import arrayMutators from 'final-form-arrays';
// These relative imports need to point to correct directories
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import { Form, FieldTextInput, PrimaryButton, SecondaryButton } from '../../components';
import * as validators from '../../util/validators';
import { debounce } from 'lodash';
// Create this file using EditListingFeaturesForm.module.css
// as a template.
import css from './InviteFirmMemberForm.module.css';
import { useDispatch } from 'react-redux';
import { fetchFirmMemberDetails } from '../../containers/FirmPage/FirmPage.duck';
import { withRouter } from 'react-router-dom';
import {
  LISTING_TYPE_FIRM_PATHNAME,
  SMALL_TEAMS_MONTHLY,
  SMALL_TEAMS_YEARLY,
  USER_ROLE_PARTNER,
} from '../../util/types';
import { USER_ROLE_CLIENT } from '../../util/types';
import { getUserDetails, userPlanName } from '../../util/destructorHelpers';

export const InviteFirmMemberForm = props => {
  return (
    <FinalForm
      {...props}
      mutators={{
        ...arrayMutators,
      }}
      render={formRenderProps => {
        const {
          className,
          handleSubmit,
          intl,
          pristine,
          updateError,
          updateInProgress,
          values,
          isOpen,
          form,
          onCloseModal,
          firmData,
          authorEmail,
          authorType,
          collaborationMetaData,
          isCustomer,
          location,
          currentUser,
          currentFirmAuthor,
          isExpert,
        } = formRenderProps;
        const currentUserEmail = !!currentUser?.id && getUserDetails(currentUser)?.email;
        const userPlan =
          !!currentUser?.id &&
          userPlanName(currentUser)?.some(item =>
            [SMALL_TEAMS_MONTHLY, SMALL_TEAMS_YEARLY]?.includes(item)
          );
        const isFirmPage = location.pathname.includes(LISTING_TYPE_FIRM_PATHNAME);
        const firmAuthorEmail = currentFirmAuthor && getUserDetails(currentFirmAuthor).email;

        const checkEmail = /^[a-z0-9][a-z0-9-_\.A-Z]+@([a-z]|[a-z0-9]?[a-z0-9-]+[a-z0-9])\.[a-z0-9]{2,10}(?:\.[a-z]{2,10})?$/i.test(
          values?.userEmail
        );

        const isFirmMember =
          (Array.isArray(firmData) &&
            !!firmData?.find(user => user?.email === values?.userEmail)) ||
          firmAuthorEmail === values?.userEmail;

        const isUserAlreadyExist =
          Array.isArray(collaborationMetaData) &&
          !!collaborationMetaData?.find(user => user.email === values?.userEmail);

        const [isConnectedToFirm, setIsConnectedToFirm] = useState(false);
        const [isOwnFirm, setIsOwnFirm] = useState(false);
        const [userType, setUserType] = useState(false);
        const dispatch = useDispatch();
        const emailDomain = authorEmail && authorEmail?.split('@')[1];
        const [isRegisteredUser, setIsRegisteredUser] = useState();

        const handleFirmMemberDetails = async () => {
          if (checkEmail) {
            const result = await dispatch(fetchFirmMemberDetails(values?.userEmail));
            form.change('memberRole',result?.userRole)
            Array.isArray(collaborationMetaData) && collaborationMetaData?.length
              ? setIsConnectedToFirm(false)
              : isCustomer &&
                firmData?.length &&
                firmData.find(data => data?.email === result?.email)
              ? setIsConnectedToFirm(false)
              : setIsConnectedToFirm(result?.isConnectedToFirm);
            setUserType(result?.userRole);
            setIsOwnFirm(result?.isOwnFirm);
            setIsRegisteredUser(result?.email ? true : false);
          }
        };

        useEffect(() => {
          if (!isOpen) {
            form.reset();
          }
        }, [!isOpen]);

        const errorMessage = updateError ? (
          <p className={css.error}>
            <FormattedMessage id="InviteFirmMemberForm.updateFailed" />
          </p>
        ) : null;

        const classes = classNames(css.root, className);
        const submitInProgress = updateInProgress;
        const isUserEmailSameAsAuthor = values?.userEmail === authorEmail;
        const isFirmPageAndNotFirmMember = isFirmPage && !isFirmMember;
        const desiredDomain = 'insightgig.com';
        const isDesiredDomainMatch = values?.userEmail?.endsWith('@' + desiredDomain);
        const isUserTypeMismatch = !!userType && authorType !== userType && !isDesiredDomainMatch;
        const isFirmPageAndOwnFirm = isFirmPage && isOwnFirm;
        const isUserAlreadyExisting = isUserAlreadyExist || !checkEmail;
        const isInvalidFirmDomain =
          isFirmPage &&
          values?.userEmail?.split('@')[1] !== emailDomain &&
          !isDesiredDomainMatch &&
          !isExpert;
        const isExpertAndDesiredDomainMatch = isDesiredDomainMatch && isExpert;
        const submitDisabled =
          pristine ||
          submitInProgress ||
          firmData?.length > 10 ||
          isUserEmailSameAsAuthor ||
          (isCustomer && isFirmPageAndNotFirmMember && isConnectedToFirm) ||
          isUserTypeMismatch ||
          (!isFirmPage && !isFirmMember && isCustomer) ||
          isFirmPageAndOwnFirm ||
          isUserAlreadyExisting ||
          isInvalidFirmDomain ||
          (isFirmPage && isFirmMember) ||
          currentUserEmail === values?.userEmail ||
          isExpertAndDesiredDomainMatch ||  (!isRegisteredUser && authorType === USER_ROLE_PARTNER && !isFirmPage);

        // email label
        const emailLabel = intl.formatMessage({
          id: 'InviteFirmMemberForm.emailLabel',
        });

        // email placeholder
        const emailPlaceholder = intl.formatMessage({
          id: 'InviteFirmMemberForm.emailPlaceholder',
        });

        // emailRequired Message
        const emailRequiredMessage = intl.formatMessage({
          id: 'InviteFirmMemberForm.emailRequiredMessage',
        });
        const emailRequired = validators.required(emailRequiredMessage);
        const emailInvalidMessage = intl.formatMessage({
          id: 'InviteFirmMemberForm.emailInvalid',
        });
        const emailValid = validators.emailFormatValid(emailInvalidMessage);

        const userAlreadyLinkedToFirm = intl.formatMessage({
          id: 'InviteFirmMemberForm.userAlreadyLinkedToFirmErrorMessage',
        });
        const selfInviteErrorMessage = intl.formatMessage({
          id: 'InviteFirmMemberForm.selfInviteErrorMessage',
        });
        const onlyFirmMemberAllowMessage = intl.formatMessage({
          id: 'InviteFirmMemberForm.onlyFirmMemberAllowMessage',
        });
        const userRoleErrorMessage = isCustomer
          ? intl.formatMessage({
              id: 'InviteFirmMemberForm.userRoleErrorMessageForClient',
            })
          : intl.formatMessage({
              id: 'InviteFirmMemberForm.userRoleErrorMessageForProvider',
            });
        const ownFirmErrorMessage = intl.formatMessage({
          id: 'InviteFirmMemberForm.ownFirmErrorMessage',
        });

        const unRegisteredErrorMessage = intl.formatMessage({
          id: 'InviteFirmMemberForm.unregisteredUser',
        });
        const invalidDomainErrorMessage = intl.formatMessage({
          id: 'InviteFirmMemberForm.invalidDomain',
        });
        const userAlreadyExistMessage = intl.formatMessage({
          id: 'InviteFirmMemberForm.userAlreadyExistMessage',
        });
        const isExpertAndDesiredDomainMatchErrorMessage = intl.formatMessage({
          id: 'InviteFirmMemberForm.isExpertAndDesiredDomainMatchErrorMessage',
        });
        const checkErrorStatus = () => {
          const checkDomain = !isFirmPage
            ? authorType === USER_ROLE_CLIENT && values?.userEmail?.split('@')[1] !== emailDomain
            : values?.userEmail?.split('@')[1] !== emailDomain;

          if (submitInProgress) {
            return;
          }
          if (!isFirmPage && isCustomer && !isFirmMember) {
            return <p className={css.userRoleErrorMessage}>{onlyFirmMemberAllowMessage}</p>;
          } else if (isFirmPage ? isFirmMember : isUserAlreadyExist) {
            return <p className={css.userRoleErrorMessage}>{userAlreadyExistMessage}</p>;
          } else if (checkDomain && !isDesiredDomainMatch && !isExpert) {
            return <p className={css.userRoleErrorMessage}>{invalidDomainErrorMessage}</p>;
          } else if (!isRegisteredUser && !isExpertAndDesiredDomainMatch) {
            return <p className={css.userRoleErrorMessage}>{unRegisteredErrorMessage}</p>;
          } else if (userType && userType !== authorType && !isDesiredDomainMatch) {
            return <p className={css.userRoleErrorMessage}>{userRoleErrorMessage}</p>;
          } else if (isFirmPage && isOwnFirm) {
            return <p className={css.userRoleErrorMessage}>{ownFirmErrorMessage}</p>;
          } else if (isExpertAndDesiredDomainMatch) {
            return (
              <p className={css.userRoleErrorMessage}>
                {isExpertAndDesiredDomainMatchErrorMessage}
              </p>
            );
          }
        };

        return (
          <Form className={classes} onSubmit={e => e.preventDefault()}>
            <h3 className={css.modalHeadingName}>
              {isFirmPage ? (
                <FormattedMessage id="InviteFirmMemberForm.firmPageHeading" />
              ) : (
                <FormattedMessage id="InviteFirmMemberForm.projectPageHeading" />
              )}
            </h3>

            {!isFirmPage &&
            !isFirmMember &&
            userType &&
            userType !== USER_ROLE_PARTNER &&
            isConnectedToFirm &&
            checkEmail ? (
              <p className={css.userAlreadyConnectedToFirmErrorMessage}>
                {userAlreadyLinkedToFirm}
              </p>
            ) : null}
            {checkEmail && checkErrorStatus()}
            {currentUserEmail === values?.userEmail ? (
              <p className={css.userAlreadyConnectedToFirmErrorMessage}>{selfInviteErrorMessage}</p>
            ) : null}
            <FieldTextInput
              className={css.inputBox}
              type="email"
              id="userEmail"
              name="userEmail"
              label={emailLabel}
              placeholder={emailPlaceholder}
              validate={validators.composeValidators(emailRequired, emailValid)}
              onKeyUp={() => handleFirmMemberDetails()}
            />
            <div className={css.bottomWrapper}>
              <SecondaryButton type="button" onClick={onCloseModal} className={css.secondaryButton}>
                <FormattedMessage id="InviteFirmMemberForm.cancelInvitationButton" />
              </SecondaryButton>
              <PrimaryButton
                type="button"
                inProgress={submitInProgress}
                disabled={submitDisabled}
                className={css.primaryButton}
                onClick={handleSubmit}
              >
                <FormattedMessage id="InviteFirmMemberForm.sendInvitationButton" />
              </PrimaryButton>
            </div>
          </Form>
        );
      }}
    />
  );
};

InviteFirmMemberForm.defaultProps = {
  selectedPlace: null,
  updateError: null,
};

InviteFirmMemberForm.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  updateInProgress: bool.isRequired,
};

export default compose(injectIntl, withRouter)(InviteFirmMemberForm);
