import { FormattedMessage } from "react-intl";
import { IconSpinner, ReviewRating } from "../../components";
import css from './ListingPage.module.css';

const WorkRatingComponent = (props) => {
  const {
    totalExperience,
    fetchTransactionInProgress,
    projectCompletionRate,
    totalProjects,
    reviewsAverageRating,
    reviews,
    isBrief,
  } = props;

  return (
    <div className={css.reviewsSubHeading}>
      {/* <div className={css.averageRating}>
        <span><FormattedMessage id="BookingPanel.totalWorkExperience" /></span>
        <span>{totalExperience}</span>
      </div> */}
      {!isBrief && (
        <div className={css.averageRating}>
          <span className={css.ratingHeading}>
            <FormattedMessage id="BookingPanel.projectComplete" />
          </span>
          <span>
            <span className={css.reviewTotalCount}>
              {fetchTransactionInProgress ? (
                <IconSpinner />
              ) : isNaN(projectCompletionRate) ? (
                0
              ) : (
                Math.round(projectCompletionRate)
              )}
              %
            </span>
            <span className={css.reviewTotalInfo}>
              {fetchTransactionInProgress ? (
                <IconSpinner />
              ) : isNaN(totalProjects) ? (
                0
              ) : (
                // `(${totalProjects} projects)`
                `(${totalProjects})`
              )}
            </span>
          </span>
        </div>
      )}
      <div className={css.averageRating}>
        <span className={css.ratingHeading}>
          <FormattedMessage id="BookingPanel.review" />
        </span>
        <span className={css.ratingRow}>
          <ReviewRating
            rating={1}
            stars={[1]}
            reviewStarClassName={css.reviewStar}
            className={css.reviewStars}
          />
          <span className={css.reviewTotalCount}>{reviewsAverageRating.toFixed(1)}</span>
          <span className={css.reviewTotalInfo}>
            {`(${reviews ? reviews.length : 0})`}
            {/* <FormattedMessage
              className={css.reviewTotalInfo}
              id="ListingPage.reviewsLabel"
              values={{ count: reviews && reviews.length }}
            /> */}
          </span>
        </span>
      </div>
    </div>
  )
}

export default WorkRatingComponent