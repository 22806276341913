import React, { useState } from 'react';
import { func } from 'prop-types';
import { compose } from 'redux';
import { Field, Form as FinalForm } from 'react-final-form';
// These relative imports need to point to correct directories
import {
  intlShape,
  injectIntl,
  FormattedMessage,
} from '../../util/reactIntl';
import { Form, FieldTextInput, PrimaryButton, SecondaryButton, FieldSelect, Modal, IconCard, CaptureRunNameField } from '../../components';
import css from './OpenAITextPreProcessingForm.module.css';
import FileView from '../../components/FileView/FileView';
import * as validators from '../../util/validators';
import { AI_KEYWORD_EXTRACTION, AI_SENTIMENT_ANALYSIS } from '../../util/types';
import axios from 'axios';
import useHandleFileExtensionError from '../../hooks/useHandleFileExtensionError';
import classNames from 'classnames';

const ACCEPT_FILE = '.csv,.xls,.xlsx';
const FILE_SIZE_LIMIT = 25*1000*1000;
const FILE_EXTENSION_ERROR_MESSAGE = 'Please select files in CSV, XLS, or XLSX format only.';

export const OpenAITextPreProcessingForm = props => {
  return (
    <FinalForm
      {...props}
      render={formRenderProps => {
        const {
          disabled,
          handleSubmit,
          intl,
          invalid,
          values,
          form,
          manageDisableScrolling,
          handleRedirectToGigAppsPage,
          appRoute,
          appTitle,
          openaiRequest,
        } = formRenderProps;

        const [error, setError]= useState('');
        const [fileExtensionError, checkForFileExtension] = useHandleFileExtensionError();
        const emailRequiredMessage = intl.formatMessage({ id: 'ConfirmSignupForm.emailRequired'});
        const emailRequired = validators.required(emailRequiredMessage);
        const emailInvalidMessage = intl.formatMessage({ id: 'ConfirmSignupForm.emailInvalid'});
        const emailValid = validators.emailFormatValid(emailInvalidMessage);

        const submitDisabled = !values.file;
        const [openAlertModal, setOpenAlertModal] = useState(false);
        const isKeywordApp = appRoute === AI_KEYWORD_EXTRACTION;
        const isSentimentAnalysisApp = appRoute === AI_SENTIMENT_ANALYSIS;

        const {openSuccessModal = false, step = 1} = values || {};
        const setOpenSuccessModal = (value) => {
          form.change('openSuccessModal', value)
        }

        function handleError(errorMessage){
          setError(errorMessage)
          setTimeout(() => setError(''), 3000)
        }

        async function handleAttachment(file) {
          const formData = new FormData();
          formData.append('file', file);
          
          const response = await axios.post(`${process.env.REACT_APP_DJANGO_BACKEND_API}/check`, formData, 
            {
              headers: { 'Content-Type': 'multipart/form-data'}
            }
          );
          
          if(response.status === 200){
            const output = response.data.response;
            if(isSentimentAnalysisApp && Number(output?.columnsNum ?? 0) < 3){
              handleError('Incorrect file format. Please upload file with ID, rating, and qualitative comment column in that order. ')
              return
            }
            if(typeof output === 'object' && Object.values(output)?.includes('true')){
              setOpenAlertModal(true)
              form.change('output', output)
            }
            form.change('file', file)
          }
        }

        return (
          <Form onSubmit={handleSubmit}>
            <div className={css.briefGeneratorForm}>
              <div className={css.container}>
                <div className={css.briefGenerator}>
                  <span className={css.generatorTitle}>{appTitle}</span>
                </div>
                {/* <div className={css.textareaBox}>
                  <label><FormattedMessage id='OpenAITextPreProcessingForm.emailLabel' /></label>
                  <FieldTextInput
                    className={css.inputBox}
                    type="textarea"
                    id='email'
                    name="email"
                    autoComplete="given-name"
                    validate={validators.composeValidators(emailRequired, emailValid)}
                  />
                </div> */}
                <CaptureRunNameField step={step} form={form} values={values}>
                  <div className={css.attachmentRow}>
                    <label><FormattedMessage id="OpenAITextPreProcessingForm.attachmentLabel" /></label>
                    <div className={css.attachment}>
                      {!!values.file ? (
                        <FileView file={values.file}>
                          <span className={css.close} onClick={() => form.change('file', null)}>
                            <IconCard brand='cross'/>
                          </span>
                        </FileView>
                      ) : (
                        <>
                          <Field
                            id="file"
                            name="file"
                            accept={ACCEPT_FILE}
                            type="file"
                          >
                            {fieldprops => {
                              const { accept, input, disabled: fieldDisabled } = fieldprops;
                              const { name, type } = input;
                              const onChange = e => {
                                const file = e.target.files[0];
                              
                                const allowedExtensions = /(\.csv|\.xls|\.xlsx)$/i;
                                let extensionError = checkForFileExtension(allowedExtensions, file, FILE_EXTENSION_ERROR_MESSAGE)
                                if(extensionError) return
                                if (file && file.name && (file.size < FILE_SIZE_LIMIT)) {
                                  handleAttachment(file)
                                  form.change('gigAppInputFiles',file)
                                }
                              };

                              const inputProps = { accept, id: name, name, onChange, type };
                              return <input {...inputProps} className={css.addImageInput} />
                            }}
                          </Field>
                          <label htmlFor="file" className={css.inputLabel}>
                            <IconCard brand="upload" />
                            <span className={css.dragDrop}><FormattedMessage id="ZohoSign.dragAndDropUpload" /></span>
                            <span className={css.docType}>
                              <FormattedMessage id="OpenAITextPreProcessingForm.supportedFilesType" />
                            </span>
                          </label>
                          {fileExtensionError && <p className={css.errorMessage}>
                            {fileExtensionError}
                          </p>}
                          {error && <p className={css.errorMessage}>{error}</p>}
                        </>
                      )}
                    </div>
                  </div>
                  {isKeywordApp && (
                    <div>
                      <label><FormattedMessage id='OpenAITextPreProcessingForm.keywordNumberLabel' /></label>
                      <FieldTextInput
                        className={css.rangeInput}
                        id="keywordRange"
                        name="keywordRange"
                        type="number"
                        placeholder='5-20'
                        onChange={e => {
                          const input = e.target.value;
                          form.change('keywordRange', input)
                        }}
                        onBlur={e => {
                          const input = e.target.value;
                          if(input > 20) form.change('keywordRange', 20)
                          else if(input < 5) form.change('keywordRange', 5)
                        }}
                      />
                    </div>
                  )}
                  <div className={css.actionButtons}>
                    <SecondaryButton
                      type='button'
                      className={css.resetButton}
                      onClick={() => {
                        form.reset()
                      }}
                      disabled={openaiRequest}
                    >
                      <FormattedMessage id='OpenAIContractForm.resetButtonText' />
                    </SecondaryButton>
                    <PrimaryButton
                      type='submit'
                      onClick={(e)=>{
                        if(!values.file){
                          e.preventDefault()
                          handleError("Please choose a file first")
                        }
                      }}
                      className={classNames(submitDisabled ? css.disabledSubmitButton : css.submitButton )}
                      inProgress={openaiRequest}
                    >
                      <FormattedMessage id='OpenAIContractForm.submitButtonText' />
                    </PrimaryButton>
                  </div>
                </CaptureRunNameField>
              </div>
            </div>
            <Modal
							id="openAlertIndicator"
							className={css.disableModalBorder}
							contentClassName={css.containerClassName}
							isOpen={openAlertModal}
							onClose={() => setOpenAlertModal(false)}
							usePortal
							onManageDisableScrolling={manageDisableScrolling}
						>
							<div className={css.alertModal}>
                <h2>Warning!</h2>
                {values?.output?.isNotUnique === 'true' && (          
                  <p><FormattedMessage id='OpenAITextPreProcessingForm.uniqueRowsWarning' /></p>
                )}
                {values?.output?.hasNotMinimumRows === 'true' && (
                  <p><FormattedMessage id='OpenAITextPreProcessingForm.lessRowsWarning' /></p>
                )}
                {values?.output?.hasBlankContent === 'true' && (
								  <p><FormattedMessage id='OpenAITextPreProcessingForm.blankContentWarning' /></p>
                )}
                {values?.output?.hasBlankRating === 'true' && (
								  <p><FormattedMessage id='OpenAITextPreProcessingForm.blankRatingWarning' /></p>
                )}
							</div>
              <div className={css.actionButtons}>
                <SecondaryButton
                  type='button'
                  className={css.resetButton}
                  onClick={() => {
                    setOpenAlertModal(false)
                    form.reset()
                  }}
                >
                  <FormattedMessage id='OpenAITextPreProcessingForm.cancelButtonText' />
                </SecondaryButton>
                <PrimaryButton
                  type='button'
                  className={css.submitButton}
                  onClick={() => setOpenAlertModal(false)}
                >
                  <FormattedMessage id='OpenAITextPreProcessingForm.continueButtonText' />
                </PrimaryButton>
              </div>
						</Modal>
            <Modal
							id="openSuccessModal"
							className={css.disableModalBorder}
							contentClassName={css.containerClassName}
							isOpen={openSuccessModal}
							onClose={() => {
                setOpenSuccessModal(false)
                handleRedirectToGigAppsPage()
              }}
							usePortal
							onManageDisableScrolling={manageDisableScrolling}
						>
							<div className={css.alertModal}>
                <h2>
                  <FormattedMessage id="OpenAiAppsPanel.successModalHeading" />
                </h2>
                <p><FormattedMessage id='OpenAITextPreProcessingForm.successMessage' /></p>
                <PrimaryButton
                  type='button'
                  className={css.closeButton}
                  onClick={() => {
                    setOpenSuccessModal(false)
                    handleRedirectToGigAppsPage()
                  }}
                >
                  <FormattedMessage id='OpenAIContentAnalysisForm.closeButtonLabel' />
                </PrimaryButton>
              </div>
						</Modal>
          </Form>
        );
      }}
    />
  )
}


OpenAITextPreProcessingForm.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  //   updateInProgress: bool.isRequired,
};

export default compose(injectIntl)(OpenAITextPreProcessingForm);