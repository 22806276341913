import React, {useState} from 'react';
import { bool, func, object, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { ensureOwnListing } from '../../util/data';
import { findOptionsForSelectFilter } from '../../util/search';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { ListingLink } from '..';
import { EditListingDescriptionForm, SampleWorkForm } from '../../forms';
import config from '../../config';
import { getDefaultTimeZoneOnBrowser } from '../../util/dates';
import { SampleWorkFormComponent } from '../../forms/SampleWorkForm/SampleWorkForm';
import {Modal} from '../../components';

import css from './EditListingSampleWorkPanel.module.css';
import ContentSampleWork from '../../forms/SampleWorkForm/ContentSampleWork';


const defaultTimeZone = () =>
  typeof window !== 'undefined' ? getDefaultTimeZoneOnBrowser() : 'Etc/UTC';

const defaultAvailabilityPlan = {
  type: 'availability-plan/time',
  timezone: defaultTimeZone(),
  entries: [
    { dayOfWeek: 'mon', startTime: '00:00', endTime: '00:00', seats: 1 },
    { dayOfWeek: 'tue', startTime: '00:00', endTime: '00:00', seats: 1 },
    { dayOfWeek: 'wed', startTime: '00:00', endTime: '00:00', seats: 1 },
    { dayOfWeek: 'thu', startTime: '00:00', endTime: '00:00', seats: 1 },
    { dayOfWeek: 'fri', startTime: '00:00', endTime: '00:00', seats: 1 },
    { dayOfWeek: 'sat', startTime: '00:00', endTime: '00:00', seats: 1 },
    { dayOfWeek: 'sun', startTime: '00:00', endTime: '00:00', seats: 1 },
  ],
};

const EditListingSampleWorkPanel = props => {
  const {
    className,
    rootClassName,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
    onManageDisableScrolling,
    isNewURI
  } = props;
  const [ openModal, SetOpenModal ] = useState(false)
  const [ initialValues, SetInitialValues] = useState({})
 const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);
  const { description, title, publicData } = currentListing.attributes;

  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const panelTitle = isPublished ? (
    <FormattedMessage
      id="EditListingDescriptionPanel.title"
      values={{ listingTitle: <ListingLink listing={listing} /> }}
    />
  ) : (
    <FormattedMessage id="EditListingSampleWorkPanel.createListingTitle" />
  );

  const closeModal = () => {
    SetInitialValues({})
    SetOpenModal(false)
  }

  const countryDropDown = findOptionsForSelectFilter('country', config.custom.filters);

  return (
    <div className={classes}>
      <h2 className={css.title}>{panelTitle}</h2>
      <Modal
        id="MissingInformationReminder"
        className={css.disableModalBorder}
        contentClassName={css.containerClassName}
        isOpen={ openModal }
        onClose={ closeModal }
        usePortal
        onManageDisableScrolling={onManageDisableScrolling}
      >
        <ContentSampleWork />
      </Modal>
      <SampleWorkForm
        key={'EditListingCertificatesForm'}
        className={css.form}
        disabled={disabled}
        ready={ready}
        fetchErrors={errors}
        SetOpenModal={SetOpenModal}
        // initialValues={{certificatesUrls}} 
        // certificatesUrls={certificatesUrls}
        isContractorSubmittedData={publicData.isContractorSubmittedData ? publicData.isContractorSubmittedData : false}
        isApproved={publicData.isApproved ? publicData.isApproved : false}
        // certificates={certificates}
        onSubmit={(values) => {
          const { certificatesUrls } = values;
          const updateValues = {
            publicData: { certificatesUrls, isApproved: false, },
          };
          onSubmit(updateValues);
        }}
        // onRemoveImage={onRemoveImage}
        saveActionMsg={submitButtonText}
        updateInProgress={updateInProgress}
        onManageDisableScrolling={onManageDisableScrolling}
        publicData={publicData}
        // onRemoveFileLinks={onRemoveFileLinks}
        updated={panelUpdated}
      />
    </div> 
  );
};

EditListingSampleWorkPanel.defaultProps = {
  className: null,
  rootClassName: null,
  errors: null,
  listing: null,
};

EditListingSampleWorkPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingSampleWorkPanel;
