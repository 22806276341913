import React, { useState } from 'react';
import { func } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import '../../styles/phoneNumberInput.css'
import PhoneInput, { formatPhoneNumber, formatPhoneNumberIntl, isValidPhoneNumber } from 'react-phone-number-input'
// These relative imports need to point to correct directories
import {
  intlShape,
  injectIntl,
  FormattedMessage,
} from '../../util/reactIntl';
import { Form, Button, FieldTextInput } from '../../components';

// as a template.
import css from './ContactUsForm.module.css';
import * as validators from '../../util/validators';


export const ContactUsForm = props => {
  const [phoneValue, setPhoneValue] = useState();
  const [invalidPhoneValue, setInvalidPhoneValue] = useState();
  return (
    <FinalForm
      {...props}
      mutators={{
        ...arrayMutators,
      }}
      render={formRenderProps => {
        const {
          disabled,
          handleSubmit,
          intl,
          invalid,
          pristine,
          updated,
          formId,
          updateError,
          updateInProgress,
          values,
          form,
          successToaster,
          errorToaster,
        } = formRenderProps;

        // firstName
        const firstNameLabel = intl.formatMessage({
          id: 'ContactUsForm.firstNameLabel',
        });
        const firstNamePlaceholder = intl.formatMessage({
          id: 'ContactUsForm.firstNamePlaceholder',
        });
        const firstNameRequiredMessage = intl.formatMessage({
          id: 'ContactUsForm.firstNameRequiredMessage',
        });
        const firstNameRequired = validators.required(firstNameRequiredMessage);

        // lastName
        const lastNameLabel = intl.formatMessage({
          id: 'ContactUsForm.lastNameLabel',
        });
        const lastNamePlaceholder = intl.formatMessage({
          id: 'ContactUsForm.lastNamePlaceholder',
        });
        const lastNameRequiredMessage = intl.formatMessage({
          id: 'ContactUsForm.lastNameRequiredMessage',
        });
        const lastNameRequired = validators.required(lastNameRequiredMessage);

        // message
        const messageLabel = intl.formatMessage({
          id: 'ContactUsForm.messageLabel',
        });
        const messagePlaceholder = intl.formatMessage({
          id: 'ContactUsForm.messagePlaceholder',
        });
        const messageRequiredMessage = intl.formatMessage({
          id: 'ContactUsForm.messageRequiredMessage',
        });
        const messageRequired = validators.required(messageRequiredMessage);


        // Phone number 
        const phoneNumberLabel = intl.formatMessage({
          id: 'ContactUsForm.phoneNumberLabel',
        });
        const phoneNumberPlaceholder = intl.formatMessage({
          id: 'ContactUsForm.phoneNumberPlaceholder',
        });
        const phoneNumberRequiredMessage = intl.formatMessage({
          id: 'ContactUsForm.phoneNumberRequiredMessage',
        });

        // email
        const emailLabel = intl.formatMessage({
          id: 'ContactUsForm.emailLabel',
        });
        const emailPlaceholder = intl.formatMessage({
          id: 'ContactUsForm.emailPlaceholder',
        });
        const emailRequiredMessage = intl.formatMessage({
          id: 'ContactUsForm.emailRequiredMessage',
        });
        const emailRequired = validators.required(emailRequiredMessage);
        const emailInvalidMessage = intl.formatMessage({
          id: 'ContactUsForm.emailInvalid',
        });

        const emailValid = validators.emailFormatValid(emailInvalidMessage);


        //Website
        const websiteLabel = intl.formatMessage({
          id: 'ContactUsForm.websiteLabel',
        });
        const websitePlaceholder = intl.formatMessage({
          id: 'ContactUsForm.websitePlaceholder',
        });

        //Company
        const companyLabel = intl.formatMessage({
          id: 'ContactUsForm.companyLabel',
        });
        const companyPlaceholder = intl.formatMessage({
          id: 'ContactUsForm.companyPlaceholder',
        });


        const errorMessage = updateError ? (
          <p className={css.error}>
            <FormattedMessage id="ContactUsForm.updateFailed" />
          </p>
        ) : null;



        const saveContactUsDetails = intl.formatMessage({
          id: "ContactUsForm.submitButton",
        });

        const submitReady = updated && pristine;
        const submitInProgress = updateInProgress;
        const submitDisabled = invalid || disabled || submitInProgress || invalidPhoneValue;

        const changePhoneValue = () => {
          if (phoneValue) {
            form.change('phoneNumber', phoneValue);
            const formattedNumber = formatPhoneNumberIntl(phoneValue)
            if (isValidPhoneNumber(formattedNumber)) {
              setInvalidPhoneValue(false)
            } else {
              setInvalidPhoneValue(true)
            }
          }
        }

        return (
          <Form onSubmit={handleSubmit} className={css.mainForm} >
            <div className={css.contactUsForm}>
            {errorMessage}
            <div className={css.packageBox}>
              <FieldTextInput
                className={css.fieldTextInput}
                type="text"
                id={formId ? `${formId}.firstName` : 'firstName'}
                name="firstName"
                autoComplete="given-name"
                label={firstNameLabel}
                placeholder={firstNamePlaceholder}
                validate={firstNameRequired}
              />
              <FieldTextInput
                className={css.fieldTextInput}
                type="text"
                id={formId ? `${formId}.lastName` : 'lastName'}
                name="lastName"
                autoComplete="given-name"
                label={lastNameLabel}
                placeholder={lastNamePlaceholder}
                validate={lastNameRequired}
              />
              <FieldTextInput
                className={css.fieldTextInput}
                type="email"
                id={formId ? `${formId}.businessEmail` : 'businessEmail'}
                name="businessEmail"
                autoComplete="given-name"
                label={emailLabel}
                placeholder={emailPlaceholder}
                validate={validators.composeValidators(emailRequired, emailValid)}
              />

              <FieldTextInput
                className={css.fieldTextInput}
                type="text"
                id={formId ? `${formId}.company` : 'company'}
                name="company"
                label={companyLabel}
                placeholder={companyPlaceholder}
              />
              <FieldTextInput
                className={css.fieldTextInput}
                type="text"
                id={formId ? `${formId}.website` : 'website'}
                name="website"
                label={websiteLabel}
                placeholder={websitePlaceholder}
              />
              {/* <FieldTextInput
                className={css.firstNameRoot}
                type="number"
                id={formId ? `${formId}.phoneNumber` : 'phoneNumber'}
                name="phoneNumber"
                autoComplete="given-name"
                label={firmLabel}
                placeholder={firmPlaceholder}
                validate={firmRequired}
              /> */}
              <p className={css.phoneNumberLabel}>
                {phoneNumberLabel}
              </p>
              <PhoneInput
                className={css.fieldTextInput}
                placeholder={phoneNumberPlaceholder}
                value={formatPhoneNumberIntl(phoneValue)}
                onChange={setPhoneValue}
                error={phoneValue ? (isValidPhoneNumber(phoneValue) ? undefined : 'Invalid phone number') : 'Phone number required'}
                onBlur={changePhoneValue}
                defaultCountry='US'
              />
              {invalidPhoneValue &&
                <p className={css.invalidPhoneValue}>
                  <FormattedMessage id='ContactUsForm.invalidPhoneNumber' />
                </p>
              }
              {/* <FieldTextInput
                className={css.fieldTextInput}
                type="textarea"
                id={formId ? `${formId}.message` : 'message'}
                name="message"
                label={messageLabel}
                placeholder={messagePlaceholder}
                validate={messageRequired}
              /> */}

            </div>
            {successToaster && 
              <div className={css.successMessage}>
                <FormattedMessage id="ContactUsForm.successMessage" />
              </div>
            }
            {errorToaster && 
              <div className={css.meetingError}>
                <FormattedMessage id="ContactUsForm.erroMessage" />
              </div>
            }
            <div className={css.submitButtonWrapper}>
              <Button
                className={css.submitButton}
                type="submit"
                inProgress={submitInProgress}
                disabled={submitDisabled}
                ready={submitReady}
              >
                {saveContactUsDetails}
              </Button>
            </div>
            </div>
          </Form>
        );
      }}
    />
  )
}

ContactUsForm.defaultProps = {
  selectedPlace: null,
  updateError: null,
};

ContactUsForm.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  //   updateInProgress: bool.isRequired,
};

export default compose(injectIntl)(ContactUsForm);