import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import * as validators from '../../util/validators';
import {
  Form,
  PrimaryButton,
  FieldTextInput,
  FieldRadioButton,
  FieldCheckbox,
  Modal,
  SecondaryButton,
  IconCard,
  FieldSelect,
} from '../../components';

import css from './SignupForm.module.css';
import { BACK_ARROW } from '../../components/IconCard/IconCard';

const KEY_CODE_ENTER = 13;

const SignupFormComponent = props => (
  <FinalForm
    {...props}
    render={fieldRenderProps => {
      const {
        rootClassName,
        className,
        formId,
        handleSubmit,
        inProgress,
        invalid,
        intl,
        onOpenTermsOfUse,
        values,
        location,
        form,
        desiredDomains,
        showPassword,
        togglePasswordVisibility,
        handleNextStep,
        handlePreviousStep,
        currentStep,
      } = fieldRenderProps;
      const showPasswordText = intl.formatMessage({
        id: 'SignupForm.showPasswordText',
      });
      const hidePasswordText = intl.formatMessage({
        id: 'SignupForm.hidePasswordText',
      });
      const signupFormHeading = intl.formatMessage({
        id: 'SignupForm.signupFormHeading',
      });
      const signupRoleHeading = intl.formatMessage({
        id: 'SignupForm.signupRoleHeading',
      });
      const signupOptionHeading = intl.formatMessage({
        id: 'SignupForm.signupOptionHeading',
      });
      const signupFinishHeading = intl.formatMessage({
        id: 'SignupForm.signupFinishHeading',
      });
      // email
      const emailLabel = intl.formatMessage({
        id: 'SignupForm.emailLabel',
      });
      const emailPlaceholder = intl.formatMessage({
        id: 'SignupForm.emailPlaceholder',
      });
      const emailRequiredMessage = intl.formatMessage({
        id: 'SignupForm.emailRequired',
      });
      const emailRequired = validators.required(emailRequiredMessage);
      const emailInvalidMessage = intl.formatMessage({
        id: 'SignupForm.emailInvalid',
      });
      const emailValid = validators.emailFormatValid(emailInvalidMessage);

      // password
      const passwordLabel = intl.formatMessage({
        id: 'SignupForm.passwordLabel',
      });
      const passwordPlaceholder = intl.formatMessage({
        id: 'SignupForm.passwordPlaceholder',
      });
      const passwordRequiredMessage = intl.formatMessage({
        id: 'SignupForm.passwordRequired',
      });
      const passwordMinLengthMessage = intl.formatMessage(
        {
          id: 'SignupForm.passwordTooShort',
        },
        {
          minLength: validators.PASSWORD_MIN_LENGTH,
        }
      );
      const passwordMaxLengthMessage = intl.formatMessage(
        {
          id: 'SignupForm.passwordTooLong',
        },
        {
          maxLength: validators.PASSWORD_MAX_LENGTH,
        }
      );
      const passwordMinLength = validators.minLength(
        passwordMinLengthMessage,
        validators.PASSWORD_MIN_LENGTH
      );
      const passwordMaxLength = validators.maxLength(
        passwordMaxLengthMessage,
        validators.PASSWORD_MAX_LENGTH
      );
      const passwordRequired = validators.requiredStringNoTrim(passwordRequiredMessage);
      const passwordValidators = validators.composeValidators(
        passwordRequired,
        passwordMinLength,
        passwordMaxLength
      );

      // firstName
      const firstNameLabel = intl.formatMessage({
        id: 'SignupForm.firstNameLabel',
      });
      const firstNamePlaceholder = intl.formatMessage({
        id: 'SignupForm.firstNamePlaceholder',
      });
      const firstNameRequiredMessage = intl.formatMessage({
        id: 'SignupForm.firstNameRequired',
      });
      const firstNameRequired = validators.required(firstNameRequiredMessage);

      // lastName
      const lastNameLabel = intl.formatMessage({
        id: 'SignupForm.lastNameLabel',
      });
      const lastNamePlaceholder = intl.formatMessage({
        id: 'SignupForm.lastNamePlaceholder',
      });
      const lastNameRequiredMessage = intl.formatMessage({
        id: 'SignupForm.lastNameRequired',
      });

      // Phone
      const phoneLabel = intl.formatMessage({
        id: 'SignupForm.phoneLabel',
      });
      const phonePlaceholder = intl.formatMessage({
        id: 'SignupForm.phonePlaceholder',
      });

      // Linkedin
      const linkedinLabel = intl.formatMessage({
        id: 'SignupForm.linkedinLabel',
      });
      const linkedinPlaceholder = intl.formatMessage({
        id: 'SignupForm.linkedinPlaceholder',
      });

      //Hear about us
      const hearAboutUsLabel = intl.formatMessage({
        id: 'SignupForm.hearAboutUsLabel',
      });

      const businessColleagues = intl.formatMessage({
        id: 'SignupForm.businessColleagues',
      });
      const friends = intl.formatMessage({
        id: 'SignupForm.friends',
      });
      const internetSearch = intl.formatMessage({
        id: 'SignupForm.internetSearch',
      });
      const other = intl.formatMessage({
        id: 'SignupForm.other',
      });
      const linkedin = intl.formatMessage({
        id: 'SignupForm.linkedin',
      });
      const callLabel = intl.formatMessage({
        id: 'SignupForm.callLabel',
      });
      const messageLabel = intl.formatMessage({
        id: 'SignupForm.messageLabel',
      });

      // signUpButtonText
      const signUpButtonText = intl.formatMessage({
        id: 'SignupForm.signUpButtonText',
      });

      // signUpContinueButtonText
      const signUpContinueButtonText = intl.formatMessage({
        id: 'SignupForm.signUpContinueButtonText',
      });

      // Expert
      const clientLabel = intl.formatMessage({
        id: 'SignupForm.clientLabel',
      });
      const insightPartnerLabel = intl.formatMessage({
        id: 'SignupForm.insightPartnerLabel',
      });
      const csmLabel = intl.formatMessage({
        id: 'SignupForm.csmLabel',
      });
      const rolesLabel = intl.formatMessage({
        id: 'SignupForm.rolesLabel',
      });
      const welcomeMessage = intl.formatMessage({
        id: 'SignupForm.welcomeMessage',
      });
      const RolesRequiredMessage = validators.required(
        intl.formatMessage({
          id: 'SignupForm.rolesRequired',
        })
      );

      const privacyRequiredMessage = intl.formatMessage({
        id: 'SignupForm.privacyPolicyRequired',
      });

      const privacyRequired = validators.requiredFieldArrayCheckbox(privacyRequiredMessage);

      const lastNameRequired = validators.required(lastNameRequiredMessage);
      const classes = classNames(rootClassName || css.root, className);
      const submitInProgress = inProgress;
      const submitDisabled = invalid || submitInProgress;
      const { email } = values || {};
      const emailDomain = email?.split('@')[1];
      const handleTermsKeyUp = e => {
        // Allow click action with keyboard like with normal links
        if (e.keyCode === KEY_CODE_ENTER) {
          onOpenTermsOfUse();
        }
      };
      const termsLink = (
        <span
          className={css.termsLink}
          onClick={onOpenTermsOfUse}
          role="button"
          tabIndex="0"
          onKeyUp={handleTermsKeyUp}
        >
          <FormattedMessage id="SignupForm.termsAndConditionsLinkText" />
        </span>
      );
      useEffect(() => {
        location?.findAnExpert
          ? form?.change('Role', 'client')
          : location?.becomeAnExpert
          ? form?.change('Role', 'partner')
          : null;
      }, []);

      const options = [
        { id: 'businessColleagues', label: businessColleagues, value: businessColleagues },
        { id: 'friends', label: friends, value: friends },
        { id: 'linkedIn', label: linkedin, value: linkedin },
        { id: 'internetSearch', label: internetSearch, value: internetSearch },
        { id: 'other', label: other, value: other },
      ];
      function getHeadingForStep(step) {
        switch (step) {
          case 0:
            return signupFormHeading;
          case 1:
            return signupRoleHeading;
          case 2:
            return signupOptionHeading;
          default:
            return signupFinishHeading;
        }
      }
      const handleButtonClick = () => {
        const isFinalStep = currentStep === 3;
        const handleCurrentStepAction = isFinalStep ? handleSubmit : handleNextStep;
        handleCurrentStepAction();
      };
      return (
        <Form className={classes} onSubmit={handleSubmit}>
          <div>
            <div className={css.loginTopbar}>
              {currentStep > 0 ? (
                <div className={css.backArrow} onClick={() => handlePreviousStep(form)}>
                  <IconCard brand={BACK_ARROW} />
                </div>
              ) : null}
              <div className={css.loginTitle}>{getHeadingForStep(currentStep)}</div>
            </div>
            {currentStep === 0 ? <h1 className={css.brandName}>{welcomeMessage}</h1> : null}
            {currentStep === 0 ? (
              <div>
                <FieldTextInput
                  className={css.Email}
                  type="text"
                  id={formId ? `${formId}.fname` : 'fname'}
                  name="fname"
                  autoComplete="given-name"
                  label={firstNameLabel}
                  placeholder={firstNamePlaceholder}
                  validate={firstNameRequired}
                />
                <FieldTextInput
                  className={css.Email}
                  type="text"
                  id={formId ? `${formId}.lname` : 'lname'}
                  name="lname"
                  autoComplete="family-name"
                  label={lastNameLabel}
                  placeholder={lastNamePlaceholder}
                  validate={lastNameRequired}
                />
                <FieldTextInput
                  className={css.Email}
                  type="email"
                  id={formId ? `${formId}.email` : 'email'}
                  name="email"
                  autoComplete="email"
                  label={emailLabel}
                  placeholder={emailPlaceholder}
                  validate={validators.composeValidators(emailRequired, emailValid)}
                />
                <div className={css.privacyPolicybox}>
                  <FieldCheckbox
                    id="termsAndConditions"
                    name="termsAndConditions"
                    value="termsAndConditions"
                    validate={privacyRequired}
                  />
                  <span className={css.termsText}>
                    <FormattedMessage
                      id="SignupForm.termsAndConditionsAcceptText"
                      values={{ termsLink }}
                    />
                  </span>
                </div>
              </div>
            ) : currentStep === 1 ? (
              <div className={css.selectRole}>
                <label className={css.roleLabel}>{rolesLabel}</label>
                <div className={css.roleRadioBox}>
                  <span className={css.tooltip}>
                    <FieldRadioButton
                      id="client"
                      name="Role"
                      // label={clientLabel}
                      value="client"
                      showAsRequired={RolesRequiredMessage}
                    />
                    <label
                      htmlFor="client"
                      className={css.tooltipText}
                      data-tooltip={'Choose this if you require insight related services.'}
                    >
                      {clientLabel}
                    </label>
                  </span>
                  <span className={css.tooltip}>
                    <FieldRadioButton
                      id="partner"
                      name="Role"
                      // label={insightPartnerLabel}
                      value="partner"
                      showAsRequired={RolesRequiredMessage}
                    />
                    <label
                      htmlFor="partner"
                      className={css.tooltipText}
                      data-tooltip={'Choose this if you provide insight related service.'}
                    >
                      {insightPartnerLabel}
                    </label>
                  </span>
                  {desiredDomains.includes(emailDomain) ? (
                    <FieldRadioButton
                      id="csm"
                      name="Role"
                      label={csmLabel}
                      value="csm"
                      showAsRequired={RolesRequiredMessage}
                    />
                  ) : null}
                </div>
              </div>
            ) : currentStep === 2 ? (
              <div className={css.thirdStep}>
                <FieldTextInput
                  className={css.Email}
                  type="number"
                  id={formId ? `${formId}.mobileNumber` : 'mobileNumber'}
                  name="mobileNumber"
                  label={phoneLabel}
                  placeholder={phonePlaceholder}
                  // validate={lastNameRequired}
                />
                {!values?.mobileNumber ? null : (
                  <div className={css.contactPreference}>
                    <h3>
                      <FormattedMessage id="SignupForm.contactPreference" />
                    </h3>
                    <div className={css.contactCheck}>
                      <FieldCheckbox
                        id="call"
                        name="call"
                        value="call"
                        label={callLabel}
                        // validate={privacyRequired}
                      />
                      <FieldCheckbox
                        id="message"
                        name="message"
                        value="message"
                        label={messageLabel}
                        // validate={privacyRequired}
                      />
                    </div>
                  </div>
                )}
                <FieldTextInput
                  className={css.Email}
                  type="text"
                  id={formId ? `${formId}.linkedinLink` : 'linkedinLink'}
                  name="linkedinLink"
                  label={linkedinLabel}
                  placeholder={linkedinPlaceholder}
                  // validate={lastNameRequired}
                />
                <FieldSelect
                  id="hearAboutUsFrom"
                  name="hearAboutUsFrom"
                  type="radio"
                  label={hearAboutUsLabel}
                  className={css.hearAbout}
                >
                  <option value="" selected disabled>
                    Select
                  </option>
                  {options &&
                    options.map(c => (
                      <option key={c.id} value={c.label}>
                        {c.label}
                      </option>
                    ))}
                </FieldSelect>
              </div>
            ) : currentStep === 3 ? (
              <div className={css.fourthStep}>
                <FieldTextInput
                  className={css.password}
                  type={showPassword ? 'text' : 'password'}
                  id={formId ? `${formId}.password` : 'password'}
                  name="password"
                  // autoComplete="new-password"
                  label={passwordLabel}
                  placeholder={passwordPlaceholder}
                  validate={passwordValidators}
                />
                {values?.password && (
                  <label className={css.showHide} onClick={togglePasswordVisibility}>
                    {showPassword ? hidePasswordText : showPasswordText}
                  </label>
                )}
              </div>
            ) : null}
          </div>

          <div className={css.bottomWrapper}>
            {/* desiredDomains.includes(emailDomain) */}
            <PrimaryButton type="button" disabled={submitDisabled} onClick={handleButtonClick}>
              {currentStep === 3 ? signUpButtonText : signUpContinueButtonText}
            </PrimaryButton>
          </div>
        </Form>
      );
    }}
  />
);

SignupFormComponent.defaultProps = { inProgress: false };

const { bool, func } = PropTypes;

SignupFormComponent.propTypes = {
  inProgress: bool,

  onOpenTermsOfUse: func.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const SignupForm = compose(injectIntl)(SignupFormComponent);
SignupForm.displayName = 'SignupForm';

export default SignupForm;
