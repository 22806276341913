import React from 'react';
import css from '../LandingPageTabsPanel.module.css';
import { IconCardHomePage, NamedLink } from '../..';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

const LandingPageThirdTab = props => {
  const { handleAdminEmailModal,isApprovedUser, currentUser } = props;

  const emailParams = {
    cardTitle: 'Access a suite of insights apps',
    cardDescription: 'We have developed a range of applications that assist you in performing a variety of insights related tasks effectively and efficiently.'
  }
  return (
    <div className={css.insightToolkit}>
      <div className={css.insightCard}>
        <div className={css.insightToolIcon}>
          <IconCardHomePage type="edittool" />
        </div>
        <div className={css.insightRightBar}>
          <div className={css.toolkitHeading}>
            Insights ToolKit
          </div>
          <div className={css.toolkitSubHeading}>
            <FormattedMessage id='LandingPageTabsPanel.insightAppsHeading' />
          </div>
          <div className={css.toolkitDescription}>
            <FormattedMessage id='LandingPageTabsPanel.insightAppsDescription' />
          </div>
          <div className={css.toolkitBottom}>
            <NamedLink className={classNames(css.gigappLink,(!isApprovedUser && currentUser) ? css.disabled : "")} name={currentUser ? `GigAppsPage` :`LoginPage`}>
              <FormattedMessage id='LandingPageTabsPanel.insightAppsLinkText' />
            </NamedLink>
            <div className={classNames(css.questionMarkIcon,(!isApprovedUser && currentUser) ? css.disabled : "")} onClick={() => handleAdminEmailModal(emailParams)}>
              <IconCardHomePage type="questionmark" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingPageThirdTab;
