import React, { useState } from 'react'
import { Menu, MenuLabel, MenuContent, MenuItem, IconCard, Modal, PrimaryButton } from '../../components';
import css from './OpenAIContentAnalysisForm.module.css';
import { FormattedMessage } from 'react-intl';

//Alert Modal Types - 
const REMOVE_QUESTION_ALERT = 'removeQuestionAlert';
const RESET_ALL_ALERT = 'resetAllAlert';

function OptionMenu(props) {
  const {questions, index, setQuestions, manageDisableScrolling, isAnalyzeApp} = props;
  const [alertModal, setAlertModal] = useState(false);

  function handleAddNewRow(){
    const newQuestions = [...questions]
    setQuestions(newQuestions.toSpliced(index+1, 0, {
      originalQuestion: '',
      modifiedQuestion: '',
      generatedQuestion: '',
    }))
  }

  function handleResetQuestion(){
    const newQuestions = [...questions]
    const generatedQuestion = questions[index]?.generatedQuestion;
    newQuestions[index].modifiedQuestion = isAnalyzeApp ? "" :generatedQuestion ;
    setQuestions(newQuestions)
  }

  function handleRemoveQuestion(){
    const newQuestions = [...questions]
    setQuestions(newQuestions.toSpliced(index, 1))
    setAlertModal(false)
  }
  
  return (
    <div className={css.optionMenuContainer}>
      <Menu contentPlacementOffset={9}>
        <MenuLabel className={css.subMenuLabel}>
          <span>
            <IconCard brand="horizontalDots" />
          </span>
        </MenuLabel>
        <MenuContent rootClassName={css.optionMenu} >
          <MenuItem key='selectAction' className={css.firstChild}>
            <FormattedMessage id='OpenAIContentAnalysisOption.selectQuestion'/>
          </MenuItem>
          <MenuItem key='resetQuestion'>
            <span onClick={handleResetQuestion}>
              <FormattedMessage id='OpenAIContentAnalysisOption.resetQuestion'/>
            </span>
          </MenuItem>
          <MenuItem key='addNewQuestion'>
            <span onClick={handleAddNewRow}>
              <FormattedMessage id='OpenAIContentAnalysisOption.addNewQuestion'/>
            </span>
          </MenuItem>
          <MenuItem key='removeQuestion'>
            <span onClick={() => setAlertModal(REMOVE_QUESTION_ALERT)}>
              <FormattedMessage id='OpenAIContentAnalysisOption.removeQuestion'/>
            </span>
          </MenuItem>
        </MenuContent>
      </Menu>

      <Modal
        id="alertModal"
        contentClassName={css.containerClassName}
        isOpen={!!alertModal}
        onClose={() => setAlertModal(false)}
        onManageDisableScrolling={manageDisableScrolling}
      >
        <div className={css.alertModal}>
          {alertModal === REMOVE_QUESTION_ALERT ? (
            <>
              <h2 style={{textAlign: 'center'}}><FormattedMessage id='OpenAIContentAnalysisForm.removeQuestionAlert'/></h2>
              <p><FormattedMessage id='OpenAIContentAnalysisForm.removeQuestionWarning'/></p> 
            </>
          ) : alertModal === RESET_ALL_ALERT ? (
            <>
              <h2 style={{textAlign: 'center'}}><FormattedMessage id='OpenAIContentAnalysisForm.removeQuestionAlert'/></h2>
              <p><FormattedMessage id='OpenAIContentAnalysisForm.questionProcessingAlertMessage' /></p>
            </>
          ) : null}
          <div className={css.buttonWrapper}>
            <div className={css.cancelButton} onClick={() => setAlertModal(false)}>
              Cancel
            </div>
            <PrimaryButton
              type='button'
              className={css.submitButton}
              onClick={handleRemoveQuestion}
            >
              Yes
            </PrimaryButton>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default OptionMenu