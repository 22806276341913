import React, { useState } from 'react';
import { bool, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';

import css from './SectionHowItWorks.module.css';

const SectionHowItWorks = props => {
  const { rootClassName, className, currentUser } = props;
  const [switcher, setSwitcher] = useState(false);

  const classes = classNames(rootClassName || css.root, className);
  return (
    <div className={classes}>
      <div className={css.title}>
        <FormattedMessage id="SectionHowItWorks.title" />
      </div>
      <div className={css.stepsWrapper}>
        <div className={css.roleTitle}>
          <div
            onClick={() => setSwitcher(false)}
            className={classNames(css.role, {
              [css.activeRole]: !switcher,
            })}
          >
            <FormattedMessage id="SectionHowItWorks.forCustomers" />
          </div>
          <div
            onClick={() => setSwitcher(true)}
            className={classNames(css.role, {
              [css.activeRole]: switcher,
            })}
          >
            <FormattedMessage id="SectionHowItWorks.forExperts" />
          </div>
        </div>
        {!switcher ? (
          <div className={css.steps}>
            <div className={css.step}>
              <span className={css.stepNumber}>1</span>
              <h2 className={css.stepTitle}>
                <FormattedMessage id="SectionHowItWorks.signUp" />
              </h2>
            </div>
            <div className={css.step}>
              <span className={css.stepNumber}>2</span>
              <h2 className={css.stepTitle}>
                <FormattedMessage id="SectionHowItWorks.post" />
              </h2>
            </div>
            <div className={css.step}>
              <span className={css.stepNumber}>3</span>
              <h2 className={css.stepTitle}>
                <FormattedMessage id="SectionHowItWorks.find" />
              </h2>
            </div>
            <div className={css.step}>
              <span className={css.stepNumber}>4</span>
              <h2 className={css.stepTitle}>
                <FormattedMessage id="SectionHowItWorks.complete2" />
              </h2>
            </div>            
          </div>
        ) : (
          <div className={css.steps}>
          <div className={css.step}>
            <span className={css.stepNumber}>1</span>
            <h2 className={css.stepTitle}>
              <FormattedMessage id="SectionHowItWorks.signUp" />
            </h2>
          </div>
          <div className={css.step}>
            <span className={css.stepNumber}>2</span>
            <h2 className={css.stepTitle}>
              <FormattedMessage id="SectionHowItWorks.build" />
            </h2>
          </div>
          <div className={css.step}>
            <span className={css.stepNumber}>3</span>
            <h2 className={css.stepTitle}>
              <FormattedMessage id="SectionHowItWorks.invite" />
            </h2>
          </div>
          <div className={css.step}>
            <span className={css.stepNumber}>4</span>
            <h2 className={css.stepTitle}>
              <FormattedMessage id="SectionHowItWorks.submit" />
            </h2>
          </div>
          <div className={css.step}>
            <span className={css.stepNumber}>5</span>
            <h2 className={css.stepTitle}>
              <FormattedMessage id="SectionHowItWorks.complete" />
            </h2>
          </div>
        </div>
        )}
      </div>
    </div>
  );
};

SectionHowItWorks.defaultProps = {
  rootClassName: null,
  className: null,
};

SectionHowItWorks.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionHowItWorks;
