import moment from 'moment';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { fetchCurrentUser } from '../../ducks/user.duck';
import {
  getTransaction,
  getZohoAccessToken,
  updateMetadata,
  updateUserProfile,
} from '../../util/api';
import { storableError } from '../../util/errors';
import { types as sdkTypes } from '../../util/sdkLoader';
import {
  INVITE_STATUS_ACTIVE,
  INVITE_STATUS_CANCEL,
  INVITE_STATUS_PENDING,
  INVITE_STATUS_REJECTED,
  PROVIDER_INVITEE,
  THIRD_PARTY_EXPERT,
  TRANSACTION_NOTIFICATION,
  USER_TYPE_PARTNER,
} from '../../util/types';
import { fetchUserRole } from '../../util/userRole';
import { sendZohoDocument, storeNotificationInDb } from '../TransactionPage/TransactionPage.duck';
import { denormalisedEntities, updatedEntities } from '../../util/data';
import { getUserDetails } from '../../util/destructorHelpers';
const { UUID } = sdkTypes;

//Action types

export const FETCH_TRANSACTION_THROUGH_ISDK_REQUEST =
  'app/InvitationPage/FETCH_TRANSACTION_THROUGH_ISDK_REQUEST';
export const FETCH_TRANSACTION_THROUGH_ISDK_SUCCESS =
  'app/InvitationPage/FETCH_TRANSACTION_THROUGH_ISDK_SUCCESS';
export const FETCH_TRANSACTION_THROUGH_ISDK_ERROR =
  'app/InvitationPage/FETCH_TRANSACTION_THROUGH_ISDK_ERROR';

export const INVITE_ACCEPTED_REQUEST = 'app/InvitationPage/INVITE_ACCEPTED_REQUEST';
export const INVITE_ACCEPTED_SUCCESS = 'app/InvitationPage/INVITE_ACCEPTED_SUCCESS';
export const INVITE_REJECTED_REQUEST = 'app/InvitationPage/INVITE_REJECTED_REQUEST';
export const INVITE_REJECTED_SUCCESS = 'app/InvitationPage/INVITE_REJECTED_SUCCESS';
export const SIGNATURE_REQUIRED = 'app/InvitationPage/SIGNATURE_REQUIRED';

export const INVITATION_ACCEPTED = 'Invitation Accepted';
export const INVITATION_REJECTED = 'Invitation Rejected';

//InitialState
const initialState = {
  fetchTransactionInProgress: false,
  fetchTransactionError: null,
  inviteAccepted: null,
  inviteRejected: null,
  inviteInProgress: false,
  rejectInProgress: false,
  transactions: null,
  isSignatureRequired: false,
  transaction: null,
};

//reducer function
export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_TRANSACTION_THROUGH_ISDK_REQUEST:
      return { ...state, fetchTransactionInProgress: true, fetchTransactionError: null };
    case FETCH_TRANSACTION_THROUGH_ISDK_SUCCESS: {
      return { ...state, fetchTransactionInProgress: false, transaction: payload };
    }
    case FETCH_TRANSACTION_THROUGH_ISDK_ERROR:
      // console.error(payload); // eslint-disable-line
      return { ...state, fetchTransactionInProgress: false, fetchTransactionError: payload };
    case INVITE_ACCEPTED_REQUEST:
      return { ...state, inviteInProgress: true };
    case INVITE_ACCEPTED_SUCCESS:
      return { ...state, inviteAccepted: true, inviteRejected: null, inviteInProgress: false };
    case INVITE_REJECTED_REQUEST:
      return { ...state, rejectInProgress: true };
    case INVITE_REJECTED_SUCCESS:
      return { ...state, inviteRejected: true, inviteAccepted: null, rejectInProgress: false };
    case SIGNATURE_REQUIRED:
      return { ...state, isSignatureRequired: true };

    default:
      return state;
  }
}

//Selectors

export const checkScrollingDisabled = state => isScrollingDisabled(state);

// ================ Action creators ================ //

const fetchTransactionThroughIsdkRequest = () => ({ type: FETCH_TRANSACTION_THROUGH_ISDK_REQUEST });
const fetchTransactionThroughIsdkSuccess = response => ({
  type: FETCH_TRANSACTION_THROUGH_ISDK_SUCCESS,
  payload: response,
});
const fetchTransactionThroughIsdkError = e => ({
  type: FETCH_TRANSACTION_THROUGH_ISDK_ERROR,
  error: true,
  payload: e,
});

const inviteAcceptedRequest = () => ({ type: INVITE_ACCEPTED_REQUEST });
const inviteAcceptedSuccess = () => ({ type: INVITE_ACCEPTED_SUCCESS });
const inviteRejectedRequest = () => ({ type: INVITE_REJECTED_REQUEST });
const inviteRejectedSuccess = () => ({ type: INVITE_REJECTED_SUCCESS });
const signatureRequired = () => ({ type: SIGNATURE_REQUIRED });
// ================ Thunk ================ //

const listingRelationship = txResponse => {
  return txResponse.data.data.relationships.listing.data;
};

const handleNotification = async (transaction, currentUser, type) => {
  try {
    const { customer = {}, provider = {} } = transaction?.id && transaction;
    const txId = transaction?.id?.uuid;
    const customerId = customer?.id && getUserDetails(customer).id;
    const providerId = provider?.id && getUserDetails(provider).id;
    const userName = currentUser.id && getUserDetails(currentUser).firstName;
    const userEmail = currentUser.id && getUserDetails(currentUser).email;
    const firmName = currentUser.id && getUserDetails(currentUser).firmName;
    const transactionRole =
      transaction?.id &&
      transaction.attributes.metadata.collaborationMetaData.find(user => user.email === userEmail)
        ?.invitedByUserRole;
    const { collaborationMetaData = [] } =
      (transaction.id && transaction.attributes.metadata) || {};
    const activeCollaborators = collaborationMetaData.filter(({ email, status }) => email !== userEmail && status === INVITE_STATUS_ACTIVE);

    const firmDetails = firmName ? ` from ${firmName}` : '';
    // Check if both customer and provider exist
    if (customer && provider) {
      // Create notification object
      const notificationObject = {
        notificationType: TRANSACTION_NOTIFICATION,
        transactionId: txId,
        notificationUrl: `${process.env.REACT_APP_CANONICAL_ROOT_URL}/t/${txId}/details`,
        content:
          type === INVITE_STATUS_CANCEL
            ? `${userName}${firmDetails} has canceled the project request as a ${transactionRole}`
            : `${userName}${firmDetails} has joined the project as a ${transactionRole}`,
      };

      // Combine and filter customer and provider IDs
      const recipientIds = [
        customerId,
        providerId,
        ...(activeCollaborators || []).map(collaborator => collaborator.collaboratorID),
      ].filter(Boolean);

      // Loop through recipient IDs
      for (const userId of recipientIds) {
        // Send notification with user-specific content
        await storeNotificationInDb({
          ...notificationObject,
          userId: userId,
        });
      }
    }
  } catch (error) {
    return;
  }
};

const sendDocument = async params => {
  const {
    currentUser,
    collaborationTransactionDetails,
    collaboratorData,
    collaborationMetaData,
    txId,
    zohoParams,
  } = params;
  const zohoAccessToken = await getZohoAccessToken();

  const response =
    type !== INVITE_STATUS_CANCEL &&
    fetchUserRole(currentUser) === USER_TYPE_PARTNER &&
    (await dispatch(
      sendZohoDocument({ ...zohoParams, access_token: zohoAccessToken?.data?.access_token })
    ));

  if (response && response.status === 'success') {
    collaboratorData.status = INVITE_STATUS_PENDING;
    collaboratorData.request_id = response.requests?.request_id;
    collabTxnData.request_id = response.requests?.request_id;

    updateMetadata({ collaborationMetaData, txId });
    updateUserProfile({
      data: {
        id: new UUID(currentUser?.id?.uuid),
        metadata: {
          zohoAccessToken: {
            access_token: zohoAccessToken?.data?.access_token,
            expiresAt: moment()
              .add(1, 'hour')
              .format('MM-DD-YYYY HH:mm:ss'),
          },
          collaborationTransactionDetails,
        },
      },
    });
  }
};

export const updateInvitation = (transaction, type) => async (dispatch, getState, sdk) => {
  const txId = transaction?.id?.uuid;
  await dispatch(fetchCurrentUser());
  try {
    if (type === INVITE_STATUS_CANCEL) {
      dispatch(inviteRejectedRequest());
    } else {
      dispatch(inviteAcceptedRequest());
    }
    const currentUser = getState()?.user?.currentUser;
    const collaborationMetaData = !!transaction?.id && transaction?.attributes?.metadata?.collaborationMetaData;
    const zohoTemplateData = !!transaction?.id && transaction?.attributes?.metadata?.zohoTemplate;
    const template_id = zohoTemplateData && zohoTemplateData?.template_id;
    const action_id = zohoTemplateData && zohoTemplateData?.action_id;
    const expertInviteeSign = zohoTemplateData && zohoTemplateData?.expertInviteeSign;
    const thirdPartySign = zohoTemplateData && zohoTemplateData?.thirdPartySign;
    const collaborationTransactionDetails = !!currentUser?.id &&
      currentUser?.attributes?.profile?.metadata?.collaborationTransactionDetails;
    const name = !!currentUser?.id && currentUser?.attributes?.profile?.displayName;
    const email = !!currentUser?.id && currentUser?.attributes?.email;
    const collabTxnData = Array.isArray(collaborationTransactionDetails) &&
      collaborationTransactionDetails.find(md => md.id === txId);
    if (collabTxnData) {
      collabTxnData.inviteStatus = type == INVITE_STATUS_CANCEL ? INVITE_STATUS_REJECTED : INVITE_STATUS_ACTIVE;
    } else {
      // console.warn('collabTxnData is undefined, cannot set inviteStatus');
    }

    const collaboratorData = Array.isArray(collaborationMetaData) &&
      collaborationMetaData.find(md => md.email === currentUser?.attributes?.email);
    collaboratorData.status = type === INVITE_STATUS_CANCEL ? INVITE_STATUS_REJECTED : INVITE_STATUS_ACTIVE;
    collaboratorData.collaboratorID = currentUser?.id?.uuid;

    const zohoParams = {
      template_id,
      action_id,
      partnerName: name,
      partnerEmail: email,
    };

    const documentArgs = {
      currentUser,
      collaborationTransactionDetails,
      collaboratorData,
      collaborationMetaData,
      txId,
      zohoParams,
    };
    handleNotification(transaction, currentUser, type);

    if (collaboratorData.invitedByUserRole === THIRD_PARTY_EXPERT && thirdPartySign) {
      dispatch(signatureRequired());
      sendDocument(documentArgs);
    } else if (collaboratorData.invitedByUserRole === PROVIDER_INVITEE && expertInviteeSign) {
      dispatch(signatureRequired());
      sendDocument(documentArgs);
    } else {
      collabTxnData.hasUserSigned = null;
      const params = {
        id: new UUID(currentUser?.id?.uuid),
        metadata: {
          collaborationTransactionDetails: collaborationTransactionDetails,
        },
      };
      updateUserProfile({ data: params });
      updateMetadata({ collaborationMetaData, txId });
    }
    return type === INVITE_STATUS_CANCEL
      ? dispatch(inviteRejectedSuccess())
      : dispatch(inviteAcceptedSuccess());
  } catch (error) {
    throw error;
  }
};

export const fetchTransactionThroughIsdk = txId => async (dispatch, getState, sdk) => {
  dispatch(fetchTransactionThroughIsdkRequest());
  try {
    const response = txId && (await getTransaction({ txId }));
    const listingId = listingRelationship(response).id;
    const entities = updatedEntities({}, response.data);
    const listingRef = { id: listingId, type: 'listing' };
    const transactionRef = { id: new UUID(txId), type: 'transaction' };
    const denormalised = denormalisedEntities(entities, [listingRef, transactionRef]);
    const transaction = denormalised[1];
    dispatch(fetchTransactionThroughIsdkSuccess(transaction));
  } catch (error) {
    dispatch(fetchTransactionThroughIsdkError(storableError(error)));
  }
};
//LoadData
export const loadData = params => async (dispatch, getState, sdk) => {
  try {
    return Promise.all([
      dispatch(fetchCurrentUser()),
      dispatch(fetchTransactionThroughIsdk(params.id)),
    ]);
  } catch (e) {
    return;
  }
};
