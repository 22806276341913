const isImage = ['gif', 'jpg', 'jpeg', 'png', 'heic', 'svg'];
const isVideo = ['mpg', 'mp2', 'mpeg', 'mpe', 'mpv', 'mp4', 'MP4', 'webm'];
const isPDF = ['pdf'];
const isXls = ['xls', 'xlsx'];
const isDocs = ['docs', 'docx', 'doc'];
const isPPT = ['pptx', 'ppt'];
const isCSV = ['csv'];
const isZip = ['zip'];
const combinedMediaTypes = [...isImage, ...isVideo, ...isXls, ...isPDF, ...isPPT, ...isCSV, ...isDocs, ...isZip];

export const getAttachmentType = (url) => {
    const extension = url && url.split(/[#?]/)[0].split('.').pop().trim();

    const imageIndex = extension && isImage && isImage.findIndex(item => item === extension);
    const videoIndex = extension && isVideo && isVideo.findIndex(item => item === extension);
    const pdfIndex = extension && isPDF && isPDF.findIndex(item => item === extension);
    const xlsIndex = extension && isXls && isXls.findIndex(item => item === extension);
    const docsIndex = extension && isDocs && isDocs.findIndex(item => item === extension);
    const pptIndex = extension && isPPT && isPPT.findIndex(item => item === extension);
    const csvIndex = extension && isCSV && isCSV.findIndex(item => item === extension);
    const zipIndex = extension && isZip && isZip.findIndex(item => item === extension);
    if (imageIndex > -1) {
        return { type: 'image', link: url, extension }
    } else if (videoIndex > -1) {
        return { type: 'video', link: url }
    } else if (pdfIndex > -1) {
        return { type: 'pdf', link: url }
    }
    else if (xlsIndex > -1) {
        return { type: 'xls', link: url }
    }
    else if (docsIndex > -1) {
        return { type: 'docs', link: url }
    }
    else if (pptIndex > -1) {
        return { type: 'ppt', link: url }
    }
    else if (csvIndex > -1) {
        return { type: 'csv', link: url }
    }
    else if (zipIndex > -1) {
        return { type: 'zip', link: url }
    }
    else if (zipIndex > -1) {
        return { type: 'zip', link: url }
    }
}

export function ordinal_suffix_of(i) {
    var j = i % 10,
        k = i % 100;
    if (j == 1 && k != 11) {
        return i + "st";
    }
    if (j == 2 && k != 12) {
        return i + "nd";
    }
    if (j == 3 && k != 13) {
        return i + "rd";
    }
    return i + "th";
}
export const mediaCheck = (message) => {
    const extension = message && message.split(/[#?]/)[0].split('.').pop().trim();
    const isMedia = combinedMediaTypes.includes(extension);
    return isMedia;
}

export const getUserShortName = (fullName, email) => {
    // Handle optional inputs and return a default value:
    if (!fullName && !email) {
      return ""; 
    }
  
    // Prioritize fullName if available:
    if (fullName) {
      return fullName.match(/\b\w/g).join('').toUpperCase();
    } else {
      return email.split('@')[0].match(/\b\w/g).join('').toUpperCase();
    }
  };
  


export const fileBrand = {
    'pdf': 'pdf',
    'doc': 'msword',
    'docs': 'msword',
    'docx': 'msword',
    'xls': 'msexcel',
    'xlsx': 'msexcel',
    'ppt': 'powerpoint',
    'pptx': 'powerpoint',
    'csv': 'csv',
    'zip': 'zip'
}