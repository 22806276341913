import React from 'react';
import { func } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
// These relative imports need to point to correct directories
import {
  intlShape,
  injectIntl,
  FormattedMessage,
} from '../../util/reactIntl';
import { Form, Button, FieldTextInput } from '../../components';

// as a template.
import css from './FirmForm.module.css';
import * as validators from '../../util/validators';


export const FirmForm = props => {
  return (
    <FinalForm
      {...props}
      mutators={{
        ...arrayMutators,
      }}
      render={formRenderProps => {
        const {
          disabled,
          handleSubmit,
          intl,
          invalid,
          pristine,
          updated,
          formId,
          updateError,
          updateInProgress,
        } = formRenderProps;

        //   firmLabel 

        const firmLabel = intl.formatMessage({
          id: "FirmForm.firmLabel",
        });

        // firm placeholder 
        const firmPlaceholder = intl.formatMessage({
          id: "FirmForm.firmPlaceholder"
        });

        // firmRequiredMessage 
        const firmRequiredMessage = intl.formatMessage({
          id: "FirmForm.firmRequiredMessage"
        });
        const firmRequired = validators.required(firmRequiredMessage);
        const errorMessage = updateError ? (
          <p className={css.error}>
            <FormattedMessage id="FirmForm.updateFailed" />
          </p>
        ) : null;



        const saveFirmButton = intl.formatMessage({
          id: "FirmForm.saveFirmButton",
        });

        const submitReady = updated && pristine;
        const submitInProgress = updateInProgress;
        const submitDisabled = invalid || disabled || submitInProgress;

        return (
          <Form className={css.packagesForm} onSubmit={handleSubmit} >
            {errorMessage}
            <div className={css.packageBox}>
              <FieldTextInput
                className={css.firstNameRoot}
                type="text"
                id={formId ? `${formId}.firmTitle` : 'firmTitle'}
                name="firmTitle"
                autoComplete="given-name"
                label={firmLabel}
                placeholder={firmPlaceholder}
                validate={firmRequired}
              />

            </div>
            <Button
              className={css.submitButton}
              type="submit"
              inProgress={submitInProgress}
              disabled={submitDisabled}
              ready={submitReady}
            >
              {saveFirmButton}
            </Button>
          </Form>
        );
      }}
    />
  )
}

FirmForm.defaultProps = {
  selectedPlace: null,
  updateError: null,
};

FirmForm.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  //   updateInProgress: bool.isRequired,
};

export default compose(injectIntl)(FirmForm);