import React, { useEffect, useState } from 'react';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Field, Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { maxLength, required, composeValidators } from '../../util/validators';
import { Form, Button, FieldTextInput, FieldCheckbox, IconCard } from '../../components';
import css from './EditListingJobQualificationForm.module.css';
import { storeKeyword } from '../../util/api';

const MIN = 0;
const MAX = 10;

const parseMin = (min, currentMax) => value => {
  const parsedValue = Number.parseInt(value, 10);
  if (isNaN(parsedValue)) {
    return '';
  }
  return parsedValue < min ? min : parsedValue > currentMax ? currentMax : parsedValue;
};
  
const parseMax = (max, currentMin) => value => {
  const parsedValue = Number.parseInt(value, 10);
  if (isNaN(parsedValue)) {
    return '';
  }
  return parsedValue > max ? max : parsedValue;
    // < currentMin ? currentMin : parsedValue > max ? max : parsedValue;
};

const EditListingJobQualificationForm = props => (
  <FinalForm
    {...props}
    render={formRenderProps => {
      const {
        className,
        disabled,
        ready,
        handleSubmit,
        intl,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        values,
        form,
      } = formRenderProps;
      
      const {skillSuggestion, selectedSkills} = values || {}

      const [showSkillButton, setShowSkillButton] = useState(true);
      
      //Job Skills
      const skillPlaceholderMessage = intl.formatMessage({ id: 'EditListingJobQualificationForm.skillPlaceholderMessage'});
      //Job Responsibility
      const responsibilityLabel = intl.formatMessage({ id: 'EditListingJobQualificationForm.responsibilityLabel'});
      const responsibilityPlaceholderMessage = intl.formatMessage({id: 'EditListingJobQualificationForm.responsibilityPlaceholderMessage',});

      const checkboxLabel = intl.formatMessage({ id: 'EditListingJobQualificationForm.noExperienceMessage'})
    
      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const isExperienceRequired = !!values.experienceRequired?.length && values.experienceRequired[0] === 'true';
      const submitDisabled = invalid || disabled || submitInProgress || (!isExperienceRequired && !values.maxExperience);

      const { minExperience: min, maxExperience: max } = values;
      const minPrice = min ? min : MIN;
      const maxPrice = max !== undefined && max ? max : 50;
      const isSkillAlreadyAdded = values.skills && selectedSkills.map(skill => skill.toLowerCase()).includes(values.skills.toLowerCase());

      const handleMaxExperience = e => {
        if (values.maxExperience < values.minExperience) {
          form.change('maxExperience', values.minExperience);
        }
      };

      function handleSkills(item){
        if(selectedSkills.includes(item)) {
          const updatedSkills = selectedSkills.filter(skill => skill !== item)
          form.change('selectedSkills', updatedSkills)
        } else {
          form.change('selectedSkills', [...selectedSkills, item])
        }
        form.change('skills', '')
        setShowSkillButton(true)
      }

      async function handleEnterSkill(value){
        form.change('selectedSkills', [...selectedSkills, value])
        form.change('skills', '');
        setShowSkillButton(true)

        const isUniqueKeyword = !!skillSuggestion.find(itm => itm.key === value.toLowerCase())
        
        if(value !== '' && !isUniqueKeyword){
          const param = {
            key: value.replace(/\s/g, '').toLowerCase(),
            label: value
          }
          form.change('skillSuggestion', [...skillSuggestion, param])
          storeKeyword({ skillsKeywords: param })
        }
      }

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          <div className={css.serviceContainer}>
            <div className={css.serviceHeading}>
              <FormattedMessage id="EditListingJobQualificationForm.skillLabel" />
            </div>
            <div className={css.inputSelectCard}>
              {Array.isArray(selectedSkills) && selectedSkills.map(item =>
                <span className={css.servicesSelected} key={item}>
                  {item}
                  <span className={css.closeItem} onClick={() => handleSkills(item)}>
                    <IconCard brand="crossSign"/>
                  </span>
                </span>
              )}
            </div>
            {showSkillButton ? (
              <button onClick={() => setShowSkillButton(false)} className={css.addServicesButton}>
                <FormattedMessage id="EditListingJobQualificationForm.addSkillButton" />
              </button>
            ) : (
              <>
                {isSkillAlreadyAdded && <p><FormattedMessage id="EditListingJobQualificationForm.skillAlreadyExitMessage"/></p>}
                <div className={css.inputSelect}>
                  <FieldTextInput
                    className={css.input}
                    id='skills'
                    name='skills'
                    type="text"
                    onKeyPress={e => {
                      if (e.key === "Enter" && !isSkillAlreadyAdded) {
                        handleEnterSkill(e.target.value)
                      }
                    }}
                    placeholder={skillPlaceholderMessage}
                    autoComplete="off"
                    autoFocus
                  />
                  <span onClick={() => setShowSkillButton(true)}><IconCard brand="cross"/></span>
                </div>
                <ul>
                  {values.skills && skillSuggestion?.length !== 0 &&
                    skillSuggestion.map((item) => {
                      if (item.key.includes(values.skills?.toLowerCase())) {
                        return (
                          <li
                            key={item.key}
                            onClick={() => handleSkills(item.label)}
                            className={selectedSkills.includes(item.label) && css.activeSelect}
                          >
                            {item.label}
                          </li>
                        );
                      }
                    })}
                </ul>
              </>
            )}
          </div>
          <FieldTextInput
            id="responsibility"
            name="responsibility"
            className={css.description}
            type="textarea"
            label={responsibilityLabel}
            placeholder={responsibilityPlaceholderMessage}
            // validate={required(descriptionRequiredMessage)}
          />
          <div className={css.experienceLabel}><FormattedMessage id="EditListingJobQualificationForm.experienceLabel" /></div>
          <div className={css.experienceInputs}>
            <Field
              className={classNames(isExperienceRequired && css.experienceDisabled)}
              id="minExperience"
              name="minExperience"
              component="input"
              type="number"
              placeholder={'0'}
              min={MIN}
              max={MAX}
              parse={parseMin(MIN, maxPrice)}
              disabled={isExperienceRequired}
            />
            <span className={css.priceSeparator}>-</span>
            <Field
              onBlur={handleMaxExperience}
              className={classNames(isExperienceRequired && css.experienceDisabled)}
              id="maxExperience"
              name="maxExperience"
              component="input"
              type="number"
              placeholder={'0'}
              min={MIN}
              max={MAX}
              parse={parseMax(50, minPrice)}
              disabled={isExperienceRequired}
            />
            <span>years</span>
          </div>
          <FieldCheckbox
            className={css.checkbox} 
            id='experienceRequired'
            name='experienceRequired'
            label={checkboxLabel} 
            value={"true"} 
          />
          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button>
        </Form>
      );
    }}
  />
);

EditListingJobQualificationForm.defaultProps = { className: null, fetchErrors: null };

EditListingJobQualificationForm.propTypes = {
  className: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    createListingDraftError: propTypes.error,
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  })
};

export default compose(injectIntl)(EditListingJobQualificationForm);
