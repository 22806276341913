import {USER_ROLE_CLIENT, USER_ROLE_CSM, USER_ROLE_PARTNER} from './types';

const STORAGE_BASE_NAME = 'userRole';

const hasWindow = () => typeof window !== 'undefined';
const getUserId = user => user?.id?.uuid || '';
const getStorageName = user => `${STORAGE_BASE_NAME}-${getUserId(user)}`;

export const getUserRole = user => user?.attributes?.profile?.protectedData?.Role ?? user?.attributes?.profile?.publicData?.role ;

export const getStoredUserRole = user => {
  const userRole = getUserRole(user);
  if (userRole === USER_ROLE_CLIENT){
    return USER_ROLE_CLIENT;
  }
  if (userRole === USER_ROLE_CSM){
    return USER_ROLE_CSM;
  }

  if (!hasWindow){
    return;
  }

  const storageName = getStorageName(user);
  
  if (localStorage.getItem(storageName)){
    return localStorage.getItem(storageName);
  }

  return USER_ROLE_PARTNER;
} 

export const setStoredUserRole = (user, newRole) => {
  if (!hasWindow){
    return;
  }
  const storageName = getStorageName(user);
  localStorage.setItem(storageName, newRole);
} 

// user approved data
export const approvedUserStatus = user => !!user && user?.attributes?.profile?.protectedData?.isApprovedUser;

export const userTncVersion = user => !!user && user?.attributes?.profile?.protectedData?.tncVersion;

//fetchUserRole from public Data
export const fetchUserRole = (user)=>  !!user && !!user?.id && user?.attributes?.profile?.publicData?.role && !!user && !!user?.id && user?.attributes?.profile?.publicData?.role;

// fetch Profile Views
export const fetchExpertProfileViews = (user) => !!user?.id && user?.attributes?.profile?.protectedData?.expertProfileViews || [];