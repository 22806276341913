import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconInformation.module.css';

const IconInformation = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg className={classes} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8 0C3.63636 0 0 3.55556 0 8C0 12.4444 3.55556 16 8 16C12.4444 16 16 12.4444 16 8C16 3.55556 12.3636 0 8 0ZM8 14.3838C4.44444 14.3838 1.61616 11.5556 1.61616 8C1.61616 4.44444 4.44444 1.61616 8 1.61616C11.5556 1.61616 14.3838 4.44444 14.3838 8C14.3838 11.5556 11.5556 14.3838 8 14.3838Z" fill="#EB5757"/>
    <path d="M7.99998 3.23242C7.59593 3.23242 7.19189 3.55565 7.19189 4.0405V9.61626C7.19189 10.0203 7.51513 10.4243 7.99998 10.4243C8.48482 10.4243 8.80806 10.1011 8.80806 9.61626V4.0405C8.80806 3.55565 8.40402 3.23242 7.99998 3.23242Z" fill="#EB5757"/>
    <path d="M7.43432 11.3939C7.11109 11.7171 7.11109 12.2019 7.43432 12.5252C7.75755 12.8484 8.2424 12.8484 8.56563 12.5252C8.88886 12.2019 8.88886 11.7171 8.56563 11.3939C8.2424 11.1514 7.75755 11.1514 7.43432 11.3939Z" fill="#EB5757"/>
    </svg>
  );
};

IconInformation.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

IconInformation.propTypes = {
  rootClassName: string,
  className: string,
};

export default IconInformation;
