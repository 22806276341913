//Action types

import { isScrollingDisabled } from '../../ducks/UI.duck';
import { fetchCurrentUser } from '../../ducks/user.duck';
import {
  cancelStripeSubscription,
  createStripeSubscriptionOffline,
  createSubscriptionWithStripe,
  deleteFirmMemberData,
  getPriceFromMongo,
  getPriceFromStripe,
  getSubscriptionsFromStripe,
} from '../../util/api';
import { storableError } from '../../util/errors';
import { updateProfile } from '../ProfileSettingsPage/ProfileSettingsPage.duck';

export const FETCH_SUBSCRIPTION_PRICE_REQUEST = 'app/StripeSubscriptionPage/FETCH_SUBSCRIPTION_PRICE_REQUEST';
export const FETCH_SUBSCRIPTION_PRICE_SUCCESS = 'app/StripeSubscriptionPage/FETCH_SUBSCRIPTION_PRICE_SUCCESS';
export const FETCH_SUBSCRIPTION_PRICE_ERROR = 'app/StripeSubscriptionPage/FETCH_SUBSCRIPTION_PRICE_ERROR';
export const FETCH_SUBSCRIPTIONS_REQUEST = 'app/StripeSubscriptionPage/FETCH_SUBSCRIPTIONS_REQUEST';
export const FETCH_SUBSCRIPTIONS_SUCCESS = 'app/StripeSubscriptionPage/FETCH_SUBSCRIPTIONS_SUCCESS';
export const FETCH_SUBSCRIPTIONS_ERROR = 'app/StripeSubscriptionPage/FETCH_SUBSCRIPTIONS_ERROR';

export const CREATE_OFFLINE_SUBSCRIPTION_REQUEST = 'app/StripeSubscriptionPage/CREATE_OFFLINE_SUBSCRIPTION_REQUEST';
export const CREATE_OFFLINE_SUBSCRIPTION_SUCCESS = 'app/StripeSubscriptionPage/CREATE_OFFLINE_SUBSCRIPTION_SUCCESS';
export const CREATE_OFFLINE_SUBSCRIPTION_ERROR = 'app/StripeSubscriptionPage/CREATE_OFFLINE_SUBSCRIPTION_ERROR';

export const CREATE_FREE_SUBSCRIPTION_REQUEST = 'app/StripeSubscriptionPage/CREATE_FREE_SUBSCRIPTION_REQUEST';
export const CREATE_FREE_SUBSCRIPTION_SUCCESS = 'app/StripeSubscriptionPage/CREATE_FREE_SUBSCRIPTION_SUCCESS';
export const CREATE_FREE_SUBSCRIPTION_ERROR = 'app/StripeSubscriptionPage/CREATE_FREE_SUBSCRIPTION_ERROR';

//InitialState
const initialState = {
  subscriptionPlan: null,
  fetchPriceInProgress: false,
  fetchPriceError: null,
  userSubscriptions: [],
  fetchSubscriptionInProgress: false,
  fetchSubscriptionError: null,
  offlineSubscriptionInProgress:false,
  offlineSubscriptionMessage: null,
  offlineSubscriptionError: null,
  createFreeSubscriptionInProgress: false,
  createFreeSubscriptionSuccess: false,
  createFreeSubscriptionError: null,
};

//Selectors

export const checkScrollingDisabled = state => isScrollingDisabled(state);

//reducer function
export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_SUBSCRIPTION_PRICE_REQUEST:
      return { ...state, fetchPriceInProgress: true, fetchPriceError: null };
    case FETCH_SUBSCRIPTION_PRICE_SUCCESS:
      return {
        ...state,
        subscriptionPlan: payload,
        fetchPriceInProgress: false,
        fetchPriceError: null,
      };
    case FETCH_SUBSCRIPTION_PRICE_ERROR:
      return { ...state, fetchPriceInProgress: false, fetchPriceError: payload };
    case FETCH_SUBSCRIPTIONS_REQUEST:
      return { ...state, fetchSubscriptionInProgress: true, fetchSubscriptionError: null };
    case FETCH_SUBSCRIPTIONS_SUCCESS:
      return {
        ...state,
        userSubscriptions: payload,
        fetchSubscriptionInProgress: false,
        fetchSubscriptionError: null,
      };
    case FETCH_SUBSCRIPTIONS_ERROR:
      return { ...state, fetchSubscriptionInProgress: false, fetchSubscriptionError: payload };
    case CREATE_OFFLINE_SUBSCRIPTION_REQUEST:
      return { ...state, offlineSubscriptionInProgress: true, offlineSubscriptionError: null };
    case CREATE_OFFLINE_SUBSCRIPTION_SUCCESS:
      return { ...state, offlineSubscriptionInProgress: false, offlineSubscriptionError: null,offlineSubscriptionMessage: payload };
    case CREATE_OFFLINE_SUBSCRIPTION_ERROR:
      return { ...state, offlineSubscriptionInProgress: false, offlineSubscriptionError: payload };
    case CREATE_FREE_SUBSCRIPTION_REQUEST:
      return { ...state, createFreeSubscriptionInProgress: true};
    case CREATE_FREE_SUBSCRIPTION_SUCCESS:
      return { ...state, createFreeSubscriptionInProgress: false, createFreeSubscriptionSuccess: true };
    case CREATE_FREE_SUBSCRIPTION_ERROR:
      return { ...state, createFreeSubscriptionInProgress: false, createFreeSubscriptionSuccess: false, createFreeSubscriptionError: payload };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const fetchPriceRequest = () => ({
  type: FETCH_SUBSCRIPTION_PRICE_REQUEST,
});
export const fetchPriceSuccess = data => ({
  type: FETCH_SUBSCRIPTION_PRICE_SUCCESS,
  payload: data,
});

export const fetchPriceError = e => ({
  type: FETCH_SUBSCRIPTION_PRICE_ERROR,
  error: true,
  payload: e,
});

export const fetchSubscriptionsRequest = () => ({
  type: FETCH_SUBSCRIPTIONS_REQUEST,
});
export const fetchSubscriptionsSuccess = data => ({
  type: FETCH_SUBSCRIPTIONS_SUCCESS,
  payload: data,
});

export const fetchSubscriptionsError = e => ({
  type: FETCH_SUBSCRIPTION_PRICE_ERROR,
  error: true,
  payload: e,
});
export const offlineSubscriptionRequest = () => ({
  type: CREATE_OFFLINE_SUBSCRIPTION_REQUEST,
});
export const offlineSubscriptionSuccess = data => ({
  type: CREATE_OFFLINE_SUBSCRIPTION_SUCCESS,
  payload: data,
});

export const offlineSubscriptionError = e => ({
  type: CREATE_OFFLINE_SUBSCRIPTION_ERROR,
  error: true,
  payload: e,
});

export const freeSubscriptionRequest = () => ({ type: CREATE_FREE_SUBSCRIPTION_REQUEST });
export const freeSubscriptionSuccess = () => ({ type: CREATE_FREE_SUBSCRIPTION_SUCCESS });

export const freeSubscriptionError = e => ({
  type: CREATE_FREE_SUBSCRIPTION_ERROR,
  payload: e,
});

// ================ Thunk ================ //

export const fetchPriceFromStripe = () => async (dispatch, getState, sdk) => {
  dispatch(fetchPriceRequest());
  try {
    // const response = await getPriceFromStripe();
    const response = await getPriceFromMongo();
    
    dispatch(fetchPriceSuccess(response));
  } catch (error) {
    dispatch(fetchPriceError(storableError(error)));
  }
};

//Create subscription with Stripe

export const createSubscription = params => async (dispatch, sdk, getState) => {
  try {
    const subscription = await createSubscriptionWithStripe(params);
    return subscription;
  } catch (error) {
    throw error;
  }
};

// Get subscriptions

export const getSubscriptions = (params) => async (dispatch, sdk, getState) => {
  dispatch(fetchSubscriptionsRequest());
  try {
    const subscriptions = await getSubscriptionsFromStripe(params);
    dispatch(fetchSubscriptionsSuccess(subscriptions));
    return subscriptions;
  } catch (error) {
    dispatch(fetchSubscriptionsError(storableError(error)));
  }
};

export const createOfflineSubscription = (params) => async (dispatch, sdk, getState) => {
  dispatch(offlineSubscriptionRequest());
  try {
    const data = await createStripeSubscriptionOffline(params);
    dispatch(offlineSubscriptionSuccess(data.message));
    dispatch(fetchCurrentUser());
    return data;
  } catch (error) {
    dispatch(offlineSubscriptionError(storableError(error)));
  }
};

export const createFreeSubscription = (params) => async (dispatch, sdk, getState) => {
  dispatch(freeSubscriptionRequest());
  try {
    const {userIdArray, stripeCustomerId} = params;
    // Update user profile by removing offlineSubscriptionObject
    const profile = {
      protectedData: {
        offlineSubscriptionObject: null,
        stripeCustomerId: null,
        subscriptionPlan: null,
      },
    };
    await dispatch(updateProfile(profile)); // Update user profile

    // Remove associated data
    if (userIdArray.length > 0) {
      await deleteFirmMemberData({ userIdArray }); // Delete firm member data
    }

    if (stripeCustomerId) {
      await cancelStripeSubscription(); // Cancel Stripe subscription
    }
    dispatch(fetchCurrentUser());
    dispatch(freeSubscriptionSuccess());
    return {status: true};
  } catch (error) {
    dispatch(freeSubscriptionError(storableError(error)));
  }
};



//LoadData
export const loadData = params => async (dispatch, getState, sdk) => {
  await dispatch(fetchCurrentUser());

  try {
    const currentUser = getState().user?.currentUser;

    return Promise.all([
      dispatch(fetchCurrentUser()),
      dispatch(fetchPriceFromStripe()),
      // dispatch(getSubscriptions()),
    ]);
  } catch (error) {
    dispatch(showListingError(error));
    throw error;
  }
};
