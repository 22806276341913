import React from 'react'
import css from './EditListingAvailabilityPanel.module.css'
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';

const EditListingAvailabilityPanelSwitcher = (props) => {
    const { handleToggleState, toggle, isExpertUnavailable } = props;
    return (
      <div className={css.container}>
        <span className={classNames(css.textSwitcher, toggle && css.unselected)}>
          <h3>
            <FormattedMessage id="EditListingAvailabilityPanelSwitcher.unAvailable" />
          </h3>
        </span>
        <div className={classNames(css.checkboxWrapper, {
              [css.toggled]: toggle,
            })}
          onClick={handleToggleState}
        >
          <div
            className={classNames(css.ball, {
              [css.toggled]: toggle,
            })}
          >
            {' '}
          </div>
        </div>
        <span className={classNames(css.textSwitcher, !toggle && css.unselected)}>
          <h3>
            <FormattedMessage id="EditListingAvailabilityPanelSwitcher.available" />
          </h3>
        </span>   
      </div>
    )
  }

export default EditListingAvailabilityPanelSwitcher