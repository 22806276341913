import React, { Component, useEffect, useState } from 'react';
import { array, arrayOf, bool, func, number, object, string } from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import {
  txIsBriefOffered,
  txIsBriefDeclined,
  txIsProposalDeclined,
  txIsToBriefProposalDeclined,
  // txIsProposalCanceled,
  // txIsToBriefProposalCanceled,
  txIsPendingProposal,
  txIsToBriefPendingProposal,
  // txIsProposalAccepted,
  // txIsToBriefProposalAccepted,
  txHasBeenDelivered,
  txIsReviewed,
  txIsMilestoneAdded,
  txIsMilestoneUpdated,
  txIsMilestoneDeleted,
  txIsMilestoneCompleted,
  txIsMilestoneUnfinished,
  txIsMilestoneAddedByOperator,
  TRANSITION_CREATE_PROPOSAL,
  TRANSITION_BRIEF_CREATE_PROPOSAL,
  TRANSITION_BRIEF_UPDATE_PROPOSAL,
  TRANSITION_DECLINE_BRIEF,
  TRANSITION_ACCEPT_PROPOSAL_AFTER_BRIEF,
  TRANSITION_ACCEPT_PROPOSAL,
  txIsPrepaidUsingStripe,
  TRANSITION_REQUEST_PREPAYMENT_USING_STRIPE,
  TRANSITION_REQUEST_PREPAYMENT,
  txIsPrepaymentPending,
  txIsPrepaymentApproved,
  txIsPaymentPending,
  txIsInFirstReview,
  txIsPrepaid,
  txIsInProgress,
  TRANSITION_REVIEW_1_BY_PROVIDER,
  TRANSITION_REVIEW_2_BY_PROVIDER,
  TRANSITION_REVIEW_1_BY_CUSTOMER,
  TRANSITION_REVIEW_2_BY_CUSTOMER,
  txConfirmedPaymentAfterProposalAccepted,
  txIsFinalPaymentPending,
  txIsFinalPaymentPendingIsPaymentIsDueUponProjectCompletion,
  TRANSITION_CONFIRM_PAYMENT_AFTER_PROPOSAL_ACCEPTED,
  TRANSITION_REQUEST_FIRST_INTERVIEW,
  txIsCsmInvitePending,
  TRANSITION_PROPOSAL_ACCEPT_AFTER_CSM_INVITE_OPERATOR,
  TRANSITION_REQUEST_SECOND_INTERVIEW,
  TRANSITION_CSM_PRIVATE_BRIEF_INVITE,
  TRANSITION_ACCEPT_PROPOSAL_OPERATOR,
  txIsJobApplicationExpire,
  txIsJobDescriptionExpire,
} from '../../util/transaction';
import {
  propTypes,
  USER_ROLE_CLIENT,
  // USER_ROLE_PARTNER,
  CUSTOMER_INVITEE,
  ONLINE,
  OFFLINE,
  TRANSACTION_BRIEF_PROCESS,
  OVERVIEW,
  CHAT,
  ACTIVITY,
  MILESTONES,
  COLLABORATORS,
  TOOLS,
  USER_ROLE_CUSTOMER,
  USER_ROLE_PROVIDER,
  OFFLINE_PAYMENT_STEP,
  OFFLINE_PAYMENT_SUBJECT,
  OFFLINE_FINAL_PAYMENT_SUBJECT,
  OFFLINE_FINAL_PAYMENT_STEP,
  CUSTOMER,
  PROVIDER,
  TRANSACTION_DETAILS_PAGE,
  PAYMENT_INTENT,
  ZOOM_MEET,
  GOOGLE_MEET,
  ZOOM_MEETING_FORM,
  FILES,
  USER_ROLE_CSM,
  TRANSACTION_APPLY_JOB_PROCESS,
  TRANSACTION_JOB_DESCRIPTION_PROCESS,
  INVITE_STATUS_PENDING,
  TRANSACTION_NOTIFICATION,
  TRANSACTION_ACTIVITY_PAGE,
  TRANSACTION_CHAT_PAGE,
  TRANSACTION_DETAIL_PAGE,
  TRANSACTION_MILESTONE_PAGE,
  TRANSACTION_COLLABORATORS_PAGE,
  TRANSACTION_FILES_PAGE,
  PROJECT_COMPLETE_ALERT,
} from '../../util/types';
import {
  ensureListing,
  ensureTransaction,
  ensureUser,
  userDisplayNameAsString,
} from '../../util/data';
import { isMobileSafari } from '../../util/userAgent';
import { formatMoney } from '../../util/currency';
import {
  NamedLink,
  ReviewModal,
  UserDisplayName,
  ModalProposal,
  IconEditPencil,
  Button,
  Menu,
  MenuLabel,
  MenuContent,
  MenuItem,
  ExternalLink,
  IconCard,
  Modal,
  CollaborationPanel,
  RealTimeChatPanel,
  CheckoutModal,
  PrimaryButton,
  TosterMessage,
  IntegrationCard,
  Avatar,
  IconSpinner,
  ZoomMeetPanel,
  IconBannedUser,
  NotificationBar,
} from '../../components';
import { ModalSendCsmTransactionInviteForm } from '../../forms';
import config from '../../config';
import { types } from 'sharetribe-flex-sdk';
import zoomIcon from '../../assets/zoomIcon.png';
import googleMeetIcon from '../../assets/googlemeeticon.png';
import ShowReview from './ShowReview';
import moment from 'moment';
import css from './TransactionPanel.module.css';
import FieldMilestones from './Milestones';
import { getUserRole } from '../../util/userRole';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import GoogleMeetPanel from '../GoogleMeetPanel/GoogleMeetPanel';
import ZohoSign from '../ZohoSign/ZohoSign';
import {
  getUserData,
  handleExpertDetails,
  handleWeavaiteDatabase,
  storeNotification,
  updateUserProfile,
} from '../../util/api';
import {
  checkUserRole,
  getPaymentMode,
  getUserDetails,
  getUserDisplayName,
  isUserLinkedToFirm,
} from '../../util/destructorHelpers';

const { UUID } = types;

import { BorderButton } from '../Button/Button';
import pdfimage from '../../assets/pdfimage.png';
import ppticon from '../../assets/ppticon.png';
import docicon from '../../assets/docImage.png';
import exlicon from '../../assets/exlImage.png';
import { getCustomerData } from '../../containers/TransactionPage/TransactionPage.duck';
import { useDispatch } from 'react-redux';
import { TRANSITION_REQUEST_PREPAYMENT_IF_PAYMENT_DUE_UPON_PROJECT_COMPLETION } from '../../util/transaction';
import { CROSS_SIGN } from '../IconCard/IconCard';
import BoxComponent from '../Box/Box';
import JobDescription from './JobDescription';
import FileView from '../FileView/FileView';
import { createSlug } from '../../util/urlHelpers';

const { Money } = types;
const { paymentTerms: paymentTermsOptions } = config.custom;
const dateFormat = 'DD/MM/YYYY';

const BriefItem = ({ intl, transaction }) => {
  const {
    id: briefId,
    title,
    description,
    maxPrice,
    minPrice,
    briefAttachmentsUrls,
    currencyFee,
    projectTimelines,
    domainExpertise,
    industrySector,
    location,
  } = transaction?.attributes?.protectedData?.brief || {};

  if (!briefId) return null;

  const minMoney = new Money(minPrice * 100, currencyFee || 'USD');
  const maxMoney = new Money(maxPrice * 100, currencyFee || 'USD');

  return (
    <div className={css.briefCardWrapper}>
      <div className={css.briefCard}>
        <h4 className={css.mainHeading}>
          <FormattedMessage id="TransactionPanel.projectbrief" />
        </h4>
      </div>
      <div className={css.briefCard}>
        <h5>
          <FormattedMessage id="TransactionPanel.projectObjective" />
        </h5>
        <div className={css.briefDescription}>{title}</div>
      </div>
      <div className={css.briefCard}>
        <h5>
          <FormattedMessage id="TransactionPanel.projectDescriptionText" />
        </h5>
        <div className={css.briefDescription}>{description}</div>
      </div>
      <div className={css.briefCard}>
        <h5>
          <FormattedMessage id="TransactionPanel.projectService" />
        </h5>
        <div className={css.briefDescription}>{domainExpertise?.join(', ')}</div>
      </div>
      <div className={css.briefCard}>
        <h5>
          <FormattedMessage id="TransactionPanel.projectIndustry" />
        </h5>
        <div className={css.briefDescription}>{industrySector?.join(', ')}</div>
      </div>
      <div className={css.briefCard}>
        <h5>
          <FormattedMessage id="TransactionPanel.projectTimeline" />
        </h5>
        <div className={css.briefDescription}>{projectTimelines}</div>
      </div>
      <div className={css.briefCard}>
        <h5>
          <FormattedMessage id="TransactionPanel.budgetRange" />
        </h5>
        <div className={css.briefDescription}>
          {formatMoney(intl, minMoney)} - {formatMoney(intl, maxMoney)}
        </div>
      </div>
      <div className={css.briefCard}>
        <h5>
          <FormattedMessage id="TransactionPanel.physicalPresence" />
        </h5>
        <div className={css.briefDescription}>
          {location?.length ? <span>Yes - {location?.join(', ')}</span> : <span>No</span>}
        </div>
      </div>
      <div className={css.briefCard}>
        <h5>
          <FormattedMessage id="TransactionPanel.supportingDocumentsText" />
        </h5>
        <div className={css.mediaAttachment}>
          {Array.isArray(briefAttachmentsUrls) &&
            briefAttachmentsUrls.map(item => {
              const fileTypeExt = item.name.split('.')[1];
              return (
                <ExternalLink
                  key={item.link}
                  className={css.fileList}
                  target="_blank"
                  href={item && item?.link}
                >
                  <div className={css.documentImage}>
                    {fileTypeExt?.includes('pdf') ? (
                      <img src={pdfimage} />
                    ) : ['doc', 'docs', 'docx'].includes(fileTypeExt) ? (
                      <img src={docicon} />
                    ) : ['xls', 'xlsx'].includes(fileTypeExt) ? (
                      <img src={exlicon} />
                    ) : fileTypeExt?.includes('ppt', 'pptx') ? (
                      <img src={ppticon} />
                    ) : ['csv'].includes(fileTypeExt) ? (
                      <IconCard brand="csv" />
                    ) : ['zip'].includes(fileTypeExt) ? (
                      <IconCard brand="zip" />
                    ) : null}
                  </div>
                  <div>
                    <div className={css.documentName}>{item && item?.name}</div>
                    <div className={css.documentDate}>{item && item?.date}</div>
                  </div>
                </ExternalLink>
              );
            })}
        </div>
      </div>
    </div>
  );
};

const ProposalItem = ({
  onEdit,
  transaction,
  intl,
  showEdit,
  headingState,
  // transactionDocuments,
}) => {
  if (!transaction?.attributes?.metadata?.proposal) {
    return null;
  }

  const {
    reason,
    scopeOfWork,
    methodology,
    paymentFee: { amount, currency },
    paymentTerms,
    startDate,
    deadline,
  } = transaction.attributes.metadata.proposal;

  const statusAccepted = intl.formatMessage({ id: 'TransactionPanel.statusAccepted' });
  const statusDeclined = intl.formatMessage({ id: 'TransactionPanel.statusDeclined' });

  const paymentTermsValue =
    paymentTermsOptions.find(({ key }) => key == paymentTerms)?.label || null;
  const paymentFeeMoney = new Money(amount, currency);
  const { metadata = {} } = transaction && transaction.attributes;
  const proposalAttachments =
    metadata && !!metadata.proposal.attachmentsUrls && metadata.proposal.attachmentsUrls;

  const status = () => {
    if (showEdit) {
      return (
        <button className={css.accordionStatusButton} onClick={onEdit}>
          <IconEditPencil />
          <FormattedMessage id="TransactionPanel.editButton" />
        </button>
      );
    }
    if (headingState === 'declined') {
      return <div className={css.statusDeclined}>{statusDeclined}</div>;
    }
    if (headingState === 'accepted') {
      return <div className={css.statusAccepted}>{statusAccepted}</div>;
    }

    return null;
  };

  return (
    <div className={css.briefCardWrapper}>
      <div className={css.briefCard}>
        <h4 className={css.mainHeading}>
          <FormattedMessage id="TransactionPanel.projectProposal" />
        </h4>
        <div className={css.briefDeailBox}>{status()}</div>
      </div>
      <div className={css.briefCard}>
        <h5>
          <FormattedMessage id="TransactionPanel.whySuited" />
        </h5>
        <div className={css.briefDeailBox}>
          <div className={css.briefDescription}>{reason}</div>
        </div>
      </div>
      <div className={css.briefCard}>
        <h5>
          <FormattedMessage id="TransactionPanel.scopeOfWork" />
        </h5>
        <div className={css.briefDeailBox}>
          <div className={css.briefDescription}>{scopeOfWork}</div>
        </div>
      </div>
      <div className={css.briefCard}>
        <h5>
          <FormattedMessage id="TransactionPanel.projectApproach" />
        </h5>
        <div className={css.briefDeailBox}>
          <div className={css.briefDescription}>{methodology}</div>
        </div>
      </div>
      <div className={css.briefCard}>
        <h5>
          <FormattedMessage id="TransactionPanel.supportingDocumentsText" />
        </h5>
        <div className={css.mediaAttachment}>
          {Array.isArray(proposalAttachments) &&
            proposalAttachments.map(item => {
              return <div key={item?.date}>{item?.name && <FileView file={item} />}</div>;
            })}
        </div>
      </div>
      <div className={css.briefCard}>
        <h5>
          <FormattedMessage id="TransactionPanel.feeInfoProposal" />
        </h5>
        <div className={css.briefDeailBox}>
          <div className={css.briefDescription}>{formatMoney(intl, paymentFeeMoney)}</div>
        </div>
      </div>
      <div className={css.briefCard}>
        <h5>
          <FormattedMessage id="TransactionPanel.paymentTerms" />
        </h5>
        <div className={css.briefDeailBox}>
          <div className={css.briefDescription}>{paymentTermsValue}</div>
        </div>
      </div>
      <div className={css.briefCard}>
        <h5>
          <FormattedMessage id="TransactionPanel.projectTimeline" />
        </h5>
        <div className={css.briefDeailBox}>
          <div className={css.briefDescription}>
            {`${moment(startDate).format(dateFormat)} - ${moment(deadline).format(dateFormat)}`}
          </div>
        </div>
      </div>
    </div>
  );
};

export const MilestonesItem = ({
  typeUser,
  transaction,
  onChange,
  onDelete,
  onToggle,
  intl,
  actionInProgress,
  editable,
  onManageDisableScrolling,
  collaborationMetaData,
  currentUser,
  isGigAppTransaction,
}) => {
  const transitions =
    transaction?.id && transaction?.attributes?.transitions?.map(e => e.transition);
  const isPrepaymentDone =
    (transitions?.length && transitions?.includes(TRANSITION_REQUEST_PREPAYMENT)) ||
    transitions?.includes(TRANSITION_REQUEST_PREPAYMENT_USING_STRIPE) ||
    transitions?.includes(TRANSITION_REQUEST_PREPAYMENT_IF_PAYMENT_DUE_UPON_PROJECT_COMPLETION);

  if (!isGigAppTransaction && (!transaction?.attributes?.metadata?.proposal || !isPrepaymentDone)) {
    return (
      <div className={css.briefInfoBlock}>
        <p className={css.accordionContent}>
          <FormattedMessage id="TransactionPanel.milestoneNotEnabled" />
        </p>
      </div>
    );
  }

  const { milestones = [] } = transaction?.attributes?.metadata || {};
  milestones.sort((a, b) => a.date - b.date);

  const handleChange = milestone => {
    return onChange(milestone);
  };
  return (
    <>
      <div className={css.briefInfoBlock}>
        {(!milestones.length && typeUser === USER_ROLE_CLIENT) ? 
          (!isGigAppTransaction ? 
            <p className={css.accordionContent}>
              <FormattedMessage id="TransactionPanel.noContentMilestones" />
            </p>
            : 
            <span className={css.noMilestonesContent}>
              <FormattedMessage id="TransactionPanel.noMilestonesYet" />
            </span>
          )
          :
          <FieldMilestones
            initialValues={{ milestones }}
            changeContentMilestones={handleChange}
            onDeleteMilestone={onDelete}
            onToggle={onToggle}
            typeUser={typeUser}
            name="milestones"
            actionInProgress={actionInProgress}
            editable={editable}
            onManageDisableScrolling={onManageDisableScrolling}
            collaborationMetaData={collaborationMetaData}
            currentTransaction={transaction}
            currentUser={currentUser}
            isGigAppTransaction={isGigAppTransaction}
          />
        }
      </div>
    </>
  );
};

const ChatBar = ({
  otherUserDisplayNameString,
  currentTransaction,
  currentUser,
  fetchMessagesError,
  fetchMessagesInProgress,
  initialMessageFailed,
  messages,
  chainedMessages,
  oldestMessagePageFetched,
  onShowMoreMessages,
  totalMessagePages,
  onOpenReviewModal,
  setClearForm,
  clearAttachmentForm,
  transaction,
  isMobSaf,
  connectedUsers,
  activeTab,
  isJobListing,
  message,
  socket,
  unreadMessages,
  setMessage,
  onManageDisableScrolling,
  isOnline,
  isJobOrApplicationExpired,
}) => {
  const dispatch = useDispatch();
  const [userData, setUsersData] = useState([]);
  const [showOnline, setShowOnline] = useState(false);

  const isMobile = typeof window !== 'undefined' && window.innerWidth < 768;
  const customer = currentTransaction?.id && getUserDetails(currentTransaction?.customer);
  const expert = currentTransaction?.id && getUserDetails(currentTransaction?.provider);

  const collaborationMetaData =
    currentTransaction?.id && currentTransaction?.attributes?.metadata?.collaborationMetaData;
  const collaborationData =
    (collaborationMetaData?.length &&
      collaborationMetaData?.map(user => {
        return {
          name: getUserDisplayName(user.fullName),
          fullName: user.fullName,
          profileImage: user.profileImage,
          id: user.collaboratorID,
          email: user.email,
          role: user.invitedByUserRole,
        };
      })) ||
    [];

  const membersData = [
    {
      ...customer,
      name: getUserDisplayName(customer?.fullName),
      fullName: customer?.fullName,
      role: CUSTOMER,
    },
    {
      ...expert,
      name: getUserDisplayName(expert?.fullName),
      fullName: expert?.fullName,
      role: PROVIDER,
    },
    ...collaborationData,
  ];

  const lastTransitionedAt =
    transaction && transaction.id && transaction?.attributes?.lastTransitionedAt;
  const isReviewd = txIsReviewed(transaction);
  const chatExpirationTime =
    isReviewd &&
    moment(lastTransitionedAt)
      .add(30, 'days')
      .format('LLLL');
  const isChatExpired =
    (isReviewd && moment(moment()).isAfter(chatExpirationTime)) || isJobOrApplicationExpired;

  const UserAvatar = ({ member, isCard, role, csmTooltipText }) => {
    const isCsm = role === USER_ROLE_CSM;
    const classess = classNames(
      isCard ? css.cardImage : css.imagesBox,
      isCsm && css.csmBadge,
      isCsm && csmTooltipText && css.csmTooltip
    );
    const isOnline = connectedUsers?.[member.id]?.userId || false;

    return (
      <span className={classess} data-tooltip={csmTooltipText}>
        {!member?.email ? (
          <IconBannedUser className={css.profileImage} />
        ) : member.profileImage ? (
          <img src={member.profileImage} />
        ) : (
          <span className={classNames(css.profileImage)}>
            {member.name
              .match(/(\b\S)?/g)
              .join('')
              .toUpperCase()}
          </span>
        )}
        <span className={isOnline ? css.onlineDot : css.offlineDot}></span>
      </span>
    );
  };

  useEffect(() => {
    activeTab === TRANSACTION_CHAT_PAGE &&
      membersData.forEach(member => {
        member?.email &&
          dispatch(getCustomerData(member.email))
            .then(user => {
              setUsersData(oldArray => [...oldArray, user]);
            })
            .catch(error => console.log(error));
      });
  }, []);

  const getUserFirmInfo = email => {
    let firmTitle, firmId, role;
    userData?.length &&
      userData.map(user => {
        if (user?.attributes?.email === email) {
          firmId = getUserDetails(user)?.firmId;
          firmTitle = getUserDetails(user)?.firmName;
          role = getUserRole(user);
        }
      });
    return { firmTitle, firmId, role };
  };

  return (
    <div className={css.containerChatBlock}>
      <div className={css.chatContainerBlock}>
        {activeTab === TRANSACTION_CHAT_PAGE && (
          <div className={css.mobileOnlineBox} onClick={() => setShowOnline(!showOnline)}>
            <IconCard brand="profileicon" />
          </div>
        )}
        {showOnline && isMobile ? null : (
          <RealTimeChatPanel
            otherUserDisplayNameString={otherUserDisplayNameString}
            currentTransaction={currentTransaction}
            isMobSaf={isMobSaf}
            isReviewd={isReviewd}
            isChatExpired={isChatExpired}
            rootClassName={css.feedContainer}
            currentUser={currentUser}
            fetchMessagesError={fetchMessagesError}
            fetchMessagesInProgress={fetchMessagesInProgress}
            initialMessageFailed={initialMessageFailed}
            messages={messages}
            chainedMessages={chainedMessages}
            oldestMessagePageFetched={oldestMessagePageFetched}
            onOpenReviewModal={onOpenReviewModal}
            onShowMoreMessages={() => onShowMoreMessages(currentTransaction.id)}
            totalMessagePages={totalMessagePages}
            setClearForm={setClearForm}
            clearAttachmentForm={clearAttachmentForm}
            connectedUsers={connectedUsers}
            isOnline={isOnline}
            activeTab={activeTab}
            isJobListing={isJobListing}
            message={message}
            socket={socket}
            unreadMessages={unreadMessages}
            setMessage={setMessage}
            membersData={membersData}
            onManageDisableScrolling={onManageDisableScrolling}
            // socket={socket}
          />
        )}
      </div>
      {activeTab === TRANSACTION_CHAT_PAGE && (
        <div className={classNames(css.chatMembers, showOnline && css.chatMembersMobile)}>
          <h3>
            <FormattedMessage id="TransactionPanel.members" />
          </h3>
          {membersData?.length !== 0 &&
            membersData?.map((member, index) => {
              const firmId = getUserFirmInfo(member.email).firmId;
              const firmName = getUserFirmInfo(member.email).firmTitle;
              const role = getUserFirmInfo(member.email).role;
              const csmTooltipText = firmName
                ? `This user is a Customer Success Manager working on behalf of ${firmName} on the InsightGig platform.`
                : 'This user is a Customer Success Manager (CSM) on the InsightGig platform. CSMs work on behalf of their clients.';
              return (
                <div className={css.memberTab} key={index}>
                  <UserAvatar member={member} isCard={false} role={role} />
                  <span className={css.titleName}>{member.name}</span>
                  <div className={css.memberCard}>
                    <div className={css.memberAvatar}>
                      <UserAvatar
                        member={member}
                        isCard={true}
                        role={role}
                        csmTooltipText={csmTooltipText}
                      />
                      <div>
                        <h3>{member.name}</h3>
                        {firmId && firmName && (
                          <NamedLink
                            name="EditBecomeInsightGigPartnerPageDetails"
                            params={{ id: firmId, slug: firmName }}
                          >
                            <h4>{firmName}</h4>
                          </NamedLink>
                        )}
                      </div>
                    </div>
                    <span className={css.dividerLine}></span>
                    <div className={css.memberDetails}>
                      <h4>
                        <FormattedMessage id="TransactionPanel.role" />
                      </h4>
                      <h4 style={{ color: '#B2B2B2' }}>{member.role}</h4>
                    </div>
                    <div className={css.memberDetails}>
                      <h4>
                        <FormattedMessage id="TransactionPanel.email" />
                      </h4>
                      <h4 style={{ color: '#B2B2B2' }}>{member.email}</h4>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      )}
    </div>
  );
};

// Helper function to get display names for different roles
const displayNames = (ensuredAuthor, currentUser, currentProvider, currentCustomer, intl) => {
  const authorDisplayName = <UserDisplayName user={ensuredAuthor} intl={intl} />;
  const customerDisplayName = <UserDisplayName user={currentCustomer} intl={intl} />;

  let otherUserDisplayName = '';
  let otherUserDisplayNameString = '';
  const currentUserIsCustomer =
    currentUser?.id && currentCustomer?.id && currentUser?.id?.uuid === currentCustomer?.id?.uuid;
  const currentUserIsProvider =
    currentUser?.id && currentProvider?.id && currentUser?.id?.uuid === currentProvider?.id?.uuid;

  if (currentUserIsCustomer) {
    otherUserDisplayName = authorDisplayName;
    otherUserDisplayNameString = userDisplayNameAsString(currentProvider, '');
  } else if (currentUserIsProvider) {
    otherUserDisplayName = customerDisplayName;
    otherUserDisplayNameString = userDisplayNameAsString(currentCustomer, '');
  }

  return {
    authorDisplayName,
    customerDisplayName,
    otherUserDisplayName,
    otherUserDisplayNameString,
  };
};

export class TransactionPanelComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sendMessageFormFocused: false,
      isReviewModalOpen: false,
      reviewSubmitted: false,
      modalProposalOpen: false,
      modalAskedOpen: false,
      stopLoop: false,
      meetingModal: false,
      meetingErrorToaster: false,
      meetingToggle: '',
      modal: false,
      zoomMeetingModal: false,
      checkoutModal: false,
      offlinePaymentState: false,
      onlinePaymentState: false,
      activeTab: TRANSACTION_DETAIL_PAGE,
      modalPayNow: false,
      showConfirmContractBox: true,
      milestoneVisibility: [],
      openCollaboratorModalState: false,
      csmInvitationToasterState: false,
      isFirstInterview: 1,
      showTooltip: false,
      scheduleMeetingNoTransition: false,
    };

    this.isMobSaf = false;
    this.onOpenReviewModal = this.onOpenReviewModal.bind(this);
    this.onSubmitReview = this.onSubmitReview.bind(this);
    this.onSendMessageFormFocus = this.onSendMessageFormFocus.bind(this);
    this.onSendMessageFormBlur = this.onSendMessageFormBlur.bind(this);
    this.onMessageSubmit = this.onMessageSubmit.bind(this);
    this.scrollToMessage = this.scrollToMessage.bind(this);
    this.handleProposal = this.handleProposal.bind(this);
    this.onToggleActive = this.onToggleActive.bind(this);
    this.setClearForm = this.setClearForm.bind(this);
    this.timer = null;
  }

  componentDidMount() {
    const tar = document.getElementById('toScroll');
    if (tar) tar.scrollTop = 9999;
    this.isMobSaf = isMobileSafari();
    this.setState({ meetingErrorToaster: false });
  }
  componentDidUpdate(prevProps) {
    const { meetingError, zoomMeetingError, transaction } = this.props;
    if (
      meetingError !== prevProps.meetingError ||
      zoomMeetingError !== prevProps.zoomMeetingError
    ) {
      this.setState({ meetingErrorToaster: true, meetingModal: false });
      this.timer = setTimeout(() => this.setState({ meetingErrorToaster: false }), 3000);
    }
    const prevTransaction = prevProps.transaction;
    if (
      transaction?.attributes?.metadata?.milestones !==
      prevTransaction?.attributes?.metadata?.milestones
    ) {
      this.setState({
        milestoneVisibility: transaction.attributes.metadata?.milestones?.filter(milestone => {
          const currentDate = moment().startOf('day'); // Get the current date without time
          const dueDate = moment(milestone?.date).startOf('day'); // Get the due date without time
          const isOverdue = dueDate.isBefore(currentDate); // Check if due date is before current date
          const isDue = dueDate.isSame(currentDate); // Check if due date is same
          return (isDue || isOverdue) && milestone.selected.length === 0;
        }),
      });
    }
  }
  componentWillUnmount() {
    clearTimeout(this.timer);
  }
  onToggleActive(isOpen) {
    this.setState({ modalAskedOpen: isOpen });
    // localStorage.removeItem(IS_FINAL)
  }

  onOpenReviewModal() {
    this.setState({ isReviewModalOpen: true });
  }

  setClearForm(type) {
    this.setState({ clearAttachmentForm: type, stopLoop: true });
  }

  onSubmitReview(values, isClient, updateCollaboratorMetaData) {
    const {
      onSendReview,
      transaction,
      transactionRole,
      onUpdateUserDetailsInWeaviate,
    } = this.props;
    const currentTransaction = ensureTransaction(transaction);
    const { reviewRating, reviewContent } = values;
    const rating = Number.parseInt(reviewRating, 10);

    onSendReview(transactionRole, currentTransaction, rating, reviewContent)
      .then(async r => {
        const txId = transaction.id.uuid;
        const { customer = {}, provider = {} } = transaction?.id && transaction;
        const isAuthorCustomer = !!currentTransaction?.reviews?.find(
          tx => tx.author.id.uuid === customer.id.uuid
        );
        const authorId = isAuthorCustomer
          ? getUserDetails(customer).id
          : getUserDetails(provider).id;
        const userName = isAuthorCustomer
          ? getUserDetails(provider).fullName
          : getUserDetails(customer).fullName;
        const briefId = transaction?.attributes?.protectedData?.brief?.id;
        const expertListingId = transaction?.listing?.id?.uuid;
        const listingId = isClient ? expertListingId : briefId;

        const notificationObject = {
          userId: authorId,
          notificationType: TRANSACTION_NOTIFICATION,
          content: `${userName} left a review`,
          transactionId: txId,
          notificationUrl: `${process.env.REACT_APP_CANONICAL_ROOT_URL}/t/${txId}/details`,
        };
        if (txId) {
          storeNotification(notificationObject);
          updateCollaboratorMetaData();

          if (isClient) {
            //To update review rating in Weaviate Database
            onUpdateUserDetailsInWeaviate({currentUser: provider});
          }
        }
        this.setState({ isReviewModalOpen: false, reviewSubmitted: true });
      })
      .catch(e => console.error(e));
  }

  onSendMessageFormFocus() {
    this.setState({ sendMessageFormFocused: true });
    if (this.isMobSaf) {
      // Scroll to bottom
      window.scroll({ top: document.body.scrollHeight, left: 0, behavior: 'smooth' });
    }
  }

  onSendMessageFormBlur() {
    this.setState({ sendMessageFormFocused: false });
  }

  onMessageSubmit(values, form) {
    const { message = 'Attachment', attachmentsUrls = [] } = values;
    const messageText = message ? message.trim() : null;

    const attachments = attachmentsUrls && attachmentsUrls.map(item => item);

    const { transaction, onSendMessage, onUpdateMetadata } = this.props;
    const ensuredTransaction = ensureTransaction(transaction);

    const metadata = transaction && transaction.attributes && transaction.attributes.metadata;
    const allAttachments = metadata && metadata.attachments ? JSON.parse(metadata.attachments) : [];
    if (!messageText) {
      return;
    }

    onSendMessage(ensuredTransaction.id, messageText)
      .then(messageId => {
        typeof window !== 'undefined' &&
          window.document &&
          window.document.forms['SendMessageForm'].reset();
        form.reset();
        if (attachments && attachments.length) {
          allAttachments.push({ messageId, attachments });
          onUpdateMetadata(allAttachments, ensuredTransaction.id)
            .then(response => {
              // if(!this.state.stopLoop){
              //   this.setClearForm(true);
              // }
              this.scrollToMessage(messageId);
            })
            .catch(e => console.error(e));
        }
      })
      .catch(e => console.error(e));
    typeof window !== 'undefined' &&
      window.document &&
      window.document.forms['SendMessageForm'].reset();
  }

  scrollToMessage(messageId) {
    // const selector = `#msg-${messageId.uuid}`;
    // const el = document.querySelector(selector);
    const tar = document.getElementById('toScroll');
    if (tar) {
      tar.scrollTop = 9999;
      // tar.scrollIntoView({
      //   block: 'start',
      //   behavior: 'smooth',
      // });
    }
  }

  async handleProposal(values) {
    const { onHandleProposal } = this.props;
    try {
      await onHandleProposal(values);
      this.setState({ modalProposalOpen: false });
    } catch (e) {
      // console.log(e);
    }
  }

  render() {
    const {
      rootClassName,
      className,
      currentUser,
      transaction,
      totalMessagePages,
      oldestMessagePageFetched,
      messages,
      initialMessageFailed,
      // savePaymentMethodFailed,
      fetchMessagesInProgress,
      fetchMessagesError,
      // sendMessageInProgress,
      sendMessageError,
      sendReviewInProgress,
      sendReviewError,
      // onFetchTimeSlots,
      onManageDisableScrolling,
      onShowMoreMessages,
      transactionRole,
      intl,
      // onAcceptSale,
      // onDeclineSale,
      // acceptInProgress,
      // declineInProgress,
      // acceptSaleError,
      // declineSaleError,
      // onSubmitBookingRequest,
      // monthlyTimeSlots,
      // nextTransitions,
      onFetchTransactionLineItems,
      // lineItems,
      // fetchLineItemsInProgress,
      // fetchLineItemsError,
      typePartner,
      actionInProgress,
      onDeclineBrief,
      onDeclineProposal,
      onAcceptProposal,
      chainedMessages,
      onPayProposal,
      onChangeMilestone,
      onDeleteMilestone,
      onToggleMilestone,
      onCompleteProposal,
      onCompleteProposalThroughIntegration,
      documentsBrief,
      transactionDocuments,
      onGetGoogleAccessToken,
      onScheduleGoogleMeet,
      meetingInProgress,
      meetingCreated,
      meetingError,
      history,
      params,
      meetingSuccessToaster,
      handleCreateZoomMeeting,
      handleEnterMeeting,
      onCreateZoomAuth,
      zoomMeetingInProgress,
      zoomMeetingError,
      redirectToMainTransaction,
      isCollaborator,
      connectedUsers,
      collaborationMetaData,
      onUpdateFinalPaymentInfo,
      setMeetingSuccessToaster,
      handleConfirmContract,
      pageName,
      onRequestScheduleInterview,
      firmListing,
      onCollaborationInvite,
      onSendCSMInviteToFirmMemberToAccpetProposal,
      onAcceptProposalAfterCsmInvitation,
      onDeclineProposalAfterCsmInvitation,
      sendInviteInProgress,
      isCreatedFromBrief,
      sendCsmInviteInProgress,
      onRequestCaseStudy,
      isChatExpired,
      message,
      socket,
      setMessage,
      unreadMessages,
      notifications,
      isOnline,
      isProviderIndianOrigin,
      stripeConnectAccountId,
      filteredChatNotifications,
      markNotificationAsRead,
    } = this.props;
    const { scheduleMeetingNoTransition } = this.state || {};
    const urlParams = new URLSearchParams(window.location.search);
    const paymentIntentId = urlParams.get(PAYMENT_INTENT);
    const hasZoomCode = urlParams.get('code');
    const classes = classNames(rootClassName || css.root, className);
    const {
      modal,
      meetingModal,
      meetingErrorToaster,
      milestoneVisibility,
      csmInvitationToasterState,
    } = this.state;

    const currentPath = typeof window !== 'undefined' && window.location.pathname;
    const currentTransaction = ensureTransaction(transaction);

    const processName = currentTransaction?.attributes?.processName;
    const isBrief =
      !!currentTransaction?.id &&
      (processName === TRANSACTION_BRIEF_PROCESS || processName === TRANSACTION_APPLY_JOB_PROCESS);
    const listingTitle = currentTransaction?.attributes?.protectedData?.brief?.title;
    const paymentMode = getPaymentMode(currentTransaction);
    const lastTransition = currentTransaction?.attributes?.lastTransition;
    const currentListing = ensureListing(currentTransaction.listing);
    const isJobListing =
      processName === TRANSACTION_APPLY_JOB_PROCESS ||
      processName === TRANSACTION_JOB_DESCRIPTION_PROCESS;

    const currentProvider = ensureUser(
      isBrief ? currentTransaction.customer : currentTransaction.provider
    );
    const currentCustomer = ensureUser(
      isBrief ? currentTransaction.provider : currentTransaction.customer
    );
    const customerName = getUserDetails(currentCustomer)?.fullName;
    const expertName = getUserDetails(currentProvider)?.fullName;
    const isClient = typePartner === USER_ROLE_CLIENT || typePartner === USER_ROLE_CSM;
    const currentAuthor = !!currentUser?.id?.uuid && isClient ? currentProvider : currentCustomer;
    const ensuredAuthor = ensureUser(currentAuthor);
    const isCustomer = transactionRole === USER_ROLE_CUSTOMER;
    const customerLocation =
      currentAuthor?.id && currentAuthor?.attributes?.profile?.publicData?.location?.search;
    const isProvider = transactionRole === USER_ROLE_PROVIDER;
    const typeUser = getUserRole(currentUser);
    const authorRole = currentAuthor?.id && getUserRole(currentAuthor);
    const isAuthorLinkedToFirm = currentAuthor?.id && isUserLinkedToFirm(currentAuthor);
    const authorFirmName = (currentAuthor?.id && getUserDetails(currentAuthor)?.firmName) || '';
    const providerEmail = getUserDetails(currentProvider)?.email;
    const customerEmail = getUserDetails(currentCustomer)?.email;

    const isContractConfirm =
      currentTransaction?.id && currentTransaction?.attributes?.metadata?.isContractConfirm;
    const isContractSigned =
      currentTransaction?.id && currentTransaction?.attributes?.metadata?.isContractSigned;
    const expertLeadZohoDetails =
      currentTransaction?.id && currentTransaction?.attributes?.metadata?.expertLeadZohoDetails;
    const tab = params.tab || {};
    const transitions =
      currentTransaction?.id && currentTransaction?.attributes?.transitions.map(t => t.transition);
    const currentAuthorName = getUserDetails(ensuredAuthor)?.firstName;
    const currentAuthorEmail = getUserDetails(ensuredAuthor)?.email;
    const firmName = firmListing?.id && firmListing?.attributes?.title;
    const isFinal =
      transitions?.includes(TRANSITION_CONFIRM_PAYMENT_AFTER_PROPOSAL_ACCEPTED) ||
      transitions?.includes(TRANSITION_REQUEST_PREPAYMENT_IF_PAYMENT_DUE_UPON_PROJECT_COMPLETION) ||
      transitions?.includes(TRANSITION_REQUEST_PREPAYMENT_USING_STRIPE);
    const isJobOrApplicationExpired = txIsJobApplicationExpire(transaction) || txIsJobDescriptionExpire(transaction);
    const listingSlug = currentListing?.attributes?.title && createSlug(currentListing?.attributes?.title);
    const expertProfileUrl = typeof window !== 'undefined' && `${window.location.origin}/l/${listingSlug}/${currentListing?.id?.uuid}`;

    const isMilestoneDueSoon = milestone => {
      const dueDate = moment.unix(milestone?.date).startOf('day');
      const currentDate = moment().startOf('day');
      const daysDifference = currentDate.diff(dueDate, 'days'); // Swap the dates in the diff calculation
      return milestone.selected.length === 0 && daysDifference >= 0 && daysDifference <= 1;
    };

    const isMilestoneOverdue = milestone => {
      const dueDateObject = moment.unix(milestone?.date);
      return milestone.selected.length === 0 && dueDateObject.isBefore(moment(), 'day');
    };

    const handleRemoveReminder = id => {
      const checkMilestone = milestoneVisibility?.filter(m => m?.id !== id);
      if (checkMilestone) {
        this.setState({ milestoneVisibility: checkMilestone });
      }
    };

    const currentCustomerEmail = currentCustomer?.id && getUserDetails(currentCustomer)?.email;
    const currentProviderEmail = currentProvider?.id && getUserDetails(currentProvider)?.email;
    const userEmailsForGoogleMeet =
      !!currentTransaction?.id &&
      [currentCustomerEmail, currentProviderEmail]?.map(email => ({ email }));
    const isCsm = currentUser?.id && getUserRole(currentUser) === USER_ROLE_CSM;
    const { firmData = [] } = (firmListing?.id && firmListing?.attributes?.metadata) || {};
    const { protectedData = {} } = (currentTransaction?.id && currentTransaction.attributes) || {};
    const { brief = {}, chainedTransactionId = null } = protectedData || {};
    // Now, 'brief' and 'chainedTransactionId' are safely extracted, or they will be undefined if 'currentTransaction.attributes' or 'protectedData' is undefined.
    const csmInviteeDetails =
      currentTransaction?.id &&
      currentTransaction?.attributes?.metadata?.collaborationMetaData?.find(
        user => user?.isCsmInvite
      );

    const stateDataFn = tx => {
      if (txIsBriefOffered(tx)) {
        return isClient || isCsm
          ? {
              icon: 'response',
              id: 'TransactionPanel.briefOfferedTitleClient',
              userName: expertName,
              showSendProsalButtons: false,
            }
          : {
              icon: 'briefrecieved',
              id: 'TransactionPanel.briefOfferedTitlePartner',
              userName: customerName,
              showSendProsalButtons: true,
            };
      } else if (txIsBriefDeclined(tx)) {
        return isClient
          ? {
              icon: 'declined',
              id: 'TransactionPanel.briefDeclinedTitleClient',
              userName: expertName,
            }
          : {
              icon: 'declined',
              id: 'TransactionPanel.briefDeclinedTitlePartner',
              userName: customerName,
            };
      } else if (txIsPendingProposal(tx) || txIsToBriefPendingProposal(tx)) {
        return isClient || isCsm
          ? {
              icon: 'briefrecieved',
              id:
                isCsm && [TRANSITION_CSM_PRIVATE_BRIEF_INVITE].includes(lastTransition)
                  ? 'TransactionPanel.csmInviteSent'
                  : 'TransactionPanel.pendingProposalTitleClient',
              showAcceptProsalButtons: isCsm ? false : true,
              userName: isCollaborator
                ? expertName
                : csmInviteeDetails?.isCsmInvite
                ? csmInviteeDetails?.fullName
                : expertName,
              showCollaborationPanel:
                isCsm &&
                [
                  TRANSITION_CREATE_PROPOSAL,
                  TRANSITION_BRIEF_UPDATE_PROPOSAL,
                  TRANSITION_BRIEF_CREATE_PROPOSAL,
                ].includes(lastTransition)
                  ? true
                  : false,
              // TRANSITION_CREATE_PROPOSAL TRANSITION_BRIEF_UPDATE_PROPOSAL TRANSITION_BRIEF_CREATE_PROPOSAL
            }
          : {
              icon: 'response',
              id: csmInviteeDetails?.isCsmInvite
                ? 'TransactionPanel.csmInviteSentMessageForExpert'
                : 'TransactionPanel.pendingProposalTitlePartner',
              showAcceptProsalButtons: false,
              showEditProposalButton: true,
              userName: csmInviteeDetails?.isCsmInvite ? csmInviteeDetails?.fullName : customerName,
            };
      } else if (txIsProposalDeclined(tx) || txIsToBriefProposalDeclined(tx)) {
        return isClient || isCsm
          ? {
              icon: 'declined',
              id: csmInviteeDetails?.isCsmInvite
                ? !!isCollaborator
                  ? 'TransactionPanel.proposalDeclinedTitleClient'
                  : 'TransactionPanel.proposalDeclinedByCsmInviteeTitlePartner'
                : 'TransactionPanel.proposalDeclinedTitleClient',
              userName: expertName,
              collaboratorName: csmInviteeDetails?.fullName,
            }
          : {
              icon: 'declined',
              id: 'TransactionPanel.proposalDeclinedTitlePartner',
              userName: csmInviteeDetails?.isCsmInvite ? csmInviteeDetails?.fullName : expertName,
            };
      } else if (txIsCsmInvitePending(tx)) {
        return {
          icon: 'response',
          id: !!isCollaborator
            ? 'TransactionPanel.pendingProposalTitleClient'
            : isClient
            ? 'TransactionPanel.csmInviteSent'
            : 'TransactionPanel.csmInviteSentMessageForExpert',
          showAcceptProsalButtons: isCollaborator ? true : false,
          userName: isCollaborator ? expertName : csmInviteeDetails?.fullName,
        };
      } else if (txIsPrepaymentPending(tx)) {
        if (isClient || isCsm) {
          return isContractSigned
            ? {
                icon: 'accepted',
                id: 'TransactionPanel.contractSignedTitleClient',
                showPayNowButton: true,
              }
            : isContractConfirm
            ? {
                icon: 'accepted',
                id: 'TransactionPanel.contractInprogressTitle',
                showPayNowButton: false,
              }
            : {
                icon: 'accepted',
                id: !!isCollaborator
                  ? `TransactionPanel.proposalAcceptedTitleCsm`
                  : csmInviteeDetails?.isCsmInvite
                  ? 'TransactionPanel.proposalAcceptedTitleCsmToOtherUsers'
                  : 'TransactionPanel.proposalAcceptedTitleClient',
                showPayNowButton: !!isCollaborator ? false : true,
                userName: !!isCollaborator
                  ? expertName
                  : csmInviteeDetails?.isCsmInvite
                  ? csmInviteeDetails?.fullName
                  : expertName,
              };
        } else {
          return isContractSigned
            ? {
                icon: 'accepted',
                id: 'TransactionPanel.contractSignedTitleExpert',
                showPayNowButton: false,
                userName: customerName,
              }
            : isContractConfirm
            ? {
                icon: 'accepted',
                id: 'TransactionPanel.contractInprogressTitle',
                showPayNowButton: false,
              }
            : {
                icon: 'accepted',
                id: 'TransactionPanel.proposalAcceptedTitlePartner',
                showPayNowButton: false,
                userName: csmInviteeDetails?.isCsmInvite
                  ? csmInviteeDetails?.fullName
                  : customerName,
              };
        }
      } else if (txIsPrepaymentApproved(tx)) {
        return isClient || isCsm
          ? {
              icon: 'response',
              id: 'TransactionPanel.prepaymentPendingTitleClient',
            }
          : {
              icon: 'response',
              id: 'TransactionPanel.prepaymentPendingTitleExpert',
            };
      } else if (txConfirmedPaymentAfterProposalAccepted(tx)) {
        return {
          icon: 'response',
          id: 'TransactionPanel.prepaymentApprovalPending',
        };
      } else if (
        txIsMilestoneAdded(tx) ||
        txIsMilestoneAddedByOperator(tx) ||
        txIsMilestoneUpdated(tx) ||
        txIsMilestoneDeleted(tx) ||
        txIsMilestoneCompleted(tx) ||
        txIsMilestoneUnfinished(tx) ||
        txIsPrepaid(tx) ||
        txIsInProgress(tx) ||
        txIsPrepaidUsingStripe(tx)
      ) {
        return {
          icon: 'inprogress',
          id: 'TransactionPanel.inProgressTitle',
          showCompleteButton:
            !tx?.attributes?.metadata?.finalPaymentTxId && (isClient || isCsm) ? true : false,
          editMilestones: true,
        };
      } else if (txIsFinalPaymentPending(tx)) {
        return {
          icon: 'response',
          id: 'TransactionPanel.finalPaymentApprovalPending',
        };
      } else if (txIsFinalPaymentPendingIsPaymentIsDueUponProjectCompletion(tx)) {
        return {
          icon: 'response',
          id: 'TransactionPanel.finalPaymentApprovalPending',
        };
      } else if (txIsPaymentPending(tx)) {
        return isClient || isCsm
          ? {
              icon: 'response',
              id: 'TransactionPanel.prepaymentPendingTitleClient',
            }
          : {
              icon: 'response',
              id: 'TransactionPanel.prepaymentPendingTitleExpert',
            };
      } else if (txHasBeenDelivered(tx)) {
        return {
          icon: 'completed',
          id: isCollaborator
            ? 'TransactionPanel.projectCompleteCollaborator'
            : 'TransactionPanel.projectCompleteTitle',
          showReviewButton: true,
        };
      } else if (txIsInFirstReview(tx)) {
        return {
          icon: 'review',
          id: 'TransactionPanel.oneReviewGiven',
          showReviewButton: true,
        };
      } else if (txIsReviewed(tx)) {
        return {
          icon: 'review',
          id: 'TransactionPanel.bothReviewGiven',
        };
      } else {
        return { id: 'TransactionPanel.loading' };
      }
    };
    const stateData = stateDataFn(currentTransaction);
    const {
      authorDisplayName,
      customerDisplayName,
      otherUserDisplayName,
      otherUserDisplayNameString,
    } = displayNames(ensuredAuthor, currentUser, currentProvider, currentCustomer, intl);

    const tabLink = [
      {
        tabNames: OVERVIEW,
        icon: <IconCard brand="overview" />,
        tabRoute: TRANSACTION_DETAIL_PAGE,
      },
      {
        tabNames: CHAT,
        icon: <IconCard brand="chat" />,
        notificationDot:
          filteredChatNotifications?.length && tab !== TRANSACTION_CHAT_PAGE ? (
            <span className={css.notificationDot} />
          ) : null,
        tabRoute: TRANSACTION_CHAT_PAGE,
      },
      {
        tabNames: ACTIVITY,
        icon: <IconCard brand="activity" />,
        tabRoute: TRANSACTION_ACTIVITY_PAGE,
      },
      !isJobListing && {
        tabNames: MILESTONES,
        icon: <IconCard brand="milesstone" />,
        tabRoute: TRANSACTION_MILESTONE_PAGE,
      },
      !isJobListing && {
        tabNames: COLLABORATORS,
        icon: <IconCard brand="collaborators" />,
        tabRoute: TRANSACTION_COLLABORATORS_PAGE,
      },
      // {
      //   tabNames: TOOLS,
      //   icon: <IconCard brand="tools" />,
      // },
      !isJobListing && {
        tabNames: FILES,
        icon: <IconCard brand="files" />,
        tabRoute: TRANSACTION_FILES_PAGE,
      },
    ];

    const updateCollaboratorMetaData = () => {
      collaborationMetaData?.length &&
        collaborationMetaData?.forEach(async e => {
          const response = await getUserData({ id: e.collaboratorID });
          const collaborationTransactionDetails =
            response?.data?.data?.attributes?.profile?.metadata?.collaborationTransactionDetails;
          const index = collaborationTransactionDetails.findIndex(
            tx => tx?.id === currentTransaction?.id?.uuid
          );
          collaborationTransactionDetails.at(index).isTransactionRead = false;

          const data = {
            id: new UUID(e.collaboratorID),
            metadata: {
              collaborationTransactionDetails,
            },
          };
          updateUserProfile({ data });
        });
    };

    function handleProjecCompleted() {
      const param = {
        type: 'projectCompleted',
        expertId: currentListing?.id?.uuid,
        projectId: currentTransaction?.id?.uuid,
      };
      handleExpertDetails(param);
    }

    const askUserConfirm = (id, text) => {
      const emails = [providerEmail];
      collaborationMetaData?.length &&
        collaborationMetaData.forEach(e => {
          if (e.invitedByUserRole !== CUSTOMER_INVITEE) {
            emails.push(e.email);
          }
        });
      const emailParams = {
        projectTitle:
          currentTransaction?.attributes?.protectedData?.brief?.title ||
          currentTransaction?.listing?.attributes?.title,
        customerName: currentUser?.attributes?.profile?.firstName,
        providerName: currentTransaction?.id && getUserDetails(currentTransaction?.provider).firstName,
        step: PROJECT_COMPLETE_ALERT,
        currentUser,
        userEmail: emails,
        currentPath: expertProfileUrl,
        subject: OFFLINE_FINAL_PAYMENT_SUBJECT,
      };

      const onComplete = () => {
        this.setState({ modalAskedOpen: false });
        if (isCollaborator) {
          onCompleteProposalThroughIntegration(currentTransaction, emailParams);
          updateCollaboratorMetaData();
          handleProjecCompleted();
        } else if (paymentMode === OFFLINE) {
          const completeProposalArgs = {
            currentTransaction,
            emailParams,
            currentUser,
          };
          onCompleteProposal(completeProposalArgs);
          updateCollaboratorMetaData();
          handleProjecCompleted();
        } else {
          this.setState({ checkoutModal: true });
        }
      };
      // const onPay = () => {
      //   this.setState({ modalAskedOpen: false });
      //   setTimeout(onPayProposal(currentTransaction?.id?.uuid), 1100);
      // };
      return (
        <Menu onToggleActive={this.onToggleActive} isOpen={this.state.modalAskedOpen}>
          <MenuLabel className={css.panelTopControl}>
            <div className={css.buttonMarkProject}>
              <FormattedMessage id={id} />
            </div>
          </MenuLabel>
          <MenuContent
            contentClassName={css.modalAskedUser}
            className={css.userMessage}
            arrowPosition={css.arrowPosition}
          >
            <MenuItem key="label" className={css.titleModalAskedUser}>
              <span className={css.completePopup}>
                <FormattedMessage id="TransactionPanel.askedUserMessage" values={{ text: text }} />
              </span>
              <div className={css.blockButtonModalAsked}>
                {stateData.showCompleteButton && <button onClick={onComplete}>Confirm</button>}
                <button
                  className={css.buttonAskCancel}
                  onClick={() => this.setState({ modalAskedOpen: false })}
                >
                  cancel
                </button>
              </div>
            </MenuItem>
          </MenuContent>
        </Menu>
      );
    };

    const handlePaymentStatus = async type => {
      const isOnline = type === ONLINE;
      const isOffline = type === OFFLINE;
      if (!isOnline && !isOffline) {
        throw new Error('Invalid payment type');
      }
      const metadata = {
        isTransactionRead: false,
        isPaymentOnline: isOnline,
        isPaymentOffline: isOffline,
        customerEmail: currentTransaction?.customer?.attributes?.profile?.publicData?.email,
        providerEmail: currentTransaction?.provider?.attributes?.profile?.publicData?.email,
      };
      const emailParams = {
        projectTitle:
          currentTransaction?.attributes?.protectedData?.brief?.title ||
          currentTransaction?.listing?.attributes?.title,
        customerName: currentUser?.attributes?.profile?.firstName,
        step: OFFLINE_PAYMENT_STEP,
        subject: OFFLINE_PAYMENT_SUBJECT,
        clientEmail: currentUser?.attributes?.email,
      };
      const params = { currentTransaction, metadata, type, emailParams };
      updateCollaboratorMetaData();
      await onUpdateFinalPaymentInfo(params);
    };

    const handleScheduleInterview = async params => {
      const isFirstInterview = this.state.isFirstInterview === 1;
      const transition = isFirstInterview
        ? TRANSITION_REQUEST_FIRST_INTERVIEW
        : TRANSITION_REQUEST_SECOND_INTERVIEW;
      return await onRequestScheduleInterview({ ...params, currentTransaction, transition });
    };

    const handleInterviewOverChat = async () => {
      const id = currentTransaction?.id?.uuid;
      const isFirstInterview = this.state.isFirstInterview === 1;
      const transition = isFirstInterview
        ? TRANSITION_REQUEST_FIRST_INTERVIEW
        : TRANSITION_REQUEST_SECOND_INTERVIEW;
      const param = { firstInterviewData: null };

      const response = await onRequestCaseStudy({
        transaction: currentTransaction,
        transition,
        param,
        isTransition: true,
      });
      if (response?.data?.data?.id) {
        this.setState({ modal: false });
        history.push(
          createResourceLocatorString(
            'TransactionDetailsPage',
            routeConfiguration(),
            { id: currentTransaction?.id?.uuid, tab: TRANSACTION_CHAT_PAGE },
            {}
          )
        );
      }
    };

    const HandleBriefButton = props => {
      const { onDecline, onAccept, transaction } = props;
      const transactionId = transaction?.id?.uuid;
      return (
        <div className={css.actionButton}>
          <BorderButton
            className={css.declineButton}
            onClick={() => onDecline(transaction, TRANSITION_DECLINE_BRIEF)}
          >
            <FormattedMessage id="TransactionPanel.declineBriefButton" />
          </BorderButton>
          <button className={css.acceptButton} onClick={onAccept}>
            <FormattedMessage id="TransactionPanel.acceptBriefButton" />
          </button>
        </div>
      );
    };

    const HandleProposalButton = props => {
      const { onDecline, onAccept, transaction } = props;
      return (
        <div className={css.wrapperButtonProposal}>
          <button
            className={css.buttonAction}
            onClick={() =>
              isCollaborator
                ? onAcceptProposalAfterCsmInvitation({ currentTransaction })
                : onAccept()
            }
          >
            {actionInProgress ? (
              <IconSpinner strokeColor="white" />
            ) : (
              <FormattedMessage id="TransactionPanel.acceptProposalButton" />
            )}
          </button>
          <button
            className={css.buttonDecline}
            onClick={() =>
              isCollaborator
                ? onDeclineProposalAfterCsmInvitation({ currentTransaction })
                : onDecline(transaction)
            }
          >
            <FormattedMessage id="TransactionPanel.declineButton" />
          </button>
        </div>
      );
    };
    const handlePayNowButton = () => {
      return (
        <>
          {!!currentTransaction?.id &&
          (isClient || isCsm) &&
          [
            TRANSITION_ACCEPT_PROPOSAL_AFTER_BRIEF,
            TRANSITION_ACCEPT_PROPOSAL,
            TRANSITION_PROPOSAL_ACCEPT_AFTER_CSM_INVITE_OPERATOR,
            TRANSITION_ACCEPT_PROPOSAL_OPERATOR,
          ]?.includes(lastTransition) ? (
            <Menu onToggleActive={this.onToggleActive} isOpen={this.state.modalAskedOpen}>
              <MenuLabel className={css.panelTopControl}>
                <Button className={css.buttonMarkProject}>
                  <FormattedMessage id="TransactionPanel.payNowButton" />
                </Button>
              </MenuLabel>
              <MenuContent
                contentClassName={css.modalAskedUser}
                arrowPosition={css.arrowPosition}
                className={css.askedModalBox}
              >
                <MenuItem key="label" className={css.titleModalAskedUser}>
                  <FormattedMessage id="TransactionPanel.paymentMethod" />
                  <div className={css.blockButtonModalAsked}>
                    <button onClick={() => handlePaymentStatus(OFFLINE)}>
                      <FormattedMessage id="TransactionPanel.checkoutModalButtonTextOffline" />
                    </button>
                    <button onClick={() => this.setState({ checkoutModal: true })} disabled={isCsm}>
                      <FormattedMessage id="TransactionPanel.checkoutModalButtonTextOnline" />
                    </button>
                  </div>
                </MenuItem>
              </MenuContent>
            </Menu>
          ) : null}
        </>
      );
    };

    const HandleReviewButton = () => {
      if (isCollaborator) return null;
      return (
        <>
          {isProvider
            ? !(
                lastTransition === TRANSITION_REVIEW_1_BY_PROVIDER ||
                lastTransition === TRANSITION_REVIEW_2_BY_PROVIDER
              ) && (
                <button className={css.checkoutModalButton} onClick={this.onOpenReviewModal}>
                  <FormattedMessage id="TransactionPanel.leaveReview" />
                </button>
              )
            : isCustomer &&
              !(
                lastTransition === TRANSITION_REVIEW_1_BY_CUSTOMER ||
                lastTransition === TRANSITION_REVIEW_2_BY_CUSTOMER
              ) && (
                <button className={css.checkoutModalButton} onClick={this.onOpenReviewModal}>
                  <FormattedMessage id="TransactionPanel.leaveReview" />
                </button>
              )}
        </>
      );
    };

    const ZohoContract = () => {
      return (
        <>
          {currentTransaction?.id &&
            this.state.showConfirmContractBox &&
            [TRANSITION_ACCEPT_PROPOSAL_AFTER_BRIEF, TRANSITION_ACCEPT_PROPOSAL]?.includes(
              lastTransition
            ) &&
            isContractConfirm === undefined && (
              <div className={css.confirmBox}>
                <p>
                  <FormattedMessage id="TransactionPanel.isContractMessage" />
                </p>
                <div className={css.buttons}>
                  <button
                    className={css.acceptButton}
                    onClick={() => {
                      this.setState({ showConfirmContractBox: false });
                      handleConfirmContract(true);
                      history.push(
                        createResourceLocatorString(
                          'TransactionDetailsPage',
                          routeConfiguration(),
                          { id: currentTransaction?.id?.uuid, tab: TRANSACTION_COLLABORATORS_PAGE },
                          {}
                        )
                      );
                    }}
                  >
                    <FormattedMessage id="TransactionPanel.confirmContract" />
                  </button>
                  <button
                    className={css.rejectButton}
                    onClick={() => {
                      this.setState({ showConfirmContractBox: false });
                      handleConfirmContract(false);
                    }}
                  >
                    <FormattedMessage id="TransactionPanel.rejectContract" />
                  </button>
                </div>
              </div>
            )}
        </>
      );
    };

    const collaborationTransactionData = {
      briefTitle: currentTransaction?.attributes?.protectedData?.brief?.title ?? null,
      briefSentAt: currentTransaction?.attributes?.protectedData?.brief?.briefSentAt ?? null,
      briefCreatedAt: currentTransaction?.attributes?.protectedData?.brief?.createdAt ?? null,
      briefAuthor: customerName ?? null,
      proposalAuthor: expertName ?? null,
      proposalSentAt: currentTransaction?.attributes?.metadata?.proposal?.proposalSentAt ?? null,
      listingImage:
        currentTransaction?.listing?.images?.[0]?.attributes?.variants?.['square-small2x']?.url ??
        null,
      inviteStatus: INVITE_STATUS_PENDING,
      isTransactionRead: false,
      hasUserSigned: false,
    };

    const handleCsmInvite = async values => {
      const currentPath = typeof window !== 'undefined' && window.location.origin;

      const { firmMemberEmail } = values;

      const fnParams = {
        currentTransaction,
        protectedData: {
          brief,
          chainedTransactionId: currentTransaction.id.uuid,
          chainedTransactionCreatedAt: moment(currentTransaction.attributes.createdAt).format(
            'MMMM D, YYYY'
          ),
        },
      };
      const resp = await onSendCSMInviteToFirmMemberToAccpetProposal(fnParams);
      if (resp?.id) {
        this.setState({ openCollaboratorModalState: false });
        const chainTxnId = resp.id.uuid;
        const currentUserName =
          currentUser?.id &&
          getUserDetails(currentUser)
            ?.fullName?.split(' ')
            ?.at(0);
        const currentUserEmail = currentUser?.id && getUserDetails(currentUser)?.email;
        const inviteArgs = {
          userEmail: firmMemberEmail,
          authorEmail: currentUserEmail,
          authorName: currentUserName,
          listingTitle,
          firmName,
          currentPath: `${currentPath}/invited-transaction/${chainTxnId}`,
          currentTransactionId: chainTxnId,
        };
        const isUserExist = collaborationMetaData?.find(user => user.email === firmMemberEmail);
        const userRoleArgs = {
          userEmail: firmMemberEmail,
          currentUser,
        };
        if (!isUserExist) {
          collaborationMetaData.push({
            email: firmMemberEmail,
            status: INVITE_STATUS_PENDING,
            invitationExpiredAt: moment()
              .add(14, 'days')
              .format('YYYY-MM-DD HH:mm:ss'),
            currentTransactionId: chainTxnId,
            invitedBy: currentUser?.id?.uuid,
            invitedByUserRole: checkUserRole(userRoleArgs),
            isCsmInvite: true,
          });
        }
        const response = await onCollaborationInvite(
          { ...inviteArgs },
          { ...collaborationTransactionData, id: chainTxnId },
          collaborationMetaData
        );
        if (response) {
          this.setState({ openCollaboratorModalState: false, csmInvitationToasterState: true });
          redirectToMainTransaction(chainTxnId ? chainTxnId : currentTransaction.id.uuid);
        }
      }
      setTimeout(() => {
        this.setState({ csmInvitationToasterState: false });
        // onSendCSMInviteToFirmMemberToAccpetProposal(args)
      }, 3000);
    };

    return (
      <>
        {currentTransaction?.id ? (
          <div className={classes}>
            <div className={css.container}>
              {csmInvitationToasterState ? (
                <div className={css.csmInviteSuccessMessage}>
                  <FormattedMessage id="TransactionPanel.csmInviteSuccessToasterMessage" />
                </div>
              ) : null}
              {milestoneVisibility?.map(milestone => (
                <div key={milestone.id}>
                  {isMilestoneDueSoon(milestone) && (
                    <div className={css.warningAlert}>
                      <div className={css.dueBox}>
                        <span className={css.dueBold}>
                          <FormattedMessage id="TransactionPanel.milestoneDueMessageHeading" />
                        </span>
                        <span className={css.descriptionDue}>
                          <FormattedMessage
                            id="TransactionPanel.milestoneDueMessage"
                            values={{ milestoneTitle: milestone?.title }}
                          />
                        </span>
                      </div>
                      <span
                        onClick={() => handleRemoveReminder(milestone?.id)}
                        className={css.closeButton}
                      >
                        <IconCard brand={CROSS_SIGN} />
                      </span>
                    </div>
                  )}
                  {isMilestoneOverdue(milestone) && (
                    <div className={css.dangerAlert}>
                      <div className={css.dueBox}>
                        <span className={css.dueBold}>
                          <FormattedMessage id="TransactionPanel.milestoneOverdueMessageHeading" />
                        </span>
                        <FormattedMessage
                          id="TransactionPanel.milestoneOverdueMessage"
                          values={{ milestoneTitle: milestone?.title }}
                        />
                      </div>
                      <span
                        className={css.closeButton}
                        onClick={() => handleRemoveReminder(milestone?.id)}
                      >
                        <IconCard brand={CROSS_SIGN} />
                      </span>
                    </div>
                  )}
                </div>
              ))}
              <div className={css.header}>
                <div className={css.leftHeader}>
                  <h1>{listingTitle}</h1>

                  <div className={css.profileBox}>
                    <div
                      className={classNames(
                        authorRole === USER_ROLE_CSM && css.csmImageBox,
                        css.meetingImage
                      )}
                      data-tooltip={
                        isAuthorLinkedToFirm
                          ? `This user is a Customer Success Manager working on behalf of ${authorFirmName} on the InsightGig platform.`
                          : 'This user is a Customer Success Manager (CSM) on the InsightGig platform. CSMs work on behalf of their clients.'
                      }
                    >
                      <Avatar user={ensuredAuthor} />
                      {connectedUsers?.[ensuredAuthor.id.uuid]?.userId ? (
                        <span className={css.activeIcon} />
                      ) : (
                        <span className={css.offlineIcon} />
                      )}
                    </div>
                    <div className={css.meetingDetail}>
                      <div className={css.name}>{authorDisplayName}</div>
                      <div className={css.location}>
                        <span className={css.locationIcon}>
                          <IconCard brand="location" />
                        </span>
                        {customerLocation}
                      </div>
                      <div className={css.review}>
                        <ShowReview
                          currentTransaction={currentTransaction}
                          author={ensuredAuthor}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {isChatExpired || isJobOrApplicationExpired ? null : (
                  <span
                    className={css.scheduleHeading}
                    onClick={() => {
                      if (isJobListing) {
                        this.setState({ scheduleMeetingNoTransition: true });
                      }
                      this.setState({ modal: true });
                      sessionStorage.removeItem('params');
                    }}
                  >
                    <FormattedMessage id="TransactionPanel.ScheduleMeeting" />
                  </span>
                )}
              </div>
              {meetingSuccessToaster && (
                <div className={css.successMessage}>
                  <TosterMessage
                    message={'Meeting successfully created. Please check your calendar.'}
                    onClose={() => setMeetingSuccessToaster(false)}
                  />
                </div>
              )}
              {(meetingError || zoomMeetingError) && meetingErrorToaster ? (
                <p className={css.meetingError}>
                  <FormattedMessage id="GoogleMeetForm.meetingErrorMessage" />
                </p>
              ) : null}

              <Modal
                id="TransactionPanel.sendCsmInviteToFirmMember"
                isOpen={this.state.openCollaboratorModalState}
                onClose={() => this.setState({ openCollaboratorModalState: false })}
                onManageDisableScrolling={onManageDisableScrolling}
              >
                <ModalSendCsmTransactionInviteForm
                  onSubmit={handleCsmInvite}
                  firmData={firmData}
                  currentUser={currentUser}
                  updateInProgress={sendCsmInviteInProgress}
                />
              </Modal>
              <Modal
                id="TransactionPanel.createMeeting"
                isOpen={modal}
                onClose={() => this.setState({ modal: false, meetingToggle: '' })}
                onManageDisableScrolling={onManageDisableScrolling}
                usePortal
              >
                <div className={css.meetingHeading}>
                  <FormattedMessage
                    id={
                      isJobListing && modal === 'interview'
                        ? 'TransactionPanel.interviewApplicant'
                        : 'TransactionPanel.selectYourMeeting'
                    }
                  />
                </div>
                {isJobListing && modal === 'interview' && (
                  <div className={css.interviewSubHeading}>
                    <FormattedMessage id="TransactionPanel.interviewSubHeading" />
                  </div>
                )}
                <div className={css.meetingButtonsBox}>
                  <div
                    id="googleBox"
                    className={classNames(css.meetLnk, {
                      [css.meetingBoxBorder]: this.state.meetingToggle === GOOGLE_MEET,
                    })}
                    onClick={() => this.setState({ meetingToggle: GOOGLE_MEET })}
                  >
                    <img src={googleMeetIcon} />
                    <span className={css.hoverBox}>
                      <FormattedMessage id="TransactionPanel.googleMeetHoverText" />
                    </span>
                  </div>
                  <div
                    id="zoomBox"
                    className={classNames(css.meetLnk, {
                      [css.meetingBoxBorder]: this.state.meetingToggle === ZOOM_MEET,
                    })}
                    onClick={() => this.setState({ meetingToggle: ZOOM_MEET })}
                  >
                    <img src={zoomIcon} />
                    <span className={css.hoverBox}>
                      <FormattedMessage id="TransactionPanel.zoomMeetHoverText" />
                    </span>
                  </div>
                  {isJobListing && modal === 'interview' && (
                    <div className={css.chatLink}>
                      Or arrange interview via
                      <span onClick={handleInterviewOverChat}>
                        {actionInProgress ? <IconSpinner strokeColor="#0B96F5" /> : ` chat`}
                      </span>
                    </div>
                  )}
                </div>
                <div className={css.buttonSection}>
                  <p
                    className={css.buttonCancel}
                    onClick={() => this.setState({ modal: false, meetingToggle: '' })}
                  >
                    <FormattedMessage id="TransactionPanel.cancel" />
                  </p>

                  <PrimaryButton
                    className={css.buttonSubmit}
                    disabled={!this.state.meetingToggle}
                    onClick={() => this.setState({ meetingModal: true, modal: false })}
                  >
                    <FormattedMessage id="TransactionPanel.next" />
                  </PrimaryButton>
                </div>
              </Modal>
              <Modal
                id="Googel/Zoom Panel"
                isOpen={
                  meetingModal ||
                  hasZoomCode ||
                  (typeof sessionStorage !== 'undefined' &&
                    !!JSON.parse(sessionStorage.getItem('params'))?.currentForm)
                }
                onClose={() => {
                  sessionStorage.removeItem('params');
                  this.setState({ meetingModal: false, meetingToggle: '' });
                  JSON.parse(sessionStorage.getItem('params'))?.currentForm ||
                    (hasZoomCode &&
                      history.push(
                        createResourceLocatorString(
                          TRANSACTION_DETAILS_PAGE,
                          routeConfiguration(),
                          {
                            id: currentTransaction.id.uuid,
                          }
                        )
                      ));
                }}
                onManageDisableScrolling={onManageDisableScrolling}
                usePortal
              >
                {this.state.meetingToggle === ZOOM_MEET ||
                hasZoomCode ||
                (typeof sessionStorage !== 'undefined' &&
                  JSON.parse(sessionStorage.getItem('params'))?.currentForm ===
                    ZOOM_MEETING_FORM) ? (
                  <ZoomMeetPanel
                    onManageDisableScrolling={onManageDisableScrolling}
                    currentTransaction={currentTransaction}
                    handleCreateZoomMeeting={handleCreateZoomMeeting}
                    handleEnterMeeting={handleEnterMeeting}
                    currentUser={currentUser}
                    onCreateZoomAuth={onCreateZoomAuth}
                    zoomMeetingInProgress={zoomMeetingInProgress}
                    zoomMeetingError={zoomMeetingError}
                    redirectToMainTransaction={() =>
                      redirectToMainTransaction(currentTransaction?.id?.uuid)
                    }
                    closeZoomMeetingModel={() => this.setState({ meetingModal: false })}
                    isOpen={meetingModal}
                    currentAuthor={currentCustomer}
                    currentProvider={currentProvider}
                    currentPage={TRANSACTION_DETAILS_PAGE}
                    setMeetingSuccessToaster={setMeetingSuccessToaster}
                    params={params}
                    hasZoomCode={hasZoomCode}
                  />
                ) : (
                  <GoogleMeetPanel
                    onGetGoogleAccessToken={onGetGoogleAccessToken}
                    onScheduleGoogleMeet={
                      isJobListing && !scheduleMeetingNoTransition
                        ? handleScheduleInterview
                        : onScheduleGoogleMeet
                    }
                    currentUser={currentUser}
                    currentAuthor={currentCustomer}
                    params={params}
                    history={history}
                    meetingInProgress={meetingInProgress}
                    meetingCreated={meetingCreated}
                    meetingError={meetingError}
                    closeGoogleMeetingModel={() => this.setState({ meetingModal: false })}
                    isOpen={meetingModal}
                    currentPath={currentPath}
                    currentTransaction={currentTransaction}
                    currentProvider={currentProvider}
                    setMeetingSuccessToaster={setMeetingSuccessToaster}
                    userEmailsForGoogleMeet={userEmailsForGoogleMeet}
                    isTransactionPage={true}
                  />
                )}
              </Modal>

              <div className={css.tabBoxWrapper}>
                <div className={css.tabBarLinks}>
                  {tabLink && tabLink.map(item => {
                    if (!item.tabNames) return;
                    return (
                      <span
                        key={item.tabNames}
                        className={tab === item.tabRoute ? css.tabsSelected : css.tabNavLink}
                        onClick={() => {
                          history.push(
                            createResourceLocatorString(
                              'TransactionDetailsPage',
                              routeConfiguration(),
                              { id: currentTransaction?.id?.uuid, tab: item.tabRoute },
                              {}
                            )
                          );
                          if (item.tabNames === CHAT) {
                            markNotificationAsRead();
                          }
                        }}
                      >
                        <span className={css.tabIcon}>{item.icon}</span>
                        <span className={css.tabName}>{item.tabNames} </span>
                        {item.notificationDot}
                      </span>
                    );
                  })}
                </div>
                <div className={css.rightSideTabsBar}>
                  {!!currentTransaction?.id && tab === TRANSACTION_DETAIL_PAGE ? (
                    <>
                      {!isJobListing ? (
                        <div className={css.accordionWrapper}>
                          <NotificationBar
                            currentUser={currentUser}
                            notifications={notifications}
                            currentTransaction={currentTransaction}
                            history={history}
                          />
                          {isClient && <ZohoContract />}
                          <div className={css.statusBar}>
                            <h4>
                              <FormattedMessage id="TransactionPanel.status" />
                            </h4>
                            <div className={css.rightSideStatus}>
                              <div className={css.responseText}>
                                <span className={css.statusIcon}>
                                  <IconCard brand={stateData.icon} />
                                </span>
                                <span className={css.statusText}>
                                  <FormattedMessage
                                    id={stateData.id}
                                    values={{
                                      userName: stateData.userName,
                                      collaboratorName: stateData.collaboratorName,
                                    }}
                                  />
                                </span>
                              </div>
                              {stateData.showSendProsalButtons ? (
                                <HandleBriefButton
                                  transaction={transaction}
                                  onAccept={() =>
                                    this.setState({ meetingModal: false, modalProposalOpen: true })
                                  }
                                  onDecline={onDeclineBrief}
                                />
                              ) : null}
                              {stateData.showAcceptProsalButtons ? (
                                <HandleProposalButton
                                  transaction={transaction}
                                  onAccept={onAcceptProposal}
                                  onDecline={onDeclineProposal}
                                />
                              ) : null}
                              {stateData.showCollaborationPanel ? (
                                <div
                                  className={css.inviteButtonSection}
                                  onClick={() =>
                                    this.setState({ openCollaboratorModalState: true })
                                  }
                                >
                                  <FormattedMessage id="TransactionPanel.inviteButton" />
                                </div>
                              ) : null}
                              {stateData.showPayNowButton ? handlePayNowButton() : null}

                              {stateData.showCompleteButton
                                ? askUserConfirm(
                                    'TransactionPanel.completeButton',
                                    <FormattedMessage id="TransactionPanel.completeButtonText" />
                                  )
                                : null}
                              {stateData.showReviewButton ? <HandleReviewButton /> : null}
                            </div>
                          </div>
                          <ProposalItem
                            intl={intl}
                            transaction={transaction}
                            onAccept={onAcceptProposal}
                            onDecline={onDeclineProposal}
                            onEdit={() => this.setState({ modalProposalOpen: true })}
                            showEdit={stateData.showEditProposalButton}
                            headingState={stateData.headingState}
                            transactionDocuments={transactionDocuments}
                          />
                          <BriefItem intl={intl} transaction={transaction} />
                        </div>
                      ) : (
                        <JobDescription
                          transaction={currentTransaction}
                          isClient={isClient}
                          customerName={customerName}
                          expertName={expertName}
                          onManageDisableScrolling={onManageDisableScrolling}
                          intl={intl}
                          actionInProgress={actionInProgress}
                          openInterviewModal={value =>
                            this.setState({ modal: 'interview', isFirstInterview: value })
                          }
                          currentUser={currentUser}
                          expertEmail={providerEmail}
                          customerEmail={customerEmail}
                          openReviewModal={() => this.setState({ isReviewModalOpen: true })}
                          notifications={notifications}
                          history={history}
                        />
                      )}
                    </>
                  ) : [TRANSACTION_CHAT_PAGE, TRANSACTION_ACTIVITY_PAGE].includes(tab) ? (
                    <ChatBar
                      intl={intl}
                      sendMessageError={sendMessageError}
                      onFocus={this.onSendMessageFormFocus}
                      onBlur={this.onSendMessageFormBlur}
                      onSubmit={this.onMessageSubmit}
                      otherUserDisplayNameString={otherUserDisplayNameString}
                      currentTransaction={currentTransaction}
                      currentUser={currentUser}
                      fetchMessagesError={fetchMessagesError}
                      fetchMessagesInProgress={fetchMessagesInProgress}
                      initialMessageFailed={initialMessageFailed}
                      messages={messages}
                      chainedMessages={chainedMessages}
                      oldestMessagePageFetched={oldestMessagePageFetched}
                      onShowMoreMessages={onShowMoreMessages}
                      totalMessagePages={totalMessagePages}
                      onOpenReviewModal={this.onOpenReviewModal}
                      setClearForm={this.setClearForm}
                      clearAttachmentForm={this.state.clearAttachmentForm}
                      transaction={transaction}
                      isMobSaf={this.isMobSaf}
                      connectedUsers={connectedUsers}
                      activeTab={tab}
                      isJobListing={isJobListing}
                      authorRole={authorRole}
                      isAuthorLinkedToFirm={isAuthorLinkedToFirm}
                      authorFirmName={authorFirmName}
                      message={message}
                      socket={socket}
                      unreadMessages={unreadMessages}
                      setMessage={setMessage}
                      onManageDisableScrolling={onManageDisableScrolling}
                      isOnline={isOnline}
                      isJobOrApplicationExpired={isJobOrApplicationExpired}
                    />
                  ) : tab === TRANSACTION_MILESTONE_PAGE ? (
                    <MilestonesItem
                      typeUser={typeUser}
                      transaction={transaction}
                      onChange={onChangeMilestone}
                      onDelete={onDeleteMilestone}
                      onToggle={onToggleMilestone}
                      intl={intl}
                      actionInProgress={actionInProgress}
                      editable={!!stateData.editMilestones}
                      currentUser={currentUser}
                      onManageDisableScrolling={onManageDisableScrolling}
                      collaborationMetaData={collaborationMetaData}
                    />
                  ) : tab === TRANSACTION_COLLABORATORS_PAGE ? (
                    <>
                      {isContractConfirm && (
                        <ZohoSign
                          currentTransaction={currentTransaction}
                          isClient={isClient}
                          closeTab={() => this.setState({ activeTab: OVERVIEW })}
                          isCollaborator={isCollaborator}
                        />
                      )}
                      {isClient && <ZohoContract />}
                      <CollaborationPanel
                        currentTransaction={currentTransaction}
                        connectedUsers={connectedUsers}
                        lastTransition={lastTransition}
                        isOnline={isOnline}
                        isContractConfirm={isContractConfirm}
                        isContractSigned={isContractSigned}
                        typePartner={typePartner}
                        processName={processName}
                      />
                    </>
                  ) : tab === TOOLS ? (
                    <div className={css.toolsGrid}>
                      <IntegrationCard />
                    </div>
                  ) : tab === TRANSACTION_FILES_PAGE ? (
                    <div className={css.toolsGridFiles}>
                      <BoxComponent
                        transaction={currentTransaction}
                        providerEmail={providerEmail}
                        customerEmail={customerEmail}
                      />
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
            <ReviewModal
              id="ReviewOrderModal"
              isOpen={this.state.isReviewModalOpen}
              onCloseModal={() => this.setState({ isReviewModalOpen: false })}
              onManageDisableScrolling={onManageDisableScrolling}
              onSubmitReview={values => {
                this.onSubmitReview(values, isClient, updateCollaboratorMetaData);
              }}
              revieweeName={otherUserDisplayName}
              reviewSent={this.state.reviewSubmitted}
              sendReviewInProgress={sendReviewInProgress}
              sendReviewError={sendReviewError}
            />
            {this.state.modalProposalOpen && (
              <ModalProposal
                proposalData={transaction.attributes.metadata.proposal}
                id="ModalProposal.TransactionPage"
                isOpen={this.state.modalProposalOpen}
                onClose={() => this.setState({ modalProposalOpen: false })}
                onManageDisableScrolling={onManageDisableScrolling}
                onSubmit={this.handleProposal}
                inProgress={actionInProgress}
                documents={transactionDocuments}
              />
            )}

            {paymentMode === OFFLINE ? null : isClient ? (
              <CheckoutModal
                currentTransaction={currentTransaction}
                isOpen={this.state.checkoutModal}
                onClose={() => {
                  this.setState({ checkoutModal: false });
                  paymentIntentId &&
                    history.push(
                      createResourceLocatorString(TRANSACTION_DETAILS_PAGE, routeConfiguration(), {
                        id: currentTransaction?.id?.uuid,
                      })
                    );
                }}
                onlinePaymentState={handlePaymentStatus}
                params={params}
                intl={intl}
                isProvider={isProvider}
                onFetchTransactionLineItems={onFetchTransactionLineItems}
                isFinal={isFinal}
                stripeConnectAccountId={stripeConnectAccountId}
                isProviderIndianOrigin={isProviderIndianOrigin}
              />
            ) : null}
          </div>
        ) : null}
      </>
    );
  }
}

TransactionPanelComponent.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
  acceptSaleError: null,
  declineSaleError: null,
  fetchMessagesError: null,
  initialMessageFailed: false,
  savePaymentMethodFailed: false,
  sendMessageError: null,
  sendReviewError: null,
  monthlyTimeSlots: null,
  nextTransitions: null,
  lineItems: null,
  fetchLineItemsError: null,
};

TransactionPanelComponent.propTypes = {
  rootClassName: string,
  className: string,

  currentUser: propTypes.currentUser,
  // transaction: propTypes.transaction.isRequired,
  totalMessagePages: number.isRequired,
  oldestMessagePageFetched: number.isRequired,
  messages: arrayOf(propTypes.message).isRequired,
  initialMessageFailed: bool,
  savePaymentMethodFailed: bool,
  fetchMessagesInProgress: bool.isRequired,
  fetchMessagesError: propTypes.error,
  sendMessageInProgress: bool.isRequired,
  sendMessageError: propTypes.error,
  sendReviewInProgress: bool.isRequired,
  sendReviewError: propTypes.error,
  onFetchTimeSlots: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  onShowMoreMessages: func.isRequired,
  onSendMessage: func.isRequired,
  onSendReview: func.isRequired,
  // onSubmitBookingRequest: func.isRequired,
  monthlyTimeSlots: object,
  nextTransitions: array,

  // Sale related props
  onAcceptSale: func.isRequired,
  onDeclineSale: func.isRequired,
  acceptInProgress: bool.isRequired,
  declineInProgress: bool.isRequired,
  acceptSaleError: propTypes.error,
  declineSaleError: propTypes.error,

  // line items
  onFetchTransactionLineItems: func.isRequired,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,

  // from injectIntl
  intl: intlShape,
};

const TransactionPanel = injectIntl(TransactionPanelComponent);

export default TransactionPanel;
