import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ensureListing, ensureUser } from '../../util/data';
import css from './CollaborationPanel.module.css';
import { FormattedMessage, injectIntl } from '../../util/reactIntl';
import {
  CUSTOMER,
  INVITE_STATUS_ACTIVE,
  INVITE_STATUS_PENDING,
  PROVIDER,
  USER_ROLE_PARTNER,
  CUSTOMER_INVITEE,
  PROVIDER_INVITEE,
  THIRD_PARTY_EXPERT,
  USER_ROLE_CSM,
  TRANSACTION_BRIEF_PROCESS,
  COLLABORATOR_INVITE_STEP,
  TRANSACTION_GIG_APPS_PROCESS,
  MAX_COLLABORATORS_LIMIT,
} from '../../util/types';
import Modal from '../Modal/Modal';
import { manageDisableScrolling } from '../../ducks/UI.duck';
import { InviteFirmMemberForm } from '../../forms';
import {
  collaborationInvite,
  collaborationInviteClearSuccessToaster,
  getCustomerData,
  removeMemberFromTransactionPage,
} from '../../containers/TransactionPage/TransactionPage.duck';
import moment from 'moment';
import { compose } from 'redux';
import { withViewport } from '../../util/contextHelpers';
import { withRouter } from 'react-router-dom';
import { collaborator } from '../../ducks/user.duck';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { getUserTxRole, txIsInProgress, txIsPrepaid, txIsPrepaidUsingStripe, txRoleIsCustomer } from '../../util/transaction';
import { checkUserRole, getUserDetails } from '../../util/destructorHelpers';
import classNames from 'classnames';
import NamedLink from '../NamedLink/NamedLink';
import { getUserRole } from '../../util/userRole';
import IconBannedUser from '../IconBannedUser/IconBannedUser';
import { getUserShortName } from '../../util/typeHelpers';
import { PrimaryButton } from '../Button/Button';

const CollaborationPanel = props => {
  const { currentTransaction, processName, isGigAppTransaction } = props;

  const [openMaxCollaboratorLimitModal, setOpenMaxCollaboratorLimitModal] = useState(false);
  const [openInvitationModal, setOpenInvitationModal] = useState(false);
  const [inviteSuccessToaster, setInviteSuccessToaster] = useState(false);
  const [toggle, setToggle] = useState(false);
  const { firmId, sendInviteInProgress, invitationSent, sendInviteError } = useSelector(
    state => state.TransactionPage
  );

  const state = useSelector(state => state);
  const { currentUser = null } = state.user;
  const dispatch = useDispatch();
  const currentProvider = !!currentTransaction?.id && currentTransaction?.provider;
  const currentCustomer = !!currentTransaction?.id && currentTransaction?.customer;


  function getEnsuredUsers(processName, currentProvider, currentCustomer) {
    let ensuredProvider, ensuredCustomer;

    switch (processName) {
      case TRANSACTION_BRIEF_PROCESS:
        ensuredProvider = ensureUser(currentCustomer);
        ensuredCustomer = ensureUser(currentProvider);
        break;
      case TRANSACTION_GIG_APPS_PROCESS:
        ensuredProvider = ensureUser(currentProvider);
        ensuredCustomer = ensureUser(currentCustomer);
        break;
      default:
        ensuredProvider = ensureUser(currentProvider);
        ensuredCustomer = ensureUser(currentCustomer);
    }

    return { ensuredProvider, ensuredCustomer };
  }

  const { ensuredProvider, ensuredCustomer } = getEnsuredUsers(processName, currentProvider, currentCustomer);
  const ensuredCurrentUser = ensureUser(currentUser);
  const [usersData, setUsersData] = useState([])
  const isCsm = isGigAppTransaction ? getUserRole(ensuredProvider) === USER_ROLE_CSM : getUserRole(ensuredCustomer) === USER_ROLE_CSM;

  const providerProfileImage = getUserDetails(ensuredProvider)?.profileImage;
  const customerProfileImage = getUserDetails(ensuredCustomer)?.profileImage;
  const providerName = getUserDetails(ensuredProvider)?.fullName;
  const customerName = getUserDetails(ensuredCustomer)?.fullName;
  const providerEmail = getUserDetails(ensuredProvider)?.email;
  const customerEmail = getUserDetails(ensuredCustomer)?.email;
  const currentUserName = getUserDetails(ensuredCurrentUser)?.fullName?.split(' ')?.at(0);
  const currentUserEmail = getUserDetails(ensuredCurrentUser)?.email;
  const customerId = getUserDetails(ensuredCustomer)?.id;
  const providerId = getUserDetails(ensuredProvider)?.id;
  const listingTitle = !!currentTransaction?.id && currentTransaction?.attributes?.protectedData?.brief?.title;

  const authorType =
    !!ensuredCurrentUser?.id && ensuredCurrentUser?.attributes?.profile?.protectedData?.Role
      ? ensuredCurrentUser?.attributes?.profile?.protectedData?.Role
      : ensuredCurrentUser?.attributes?.profile?.publicData?.role;
  const collaborationMetaData =
    currentTransaction?.id && currentTransaction?.attributes?.metadata?.collaborationMetaData
      ? currentTransaction?.attributes?.metadata?.collaborationMetaData
      : [];

  const currentPath = typeof window !== 'undefined' && window.location.origin;
  const getListing = id => {
    const ref = { id, type: 'listing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };
  const firmListing = ensureListing(getListing(firmId));
  const firmMetadata = !!firmListing?.id && firmListing?.attributes?.metadata;
  const firmData = Array.isArray(firmMetadata.firmData) && firmMetadata.firmData;
  const firmName = firmListing?.id && firmListing?.attributes?.title;
  const userRole = currentUser?.id && getUserRole(currentUser);

  useEffect(() => {
    if (invitationSent) {
      setInviteSuccessToaster(true);
      const timer = setTimeout(() => {
        setInviteSuccessToaster(false);
        dispatch(collaborationInviteClearSuccessToaster());
      }, 3000);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [invitationSent]);
  const handleToggleState = () => {
    setToggle(prev => !prev);
  };

  const collaborationTransactionData = {
    id: currentTransaction?.id?.uuid,
    briefTitle: currentTransaction?.attributes?.protectedData?.brief?.title,
    briefCreatedAt: currentTransaction?.attributes?.protectedData?.brief?.createdAt,
    briefAuthor: currentTransaction?.provider?.attributes?.profile?.displayName,
    proposalAuthor: currentTransaction?.customer?.attributes?.profile?.displayName,
    proposalSentAt: currentTransaction?.attributes?.metadata?.proposal?.proposalSentAt,
    listingImage: currentTransaction?.listing?.images?.at(0)?.attributes?.variants?.['square-small2x']?.url,
    inviteStatus: INVITE_STATUS_PENDING,
    isTransactionRead: false,
    hasUserSigned: false,
    currentTransaction,
    invitedByUserRole: currentUser?.id && getUserRole(currentUser)

  };
  const isCollaborator = !!collaborator(ensuredCurrentUser, currentTransaction);
  const ownRole = getUserTxRole(ensuredCurrentUser?.id, currentTransaction);
  const isCustomer = !!ownRole && txRoleIsCustomer(ownRole);

  const newFirmAuthor = firmListing && firmListing?.attributes?.publicData?.newAuthor;
  const currentFirmAuthor = newFirmAuthor ? newFirmAuthor : firmListing?.id && firmListing?.author;
  const isPrepaymentDone = !!currentTransaction?.id && (txIsPrepaid(currentTransaction) || txIsInProgress(currentTransaction) || txIsPrepaidUsingStripe(currentTransaction));
  const showInviteButton = isPrepaymentDone || processName === TRANSACTION_GIG_APPS_PROCESS;
  const clientFirmName = getUserDetails(ensuredCustomer)?.firmName;
  const csmTooltipText = clientFirmName
    ? `This user is a Customer Success Manager working on behalf of ${clientFirmName} on the InsightGig platform.`
    : 'This user is a Customer Success Manager (CSM) on the InsightGig platform. CSMs work on behalf of their clients.';

  const handleOpenInviteModal = () => {
    const inviteesCount = collaborationMetaData.filter(member => member.invitedBy === currentUser?.id.uuid).length;
    const inviteesLimitExceeded = inviteesCount >= MAX_COLLABORATORS_LIMIT;

    if (inviteesLimitExceeded) {
      setOpenMaxCollaboratorLimitModal(true);
    } else {
      setOpenInvitationModal(true);
    }
  }

  const handleSendEmail = async values => {
    const { userEmail } = values;

    const isUserExist = collaborationMetaData?.find(user => user.email === (userEmail || values));
    const userRoleArgs = {
      userEmail,
      currentUser: ensuredCurrentUser,
      firmData,
      currentFirmAuthor
    }

    if (!isUserExist) {
      collaborationMetaData.push({
        email: userEmail ? userEmail : values,
        status: INVITE_STATUS_PENDING,
        invitationExpiredAt: moment()
          .add(14, 'days')
          .format('YYYY-MM-DD HH:mm:ss'),
        currentTransactionId: currentTransaction?.id?.uuid,
        invitedBy: ensuredCurrentUser?.id?.uuid,
        invitedByUserRole: checkUserRole(userRoleArgs),
      });
    }
    const params = {
      userEmail: userEmail ? userEmail : values,
      authorEmail: currentUserEmail,
      authorName: currentUserName,
      listingTitle,
      firmName,
      currentPath: `${currentPath}/invited-transaction/${currentTransaction?.id?.uuid}`,
      currentTransactionId: currentTransaction?.id?.uuid,
      step: COLLABORATOR_INVITE_STEP,
      collaborationMetaData
    };
    dispatch(
      collaborationInvite({ ...params }, { ...collaborationTransactionData, inviteeRole: checkUserRole(userRoleArgs) })
    );
    setOpenInvitationModal(false);
  };

  const handleRemoveUser = async (index, firm) => {
    if (
      typeof window !== 'undefined' &&
      window?.confirm('Are you sure you want to remove this user from collaborating on this project?')
    ) {
      // dispatch(updateFirmMemberProfile(fd));
      const updateDeletedUserProfile = await dispatch(
        removeMemberFromTransactionPage({
          collaboratorEmail: firm?.email,
          firmName,
          listingTitle,
          currentTransaction,
          authorName: currentUserName,
          authorEmail: currentUserEmail,
          currentUser: ensuredCurrentUser

        })
      );
    }
  };

  useEffect(() => {
    let collaboratorEmails = [];
    collaborationMetaData.forEach(e => collaboratorEmails.push(e.email));

    collaboratorEmails.forEach(email => {
      dispatch(getCustomerData(email))
        .then(user => {
          setUsersData(oldArray => [...oldArray, user])
        }).catch(error => console.log(error))
    })
  }, [])

  const getUserFirmInfo = (email) => {
    let firmTitle, firmId;
    usersData?.length && usersData.map(user => {
      if (user?.attributes?.email === email) {
        const { publicData = {} } = user.attributes?.profile;
        firmId = publicData?.firmId || publicData?.linkedToFirms?.[0].firmId;
        firmTitle = publicData?.firmListing?.firmTitle || publicData?.linkedToFirms?.[0].firmName;
      }
    })
    return { firmTitle, firmId };
  }

  //Client and Expert Lead card 
  const LeadCard = ({ email, profileImage, name, tag, user, tagClassName, isCsm }) => {
    return (
      <div className={css.collaborationCard}>
        <div className={css.cardTop}>
          <span className={classNames(isCsm && css.csmImageBoxCard)} data-tooltip={csmTooltipText}>
            {!email ? (
              <IconBannedUser className={css.profileImage} />
            ) : profileImage ? (
              <img src={profileImage} alt={'profileImage'} className={css.profileImage} />
            ) : (
              <div className={css.profileImage}>
                {!!name && name?.match(/(\b\S)?/g).join('').toUpperCase()}
              </div>
            )}
          </span>
          <div className={css.authorName}>{name}</div>
          <div className={css.dividerLine} />
          <div className={css.bottomBox}>
            <div>
              <div className={css.projectTitle}>
                {getUserDetails(user).firmName &&
                  <NamedLink
                    className={css.firmTitle}
                    name="EditBecomeInsightGigPartnerPageDetails"
                    params={{
                      id: getUserDetails(user).firmId,
                      slug: getUserDetails(user).firmName
                    }}
                  >
                    <FormattedMessage
                      id="CollaborationPanel.firmTitle"
                      values={{ "firmTitle": getUserDetails(user).firmName }}
                    />
                  </NamedLink>
                }
              </div>
              <div className={css.authorEmail}>{email}</div>
            </div>
            <div>
              <div className={css.userRole}>
                <span className={tagClassName}>{tag}</span>
              </div>
              <div className={css.userStatus}><span>{email ? INVITE_STATUS_ACTIVE : '-'}</span></div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  //Client and Expert Lead Table row
  const LeadTableRow = ({ email, profileImage, name, tag, user, tagClassName, isCsm }) => {
    return (
      <tr>
        <td>
          <div className={css.authorNameDetails}>
            <span className={classNames(isCsm && css.csmImageBox, css.profileIcon)} data-tooltip={csmTooltipText}>
              {!email ? (
                <IconBannedUser className={css.profileImage} />
              ) : profileImage ? (
                <img src={profileImage} alt={'profileImage'} className={css.profileImage} />
              ) : (
                <div className={css.profileImage}>
                  {!!name && name?.match(/(\b\S)?/g).join('').toUpperCase()}
                </div>
              )}
            </span>
            <div className={css.providerName}>
              <div className={css.fullName}>{name}</div>
              {getUserDetails(user).firmName &&
                <NamedLink
                  className={css.firmTitle}
                  name="EditBecomeInsightGigPartnerPageDetails"
                  params={{
                    id: getUserDetails(user).firmId,
                    slug: getUserDetails(user).firmName
                  }}
                >
                  <FormattedMessage
                    id="CollaborationPanel.firmTitle"
                    values={{ "firmTitle": getUserDetails(user).firmName }}
                  />
                </NamedLink>
              }
            </div>
          </div>
        </td>
        <td className={css.authorEmail}>{email}</td>
        <td className={css.roleBox}>
          <span className={tagClassName}>{tag}</span>
        </td>
        <td className={css.statusBox}><span>{email ? INVITE_STATUS_ACTIVE : '-'}</span></td>
      </tr>
    )
  }

  //Table Row view for collaborators
  const userDataRow = (member, i) => {
    const firmId = getUserFirmInfo(member.email).firmId;
    const firmName = getUserFirmInfo(member.email).firmTitle;
    return (
      <tr key={i} className={css.CollaborationPanelData}>
        <td className={css.nameBox}>
          <div className={css.authorNameDetails}>
            <span className={css.profileIcon}>
              {member?.profileImage ? (
                <img src={member?.profileImage} alt={'profileImage'} className={css.profileImage} />
              ) : (
                <div className={css.profileImage}>
                  {getUserShortName(member?.fullName, member?.email)}
                </div>
              )}
            </span>
            <div className={css.providerName}>
              <div className={css.fullName}>{member?.fullName}</div>
              {(firmId && firmName) &&
                <NamedLink
                  className={css.firmTitle}
                  name="EditBecomeInsightGigPartnerPageDetails"
                  params={{
                    id: firmId,
                    slug: firmName
                  }}
                >
                  <FormattedMessage
                    id="CollaborationPanel.firmTitle"
                    values={{ "firmTitle": firmName }}
                  />
                </NamedLink>
              }
            </div>
          </div>
        </td>
        <td className={css.authorEmail}>{member?.email}</td>
        <td className={css.roleBox}>
          <span
            className={
              member.invitedByUserRole === CUSTOMER_INVITEE ? css.redBack :
                member.invitedByUserRole === PROVIDER_INVITEE ? css.skyblueBack :
                  member.invitedByUserRole === THIRD_PARTY_EXPERT ? css.grayBack :
                    null}
          >{member.invitedByUserRole}</span>
        </td>
        <td className={css.statusBox}>
          <span>{member?.status}</span>
        </td>
        <td className={css.buttonBox}>
          {member.status !== INVITE_STATUS_ACTIVE &&
            member?.invitedBy === ensuredCurrentUser?.id?.uuid ? (
            <div
              className={css.resendEmailButton}
              onClick={() => handleSendEmail(member?.email)}
            >
              <FormattedMessage id="OrganizationMemberCard.resendButton" />
            </div>
          ) : (
            <></>
          )}
        </td>
        {member?.invitedBy === ensuredCurrentUser?.id?.uuid && (
          <td className={css.trashBox} >
            <div
              onClick={() => handleRemoveUser(i, member)}
              className={css.trashLink}
            >
              <FormattedMessage id="CollaborationPanel.Remove" />
            </div>
          </td>
        )}
      </tr>
    );
  };

  // Cards View for collorators
  const userDataCard = (member, i) => {
    const firmId = getUserFirmInfo(member.email).firmId;
    const firmName = getUserFirmInfo(member.email).firmTitle;
    return (
      <div key={i} className={css.collaborationCard}>
        <div className={css.cardTop}>
          {member?.profileImage ?
            <img
              src={member?.profileImage}
              alt={'profileImage'}
              className={css.cardImage}
            /> :
            <div className={css.profileImage}>
              {getUserShortName(member?.fullName, member?.email)}
            </div>
          }
          <div className={css.authorName}>{member?.fullName ? member?.fullName : member?.email.split('@')[0]}</div>
          <div className={css.dividerLine} />
          <div className={css.bottomBox}>
            <div>
              <div className={css.projectTitle}>
                {(firmId && firmName) &&
                  <NamedLink
                    className={css.firmTitle}
                    name="EditBecomeInsightGigPartnerPageDetails"
                    params={{
                      id: firmId,
                      slug: firmName
                    }}
                  >
                    <FormattedMessage
                      id="CollaborationPanel.firmTitle"
                      values={{ "firmTitle": firmName }}
                    />
                  </NamedLink>
                }
              </div>
              <div className={css.authorEmail}>{member?.email}</div>
            </div>
            <div>
              <div className={
                member.invitedByUserRole === CUSTOMER_INVITEE ? css.lightYellow :
                  member.invitedByUserRole === PROVIDER_INVITEE ? css.lightBlue :
                    member.invitedByUserRole === THIRD_PARTY_EXPERT ? css.lightGray
                      : null}>
                <span>{member.invitedByUserRole}</span>
              </div>
              <div className={css.userStatus}><span>{member?.status}</span></div>
            </div>
          </div>
        </div>
        <div className={css.bottomLink}>
          {member.status !== INVITE_STATUS_ACTIVE &&
            member?.invitedBy === ensuredCurrentUser?.id?.uuid ? (
            <div
              className={css.resendLink}
              onClick={() => handleSendEmail(member?.email)}
            >
              <FormattedMessage id="CollaborationPanel.resendInvite" />
            </div>
          ) : <div></div>}
          {member?.invitedBy === ensuredCurrentUser?.id?.uuid && (
            <div
              className={css.removeLink}
              onClick={() => handleRemoveUser(i, member)}
            >
              <FormattedMessage id="CollaborationPanel.Remove" />
            </div>
          )}
        </div>
      </div>
    )
  }

  return (
    <div className={css.collaborationContainer}>
      <div className={css.inviteSection}>
        {inviteSuccessToaster && (
          <div className={css.successToasterMessage}>
            <FormattedMessage id="CollaborationPanel.successToasterMessage" />
          </div>
        )}
        <Modal
          id="CollaborationPanelInvitationModal"
          className={css.disableModalBorder}
          contentClassName={css.containerClassName}
          isOpen={openInvitationModal}
          onClose={() => setOpenInvitationModal(false)}
          usePortal
          onManageDisableScrolling={(componentId, disableScrolling) =>
            dispatch(manageDisableScrolling(componentId, disableScrolling))
          }
        >
          <InviteFirmMemberForm
            onSubmit={values => handleSendEmail(values)}
            updateInProgress={sendInviteInProgress}
            authorType={authorType}
            isOpen={openInvitationModal}
            onCloseModal={() => setOpenInvitationModal(false)}
            collaborationMetaData={collaborationMetaData}
            isCustomer={isCustomer}
            firmData={firmData}
            authorEmail={customerEmail}
            currentUser={currentUser}
            currentFirmAuthor={currentFirmAuthor}
            isExpert={userRole === USER_ROLE_PARTNER}
          />
        </Modal>
      </div>
      <div className={css.headerSection}>
        <span className={!toggle ? css.activeView : null}>
          <FormattedMessage id="OrganizationMemberCard.tiles" />
        </span>
        <div className={css.checkboxWrapper} onClick={handleToggleState} >
          <div className={classNames(css.ball, toggle && css.toggled)}></div>
        </div>
        <span className={toggle ? css.activeView : null}>
          <FormattedMessage id="OrganizationMemberCard.table" />
        </span>
      </div>
      <div className={css.collaborators}>
        {toggle ? //-----------Table View-------------
          <>
            <div className={css.collaborationTable}>
              <table>
                <thead className={css.tableHeading}>
                  <tr>
                    <th className={css.nameBox}>
                      <FormattedMessage id="CollaborationPanel.memberName" />
                    </th>
                    <th style={{ textAlign: 'center' }}>
                      <FormattedMessage id="CollaborationPanel.memberEmail" />
                    </th>
                    <th style={{ textAlign: 'center' }}>
                      <FormattedMessage id="CollaborationPanel.memberRole" />
                    </th>
                    <th style={{ textAlign: 'center' }}>
                      <FormattedMessage id="CollaborationPanel.memberStatus" />
                    </th>
                    {/* <th style={{ textAlign: 'center' }}>
                    <FormattedMessage id="CollaborationPanel.resendInvite" />
                  </th> */}
                    <th style={{ textAlign: 'center' }} colSpan="2">
                      <FormattedMessage id="CollaborationPanel.action" />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <LeadTableRow
                    email={customerEmail}
                    profileImage={customerProfileImage}
                    name={customerName}
                    tag={CUSTOMER}
                    user={ensuredCustomer}
                    tagClassName={css.yellowBack}
                  />
                  {Array.isArray(collaborationMetaData) &&
                    collaborationMetaData.map((member, i) => member.invitedBy == customerId && userDataRow(member, i)
                    )}
                  <LeadTableRow
                    email={providerEmail}
                    profileImage={providerProfileImage}
                    name={providerName}
                    tag={PROVIDER}
                    user={ensuredProvider}
                    tagClassName={css.blueBack}
                    isCsm={isCsm}

                  />
                  {Array.isArray(collaborationMetaData) &&
                    collaborationMetaData.sort((a, b) => {
                      if (a.invitedByUserRole < b.invitedByUserRole) return -1;
                      if (a.invitedByUserRole > b.invitedByUserRole) return 1;
                      return 0;
                    }).map(
                      (member, i) => member.invitedBy == providerId && userDataRow(member, i)
                    )}
                </tbody>
              </table>
            </div>

            {(!isCollaborator && showInviteButton) && (
              <div className={css.inviteButtonSection} onClick={() => handleOpenInviteModal()}>
                <FormattedMessage id="CollaborationPanel.inviteButton" />
              </div>
            )}
          </>
          : //-------------Card View---------------
          <div className={css.collaborationGrid}>
            <LeadCard
              email={customerEmail}
              profileImage={customerProfileImage}
              name={customerName}
              tag={CUSTOMER}
              user={ensuredCustomer}
              tagClassName={css.yellowBack}
            />
            {Array.isArray(collaborationMetaData) &&
              collaborationMetaData.map((member, i) => member.invitedBy == customerId && userDataCard(member, i)
              )}
            <LeadCard
              email={providerEmail}
              profileImage={providerProfileImage}
              name={providerName}
              tag={PROVIDER}
              user={ensuredProvider}
              tagClassName={css.blueBack}
              isCsm={isCsm}
            />
            {Array.isArray(collaborationMetaData) &&
              collaborationMetaData.sort((a, b) => {
                if (a.invitedByUserRole < b.invitedByUserRole) return -1;
                if (a.invitedByUserRole > b.invitedByUserRole) return 1;
                return 0;
              }).map(
                (member, i) => member.invitedBy == providerId && userDataCard(member, i)
              )}

            {(!isCollaborator && showInviteButton) && (
              <div className={css.inviteButtonBox} onClick={() => handleOpenInviteModal()}>
                <FormattedMessage id="CollaborationPanel.inviteButton" />
              </div>
            )}
          </div>
        }
      </div>
      <Modal
        id="CollaborationPanelInvitationModal"
        className={css.disableModalBorder}
        contentClassName={css.containerClassName}
        isOpen={openMaxCollaboratorLimitModal}
        onClose={() => setOpenMaxCollaboratorLimitModal(false)}
        usePortal
        onManageDisableScrolling={(componentId, disableScrolling) =>
          dispatch(manageDisableScrolling(componentId, disableScrolling))
        }
      >
        <div className={css.alertModal}>
          <h2><FormattedMessage id="CollaborationPanel.maxCollaboratorsLimitHeading" /></h2>
          <p><FormattedMessage id="CollaborationPanel.maxCollaboratorsLimitMessage" /></p>
          <div className={css.buttonWrapper}>
            <PrimaryButton onClick={() => setOpenMaxCollaboratorLimitModal(false)}>
              Okay
            </PrimaryButton>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default compose(withViewport, withRouter, injectIntl)(CollaborationPanel);
