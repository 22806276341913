import React, { useState } from 'react';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import arrayMutators from 'final-form-arrays';
// These relative imports need to point to correct directories
import {
  intlShape,
  injectIntl,
  FormattedMessage,
} from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { Form, Button, FieldCheckboxGroup, Modal } from '../../components';

// Create this file using EditListingFeaturesForm.module.css
// as a template.
import css from './EditListingPackagesForm.module.css';

export const EditListingPackagesForm = props => (
  <FinalForm
    {...props}
    mutators={{
      ...arrayMutators,
    }}
    render={formRenderProps => {
      const {
        className,
        disabled,
        handleSubmit,
        intl,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        updateError,
        updateInProgress,
        packagesOptions,
        values,
        onManageDisableScrolling
      } = formRenderProps;
      const [marketResearchState, setMarketResearchState] = useState(false);
      const [customerExperienceState, setCustomerExperienceState] = useState(false);
      const [dataAndAnalyticsState, setDataAndAnalyticsState] = useState(false);
      const [immersionAndIdeaGenerationState, setImmersionAndIdeaGeneration] = useState(false);
      const [moreOptionModal, setMoreOptionModal] = useState(false);

      const errorMessage = updateError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingPackagesForm.updateFailed" />
        </p>
      ) : null;

      const classes = classNames(css.root, className);
      const submitReady = updated && pristine;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;
      const getPackageOptions = (key) => {
        const options = packagesOptions && packagesOptions.length && packagesOptions.filter(p => p.parentKey === key);
        return options;

      }
      const marketResearch = getPackageOptions('marketResearch');
      const customerExperienceManagement = getPackageOptions('customerExperience');
      const dataAndAnalytics = getPackageOptions('dataAndAnalytics');
      const immersionAndIdeaGeneration = getPackageOptions('immersionAndInnovation');
      const selectedOptions = values ? values?.servicePackage?.map(item => item?.key) : [];
      return (
        <Form className={css.packagesForm} onSubmit={handleSubmit} >
          {errorMessage}
          <div className={css.packageBox}>
            {marketResearch ?
              <>
                <div onClick={() => setMarketResearchState(!marketResearchState)} className={css.HeadingTitle}>
                  <FormattedMessage id="EditListingPackagesForm.marketResearchTitle" />
                </div>
                {marketResearchState ?
                  <div className={css.packageCard}>
                    <FieldCheckboxGroup
                      className={css.servicePackage}
                      id='servicePackage'
                      name='servicePackage'
                      twoColumns
                      options={marketResearch}
                      selectedOptions={selectedOptions}
                      moreOptionModal={moreOptionModal}
                      setMoreOptionModal={setMoreOptionModal}
                    />
                  </div> : null}
              </> : null}
            {customerExperienceManagement ?
              <div className={css.packageCard}>
                <div onClick={() => setCustomerExperienceState(!customerExperienceState)} className={css.HeadingTitle}>
                  <FormattedMessage id="EditListingPackagesForm.customerExperienceTitle" />
                </div>
                {customerExperienceState ?
                  <FieldCheckboxGroup
                    className={css.servicePackage}
                    id='servicePackage'
                    name='servicePackage'
                    twoColumns
                    options={customerExperienceManagement}
                    selectedOptions={selectedOptions}
                    moreOptionModal={moreOptionModal}
                    setMoreOptionModal={setMoreOptionModal}
                  />
                  : null}
              </div>
              : null}
            {immersionAndIdeaGeneration ?
              <div className={css.packageCard}>
                <div onClick={() => setImmersionAndIdeaGeneration(!immersionAndIdeaGenerationState)} className={css.HeadingTitle}>
                  <FormattedMessage id="EditListingPackagesForm.immersionAndIdeaGenerationTitle" />
                </div>
                {immersionAndIdeaGenerationState ?
                  <FieldCheckboxGroup
                    className={css.servicePackage}
                    id='servicePackage'
                    name='servicePackage'
                    twoColumns
                    options={immersionAndIdeaGeneration}
                    moreOptionModal={moreOptionModal}
                    setMoreOptionModal={setMoreOptionModal}
                  />
                  : null}
              </div>
              : null}

            {dataAndAnalytics ?
              <div className={css.packageCard}>
                <div onClick={() => setDataAndAnalyticsState(!dataAndAnalyticsState)} className={css.HeadingTitle}>
                  <FormattedMessage id="EditListingPackagesForm.dataAnalyticsTitle" />
                </div>
                {dataAndAnalyticsState ?
                  <FieldCheckboxGroup
                    className={css.servicePackage}
                    id='servicePackage'
                    name='servicePackage'
                    twoColumns
                    options={dataAndAnalytics}
                    selectedOptions={selectedOptions}
                    moreOptionModal={moreOptionModal}
                    setMoreOptionModal={setMoreOptionModal}
                  />
                  : null}
              </div>
              : null}
            <Modal
              id="editListingPackagesForm"
              className={css.disableModalBorder}
              contentClassName={css.containerClassName}
              isOpen={moreOptionModal}
              onClose={() => setMoreOptionModal(false)}
              usePortal
              onManageDisableScrolling={onManageDisableScrolling}
            >
              <h2>Coming Soon...</h2>
            </Modal>
          </div>
          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button>
        </Form>
      );
    }}
  />
);

EditListingPackagesForm.defaultProps = {
  selectedPlace: null,
  updateError: null,
};

EditListingPackagesForm.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  updated: bool.isRequired,
  updateError: propTypes.error,
  updateInProgress: bool.isRequired,
  packagesOptions: arrayOf(
    shape({
      key: string.isRequired,
      label: string.isRequired,
    })
  ).isRequired,
};

export default compose(injectIntl)(EditListingPackagesForm);