import React from 'react'
import css from '../LandingPageTabsPanel.module.css'
import { createResourceLocatorString } from '../../../util/routes';
import routeConfiguration, { draftId, draftSlug } from '../../../routeConfiguration';
import { AI_BRIEF_GENERATOR, CSM_BANNER_PAGE_NOTIFICATION, INBOX_TAB_BRIEFS, USER_ROLE_CLIENT, USER_ROLE_PARTNER } from '../../../util/types';
import { Button, ExternalLink, IconCardHomePage, NamedLink } from '../..';
import { FormattedMessage } from 'react-intl';
import profileIcon from '../../../assets/csm-profile.png';

const LandingPageFirstTab = (props) => {
  const { handleAdminEmailModal, intl, isApprovedUser, currentUser } = props;

  const emailParams = {
    step: CSM_BANNER_PAGE_NOTIFICATION,
    cardTitle: intl.formatMessage({ id: "CsmSupportBanner.adminEmailLandingPageCardData" }),
  }

  return (
    <div className={css.supportBanner}>
      <div className={css.supportBannerWrapper}>
        <div className={css.profileIcon}>
          <img src={profileIcon} />
        </div>
        <h2>
          <FormattedMessage id="LandingPage.adminEmailFirmDashboardPageHeading" />
        </h2>
        <p>
          <FormattedMessage id="LandingPage.adminEmailFirmDashboardPagDescription" />
        </p>
        <Button className={(!isApprovedUser && currentUser) ? css.disabled :""} onClick={() => handleAdminEmailModal(emailParams)}>
          <FormattedMessage id="LandingPage.scheduleACallButtonText" />
        </Button>
      </div>
    </div>
  )
}

export default LandingPageFirstTab