import React from 'react'
import ContentLoader from 'react-content-loader';

function LinesLoader(props) {
  return (
    <ContentLoader
      speed={2}
      width={400}
      height={100}
      viewBox="0 0 400 70"
      backgroundColor="#ddd"
      foregroundColor="#ecebeb"
    >
      <rect x="0" y="8" rx="3" ry="3" width="300" height="10" />
      <rect x="0" y="26" rx="3" ry="3" width="300" height="10" />
      <rect x="0" y="44" rx="3" ry="3" width="300" height="10" />
    </ContentLoader>
  )
}

export default LinesLoader