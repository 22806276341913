import React from 'react';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import classNames from 'classnames';
import { FormattedMessage, injectIntl } from '../../util/reactIntl';
import * as validators from '../../util/validators';
import {
  FieldAntDatePickerInput, FieldCheckbox,
  FieldTextInput,
  Form,
  PrimaryButton,
} from '../../components';

import css from './SampleWorkForm.module.css';

import { composeValidators } from "../../util/validators";
import {fakeIntl} from "../../util/test-data";



const ContentSampleWorkComponent = props => {
  const { onClose, intl, onSubmit, ...restOfProps } = props;

  // title
  const addCertification = intl.formatMessage({
    id: 'EditListingCertificationPanel.addSampleWork',
  });

  // certification
  const certificationLabel = intl.formatMessage({
    id: 'EditListingCertificationPanel.certificationLabel',
  });
  const certificationPlaceholder = intl.formatMessage({
    id: 'EditListingCertificationPanel.certificationPlaceholder',
  });
  const certificationRequired = intl.formatMessage({
    id: 'EditListingCertificationPanel.certificationRequired',
  });


  const submit = values => onSubmit({ values: values })

  const handleDelete = values => {
    if(values.number) onSubmit({ type: values.number, values: values })
    // onClose()
  }

  return (
    <FinalForm
      {...restOfProps}
      onSubmit={submit}
      mutators={{
        ...arrayMutators,
      }}
      render={fieldRenderProps => {
        const {
          rootClassName,
          className,
          formId,
          form,
          disabled,
          invalid,
          handleSubmit,
          inProgress,
          values,
        } = fieldRenderProps;

        const classes = classNames(rootClassName || css.root, className);
        const submitInProgress = inProgress;
        const notExpire = !!(values.notExpire && values.notExpire.length)
        const valueEndDate = values.endDate
        const valueStartDate = values.startDate

        const submitDisabled = invalid || disabled || submitInProgress || !valueStartDate


        const minAgeRequired = validators.dateComparison('Invalid date', valueEndDate);
        const dateComparison = validators.dateComparison('Invalid date');
        const reversComparison = validators.dateComparison('Invalid date', valueStartDate, true);
        const validStartDate =  notExpire || !valueEndDate
          ? composeValidators(dateComparison)
          : composeValidators(minAgeRequired, dateComparison)
        const validEndDate =  notExpire || !valueStartDate
          ? composeValidators(dateComparison)
          : composeValidators(dateComparison, reversComparison)

        return (
          <Form id={formId} className={classes} onSubmit={ e => {
            handleSubmit(e)
            form.restart()
          }}>
            <h2 className={css.titleModal}>{addCertification}</h2>
            <FieldTextInput
              id="certification"
              name="certification"
              className={css.description}
              type="text"
              label={certificationLabel}
              placeholder={certificationPlaceholder}
              validate={validators.required(certificationRequired)}
            />


            <div className={css.buttonSection}>
              <PrimaryButton className={css.buttonDelete} onClick={()=> handleDelete(values)} type="button">
                <FormattedMessage id="EditListingWorkExperienceFormComponent.deleteSchedule" />
              </PrimaryButton>

              <PrimaryButton className={css.buttonSave} type="submit" disabled={submitDisabled}>
                <FormattedMessage id="EditListingWorkExperienceFormComponent.saveSchedule" />
              </PrimaryButton>
            </div>
          </Form>
        );
      }}
    />
  );
};

const ContentSampleWork = compose(injectIntl)(
  ContentSampleWorkComponent
);


export default ContentSampleWork;
