import { calculateTotalWorkExperience } from '../../util/destructorHelpers';

const MAX_TOKENS = 8191;

const similarExpertTemplate = async (params) => {
  const { country, firmName, titleExpert, description, engagementRoles,
    spokenLanguagesKeywords, domainExpertise, industrySector, servicePackage, Experience, Certification
  } = params;

  const userDetails = `Expert with ${titleExpert} from ${country} with a total work experience of ${calculateTotalWorkExperience(Experience)}. Now open to ${engagementRoles?.map(role => `${role === 'projects' ? 'ad hoc projects' : `${role} opportunities`}`).join(', ')}. Expertise is summarized as ${description}. ${firmName ? `Works in the firm named ${firmName}.` : ''}\n`

  const workDetails = `Work experience: ${Experience?.map(ex => (
    `Worked as a ${ex?.job} in the company ${ex?.company} with the role of ${ex?.description}.`
  )).join(' ')}\n`

  const domains = `Domain expertise: ${domainExpertise?.join(', ')}\n`;
  const industry = `Industry/Sector: ${industrySector?.join(', ')}\n`;
  const languages = `Languages: ${spokenLanguagesKeywords ?? ''}\n`;

  const certifications = `Certifications: ${Certification?.length ? Certification?.map(ct => (
    `${ct.certification}, ${ct.organisation ?? ''}, ${ct.certificationURL ?? ''}`
  )) : ''}\n`

  const packages = `Offering Packages: ${servicePackage?.length ? servicePackage?.join(', ') : ''}\n`;

  const profile = userDetails + workDetails + domains + industry + languages + certifications + packages;

  const profileTokens = profile.match(/.{1,5}/g).length;

  if(profileTokens > MAX_TOKENS){
    return profile.match(/.{1,5}/g).splice(0, MAX_TOKENS)?.join('');
  }
  return profile;
}

export default similarExpertTemplate