import { useEffect, useState } from "react";
import { GIG_APP_COMPLETED, GIG_APP_USAGE, GIG_APP_VIEWS } from "../util/types";
import moment from 'moment';


export default function useFetchGigAppData(userDetails) {
  const [gigAppData, setGigAppData] = useState({})

  function getStartAndEndOfPreviousQuarter() {
    const today = new Date();
    const year = today.getUTCFullYear();
    let startOfPreviousQuarter, endOfPreviousQuarter, startOfSecondPreviousQuarter, endOfSecondPreviousQuarter;
  
    // Determine the start and end dates of the previous quarter
    if (today.getMonth() >= 9) { 
        startOfPreviousQuarter = new Date(Date.UTC(year, 6, 1, 0, 0));   
        endOfPreviousQuarter = new Date(Date.UTC(year, 8, 30, 23, 59));  
        startOfSecondPreviousQuarter = new Date(Date.UTC(year, 3, 1, 0, 0));  
        endOfSecondPreviousQuarter = new Date(Date.UTC(year, 5, 30, 23, 59)); 
    } else if (today.getMonth() >= 6) { 
        startOfPreviousQuarter = new Date(Date.UTC(year, 3, 1, 0, 0));   
        endOfPreviousQuarter = new Date(Date.UTC(year, 5, 30, 23, 59));  
        startOfSecondPreviousQuarter = new Date(Date.UTC(year, 0, 1, 0, 0));  
        endOfSecondPreviousQuarter = new Date(Date.UTC(year, 2, 31, 23, 59)); 
    } else if (today.getMonth() >= 3) { 
        startOfPreviousQuarter = new Date(Date.UTC(year, 0, 1, 0, 0));   
        endOfPreviousQuarter = new Date(Date.UTC(year, 2, 31, 23, 59));  
        startOfSecondPreviousQuarter = new Date(Date.UTC(year - 1, 9, 1, 0, 0));  
        endOfSecondPreviousQuarter = new Date(Date.UTC(year - 1, 11, 31, 23, 59));
    } else { 
        startOfPreviousQuarter = new Date(Date.UTC(year - 1, 9, 1, 0, 0));  
        endOfPreviousQuarter = new Date(Date.UTC(year - 1, 11, 31, 23, 59));
        startOfSecondPreviousQuarter = new Date(Date.UTC(year - 1, 6, 1, 0, 0));  
        endOfSecondPreviousQuarter = new Date(Date.UTC(year - 1, 8, 30, 23, 59)); 
    }
  
    // Convert all dates to Unix timestamps (in seconds)
    const previousQuarterstart = Math.floor(startOfPreviousQuarter.getTime() / 1000);
    const previousQuarterEnd = Math.floor(endOfPreviousQuarter.getTime() / 1000);
    const secondPreviousQuarterStart = Math.floor(startOfSecondPreviousQuarter.getTime() / 1000);
    const secondPreviousQuarterEnd = Math.floor(endOfSecondPreviousQuarter.getTime() / 1000);
  
    return { previousQuarterstart, previousQuarterEnd, secondPreviousQuarterStart, secondPreviousQuarterEnd };
  }
  
  const { previousQuarterstart, previousQuarterEnd, secondPreviousQuarterStart, secondPreviousQuarterEnd } = getStartAndEndOfPreviousQuarter()

  useEffect(() => {
    const response = calculateGigappUsageCount(userDetails, '');
    if(Object.values(response).length) setGigAppData(response)
  }, [userDetails?.length])

  function getTotalCount(userDetails, category, appRoute) {
    return userDetails.reduce((total, user) => {
      const categoryItem = user[category] || [];
      return total + categoryItem.filter(itm => {
        let isInPreviousQuarter = itm.date>previousQuarterstart*1000 && itm.date<previousQuarterEnd*1000
        return !!appRoute ? (itm.name === appRoute && isInPreviousQuarter) : (isInPreviousQuarter)
      }).length;
    }, 0);
  }

  function getCustomDaysCount(userDetails, category, appRoute) {
    return userDetails.reduce((total, user) => {
      const categoryItem = user[category];
      const filteredItems = categoryItem.filter(itm => {
        const isInDateRange = itm.date > secondPreviousQuarterStart*1000 && itm.date < secondPreviousQuarterEnd*1000;
        if (appRoute) {
          return itm.name === appRoute && isInDateRange;
        }
        return isInDateRange;
      });
      
      return total + filteredItems.length;
    }, 0);
  }
  
  const calculateGigappUsageCount = (data = [], appRoute) => {
    // Gig App views
    const gigAppViewsCount = getTotalCount(data, GIG_APP_VIEWS, appRoute);
    const gigAppViewsLast90DaysCount = getCustomDaysCount(data, GIG_APP_VIEWS, appRoute);
    const gigAppViewsLast90DaysPercetange = ((gigAppViewsCount - gigAppViewsLast90DaysCount) / gigAppViewsLast90DaysCount) * 100 || Infinity;  

    // Gig App usage
    const gigAppUsageCount = getTotalCount(data, GIG_APP_USAGE, appRoute);
    const gigAppUsageLast90DaysCount = getCustomDaysCount(data, GIG_APP_USAGE, appRoute);
    const gigAppUsageLast90DaysPercetange = ((gigAppUsageCount - gigAppUsageLast90DaysCount)  / gigAppUsageLast90DaysCount) * 100 || Infinity;
    
    // Gig App successful output
    const gigAppCompletedCount = getTotalCount(data, GIG_APP_COMPLETED, appRoute);
    const gigAppCompletedLast90DaysCount = getCustomDaysCount(data, GIG_APP_COMPLETED, appRoute);
    const gigAppCompletedLast90DaysPercetange = ((gigAppCompletedCount - gigAppCompletedLast90DaysCount) / gigAppCompletedLast90DaysCount) * 100 || Infinity; 

    return {
      gigAppUsageCount,
      gigAppViewsCount,
      gigAppCompletedCount,
      gigAppUsageLast90DaysPercetange,
      gigAppViewsLast90DaysPercetange,
      gigAppCompletedLast90DaysPercetange
    }
  };
  
  return [gigAppData, setGigAppData, calculateGigappUsageCount]
}