import React from 'react';
import css from './BriefLocation.module.css';
import { FormattedMessage } from 'react-intl';

const BriefLocation = (props) => {

  const { briefLocation } = props;

  return (
    <div className={css.briefLocationBox}>
      <h2>Location</h2>
      <div className={css.headingName}>
        <FormattedMessage id='BriefLocation.heading' />
        <span>
          {briefLocation && briefLocation.length ?
            <span className={css.locationLength}>
              <FormattedMessage id='Brieflocation.yes' />
            </span>
            : <span className={css.locationLength}>
              <FormattedMessage id='BriefLocation.noLocationFound' />
            </span>}
        </span>
      </div>
      <ul>
        {briefLocation && briefLocation.length ? briefLocation.map((bl, i) => (
          <li key={i} className={css.locationList}>
            <p className={css.locationText}>{bl?.Location?.selectedPlace?.address}</p>
          </li>
        )) : null}
      </ul>
    </div>
  )
}

export default BriefLocation