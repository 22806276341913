import classNames from 'classnames';
import css from './LoadingDots.module.css'

const LoadingDots = (props) => {
  const {className} = props;
  const classes = classNames(css.dots, className)
  return(
    <div className={classes}></div>
  )
}

export default LoadingDots