import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { queryUserReviews } from '../../containers/TransactionPage/TransactionPage.duck';
import IconCard from '../IconCard/IconCard'
import css from './TransactionPanel.module.css';

const ShowReview = (props) => {
    const {currentTransaction, author} = props;
    const dispatch = useDispatch();
    const [reviews, setReviews] = useState([]);
    const averageReview = reviews?.length && 
        reviews?.map(e => e.attributes?.rating).reduce((a, b) => a + b)

    const getReviews = async () => {
        const reviews = author?.id && await dispatch(queryUserReviews({userId: author?.id}));
        setReviews(reviews);
    }

    useEffect(() => {
        getReviews();
    },[author?.id])

    return(
        <>
         <span className={css.starIcon}>
            <IconCard brand="star" />
        </span>{reviews?.length ? (averageReview/reviews?.length).toFixed(1) : 0}
        <span className={css.reviewCount}> ({reviews?.length} reviews)</span>
        </>
    )
} 

export default ShowReview