import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconSocialMediaYouTube.module.css';

const IconSocialMediaYouTube = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg className={classes} width="22" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M21.3803 2.70166C21.1312 1.77 20.3967 1.0355 19.465 0.78633C17.775 0.333496 10.9998 0.333496 10.9998 0.333496C10.9998 0.333496 4.22467 0.333496 2.53467 0.78633C1.603 1.0355 0.868504 1.77 0.619337 2.70166C0.166504 4.39166 0.166504 9.00016 0.166504 9.00016C0.166504 9.00016 0.166504 13.6087 0.619337 15.2987C0.868504 16.2303 1.603 16.9648 2.53467 17.214C4.22467 17.6668 10.9998 17.6668 10.9998 17.6668C10.9998 17.6668 17.775 17.6668 19.465 17.214C20.3978 16.9648 21.1312 16.2303 21.3803 15.2987C21.8332 13.6087 21.8332 9.00016 21.8332 9.00016C21.8332 9.00016 21.8332 4.39166 21.3803 2.70166ZM8.83317 11.8147V6.18566C8.83317 5.76858 9.28492 5.50858 9.64567 5.71658L14.5207 8.53108C14.8814 8.73908 14.8814 9.26124 14.5207 9.46924L9.64567 12.2837C9.28492 12.4928 8.83317 12.2317 8.83317 11.8147Z" />
    </svg>
  );
};

IconSocialMediaYouTube.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

IconSocialMediaYouTube.propTypes = { rootClassName: string, className: string };

export default IconSocialMediaYouTube;
