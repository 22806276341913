import unionWith from 'lodash/unionWith';
import { storableError } from '../../util/errors';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { convertUnitToSubUnit, unitDivisor } from '../../util/currency';
import { formatDateStringToUTC, getExclusiveEndDate } from '../../util/dates';
import { parse } from '../../util/urlHelpers';
import config from '../../config';
import { fetchCurrentUser } from '../../ducks/user.duck';
import { USER_ROLE_PARTNER } from '../../util/types';
import { getPreferredInsightGigPartner } from '../../util/destructorHelpers';

// Pagination page size might need to be dynamic on responsive page layouts
// Current design has max 3 columns 12 is divisible by 2 and 3
// So, there's enough cards to fill all columns on full pagination pages
const RESULT_PAGE_SIZE = 20;

// ================ Action types ================ //

export const FAVORITE_LISTING_REQUEST = 'app/FavouritesPage/FAVORITE_LISTING_REQUEST';
export const FAVORITE_LISTING_SUCCESS = 'app/FavouritesPage/FAVORITE_LISTING_SUCCESS';
export const FAVORITE_LISTING_ERROR = 'app/FavouritesPage/FAVORITE_LISTING_ERROR';

export const SEARCH_MAP_LISTINGS_REQUEST = 'app/FavouritesPage/SEARCH_MAP_LISTINGS_REQUEST';
export const SEARCH_MAP_LISTINGS_SUCCESS = 'app/FavouritesPage/SEARCH_MAP_LISTINGS_SUCCESS';
export const SEARCH_MAP_LISTINGS_ERROR = 'app/FavouritesPage/SEARCH_MAP_LISTINGS_ERROR';

export const SEARCH_MAP_SET_ACTIVE_LISTING = 'app/FavouritesPage/SEARCH_MAP_SET_ACTIVE_LISTING';

// ================ Reducer ================ //

const initialState = {
  pagination: null,
  queryParams: {},
  queryInProgress: false,
  favoriteListingError: null,
  currentPageResultIds: [],
};

const resultIds = data => data.map(l => l.id);
const listingPageReducer = (state = initialState, action = {}) => {
  
  const { type, payload } = action;
  switch (type) {
    case FAVORITE_LISTING_REQUEST:
      return {
        ...state,
        queryParams: payload,
        queryInProgress: true,
        searchMapListingIds: [],
        favoriteListingError: null,
      };
    case FAVORITE_LISTING_SUCCESS:
      return {
        ...state,
        currentPageResultIds: resultIds(payload.data),
        pagination: payload.data.meta,
        queryInProgress: false,
      };
    case FAVORITE_LISTING_ERROR:
      return { ...state, queryInProgress: false, favoriteListingError: payload };

    default:
      return state;
  }
};

export default listingPageReducer;

// ================ Action creators ================ //

export const  favoriteListingRequest = queryParams => ({
  type: FAVORITE_LISTING_REQUEST,
  payload: queryParams,
});

export const favoriteListingSuccess = response => ({
  type: FAVORITE_LISTING_SUCCESS,
  payload: { data: response.data?.data },
});

export const favoriteListingError = e => ({
  type: FAVORITE_LISTING_ERROR,
  error: true,
  payload: e,
});

export const searchListings = (ids, rest) => (dispatch, getState, sdk) => {
  dispatch(favoriteListingRequest(rest));
  const params = {
    ids,
    per_page: RESULT_PAGE_SIZE,
    pub_type: USER_ROLE_PARTNER,
    ...rest,
    include: ['author', 'images'],
    'limit.images': 1,
  };

  return sdk.listings
    .query(params)
    .then(response => { 
      const {data} = response;
      dispatch(addMarketplaceEntities(response));
      dispatch(favoriteListingSuccess(response));

      return response;
    })
    .catch(e => {
      dispatch(favoriteListingError(storableError(e)));
      throw e;
    });
};

export const loadData = (params, search) => async (dispatch,getState,sdk) => {
  try{
    await dispatch(fetchCurrentUser());
    const { currentUser } = getState().user;
    const preferredInsightGigPartner = !!currentUser?.id && getPreferredInsightGigPartner(currentUser);
    const ids = preferredInsightGigPartner.join(',');

    const queryParams = parse(search, {
      latlng: ['origin'],
      latlngBounds: ['bounds'],
    });
    const { page = 1, address, origin, ...rest } = queryParams;

    dispatch(searchListings(ids, rest))
  }catch(error){
    dispatch(favoriteListingError(storableError(e)));
  }
};
