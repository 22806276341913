import React, {  useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import routeConfiguration from '../../routeConfiguration';
import {
  LINE_ITEM_NIGHT,
  LINE_ITEM_DAY,
  LISTING_STATE_PENDING_APPROVAL,
  LISTING_STATE_CLOSED,
  LISTING_STATE_DRAFT,
  propTypes,
  FIRM_DASHBOARD_PAGE,
  USER_ROLE_CLIENT,
  USER_ROLE_PARTNER,
} from '../../util/types';
import { formatMoney } from '../../util/currency';
import { ensureOwnListing } from '../../util/data';
import {
  LISTING_PAGE_PENDING_APPROVAL_VARIANT,
  LISTING_PAGE_DRAFT_VARIANT,
  LISTING_PAGE_PARAM_TYPE_DRAFT,
  LISTING_PAGE_PARAM_TYPE_EDIT,
  createSlug,
} from '../../util/urlHelpers';
import { createResourceLocatorString, findRouteByRouteName } from '../../util/routes';
import config from '../../config';
import {
  InlineTextButton,
  Menu,
  MenuLabel,
  MenuContent,
  MenuItem,
  NamedLink,
  IconSpinner,
  ResponsiveImage,
  IconCard,
  CustomBriefShareModal,
} from '../../components';

import MenuIcon from './MenuIcon';
import Overlay from './Overlay';
import css from './ManageListingCard.module.css';
import { findOptionsForSelectFilter } from '../../marketplace-custom-config';
import { types } from 'sharetribe-flex-sdk';
import { useDispatch, useSelector } from 'react-redux';
import { getUserRole } from '../../util/userRole';
import {  sharedBriefIds } from '../../util/destructorHelpers';
import { getFirmData } from '../../util/destructorHelpers';
import { showUser, updateUserProfile } from '../../util/api';
import { closeSharedBriefThroughIsdk, openSharedBriefThroughIsdk } from '../../containers/ManageListingsPage/ManageListingsPage.duck';
import { DESCRIPTION } from '../EditListingBriefWizard/EditListingBriefWizardTab';

const { Money } = types;

// Menu content needs the same padding
const MENU_CONTENT_OFFSET = -12;
const MAX_LENGTH_FOR_WORDS_IN_TITLE = 7;

const priceData = (price, intl) => {
  if (price && price.currency === config.currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: intl.formatMessage(
        { id: 'ManageListingCard.unsupportedPrice' },
        { currency: price.currency }
      ),
      priceTitle: intl.formatMessage(
        { id: 'ManageListingCard.unsupportedPriceTitle' },
        { currency: price.currency }
      ),
    };
  }
  return {};
};

const createListingURL = (routes, listing) => {
  const id = listing.id.uuid;
  const slug = createSlug(listing.attributes.title);
  const isPendingApproval = listing.attributes.state === LISTING_STATE_PENDING_APPROVAL;
  const isDraft = listing.attributes.state === LISTING_STATE_DRAFT;
  const variant = isDraft
    ? LISTING_PAGE_DRAFT_VARIANT
    : isPendingApproval
      ? LISTING_PAGE_PENDING_APPROVAL_VARIANT
      : null;

  const linkProps =
    isPendingApproval || isDraft
      ? {
        name: 'ListingPageVariant',
        params: {
          id,
          slug,
          variant,
        },
      }
      : {
        name: 'ListingPage',
        params: { id, slug },
      };

  return createResourceLocatorString(linkProps.name, routes, linkProps.params, {});
};

// Cards are not fixed sizes - So, long words in title make flexboxed items to grow too big.
// 1. We split title to an array of words and spaces.
//    "foo bar".split(/([^\s]+)/gi) => ["", "foo", " ", "bar", ""]
// 2. Then we break long words by adding a '<span>' with word-break: 'break-all';
const formatTitle = (title, maxLength) => {
  const nonWhiteSpaceSequence = /([^\s]+)/gi;
  return title.split(nonWhiteSpaceSequence).map((word, index) => {
    return word.length > maxLength ? (
      <span key={index} style={{ wordBreak: 'break-all' }}>
        {word}
      </span>
    ) : (
      word
    );
  });
};

export const ManageListingCardComponent = props => {
  const {
    className,
    rootClassName,
    hasClosingError,
    hasOpeningError,
    history,
    intl,
    isMenuOpen,
    actionsInProgressListingId,
    listing,
    onCloseListing,
    onOpenListing,
    onToggleMenu,
    renderSizes,
    availabilityEnabled,
    currentPage,
    setInviteSuccessToaster,
    listingMenuOpen,
  } = props;
  const dispatch = useDispatch();
  const { currentUser = {} } = useSelector(state => state?.user) || {};
  const { firmListing = {} } = useSelector(state => state?.ManageListingsPage) || {};
  const briefSharedIds = currentUser?.id && sharedBriefIds(currentUser);
  const userRole = currentUser?.id && getUserRole(currentUser);
  const [shareBriefModalState, setShareBriefModalState] = useState(false);
  const [shareBriefInviteInProgess, setShareBriefInviteInProgress] = useState(false);
  const firmData = firmListing?.id && getFirmData(firmListing)?.filter(firm=>firm?.userId !== currentUser?.id?.uuid);
  const isCsm = !!currentUser?.id && !!getUserRole(currentUser);

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);
  const id = currentListing.id.uuid;
  const { title = '', price, state } = currentListing.attributes;
  const slug = createSlug(title);
  const isPendingApproval = state === LISTING_STATE_PENDING_APPROVAL;
  const isClosed = state === LISTING_STATE_CLOSED;
  const isDraft = state === LISTING_STATE_DRAFT;
 
  // const descriptionText = listing && listing?.attributes?.description;
  const firstImage = currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;
  const sharedBriefId = currentUser?.id && sharedBriefIds(currentUser);
  const isSharedBrief = sharedBriefId?.length && sharedBriefId?.includes(id);

  const { description, publicData } = currentListing && currentListing?.attributes;
  const { maxPrice, minPrice, currencyFee, domainExpertise, industrySector } = publicData;
  const menuItemClasses = classNames(css.menuItem, {
    [css.menuItemDisabled]: !!actionsInProgressListingId,
  });

  const { formattedPrice, priceTitle } = priceData(price, intl);

  const hasError = hasOpeningError || hasClosingError;
  const thisListingInProgress =
    actionsInProgressListingId && actionsInProgressListingId.uuid === id;

  const onOverListingLink = () => {
    // Enforce preloading of ListingPage (loadable component)
    const { component: Page } = findRouteByRouteName('ListingPage', routeConfiguration());
    // Loadable Component has a "preload" function.
    if (Page.preload) {
      Page.preload();
    }
  };


  const titleClasses = classNames(css.title, {
    [css.titlePending]: isPendingApproval,
    [css.titleDraft]: isDraft,
  });

  const editListingLinkType = isDraft
    ? LISTING_PAGE_PARAM_TYPE_DRAFT
    : LISTING_PAGE_PARAM_TYPE_EDIT;

  const unitType = config.bookingUnitType;
  const isNightly = unitType === LINE_ITEM_NIGHT;
  const isDaily = unitType === LINE_ITEM_DAY;

  const unitTranslationKey = isNightly
    ? 'ManageListingCard.perNight'
    : isDaily
      ? 'ManageListingCard.perDay'
      : 'ManageListingCard.perUnit';

  const domainMarketplaceOptions = findOptionsForSelectFilter('domainExpertise', config.custom.filters);
  const industryMarketplaceOptions = findOptionsForSelectFilter('industry', config.custom.filters);

  const domainExpertiseArray = domainMarketplaceOptions && domainMarketplaceOptions.filter(o => domainExpertise?.find(s => s === o.key));
  const industrySectorArray = industryMarketplaceOptions && industryMarketplaceOptions.filter(o => industrySector?.find(s => s === o.key));

  const maxMoney = !!maxPrice && new Money(maxPrice * 100, currencyFee)
  const minMoney = !!minPrice && new Money(minPrice * 100, currencyFee)

  const handleBriefShare = async (values) => {
    setShareBriefInviteInProgress(true);
    const { firmUserId = null } = values;
    const briefIds = await showUser({userId:firmUserId});
    const existingBriefIds = shareBriefModalState?.id?.uuid ? [...(briefIds || []), shareBriefModalState.id.uuid] : [];
    const uniqueBriefIds = Array.from(new Set(existingBriefIds)); // Removes duplicates
  
    const data = {
      id: firmUserId,
      protectedData: {
        briefIds: uniqueBriefIds
      },
    };
   const response = await  updateUserProfile({ data });
   if(response.message === 'ok') {
     setShareBriefModalState(false);
     setShareBriefInviteInProgress(false);
     setInviteSuccessToaster();
   }
  };

  
  
  
    return (
    <div className={classes}>
      <div
        className={css.threeToTwoWrapper}
        tabIndex={0}
        onClick={event => {
          event.preventDefault();
          event.stopPropagation();

          // ManageListingCard contains links, buttons and elements that are working with routing.
          // This card doesn't work if <a> or <button> is used to wrap events that are card 'clicks'.
          //
          // NOTE: It might be better to absolute-position those buttons over a card-links.
          // (So, that they have no parent-child relationship - like '<a>bla<a>blaa</a></a>')
          history.push(createListingURL(routeConfiguration(), listing));
        }}
        onMouseOver={onOverListingLink}
        onTouchStart={onOverListingLink}
      >
        {/* <div className={css.aspectWrapper}>
          <ResponsiveImage
            rootClassName={css.rootForImage}
            alt={title}
            image={firstImage}
            variants={['landscape-crop', 'landscape-crop2x']}
            sizes={renderSizes}
          />
        </div> */}
        {/* <div className={css.aspectWrapper}></div> */}
        {/* <div className={classNames(css.menuOverlayWrapper, { [css.menuOverlayOpen]: isMenuOpen })}>
          <div className={classNames(css.menuOverlay)} />
          <div className={css.menuOverlayContent}>
            <FormattedMessage id="ManageListingCard.viewListing" />
          </div>
        </div> */}
        <div className={css.menubarWrapper}>
          {currentPage === FIRM_DASHBOARD_PAGE ? null : <div className={css.menubar}>
            <Menu
              className={classNames(css.menu, { [css.cardIsOpen]: !isClosed })}
              contentPlacementOffset={MENU_CONTENT_OFFSET}
              contentPosition="left"
              useArrow={false}
              onToggleActive={isOpen => {
                const listingOpen = isOpen ? currentListing : null;
                onToggleMenu(listingOpen);
              }}
              isOpen={isMenuOpen}
            >
              <MenuLabel className={css.menuLabel} isOpenClassName={css.listingMenuIsOpen}>
               {isDraft ? null : <div className={css.iconWrapper}>
                  <MenuIcon className={css.menuIcon} isActive={isMenuOpen} />
                </div>}
              </MenuLabel>
              <MenuContent rootClassName={css.menuContent}>
                <MenuItem key="close-listing">
                  <InlineTextButton
                    rootClassName={menuItemClasses}
                    onClick={event => {
                      event.preventDefault();
                      event.stopPropagation();
                      if (!actionsInProgressListingId) {
                        onToggleMenu(null);
                        isSharedBrief ? dispatch(closeSharedBriefThroughIsdk(currentListing.id)) : onCloseListing(currentListing.id);
                      }
                    }}
                  >
                    <FormattedMessage id="ManageListingCard.closeListingPartner" />
                  </InlineTextButton>
                </MenuItem>
                {isDraft || [USER_ROLE_CLIENT, USER_ROLE_PARTNER]?.includes(userRole) ? null : <MenuItem key="share-listing">
                  <InlineTextButton
                    rootClassName={menuItemClasses}
                    onClick={event => {
                      event.preventDefault();
                      event.stopPropagation();
                      if (!actionsInProgressListingId) {
                        onToggleMenu(null);
                        setShareBriefModalState(listingMenuOpen);
                      }
                    }}
                  >
                    <FormattedMessage id="ManageListingCard.shareBrief" />
                  </InlineTextButton>
                </MenuItem>}
              </MenuContent>
            </Menu>
          </div>}
        </div>
        {/* {isDraft ? (
          <React.Fragment>
            <div className={classNames({ [css.draftNoImage]: !firstImage })} />
            <Overlay
              message={intl.formatMessage(
                { id: 'ManageListingCard.draftOverlayText' },
                { listingTitle: title }
              )}
            >
              <NamedLink
                className={css.finishListingDraftLink}
                name="EditListingPage"
                params={{ id, slug, type: LISTING_PAGE_PARAM_TYPE_DRAFT, tab: 'photos' }}
              >
                <FormattedMessage id="ManageListingCard.finishListingDraft" />
              </NamedLink>
            </Overlay>
          </React.Fragment>
        ) : null} */}

        {isPendingApproval ? (
          <Overlay
            message={intl.formatMessage(
              { id: 'ManageListingCard.pendingApproval' },
              { listingTitle: title }
            )}
          />
        ) : null}
        {/* {thisListingInProgress ? (
          <Overlay>
            <IconSpinner />
          </Overlay>
        ) : hasError ? (
          <Overlay errorMessage={intl.formatMessage({ id: 'ManageListingCard.actionFailed' })} />
        ) : null} */}
      </div>
      <div className={classNames(css.mainInfo, isClosed && css.disabled)}>
        <div className={css.titleWrapper}>
          <InlineTextButton
            rootClassName={titleClasses}
            className={css.titleButton}
            onClick={event => {
              event.preventDefault();
              event.stopPropagation();
              history.push(createListingURL(routeConfiguration(), listing));
            }}
          >
            <span>{formatTitle(title, MAX_LENGTH_FOR_WORDS_IN_TITLE)}</span>
          </InlineTextButton>
        </div>
        <div className={css.priceValue}>
          {formattedPrice}
        </div>
        <div className={css.experience}>
          <b>Description: </b>{description && description.slice(0, 160)}
        </div>
        <div className={css.author}>
          <div className={css.experience}>
            <b>Project timeline :</b> {publicData && publicData.projectTimelines}
          </div>
          <div className={css.experience}>
            <b>Price range :</b> {!!minMoney && formatMoney(intl, minMoney)} - {!!maxMoney && formatMoney(intl, maxMoney)}
          </div>
        </div>
        <div className={css.domainInfo}>
          {domainExpertise && domainExpertise.length ? domainExpertise.slice(0, 4).map(item =>
            <div className={css.domainCard} key={item}>{item && item}
            </div>
          ) : null}
        </div>
        <div className={css.industryInfo}>
          {industrySector && industrySector.length ? industrySector.slice(0, 4).map(item =>
            <div key={item}>{item && item}
            </div>
          ) : null}
        </div>
      </div>
      <div className={css.buttonWrapper}>
        {isDraft ? (
          <NamedLink
            className={css.finishListingDraftLink}
            name="EditListingPage"
            params={{ id, slug, type: LISTING_PAGE_PARAM_TYPE_DRAFT, tab:  DESCRIPTION }}
          >
            <FormattedMessage id="ManageListingCard.finishListingDraft" />
          </NamedLink>
        ) : <div></div>}
        {isClosed ? (
          <button
            className={css.openListingButton}
            disabled={!!actionsInProgressListingId}
            onClick={event => {
              event.preventDefault();
              event.stopPropagation();
              if (!actionsInProgressListingId) {
                isSharedBrief ? dispatch(openSharedBriefThroughIsdk(currentListing.id)) : onOpenListing(currentListing.id);
              }
            }}
          >
            <FormattedMessage id="ManageListingCard.openListingpartner" />
          </button>
        ) : null}
      </div>
      {shareBriefInviteInProgess }
     {shareBriefModalState ?
   
      <CustomBriefShareModal
        onSubmit={handleBriefShare}
        firmData={firmData}
        updateInProgress={shareBriefInviteInProgess}
        onClose={()=>setShareBriefModalState(false)}
        shareBriefModalState={shareBriefModalState}
        isCsm={isCsm}
      />
  
  :null}
    </div>
  );
};

ManageListingCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  actionsInProgressListingId: null,
  renderSizes: null,
  availabilityEnabled: config.enableAvailability,
};

const { bool, func, shape, string } = PropTypes;

ManageListingCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  hasClosingError: bool.isRequired,
  hasOpeningError: bool.isRequired,
  intl: intlShape.isRequired,
  // listing: propTypes.ownListing.isRequired,
  isMenuOpen: bool.isRequired,
  actionsInProgressListingId: shape({ uuid: string.isRequired }),
  onCloseListing: func.isRequired,
  onOpenListing: func.isRequired,
  onToggleMenu: func.isRequired,
  availabilityEnabled: bool,

  // Responsive image sizes hint
  renderSizes: string,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
};

export default compose(
  withRouter,
  injectIntl
)(ManageListingCardComponent);
