import React, { Component, useEffect, useState } from 'react';
import { array, string, func } from 'prop-types';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';
import { FAVOURITES_PAGE, FIRM_DASHBOARD_PAGE, propTypes, SEARCH_PAGE } from '../../util/types';
import { ensureListing } from '../../util/data';
import { richText } from '../../util/richText';
import { findOptionsForSelectFilter } from '../../util/search';
import { createSlug } from '../../util/urlHelpers';
import config from '../../config';
import { NamedLink, ResponsiveImage, ReviewRating, IconHeartFilled, IconHeart, AvatarMedium } from '../../components';
import SectionAvatar from '../../containers/ListingPage/SectionAvatar';
import css from './ListingCard.module.css';
import { getAuthorDisplayName, getTotalWorkYears, hasOwnFirm, isUserLinkedToFirm } from '../../util/destructorHelpers';
import { useSelector } from 'react-redux';
import { getFirmListing } from '../../containers/SearchPage/SearchPage.duck'
import moment from 'moment';
import { openAIImageDetails } from '../../util/destructorHelpers';
import useGetScreenWidth from '../../hooks/useGetScreenWidth';

const MIN_LENGTH_FOR_LONG_WORDS = 10;

const getCertificateInfo = (certificateOptions, key) => {
  return certificateOptions.find(c => c.key === key);
};

class ListingImage extends Component {
  render() {
    return <ResponsiveImage {...this.props} />;
  }
}

const LazyImage = lazyLoadWithDimensions(ListingImage, { loadAfterInitialRendering: 3000 });

export const ListingCardComponent = props => {
  const {
    className,
    rootClassName,
    intl,
    listing,
    renderSizes,
    filtersConfig,
    setActiveListing,
    bookmarks,
    preferredInsightGigPartner,
    isAuthenticated,
    handleWishlist,
    params: rawParams,
    currentPage,
    infoClassName,
  } = props;

  const screenWidth = useGetScreenWidth();

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);
  const openAIImageURL = openAIImageDetails(currentListing)?.imageURL ?? null;
  const id = currentListing.id.uuid;
  const { title = '', publicData } = currentListing.attributes;
  const { currentUser = {} } = useSelector(state => state.user)
  const userLinkedToFirm = !!currentUser?.id && isUserLinkedToFirm(currentUser);
  const userOwnFirm = !!currentUser?.id && hasOwnFirm(currentUser);
  const isUserHasSubscribed = currentUser?.id &&
    !!(currentUser?.attributes?.profile?.publicData?.freePlanData?.isFreePlanUsed ||
      currentUser?.attributes?.profile?.protectedData?.subscriptionPlan?.length);

  // const listingId = new UUID(rawParams.id);
  //   const isPendingApprovalVariant = rawParams.variant === LISTING_PAGE_PENDING_APPROVAL_VARIANT;
  //   const isDraftVariant = rawParams.variant === LISTING_PAGE_DRAFT_VARIANT;
  //   const currentListing =
  //     isPendingApprovalVariant || isDraftVariant
  //       ? ensureOwnListing(getOwnListing(listingId))
  //       : ensureListing(getListing(listingId));

  //   const listingSlug = rawParams.slug || createSlug(currentListing.attributes.title || '');
  //   const params = { slug: listingSlug, ...rawParams };
  const authorAvailable = currentListing?.id && currentListing.author;
  const currentAuthor = authorAvailable ? currentListing.author : null;
  const experience = publicData && publicData?.Experience;
  const displayName = currentListing?.id && currentListing?.author?.attributes?.profile?.displayName;
  const slug = createSlug(title);
  const firstImage = currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;

  const domainExpertise = findOptionsForSelectFilter('domainExpertise', filtersConfig);
  const industrySector = findOptionsForSelectFilter('industry', filtersConfig);

  const domainExpertiseOption = publicData?.domainExpertise
  const industrySectorOption = publicData?.industrySector

  const typeListing = currentListing?.id && currentListing?.attributes?.publicData?.listingType
  const firmLogoUrl = listing?.attributes?.publicData?.firmLogo?.url;
  const logoShape = listing?.attributes?.publicData?.firmLogo?.shape;

  const certificateOptions = findOptionsForSelectFilter('certificate', filtersConfig);
  const certificate = publicData
    ? getCertificateInfo(certificateOptions, publicData.certificate)
    : null;


  const UserExpertise = ({ options, classNameElement, classNameTitle })=>{
    let totalChar = 0
    let line2char = 0
    let line1 = []
    let line2 = []
    let maxWidth = (screenWidth && screenWidth>1024) ? 45 : 30

    options.map((item)=>{
      totalChar += item.length
      if((totalChar)<maxWidth || line1.length===0){
        line1 = [...line1,item]
      }
      else if(((totalChar)<80 && (line2char+item.length) < maxWidth)){
        line2char += item.length
        if(line2.length===0 && item.length>30){
          line2 = [item.substring(0,30)+"..."]
        }else{
          line2 = [...line2,item]
        }
      }
    })

    let itemsLeft = options.length-(line1.length + line2.length)

    return <ul className={css.checkBoxBlock}>
      {line1.map((ele,ind)=>(
        <li className={classNameElement} key={ind}>
          <label className={classNameTitle}>{ele}</label>
        </li>
      ))}
      {line2.map((ele,ind)=>(
        <li className={classNameElement} key={ind}>
          <label className={classNameTitle}>{ele}</label>
        </li>
      ))}
      <li>
        <label className={classNameTitle}>{itemsLeft > 0 && `... ${itemsLeft} more`}</label>
      </li>
    </ul>
  }

  const CheckBoxOption = ({ options, classNameElement, classNameTitle }) => {
    let totalChar = 0, count = 0, isCompleted = false;
    if (!options || !options.length) return null;

    return (
      <ul className={css.checkBoxBlock}>
        {options && options.map((el, i) => {
          const _temp = domainExpertise && domainExpertise.find(e => e && e.key === el) ||
            industrySector && industrySector.find(e => e && e.key === el);

          totalChar += el.length+5;
          count++;

          if(isCompleted){
            return
          }
          if(totalChar > 100){
            isCompleted = true
            return <li key={i}>
            <label className={classNameTitle}>{`... ${options.length - (count-1)} more`}</label>
          </li>
          }
          return (
            <li className={classNameElement} key={i}>
              <label className={classNameTitle}>{_temp ? _temp.label : el}</label>
            </li>
          )
        }
        )}
      </ul>
    )
  }

  // const reviews = 0
  // let reviewsTotalRating =
  //   reviews &&
  //   reviews.reduce((accumulator, currentValue) => {
  //     return accumulator + currentValue.attributes.rating;
  //   }, 0);

  // const reviewsAverageRating =
  //   reviewsTotalRating && reviews ? Math.round(reviewsTotalRating / reviews.length) : 0;

  const ratings = currentListing?.attributes?.publicData?.ratings || [];
  const ratingSum = ratings.length ? ratings.reduce((acc, curr) => acc + curr, 0) : 0;
  const totalReviews = ratings.length;
  const reviewsAverageRating = ratingSum === 0 ? 0 : Math.round(ratingSum / totalReviews);

  const firmId = currentAuthor?.id && currentAuthor?.attributes?.profile?.publicData?.firmId;
  const isUserConnectedToFirm = currentAuthor?.attributes?.profile?.publicData?.linkedToFirms?.length > 0 &&
    currentAuthor?.attributes?.profile?.publicData?.linkedToFirms;

  const firmTitle = firmId ? currentAuthor?.attributes?.profile?.publicData?.firmListing?.firmTitle 
    : Array.isArray(isUserConnectedToFirm) && isUserConnectedToFirm[0]?.firmName;
  const totalExperience = currentListing?.attributes?.publicData?.experience ;

  return (
    <NamedLink className={classes} name="ListingPage" params={{ id, slug }}>
      <div
        className={css.threeToTwoWrapper}
        onMouseEnter={() => setActiveListing(currentListing.id)}
        onMouseLeave={() => setActiveListing(null)}
      >
        <div className={css.aspectWrapper}>
          <AvatarMedium user={currentAuthor}/>
          {firmLogoUrl && (
            <img className={css.firmLogo} src={firmLogoUrl} style={{width: logoShape==="rectangle"?"60px":"40px", borderRadius: logoShape==="circle"?"50%":"0"}}/>
          )}
        </div>
      </div>
      <div className={css.favorite}>
      </div>
      <div className={classNames(infoClassName, css.info)}>
        <div className={css.mainInfo}>
          <div className={css.headingBoxMain}>
            <div className={css.title}>
              <h3 className={css.authorName}>
                {currentListing && currentListing.author && getAuthorDisplayName(currentListing.author)}
              </h3>
              <span className={css.authorTitle}>
                {richText(title, {
                  longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
                  longWordClass: css.longWord,
                })}
              </span>
            </div>
            {/* {currentPage === SEARCH_PAGE || currentPage === FIRM_DASHBOARD_PAGE || currentPage === FAVOURITES_PAGE ? null : <div className={css.favIconBox}>
              {firmTitle && <span>{`Firm (${firmTitle})`}</span>}
              {preferredInsightGigPartner && preferredInsightGigPartner.findIndex(e => e === id) > -1 ? (
                <span
                  className={css.bookmark}
                  onClick={e => {
                    handleWishlist(id, e);
                  }}
                >
                  <IconHeartFilled />
                </span>
              ) : (
                <span
                  className={css.bookmark}
                  onClick={e => {
                    handleWishlist(id, e);
                  }}
                >
                  <IconHeart />
                </span>
              )}
            </div>} */}
          </div>
          {currentPage === FIRM_DASHBOARD_PAGE ? null : <div className={css.reviewsSubHeading}>
            <ReviewRating
              rating={1}
              stars={[1]}
              reviewStarClassName={css.reviewStar}
              className={css.reviewStars}
            />
            <span className={css.reviewTotalCount}>{reviewsAverageRating}</span>
            <span className={css.reviewTotalInfo}>
              <FormattedMessage
                className={css.reviewTotalInfo}
                id="ListingPage.reviewsLabel"
                values={{ count: totalReviews }}
              />
            </span>
          </div>}
          {totalExperience === 0 ? (
            <>
              <span className={css.experience}>
                <FormattedMessage id="ListingPage.noWorkExperienceUser" />
              </span>
            </>
          ) : (totalExperience >= 1) ? (
            <>
              <span className={css.experience}>
                {totalExperience}
                <FormattedMessage
                  id="ListingPage.experienceUser"
                  values={{ values: totalExperience > 1 ? 's' : null }}
                />
              </span>
            </>) :
            <>
              <span className={css.experience}>
                <FormattedMessage id="ListingPage.lessThanOneYearExperienceUser" />
              </span>
            </>
          }
          <UserExpertise
            options={domainExpertiseOption}
            classNameElement={classNames(css.styleElement, css.borderStyleDomainExpertise)}
            classNameTitle={classNames(css.styleTitleCategory, css.colorStyleDomainExpertise)} 
          />
          <UserExpertise
            options={industrySectorOption}
            classNameElement={classNames(css.styleElement, css.borderStyleIndustrySector)}
            classNameTitle={classNames(css.styleTitleCategory, css.colorStyleIndustrySector)} 
          />
          {/* <div className={css.certificateInfo}>
            {certificate && !certificate.hideFromListingInfo ? (
              <span>{certificate.label}</span>
            ) : null}
          </div> */}
        </div>
      </div>
    </NamedLink>
  );
};

ListingCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
  filtersConfig: config.custom.filters,
  setActiveListing: () => null,
};

ListingCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  filtersConfig: array,
  intl: intlShape.isRequired,
  listing: propTypes.listing.isRequired,

  // Responsive image sizes hint
  renderSizes: string,

  setActiveListing: func,
};

export default injectIntl(ListingCardComponent);
