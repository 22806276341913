import React, { useEffect, useState, } from 'react';
import { string } from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import {
  IconCard,
  Modal,
  SecondaryButton,
} from '..';
import { Form as FinalForm, Field } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import css from './TransactionPanel.module.css';
import moment from "moment";
const dateFormat = 'MMM D';
import { SELECTED, USER_ROLE_CLIENT, USER_ROLE_CSM, USER_ROLE_PARTNER } from "../../util/types";
import { MilestoneForm } from '../../forms';
import { useSelector } from 'react-redux';
import { getUserRole } from '../../util/userRole';
import { OPEN_MILESSTONE_MODAL } from '../IconCard/IconCard';
import { getUserDetails } from '../../util/destructorHelpers';
import { TRANSITION_EXPIRE_PAYMENT, TRANSITION_REQUEST_MARK_COMPLETE, TRANSITION_REQUEST_MARK_COMPLETE_OPERATOR } from '../../util/transaction';
import { getUserShortName } from '../../util/typeHelpers';

const FieldMilestones = props => {
  const {
    typeUser,
    rootClassName,
    className,
    inputRootClass,
    id,
    label,
    meta,
    intl,
    input,
    name,
    initialValues,
    changeContentMilestones,
    onDeleteMilestone,
    actionInProgress,
    onToggle,
    editable,
    onManageDisableScrolling,
    collaborationMetaData,
    currentTransaction,
    currentUser,
    isGigAppTransaction,
    ...rest
  } = props;

  const isCollaborator = collaborationMetaData?.length && collaborationMetaData.some(({ collaboratorID }) => collaboratorID === currentUser?.id?.uuid);
  const { provider = {} } = currentTransaction || {}
  const currentUserObject = {
    collaboratorID: isCollaborator ? provider?.id?.uuid : currentUser?.id?.uuid,
    fullName: isCollaborator ? getUserDetails(provider).fullName : getUserDetails(currentUser)?.fullName,
    profileImage: isCollaborator ? getUserDetails(provider)?.profileImage : getUserDetails(currentUser)?.profileImage
  }
  const isTransitionMarkedAsComplete = !!currentTransaction?.id &&
    currentTransaction?.attributes?.transitions.some(tx =>
      [TRANSITION_REQUEST_MARK_COMPLETE_OPERATOR, TRANSITION_REQUEST_MARK_COMPLETE, TRANSITION_EXPIRE_PAYMENT].includes(tx.transition)
    );


  const classes = classNames(rootClassName || css.root, className);
  const [addItemMilestone, setAddItemMilestone] = useState(false);
  const [editedMilestoneId, setEditedMilestoneId] = useState(null);
  const [toggleMilestone, setToggleMilestone] = useState(null);
  const userRole = !!currentUser?.id && getUserRole(currentUser);
  const milestoneInitialValues = m => {
    if (!m) {
      return {}
    }
    const milestone = m && initialValues?.milestones
      .find(({ id }) => m?.id === id);
    return {
      ...milestone,
      selected: milestone?.selected?.length ? 'true' : 'false',
      date: milestone?.date,
    }
  }

  const editMilestone = id => {
    setEditedMilestoneId(id);
    setAddItemMilestone(true);
  }



  const handleRowHover = (milestone) => {
    setToggleMilestone(milestone);
  };

  const deleteMilestone = async id => {
    try {
      const response = await onDeleteMilestone(id);
      const { data: { data = {} = {} } } = response || {};
      if (data.id) {
        handleRowHover(null);
      }
    } catch (error) {
      // Handle the error
    }
  };

  const onClose = () => {
    setAddItemMilestone(false);
    setEditedMilestoneId(null);
    setToggleMilestone(null)
  }
  const saveNewMilestonesItem = async values => {


    const {
      date,
      title,
      description,
      id,
      selected,
      ...rest
    } = values;
    const response = await changeContentMilestones({
      date,
      title,
      description,
      id: id || String(Date.now()),
      selected: [],
      ...rest
    });

    const { data: { data = {} } = {} } = response || {};
    if (data?.id) {
      onClose()
    }
  }
  const getMilestoneTitle = (m) => {
    let title = '';

    if (m.dependency) {
      try {
        const dependencyObject = JSON.parse(m.dependency);
        title = dependencyObject.title;
      } catch (error) {
      }
    }

    return title;
  };

  const shouldRenderAddMilestoneButton = () => {
    return (typeUser === USER_ROLE_PARTNER && !isGigAppTransaction) || (isGigAppTransaction && typeUser === USER_ROLE_CSM);
  };
  return (
    <div className={classes}>
      <FinalForm
        onSubmit={() => { }}
        initialValues={initialValues}
        mutators={{ ...arrayMutators }}
        render={({ form, values }) => {
          const { metadata: { milestones = [] } = {} } = (currentTransaction?.id && currentTransaction?.attributes) ?? {};

          const handleCheckboxChange = (e, milestone, form) => {
            const { checked } = e.target;
            const updatedMilestones = form.getState().values.milestones.map((m) => {
              if (m.id === milestone.id) {
                return {
                  ...m,
                  selected: checked ? [SELECTED] : [],
                };
              }
              return m;
            });

            form.change('milestones', updatedMilestones);
            onToggle(milestone.id, checked); // Call the onToggle function here with the updated values
          };
          const totalMilestones = initialValues?.milestones.length;
          const checkedMilestones = initialValues?.milestones.filter(
            (m) => m.selected && m.selected.length > 0
          );
          const checkedCount = checkedMilestones.length;
          const progress = totalMilestones > 0 ? (checkedCount / totalMilestones) * 100 : 0;

          return (
            <div>
              <div className={classNames(css.tableWrapper)}>
                <table>
                  <thead>
                    <tr>
                      <th><FormattedMessage id="TransactionPanel.tableMilestoneHeading" /> </th>
                      <th><FormattedMessage id="TransactionPanel.tableMilestoneOwnerHeading" /> </th>
                      <th><FormattedMessage id="TransactionPanel.tableMilestoneDueDateHeading" /> </th>
                      <th><FormattedMessage id="TransactionPanel.tableMilestoneDependencyHeading" /> </th>
                    </tr>
                  </thead>
                  <tbody>
                    {milestones?.map(m => (
                      <tr
                        key={m.id}
                        className={
                          m.selected.length ? css.selectSuccess :
                            !m.selected.length ? css.dangerRed :
                              css.unSelect}
                      >
                        <td className={css.firstTd}>
                          <div
                            className={css.milestoneBox}>
                            {typeUser === USER_ROLE_PARTNER && (
                              <Field name="milestones">
                                {({ input }) =>
                                  <span key={m.id} className={css.checkboxMilestone} >
                                    <input
                                      id={m.id}
                                      name={m.title}
                                      type="checkbox"
                                      checked={input?.value?.some((item) => item.id === m.id && item.selected && item.selected.length > 0)}
                                      onChange={(e) => handleCheckboxChange(e, m, form)}
                                    />
                                  </span>
                                }
                              </Field>
                            )}
                            <span className={css.milestonesTitle} data-tooltip={m.title}>{m.title}</span>
                          </div>
                          <span
                            className={css.openMilestone}
                            onClick={() => handleRowHover(m)}
                          >
                            <IconCard brand={OPEN_MILESSTONE_MODAL} />
                          </span>
                        </td>
                        <td className={toggleMilestone === m ? SELECTED : ''}>
                          <div className={css.selectOwnerBox}>
                            {m?.selectOwner?.profileImage ? (
                              <img className={css.profileImageMilestone} src={m?.selectOwner?.profileImage} alt='profileImage' />
                            ) : (
                              <div className={classNames(m?.selectOwner && css.noImageWithText)}>
                                {!!m?.selectOwner?.fullName
                                  ? getUserShortName(m?.selectOwner?.fullName)
                                  : '-'
                                }
                              </div>
                            )}
                          </div>
                        </td>
                        <td>
                          <div className={css.profileDate}>
                            {moment.unix(m.date).format(dateFormat)}
                          </div>
                        </td>
                        <td>
                          <div className={css.dependencyBox}>
                            {m?.dependency ? getMilestoneTitle(m) : '-'}
                          </div>
                        </td>
                      </tr>
                    ))}
                    {shouldRenderAddMilestoneButton () ? (
                      <tr>
                        <td colSpan={4}>
                          <button
                            className={css.addMilestone}
                            onClick={() => setAddItemMilestone(true)}
                          >
                           <FormattedMessage id="TransactionPanel.addMilestone" />
                          </button>
                        </td>
                      </tr>
                    ) : null}
                  </tbody>
                </table>
              </div>
              <div className={css.progressBox}>
                <div className={css.progressHeading}>
                  <FormattedMessage
                    id="TransactionPanel.milestoneProgressBarHeading"
                    values={{ checkedCount, totalMilestones }}
                  />
                </div>
                <div className={css.progressBarBox}>
                  <div className={css.progressBar}>
                    <div
                      style={{
                        width: `${progress}%`,
                        backgroundColor: '#F6B431',
                        height: '10px',
                      }}
                    />
                  </div>
                  <div className={css.progressPercent}>{`${progress.toFixed(0)}%`}</div>
                </div>
              </div>
            </div>
          )
        }}
      />

      <Modal
        id='MilestoneCard'
        isOpen={!!addItemMilestone || !!toggleMilestone}
        onClose={onClose}
        onManageDisableScrolling={onManageDisableScrolling}
      >
        {addItemMilestone && !editedMilestoneId && !isTransitionMarkedAsComplete ? (
          <h3 className={css.modalHeading}>
            <FormattedMessage
              id="TransactionPanel.addMileStoneModalHeading"
            />
          </h3>
        ) : editedMilestoneId ? (
          <h3 className={css.modalHeading}>
            <FormattedMessage
              id="TransactionPanel.editMileStoneModalHeading"
            />
          </h3>
        ) : (
          <h3 className={css.modalHeading}>
            <FormattedMessage
              id="TransactionPanel.toggledMileStoneModalHeading"
              values={{ title: toggleMilestone?.title }}
            />
          </h3>
        )}
        {addItemMilestone && !isTransitionMarkedAsComplete ?
          <MilestoneForm
            milestones={initialValues?.milestones}
            initialValues={milestoneInitialValues(toggleMilestone)}
            intl={intl}
            onSubmit={saveNewMilestonesItem}
            updateInProgress={actionInProgress}
            collaborationMetaData={collaborationMetaData}
            dateFormat={dateFormat}
            editedMilestoneId={editedMilestoneId}
            currentUserObject={currentUserObject}
            currentTransaction={currentTransaction}

          /> :
          toggleMilestone && (
            <div className={css.milestoneWrapper}>
              <p className={css.descriptionText}>
                <FormattedMessage
                  id="TransactionPanel.toggledMileStoneModalDescription"
                  values={{ description: toggleMilestone?.description }}
                />
              </p>
              <div className={css.toggleMilestoneOwnerPanel}>
                <div className={css.milesList}>
                  <div className={css.milesHeading}>
                    <FormattedMessage id="TransactionPanel.toggledMileStoneOwnerNameHeading" />
                  </div>
                  <div className={css.headingNameImage}>
                    {toggleMilestone?.selectOwner?.profileImage ?
                      <img
                        className={css.profileImage}
                        src={toggleMilestone?.selectOwner?.profileImage}
                        alt='profileImage'
                      /> :
                      <div className={css.noImageWithText}>
                        {!!toggleMilestone?.selectOwner?.fullName
                          && getUserShortName(toggleMilestone?.selectOwner?.fullName)
                        }
                      </div>
                    }
                    <span className={css.ownerName}>{toggleMilestone?.selectOwner?.fullName}</span>
                  </div>
                </div>
                <div className={css.milesList}>
                  <div className={css.milesHeading}>
                    <FormattedMessage id="TransactionPanel.dueDate" />
                  </div>

                  <div className={css.headingNameImage}>
                    <span className={css.ownerName}>{moment.unix(toggleMilestone.date).format(dateFormat)}</span>
                  </div>
                </div>
                <div className={css.milesList}>
                  <div className={css.milesHeading}>
                    <FormattedMessage id="TransactionPanel.dependency" />
                  </div>
                  <div className={css.headingNameImage}>
                    <span className={css.ownerName}>{toggleMilestone.dependency ? getMilestoneTitle(toggleMilestone) : '-'}</span>
                  </div>
                </div>
                {shouldRenderAddMilestoneButton () && (
                  <div className={css.bottomButton}>
                    <SecondaryButton
                      className={css.withOutBorder}
                      inProgress={actionInProgress}
                      type="button"
                      onClick={() => deleteMilestone(toggleMilestone?.id)}
                    >
                      <FormattedMessage
                        id="TransactionPanel.toggledMileStoneDeleteButton"
                      />
                    </SecondaryButton>
                    <SecondaryButton
                      type="button"
                      onClick={() => editMilestone(toggleMilestone?.id)}
                    >
                      <FormattedMessage
                        id="TransactionPanel.toggledMileStoneEditButton"
                      />
                    </SecondaryButton>
                  </div>
                )}
              </div>
            </div>
          )}
        {isTransitionMarkedAsComplete && (
          <div className={css.isTransitionMarkedAsComplete}>
            <h3>
              <FormattedMessage id="TransactionPanel.isTransitionMarkedAsComplete" />
            </h3>
          </div>
        )}
      </Modal>

    </div>
  );
}

FieldMilestones.defaultProps = {
  rootClassName: null,
  className: null,
  inputRootClass: null,
  id: null,
  label: null,
};

FieldMilestones.propTypes = {
  rootClassName: string,
  className: string,
  inputRootClass: string,
  id: string,
  label: string,
}

export default compose(injectIntl)(FieldMilestones);
