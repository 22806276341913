import React from 'react';
import classNames from 'classnames';

import css from './IconEditPencil.module.css';

const IconEditPencil = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg
      className={classes} 
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path fillRule="evenodd" clipRule="evenodd" d="M5.30722 11.1549L1 13L2.84595 8.69191L10.5387 1L13 3.46127L5.30722 11.1549V11.1549Z" stroke="#3D3D3D" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M11 5L9 3" stroke="#3D3D3D" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M5 11L3 9" stroke="#3D3D3D" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
};

export default IconEditPencil;
