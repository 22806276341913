/*
 * Marketplace specific configuration.
 *
 * Every filter needs to have following keys:
 * - id:     Unique id of the filter.
 * - label:  The default label of the filter.
 * - type:   String that represents one of the existing filter components:
 *           BookingDateRangeFilter, KeywordFilter, PriceFilter,
 *           SelectSingleFilter, SelectMultipleFilter.
 * - group:  Is this 'primary' or 'secondary' filter?
 *           Primary filters are visible on desktop layout by default.
 *           Secondary filters are behind "More filters" button.
 *           Read more from src/containers/SearchPage/README.md
 * - queryParamNames: Describes parameters to be used with queries
 *                    (e.g. 'price' or 'pub_amenities'). Most of these are
 *                    the same between webapp URLs and API query params.
 *                    You can't change 'dates', 'price', or 'keywords'
 *                    since those filters are fixed to a specific attribute.
 * - config: Extra configuration that the filter component needs.
 *
 * Note 1: Labels could be tied to translation file
 *         by importing FormattedMessage:
 *         <FormattedMessage id="some.translation.key.here" />
 *
 * Note 2: If you need to add new custom filter components,
 *         you need to take those into use in:
 *         src/containers/SearchPage/FilterComponent.js
 *
 * Note 3: If you just want to create more enum filters
 *         (i.e. SelectSingleFilter, SelectMultipleFilter),
 *         you can just add more configurations with those filter types
 *         and tie them with correct extended data key
 *         (i.e. pub_<key> or meta_<key>).
 */


//need only for test milestone in Transaction Page
export const someArr = [
  {
    selected: [],
    date: '12/11/2021',
    content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex.',
    title: 'Questionnaire development',
  },
  {
    selected: [],
    date: '13/11/2021',
    content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.',
    title: 'Questionnaire launched',
  },
  {
    selected: [],
    date: '14/11/2021',
    content: '',
    title: 'Analyse questionnaire results',
  },
  {
    selected: [],
    date: '15/11/2021',
    content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.',
    title: 'Present insights',
  },
  {
    selected: ['selected'],
    date: '16/11/2021',
    content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.',
    title: 'Workshop with the team to brainstorm solutions',
  },
]


export const currencyFeeTemp = [
  { label: 'INR ', key: "INR", symbol: "(₹)", sym: "₹" },
  { label: 'USD ', key: "USD", symbol: "($)", sym: "$" },
]


export const ExpertType = [
  { key: 'Individual', label: 'Individual' },
  { key: 'Company', label: 'Company' },
];
export const industryType = [
  { key: 'fmcg', label: 'FMCG' },
  { key: 'b2b', label: 'B2B' },
  { key: 'pharma', label: 'Pharma' },
  { key: 'techStartups', label: 'Tech & Startups' },
  { key: 'automotive', label: 'Automotive' },
  { key: 'finance', label: 'Finance' },
  { key: 'aviation', label: 'Aviation' },
  { key: 'others', label: 'Others' },
];
export const typeCompanyArr = [
  { key: 'soleProprietorship', label: 'Sole Proprietorship' },
  { key: 'partnership', label: 'Partnership' },
  { key: 'privateCompany', label: 'Private Limited Company' },
  { key: 'publicCompany', label: 'Public Limited Company' },
  { key: 'limitedLiabilityPartnership', label: 'Limited Liability Partnership' },
];

export const typeBusinessArr = [
  { key: 'privateLimited', label: 'Private Limited' },
  { key: 'proprietorship', label: 'Proprietorship' },
  { key: 'partnership', label: 'Partnership' },
  { key: 'individual', label: 'Individual' },
  { key: 'publicLimited', label: 'Public Limited' },
  { key: 'LLP', label: ' LLP' },
  { key: 'Trust', label: 'Trust' },
  { key: 'Society', label: 'Society' },
  { key: 'NGO', label: 'NGO' },
];

export const filters = [
  {
    id: 'dates-length',
    label: 'Dates',
    type: 'BookingDateRangeLengthFilter',
    group: 'primary',
    // Note: BookingDateRangeFilter is fixed filter,
    // you can't change "queryParamNames: ['dates'],"
    queryParamNames: ['dates', 'minDuration'],
    config: {
      // See: https://en.wikipedia.org/wiki/List_of_tz_database_time_zones
      searchTimeZone: 'Etc/UTC',

      // Options for the minimum duration of the booking
      options: [
        { key: '0', label: 'Any length' },
        { key: '60', label: '1 hour', shortLabel: '1h' },
        { key: '120', label: '2 hours', shortLabel: '2h' },
      ],
    },
  },
  {
    id: 'price',
    label: 'Price',
    type: 'PriceFilter',
    group: 'primary',
    // Note: PriceFilter is fixed filter,
    // you can't change "queryParamNames: ['price'],"
    queryParamNames: ['price'],
    // Price filter configuration
    // Note: unlike most prices this is not handled in subunits
    config: {
      min: 0,
      max: 1000,
      step: 5,
    },
  },
  {
    id: 'certificate',
    label: 'Certificate',
    type: 'SelectSingleFilter',
    group: 'secondary',
    queryParamNames: ['pub_certificate'],
    config: {
      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'none', label: 'None', hideFromFilters: true, hideFromListingInfo: true },
        { key: '200h', label: 'Registered yoga teacher 200h' },
        { key: '500h', label: 'Registered yoga teacher 500h' },
      ],
    },
  },
  {
    id: 'domainExpertise',
    label: 'Domain expertise',
    type: 'SelectMultipleFilter',
    group: 'primary',
    // Note: KeywordFilter is fixed filter,
    // you can't change "queryParamNames: ['keywords'],"
    queryParamNames: ['pub_domainExpertise'],
    // NOTE: If you are ordering search results by distance
    // the keyword search can't be used at the same time.
    // You can turn on/off ordering by distance from config.js file.
    config: {
      // Optional modes: 'has_all', 'has_any'
      // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
      searchMode: 'has_any',
      options: [
        { key: 'endToEndResearch', label: 'End-to-End Research' },
        { key: 'customerExperienceManagement', label: 'Customer Experience Management' },
        { key: 'dataAnalytics', label: 'Data Analytics' },
        { key: 'moderation', label: 'Moderation' },
        { key: 'fieldwork', label: 'Fieldwork' },
        { key: 'respondentRecruitment', label: 'Respondent Recruitment' },
        { key: 'onlinePanels', label: 'Online Panels' },
        { key: 'qualitativeResearch', label: 'Qualitative Research' },
        { key: 'researchDesign', label: 'Research Design or Questionnaire Development' },
        { key: 'reporting', label: 'Reporting' },
        { key: 'briefProposalDevelopment', label: 'Brief/Proposal Development' },
        { key: 'quantitativeResearch', label: 'Quantitative Research' },
        { key: 'uxResearch', label: 'UX Research' },
        { key: 'projectmanagement', label: 'Project Management' },
        { key: 'storyTelling', label: 'Story Telling' },
        { key: 'others', label: "Others" },
      ],
    },
  },
  {
    id: 'industry',
    label: 'Industry',
    type: 'SelectMultipleFilter',
    group: 'primary',
    // Note: KeywordFilter is fixed filter,
    // you can't change "queryParamNames: ['keywords'],"
    queryParamNames: ['pub_industrySector'],
    // NOTE: If you are ordering search results by distance
    // the keyword search can't be used at the same time.
    // You can turn on/off ordering by distance from config.js file.
    config: {
      // Optional modes: 'has_all', 'has_any'
      // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
      searchMode: 'has_any',
      options: [
        { key: 'fmcg', label: 'FMCG' },
        { key: 'b2b', label: 'B2B' },
        { key: 'pharma', label: 'Pharma' },
        { key: 'techStartups', label: 'Tech-Startups' },
        { key: 'automotive', label: 'Automotive' },
        { key: 'finance', label: 'Finance' },
        { key: 'aviation', label: 'Aviation' },
        { key: 'others', label: 'Others' },
      ],
    },
  },
  {
    id: 'engagementRoles',
    label: 'Open to',
    type: 'SelectMultipleFilter',
    group: 'primary',
    // Note: KeywordFilter is fixed filter,
    // you can't change "queryParamNames: ['keywords'],"
    queryParamNames: ['pub_engagementRoles'],
    // NOTE: If you are ordering search results by distance
    // the keyword search can't be used at the same time.
    // You can turn on/off ordering by distance from config.js file.
    config: {
      // Optional modes: 'has_all', 'has_any'
      // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
      searchMode: 'has_any',
      options: [
        { key: 'projects', label: 'Ad-hoc projects' },
        { key: 'secondment', label: 'Secondment' },
        { key: 'fullTimeEmployment', label: 'Full-time employment' },
      ],
    },
  },
  {
    id: 'totalExperience',
    label: 'Total Experience',
    type: 'SelectMultipleFilter',
    group: 'primary',
    // Note: KeywordFilter is fixed filter,
    // you can't change "queryParamNames: ['keywords'],"
    queryParamNames: ['pub_totalExperience'],
    // NOTE: If you are ordering search results by distance
    // the keyword search can't be used at the same time.
    // You can turn on/off ordering by distance from config.js file.
    config: {
      // Optional modes: 'has_all', 'has_any'
      // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
      searchMode: 'has_any',
      options: [
        { key: '0-3', label: '0 to 3 years' },
        { key: '3-6', label: '3 to 6 years' },
        { key: '6-10', label: '6 to 10 years' },
        { key: '10-15', label: '10 to 15 years' },
        { key: '15-20', label: '15 to 20 years' },
        { key: '20-100', label: '> 20 years' },
      ],
    },
  },
  {
    id: 'expert-unavailability',
    label: 'Availability',
    type: 'SelectMultipleFilter',
    group: 'primary',

    queryParamNames: ['pub_unavailability'],
    config: {
      // Optional modes: 'has_all', 'has_any'
      // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
      searchMode: 'has_any',
      options: [
        { key: 'available', label: 'Available' },
        { key: 'unAvailable', label: 'Unavailable' },

      ],
    },
  },
  {
    id: 'packages',
    label: 'Packages',
    type: 'SelectMultipleFilter',
    group: 'primary',
    queryParamNames: ['pub_servicePackage'],
    config: {
      // Schema type is enum for SelectSingleFilter
      schemaType: 'multi-enum',
      searchMode: 'has_any',
      options: [
        {
          key: 'scout',
          label: 'SCOUT: Exploratory Study',
          moreOption: 'Learn more',
          parentKey: 'marketResearch',
          subCategory: 'B2B'
        },
        {
          key: 'augur',
          label: 'AUGUR: B2B Concept Study',
          moreOption: 'Learn more',
          parentKey: 'marketResearch',
          subCategory: 'B2B'
        },
        {
          key: 'acumen',
          label: 'Acumen: Market Size Estimator',
          moreOption: 'Learn more',
          parentKey: 'marketResearch',
          subCategory: 'B2B'
        },
        {
          key: 'sift',
          label: 'SIFT: Partner Identification',
          moreOption: 'Learn more',
          parentKey: 'marketResearch',
          subCategory: 'B2B'
        },
        {
          key: 'crisp',
          label: 'CRISP: Competitor Analysis',
          moreOption: 'Learn more',
          parentKey: 'marketResearch',
          subCategory: 'B2B'
        },
        {
          key: 'tag',
          label: 'TAG: B2B Customer Database',
          moreOption: 'Learn more',
          parentKey: 'marketResearch',
          subCategory: 'B2B'
        },
        {
          key: 'stature',
          label: 'STATURE: Channel Strength',
          moreOption: 'Learn more',
          parentKey: 'marketResearch',
          subCategory: 'B2B'
        },
        {
          key: 'ken',
          label: 'KEN: B2B Brand Dipstick',
          moreOption: 'Learn more',
          parentKey: 'marketResearch',
          subCategory: 'B2B'
        },
        {
          key: 'star',
          label: 'STAR: Event Feedback Study',
          moreOption: 'Learn more',
          parentKey: 'marketResearch',
          subCategory: 'B2B'
        },
        {
          key: 'pin-point',
          label: 'PINPOINT: B2B Diagnostic Study',
          moreOption: 'Learn more',
          parentKey: 'marketResearch',
          subCategory: 'B2B'
        },
        {
          key: 'panorama',
          label: 'PANORAMA: Corporate Image Study',
          moreOption: 'Learn more',
          parentKey: 'marketResearch',
          subCategory: 'B2B'
        },
        {
          key: 'intellect',
          label: 'INTELLECT: M&A Research Advisory ',
          moreOption: 'Learn more',
          parentKey: 'marketResearch',
          subCategory: 'B2B'
        },
        {
          key: 'chart',
          label: 'CHART: Competition Mapping',
          moreOption: 'Learn more',
          parentKey: 'marketResearch',
          subCategory: 'B2B'
        },
        {
          key: 'focus',
          label: 'FOCUS: Market Segmentation Exercise',
          moreOption: 'Learn more',
          parentKey: 'marketResearch',
          subCategory: 'B2C',
        },
        {
          key: 'solve',
          label: 'SOLVE: Brand Diagnostic Study',
          moreOption: 'Learn more',
          parentKey: 'marketResearch',
          subCategory: 'B2C',
        },
        {
          key: 'impact',
          label: 'IMPACT: Campaign Impact Assessment',
          moreOption: 'Learn more',
          parentKey: 'marketResearch',
          subCategory: 'B2C',
        },

        {
          key: 'view',
          label: 'VIEW: Concept Testing',
          moreOption: 'Learn more',
          parentKey: 'marketResearch',
          subCategory: 'B2C',
        },
        {
          key: 'impression',
          label: 'IMPRESSION: Packaging & Name Testing',
          moreOption: 'Learn more',
          parentKey: 'marketResearch',
          subCategory: 'B2C',
        },
        {
          key: 'dialogue',
          label: 'DIALOGUE: Communication Testing',
          moreOption: 'Learn more',
          parentKey: 'marketResearch',
          subCategory: 'B2C',
        },
        {
          key: 'apply',
          label: 'APPLY: User Testing',
          moreOption: 'Learn more',
          parentKey: 'marketResearch',
          subCategory: 'B2C',
        },
        {
          key: 'involve',
          label: 'INVOLVE: Product Testing',
          moreOption: 'Learn more',
          parentKey: 'marketResearch',
          subCategory: 'B2C',
        },
        {
          key: 'iterate',
          label: 'ITERATE: Rapid Experimentation',
          moreOption: 'Learn more',
          parentKey: 'marketResearch',
          subCategory: 'B2C',
        },
        {
          key: 'adopt',
          label: 'ADOPT: Brand Equity Assessment',
          moreOption: 'Learn more',
          parentKey: 'marketResearch',
          subCategory: 'B2C',
        },
        {
          key: 'esteem',
          label: 'Esteem: Brand Perception Mapping',
          moreOption: 'Learn more',
          parentKey: 'marketResearch',
          subCategory: 'B2C',
        },
        {
          key: 'esteem',
          label: 'Esteem: Brand Perception Mapping',
          moreOption: 'Learn more',
          parentKey: 'marketResearch',
          subCategory: 'B2C',
        },
        {
          key: 'vibes',
          label: 'VIBES: Usage & Attitude (U&A) Study',
          moreOption: 'Learn more',
          parentKey: 'marketResearch',
          subCategory: 'B2C',
        },
        {
          key: 'monitor',
          label: 'MONITOR: Brand Tracking',
          moreOption: 'Learn more',
          parentKey: 'marketResearch',
          subCategory: 'B2C',
        },
        {
          key: 'slip',
          label: 'SLIP: Lapsing Diagnosis',
          moreOption: 'Learn more',
          parentKey: 'marketResearch',
          subCategory: 'B2C',
        },
        {
          key: 'value',
          label: 'VALUE: Pricing Research',
          moreOption: 'Learn more',
          parentKey: 'marketResearch',
          subCategory: 'B2C',
        },

        {
          key: 'touchpoints',
          label: 'TOUCHPOINTS: Customer Journey Mapping',
          moreOption: 'Learn more',
          parentKey: 'customerExperience',
          subCategory: 'CX AND EX',
        },
        {
          key: 'proactive',
          label: 'PROACTIVE: Customer Experience Management Program',
          moreOption: 'Learn more',
          parentKey: 'customerExperience',
          subCategory: 'CX',
        },
        {
          key: 'happy',
          label: 'HAPPY: Customer Satisfaction Survey',
          moreOption: 'Learn more',
          parentKey: 'customerExperience',
          subCategory: 'CX',
        },
        {
          key: 'regain',
          label: 'REGAIN: Lapsing Diagnosis',
          moreOption: 'Learn more',
          parentKey: 'customerExperience',
          subCategory: 'CX',
        },
        {
          key: 'talent',
          label: 'TALENT: Employee Experience Management Program',
          moreOption: 'Learn more',
          parentKey: 'customerExperience',
          subCategory: 'EX',
        },
        {
          key: 'hybrid',
          label: 'HYBRID: Understanding Work Expectations',
          moreOption: 'Learn more',
          parentKey: 'customerExperience',
          subCategory: 'EX',
        },
        {
          key: 'pulse',
          label: 'PULSE: Employee Satisfaction',
          moreOption: 'Learn more',
          parentKey: 'customerExperience',
          subCategory: 'EX',
        },
        {
          key: 'immerse',
          label: 'IMMERSE: Ethnographic Study',
          moreOption: 'Learn more',
          parentKey: 'immersionAndInnovation',
          subCategory: '',
        },
        {
          key: 'invisible',
          label: 'INVISIBLE: Mystery Shopping',
          moreOption: 'Learn more',
          parentKey: 'immersionAndInnovation',
          subCategory: '',
        },
        {
          key: 'emerge',
          label: 'EMERGE: Innovation Labs',
          moreOption: 'Learn more',
          parentKey: 'immersionAndInnovation',
          subCategory: '',
        },
        {
          key: 'live',
          label: 'LIVE: Customer Walk-Throughs',
          moreOption: 'Learn more',
          parentKey: 'immersionAndInnovation',
          subCategory: '',
        },
        {
          key: 'meaninig',
          label: 'MEANING: Semiotics',
          moreOption: 'Learn more',
          parentKey: 'immersionAndInnovation',
          subCategory: '',
        },
        {
          key: 'coopt',
          label: 'CO-OPT: Customer Co-Creation',
          moreOption: 'Learn more',
          parentKey: 'immersionAndInnovation',
          subCategory: '',
        },
        {
          key: 'note',
          label: 'NOTE: Digital Diaries',
          moreOption: 'Learn more',
          parentKey: 'immersionAndInnovation',
          subCategory: '',
        },
        {
          key: 'expert',
          label: 'EXPERT: Cutting Edge Panels',
          moreOption: 'Learn more',
          parentKey: 'immersionAndInnovation',
          subCategory: '',
        },
        {
          key: 'byod',
          label: 'BYOD: Exploratory Data Analytics',
          moreOption: 'Learn more',
          parentKey: 'dataAndAnalytics',
          subCategory: '',
        },
        {
          key: 'foresee',
          label: 'FORESEE: Quantitative Sales Forecasting',
          moreOption: 'Learn more',
          parentKey: 'dataAndAnalytics',
          subCategory: '',
        },
        {
          key: 'chatter',
          label: 'CHATTER: Social Media Listening',
          moreOption: 'Learn more',
          parentKey: 'dataAndAnalytics',
          subCategory: '',
        },
        {
          key: 'avert',
          label: 'AVERT: Churn Modeling',
          moreOption: 'Learn more',
          parentKey: 'dataAndAnalytics',
          subCategory: '',
        },
        {
          key: 'label',
          label: 'LABEL: Segmentation & Classification',
          moreOption: 'Learn more',
          parentKey: 'dataAndAnalytics',
          subCategory: '',
        },
        {
          key: 'preference',
          label: 'PREFERENCE: Maxdiff Analysis',
          moreOption: 'Learn more',
          parentKey: 'dataAndAnalytics',
          subCategory: '',
        },
        {
          key: 'quintessential',
          label: 'QUINTESSENTIAL: Conjoint Analysis',
          moreOption: 'Learn more',
          parentKey: 'dataAndAnalytics',
          subCategory: '',
        },
        {
          key: 'impact-driver-modeling',
          label: 'IMPACT: Driver Modeling',
          moreOption: 'Learn more',
          parentKey: 'dataAndAnalytics',
          subCategory: '',
        },
        {
          key: 'pattern',
          label: 'PATTERN: AI/ML Classification',
          moreOption: 'Learn more',
          parentKey: 'dataAndAnalytics',
          subCategory: '',
        },
        {
          key: 'haystack',
          label: 'HAYSTACK: Fraud Analytics',
          moreOption: 'Learn more',
          parentKey: 'dataAndAnalytics',
          subCategory: '',
        },
        {
          key: 'convert',
          label: 'CONVERT: Customer Acquisition Modeling',
          moreOption: 'Learn more',
          parentKey: 'dataAndAnalytics',
          subCategory: '',
        },

      ],
    },
  },
  {
    id: 'country',
    label: 'Country',
    type: 'SelectCountry',
    group: 'primary',
    // Note: KeywordFilter is fixed filter,
    // you can't change "queryParamNames: ['keywords'],"
    queryParamNames: ['pub_country'],
    // NOTE: If you are ordering search results by distance
    // the keyword search can't be used at the same time.
    // You can turn on/off ordering by distance from config.js file.
    config: {
      // Optional modes: 'has_all', 'has_any'
      // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
      // searchMode: 'has_any',
      options: [
        { label: 'Afghanistan', key: 'afghanistan' },
        { label: 'Åland Islands', key: 'alandislands' },
        { label: 'Albania', key: 'albania' },
        { label: 'Algeria', key: 'algeria' },
        { label: 'American Samoa', key: 'americansamoa' },
        { label: 'Andorra', key: 'andorra' },
        { label: 'Angola', key: 'angola' },
        { label: 'Anguilla', key: 'anguilla' },
        { label: 'Antarctica', key: '"antarctica"' },
        { label: 'Antigua and Barbuda', key: 'antiguaandbarbuda' },
        { label: 'Argentina', key: 'argentina' },
        { label: 'Armenia', key: 'armenia' },
        { label: 'Aruba', key: 'aruba' },
        { label: 'Australia', key: 'australia' },
        { label: 'Austria', key: 'austria' },
        { label: 'Azerbaijan', key: 'azerbaijan' },
        { label: 'Bahamas', key: 'bahamas' },
        { label: 'Bahrain', key: 'bahrain' },
        { label: 'Bangladesh', key: 'bangladesh' },
        { label: 'Barbados', key: 'barbados' },
        { label: 'Belarus', key: 'belarus' },
        { label: 'Belgium', key: 'belgium' },
        { label: 'Belize', key: 'belize' },
        { label: 'Benin', key: 'benin' },
        { label: 'Bermuda', key: 'benin' },
        { label: 'Bhutan', key: 'bhutan' },
        { label: 'Bolivia', key: 'bolivia' },
        { label: 'Bosnia and Herzegovina', key: 'bosniaandherzegovina' },
        { label: 'Botswana', key: 'botswana' },
        { label: 'Bouvet Island', key: 'bouvetisland' },
        { label: 'Brazil', key: 'brazil' },
        { label: 'British Indian Ocean Territory', key: 'britishindianoceanterritory' },
        { label: 'Brunei Darussalam', key: 'bruneidarussalam' },
        { label: 'Bulgaria', key: 'bulgaria' },
        { label: 'Burkina Faso', key: 'burkinafaso' },
        { label: 'Burundi', key: 'burundi' },
        { label: 'Cambodia', key: 'cambodia' },
        { label: 'Cameroon', key: 'cameroon' },
        { label: 'Canada', key: 'canada' },
        { label: 'Cape Verde', key: 'capeverde' },
        { label: 'Cayman Islands', key: 'caymanislands' },
        { label: 'Central African Republic', key: 'centralafricanrepublic' },
        { label: 'Chad', key: 'chad' },
        { label: 'Chile', key: 'chile' },
        { label: 'China', key: 'china' },
        { label: 'Christmas Island', key: 'christmasisland' },
        { label: 'Colombia', key: 'colombia' },
        { label: 'Comoros', key: 'comoros' },
        { label: 'Congo', key: 'congo' },
        { label: 'Congo, The Democratic Republic of the', key: 'democraticrepublicofthecongo' },
        { label: 'Cook Islands', key: 'cookislands' },
        { label: 'Costa Rica', key: 'costarica' },
        { label: 'Croatia', key: 'croatia' },
        { label: 'Cuba', key: 'cuba' },
        { label: 'Cyprus', key: 'cyprus' },
        { label: 'Czech Republic', key: 'czechrepublic' },
        { label: 'Denmark', key: 'denmark' },
        { label: 'Djibouti', key: 'djibouti' },
        { label: 'Dominica', key: 'dominica' },
        { label: 'Dominican Republic', key: 'dominicanrepublic' },
        { label: 'Ecuador', key: 'ecuador' },
        { label: 'Egypt', key: 'egypt' },
        { label: 'El Salvador', key: 'elsalvador' },
        { label: 'Equatorial Guinea', key: 'equatorialguinea' },
        { label: 'Eritrea', key: 'eritrea' },
        { label: 'Estonia', key: 'estonia' },
        { label: 'Ethiopia', key: 'ethiopia' },
        { label: 'Finland', key: 'finland' },
        { label: 'France', key: 'france' },
        { label: 'French Guiana', key: 'frenchguiana' },
        { label: 'French Polynesia', key: 'frenchpolynesia' },
        { label: 'Gabon', key: 'gabon' },
        { label: 'Gambia', key: 'gambia' },
        { label: 'Georgia', key: 'georgia' },
        { label: 'Germany', key: 'germany' },
        { label: 'Ghana', key: 'ghana' },
        { label: 'Gibraltar', key: 'gibraltar' },
        { label: 'Greece', key: 'greece' },
        { label: 'Greenland', key: 'greenland' },
        { label: 'Grenada', key: 'grenada' },
        { label: 'Guatemala', key: 'guatemala' },
        { label: 'Guyana', key: 'guyana' },
        { label: 'Haiti', key: 'aiti' },
        { label: 'Honduras', key: 'honduras' },
        { label: 'Hong Kong', key: 'hongkong' },
        { label: 'Hungary', key: 'hungary' },
        { label: 'Iceland', key: 'iceland' },
        { label: 'India', key: 'india' },
        { label: 'Indonesia', key: 'indonesia' },
        { label: 'Iran', key: 'iran' },
        { label: 'Iraq', key: 'iraq' },
        { label: 'Ireland', key: 'ireland' },
        { label: 'Israel', key: 'israel' },
        { label: 'Italy', key: 'italy' },
        { label: 'Jamaica', key: 'jamaica' },
        { label: 'Japan', key: 'japan' },
        { label: 'Jersey', key: 'jersey' },
        { label: 'Jordan', key: 'jordan' },
        { label: 'Kazakhstan', key: 'kazakhstan' },
        { label: 'Kenya', key: 'kenya' },
        { label: 'Kiribati', key: 'kiribati' },
        { label: 'Korea, Republic of', key: 'southkorea' },
        { label: 'Kuwait', key: 'kuwait' },
        { label: 'Kyrgyzstan', key: 'kyrgyzstan' },
        { label: 'Latvia', key: 'latvia' },
        { label: 'Lebanon', key: 'lebanon' },
        { label: 'Liberia', key: 'liberia' },
        { label: 'Macao', key: 'macao' },
        { label: 'Malaysia', key: 'malaysia' },
        { label: 'Maldives', key: 'maldives' },
        { label: 'Mali', key: 'mali' },
        { label: 'Mauritius', key: 'mauritius' },
        { label: 'Mexico', key: 'mexico' },
        { label: 'Nepal', key: 'nepal' },
        { label: 'Netherlands', key: 'netherlands' },
        { label: 'New Zealand', key: 'newzealand' },
        { label: 'Nigeria', key: 'nigeria' },
        { label: 'Norway', key: 'norway' },
        { label: 'Oman', key: 'oman' },
        { label: 'Pakistan', key: 'pakistan' },
        { label: 'Palau', key: 'palau' },
        { label: 'Peru', key: 'peru' },
        { label: 'Philippines', key: 'philippines' },
        { label: 'Poland', key: 'poland' },
        { label: 'Portugal', key: 'portugal' },
        { label: 'Qatar', key: 'qatar' },
        { label: 'Romania', key: 'romania' },
        { label: 'Russian Federation', key: 'russia' },
        { label: 'Saudi Arabia', key: 'saudiarabia' },
        { label: 'Senegal', key: 'SN' },
        { label: 'Serbia', key: 'CS' },
        { label: 'Singapore', key: 'singapore' },
        { label: 'Somalia', key: 'somalia' },
        { label: 'South Africa', key: 'southafrica' },
        { label: 'South Georgia and the South Sandwich Islands', key: 'GS' },
        { label: 'Spain', key: 'spain' },
        { label: 'Sri Lanka', key: 'srilanka' },
        { label: 'Swaziland', key: 'swaziland' },
        { label: 'Sweden', key: 'sweden' },
        { label: 'Switzerland', key: 'switzerlandCH' },
        { label: 'Syrian Arab Republic', key: 'syria' },
        { label: 'Taiwan', key: 'taiwan' },
        { label: 'Tajikistan', key: 'tajikistan' },
        { label: 'Thailand', key: 'thailand' },
        { label: 'Tuvalu', key: 'tuvalu' },
        { label: 'Uganda', key: 'uganda' },
        { label: 'Ukraine', key: 'ukraine' },
        { label: 'United Arab Emirates', key: 'unitedarabemirates' },
        { label: 'United Kingdom', key: 'unitedkingdom' },
        { label: 'United States', key: 'unitedstates' },
        { label: 'Uruguay', key: 'UY' },
        { label: 'Uzbekistan', key: 'uzbekistan' },
        { label: 'Vanuatu', key: 'vanuatu' },
        { label: 'Venezuela', key: 'venezuela' },
        { label: 'Vietnam', key: 'vietnam' },
        { label: 'Yemen', key: 'yemen' },
        { label: 'Zambia', key: 'zambia' },
        { label: 'Zimbabwe', key: 'zimbabwe' },
      ],
    },
  },
  {
    id: 'keyword',
    label: 'Keyword',
    type: 'KeywordFilter',
    group: 'primary',
    // Note: KeywordFilter is fixed filter,
    // you can't change "queryParamNames: ['keywords'],"
    queryParamNames: ['keywords'],
    // NOTE: If you are ordering search results by distance
    // the keyword search can't be used at the same time.
    // You can turn on/off ordering by distance from config.js file.
    config: {},
  },
];

export const sortConfig = {
  // Enable/disable the sorting control in the SearchPage
  active: true,

  // Note: queryParamName 'sort' is fixed,
  // you can't change it since Flex API expects it to be named as 'sort'
  queryParamName: 'sort',

  // Internal key for the relevance option, see notes below.
  relevanceKey: 'relevance',

  // Keyword filter is sorting the results already by relevance.
  // If keyword filter is active, we need to disable sorting.
  conflictingFilters: ['keyword'],

  options: [
    { key: 'createdAt', label: 'Newest' },
    { key: '-createdAt', label: 'Oldest' },
    // { key: '-pub_minPrice', label: 'Lowest price' },
    // { key: 'pub_maxPrice', label: 'Highest price' },

    // The relevance is only used for keyword search, but the
    // parameter isn't sent to the Marketplace API. The key is purely
    // for handling the internal state of the sorting dropdown.
    { key: 'relevance', label: 'Relevance', longLabel: 'Relevance (Keyword search)' },
  ],
};


export const findOptionsForSelectFilter = (filterId, filters) => {
  const filter = filters.find(f => f.id === filterId);
  return filter && filter.config && filter.config.options ? filter.config.options : [];
};

export const expertType = [
  { key: 'Individual', label: 'Individual' },
  { key: 'Company', label: 'Company' },
]

export const typeOfEmployment = [
  { key: 'Full-Time', label: 'Full-Time' },
  { key: 'Part-time', label: 'Part-time' },
  { key: 'Independent Consultant', label: 'Independent Consultant' },
];

export const paymentTerms = [
  { key: '0', label: 'Payment due upon project completion' },
  { key: '0.25', label: '25% payment due immediately' },
  { key: '0.5', label: '50% payment due immediately' },
  { key: '0.6', label: '60% payment due immediately' },
  { key: '0.75', label: '75% payment due immediately' },
  { key: '1', label: '100% payment due immediately' }
];

export const listingPackages = [
  { key: 'marketResearch', label: 'Market Research' },
  { key: 'customerExperience', label: 'Customer Experience' },
  { key: 'immersionAndInnovation', label: 'Immersion And Innovation' },
  { key: 'dataAndAnalytics', label: 'Data And Analytics' }
];

export const packagesOptions = [
  {
    key: 'scout',
    label: 'SCOUT: Exploratory Study',
    moreOption: 'Learn more',
    description: `Do you require a quick exploratory study before taking an important business decision?
    For instance, finding the size of a market or studying the products on offer from
    competition or simply checking the value of imports of a particular commodity into the
    country? Our Insight Partners are equipped to assist you with such requirements offering a
    turnaround time in days rather than weeks. If you are lucky, they may even provide you
    preliminary guidance in hours!`,
    parentKey: 'marketResearch',
    subCategory: 'B2B'
  },
  {
    key: 'augur',
    label: 'AUGUR: B2B Concept Study',
    moreOption: 'Learn more',
    description: `Planning to launch a new product but the uncertainty is giving you sleepless nights? Or are
    you launching an internal process improvement initiative for your vendors and don’t know
    how they will react to it? Our Insight Partners can help you get quick validation for your
    new concept from your target customers or user group, enabling you to: -
    - Increase your success rate in the market.
    - Understand product/concept strengths for building your marketing pitch.
    - Identify product/concept weaknesses in advance and fix them before launch.
    - Save time and money before you invest in product development.`,
    parentKey: 'marketResearch',
    subCategory: 'B2B'
  },
  {
    key: 'acumen',
    label: 'Acumen: Market Size Estimator',
    moreOption: 'Learn more',
    description: `Entering a new market? Or launching a new product in an existing one? It can be full of
    unknowns! Robust market sizing that helps you make decisions backed by data. Grasp the
    potential of the market and its segments. Our vertical + methodology experts can guide
    your entry strategy into any industry or segment.`,
    parentKey: 'marketResearch',
    subCategory: 'B2B'
  },
  {
    key: 'sift',
    label: 'SIFT: Partner Identification',
    moreOption: 'Learn more',
    description: `Finding the right partner is often critical to your success. Whether you need to find the right
    vendor, manufacturing partner, logistics partner, a distributor for a specific region, or a
    strategic partner for representing your company in a country, our Insight Partners can
    assist you by following an objective criteria-driven framework!`,
    parentKey: 'marketResearch',
    subCategory: 'B2B'
  },
  {
    key: 'crisp',
    label: 'CRISP: Competitor Analysis',
    moreOption: 'Learn more',
    description: `Whether you are just entering a new market or an established player in your industry over
    many years, a deep understanding of your competitors is a critical input to your strategic
    decision-making process. CRISP can help you in thoroughly understanding your
    competition landscape with the use of popular analytical frameworks. Not just that, our
    Insight Partners can also fulfil your tactical data requirements from time to time, such as
    competitor revenues, profitability, market share, pricing, and channel strength.`,
    parentKey: 'marketResearch',
    subCategory: 'B2B'
  },
  {
    key: 'tag',
    label: 'TAG: B2B Customer Database',
    moreOption: 'Learn more',
    description: `Do you need to find potential customers for your B2B product or service? Whether you are
    just entering a new market or expanding to other segments, our Insight Partners can assist
    you in making a database of potential customers, including vetting them on preset criteria.
    You no longer need to hire dedicated resources for identifying and vetting potential
    customers.`,
    parentKey: 'marketResearch',
    subCategory: 'B2B'
  },
  {
    key: 'stature',
    label: 'STATURE: Channel Strength',
    moreOption: 'Learn more',
    description: `Whether you are a B2C or B2B product or service, your strength in the channel is a key
    factor for success. If you would like to get better visibility on your channel performance, our
    Insight Partners would be able to assist you. It could be something as simple as a mystery
    audit to check if your Point of Sale (PoS) material is displayed at stores. It can also be a
    complex exercise that evaluates all aspects of your relationship with channel partners.
    Another common use of such studies is to check competitive scenarios in the channel, and
    pricing`,
    parentKey: 'marketResearch',
    subCategory: 'B2B'
  },
  {
    key: 'ken',
    label: 'KEN: B2B Brand Dipstick',
    moreOption: 'Learn more',
    description: `Do you grapple with the lack of actionable marketing data about your niche B2B product or
    service? Would knowing the position of your brand and your competitors in the market
    help you target your customers better, increasing sales and reducing costs at the same
    time? Our Insight Partners are experienced in collecting and analyzing data for many niche
    products and services, where the lack of reliable data hampers effective marketing.
    Contact us today to know more!`,
    parentKey: 'marketResearch',
    subCategory: 'B2B'
  },
  {
    key: 'star',
    label: 'STAR: Event Feedback Study',
    moreOption: 'Learn more',
    description: `One of the critical touchpoints for your B2B product or service are events, offline or online.
    Whether you are participating in an international marquee event or have organized an
    intimate fireside chat for your select customers, our Insight Partners can help you collect
    and analyze feedback on its impact on customers and other stakeholders. Use the
    assessment to make a bigger impact in real time at the event or for future events.`,
    parentKey: 'marketResearch',
    subCategory: 'B2B'
  },
  {
    key: 'pin-point',
    label: 'PINPOINT: B2B Diagnostic Study',
    moreOption: 'Learn more',
    description: `Have the sales or market share of your B2B product or service dropped recently? Are you
    afraid that customers may not openly tell you the reasons directly? Would you like to do an
    independent third-party study to understand the reasons? Our Insight Partners can help
    you diagnose the reasons for adverse market events or phenomenon, enabling you to take
    corrective actions quickly.`,
    parentKey: 'marketResearch',
    subCategory: 'B2B'
  },
  {
    key: 'panorama',
    label: 'PANORAMA: Corporate Image Study',
    moreOption: 'Learn more',
    description: `Do you wonder how multiple stakeholders perceive your company? What do your vendors,
    employees, customers, channel partners, investors, general public, and other stakeholders
    think about your company? Is that image consistent across all stakeholders? Is that image
    consistent with your core values? Perhaps you are embarking on going public or about to
    undertake a corporate rebranding exercise, and would like to understand your corporate
    image before taking the next step. Our Insight Partners can help you understand your
    corporate image using popular frameworks. Using this study, you can develop a plan to
    further improve your company’s image in the minds of all stakeholders.`,
    parentKey: 'marketResearch',
    subCategory: 'B2B'
  },
  {
    key: 'intellect',
    label: 'INTELLECT: M&A Research Advisory ',
    moreOption: 'Learn more',
    description: `Looking to shortlist a list of companies for making an approach for mergers or acquisitions
    (making or getting acquired)? Would you like to study their business before making the
    approach? Wouldn't it be great if you could even get appointments from interested
    companies that you would like to meet for further discussion? Our Insight Partners can
    assist you with all the above! Follow an objective criteria-driven framework to identify the
    right companies for mergers or acquisitions.`,
    parentKey: 'marketResearch',
    subCategory: 'B2B'
  },
  {
    key: 'chart',
    label: 'CHART: Competition Mapping',
    moreOption: 'Learn more',
    description: `Mapping your competitive position is more than a communication aid. It’s a powerful tool to
    map your competitive strategy and chart your brand growth. Our Experts can help you use
    validated frameworks to establish where you lie in the competitive landscape, how the
    landscape is changing as well as identify those threats that you may not have foreseen`,
    parentKey: 'marketResearch',
    subCategory: 'B2B'
  },
  {
    key: 'focus',
    label: 'FOCUS: Market Segmentation Exercise',
    moreOption: 'Learn more',
    description: `Market Segmentation can help a brand define and better understand its ideal customers
    and target audiences. This can help a brand or a company develop better solutions suited
    to the specific audience in terms of product innovation, service improvements, relevant
    messaging and focused communication channels. As a matter of fact, almost every part of
    the business can benefit from getting a rich understanding of its customers segments.`,
    parentKey: 'marketResearch',
    subCategory: 'B2C',
  },
  {
    key: 'solve',
    label: 'SOLVE: Brand Diagnostic Study',
    moreOption: 'Learn more',
    description: `Would you like to understand the reason behind specific brand trends? Do you want to
    answer questions like “how can i take my sales to the next level of growth”; “what is the
    threat from a potential new entrant into the market?”; “Why are some customers leaving
    my brand”; “How do i increase customer adoption?” These and similar questions can be
    answered by deep diving into understanding the customer opinion behind your brand
    trends. Our Insight Partners can guide you with the right questions that need to be
    answered to gain incisive answers to these questions and plan your brand’s strategy.`,
    parentKey: 'marketResearch',
    subCategory: 'B2C',
  },
  {
    key: 'impact',
    label: 'IMPACT: Campaign Impact Assessment',
    moreOption: 'Learn more',
    description: `Have you just launched or are planning to launch a brand campaign in the near future. It’s
    always good to know the impact of your campaign on your consumers to not only map
    return on your campaign investment but also gain valuable insights to plan the trajectory of
    your current campaign as well as future campaigns. Our Insight Partners can help you run
    a focused study to get you a clear assessment of your campaigns impact on its intended
    audience. Campaign Impact assessments can be done not only for large scale Television
    campaigns but for all media be it print, radio, online or even an omni channel campaign
    across media touch points.`,
    parentKey: 'marketResearch',
    subCategory: 'B2C',
  },
  {
    key: 'view',
    label: 'VIEW: Concept Testing',
    moreOption: 'Learn more',
    description: `Do you have a new Product idea that you would like to launch for your customers? Make
    sure you test the idea in its concept stage before you invest all your resources into
    development and launch of the actual product or service? Our Insight Partners can help
    you both develop and test the concept among its target audience in an efficient and cost
    effective manner.`,
    parentKey: 'marketResearch',
    subCategory: 'B2C',
  },
  {
    key: 'impression',
    label: 'IMPRESSION: Packaging & Name Testing',
    moreOption: 'Learn more',
    description: `Use consumer feedback to find the perfect name and packaging for your product. Do you
    have multiple naming or packaging options and don't know which one to go with? Do you
    want to change the packaging of your brand and want customer opinion to guide your
    choice? Our experts can help you harness the power of research to make the right choice.`,
    parentKey: 'marketResearch',
    subCategory: 'B2C',
  },
  {
    key: 'dialogue',
    label: 'DIALOGUE: Communication Testing',
    moreOption: 'Learn more',
    description: `Do you want to validate your advertising concepts or your creative executions? Pre-testing
    your communication with a sample of your intended audience can help you create
    impactful messages that work for your target consumer.`,
    parentKey: 'marketResearch',
    subCategory: 'B2C',
  },
  {
    key: 'apply',
    label: 'APPLY: User Testing',
    moreOption: 'Learn more',
    description: `Do you want to walk a mile in your consumer’s shoes? Understand how they truly
    experience your product or service and learn from it. Do you want to keep your
    development process agile and iterative? Our experts can help you with timely User
    testing exercises to ensure you continue to stay customer centric with ease.`,
    parentKey: 'marketResearch',
    subCategory: 'B2C',
  },
  {
    key: 'involve',
    label: 'INVOLVE: Product Testing',
    moreOption: 'Learn more',
    description: `Do you want to get a real world understanding of how your product performs among
    consumers? Will your new improvement or modification increase customer satisfaction? Is
    your cost efficient product option competitive enough to be launched in-market? These
    and various other questions around your product can be answered by our experts using
    specialist product testing techniques to help you make the right decisions.`,
    parentKey: 'marketResearch',
    subCategory: 'B2C',
  },
  {
    key: 'iterate',
    label: 'ITERATE: Rapid Experimentation',
    moreOption: 'Learn more',
    description: `Do you want to take the guesswork out of knowing which web page or app screen option
    to launch? Do you want to be data driven in your decision making and use timely yet
    robust metrics to make decisions regarding your product? Do you want to make sure your
    website or app is at its optimum at all times? Rapid Experimentation is the route to help
    you get the most out of your initiatives. Our experts can help you with a testing protocol
    that's relevant to your needs and targeted to your objectives.`,
    parentKey: 'marketResearch',
    subCategory: 'B2C',
  },
  {
    key: 'adopt',
    label: 'ADOPT: Brand Equity Assessment',
    moreOption: 'Learn more',
    description: `A brand’s value chain begins when the organization invests in creating knowledge about
    the brand among its intended customers. Therefore, almost every investment by the brand
    be it marketing or product development or operations contributes towards defining and
    improving this value. It is therefore extremely important for brands to understand what
    exactly is this value that brands hold for the customer. In other words, the strength of
    equity that the brand has in the minds of its audience. If you have a brand, then
    understanding its equity is relevant for you and our experts can guide you on this path.`,
    parentKey: 'marketResearch',
    subCategory: 'B2C',
  },
  {
    key: 'esteem',
    label: 'Esteem: Brand Perception Mapping',
    moreOption: 'Learn more',
    description: `As a brand, you work to own the right space in the consumer's mind. Is your brand
    perception relevant and competitive? Our experts can help you with Brand perception
    studies that generate valuable insights to increase customer affinity for your brand.`,
    parentKey: 'marketResearch',
    subCategory: 'B2C',
  },

  {
    key: 'vibes',
    label: 'VIBES: Usage & Attitude (U&A) Study',
    moreOption: 'Learn more',
    description: `Would you like to understand how your customers use your brand? Are they using your
    product in different ways? How frequently do they buy? How much do they buy in one go?
    What else do they use along with your brand? What attitudes have they formed towards
    your brand over time? What are the differences in usage and attitudes among different
    types of customers? A U&A study aims to deeply understand usage of your products
    among different types of customers and the attitudes they have formed over time.
    Equipped with this knowledge, you can identify potential growth opportunities. For
    instance, can the reason for high usage by a segment of users be valid for other
    segments?`,
    parentKey: 'marketResearch',
    subCategory: 'B2C',
  },
  {
    key: 'monitor',
    label: 'MONITOR: Brand Tracking',
    moreOption: 'Learn more',
    description: `Would you like to monitor the performance of your brand over time? For instance, how has brand awareness increased over time? Are aware customers considering your brand for purchase? What percentage of those who consider your brand, purchase it and why? A brand tracking exercise enables you to feel the pulse of your customers over a long period of time. For instance, did the large marketing campaign you did have an impact on brand awareness, consideration or purchase?`,
    parentKey: 'marketResearch',
    subCategory: 'B2C',
  },
  {
    key: 'slip',
    label: 'SLIP: Lapsing Diagnosis',
    moreOption: 'Learn more',
    description: `Whether you are a startup that has just launched a new product or an established company with decades of experience, there could be situations where your customers move to other brands. The reasons for such a shift could be many; from a new product launch by a competitor to changes in consumer behaviour. It is critical to identify the cause quickly and correct the situation. Listening to your lapsed customers is the best way to understand the root cause. Our Insight Partners can help you diagnose the reasons for customers lapsing, enabling you to take corrective actions quickly.`,
    parentKey: 'marketResearch',
    subCategory: 'B2C',
  },
  {
    key: 'value',
    label: 'VALUE: Pricing Research',
    moreOption: 'Learn more',
    description: `Are you introducing a new product whose pricing needs to be fixed? Do you need to rethink the pricing of your products available in the market? Or do you have a portfolio of products and need to understand how your customers value different features and benefits between variants and fix prices accordingly? Whatever be your business objective with regard to pricing, our Insight Partners are equipped to suggest the appropriate research that can answer these complex questions.`,
    parentKey: 'marketResearch',
    subCategory: 'B2C',
  },

  {
    key: 'touchpoints',
    label: 'TOUCHPOINTS: Customer Journey Mapping',
    moreOption: 'Learn more',
    description: `Do you want to take the first step in creating a great experience for your customers? Do
    you want to understand the touch-points that direct your customer’s experience with the
    brand Our Insight Partners can help you create a detailed visual map of all the interactions
    using validated approaches that ensures a seamless and effective process.`,
    parentKey: 'customerExperience',
    subCategory: 'CX AND EX',
  },
  {
    key: 'proactive',
    label: 'PROACTIVE: Customer Experience Management Program',
    moreOption: 'Learn more',
    description: `Do you want to drive customer centricity across your organization? Do you want more than
    just Reactive Support? Do you want to create a structured approach to managing your
    customer’s experience across the organization? If the answer is yes, you can bring our
    Insight Experts on board to add their wealth of experience in designing your Experience
    Management Program suited specifically to your organization.`,
    parentKey: 'customerExperience',
    subCategory: 'CX',
  },
  {
    key: 'happy',
    label: 'HAPPY: Customer Satisfaction Survey',
    moreOption: 'Learn more',
    description: `Happy customers increase loyalty. What are your customers’ happiness and pain points?
    Decode the nuances of your customer’s brand experience, enabled by customer
    satisfaction surveys from our experts.`,
    parentKey: 'customerExperience',
    subCategory: 'CX',
  },
  {
    key: 'regain',
    label: 'REGAIN: Lapsing Diagnosis',
    moreOption: 'Learn more',
    description: `Has your brand lost a few customers? Knowing root causes can stem the leak and even
    bring customers back. Our experts can do a contextual diagnosis of lapsed customers with
    actionable insights to reverse the trend and grow adoption.`,
    parentKey: 'customerExperience',
    subCategory: 'CX',
  },
  {
    key: 'talent',
    label: 'TALENT: Employee Experience Management Program',
    moreOption: 'Learn more',
    description: `Employee experience has been growing in importance as a critical business agenda this
    past decade but the extraordinary events of 2020 have vaulted it to the forefront for most
    businesses. With remote work, transitioning to hybrid work and changing employee
    worldview on what work means to them has made it imperative for companies to ensure
    that they have a constant pulse on employee experience to ensure they attract, develop
    and retain talent. If you are one of those organizations, you will definitely want to speak to
    our experts to set up a sustainable program to understand and act on employee
    engagement.`,
    parentKey: 'customerExperience',
    subCategory: 'EX',
  },
  {
    key: 'hybrid',
    label: 'HYBRID: Understanding Work Expectations',
    moreOption: 'Learn more',
    description: `Are your employees returning to work after a hiatus? Are you considering a hybrid
    workforce model? Gain deep insight into employee sentiment and an early view of the
    strengths and weaknesses of new models, to enable course correction with the help of our
    EX Experts.`,
    parentKey: 'customerExperience',
    subCategory: 'EX',
  },
  {
    key: 'pulse',
    label: 'PULSE: Employee Satisfaction',
    moreOption: 'Learn more',
    description: `With work paradigms shifting, do you know how your employees are coping with change?
    How can you ensure they stay productive and happy? Quick pulse surveys can provide a
    clear picture of employee sentiment can help you set sentiment baselines without deep
    investments on time and cost.`,
    parentKey: 'customerExperience',
    subCategory: 'EX',
  },
  {
    key: 'immerse',
    label: 'IMMERSE: Ethnographic Study',
    moreOption: 'Learn more',
    description: `Ethnographic research is a qualitative research method that tries to understand how
    people behave and interact in their own, natural environment. It primarily focuses on
    studying people in their natural context, through observations and qualitative interviews
    and does not focus on hard data and numbers. Such studies can be used to draw broad
    conclusions on how customers take decisions, the role of a product or service in their lives,
    and the reasons behind them. Typically, researchers visit consumers in their homes or
    offices to observe and listen in an unintrusive manner. Our goal is to see people’s behavior
    on their terms, not ours`,
    parentKey: 'immersionAndInnovation',
    subCategory: '',
  },
  {
    key: 'invisible',
    label: 'INVISIBLE: Mystery Shopping',
    moreOption: 'Learn more',
    description: `Are your partners following brand tenets and processes? Is your customer getting the
    experience you have carefully designed? Setup Mystery audits to assess implementation,
    spot training cues, and provide ideas to make the process intuitive.`,
    parentKey: 'immersionAndInnovation',
    subCategory: '',
  },
  {
    key: 'emerge',
    label: 'EMERGE: Innovation Labs',
    moreOption: 'Learn more',
    description: `Are you a B2B software service provider wondering what your customers’ emerging needs
    might be? We have experts who can Manage an active, participative customer panel to
    understand latent needs, spot opportunities, test new features, and ensure market fitness
    always.`,
    parentKey: 'immersionAndInnovation',
    subCategory: '',
  },
  {
    key: 'live',
    label: 'LIVE: Customer Walk-Throughs',
    moreOption: 'Learn more',
    description: `Designing a new website or online service? Need to see how your customer experiences it
    so you can modify the design? Collaborate with our experts to design a walk-through to
    observe customers, and act on their positive points and sharp edges, backed by our
    Innovation Experience experts.`,
    parentKey: 'immersionAndInnovation',
    subCategory: '',
  },
  {
    key: 'meaninig',
    label: 'MEANING: Semiotics',
    moreOption: 'Learn more',
    description: `What deeper psychological or social associations have your customers developed with
    your brand? Do you want to align more strongly with their worldviews? Our experts
    specialising in semiotics-based approaches can offer perspectives on the role your brand
    plays in the customer’s socio-cultural context.`,
    parentKey: 'immersionAndInnovation',
    subCategory: '',
  },
  {
    key: 'coopt',
    label: 'CO-OPT: Customer Co-Creation',
    moreOption: 'Learn more',
    description: `Are you planning a new initiative and wish to make customers the front and center of the
    development exercise? Our Innovation Experts can co-opt the customer into the design,
    using frameworks that increase chances of in-market success.`,
    parentKey: 'immersionAndInnovation',
    subCategory: '',
  },
  {
    key: 'note',
    label: 'NOTE: Digital Diaries',
    moreOption: 'Learn more',
    description: `Diary studies are an uncommon, but useful tool. They collect qualitative information with
    participants entering their thoughts, feelings and observations about the product or service
    being studied in a diary over time. With the advent of technology, the physical diary has
    now transformed typically into a digital one. The purpose has not changed though. Such
    studies aim to understand the changes in thoughts on usage, attitudes of the product or
    service under study over a long period of time. Our experts can help you design such
    studies with ease!`,
    parentKey: 'immersionAndInnovation',
    subCategory: '',
  },
  {
    key: 'expert',
    label: 'EXPERT: Cutting Edge Panels',
    moreOption: 'Learn more',
    description: `Often, innovation is sparked by the interaction of a diverse group of experts, who bring
    their own unique perspectives. They are best suited for insights on broad industry trends in
    society, customer attitudes, and likely changes in the future. The panels need to be
    moderated by an experienced moderater who can bring out the deep thoughts of the
    experts, with intelligent probing. Our experts can help you in setting up such panels and
    moderating them!`,
    parentKey: 'immersionAndInnovation',
    subCategory: '',
  },
  {
    key: 'byod',
    label: 'BYOD: Exploratory Data Analytics',
    moreOption: 'Learn more',
    description: `Do you have internal data that could be insightful but don't know how to go about
    analyzing it? Or perhaps your internal resources are fully booked and don’t have the time
    to take the project up? Our Insight Partners would be happy to assist you!
    It could be an Excel sheet of historical sales data, data from your annual customer or
    employee feedback surveys, data from your channel, or any other business data. Our
    Insight Partners would be able to do an exploratory analysis of the data; including critically
    investigating the data for patterns or outliers, study data distributions, provide basic
    statistics, check for hypotheses, and thereby suggest additional analytics or modelling that
    could be done with the data.`,
    parentKey: 'dataAndAnalytics',
    subCategory: '',
  },
  {
    key: 'foresee',
    label: 'FORESEE: Quantitative Sales Forecasting',
    moreOption: 'Learn more',
    description: `Do you find it difficult to plan adequate resources due to incorrect sales forecasting? Are
    you still relying on qualitative, judgment based forecasts from individuals? Do you have
    good quality historic data that can be used for forecasting purposes? Our Insight Partners
    can help you forecast sales or any other variable of your interest by using appropriate data
    analytics techniques. From simple time-series based forecasting to complex models, our
    Insight Partners can use the full gamut of tools to increase the accuracy of your forecasts,
    leading to better planning.`,
    parentKey: 'dataAndAnalytics',
    subCategory: '',
  },
  {
    key: 'chatter',
    label: 'CHATTER: Social Media Listening',
    moreOption: 'Learn more',
    description: `Are you a B2C brand that wants to listen to social media buzz systematically across
    multiple platforms, analyse the data, check for patterns, and report them as a one-time
    exercise? Or do you already use social listening tools like HootSuite, Mention, or Sprout
    Social on a monthly basis but want to do deeper custom analysis on the data? Our Insight
    Partners are well equipped to assist you in doing this and more!`,
    parentKey: 'dataAndAnalytics',
    subCategory: '',
  },
  {
    key: 'avert',
    label: 'AVERT: Churn Modeling',
    moreOption: 'Learn more',
    description: `Would you like to deeply understand why customers are leaving you for competition? Is it
    a product feature, customer service, prices, or something else? Stop the rot before it
    becomes an issue of concern across the organization. Using your internal data, our Insight
    Partners can assist you with customer churn modeling that answers the above questions.`,
    parentKey: 'dataAndAnalytics',
    subCategory: '',
  },
  {
    key: 'label',
    label: 'LABEL: Segmentation & Classification',
    moreOption: 'Learn more',
    description: `An understanding of customer segments is a critical input for many business decisions
    such as identifying product-market fit and aligning the organization to market. You may
    have already identified segments in the market through your experience. However, a data
    analytics driven segmentation can confirm your hypothesis or disprove them. Analysing
    data comprehensively can also throw up hitherto unknown segments that have unique
    characteristics you had not originally considered. Once you have identified segments,
    follow it up with a classification exercise to get actionable results at scale on your entire
    database of customers! Our Insight Partners can assist you with customer segmentation
    and classification using modern data analytics techniques.`,
    parentKey: 'dataAndAnalytics',
    subCategory: '',
  },
  {
    key: 'preference',
    label: 'PREFERENCE: Maxdiff Analysis',
    moreOption: 'Learn more',
    description: `Understanding how customers make choices in the real world is critical to your success.
    Maxdiff Analysis is a popular technique that helps you do just that. Using this technique,
    you can understand the relative importance of factors in the customer’s mind. Our Insight
    Partners can help you design and implement Maxdiff Analysis studies that drive your
    business decisions.`,
    parentKey: 'dataAndAnalytics',
    subCategory: '',
  },
  {
    key: 'quintessential',
    label: 'QUINTESSENTIAL: Conjoint Analysis',
    moreOption: 'Learn more',
    description: `Understanding how customers make choices in the real world is critical to your success.
    Conjoint Analysis is a popular technique that helps you understand the relative value that
    consumers place on features of a product or service. The technique mirrors the real-world;
    customers need to make trade-offs between features and choose the one that suits them
    the most. Our Insight Partners can help you design and implement Conjoint Analysis
    studies that drive your business decisions.`,
    parentKey: 'dataAndAnalytics',
    subCategory: '',
  },
  {
    key: 'impact-driver-modeling',
    label: 'IMPACT: Driver Modeling',
    moreOption: 'Learn more',
    description: `Outcomes such as sales, profitability, customer satisfaction, and NPS are driven by a
    complex set of factors. It can often be difficult to understand what really drives them and
    the relative importance of each of the causal factors. Our Insight Partners can assist you in
    developing a complete understanding of the drivers of your key business outcomes. Such
    an understanding helps you in focusing your resources on the important factors that move
    the needle significantly.`,
    parentKey: 'dataAndAnalytics',
    subCategory: '',
  },
  {
    key: 'pattern',
    label: 'PATTERN: AI/ML Classification',
    moreOption: 'Learn more',
    description: `Do you have large data sets of qualitative responses from customers which you know are
    highly valuable but you never end up using them due to the sheer volume? Would you like
    to automate classification of such customer feedback? For example, it could be the
    qualitative responses to questions on a survey immediately post an interaction with your
    Contact Center or Chatbot? One application could be to identify the sentiment of a
    customer giving feedback or classify their responses into different topics for easier
    actionability. Our data scientists would be able to help you process large amounts of data
    with the help of AI/ML techniques.`,
    parentKey: 'dataAndAnalytics',
    subCategory: '',
  },
  {
    key: 'haystack',
    label: 'HAYSTACK: Fraud Analytics',
    moreOption: 'Learn more',
    description: `Would you like to identify potentially fraudulent transactions or data using analytics? Does
    your company have rules for a set of data to adhere to, but find it painful to manually
    invetigate every single case? Our data analysts could potentially help you automate the
    process!`,
    parentKey: 'dataAndAnalytics',
    subCategory: '',
  },
  {
    key: 'convert',
    label: 'CONVERT: Customer Acquisition Modeling',
    moreOption: 'Learn more',
    description: `Would you like to deeply understand why customers choose you over competition? Is it a
    certain feature, your brand, price, or something else? Which factors ultimately determine
    your success? This understanding can help in communicating your strengths to potential
    customers, and improving your weaknesses. Using your internal data, our Insight Partners
    can assist you with customer acquisition modeling that answers the above questions.`,
    parentKey: 'dataAndAnalytics',
    subCategory: '',
  },]
export const customFirmSizeRange = [
  { key: '1-10', label: '1-10' },
  { key: '11-50', label: '11-50' },
  { key: '51-200', label: '51-200' },
  { key: '201-500', label: '201-500' },
  { key: '501-1,000', label: ' 501-1,000' },
  { key: '1,001-5,000', label: '1,001-5,000' },
  { key: '5,001 & above', label: '5,001 & above' },
]

export const languagesArray = [
  { key: 'algerian arabic', label: 'Algerian Arabic' },
  { key: 'amharic', label: 'Amharic' },
  { key: 'arabic', label: 'Arabic' },
  { key: 'assamese', label: 'Assamese' },
  { key: 'azerbaijani', label: 'Azerbaijani' },
  { key: 'baluchi', label: 'Baluchi' },
  { key: 'bengali', label: 'Bengali' },
  { key: 'bhojpuri', label: 'Bhojpuri' },
  { key: 'burmese', label: 'Burmese' },
  { key: 'cebuano', label: 'Cebuano' },
  { key: 'chhattisgarhi', label: 'Chhattisgarhi' },
  { key: 'chinese', label: 'Chinese' },
  { key: 'chittagonian', label: 'Chittagonian' },
  { key: 'czech', label: 'Czech' },
  { key: 'deccan', label: 'Deccan' },
  { key: 'dutch', label: 'Dutch' },
  { key: 'egyptian arabic', label: 'Egyptian Arabic' },
  { key: 'english', label: 'English' },
  { key: 'french', label: 'French' },
  { key: 'fulah', label: 'Fulah' },
  { key: 'gan', label: 'Gan' },
  { key: 'german', label: 'German' },
  { key: 'greek', label: 'Greek' },
  { key: 'gujarati', label: 'Gujarati' },
  { key: 'hakka', label: 'Hakka' },
  { key: 'hausa', label: 'Hausa' },
  { key: 'hindi', label: 'Hindi' },
  { key: 'hungarian', label: 'Hungarian' },
  { key: 'igbo', label: 'Igbo' },
  { key: 'indonesian', label: 'Indonesian' },
  { key: 'iranian persian', label: 'Iranian Persian' },
  { key: 'italian', label: 'Italian' },
  { key: 'japanese', label: 'Japanese' },
  { key: 'javanese', label: 'Javanese' },
  { key: 'jinyu', label: 'Jinyu' },
  { key: 'kannada', label: 'Kannada' },
  { key: 'kazakh', label: 'Kazakh' },
  { key: 'khmer', label: 'Khmer' },
  { key: 'kinyarwanda', label: 'Kinyarwanda' },
  { key: 'korean', label: 'Korean' },
  { key: 'kurdish', label: 'Kurdish' },
  { key: 'kurdish', label: 'Kurdish' },
  { key: 'lahnda', label: 'Lahnda' },
  { key: 'magahi', label: 'Magahi' },
  { key: 'maithili', label: 'Maithili' },
  { key: 'malagasy', label: 'Malagasy' },
  { key: 'malay', label: 'Malay' },
  { key: 'malay', label: 'Malay' },
  { key: 'malayalam', label: 'Malayalam' },
  { key: 'mandarin', label: 'Mandarin' },
  { key: 'marathi', label: 'Marathi' },
  { key: 'marwari', label: 'Marwari' },
  { key: 'mesopotamian arabic', label: 'Mesopotamian Arabic' },
  { key: 'min bei chinese', label: 'Min Bei Chinese' },
  { key: 'min dong chinese', label: 'Min Dong Chinese' },
  { key: 'min nan', label: 'Min Nan' },
  { key: 'moroccan arabic', label: 'Moroccan Arabic' },
  { key: 'nepali', label: 'Nepali' },
  { key: 'nepali', label: 'Nepali' },
  { key: 'nigerian fulfulde', label: 'Nigerian Fulfulde' },
  { key: 'north levantine arabic', label: 'North Levantine Arabic' },
  { key: 'northern pashto', label: 'Northern Pashto' },
  { key: 'odia', label: 'Odia' },
  { key: 'oromo', label: 'Oromo' },
  { key: 'persian', label: 'Persian' },
  { key: 'polish', label: 'Polish' },
  { key: 'portuguese', label: 'Portuguese' },
  { key: 'punjabi', label: 'Punjabi' },
  { key: 'pushto', label: 'Pushto' },
  { key: 'romanian', label: 'Romanian' },
  { key: 'rundi', label: 'Rundi' },
  { key: 'russian', label: 'Russian' },
  { key: 'saʽidi arabic', label: 'Saʽidi Arabic' },
  { key: 'saraiki', label: 'Saraiki' },
  { key: 'serbo-croatian', label: 'Serbo-Croatian' },
  { key: 'sindhi', label: 'Sindhi' },
  { key: 'sinhala', label: 'Sinhala' },
  { key: 'somali', label: 'Somali' },
  { key: 'south levantine arabic', label: 'South Levantine Arabic' },
  { key: 'southern pashto', label: 'Southern Pashto' },
  { key: 'spanish', label: 'Spanish' },
  { key: 'sudanese arabic', label: 'Sudanese Arabic' },
  { key: 'sunda', label: 'Sunda' },
  { key: 'sylheti', label: 'Sylheti' },
  { key: 'tagalog', label: 'Tagalog' },
  { key: 'tamil', label: 'Tamil' },
  { key: 'telugu', label: 'Telugu' },
  { key: 'thai', label: 'Thai' },
  { key: 'tunisian arabic', label: 'Tunisian Arabic' },
  { key: 'turkish', label: 'Turkish' },
  { key: 'ukrainian', label: 'Ukrainian' },
  { key: 'urdu', label: 'Urdu' },
  { key: 'uyghur', label: 'Uyghur' },
  { key: 'uzbek', label: 'Uzbek' },
  { key: 'vietnamese', label: 'Vietnamese' },
  { key: 'wu', label: 'Wu' },
  { key: 'xiang chinese', label: 'Xiang Chinese' },
  { key: 'yoruba', label: 'Yoruba' },
  { key: 'yue', label: 'Yue' },
  { key: 'zhuang', label: 'Zhuang' },
  { key: 'zulu', label: 'Zulu' }
];




export const chooseToFitSizes = [
  { value: 'fill', label: 'Fill: Resize image to fill banner dimensions. ' },
  { value: 'center', label: 'Center: Retain original image size and dimensions and leave rest of space blank.' },
];


//InsightGig One Plan Subscription
export const diySubscriptionOptions = [
  'Create a Brief',
  'Create a Contract',
  'Create a Job Description',
  'Create a Proposal',
  'Create a Questionnaire',
  'Create a Discussion Guide',
  'Perfect Question',
  'Suggest Answer Options'
];

export const assistedSubscriptionOptions = [
  'Transcribe an Audio File',
  'Content Analysis (for Qualitative Studies)',
  'Generate Ideas from Content Analysis',
  'Query a Report',
  'Sentiment Analysis (for Quantitative Studies)',
  'Topic Modelling Using NLP (for Quantitative Studies)',
  'Text Pre-processing (for Quantitative Studies)',
  'Keyword Extraction Using TFIDF (for Quantitative Studies)'
];