import React, { useEffect, useMemo, useState } from 'react'
import { FormattedMessage } from 'react-intl';
import css from './OpenAIQuestionnaireForm.module.css'
import { FieldSelect, FieldTextInput, IconCard, PrimaryButton, SecondaryButton } from '../../components';
import { Form } from 'react-final-form';



const EditQuestionnaireModal = (props) => {
  const { data, response, closeModal, handleSave, inputTypes } = props;

  const [addOption, setAddOption] = useState("")
  const [tableData, setTableData] = useState({
    columnHeadings: "",
    rowHeadings: ""
  });

  const questionTypes = [
    { label: "Paragraph", value: "Open-ended" },
    { label: "Checkboxes (select multiple options)", value: "Multiple choice" },
    { label: "Radio boxes (select single option)", value: "Single choice" },
    { label: "Table", value: "Table" }
  ]

  const questionType =(values)=>{
    return values?.sections?.[data.sectionIndex]?.questions?.[data.questionIndex]?.questionType
  } 


  const displayInputs = (values, form) => {
    switch (data.name) {
      case "editTitle":
        return (
          <div className={css.row}>
            <label><FormattedMessage id={`OpenAIQuestionnaireForm.editTitleLabel`} /></label>
            <FieldTextInput
              required={true}
              className={css.inputBox}
              type="text"
              id={'title'}
              name="title"
            // placeholder={researchPlaceholder}
            />
            <label className={css.labelMarginTop}><FormattedMessage id={`OpenAIQuestionnaireForm.editIntroductionLabel`} /></label>
            <FieldTextInput
              required={true}
              className={css.inputBox}
              type="textarea"
              id={'subtitle'}
              name="subtitle"
            // placeholder={researchPlaceholder}
            />
          </div>
        )
      case "editIntroduction":
        return (
          <div className={css.row}>
            <label><FormattedMessage id={`OpenAIQuestionnaireForm.editIntroductionLabel`} /></label>
            <FieldTextInput
              className={css.inputBox}
              type="text"
              id={'subtitle'}
              name="subtitle"
            // placeholder={researchPlaceholder}
            />
          </div>
        )
      case "editSectionHeading":
        return (
          <div className={css.row}>
            <label><FormattedMessage id={`OpenAIQuestionnaireForm.editSectionTitleLabel`} /></label>
            <FieldTextInput
              className={css.inputBox}
              type="text"
              id={'sectionTitle'}
              name={`sections[${data.sectionIndex}].sectionTitle`}
            // placeholder={researchPlaceholder}
            />
          </div>
        )
      case "editQuestion":
        return (
          <div className={css.row}>
            <label><FormattedMessage id={`OpenAIQuestionnaireForm.editQuestionTypeLabel`} /></label>
            <FieldSelect id="questionType" name={`sections[${data.sectionIndex}].questions[${data.questionIndex}].questionType`}>
              {questionTypes.map((item, index) => (
                <option key={index} value={item.value}>{item.label}</option>
              ))}
            </FieldSelect>
            <label className={css.labelMarginTop}><FormattedMessage id={`OpenAIQuestionnaireForm.editQuestionLabel`} /></label>
            <FieldTextInput
              className={css.inputBox}
              type="text"
              id={'question'}
              name={`sections[${data.sectionIndex}].questions[${data.questionIndex}].question`}
            // placeholder={researchPlaceholder}
            />
            {values.sections[data.sectionIndex].questions[data.questionIndex]?.questionType && (values.sections[data.sectionIndex].questions[data.questionIndex]?.questionType !== "Open-ended" && values.sections[data.sectionIndex].questions[data.questionIndex]?.questionType !== "Table") &&
              <>
                <label className={css.labelMarginTop}><FormattedMessage id={`OpenAIQuestionnaireForm.editAnswerLabel`} /></label>
                {values.sections[data.sectionIndex].questions[data.questionIndex].options.map((item, index) => (
                  <div className={css.answersContainer} key={index}>
                    <input className={css.radioInput} type={inputTypes[values.sections[data.sectionIndex].questions[data.questionIndex].questionType]} checked={false} onChange={(e)=>e.preventDefault()}/>
                    <input value={item} onChange={(e)=>{
                      const updatedValues = JSON.parse(JSON.stringify(values));
                      let options = [...values.sections[data.sectionIndex].questions[data.questionIndex].options]
                      options.splice(index,1,e.target.value)
                      updatedValues.sections[data.sectionIndex].questions[data.questionIndex].options = options
                      form.change('sections', updatedValues.sections);
                    }} className={css.introductionText}/>
                    <div
                      className={css.cursorPointer}
                      onClick={() => {
                        const updatedValues = JSON.parse(JSON.stringify(values));
                        const options =[...updatedValues.sections[data.sectionIndex].questions[data.questionIndex].options]
                        options.splice(index,1);
                        updatedValues.sections[data.sectionIndex].questions[data.questionIndex].options = options
                        // const updatedColumnHeadings = [...updatedValues.sections[data.sectionIndex].questions[data.questionIndex].columnHeadings];
                        // updatedColumnHeadings.splice(index, 1);
                        // updatedValues.sections[data.sectionIndex].questions[data.questionIndex].columnHeadings = updatedColumnHeadings;
                        form.change('sections', updatedValues.sections);
                      }}>
                      <IconCard brand="crossSign" />
                    </div>
                  </div>
                ))}
                <div className={css.answersContainer}>
                  <input className={css.radioInput} type={inputTypes[values.sections[data.sectionIndex].questions[data.questionIndex].questionType]} checked={false} onChange={(e)=>e.preventDefault()}/>
                  <input placeholder='Enter another option...' type="text" value={addOption} onChange={(e) => setAddOption(e.target.value)} onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault()
                      values.sections[data.sectionIndex].questions[data.questionIndex].options.push(e.target.value)
                      setAddOption("")
                    }
                  }}
                  />
                </div>
              </>
            }
            {
              values.sections[data.sectionIndex].questions[data.questionIndex]?.questionType && values.sections[data.sectionIndex].questions[data.questionIndex]?.questionType === "Table" &&
              <>
                <div>
                  <label className={css.labelMarginTop}><FormattedMessage id={`OpenAIQuestionnaireForm.columnHeadingsLabel`} /></label>
                  <ul>
                    {values && values?.sections[data.sectionIndex]?.questions[data.questionIndex]?.columnHeadings?.map((item, index) => (
                      <div className={css.answersContainer} key={index}>
                        <input className={css.radioInput} readOnly key={index} type="radio" />
                        <input onChange={(e)=>{
                          const updatedValues = JSON.parse(JSON.stringify(values));
                          const updatedColumnHeadings = [...updatedValues.sections[data.sectionIndex].questions[data.questionIndex].columnHeadings];
                          updatedColumnHeadings.splice(index, 1, e.target.value);
                          updatedValues.sections[data.sectionIndex].questions[data.questionIndex].columnHeadings = updatedColumnHeadings;
                          form.change('sections', updatedValues.sections);
                        }} className={css.introductionText} value={item}/>
                        <div
                          className={css.cursorPointer}
                          onClick={() => {
                            const updatedValues = JSON.parse(JSON.stringify(values));
                            const updatedColumnHeadings = [...updatedValues.sections[data.sectionIndex].questions[data.questionIndex].columnHeadings];
                            updatedColumnHeadings.splice(index, 1);
                            updatedValues.sections[data.sectionIndex].questions[data.questionIndex].columnHeadings = updatedColumnHeadings;
                            form.change('sections', updatedValues.sections);
                          }}>
                          <IconCard brand="crossSign" />
                        </div>
                      </div>
                    ))}
                    <div className={css.answersContainer}>
                      <input className={css.radioInput} readOnly type="radio" />
                      <input
                        placeholder='Enter another option...'
                        type="text"
                        onChange={(e) => {
                          setTableData({
                            ...tableData,
                            columnHeadings: e.target.value
                          })
                        }}
                        value={tableData.columnHeadings}
                        onKeyPress={(e) => {
                          if (e.key === "Enter" && tableData.columnHeadings) {
                            e.preventDefault()
                            values.sections[data.sectionIndex].questions[data.questionIndex].columnHeadings.push(tableData.columnHeadings)
                            setTableData({ ...tableData, columnHeadings: "" })
                          }
                        }}
                      />
                    </div>
                  </ul>
                </div>
                <div>
                  <label><FormattedMessage id={`OpenAIQuestionnaireForm.rowHeadingsLabel`} /></label>
                  <ul>
                    {values && values?.sections[data.sectionIndex]?.questions[data.questionIndex]?.rowHeadings?.map((item, index) => (
                      <div className={css.answersContainer} key={index}>
                        <input className={css.radioInput} readOnly key={index} type="radio" />
                        <input value={item} onChange={(e)=>{
                          const updatedValues = JSON.parse(JSON.stringify(values));
                          const updatedRowHeadings = [...updatedValues.sections[data.sectionIndex].questions[data.questionIndex].rowHeadings];
                          updatedRowHeadings.splice(index, 1,e.target.value);
                          updatedValues.sections[data.sectionIndex].questions[data.questionIndex].rowHeadings = updatedRowHeadings;
                          form.change('sections', updatedValues.sections);
                        }} className={css.introductionText}/>
                        <div
                          className={css.cursorPointer}
                          onClick={() => {
                            const updatedValues = JSON.parse(JSON.stringify(values));
                            const updatedRowHeadings = [...updatedValues.sections[data.sectionIndex].questions[data.questionIndex].rowHeadings];
                            updatedRowHeadings.splice(index, 1);
                            updatedValues.sections[data.sectionIndex].questions[data.questionIndex].rowHeadings = updatedRowHeadings;
                            form.change('sections', updatedValues.sections);
                          }}>
                          <IconCard brand="crossSign" />
                        </div>
                      </div>
                    ))}
                    <div className={css.answersContainer}>
                      <input className={css.radioInput} readOnly type="radio" />
                      <input
                        placeholder='Enter another option...'
                        type="text"
                        value={tableData.rowHeadings}
                        onChange={(e) => {
                          setTableData({
                            ...tableData,
                            rowHeadings: e.target.value
                          })
                        }}
                        onKeyPress={(e) => {
                          if (e.key === "Enter" && tableData.rowHeadings) {
                            e.preventDefault()
                            values.sections[data.sectionIndex].questions[data.questionIndex].rowHeadings.push(tableData.rowHeadings)
                            setTableData({ ...tableData, rowHeadings: "" })
                          }
                        }}
                      />
                    </div>
                  </ul>
                </div>
              </>
            }
          </div>
        )
      case "editEndSection":
        return (
          <div className={css.row}>
            <label><FormattedMessage id={`OpenAIQuestionnaireForm.editEndSectionTitleLabel`} /></label>
            <FieldTextInput
              className={css.inputBox}
              type="text"
              id={'endSectionTitle'}
              name={`endSectionTitle`}
            />
            <label className={css.labelMarginTop}><FormattedMessage id={`OpenAIQuestionnaireForm.editEndSectionsubtitleLabel`} /></label>
            <FieldTextInput
              className={css.inputBox}
              type="text"
              id={'endSectionSubTitle'}
              name={`endSectionSubTitle`}
            />
          </div>
        )
      case "addSection":
        return (
          <div className={css.row}>
            <label><FormattedMessage id={`OpenAIQuestionnaireForm.editSectionTitleLabel`} /></label>
            <FieldTextInput
              className={css.inputBox}
              type="text"
              id={'sectionTitle'}
              name={`sections[${data.sectionIndex}].sectionTitle`}
            // placeholder={researchPlaceholder}
            />
          </div>
        )
      case "addQuestion":
        return (
          <div className={css.row}>
            <label><FormattedMessage id={`OpenAIQuestionnaireForm.editQuestionTypeLabel`} /></label>
            <FieldSelect id="questionType" name={`sections[${data.sectionIndex}].questions[${data.questionIndex}].questionType`}>
              <option value="">Select Question Type</option>
              {questionTypes.map((item, index) => (
                <option key={index} value={item.value}>{item.label}</option>
              ))}
            </FieldSelect>
            <label className={css.labelMarginTop}><FormattedMessage id={`OpenAIQuestionnaireForm.editQuestionLabel`} /></label>
            <FieldTextInput
              className={css.inputBox}
              type="text"
              id={'question'}
              name={`sections[${data.sectionIndex}].questions[${data.questionIndex}].question`}
            // placeholder={researchPlaceholder}
            />
            {values.sections[data.sectionIndex].questions[data.questionIndex]?.questionType && values.sections[data.sectionIndex].questions[data.questionIndex]?.questionType !== "Open-ended" && values.sections[data.sectionIndex].questions[data.questionIndex]?.questionType !== "Table" &&
              <>
                <label><FormattedMessage id={`OpenAIQuestionnaireForm.editAnswerLabel`} /></label>
                {values.sections[data.sectionIndex].questions[data.questionIndex]?.options.map((item, index) => (
                  <div className={css.answersContainer} key={index}>
                    <input className={css.radioInput} type={inputTypes[values.sections[data.sectionIndex].questions[data.questionIndex].questionType]} />
                    <input onChange={(e)=>{
                      const updatedValues = JSON.parse(JSON.stringify(values));
                      let options = [...values.sections[data.sectionIndex].questions[data.questionIndex].options]
                      options.splice(index,1,e.target.value)
                      updatedValues.sections[data.sectionIndex].questions[data.questionIndex].options = options
                      form.change('sections', updatedValues.sections);
                    }} value={item} className={css.introductionText}/>
                  </div>
                ))}
                <div className={css.answersContainer}>
                  <input className={css.radioInput} type={inputTypes[values.sections[data.sectionIndex].questions[data.questionIndex]?.questionType]} />
                  <input placeholder='Enter another option...' type="text" value={addOption} onChange={(e) => setAddOption(e.target.value)} onKeyPress={(e) => {
                    if (e.key === "Enter" && addOption) {
                      e.preventDefault()
                      values.sections[data.sectionIndex].questions[data.questionIndex].options.push(addOption)
                      setAddOption("")
                    }
                  }}
                  />
                </div>
              </>
            }
            {questionType(values) ==="Table" && 
              <>
              <div>
                <label><FormattedMessage id={`OpenAIQuestionnaireForm.columnHeadingsLabel`} /></label>
                <ul>
                  {values && values?.sections[data.sectionIndex]?.questions[data.questionIndex]?.columnHeadings?.map((item, index) => (
                    <div className={css.answersContainer} key={index}>
                      <input className={css.radioInput} readOnly key={index} type="radio" />
                      <input onChange={(e)=>{
                        const updatedValues = JSON.parse(JSON.stringify(values));
                        const updatedColumnHeadings = [...updatedValues.sections[data.sectionIndex].questions[data.questionIndex].columnHeadings];
                        updatedColumnHeadings.splice(index, 1, e.target.value);
                        updatedValues.sections[data.sectionIndex].questions[data.questionIndex].columnHeadings = updatedColumnHeadings;
                        form.change('sections', updatedValues.sections);
                      }} className={css.introductionText} value={item}/>
                      <div
                        className={css.cursorPointer}
                        onClick={() => {
                          const updatedValues = JSON.parse(JSON.stringify(values));
                          const updatedColumnHeadings = [...updatedValues.sections[data.sectionIndex].questions[data.questionIndex].columnHeadings];
                          updatedColumnHeadings.splice(index, 1);
                          updatedValues.sections[data.sectionIndex].questions[data.questionIndex].columnHeadings = updatedColumnHeadings;
                          form.change('sections', updatedValues.sections);
                        }}>
                        <IconCard brand="crossSign" />
                      </div>
                    </div>
                  ))}
                  <div className={css.answersContainer}>
                    <input className={css.radioInput} readOnly type="radio" />
                    <input
                      placeholder='Enter another option...'
                      type="text"
                      onChange={(e) => {
                        setTableData({
                          ...tableData,
                          columnHeadings: e.target.value
                        })
                      }}
                      value={tableData.columnHeadings}
                      onKeyPress={(e) => {
                        if (e.key === "Enter" && tableData.columnHeadings) {
                          e.preventDefault()
                          if(values.sections[data.sectionIndex].questions[data.questionIndex].columnHeadings){
                            values.sections[data.sectionIndex].questions[data.questionIndex].columnHeadings.push(tableData.columnHeadings)
                          }else{
                            values.sections[data.sectionIndex].questions[data.questionIndex].columnHeadings= []
                            values.sections[data.sectionIndex].questions[data.questionIndex].columnHeadings.push(tableData.columnHeadings)
                          }
                          setTableData({ ...tableData, columnHeadings: "" })
                        }
                      }}
                    />
                  </div>
                </ul>
              </div>
              <div>
                <label><FormattedMessage id={`OpenAIQuestionnaireForm.rowHeadingsLabel`} /></label>
                <ul>
                  {values && values?.sections[data.sectionIndex]?.questions[data.questionIndex]?.rowHeadings?.map((item, index) => (
                    <div className={css.answersContainer} key={index}>
                      <input className={css.radioInput} readOnly key={index} type="radio" />
                      <input value={item} onChange={(e)=>{
                        const updatedValues = JSON.parse(JSON.stringify(values));
                        const updatedRowHeadings = [...updatedValues.sections[data.sectionIndex].questions[data.questionIndex].rowHeadings];
                        updatedRowHeadings.splice(index, 1,e.target.value);
                        updatedValues.sections[data.sectionIndex].questions[data.questionIndex].rowHeadings = updatedRowHeadings;
                        form.change('sections', updatedValues.sections);
                      }} className={css.introductionText}/>
                      <div
                        className={css.cursorPointer}
                        onClick={() => {
                          const updatedValues = JSON.parse(JSON.stringify(values));
                          const updatedRowHeadings = [...updatedValues.sections[data.sectionIndex].questions[data.questionIndex].rowHeadings];
                          updatedRowHeadings.splice(index, 1);
                          updatedValues.sections[data.sectionIndex].questions[data.questionIndex].rowHeadings = updatedRowHeadings;
                          form.change('sections', updatedValues.sections);
                        }}>
                        <IconCard brand="crossSign" />
                      </div>
                    </div>
                  ))}
                  <div className={css.answersContainer}>
                    <input className={css.radioInput} readOnly type="radio" />
                    <input
                      placeholder='Enter another option...'
                      type="text"
                      value={tableData.rowHeadings}
                      onChange={(e) => {
                        setTableData({
                          ...tableData,
                          rowHeadings: e.target.value
                        })
                      }}
                      onKeyPress={(e) => {
                        if (e.key === "Enter" && tableData.rowHeadings) {
                          e.preventDefault()
                          if(values.sections[data.sectionIndex].questions[data.questionIndex].rowHeadings){
                            values.sections[data.sectionIndex].questions[data.questionIndex].rowHeadings.push(tableData.rowHeadings)
                          }else{
                            values.sections[data.sectionIndex].questions[data.questionIndex].rowHeadings= []
                            values.sections[data.sectionIndex].questions[data.questionIndex].rowHeadings.push(tableData.rowHeadings)
                          }
                          setTableData({ ...tableData, rowHeadings: "" })
                        }
                      }}
                    />
                  </div>
                </ul>
              </div>
            </>
            }
          </div>
        )
      case "deleteQuestion":
        return(
          <>
          </>
        )
      case "deleteSection":
        return(
          <>
          </>
        )
      default:
        return <></>;
    }
  }

  const initialValues = () => {
    let values = JSON.parse(JSON.stringify(response));
    if (data.show && data.name === "addSection") {
      values.sections.splice(data.sectionIndex, 0, {
        "sectionTitle": "",
        "sectionSubTitle": "",
        "questions": []
      })
    } if (data.show && data.name === "addQuestion") {
      values.sections[data.sectionIndex].questions.splice(data.questionIndex, 0, {
        "question": "",
        "questionType": "",
        "options": []
      })
    }
    return values;
  }

  const validation = (values) => {
    let errors = {}
    if (data.name === "editTitle") {
      if (!values?.title) {
        errors.title = "required"
      }
    } if (data.name === "editIntroducction") {
      if (!values?.subtitle) {
        errors.subtitle = "required"
      }
    } if (data.name === "editSectionHeading" || data.name === "addSection") {
      if (!values?.sections[data.sectionIndex]?.sectionTitle) {
        errors.subtitle = "required"
      }
    } if (data.name === "editQuestion" || data.name === "addQuestion") {
      if (!values.sections[data.sectionIndex]?.questions[data.questionIndex]?.question) {
        errors.question = "required"
      }
      if (!values.sections[data.sectionIndex]?.questions[data.questionIndex]?.questionType) {
        errors.question = "required"
      }
      if (values?.sections[data.sectionIndex]?.questions[data.questionIndex]?.questionType && values?.sections[data.sectionIndex]?.questions[data.questionIndex]?.questionType !== "Open-ended" && values?.sections[data.sectionIndex]?.questions[data.questionIndex]?.questionType !== "Table" && values.sections[data.sectionIndex]?.questions[data.questionIndex]?.options?.length === 0) {
        errors.question = "required"
      }
      if (values?.sections[data.sectionIndex]?.questions[data.questionIndex]?.questionType && values?.sections[data.sectionIndex]?.questions[data.questionIndex]?.questionType !== "Open-ended" && (values.sections[data.sectionIndex]?.questions[data.questionIndex]?.columnHeadings?.length === 0 || values.sections[data.sectionIndex]?.questions[data.questionIndex]?.rowHeadings?.length === 0)) {
        errors.question = "required"
      }
    } if (data.name === "editEndSection") {
      if (!values?.endSectionTitle) {
        errors.endSectionTitle = "required"
      }
    }
    return errors;
  }

  const emptyOptions = (values) => {
    if(questionType(values) === "Open-ended" || questionType(values) === "Multiple choice" || questionType(values) === "Single choice"){
      return values?.sections[data.sectionIndex]?.questions[data.questionIndex]?.options?.some(item=>!item)
    }if(questionType(values) === "Table"){
      let question = values?.sections[data.sectionIndex]?.questions[data.questionIndex]
      return !question?.columnHeadings || !question?.rowHeadings || (question?.columnHeadings?.some(item=>!item)) || (question?.rowHeadings?.some(item=>!item) || (question?.columnHeadings?.length===0) || (question?.rowHeadings?.length===0))
    }
  }
  

  return (
    <div>
      <h3 className={css.modalTitle}><FormattedMessage id={`OpenAIQuestionnaireForm.${data.name}`} /></h3>
      <Form
        onSubmit={handleSave}
        initialValues={useMemo((e) => initialValues(e), [])}
        validate={validation}
        render={({ handleSubmit, values, form, valid }) => (
          <form onSubmit={handleSubmit}>
            {displayInputs(values, form)}
            <div className={css.actionButtons}>
              <SecondaryButton
                type='button'
                className={css.resetButton}
                onClick={closeModal}
                disabled={false}
              >
                <FormattedMessage id='OpenAIQuestionnaireForm.cancelButtonText' />
              </SecondaryButton>
              <PrimaryButton
                type='submit'
                className={css.submitButton}
                disabled={(data.name!=="deleteSection" &&  data.name!=="deleteQuestion") &&  (!valid || emptyOptions(values)) }
                onClick={(e)=>{
                  if(data.name==="deleteSection"){
                    const updatedValues = JSON.parse(JSON.stringify(values));
                    let sections = [...values.sections]
                    sections.splice(data.sectionIndex,1)
                    updatedValues.sections = sections
                    form.change('sections', updatedValues.sections);
                  }if(data.name==="deleteQuestion"){
                    const updatedValues = JSON.parse(JSON.stringify(values));
                    let questions = [...values.sections[data.sectionIndex].questions]
                    questions.splice(data.questionIndex,1)
                    updatedValues.sections[data.sectionIndex].questions = questions
                    form.change('sections', updatedValues.sections);
                  }
                }}
              >
                <FormattedMessage id={(data.name === "addSection") ? 'OpenAIQuestionnaireForm.addButtonText' : ((data.name==="deleteSection" || data.name==="deleteQuestion") ? "EditListingWorkExperienceFormComponent.deleteSchedule" :'OpenAIQuestionnaireForm.saveButtonText')} />
              </PrimaryButton>
            </div>
          </form>
        )}
      />

    </div>
  )
}

export default EditQuestionnaireModal