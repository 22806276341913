import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { ensureListing } from '../../util/data';
import { EditListingBriefFeaturesForm } from '../../forms';
import { ListingLink } from '../../components';

import css from './EditListingBriefFeaturesPanel.module.css';
import { getKeyword, storeSoftwareInsights } from '../../util/api';
import { extractLabels, extractUSP, formatServiceInsightsForForm } from '../../util/destructorHelpers';

const EditListingBriefFeaturesPanel = props => {
  const {
    rootClassName,
    className,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);
  const { publicData } = currentListing.attributes;

  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const panelTitle = <FormattedMessage id="EditListingBriefFeaturesPanel.title" />;

  const { industrySector = [], domainExpertise = [], softwareKnowledge = [], 
    insightTechniquesOrModels = {}, serviceUSP={}, industryUSP={} 
  } = publicData || {};

  const initialValues = {
    service: formatServiceInsightsForForm(domainExpertise,serviceUSP),
    industry: formatServiceInsightsForForm(industrySector,industryUSP),
    software: formatServiceInsightsForForm(softwareKnowledge),
    insightTechniquesOrModels: formatServiceInsightsForForm(insightTechniquesOrModels),
  };

  const handleSubmit = async (values) => {
    try {
      const { service = [], industry = [], software = [], insightTechniquesOrModels = [] } = values;

      const softwareKnowledge = extractLabels(software);
      const insightTechniquesOrModelsLabel = extractLabels(insightTechniquesOrModels);
      const domainExpertiseLabel = extractLabels(service);
      const industrySectorLabel = extractLabels(industry);
      const serviceUSP = extractUSP(service);
      const industryUSP = extractUSP(industry);
      const updatedValues = {
        publicData: {
          domainExpertise: domainExpertiseLabel,
          industrySector: industrySectorLabel,
          softwareKnowledge,
          insightTechniquesOrModels: insightTechniquesOrModelsLabel,
          serviceUSP,
          industryUSP
        },
      };

      onSubmit(updatedValues);
      await storeSoftwareInsights({ service, industry, software, insightTechniquesOrModels })
    } catch (error) {
      return;
    }
  }

  return (
    <div className={classes}>
      <h2 className={css.title}>{panelTitle}</h2>
      <EditListingBriefFeaturesForm
        className={css.form}
        initialValues={initialValues}
        onChange={onChange}
        saveActionMsg={submitButtonText}
        disabled={disabled}
        ready={ready}
        updated={panelUpdated}
        updateInProgress={updateInProgress}
        fetchErrors={errors}
        onSubmit={handleSubmit}
      />
    </div>
  );
};

EditListingBriefFeaturesPanel.defaultProps = {
  rootClassName: null,
  className: null,
  listing: null,
};

const { bool, func, object, string } = PropTypes;

EditListingBriefFeaturesPanel.propTypes = {
  rootClassName: string,
  className: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingBriefFeaturesPanel;
