import React from 'react';
import { bool } from 'prop-types';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import {
  // txIsCanceled,
  txIsCompleted,
  // txIsDeclined
} from '../../util/transaction';
import {
  LINE_ITEM_CUSTOMER_COMMISSION,
  LINE_ITEM_PROVIDER_COMMISSION,
  LINE_ITEM_SERVICE_FEE,
  propTypes,
} from '../../util/types';

import css from './BookingBreakdown.module.css';
import Decimal from 'decimal.js';
import { types as sdkTypes } from '../../util/sdkLoader';
import config from '../../config';
const { Money } = sdkTypes;



const LineItemUnitPrice = props => {
  const { transaction, isProvider, intl, paymentTerms, isFinal } = props;


  const serviceItemsTotal = (transaction, serviceFeeLineItem, serviceFeePercentage) => {
    const paymentFee = transaction?.attributes?.metadata?.proposal?.paymentFee?.amount;
    const paidAmount = serviceFeeLineItem.unitPrice.amount;
    const currency = serviceFeeLineItem.unitPrice.currency;
    const remainingAmount = (Number(paymentTerms) === 0 && isFinal) ? paymentFee : paymentFee - paidAmount;
    return new Money((remainingAmount / 100) * serviceFeePercentage, currency)
  }


  let providerTotalMessageId = 'BookingBreakdown.providerTotalDefault';
  if (txIsCompleted(transaction)) {
    providerTotalMessageId = 'BookingBreakdown.providerTotalDelivered';
  }
  // else if (txIsDeclined(transaction)) {
  //   providerTotalMessageId = 'BookingBreakdown.providerTotalDeclined';
  // } else if (txIsCanceled(transaction)) {
  //   providerTotalMessageId = 'BookingBreakdown.providerTotalCanceled';
  // }

  const totalLabel = isProvider ? (
    <FormattedMessage id={providerTotalMessageId} />
  ) : (
    <FormattedMessage id="BookingBreakdown.total" />
  );

  /**
   * Checks if line item represents commission
   */
  const isCommission = lineItem => {
    return (
      lineItem.code === LINE_ITEM_PROVIDER_COMMISSION ||
      lineItem.code === LINE_ITEM_CUSTOMER_COMMISSION
    );
  };

  /**
   * Returns non-commission, non-reversal line items
   */
  const nonCommissionNonReversalLineItems = transaction => {
    return transaction.attributes.lineItems.filter(item => !isCommission(item) && !item.reversal);
  };

  const serviceFeeLineItem = transaction?.attributes?.lineItems?.find(
    item => item.code === LINE_ITEM_SERVICE_FEE && !item.reversal
  );


  const serviceFeePercentage = serviceFeeLineItem && serviceFeeLineItem?.percentage.d[0];
  const serviceFee = isFinal
    ? serviceFeeLineItem && serviceItemsTotal(transaction, serviceFeeLineItem, serviceFeePercentage)
    : serviceFeeLineItem && serviceFeeLineItem?.lineTotal;

  const formattedServiceFee = serviceFee ? formatMoney(intl, serviceFee) : null;

  /**
   * Calculates the total price in sub units for multiple line items.
   */
  const lineItemsTotal = (lineItems, paymentTerms) => {
    const amount = lineItems.reduce((total, item) => {
      return total.plus(item.lineTotal.amount);
    }, new Decimal(0));

    const currency = lineItems[0] ? lineItems[0]?.lineTotal?.currency : config.currency;
    return new Money(amount, currency);
  };
  // const totalPrice = isProvider
  //   ? transaction.attributes.payoutTotal
  //   : transaction.attributes.payinTotal;
  const subTotalLineItems = nonCommissionNonReversalLineItems(transaction).filter(
    item => item?.code !== 'line-item/service-fee'
  );
  const totalPrice = lineItemsTotal(subTotalLineItems, paymentTerms);
  const formattedTotalPrice = formatMoney(intl, totalPrice);
  const currency = totalPrice && totalPrice?.currency;

  const FINAL_PAYMENT_FEE = transaction?.attributes?.metadata?.proposal?.paymentFee?.amount / 100;
  const regex = /[^0-9.-]+/g; //regex pattern
  const updatedTotalPrice = Number(formattedTotalPrice?.replace(regex, ''));
  const updatedServiceFee = Number(formattedServiceFee?.replace(regex, ''));


  const finalPaymentTotal = (Number(paymentTerms) === 0 && isFinal) ? FINAL_PAYMENT_FEE : FINAL_PAYMENT_FEE - updatedTotalPrice;
  const finalTotalPrice =
    isFinal
      ? ((finalPaymentTotal + updatedServiceFee) * 100 || 0).toFixed(2)
      : (Number(paymentTerms) === 0 && isFinal)
        ? (finalPaymentTotal * 100 || 0).toFixed(2)
        : ((updatedTotalPrice + updatedServiceFee) * 100 || 0).toFixed(2);

  // Check if finalTotalPrice is a valid number before creating Money object
  const formattedFinalTotalPrice = !isNaN(finalTotalPrice) ? new Money(finalTotalPrice, currency) : new Money(0, currency);


  return (
    <>
      <hr className={css.totalDivider} />
      <div className={css.lineItemTotal}>
        <div className={css.totalLabel}>{totalLabel}</div>
        <div className={css.totalPrice}>{formatMoney(intl, formattedFinalTotalPrice)}</div>
      </div>
    </>
  );
};

LineItemUnitPrice.propTypes = {
  transaction: propTypes.transaction.isRequired,
  isProvider: bool.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemUnitPrice;
