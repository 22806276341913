import React from 'react';
import { bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { Form, Button } from '../../components';
import pen from '../../assets/pen.png'

import css from './EditListingCertificationForm.module.css';
import {
  calculateQuantityFromTimes,
  monthYearsString
} from "../../util/dates";

export const EditListingCertificationFormComponent = props => (
  <FinalForm
    {...props}
    render={formRenderProps => {
      const {
        className,
        disabled,
        ready,
        intl,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        handleSubmit,
        experienceList,
        SetOpenModal,
        SetInitialValues
      } = formRenderProps;

      const { updateListingError, showListingsError } = fetchErrors || {};
      const errorMessage = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingPoliciesForm.updateFailed" />
        </p>
      ) : null;
      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingPoliciesForm.showListingFailed" />
        </p>
      ) : null;

      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;

      const handleOpenInfoDescription = el => {
        SetOpenModal(true)
        SetInitialValues(el)
      }

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {errorMessage}
          {errorMessageShowListing}

          {experienceList && experienceList.map(el => (
            <div key={el.number} className={css.blockInfo}>
              <img src={pen} alt="" className={css.penEdit}
                onClick={() => handleOpenInfoDescription(el)}
              />
              <span className={css.blockTitle}>{el.certification}</span>
              <div className={css.blockCompany}>
                <span>{el.organisation}</span>
              </div>
              <div className={css.blockExperience}>
                {el.notExpire && (el.notExpire.length || !el.endDate) ?
                  <span>Issued {monthYearsString(el.startDate)}. Certificate does not expire. </span>
                  : <>
                    <span>{monthYearsString(el.startDate)} - </span>
                    <span>{monthYearsString(el.endDate)} • </span>
                    <span>{calculateQuantityFromTimes(el.startDate, el.endDate)}</span>
                  </>
                }
              </div>
              <span className={css.blockDescription}>{el.certificationURL}</span>
            </div>
          )
          )}

          <div onClick={()=> SetOpenModal(true)} className={css.addWork}>
            <p className={css.textAddWork}>
              <FormattedMessage id="EditListingCertificationPanel.addWork" />
            </p>
          </div>

          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button>
        </Form>
      );
    }}
  />
);

EditListingCertificationFormComponent.defaultProps = {
  selectedPlace: null,
  updateError: null,
};

EditListingCertificationFormComponent.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  selectedPlace: propTypes.place,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
};

export default compose(injectIntl)(EditListingCertificationFormComponent);
