import React from 'react';
import config from '../../config';
import { USER_ROLE_PROVIDER } from '../../util/types';
import LineItemSubTotalMaybe from '../BookingBreakdown/LineItemSubTotalMaybe';
import LineItemTotalPrice from '../BookingBreakdown/LineItemTotalPrice';
import css from './CustomBreakdown.module.css';
import LineItemServiceFee from '../BookingBreakdown/LineItemServiceFee';
import { FormattedMessage } from 'react-intl';
import { finalPayment, payinTotal } from '../../util/destructorHelpers';
import { useSelector } from 'react-redux';
import { formatMoney } from '../../util/currency';
import { types as sdkTypes } from '../../util/sdkLoader';
import { TRANSITION_CONFIRM_PAYMENT_AFTER_PROPOSAL_ACCEPTED } from '../../util/transaction';
import moment from 'moment';
const { Money } = sdkTypes;

const CustomBreakdown = props => {
  const {
    intl,
    paymentTerms,
    isProvider,
    isStripeTransition,
    transaction,
    isFinal,
    isProviderIndianOrigin
  } = props;

  const { currentUser = {} } = useSelector(state => state.user)
  const transitions = transaction?.attributes?.transitions;
  const firstPaymentTransition = transitions?.length && 
    transitions.find(t => t.transition === TRANSITION_CONFIRM_PAYMENT_AFTER_PROPOSAL_ACCEPTED);
  const firstPaymentTime = firstPaymentTransition && moment(firstPaymentTransition?.createdAt).format('DD/MM/yyyy');
  const serviceFeePercentage = !!transaction?.id && transaction?.attributes?.lineItems?.[1]?.percentage?.d?.[0];
  const projectFee = Number(transaction?.attributes?.metadata?.proposal?.paymentFee?.amount);
  const currency = transaction?.attributes?.metadata?.proposal?.paymentFee?.currency;
  
  const currentAmount = transaction?.attributes?.payinTotal.amount;
  const totalAmount = (projectFee) + (projectFee/100) * serviceFeePercentage;
  const nextAmount = totalAmount-currentAmount;

  const formattedNextPayment = nextAmount && formatMoney(intl, new Money(nextAmount, currency));
  const currentPayment = !!transaction?.id && formatMoney(intl, transaction?.attributes?.payinTotal);
  const formattedTotalPayment = new Money(totalAmount, currency);
  const totalPayment = totalAmount && formatMoney(intl, formattedTotalPayment);

  return (
    <div className={css.customBreakdown}>
      {!!transaction?.id && (
        <>
          <div className={css.commissionWrapper}>
            <h1 className={css.commissionTitle}><FormattedMessage id="CustomBreakdown.title"/></h1>
            <div className={css.commissionBox}>
              <ul className={css.progressBar}>
                {/* completeProgress and pendingProgress two classes both are add in li class */}
                <li className={isFinal ? css.completeProgress: css.pendingProgress}>
                  <div className={css.commissionAmount}>
                    {paymentTerms === 0 && !isFinal ? formattedNextPayment :  currentPayment}
                  </div>
                  <div className={css.commissionDay}>
                    {isFinal ? firstPaymentTime : <FormattedMessage id="CustomBreakdown.today"/>}
                  </div>
                </li>
                <li className={css.pendingProgress}>
                  <div className={css.commissionAmount}>
                    {paymentTerms === 0 && !isFinal ? currentPayment :  formattedNextPayment}
                  </div>
                  <div className={css.commissionDay}>
                    {isFinal ? <FormattedMessage id="CustomBreakdown.today"/> :  <FormattedMessage id="CustomBreakdown.projectCompleted"/>}
                  </div>
                </li>
              </ul>
              <div className={css.totalCost}>
                <div className={css.totalCostTitle}><FormattedMessage id="CustomBreakdown.totalCost"/></div>
                <div className={css.totalCostText}>{totalPayment}</div>
              </div>
            </div>
          </div>
          <div className={css.lineTotalMain}>
            <h1 className={css.commissionTitle}><FormattedMessage id="CustomBreakdown.payToday"/></h1>
            <div className={css.lineTotal}>
              <LineItemSubTotalMaybe
                transaction={transaction}
                unitType={config.bookingUnitType}
                userRole={USER_ROLE_PROVIDER}
                intl={intl}
                paymentTerms={paymentTerms}
                isFinal={isFinal}
              />
              <LineItemServiceFee
                transaction={transaction}
                isProvider={isProvider}
                intl={intl}
                isFinal={isFinal}
                currentUser={currentUser}
                paymentTerms={paymentTerms}
              />
              <LineItemTotalPrice
                paymentTerms={paymentTerms}
                transaction={transaction}
                isProvider={isProvider}
                intl={intl}
                isFinal={isFinal}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default CustomBreakdown;
