import routeConfiguration from "../../routeConfiguration";
import { createAppListing, createGigAppBoxFolder, updateSubscriptionData } from "../../util/api";
import { getUserDetails } from "../../util/destructorHelpers";
import { createResourceLocatorString } from "../../util/routes";
import { updateProfile } from "../ProfileSettingsPage/ProfileSettingsPage.duck";
import { initiateAssistedTransaction } from "./OpenAIAppsPage.duck";


export async function handleUserModeData(params) {
  try {
    const { isClient, isCsm, isUserSubscribed, setOpenAlertModal, history, appRoute, dispatch, isAssistedMode, title, description, 
      listingId, tab, currentUser, hasMacroAppAccess, setIsOpenAIAppsAssistedModal
    } = params;

    const userEmail = currentUser?.id && getUserDetails(currentUser)?.email;
    const emails = [userEmail, 'sethu@insightgig.com'];

    if (!isUserSubscribed && !isCsm) {
      setIsOpenAIAppsAssistedModal(false)
      setOpenAlertModal(true);
    }
    else if(!isAssistedMode){
      setIsOpenAIAppsAssistedModal(false)
      if(!isUserSubscribed) setOpenAlertModal(true)
      else if(!hasMacroAppAccess) setOpenAlertModal('hasMacroAppAccess')
      else {
        history.push(
          createResourceLocatorString('OpenAiAppsPageForm', routeConfiguration(), {
            slug: appRoute,
          })
        );
      }
    }
    else {
      // const response = await createAppListing({ title, authorId: "64e6236d-2ee9-4d00-8017-6c16f238b1b4", description, appRoute })
      // console.log(response,'response')
      const transactionId = await dispatch(initiateAssistedTransaction({ listingId, emails, tab, history }));
    }

  } catch (error) {
    console.error(error);
  }

}

//Update user micro gig-app usage record 
export async function handleUpdateUserSubscriptionData({isUserSubscribed, email, type, appName}){
  if(isUserSubscribed){
    const params = {
      email,
      type,
      data: {name: appName}
    }
    updateSubscriptionData(params)
  }
}