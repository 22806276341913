import React from 'react'
import css from './JobPaymentForm.module.css'
import { FormattedMessage, injectIntl } from 'react-intl';
import classNames from 'classnames';
import { Field, Form as FinalForm } from 'react-final-form';
import { FieldRadioButton, Form, PrimaryButton } from '../../components';
import { compose } from 'redux';
import { types as sdkTypes } from '../../util/sdkLoader';
import { formatMoney } from '../../util/currency';
import { OFFLINE, ONLINE } from '../../util/types';
const { Money } = sdkTypes;

function JobPaymentForm(props) {
  return (
    <FinalForm
      {...props}
      render={formRenderProps => {
        const {
          className,
          ready,
          handleSubmit,
          pristine,
          submitProgress,
          values,
          form,
          closeModal,
          intl,
          jobOfferDetails,
          isFullTimeJob,
        } = formRenderProps;

        const classes = classNames(css.root, className);
        const submitDisabled = pristine || !values?.paymentMethod || !values?.paymentType;

        const { salary, secondmentMonth = 1, currency = 'INR' } = jobOfferDetails ?? {};
        const formattedSalary = new Money(salary, currency);
        const updatedSalary = salary && formatMoney(intl, formattedSalary);

        const formattedTotalSalary = new Money((salary * secondmentMonth), currency);
        const updatedTotalSalary = salary && formatMoney(intl, formattedTotalSalary);

        const formattedFee = new Money((salary * secondmentMonth * 0.10), currency);
        const updatedFee = salary && formatMoney(intl, formattedFee);

        const formattedTotal = new Money(((salary * secondmentMonth) + (salary * secondmentMonth * 0.10)), currency);
        const updatedTotal = salary && formatMoney(intl, formattedTotal);

        const feePercentage = 10;

        const title = intl.formatMessage({ id: 'JobPaymentForm.title' });
        const paymentTypeLabel = intl.formatMessage({ id: 'JobPaymentForm.paymentTypeLabel' });
        const paymentMethodLabel = intl.formatMessage({ id: 'JobPaymentForm.paymentMethodLabel' });
        const feeTitle = intl.formatMessage({ id: 'JobPaymentForm.feeTitle' });
        const expertFeeTitle = intl.formatMessage({ id: 'JobPaymentForm.expertFeeTitle' });
        const totalLabel = intl.formatMessage({ id: 'JobPaymentForm.total' });

        return (
          <Form className={classes} onSubmit={handleSubmit}>
            <h2 className={css.title}>{title}</h2>
            <label>{paymentTypeLabel}</label>
            {isFullTimeJob ? null :
              <>
                <FieldRadioButton
                  name='paymentType'
                  label='Expert fee + InsightGig fee'
                  id='bothExpertAndInsightgig'
                  value={'both'}
                />
                <div className={css.paymentContainer}>
                  <div className={css.row}>
                    <p>{expertFeeTitle}</p>
                    <p>{updatedTotalSalary}</p>
                  </div>
                  <div className={css.totalRow}>
                    <FormattedMessage
                      id={isFullTimeJob ? 'JobPaymentForm.fullTimeSalary' : 'JobPaymentForm.secondmentSalary'}
                      values={{ salary: updatedSalary, months: secondmentMonth }}
                    />
                  </div>
                  <div className={css.row}>
                    <p className={css.feeTooltip}>{feeTitle}</p>
                    <p>{updatedFee}</p>
                  </div>
                  <div className={css.totalRow}>
                    <FormattedMessage
                      id={isFullTimeJob ? 'JobPaymentForm.fullTimeSalaryBreakdown' : 'JobPaymentForm.secondmentSalaryBreakdown'}
                      values={{ salary: updatedSalary, months: secondmentMonth, percentage: feePercentage }}
                    />
                  </div>
                  <div className={css.row}>
                    <label>{totalLabel}</label>
                    <label>{updatedTotal}</label>
                  </div>
                </div>
              </>}

            <FieldRadioButton
              name='paymentType'
              label='InsightGig fee only'
              id='onlyInsightgig'
              value={'single'}
            />
            <div className={css.paymentContainer}>
              <div className={css.row}>
                <p className={css.feeTooltip}>{feeTitle}</p>
                <p>{updatedFee}</p>
              </div>
              <div className={css.totalRow}>
                <FormattedMessage
                  id={isFullTimeJob ? 'JobPaymentForm.fullTimeSalaryBreakdown' : 'JobPaymentForm.secondmentSalaryBreakdown'}
                  values={{ salary: updatedSalary, months: secondmentMonth, percentage: feePercentage }}
                />
              </div>
              <div className={css.row}>
                <label>{totalLabel}</label>
                <label>{updatedFee}</label>
              </div>
            </div>
            <label className={css.paymentMethodLabel}>{paymentMethodLabel}</label>
            <FieldRadioButton
              className={css.disabled}
              name='paymentMethod'
              label='Online'
              id='onlinePayment'
              value={ONLINE}
            />
            <FieldRadioButton
              name='paymentMethod'
              label='Offline'
              id='offlinePayment'
              value={OFFLINE}
            />
            <div className={css.buttonWrapper}>
              <span className={css.buttonCancel} onClick={() => closeModal()}>
                Cancel
              </span>
              <PrimaryButton
                className={css.buttonSubmit}
                type='sumbit'
                disabled={submitDisabled}
                inProgress={submitProgress}
              >
                Submit
              </PrimaryButton>
            </div>
          </Form>
        )
      }}
    />
  )
}

export default compose(injectIntl)(JobPaymentForm)