import { InlineTextButton } from '../Button/Button';
import css from './ManageJobCard.module.css'
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import routeConfiguration from '../../routeConfiguration';
import { LISTING_PAGE_DRAFT_VARIANT, LISTING_PAGE_PARAM_TYPE_DRAFT, createSlug } from '../../util/urlHelpers';
import { LISTING_STATE_CLOSED, LISTING_STATE_DRAFT } from '../../util/types';
import { createResourceLocatorString } from '../../util/routes';
import { ensureOwnListing } from '../../util/data';
import classNames from 'classnames';
import { types } from 'sharetribe-flex-sdk';
import { formatMoney } from '../../util/currency';
import { FormattedMessage, injectIntl } from 'react-intl';
import NamedLink from '../NamedLink/NamedLink';
import { ROLE } from '../EditListingBriefWizard/EditListingBriefWizardTab';

const { Money } = types;
const MAX_LENGTH_FOR_WORDS_IN_TITLE = 7;
const createListingURL = (routes, listing) => {
    const id = listing.id.uuid;
    const slug = createSlug(listing.attributes.title);
    const isDraft = listing.attributes.state === LISTING_STATE_DRAFT;
  
    const linkProps =
      isDraft
        ? {
          name: 'ListingPageVariant',
          params: {
            id,
            slug,
            variant: LISTING_PAGE_DRAFT_VARIANT,
          },
        }
        : {
          name: 'ListingPage',
          params: { id, slug },
        };
  
    return createResourceLocatorString(linkProps.name, routes, linkProps.params, {});
};

const ManageJobCard = (props) => {
  const {
    listing, 
    history, 
    className, 
    rootClassName, 
    intl, 
    actionsInProgressListingId,
    onOpenListing,
    onCloseListing,
  } = props;
  const classes = classNames(rootClassName || css.root, className);
  
  const currentListing = ensureOwnListing(listing);
  const id = currentListing.id.uuid;
  const { title = '', state, publicData, description } = currentListing.attributes;
  const slug = createSlug(title);
  const isClosed = state === LISTING_STATE_CLOSED;
  const isDraft = state === LISTING_STATE_DRAFT;
  const { ctc, industrySector, employmentLength} = publicData;
  const employement = employmentLength === 'Not applicable' ? employmentLength : `${employmentLength} months`;
  
  const {maxPrice, minPrice, currency} = ctc ?? {};
  const maxMoney = !!maxPrice && new Money(Number(maxPrice.replace(/[^a-z0-9-]/g, '')) * 100, currency)
  const minMoney = !!minPrice && new Money(Number(minPrice.replace(/[^a-z0-9-]/g, '')) * 100, currency)

//   console.log(isClosed)
  return(
    <div className={classNames(classes, css.mainInfo)}>
      <div className={classNames((isDraft || isClosed) && css.titleWrapper)}>
        <InlineTextButton
          rootClassName={css.title}
          className={css.titleButton}
          onClick={event => {
            event.preventDefault();
            event.stopPropagation();
            history.push(createListingURL(routeConfiguration(), listing));
          }}
        >
          <span>{title}</span>
        </InlineTextButton>
        <div className={css.description}>
          {description && description.slice(0, 160)}
        </div>
        <div className={css.description}>
          <b>Employment length:</b> {employement}
        </div>
        <div className={css.description}>
          <b>Price range :</b> {!!minMoney && formatMoney(intl, minMoney)} - {!!maxMoney && formatMoney(intl, maxMoney)}
        </div>
        <div className={css.tags}>
          {industrySector?.length && industrySector?.map(service => {
            return(
              <div key={service} className={css.tag}>
                {service}
              </div>
            )
          })}
        </div>
      </div>
      <div className={css.buttonsWrapper}>
        <div>
          {isDraft ? (
            <NamedLink
              className={css.finishListingDraftLink}
              name="EditJobListingPage"
              params={{ id, slug, type: LISTING_PAGE_PARAM_TYPE_DRAFT, tab: ROLE }}
            >
              <FormattedMessage id="ManageJobCard.finishListingDraft" />
            </NamedLink>
              ) : isClosed && (
            <button
              className={css.openListingButton}
              disabled={!!actionsInProgressListingId}
              onClick={event => {
                  event.preventDefault();
                  event.stopPropagation();
                  if(!actionsInProgressListingId){
                  onOpenListing(currentListing.id);
                  }
              }}
            >
              <FormattedMessage id="ManageJobCard.openJobListing" />
            </button>
        )}
        </div>
        {!isClosed && (
          <button onClick={() => onCloseListing(currentListing.id)}>
            <FormattedMessage id="ManageJobCard.closeJobListing" />
          </button>
        )}
      </div>
    </div>
  )
}

export default compose(
  withRouter,
  injectIntl
)(ManageJobCard);