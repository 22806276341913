import React from 'react';
import { node, string } from 'prop-types';
import classNames from 'classnames';

import css from './EditListingHintTooltip.module.css';
import IconCard from '../IconCard/IconCard';
import { FormattedMessage } from 'react-intl';

const EditListingHintTooltipComponent = props => {
    const { rootClassName, className, tooltipText } = props;

    const classes = classNames(rootClassName || css.root, className);

    return (
        <div className={classes}>
            <div className={css.tipsBox}>
                <div className={css.tipsHeading}>
                    <FormattedMessage id="EngagementPanel.learnBestPractices" />
                </div>
                <div className={css.tooltipShown}>
                    {/* <span className={css.iconClose}>
                        <IconCard brand="close" />
                    </span> */}
                    <span className={css.tooltipText}>
                        {tooltipText}
                    </span>
                </div>
            </div>
        </div>
    );
};

EditListingHintTooltipComponent.defaultProps = {
    className: null,
    rootClassName: null,
};

EditListingHintTooltipComponent.propTypes = {
    className: string,
    rootClassName: string,
};

export default EditListingHintTooltipComponent;
