import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconSocialMediaLinkedIn.module.css';

const IconSocialMediaLinkedIn = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg className={classes} width="17" height="16" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5 22H0V7H5V22ZM2.501 5C1.118 5 0 3.879 0 2.499C0 1.119 1.12 0 2.501 0C3.879 0 5 1.121 5 2.499C5 3.879 3.879 5 2.501 5ZM23 22H18.193V14.7C18.193 12.959 18.16 10.72 15.694 10.72C13.191 10.72 12.806 12.616 12.806 14.574V22H8V6.989H12.614V9.04H12.679C13.321 7.86 14.89 6.616 17.23 6.616C22.1 6.616 23 9.725 23 13.767V22Z" />
    </svg>
  );
};

IconSocialMediaLinkedIn.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

IconSocialMediaLinkedIn.propTypes = { rootClassName: string, className: string };

export default IconSocialMediaLinkedIn;
