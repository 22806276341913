import React, { useEffect, useState } from 'react';
import { func } from 'prop-types';
import { compose } from 'redux';
import { Field, Form as FinalForm } from 'react-final-form';
// These relative imports need to point to correct directories
import {
  intlShape,
  injectIntl,
  FormattedMessage,
} from '../../util/reactIntl';
import { Form, FieldTextInput, PrimaryButton, SecondaryButton, IconCard, IconSpinner } from '../../components';
import css from './OpenAITextualConceptCreatorForm.module.css';
import { handleFileUpload } from '../../util/api';
import FileView from '../../components/FileView/FileView';
import classNames from 'classnames';
import { composeValidators, required } from '../../util/validators';
import useHandleFileExtensionError from '../../hooks/useHandleFileExtensionError';

const ACCEPT_FILE = '.doc,.docx,application/msword,application/pdf,.ppt,.pptx';
const FILE_SIZE_LIMIT = 25*1000*1000;
const FILE_EXTENSION_ERROR_MESSAGE = 'Please select files in PDF, DOC, or PPT format only.';

const fields = [
  { key: 'selectAllField', label: 'Select all' },
  { key: 'purposeOfStudy', label: 'Purpose of the study' },
  { key: 'description', label: 'Brief description of the concept' },
  { key: 'language', label: 'Language' },
  { key: 'numOfWords', label: 'Number of words' },
]

export const OpenAITextualConceptCreatorForm = props => {
  return (
    <FinalForm
      {...props}
      render={formRenderProps => {
        const {
          disabled,
          handleSubmit,
          intl,
          pristine,
          updated,
          formId,
          updateError,
          values,
          form,
          openaiRequest,
          initialText,
          isError,
        } = formRenderProps;

        const [toggle, setToggle] = useState(false);
        const [isLoading, setIsLoading] = useState(false);
        const [fileExtensionError, checkForFileExtension] = useHandleFileExtensionError();
        const [selectedFields, setSelectedFields] = useState([]);

        const { file, purposeOfStudy, description, language, numOfWords} = values || {};
        const submitDisabled = !toggle
        ? (!purposeOfStudy || !description)
        : (selectedFields.includes('selectAllField') ? false : !file?.name)

        useEffect(() => {
          if (initialText) {
            form.reset();
          }
        }, [initialText])

        function handleToggle() {
          if (toggle) {
            setSelectedFields([])
            setToggle(false)
          }
          else {
            setToggle(true)
          }
        }

        async function handleAttachment(file) {
          setIsLoading(true)
          try{
            const response = await handleFileUpload('get-parsed-document', {file})
    
            if(response?.data){
              if (response?.data){
                form.change('proposalContent', response?.data)
                form.change('file', file)
                setIsLoading(false)
              }
            }
          }
          catch(error){
            setIsLoading(false)
          }
        }

        function handleCheckboxSelect(e) {
          e.stopPropagation();
          const fieldId = e.target.id;
          if (fieldId === 'selectAllField') {
            e.target.checked
              ? setSelectedFields(fields.map(field => {
                delete values[field.key]
                return field.key
              }))
              : setSelectedFields([])
          }
          else {
            if (selectedFields.includes(fieldId)) {
              const modifiedSelectedFields = [...selectedFields].filter(field => field !== fieldId)
              setSelectedFields(modifiedSelectedFields)
            }
            else {
              delete values[fieldId]
              setSelectedFields([...selectedFields, fieldId])
            }
          }
        }

        const purposeOfStudyRequiredMessage = intl.formatMessage({
          id: 'OpenAITextualConceptCreatorForm.purposeOfStudyRequiredMessage',
        });
        const descriptionRequiredMessage = intl.formatMessage({
          id: 'OpenAITextualConceptCreatorForm.descriptionRequiredMessage',
        });

        return (
          <Form onSubmit={handleSubmit}>
            <div className={css.briefGeneratorForm}>
              <div className={css.container}>
                <div className={css.briefGenerator}>
                  <span className={css.generatorTitle}>
                    <FormattedMessage id="OpenAITextualConceptCreatorForm.heading" />
                   </span>
                </div>
                <div className={css.documentSwitcher}>
                  <label>
                    <FormattedMessage id="OpenAIDiscussionGuideForm.documentSwitcherText" />
                  </label>
                  <div className={classNames(css.switcherWrapper, toggle && css.toggled)} onClick={handleToggle}>
                    <div className={css.ball}></div>
                  </div>
                </div>
                {toggle && (
                  <div className={css.attachment}>
                    {file?.name ? (
                      <>
                        <FileView file={file}>
                          <span className={css.close} onClick={() => {
                            form.change('file', {})
                            setSelectedFields([])
                          }}>
                            <IconCard brand='cross'/>
                          </span>
                        </FileView>
                        <div className={css.checkboxWrapper}>
                          {fields.map(({ key, label }) => {
                            const isChecked = selectedFields.includes(key);
                            return (
                              <div key={key}>
                                <label htmlFor={key}>{label}</label>
                                <input
                                  type='checkbox'
                                  className={css.checkbox}
                                  id={key}
                                  name='selectFields'
                                  value={key}
                                  checked={isChecked}
                                  onChange={handleCheckboxSelect}
                                />
                              </div>
                            )
                          })}
                        </div>
                      </>
                    ) : (
                      <>
                        <Field
                          id="file"
                          name="file"
                          accept={ACCEPT_FILE}
                          type="file"
                        >
                          {fieldprops => {
                            const { accept, input, disabled: fieldDisabled } = fieldprops;
                            const { name, type } = input;
                            const onChange = e => {
                              const file = e.target.files[0];
                              const allowedExtensions = /(\.pdf|\.docx?|\.pptx?)$/i;
                              let extensionError = checkForFileExtension(allowedExtensions, file, FILE_EXTENSION_ERROR_MESSAGE)
                              if(extensionError) return
                              if (file && file.name && file.size < FILE_SIZE_LIMIT) {
                                handleAttachment(file)
                              }
                            };

                            const inputProps = { accept, id: name, name, onChange, type };
                            return <input {...inputProps} className={css.addImageInput} />
                          }}
                        </Field>
                        <label htmlFor="file" className={css.inputLabel}>
                          {isLoading ? <IconSpinner strokeColor='#0B96F5' /> : <IconCard brand="upload" />}
                          <span className={css.dragDrop}><FormattedMessage id="GigAppsPage.singleFileUpload" /></span>
                          <span className={css.docType}>
                            <FormattedMessage id="JobListing.documentAllowList" />
                          </span>
                        </label>
                        {fileExtensionError && <p className={css.fileSizeError}>
                          {fileExtensionError}
                        </p>}
                      </>
                    )}
                  </div>
                )}
                {!selectedFields.includes('purposeOfStudy') && (
                   <div className={css.textareaBox}>
                    <label><FormattedMessage id="OpenAITextualConceptCreatorForm.studyPurposeLabel" /></label>
                    <FieldTextInput
                      className={css.inputBox}
                      type="textarea"
                      name="purposeOfStudy"
                      autoComplete="given-name"
                      validate={composeValidators(required(purposeOfStudyRequiredMessage))}
                      vibrate={true}
                    />
                  </div>
                )}
                {!selectedFields.includes('description') && (
                  <div className={css.textareaBox}>
                    <label><FormattedMessage id="OpenAITextualConceptCreatorForm.briefDescriptionLabel" /></label>
                    <FieldTextInput
                      className={css.inputBox}
                      type="textarea"
                      name="description"
                      validate={composeValidators(required(descriptionRequiredMessage))}
                      vibrate={true}
                    />
                  </div>
                )}
                {!selectedFields.includes('language') && (
                  <div className={css.timelineRow}>
                    <label><FormattedMessage id="OpenAITextualConceptCreatorForm.languageLabel" /></label>
                    <FieldTextInput
                      className={classNames(css.inputBox)}
                      type="text"
                      id='language'
                      name="language"
                      placeholder='Formal, Friendly, Official, etc.'
                    />
                  </div>
                )}
                {!selectedFields.includes('numOfWords') && (
                  <div className={css.timelineRow}>
                    <label><FormattedMessage id="OpenAITextualConceptCreatorForm.numOfWordsLabel" /></label>
                    <FieldTextInput
                      className={classNames(css.inputBox)}
                      type="text"
                      id='numOfWords'
                      name="numOfWords"
                      placeholder='300 words, 500 words, etc.'
                    />
                  </div>
                )}
                {isError}
                <div className={css.actionButtons}>
                  <SecondaryButton
                    type='button'
                    className={css.resetButton}
                    onClick={() => {
                      form.reset()
                    }}
                    disabled={openaiRequest}
                  >
                    <FormattedMessage id='OpenAIContractForm.resetButtonText' />
                  </SecondaryButton>
                  <PrimaryButton
                    type='submit'
                    className={classNames(submitDisabled ? css.disabledSubmitButton : css.submitButton )}
                    inProgress={openaiRequest}
                  >
                    <FormattedMessage id='OpenAIContractForm.submitButtonText' />
                  </PrimaryButton>
                </div>
              </div>
            </div>
          </Form>
        );
      }}
    />
  )
}


OpenAITextualConceptCreatorForm.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  //   updateInProgress: bool.isRequired,
};

export default compose(injectIntl)(OpenAITextualConceptCreatorForm);