import React, { useState } from 'react';
import css from './NotificationBar.module.css';
import IconCard, { DROP_ARROW_DOWN, DROP_ARROW_UP } from '../IconCard/IconCard';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import { CHAT_NOTIFICATION, JOB_NOTIFICATION, MILESTONE_NOTIFICATION, TRANSACTION_CHAT_PAGE, TRANSACTION_MILESTONE_PAGE, } from '../../util/types';
import { deleteNotificationFromDB } from '../../util/api';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import { TRANSACTION_NOTIFICATION } from '../../util/types';

const NotificationBar = props => {
  const { currentTransaction, notifications, currentUser, history } = props;
  const [handleDropMenu, setHandleDropMenu] = useState(false);
  const notificationCount = notifications?.length ?? 0;
  const sortedNotifications = [...notifications].sort((a, b) => {
    if (a.notificationDate < b.notificationDate) {
      return -1;
    } else if (a.notificationDate > b.notificationDate) {
      return 1;
    } else {
      return 0;
    }
  });

  const latestChatNotification = notifications.find(n => n.type === CHAT_NOTIFICATION);
  const chatNotificationCount = (notifications?.length > 0 && notifications.filter(notification => notification.type === CHAT_NOTIFICATION))?.length ?? 0
  const filteredNonChatNonTransactionNotifications = notifications
    .filter(notification => ![CHAT_NOTIFICATION, TRANSACTION_NOTIFICATION, JOB_NOTIFICATION].includes(notification.type)) ?? [];
  const totalNotificationCount = (
    (latestChatNotification ? 1 : 0) +
    filteredNonChatNonTransactionNotifications.length
  );

  const handleSeenNotification = async (notification) => {
    try {
      const { type, notificationDate } = notification || {};
      const userId = currentUser?.id?.uuid;
      const transactionId = currentTransaction?.id?.uuid;

      if (!userId || !transactionId) return;

      let response;
      if (type === CHAT_NOTIFICATION) {
        response = await deleteNotificationFromDB({ userId, transactionId });
      } else if (type !== CHAT_NOTIFICATION) {
        response = await deleteNotificationFromDB({ userId, transactionId, notificationDate });
      }

      if (response?.success === 'OK' && [CHAT_NOTIFICATION, MILESTONE_NOTIFICATION].includes(type)) {
        const tab = type === CHAT_NOTIFICATION ? TRANSACTION_CHAT_PAGE : TRANSACTION_MILESTONE_PAGE;
        history.push(createResourceLocatorString('TransactionDetailsPage', routeConfiguration(), { id: transactionId, tab }, {}));
      }
    } catch (error) {
      // console.error('Error handling notification:', error);
    }
  };

  const renderDropDownContent = () => {
    if (!notificationCount) {
      return <div className={css.emptyDropdown}>
        <FormattedMessage id="NotificationBar.noNotificationText" />

      </div>;
    }

    const formattedTime = (notification) => {
      const now = moment();
      const notificationDate = moment(notification.notificationDate);
      const timeDiffInMinutes = now.diff(notificationDate, 'minutes');
      const formattedTime = timeDiffInMinutes < 60 ? `${timeDiffInMinutes} minutes ago` : `${Math.floor(timeDiffInMinutes / 60)} hours ago`;

      return formattedTime
    }
    return (
      <>
        {latestChatNotification && (
          <div className={css.dropdownWrapper} key={latestChatNotification._id} onClick={() => handleSeenNotification(latestChatNotification)}>
            <FormattedMessage
              id="NotificationBar.chatNotificationContent"
              values={{
                chatNotificationCount
              }}
            />
            <div className={css.formattedTime}>{formattedTime(latestChatNotification)}</div>
          </div>
        )}
        {filteredNonChatNonTransactionNotifications?.map((notification) => {
          return (
            <div className={css.dropdownWrapper} onClick={() => handleSeenNotification(notification)} key={notification._id}>
              <FormattedMessage
                id="NotificationBar.notificationContent"
                values={{
                  notificationContent: notification.content,
                }}
              />
              <div className={css.formattedTime}>{formattedTime(notification)}</div>
            </div>
          );
        })}
      </>
    );
  };

  return (
    <div className={css.notificationWrapper}>
      <div className={css.notificationBarContainer}>
        <h4>
          <FormattedMessage id="NotificationBar.heading" />
        </h4>
        <div className={css.notificationBarTextAndIcons}>
        <h3>
          <FormattedMessage
            id="NotificationBar.notificationHeading"
            values={{
              notificationCount: totalNotificationCount,
            }}
          />
        </h3>
        <span className={css.notificationIcon} onClick={() => setHandleDropMenu(!handleDropMenu)}>
          {handleDropMenu ? <IconCard brand={DROP_ARROW_UP} /> : <IconCard brand={DROP_ARROW_DOWN} />}
        </span>
        </div>
      </div>
      {handleDropMenu && <div className={css.dropdownContent}>{renderDropDownContent()}</div>}
    </div>
  );
};

export default NotificationBar;
