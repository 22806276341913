import React, { useEffect, useState } from 'react';
import { compose } from 'redux';
import { withRouter, useParams } from 'react-router-dom';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import { array, bool, func, node, oneOfType, shape, string } from 'prop-types';
import classNames from 'classnames';
import omit from 'lodash/omit';
import {
  propTypes,
  LISTING_STATE_CLOSED,
  USER_ROLE_CLIENT,
  ZOOM_MEET,
  GOOGLE_MEET,
  ZOOM_MEETING_FORM,
  USER_ROLE_PARTNER,
  LISTING_STATE_PUBLISHED,
  TRANSACTION_DETAIL_PAGE,
  PRIVATELY_SHARED_JOB,
  RECRUITMENTS_UNDERWAY,
} from '../../util/types';
import { formatMoney } from '../../util/currency';
import { createSlug, parse, stringify } from '../../util/urlHelpers';
import config from '../../config';
import {
  ModalInMobile,
  Button,
  Modal,
  NamedLink,
  ReviewRating,
  IconCard,
  IconSpinner,
  PrimaryButton,
  ZoomMeetPanel,
  CustomBriefShareModal,
} from '../../components';
import { createResourceLocatorString } from '../../util/routes';

import css from './BookingPanel.module.css';
import SectionDocumentsMaybe from '../../containers/ListingPage/SectionDocumentsMaybe';
import GoogleMeetPanel from '../GoogleMeetPanel/GoogleMeetPanel';
import routeConfiguration from '../../routeConfiguration';
import { useDispatch, useSelector } from 'react-redux';
import { initSendJobDescription } from '../../containers/ListingPage/ListingPage.duck';
import { checkIfUserSubscribed, getUserDetails } from '../../util/destructorHelpers';
import googleMeetIcon from '../../assets/googlemeeticon.png';
import zoomIcon from '../../assets/zoomIcon.png';
import { getAllTransactions, showUser, updateFirmUserDetails, updateUserProfile } from '../../util/api';
import WorkRatingComponent from '../../containers/ListingPage/WorkRatingComponent';
import { ModalSendBriefForm } from '../../forms';
import SectionAdditionalMaybe from '../../containers/ListingPage/SectionAdditionalMaybe';
import Recommendation from '../Recommendation/Recommendation';
import { handleUpdateUserSubscriptionData } from '../../containers/OpenAIAppsPage/openAIAppsPageHelperFunction';

// This defines when ModalInMobile shows content as Modal
const MODAL_BREAKPOINT = 1023;

const priceData = (price, intl) => {
  if (price && price.currency === config.currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: `(${price.currency})`,
      priceTitle: `Unsupported currency (${price.currency})`,
    };
  }
  return {};
};

const openBookModal = (isOwnListing, isClosed, history, location) => {
  if (isOwnListing || isClosed) {
    window.scrollTo(0, 0);
  } else {
    const { pathname, search, state } = location;
    const searchString = `?${stringify({ ...parse(search), book: true })}`;
    history.push(`${pathname}${searchString}`, state);
  }
};

const closeBookModal = (history, location) => {
  const { pathname, search, state } = location;
  const searchParams = omit(parse(search), 'book');
  const searchString = `?${stringify(searchParams)}`;
  history.push(`${pathname}${searchString}`, state);
};


const BookingPanel = props => {
  const {
    isBrief,
    rootClassName,
    className,
    titleClassName,
    listing,
    isOwnListing,
    unitType,
    title,
    subTitle,
    onManageDisableScrolling,
    history,
    location,
    intl,
    editParams,
    // lineItems,
    // fetchLineItemsError,
    toggleRequestModal,
    reviews,
    documents,
    isClient,
    onGetGoogleAccessToken,
    onScheduleGoogleMeet,
    currentUser,
    currentAuthor,
    params,
    meetingInProgress,
    meetingCreated,
    transactions,
    meetingError,
    meetingErrorToaster,
    handleCreateZoomMeeting,
    handleEnterMeeting,
    onCreateZoomAuth,
    zoomMeetingInProgress,
    zoomMeetingError,
    zoomMeetingCreated,
    onUpdateProfile,
    publicData,
    openReview,
    listingId,
    totalProjects,
    projectCompletionRate,
    isSharedBrief,
    isJobListing,
    listingRole,
    isCsm,
    firmId,
    firmData,
    modal, 
    setModal,
    openJobModal,
    setOpenJobModal,
  } = props;
  
  const dispatch = useDispatch();
  const [shareBriefModalState, setShareBriefModalState] = useState(false);
  const [shareBriefInviteInProgess, setShareBriefInviteInProgress] = useState(false);
  const [inviteSuccessToaster, setInviteSuccessToaster] = useState(false);
  const [showAllTags, setShowAllTags] = useState({});

  const [meetingModal, setMeetingModal] = useState(false);
  const currentUserEmail = !!currentUser?.id && getUserDetails(currentUser)?.email;
  const currentAuthorEmail = !!currentAuthor?.id && getUserDetails(currentAuthor)?.email;
  const userEmailsForGoogleMeet = [currentUserEmail, currentAuthorEmail]?.map(email => ({ email }));
  const urlParams = new URLSearchParams(location.search);
  const hasZoomCode = urlParams.get('code');
  const { fetchTransactionInProgress, initProposalInProgress } = useSelector(state => state.ListingPage);
  const { currentUserJobs } = useSelector(state => state.user);
  const [meetingSuccessToaster, setMeetingSuccessToaster] = useState(false);

  const [meetingToggle, setMeetingToggle] = useState('')
  const { slug, id } = useParams();
  const isUserSubscribed = checkIfUserSubscribed(currentUser);


  useEffect(() => {
    if (meetingError) {
      setMeetingModal(false);
    }
  }, [meetingError]);

  let reviewsTotalRating = reviews && reviews.reduce((accumulator, currentValue) => {
    return accumulator + currentValue.attributes.rating;
  }, 0);
  const reviewsAverageRating = reviewsTotalRating && reviews ? reviewsTotalRating / reviews.length : 0;
  const hasListingState = !!listing.attributes.state;
  const isClosed = hasListingState && listing.attributes.state === LISTING_STATE_CLOSED;


  const classes = classNames(rootClassName || css.root, className);
  const { website, blogUrl, softwareKnowledge=[], insightTechniquesOrModels=[], spokenLanguages=[]
  } = !!listing?.id && publicData;

  async function sendJobDescription({ values, listing }) {
    const { title, description, publicData, createdAt } = listing.attributes;
    const params = {
      listingId: id,
      protectedData: {
        brief: {
          id: values.briefId,
          title,
          description,
          createdAt: createdAt.getTime(),
          briefType: "Private",
          briefSentAt: Date.now(),
          ...publicData
        },
      },
      metadata: { isTransactionRead: false },
      currentUser,
      currentAuthor
    }

    dispatch(initSendJobDescription(params)).then(txId => {
      handleUpdateUserSubscriptionData({
        isUserSubscribed, 
        email: currentUserEmail, 
        type: 'sharedJobDescription', 
        appName: title,
      })
      updateFirmUserDetails({ 
        action: PRIVATELY_SHARED_JOB,
        userEmail: getUserDetails(currentUser)?.email,
        listingId: listing?.id?.uuid
      })
      history.push(createResourceLocatorString('TransactionDetailsPage', routeConfiguration(), { id: txId?.uuid, tab: TRANSACTION_DETAIL_PAGE }, {}))
    })
  }

  const handleBriefShare = async (values) => {
    setShareBriefInviteInProgress(true);
    const { firmUserId = null } = values;
    const briefIds = await showUser({ userId: firmUserId });

    // Combine existing unique IDs with new IDs and remove duplicates
    const combinedUniqueBriefIds = [...new Set([...briefIds, listing?.id?.uuid])];
    const data = {
      id: firmUserId,
      protectedData: {
        briefIds: combinedUniqueBriefIds
      },
    };
    const response = await updateUserProfile({ data });
    if (response.message === 'ok') {
      setShareBriefModalState(false);
      setShareBriefInviteInProgress(false);
      setInviteSuccessToaster(true);
    }
    setTimeout(() => {
      setInviteSuccessToaster(false);

    }, 3000)
  };

  return (
    <div className={classes}>
      {inviteSuccessToaster && (
        <div className={css.successMessage}>
          <FormattedMessage id="ManageListingCard.inviteSuccessToasterText" />
        </div>
      )}
      {(meetingError || zoomMeetingError) && meetingErrorToaster ? (
        <p className={css.meetingError}>
          <FormattedMessage id="GoogleMeetForm.meetingErrorMessage" />
        </p>
      ) : null}
      {meetingSuccessToaster && (
          <div className={css.successMessage}>
            <FormattedMessage id="BookingPanel.meetingToasterText" />
          </div>
        )}
      {/* 
        {(isCsm && isOwnListing) ? null : listing && listing.attributes.state === 'draft' ? (
          <NamedLink name="EditListingPage" params={editParams} className={css.bookButton}>
            {isBrief ? (
              <FormattedMessage id="BookingPanel.sendProposal" />
            ) : (
              <FormattedMessage id="BookingPanel.sendBrief" />
            )}
          </NamedLink>
        ) : (
          <button
            onClick={toggleRequestModal}
            className={classNames(css.bookButton, showTooltip ? css.toolTip : null)}
            // disabled={isOwnListing || porposalButtonDisable || (!isBrief && !isProjectBased)}
            data-tooltip={
              'This expert is open to secondment or full-time employment. The expert does not take up project-based work.'
            }
            disabled={isOwnListing || isSharedBrief}
          >
            {isBrief ? (
              <FormattedMessage id="BookingPanel.sendProposal" />
            ) : isJobListing ? (
              <FormattedMessage id="BookingPanel.applyForJob" />
            ) : (
              <FormattedMessage id="BookingPanel.sendBrief" />
            )}
          </button>
        )}
        {isCsm && isOwnListing && listing.attributes.state === LISTING_STATE_PUBLISHED ? (
          <>
            <button
              onClick={() => setShareBriefModalState(true)}
              type={'button'}
              className={classNames(css.bookButton, showTooltip ? css.toolTip : null)}
            >
              <FormattedMessage id="BookingPanel.csmShareBrief" />
            </button>
            {shareBriefModalState ? (
              <CustomBriefShareModal
                onSubmit={handleBriefShare}
                firmData={firmData}
                updateInProgress={shareBriefInviteInProgess}
                onClose={() => setShareBriefModalState(false)}
                shareBriefModalState={shareBriefModalState}
                isCsm={isCsm}
              />
            ) : null}
          </>
        ) : null} */}
      <div className={css.openBookingForm}>
        {isClosed ? (
          <div className={css.closedListingButton}>
            <FormattedMessage id="BookingPanel.closedListingButtonText" />
          </div>
        ) : null}
      </div>

      <SectionAdditionalMaybe publicData={publicData} />
      {listingRole === USER_ROLE_PARTNER && (
        <>
          {(blogUrl || website) && (
            <div className={css.shadowBox}>
              <div className={css.personalCard}>
                <h2>Personal info</h2>
                <div className={css.personalDetail}>
                  {website && <a href={website} target='_blank' className={css.linkName}>Visit my website</a>}
                  {blogUrl && (
                    <>
                      <span className={css.dot}>•</span>
                      <a href={blogUrl} target='_blank' className={css.linkName}>Read my blog</a>
                    </>
                  )}
                </div>
              </div>
            </div>
          )}
          <div className={css.desktopWorkRating}>
            <h2 className={css.ratingHeading}>Project Statistics</h2>
            <WorkRatingComponent
              fetchTransactionInProgress={fetchTransactionInProgress}
              projectCompletionRate={projectCompletionRate}
              totalProjects={totalProjects}
              reviewsAverageRating={reviewsAverageRating}
              reviews={reviews}
            />
          </div>
        </>
      )}
      {!!softwareKnowledge.length && (
        <div className={css.SoftwareCard}>
          <h2 className={css.ratingHeading}>Software</h2>
          <div className={css.softwareDetail}>
            <div className={classNames(css.softwareLine, showAllTags?.software && css.showAdditionalInfo)}>
              {softwareKnowledge?.map((software, index) => (
                <span key={index}>{index !== 0 && ` • `}{software}</span>
              )) }
            </div>
            {softwareKnowledge?.join(' ')?.length > 30 && (
              <div className={css.showAll} onClick={() => setShowAllTags({...showAllTags, software: !showAllTags?.software})}>
                {showAllTags?.software ? `Hide additional software` : `Show all ${softwareKnowledge.length} softwares`}
              </div>
            )}
          </div>
        </div>
      )}
      {!!insightTechniquesOrModels.length && (
        <div className={css.SoftwareCard}>
          <h2 className={css.ratingHeading}>Insight Techniques or Models</h2>
          <div className={css.softwareDetail}>
            <div className={classNames(css.softwareLine, showAllTags?.techniques && css.showAdditionalInfo)}>
              {insightTechniquesOrModels?.map((technique, index) => (
                <span key={index}>{index !== 0 && ` • `}{technique}</span>
              ))}
            </div>
            {insightTechniquesOrModels?.join(' ')?.length > 30 && (
              <div className={css.showAll} onClick={() => setShowAllTags({...showAllTags, techniques: !showAllTags?.techniques})}>
                {showAllTags?.techniques 
                  ? `Hide additional insight techniques or models` 
                  : `Show all ${insightTechniquesOrModels.length} insight techniques or models`
                }
              </div>
            )}
          </div>
        </div>
      )}
      {listingRole === USER_ROLE_PARTNER && !!spokenLanguages.length && (
        <div className={css.languageCard}>
          <h2 className={css.languageHeading}>Languages</h2>
          <div className={css.languageDetail}>
            {spokenLanguages.map((language, index) => (
              <span key={index}>{index !== 0 && ` • `}{language.label}</span>
            ))}
          </div>
        </div>
      )}

      {!documents?.length ? null : (
        <div className={css.shadowBox}>
          <SectionDocumentsMaybe
            currentUser={currentUser}
            listing={listing}
            documents={documents}
            isBrief={isBrief}
          />
        </div>
      )}
      {listingRole !== USER_ROLE_PARTNER && (
        <div className={css.desktopWorkRating}>
          <h2 className={css.ratingHeading}>User Stats</h2>
          <WorkRatingComponent
            fetchTransactionInProgress={fetchTransactionInProgress}
            projectCompletionRate={projectCompletionRate}
            totalProjects={totalProjects}
            reviewsAverageRating={reviewsAverageRating}
            reviews={reviews}
            isBrief={listingRole !== USER_ROLE_PARTNER}
          />
        </div>
      )}
      {listingRole === USER_ROLE_PARTNER && isClient && <Recommendation currentListing={listing} />}
      <Modal
        id="TransactionPanel.createMeeting"
        isOpen={modal}
        onClose={() => {
          setModal(false);
          setMeetingToggle('');
        }}
        onManageDisableScrolling={onManageDisableScrolling}
        usePortal
      >
        <div className={css.meetingHeading}>
          <FormattedMessage id="TransactionPanel.selectYourMeeting" />
        </div>
        <div className={css.meetingButtonsBox}>
          <div
            id="googleBox"
            className={classNames(
              css.meetLnk,
              meetingToggle === GOOGLE_MEET && css.meetingBoxBorder
            )}
            onClick={() => setMeetingToggle(GOOGLE_MEET)}
          >
            <img src={googleMeetIcon} />
            <span className={css.hoverBox}>
              <FormattedMessage id="BookingPanel.googleMeetHoverText" />
            </span>
          </div>
          <div
            id="zoomBox"
            className={classNames(css.meetLnk, meetingToggle === ZOOM_MEET && css.meetingBoxBorder)}
            onClick={() => setMeetingToggle(ZOOM_MEET)}
          >
            <img src={zoomIcon} />
            <span className={css.hoverBox}>
              <FormattedMessage id="BookingPanel.zoomMeetHoverText" />
            </span>
          </div>
        </div>
        <div className={css.buttonSection}>
          <p
            className={css.buttonCancel}
            onClick={() => {
              setModal(false);
              setMeetingToggle('');
            }}
          >
            <FormattedMessage id="TransactionPanel.cancel" />
          </p>

          <PrimaryButton
            className={css.buttonSubmit}
            disabled={!meetingToggle}
            onClick={() => {
              setMeetingModal(true);
              setModal(false);
            }}
          >
            <FormattedMessage id="TransactionPanel.next" />
          </PrimaryButton>
        </div>
      </Modal>
      <Modal
        id="Googel/Zoom Panel"
        isOpen={
          meetingModal ||
          (typeof sessionStorage !== 'undefined' &&
            !!JSON.parse(sessionStorage.getItem('params'))?.currentForm) ||
          hasZoomCode
        }
        onClose={() => {
          setMeetingModal(false);
          setMeetingToggle('');
          sessionStorage.removeItem('params');
        }}
        className={css.googleMeetingModal}
        onManageDisableScrolling={onManageDisableScrolling}
        usePortal
      >
        {meetingToggle === ZOOM_MEET ||
          hasZoomCode ||
          (typeof sessionStorage !== 'undefined' &&
            JSON.parse(sessionStorage.getItem('params'))?.currentForm === ZOOM_MEETING_FORM) ? (
          <ZoomMeetPanel
            onManageDisableScrolling={onManageDisableScrolling}
            // currentTransaction={currentTransaction}
            handleCreateZoomMeeting={handleCreateZoomMeeting}
            handleEnterMeeting={handleEnterMeeting}
            currentUser={currentUser}
            onCreateZoomAuth={onCreateZoomAuth}
            zoomMeetingInProgress={zoomMeetingInProgress}
            zoomMeetingError={zoomMeetingError}
            // redirectToMainTransaction={() => redirectToMainTransaction(currentTransaction?.id?.uuid)}
            closeZoomMeetingModel={() => {
              setModal(false);
            }}
            userSlug={slug}
            listingId={id}
            zoomMeetingCreated={zoomMeetingCreated}
            listing={listing}
            currentAuthor={currentAuthor}
            currentProvider={currentUser}
            params={params}
            setMeetingSuccessToaster={setMeetingSuccessToaster}
            hasZoomCode={hasZoomCode}
          />
        ) : (
          <GoogleMeetPanel
            onGetGoogleAccessToken={onGetGoogleAccessToken}
            onScheduleGoogleMeet={onScheduleGoogleMeet}
            currentUser={currentUser}
            currentAuthor={currentAuthor}
            params={params}
            history={history}
            meetingInProgress={meetingInProgress}
            meetingCreated={meetingCreated}
            isOpen={meetingModal}
            meetingError={meetingError}
            setMeetingSuccessToaster={setMeetingSuccessToaster}
            userEmailsForGoogleMeet={userEmailsForGoogleMeet}
            closeGoogleMeetingModel={() => setMeetingModal(false)}
          />
        )}
      </Modal>
      <Modal
        id="TransactionPanel.jobModal"
        isOpen={openJobModal}
        onClose={() => setOpenJobModal(false)}
        onManageDisableScrolling={onManageDisableScrolling}
        usePortal
      >
        <ModalSendBriefForm
          options={currentUserJobs}
          onClose={() => setOpenJobModal(false)}
          onSubmit={sendJobDescription}
          inProgress={initProposalInProgress}
          currentUser={currentUser}
          isJobListing={true}
        />
      </Modal>
    </div>
  );
};

BookingPanel.defaultProps = {
  rootClassName: null,
  className: null,
  titleClassName: null,
  isOwnListing: false,
  subTitle: null,
  unitType: config.bookingUnitType,
  lineItems: null,
  fetchLineItemsError: null,
};

BookingPanel.propTypes = {
  rootClassName: string,
  className: string,
  titleClassName: string,
  listing: oneOfType([propTypes.listing, propTypes.ownListing]),
  isOwnListing: bool,
  unitType: propTypes.bookingUnitType,
  title: oneOfType([node, string]).isRequired,
  subTitle: oneOfType([node, string]),
  authorDisplayName: oneOfType([node, string]).isRequired,
  onManageDisableScrolling: func.isRequired,
  lineItems: array,
  fetchLineItemsError: propTypes.error,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

export default compose(withRouter, injectIntl)(BookingPanel);
