import axios from "axios";
import FormData from "form-data";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { zohoAccessToken, sendZohoDocument, fetchZohoDocument, updateTransactionMetaData, sendZohoReminder, fetchZohoTemplate, deleteZohoTemplate, fetchTransaction } from '../../containers/TransactionPage/TransactionPage.duck'
import css from './ZohoSign.module.css'
import { compose } from "redux";
import { injectIntl, FormattedMessage } from "react-intl";
import { CUSTOMER_INVITEE } from "../../util/types";
import { PrimaryButton } from "../Button/Button";
import Modal from "../Modal/Modal";
import { manageDisableScrolling } from "../../ducks/UI.duck";
import { apiBaseUrl, storeContractSession } from "../../util/api";
import ZohoSignForm from "../../forms/ZohoSignForm/ZohoSignForm";
import pdfimage from '../../assets/pdfimage.png';

const Zoho = (props) => {
  const dispatch = useDispatch();
  const { currentTransaction, isClient, closeTab, isCollaborator } = props;
  const txId = currentTransaction?.id && currentTransaction.id.uuid;
  const collaborationMetaData = currentTransaction?.id && currentTransaction?.attributes?.metadata?.collaborationMetaData;
  const customer = currentTransaction?.id && currentTransaction?.customer;
  const provider = currentTransaction?.id && currentTransaction?.provider;
  const clientEmail = customer?.attributes?.profile?.publicData?.email;
  const clientName = customer?.attributes?.profile?.publicData?.fullName;
  const partnerEmail = provider?.attributes?.profile?.publicData?.email;
  const partnerName = provider?.attributes?.profile?.publicData?.fullName;

  const [openZohoModal, setOpenZohoModal] = useState(false);
  const [confirmDeleteBox, setConfirmDeleteBox] = useState(false);
  const [successToaster, setSuccessToaster] = useState(null);


  const { currentUser } = useSelector(state => state.user);
  const currentUserName = currentUser?.id && currentUser?.attributes?.profile?.publicData?.fullName;
  const currentUserEmail = currentUser && currentUser?.attributes?.email;
  const { metadata = {} } = !!currentTransaction && currentTransaction?.attributes;
  const access_token = metadata.zohoAccessToken?.access_token;
  const expertLeadZohoDetails = metadata?.expertLeadZohoDetails;
  const request_id = expertLeadZohoDetails && expertLeadZohoDetails;
  const template_id = metadata?.zohoTemplate?.template_id;
  const action_id = metadata?.zohoTemplate?.action_id;
  const clientSign = metadata?.zohoTemplate?.clientSign;

  const [userData, setUserData] = useState([]);
  const [zohoUserData, setZohoUserData] = useState([]);
  const [zohoTemplate, setZohoTemplate] = useState({});
  const [sendStatus, setSendStatus] = useState(!expertLeadZohoDetails);

  useEffect(() => {
    if(isClient){
      setUserData([{fullName: partnerName, request_id: request_id ,documentStatus: ''}])
      collaborationMetaData?.length && collaborationMetaData.forEach(e => {
        if(e.invitedByUserRole === CUSTOMER_INVITEE || !e.request_id) return

        setUserData(oldArray => [...oldArray, {
          fullName: e.fullName,
          request_id: e.request_id,
          documentStatus: ''
        }])
      })
    }else if(isCollaborator){
      const collaboratorData = collaborationMetaData?.length && collaborationMetaData.find(e => e.email === currentUserEmail)
      collaboratorData?.request_id && setUserData([{
        fullName: currentUserName, 
        request_id: collaboratorData.request_id,
        documentStatus: ''
      }])
    }else{
      setUserData([{fullName: currentUserName, request_id: request_id ,documentStatus: ''}])
    }
  }, [currentTransaction?.id])


  useEffect(() => {
    request_id && getDocument();
  },[collaborationMetaData, userData])

  useEffect(() => {
     template_id && fetchTemplate();
  },[template_id])

  //Save contract details in database
  async function handleSaveZohoContract(request_id){
    const params = {
      id: request_id,
      transactionId: txId,
      expert: partnerEmail,
      client: clientEmail
    }
    const response = await storeContractSession(params);
  }

  const fetchTemplate = async () => {
    const param = {access_token, template_id}
    const template = await dispatch(fetchZohoTemplate(param))

    if(template && template.success){
      setZohoTemplate(template.data)
    }
  }

  const sendDocument = async () => {
    const params = {
      access_token,
      template_id: template_id,
      action_id: action_id,
      partnerName,
      partnerEmail
    }

    const response = await dispatch(sendZohoDocument(params))

    if(response.status === 'success'){
      const bodyParam = {
        expertLeadZohoDetails: response.requests?.request_id
      }
      dispatch(updateTransactionMetaData({ txId, bodyParam }))
      const res = await dispatch(fetchZohoDocument({ access_token, request_id: response.requests?.request_id }))
      userData[0].documentStatus = res.requests.sign_percentage
      setZohoUserData(userData);
      setSendStatus(false)
      setSuccessToaster('sentSuccess')

      handleSaveZohoContract(response.requests?.request_id)
    }else setSuccessToaster('sentError');

  }

  const getDocument = async () => {
    const promises = [];
    userData.forEach(async (user, i) => {
      if(!user.request_id) return

      promises.push(
        dispatch(fetchZohoDocument({ access_token, request_id: user.request_id })).then(response => {
          if(response && response.status === 'success'){
            user.documentStatus = response.requests.sign_percentage
          }else{
            setSuccessToaster('fetchDocumentError');
          }
        })
      )
    })

    Promise.all(promises).then(() => {
      setZohoUserData(userData)
    })
  }

  const sendReminder = async (request_id) => {
    const response = await dispatch(sendZohoReminder({access_token, request_id}));
    response.success ? setSuccessToaster('reminderSuccess') : setSuccessToaster('reminderError');
  }

  const handleDocumentUpload = async (values, files, setSubmitProgress) => {
    const {privateNote, signatories=[]} = values;
    
    const file1 = files[0];
    const file2 = files[1];
    const file3 = files[2];
    const clientSign = signatories?.includes("clientSign");
    const thirdPartySign = signatories?.includes("thirdPartySign");
    const expertInviteeSign = signatories?.includes("expertInviteeSign");

    const formData = new FormData();
    formData.append('data', JSON.stringify({
      access_token,
      clientSign,
      clientName,
      clientEmail,
      privateNote
    }));
    formData.append("file1", file1);
    file2 && formData.append("file2", file2);
    file3 && formData.append("file3", file3);

    const response = await axios.post(`${apiBaseUrl()}/api/uploadContract`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })

    if(response.status === 200 && response.data.status === 'success'){
      const index = clientSign ? 1 : 0
      const bodyParam = {
        zohoTemplate: {
          template_id: response.data.templates.template_id,
          action_id: response.data.templates.actions[index]?.action_id,
          clientSign: clientSign,
          thirdPartySign: thirdPartySign,
          expertInviteeSign: expertInviteeSign
        },
      }

      setOpenZohoModal(false);
      dispatch(updateTransactionMetaData({ txId, bodyParam }))
    }else {
      setSuccessToaster('uploadDocumentError');
      setSubmitProgress(false);
    }
  };

  const ShowMessages = () => {
    let id, isSuccess = null;

    switch(successToaster){
      case 'uploadDocumentError' : {
        id = "ZohoSign.uploadDocumentError"
        isSuccess = false
      } break;
      case 'fetchDocumentError' : {
        id = "ZohoSign.fetchDocumentError"
        isSuccess = false
      } break;
      case 'sentSuccess' : {
        id = "ZohoSign.sentSuccessMessage"
        isSuccess = true
      } break;
      case 'sentError' : {
        id = "ZohoSign.sentErrorMessage"
        isSuccess = false
      } break;
      case 'reminderSuccess' : {
        id = "ZohoSign.successReminderMessage"
        isSuccess = true
      } break;
      case 'reminderError' : {
        id = "ZohoSign.errorReminderMessage"
        isSuccess = false
      } break;
      case 'deleteError' : {
        id = "ZohoSign.deleteError"
        isSuccess = false
      } break;
    }

    return(
      <>
        {!successToaster && !id ?
          <></> :
          <p className={isSuccess ? css.success : css.error}><FormattedMessage id={id}/></p>
        }
      </>
    )
  }

  const handleDeleteTemplate = async () => {
    const response = await dispatch(deleteZohoTemplate({
      access_token,
      template_id,
    }))
    if(response){
      const bodyParam = {
        zohoTemplate: null
      }
      setConfirmDeleteBox(false)
      dispatch(updateTransactionMetaData({ txId, bodyParam }))
      dispatch(fetchTransaction(currentTransaction?.id))
      closeTab();
    }else{
      setSuccessToaster('deleteError')
    }
  }

  return (
    <div>
      {template_id ? (
        <>
          <h3><FormattedMessage id="ZohoSign.title"/></h3>
          <div className={css.container}>
            <div className={css.header}>
              <h3><FormattedMessage id="ZohoSign.documents"/></h3>
              {isClient && sendStatus &&
                <h4 onClick={() => setConfirmDeleteBox(true)}>Delete All</h4>
              }
            </div>
            <div className={css.zohoDoc}>
              {zohoTemplate?.document_ids?.length
                && zohoTemplate?.document_ids?.map(item => {
                  return(
                    <div className={css.pdfBox}>
                      <span className={css.pdfImage}>
                        <img src={pdfimage} />
                      </span>
                      <div className={css.pdfRight}>
                        <div className={css.pdfName}>{item && item.document_name}</div>
                        <div className={css.pdfDate}>
                          {moment(zohoTemplate.created_time).format("Do MMM YYYY")} at {moment(zohoTemplate.created_time).format("LT")}
                          <span className={css.fileSize}>
                            {(item.document_size/1000000).toFixed(2)} mb
                          </span>
                        </div>
                      </div>
                    </div>
                  )
                })
              }
            </div>

          {isClient && sendStatus && 
            <button className={css.sendButton} onClick={sendDocument}>
              <FormattedMessage id="ZohoSign.sendDocument"/>
            </button> 
          }
          {(expertLeadZohoDetails || !sendStatus) &&
            <div className={css.zohoDetailTable}>
              <table>
                <tr>
                  <th colSpan='2'>Signatories</th>
                  <th colSpan='2'>Status</th>
                </tr>
                {zohoUserData?.length ? zohoUserData.map(user => {
                  return(
                    <tr>
                      {clientSign ? <td>{clientName}</td> : <td>{user.fullName}</td>}
                      {clientSign ? <td>{user.fullName}</td> : <td></td>}
                      <td>{user.documentStatus && user.documentStatus === 100 ?
                            <span>
                              <span className={css.boldText}>Signed:</span>
                              <FormattedMessage id="ZohoSign.allSignaturesReceived"/>
                            </span>  :
                            <span>
                            <span className={css.boldText}>Sent:</span>
                              <FormattedMessage id="ZohoSign.awaitingSignature"/>
                            </span>
                          }
                      </td>
                      <td className={css.download}>
                        {user.documentStatus && user.documentStatus === 100 ?
                          <span>
                            <a href={`https://sign.zoho.in/api/v1/requests/${user.request_id}/pdf`}>
                              <FormattedMessage id="ZohoSign.downloadDocument"/>
                            </a>
                          </span> :
                          isClient && 
                            <span onClick={() => sendReminder(user.request_id)}><FormattedMessage id="ZohoSign.sendReminder"/></span>
                        }
                      </td>
                    </tr>
                  )
                }) : <h3><FormattedMessage id="ZohoSign.statusNotFound"/></h3>
                }
              </table>
            </div>
          }

            <ShowMessages />
          </div>
        </>
      ) : (
        <>
          {isClient &&
            <div className={css.contracts}>
              <p>
                <FormattedMessage id="ZohoSign.youHaveNotUploaded" /><br />
                <FormattedMessage id="ZohoSign.uploadUpContractsSigned" />
              </p>
              <PrimaryButton
                className={css.acceptButton}
                onClick={() => setOpenZohoModal(true)}
              >
                <FormattedMessage id="ZohoSign.uploadContract" />
              </PrimaryButton>
            </div>
          }
        </>
      )
      }

      <Modal
        id="zohoSignModal"
        containerClassName={css.zohoModal}
        isOpen={openZohoModal}
        onClose={() => setOpenZohoModal(false)}
        usePortal
        onManageDisableScrolling={(componentId, disableScrolling) =>
          dispatch(manageDisableScrolling(componentId, disableScrolling))}
      >
        <ZohoSignForm
          initialValues={{signatories: ['expertSign']}}
          onSubmit={() => {}}
          setOpenZohoModal={setOpenZohoModal}
          openZohoModal={openZohoModal} 
          handleUpload={handleDocumentUpload}
        />
        <ShowMessages />
      </Modal>

      <Modal
        id="zohoSignModal"
        className={css.zohoModal}
        isOpen={confirmDeleteBox}
        onClose={() => setConfirmDeleteBox(false)}
        usePortal
        onManageDisableScrolling={(componentId, disableScrolling) =>
          dispatch(manageDisableScrolling(componentId, disableScrolling))}
      >
        <div className={css.deleteConfirmBox}>
          <h1>Are you sure you want to delete all documents?</h1>
          <p>You will need to upload all documents again. </p>
          <div className={css.buttonsContainer}>
            <button 
              type='button' 
              className={css.cancelButton}
              onClick={()=> setConfirmDeleteBox(false)} 
            >
              Cancel
            </button>
            <PrimaryButton
              type="button"
              onClick={handleDeleteTemplate}
            >
              Yes
            </PrimaryButton>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default compose(injectIntl)(Zoho)