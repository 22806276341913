import React from 'react';
import { string } from 'prop-types';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import Decimal from 'decimal.js';
import { formatMoney } from '../../util/currency';
import config from '../../config';
import { types as sdkTypes } from '../../util/sdkLoader';
import {
  propTypes,
  LINE_ITEM_CUSTOMER_COMMISSION,
  LINE_ITEM_PROVIDER_COMMISSION,
} from '../../util/types';

import css from './BookingBreakdown.module.css';

const { Money } = sdkTypes;

/**
 * Calculates the total price in sub units for multiple line items.
 */
const HUNDRED_PERCENT = 100; // 100%
const lineItemsTotal = (lineItems, { isFinal, paymentTerms, transaction }) => {
  const paymentFee = transaction?.attributes?.metadata?.proposal?.paymentFee?.amount;
  const amount = lineItems.reduce((total, item) => {
    const lineTotal = isFinal
      ? (paymentFee * (HUNDRED_PERCENT - paymentTerms * HUNDRED_PERCENT)) / 100
      : item?.lineTotal?.amount;
    return total.plus(lineTotal);
  }, new Decimal(0));
  const currency = lineItems[0]?.lineTotal?.currency || config.currency;
  return new Money(amount, currency);
};

/**
 * Checks if line item represents commission
 */
const isCommission = lineItem => {
  return (
    lineItem.code === LINE_ITEM_PROVIDER_COMMISSION ||
    lineItem.code === LINE_ITEM_CUSTOMER_COMMISSION
  );
};

/**
 * Returns non-commission, non-reversal line items
 */
const nonCommissionNonReversalLineItems = transaction => {
  return transaction.attributes.lineItems.filter(item => !isCommission(item) && !item.reversal);
};

/**
 * Checks if a transaction has a commission line-item for
 * a given user role.
 */
const txHasCommission = (transaction, userRole) => {
  let commissionLineItem = null;

  if (userRole === 'customer') {
    commissionLineItem = transaction.attributes.lineItems.find(
      item => item.code === LINE_ITEM_CUSTOMER_COMMISSION
    );
  } else if (userRole === 'provider') {
    commissionLineItem = transaction.attributes.lineItems.find(
      item => item.code === LINE_ITEM_PROVIDER_COMMISSION
    );
  }
  return !!commissionLineItem;
};

const LineItemSubTotalMaybe = props => {
  const { transaction, unitType, userRole, intl, paymentTerms, isFinal } = props;

  const refund = transaction.attributes.lineItems.find(
    item => item.code === unitType && item.reversal
  );

  // Show unit purchase line total (unit price * quantity) as a subtotal.
  // PLEASE NOTE that this assumes that the transaction doesn't have other
  // line item types than the defined unit type (e.g. week, month, year).
  const showSubTotal = txHasCommission(transaction, userRole) || refund;

  // all non-reversal, non-commission line items
  const subTotalLineItems = nonCommissionNonReversalLineItems(transaction).filter(
    item => item?.code !== 'line-item/service-fee'
  );
  // line totals of those line items combined
  const customParams = {
    paymentTerms,
    isFinal,
    transaction,
  };
  const subTotal = lineItemsTotal(subTotalLineItems, customParams);

  const formattedSubTotal = subTotalLineItems.length > 0 ? formatMoney(intl, subTotal) : null;

  return formattedSubTotal ? (
    <>
      {/* <hr className={css.totalDivider} /> */}
      <div className={css.subTotalLineItem}>
        <span className={css.itemLabel}>
          <FormattedMessage id="BookingBreakdown.expertFee"/>
          <span className={css.tooltip}>
            <FormattedMessage 
              id="BookingBreakdown.expertFeeTooltipMessage"
              values={{firstPaymentPercent: paymentTerms*100, secondPaymentPercent: 100-(paymentTerms*100)}}
            />
          </span>
        </span>
        <span className={css.itemValue}>{formattedSubTotal}</span>
      </div>
    </>
  ) : null;
};

LineItemSubTotalMaybe.propTypes = {
  transaction: propTypes.transaction.isRequired,
  userRole: string.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemSubTotalMaybe;
