import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { propTypes, SEARCH_PAGE, USER_ROLE_CLIENT, USER_ROLE_CSM } from '../../util/types';
import { ListingCard, ListingCardClient, PaginationLinks } from '../../components';
import css from './SearchResultsPanel.module.css';
import { checkExpertUnAvailabilityFrom, verifiedAuthorEmailListings } from '../../util/destructorHelpers';
import { handleExpertDetails } from '../../util/api';
import useGetScreenWidth from '../../hooks/useGetScreenWidth'

const SearchResultsPanel = props => {
  const { 
    className, 
    rootClassName, 
    listings, 
    pagination, 
    search, 
    setActiveListing, 
    typePartner, 
    bookmarks, 
    isAuthenticated, 
    handleWishlist, 
    location,
    currentUser
    } = props;
  const urlSearchParams = new URLSearchParams(location.search);
  const isExpertUnavailable = !!urlSearchParams.get('keywords');
  const classes = classNames(rootClassName || css.root, className);
  const expertAvailableListings = checkExpertUnAvailabilityFrom(listings);
  const proposalReceivedCountListings = listings?.filter(l => l?.attributes?.publicData?.proposalReceivedCount) || [];
  const uuidSet = new Set(proposalReceivedCountListings.map(listing => listing.id.uuid));
  const listingsWithoutProposalCounts = listings?.filter(l => !uuidSet.has(l?.id?.uuid)) || [];
  const sortedListings = [
    ...proposalReceivedCountListings,
    ...listingsWithoutProposalCounts
  ];

  const top3Listings = proposalReceivedCountListings.length >= 3 ? proposalReceivedCountListings.slice(0, 3) : [...proposalReceivedCountListings];
  const screenWidth = useGetScreenWidth()
  
  const paginationLinks =
    pagination && pagination.totalPages > 1 ? (
      <PaginationLinks
        className={css.pagination}
        pageName="SearchPage"
        pageSearchParams={search}
        pagination={pagination}
      />
    ) : null;

  // Panel width relative to the viewport
  const panelMediumWidth = 50;
  const panelLargeWidth = 62.5;
  const cardRenderSizes = [
    '(max-width: 767px) 100vw',
    `(max-width: 1023px) ${panelMediumWidth}vw`,
    `(max-width: 1920px) ${panelLargeWidth / 2}vw`,
    `${panelLargeWidth / 3}vw`,
  ].join(', ');

  function handleProfileView(listingId) {
    const param = {
      type: 'profileView',
      clientId: currentUser?.id.uuid,
      expertId: listingId
    }
    handleExpertDetails(param)
  }

  if ([USER_ROLE_CLIENT, USER_ROLE_CSM].includes(typePartner)) {
    return (
      <div className={classes}>
        <div className={css.listingCards}>
          {listings?.length ? listings?.sort((a, b) => b.proposalReceivedCount - a.proposalReceivedCount)?.map(l => {
            return (
              <div onClick={() => handleProfileView(l.id.uuid)} key={l.id.uuid}>
                <ListingCard
                  className={css.listingCard}
                  key={l.id.uuid}
                  listing={l}
                  renderSizes={cardRenderSizes}
                  setActiveListing={setActiveListing}
                  bookmarks={bookmarks}
                  isAuthenticated={isAuthenticated}
                  handleWishlist={handleWishlist}
                  currentPage={SEARCH_PAGE}
                  screenWidth={screenWidth}
                />
              </div>
            )
          }) : null}

          {props.children}
        </div>
        {paginationLinks}
      </div>
    )
  } else {
    return (
      <div className={classes}>
        <div className={css.listingCardsClient}>

          {!!sortedListings?.length && sortedListings.map(l => (
            <ListingCardClient
              className={css.listingCardClient}
              key={l.id.uuid}
              listing={l}
              renderSizes={cardRenderSizes}
              setActiveListing={setActiveListing}
              countWords={208}
              top3Listings={top3Listings}

            />
          ))}
          {props.children}
        </div>
        {paginationLinks}
      </div>
    )
  }
};

SearchResultsPanel.defaultProps = {
  children: null,
  className: null,
  listings: [],
  pagination: null,
  rootClassName: null,
  search: null,
};

const { array, node, object, string } = PropTypes;

SearchResultsPanel.propTypes = {
  children: node,
  className: string,
  listings: array,
  pagination: propTypes.pagination,
  rootClassName: string,
  search: object,
};

export default SearchResultsPanel;