import React from 'react'
import { FormattedMessage } from '../../util/reactIntl'
import { PrimaryButton } from '../Button/Button';
import css from './IntegrationCard.module.css'
import zohoSign from '../../assets/zoho-sign-retina-logo.png';
import boxInc from '../../assets/Box,_Inc._logo.png';
import canva from '../../assets/canva.png';
import googleMeet from '../../assets/google-meet.png';
import zoomLogo from '../../assets/Zoom-Logo.png';
import calendy from '../../assets/calendy.png';
import questionPro from '../../assets/questionpro-logo-color.png';
import leanTime from '../../assets/leantime-logo-big.png';
import tribeLogo from '../../assets/tribe-logo-wordmark-black.png';

const integrationdata = [
    {
        icon: zohoSign,
        heading: "Zoho Sign",
        subheading: "Sign online contracts",
        badgebox: "Legal",
        button: "Connect"
    },
    {
        icon: boxInc,
        heading: "Box",
        subheading: "File sharing and document collaboration",
        badgebox: "Collaboration",
        button: "Connect"
    },
    {
        icon: canva,
        heading: "Canva",
        subheading: "Document creation",
        badgebox: "Collaboration",
        button: "Connect"
    },
    {
        icon: googleMeet,
        heading: "Google Meet",
        subheading: "Video Conferencing",
        badgebox: "Collaboration",
        button: "Connect"
    },
    {
        icon: zoomLogo,
        heading: "Zoom",
        subheading: "Video Conferencing",
        badgebox: "Collaboration",
        button: "Connect"
    },
    {
        icon: calendy,
        heading: "Zoom",
        subheading: "Scheduling & appointment booking",
        badgebox: "Collaboration",
        button: "Connect"
    },
    {
        icon: questionPro,
        heading: "QuestionPro",
        subheading: "Online survey tools",
        badgebox: "Insight Tools",
        button: "Connect"
    },
    {
        icon: leanTime,
        heading: "Leantime",
        subheading: "Agile project management",
        badgebox: "Project Management",
        button: "Connect"
    },
    {
        icon: tribeLogo,
        heading: "Tribe",
        subheading: "Community platform",
        badgebox: "Community",
        button: "Connect"
    }
]
const IntegrationCard = (props) => {

    return (
        <>
            {integrationdata?.map((item, i) =>
                <div className={css.integrationCard} key={i}>
                    <div className={css.cardIcon}>
                        <img src={item.icon} />
                    </div>
                    <div className={css.cardBody}>
                        <div className={css.cardHeading}>{item.heading}</div>
                        <div className={css.cardsubHeading}>{item.subheading}</div>
                        <div className={css.cardBadge}>
                            <span>{item.badgebox}</span>
                        </div>
                        <PrimaryButton
                            className={css.submitButton}
                        >
                            {item.button}
                        </PrimaryButton>
                    </div>
                </div>
            )}
        </>
    )
}

export default IntegrationCard