import React from 'react';
import { string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import css from './SectionBrands.module.css';

import vakilSearch from './img/1.png'
import kantar from './img/2.png'
import uniphore from './img/3.png'
import vnc from './img/4.png'
import brandscapes from './img/5.png'

const SectionBrands = props => {
  const { rootClassName, className } = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <h2 className={css.title}>
        <FormattedMessage id="SectionBrands.title" />
      </h2>
      <div className={css.content}>
        <img className={css.brand} src={vakilSearch} alt='...' />
        <img className={css.brand} src={kantar} alt='...' />
        <img className={css.brand} src={uniphore} alt='...' />
        <img className={css.brand} src={vnc} alt='...' />
        <img className={css.brand} src={brandscapes} alt='...' />
      </div>
    </div>
  );
};

SectionBrands.defaultProps = { rootClassName: null, className: null };

SectionBrands.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionBrands;
