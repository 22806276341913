import React, { useEffect, useRef } from 'react';
import css from './FirmPanel.module.css';
import { FormattedMessage } from '../../util/reactIntl';
import {
  FIRM_INVITE,
  INVITE_STATUS_ACTIVE,
  INVITE_STATUS_PENDING,
  REMOVE_FIRM_MEMBER,
  USER_ROLE_EDITOR,
  USER_ROLE_OWNER,
} from '../../util/types';
import Button from '../Button/Button';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  closeListing,
  sendFirmInvite,
  updateFirmMemberProfile,
  updateMetadata,
} from '../../containers/FirmPage/FirmPage.duck';
import classNames from 'classnames';
import Modal from '../Modal/Modal';
import { manageDisableScrolling } from '../../ducks/UI.duck';
import moment from 'moment';
import downArrowIcon from '../../assets/downArrow.svg'
import { deleteFirmUserDetails } from '../../util/api';
import IconSpinner from '../IconSpinner/IconSpinner';
import { getUserShortName } from '../../util/typeHelpers';

const OrganizationMemberCard = props => {
  const {
    isInviteModalOpen,
    isOwnListing,
    firmData,
    currentAuthor,
    authorName,
    authorEmail,
    currentPath,
    firmName,
    id,
    currentListing,
    ownerNumber,
    handleTransferRole,
    firmTitle,
    userType,
    successToaster,
    setSuccessToaster,
    setToasterMessage,
    isCsm,
    currentUser
  } = props;
  const ref = useRef(null);
  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setShow(null);
    }
  };
  useEffect(() => {
    document?.addEventListener('mousedown', handleClickOutside);
    return () => {
      document?.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  const state = useSelector(state => state);
  const { openingListingInProgress, closingListingInProgress } = state?.FirmPage;
  const authorId = !!currentAuthor?.id && currentAuthor?.id?.uuid;
  const firmStatus = !!currentListing?.id && currentListing?.attributes?.state;

  // const authorEmail = !!currentAuthor?.id && currentAuthor?.attributes?.profile?.publicData?.email;
  const googleDisplayPicture = currentAuthor?.attributes?.profile?.publicData?.picture;

  const dispatch = useDispatch();
  const [toggle, setToggle] = useState(false);
  const [show, setShow] = useState(null);
  const [confirmModal, setConfirmModal] = useState(false);
  const [transferMember, setTransferMember] = useState(null);
  const [romoveModal, setRemoveModal] = useState(false);
  const [closeFirmModal, setCloseFirmModal] = useState(false);
  const [removeMember, setRemoveMember] = useState({});
  const [removeMemberInProgess, setRemoveMemberInProgess] = useState(false);

  useEffect(() => {
    const successTimeout = setTimeout(() => {
      setSuccessToaster(false);
    }, 3000);

    return () => clearTimeout(successTimeout);
  }, [successToaster]);

  const handleToggleState = () => {
    setToggle(prev => !prev);
  };

  const handleRemoveUser = async () => {
    setRemoveMemberInProgess(true);
    const emailParams = {
      authorName,
      authorEmail,
      firmName,
      userEmail: removeMember?.email,
      userName: removeMember?.fullName,
      step:REMOVE_FIRM_MEMBER
    };

    dispatch(updateFirmMemberProfile(removeMember, emailParams));

    const filteredData = firmData?.filter((v, i) => v?.email !== removeMember?.email);
    const response = await dispatch(updateMetadata(filteredData, id));
    const removeFromMongoDb = await deleteFirmUserDetails({ firmId: id.uuid, userId: removeMember?.userId })
    if (response) {
      setToasterMessage(`You have successfully removed ${removeMember?.fullName || removeMember.email?.split('@')[0]}`)
      setSuccessToaster(true);
      setRemoveModal(false);
      setRemoveMemberInProgess(false);

    }
  };

  const handleResendEmail = async (index, email) => {
    const params = {
      userEmail: email,
      authorEmail,
      authorName,
      firmName,
      currentPath,
      smallTeamData,
      step:FIRM_INVITE,
      subject: `You have an invitation from  ${authorName} to join ${firmName}`,
    };

    firmData.forEach(e => {
      if (e.email === email) {
        e.invitationExpiredAt = moment().add(14, 'days').format('YYYY-MM-DD HH:mm:ss');
        e.status = INVITE_STATUS_PENDING;
      }
    })

    await dispatch(sendFirmInvite({ ...params }, firmData, id));
  };

  const handleShow = (fd) => {
    const firmId = firmData.find(i => i?.userId === fd?.userId)?.userId;
    if (firmId) {
      setShow(firmId);
    }
  }

  const handleTransferOwnerShip = (fd) => {
    const userId = firmData.find(i => i?.userId === fd?.userId);
    if (userId) {
      setConfirmModal(true);
      setTransferMember(fd);
      setShow(null);
    }
  }
  // Clone and sort the array to avoid modifying the original
  const sortedFirmData = firmData?.length ? [...firmData].sort((a, b) => b.isAdmin - a.isAdmin) : [];
  // Separate isAdmin objects from non-admin objects
  const admins = Array.isArray(sortedFirmData) ? sortedFirmData.filter((item) => item.isAdmin) : [];
  const nonAdmins = Array.isArray(sortedFirmData) ? sortedFirmData.filter((item) => !item.isAdmin) : [];


  // Concatenate non-admin objects after admins
  const combinedData = admins?.concat(nonAdmins);
  const csmTooltipText = firmName 
    ? `This user is a Customer Success Manager working on behalf of ${firmName} on the InsightGig platform.` 
    : 'This user is a Customer Success Manager (CSM) on the InsightGig platform. CSMs work on behalf of their clients.'

  // Define a function to check if the user can remove a member
  const canRemoveMember = (fd) => {
    return ((isOwnListing && currentAuthor?.id?.uuid !== fd?.userId ) || (isCsm && !fd?.isAdmin));
  };

  // Define a function to check if the invite can be resent
  const canResendInvite = (fd) => {
    return isOwnListing && fd?.status && fd.status !== INVITE_STATUS_ACTIVE;
  };

  return (
    <div className={css.organizationContainer}>
      <div className={css.headerSection}>
        <span className={!toggle ? css.activeView : null}>
          <FormattedMessage id="OrganizationMemberCard.tiles" />
        </span>
        <div className={css.checkboxWrapper} onClick={handleToggleState}>
          <div className={classNames(css.ball, toggle && css.toggled)}></div>
        </div>
        <span className={toggle ? css.activeView : null}>
          <FormattedMessage id="OrganizationMemberCard.table" />
        </span>
      </div>
      <div>
        {toggle ?
          <div className={css.firmTableBox}>
            <table>
              <thead className={css.tableHeading}>
                <tr>
                  <th>
                    <FormattedMessage id="OrganizationMemberCard.userName" />
                  </th>
                  <th style={{ textAlign: 'center' }}>
                    <FormattedMessage id="OrganizationMemberCard.userEmail" />
                  </th>
                  <th style={{ textAlign: 'center' }}>
                    <FormattedMessage id="OrganizationMemberCard.userRole" />
                  </th>
                  <th style={{ textAlign: 'center' }}>
                    <FormattedMessage id="OrganizationMemberCard.userStatus" />
                  </th>
                  {isOwnListing &&
                    <>
                      <th className={css.removeButton} colSpan="2">
                        <FormattedMessage id="OrganizationMemberCard.removeUser" />
                      </th>
                    </>
                  }
                </tr>
              </thead>
              <tbody className={css.tableBodyBox}>
                {Array.isArray(firmData) && combinedData?.map((fd, i) => {
                  if ((isOwnListing || isCsm) ? true : fd.status === INVITE_STATUS_ACTIVE) {
                    const isCsm = fd?.isCsm;
                    return (
                      <tr key={i}>
                        <td >
                          {fd?.profileImage ? (
                            <span className={classNames(isCsm && css.csmImageBoxCard, css.rowBox)} data-tooltip={csmTooltipText}>
                              <div className={css.userNameSection}>
                                <img
                                  className={css.profileImage}
                                  src={fd?.profileImage}
                                  alt={'profileImage'}
                                />
                              </div>
                              <span className={css.authorName}>{fd?.fullName}</span>
                            </span>
                          ) : (
                            <span className={classNames(isCsm && css.csmImageBoxCard, css.rowBox)} data-tooltip={csmTooltipText}>
                              <div className={css.userNameSection}>
                                <div className={css.profileImage}>
                                  {getUserShortName(fd?.fullName, fd?.email)}
                                </div>
                              </div>
                              <span className={css.authorName}>{fd?.fullName}</span>
                            </span>
                          )}
                        </td>
                        <td className={css.authorEmail}>{fd?.email}</td>
                        <td className={css.userRoleTeam}>
                          {isOwnListing ?
                            <>
                              <span className={css.roleBox} onClick={() => handleShow(fd)}>
                                {fd?.isAdmin ? USER_ROLE_OWNER : USER_ROLE_EDITOR}
                                <img src={downArrowIcon} />
                                {(show === fd?.userId && currentAuthor?.id?.uuid !== fd?.userId) &&
                                  <ul>
                                    {fd?.status === INVITE_STATUS_ACTIVE &&
                                      <li ref={ref} key={fd?.userId} onClick={() => handleTransferOwnerShip(fd)}>
                                        <FormattedMessage id="OrganizationMemberCard.transferOwnershipHeading" />
                                      </li>
                                    }
                                  </ul>
                                }
                              </span>
                            </>
                            : <span className={fd?.isAdmin ? css.csm : css.userRoleEditor}>{fd?.isAdmin ? USER_ROLE_OWNER : USER_ROLE_EDITOR}</span>
                          }
                        </td>
                        <td className={css.userStatus}>{fd?.status}</td>
                        {canResendInvite(fd) ? (
                          <td className={css.removeButton}>
                            <Button
                              className={css.resendEmailButton}
                              onClick={e => handleResendEmail(i, fd?.email)}
                            >
                              <FormattedMessage id="OrganizationMemberCard.resendButton" />
                            </Button>
                          </td>
                        ) : null}
                        {canRemoveMember(fd) ? (
                          <td className={css.removeButton}>
                            <span
                              onClick={() => {
                                setRemoveModal(true)
                                setRemoveMember(fd)
                              }}
                            >
                              <FormattedMessage id="OrganizationMemberCard.removeButton" />
                              {/* <IconCard brand="trash" /> */}
                            </span>
                          </td>
                        ) : null}
                      </tr>
                    )
                  }
                })}
              </tbody>
            </table>
          </div>
          :
          <div className={css.cardViewBox}>
            {Array.isArray(combinedData) && combinedData?.map((fd, i) => {
              if ((isOwnListing || isCsm) ? true : fd.status === INVITE_STATUS_ACTIVE) {
                const isCsm = fd?.isCsm;
                return (
                  <div className={css.firmCard} key={i}>
                    <div className={css.cardTop}>
                      <span>
                        {fd?.profileImage ? (
                          <>
                            <div className={classNames(isCsm && css.csmImageBox, css.userNameSectionBox)}  data-tooltip={csmTooltipText}>
                              <img
                                className={css.profileImage}
                                src={fd?.profileImage}
                                alt={'profileImage'}
                              />
                            </div>
                            <div className={css.authorName}>{fd?.fullName}</div>
                          </>
                        ) : (
                          <div className={css.userNameSectionBox}>
                            <div className={classNames(isCsm && css.csmImageBox, css.profileImage)} data-tooltip={csmTooltipText}>
                              {getUserShortName(fd?.fullName, fd?.email)}
                            </div>
                            <div className={css.authorName}>{fd?.fullName}</div>
                          </div>
                        )}
                      </span>
                      {/* <div className={css.authorName}>{authorName}</div> */}
                      <div className={css.projectTitle}>
                        <FormattedMessage id="OrganizationMemberCard.firmTitle" values={{ firmTitle }} />
                      </div>
                      <div className={css.authorEmail}>{fd?.email}</div>
                      <div className={isOwnListing ? css.userRoleMember : css.userRoleBox}>
                        {isOwnListing ?
                          <div className={css.boxSelect}>
                            <div onClick={() => handleShow(fd)} className={css.userRoleSelect}>
                              {fd?.isAdmin ? USER_ROLE_OWNER : USER_ROLE_EDITOR}
                              {/* <FormattedMessage id="OrganizationMemberCard.teamMemberListHeading" /> */}
                              <span className={css.arrowIcon}>
                                <img src={downArrowIcon} />
                              </span>
                            </div>
                            {show === fd?.userId && currentAuthor?.id?.uuid !== fd?.userId ?
                              <ul>
                                {fd?.status === INVITE_STATUS_ACTIVE &&
                                  <li ref={ref} key={fd?.userId} onClick={() => handleTransferOwnerShip(fd)}>
                                    <FormattedMessage id="OrganizationMemberCard.transferOwnershipHeading" />
                                  </li>
                                }
                              </ul>
                              : null}
                          </div>
                          : <span className={fd?.isAdmin ? css.csm : css.userRoleEditor}>{fd?.isAdmin ? USER_ROLE_OWNER : USER_ROLE_EDITOR}</span>
                        }
                      </div>
                      <div className={css.userStatus}>{fd?.status}</div>
                    </div>
                    <div className={css.userCardBoxBottom}>
                      {canResendInvite(fd) ?
                        <span
                          className={css.resentInvite}
                          onClick={e => handleResendEmail(i, fd?.email)}
                        >
                          <FormattedMessage id="OrganizationMemberCard.resendInvite" />
                        </span> : <span></span>
                      }
                      {canRemoveMember(fd) ? (
                        <span
                          className={css.removeCard}
                          onClick={() => {
                            setRemoveModal(true)
                            setRemoveMember(fd)
                          }}
                        >
                          <FormattedMessage id="OrganizationMemberCard.removeUserButton" />
                        </span>
                      ) : null}
                    </div>
                  </div>
                )
              }
            }

            )}
            {isOwnListing || isCsm ?
              <div onClick={isInviteModalOpen} className={css.inviteFirmCard} >
                <FormattedMessage id="OrganizationMemberCard.inviteButton" />
              </div>
              : null}
          </div>}
        <div className={css.borderLine} />
        <div className={css.closeFirmButton}>
          {/* {isOwnListing && firmStatus === LISTING_STATE_PUBLISHED ?
            <Button onClick={() => setCloseFirmModal(true)} className={css.closeListing}>
              <FormattedMessage id="OrganizationMemberCard.closeFirm" />
            </Button> :
            null
          } */}
          { (isOwnListing || isCsm) && toggle ?
            <Button onClick={isInviteModalOpen} className={classNames(css.inviteButton)}>
              <FormattedMessage id="OrganizationMemberCard.tableInviteButton" />
            </Button> : null
          }
        </div>
      </div>
      <Modal
        id="confirmtransfermodal"
        className={css.disableModalBorder}
        contentClassName={css.containerClassName}
        isOpen={confirmModal}
        onClose={() => setConfirmModal(false)}
        usePortal
        onManageDisableScrolling={(componentId, disableScrolling) =>
          dispatch(manageDisableScrolling(componentId, disableScrolling))
        }
      >
        <div className={css.confirmModalBox}>
          <h2>
            <FormattedMessage id="OrganizationMemberCard.transferOwnershipConfirmationMessage"
              values={{ userName: transferMember?.fullName }}
            />
          </h2>
          <div className={css.bottomButton}>
            <button className={css.cancelButton} onClick={() => setConfirmModal(false)}>
              <FormattedMessage id="OrganizationMemberCard.cancelButton" />
            </button>
            <button className={css.confirmButton} onClick={() => handleTransferRole(
              transferMember,
              setConfirmModal,
            )}>
              <FormattedMessage id="OrganizationMemberCard.acceptButton" />
            </button>
          </div>

        </div>
      </Modal>
      <Modal
        id="removeMemberModal"
        className={css.disableModalBorder}
        contentClassName={css.containerClassName}
        isOpen={romoveModal}
        onClose={() => setRemoveModal(false)}
        usePortal
        onManageDisableScrolling={(componentId, disableScrolling) =>
          dispatch(manageDisableScrolling(componentId, disableScrolling))
        }
      >
        <div className={css.confirmModalBox}>
          <h2>
            <FormattedMessage id="OrganizationMemberCard.removeMember"
              values={{ fullName: removeMember?.fullName || removeMember.email?.split('@')[0] }}
            />
          </h2>
          <div className={css.bottomButton}>
            <button className={css.cancelButton} onClick={() => setRemoveModal(false)}>
              <FormattedMessage id="OrganizationMemberCard.cancelButton" />
            </button>
            <button className={css.confirmButton} onClick={() => handleRemoveUser()}>
              {removeMemberInProgess ? <IconSpinner /> : <FormattedMessage id="OrganizationMemberCard.acceptButton" />}
            </button>
          </div>
        </div>
      </Modal>
      <Modal
        id="closefirmname"
        className={css.disableModalBorder}
        contentClassName={css.containerClassName}
        isOpen={closeFirmModal}
        onClose={() => setCloseFirmModal(false)}
        usePortal
        onManageDisableScrolling={(componentId, disableScrolling) =>
          dispatch(manageDisableScrolling(componentId, disableScrolling))
        }
      >
        <div className={css.confirmModalBox}>
          <h2>
            <FormattedMessage id="OrganizationMemberCard.closeFirmConfirmationMessage"
              values={{ firmTitle: firmTitle }}
            />
          </h2>
          <div className={css.bottomButton}>
            <button className={css.cancelButton} onClick={() => setCloseFirmModal(false)}>
              <FormattedMessage id="OrganizationMemberCard.cancelButton" />
            </button>
            <button className={css.confirmButton} onClick={() => handleCloseFirmListing()}>
              <FormattedMessage id="OrganizationMemberCard.acceptButton" />
            </button>
          </div>
        </div>
      </Modal>

    </div>
  );
};

export default OrganizationMemberCard;
