import React from 'react';
import { node, string } from 'prop-types';
import classNames from 'classnames';

import css from './EditListingProgress.module.css';

const EditListingProgressComponent = props => {
	const { rootClassName, className, progressCount, value } = props;

	const completedTabsCount = progressCount / 10;
	const dummyArray = Array(10).fill(0)
	const progressArray = dummyArray.fill(1, 0, completedTabsCount);

	const classes = classNames(rootClassName || css.root, className);
	const LayoutMobile = typeof window !== 'undefined' && window.innerWidth < 768;

	return (
		<>
			{LayoutMobile ?
				<div className={css.progressBoxWrapper}>
					<progress
						className={css.progressBarMain}
						id="plannerProfileSteps"
						max="100"
						value={value}
					>
					</progress>
					<div className={css.progressLinks}>
						<span>{progressCount}%</span>
					</div>
				</div> :
				<div className={css.progressBox}>
					{progressArray.map((value, index) => {
						return (
							<div className={!value ? css.unFill : css.fill} key={index}></div>
						)
					})}
					<div className={css.progressLinks}>
						<span>{Math.floor(progressCount)}% complete</span>
					</div>
				</div>
			}
		</>
	);
};

EditListingProgressComponent.defaultProps = {
	className: null,
	rootClassName: null,
};

EditListingProgressComponent.propTypes = {
	className: string,
	rootClassName: string,
};

export default EditListingProgressComponent;
