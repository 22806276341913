import React, { useEffect, useState, forwardRef } from 'react';
import { func } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm , FormSpy} from 'react-final-form';
// These relative imports need to point to correct directories
import {
  intlShape,
  injectIntl,
  FormattedMessage,
} from '../../util/reactIntl';
import { Form, FieldTextInput, PrimaryButton, SecondaryButton, FieldSelect, Modal, IconCard, IconSpinner } from '../../components';

// as a template.
import css from './OpenAIQuestionnaireForm.module.css';
import classNames from 'classnames';
import EditQuestionnaireModal from './EditQuestionnaireModal';
import OptionMenu from './OptionMenu';
import { useParams } from 'react-router-dom';
import { fetchQueryReportData, updateOpenAIData } from '../../util/api';
import { saveAs } from 'file-saver';
import { handleConvertHTMLtoPdf, handleConvertHTMLtoDoc } from '../../components/OpenAIAppsPanel/gigAppsHelperFunction';
import { composeValidators, required } from '../../util/validators';

const questionType = [
  {key: 'closeEnded', value: 'Close-ended questions only'},
  {key: 'closeEndedQuestionsWithFewOpenEndedOnes', value: 'Close-ended questions with a few open-ended ones'},
]

const applicationTypes = {
  pdf: "application/pdf",
  docx: "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
}

export const OpenAIQuestionnaireForm = props => {
  return (
    <FinalForm
      {...props}
      render={formRenderProps => {
        const {
          disabled,
          handleSubmit,
          intl,
          pristine,
          updated,
          formId,
          updateError,
          values,
          form,
          openaiRequest,
          initialText,
          handleRedirectToGigAppsPage,
          onManageDisableScrolling,
          responseModal,
          setResponseModal,
          appRoute,
          email
        } = formRenderProps;

        // const [step,setStep] = useState(1);
        const setStep = (step)=>{
          form.change("step",step)
        }

        const params = useParams()
        const [response,setResponse ] = useState()
        const [loading,setLoading] = useState([])
        const [showModal,setShowModal] = useState({show:false,name:"addSection",sectionIndex:0,questionIndex:0,addSectionIndex:0,addQuestionIndex:0})
        const [drag,setDrag ] = useState(false)
        
        const submitDisabled = (values.step===1 && !values.research) ||  (values.step===2 && !values.audience) || (values.step===3 && (!values.questionType));

        const researchPlaceholder = intl.formatMessage({ id: 'OpenAIQuestionnaireForm.researchPlaceholder' });
        const audiencePlaceholder = intl.formatMessage({ id: 'OpenAIQuestionnaireForm.audiencePlaceholder' });
        const questionWordingPlaceholder = intl.formatMessage({ id: 'OpenAIQuestionnaireForm.questionWordingPlaceholder' });
        const questionLengthPlaceholder = intl.formatMessage({ id: 'OpenAIQuestionnaireForm.questionLengthPlaceholder' });

        const handleClose = ()=>handleRedirectToGigAppsPage();

        const handleSave = (data)=>{
          setResponse(data);
          updateOpenAIData({
            type: appRoute,
            email: email,
            id: params.id,
            data: data,
          })
          setShowModal({show:false});
        }

        const Loader = ({ type, id }) => {
          if (loading===type) {
            return <IconSpinner strokeColor='orange' />
          }
          else {
            return <FormattedMessage id={id} />
          }
        }

        useEffect(() => {
          if(initialText){
            form.reset();
          }
        }, [initialText])

        async function fetchData(){
          const response = await fetchQueryReportData({
            type: appRoute,
            email: email,
            id: params.id
          });
          if(response?.data){
            setResponse(response.data.data);
          }
        }

        useEffect(()=>{
          if(params.id){
            form.change('step',4)
            fetchData();
          }
        },[params])

        const inputTypes = {
          "Single choice" : "radio",
          "Multiple choice": "checkbox",
          "Close-ended questions with a few open-ended ones" : '"text"',
        }

        const optionsDiv = (item)=>{
          if(item.questionType==="Single choice" || item.questionType==="Multiple choice"){
            return(
              <>
              {item?.options?.map((ele,i)=>(
                <div key={i} className={css.answersContainer} style={{display:"flex", lineHeight:"24px"}}>
                    <p className={css.introductionText} style={{fontWeight:"200",margin:"0",fontSize:"14px", display:"flex"}}><input className={css.radioInput} style={{width:"auto",marginRight:"10px"}} type={inputTypes[item.questionType]} checked={false} onChange={(e)=>e.preventDefault()}/>{ele}{(ele.toLowerCase()==="others" || ele.toLowerCase()==="other" || ele.toLowerCase()==="other (please specify)") && <input style={{border:"none",marginLeft:"10px", width:"auto",borderBottomStyle:"solid",borderBottomWidth:"2px", height:"24px"}} readOnly className={classNames(css.textInput,'replace')} type="text"/>}</p>
                </div>
              ))}
              </>
            )
          }else if(item.questionType==="Open-ended"){
            return(
              <div className={css.answersContainer} style={{display:"flex"}}>
                <input className={classNames(css.textInput,'placeHolderRemove')} style={{border:"none",width:"100%",borderBottomStyle:"solid",borderBottomWidth:"2px"}} checked={false} onChange={(e)=>e.preventDefault()} type={inputTypes[item.questionType]} />
              </div>
            )
          }else if(item.questionType==="Table"){
            return(
              <div>
                <table className={css.table} style={{fontSize:"14px"}}>
                  <thead>
                    <tr className={css.tableHeadings} style={{textAlign:"center", fontWeight:"600"}}>
                      <td></td>
                      {item?.columnHeadings && item?.columnHeadings?.map((col,colIndex)=>(
                        <td key={colIndex}>{col}</td>
                        ))}
                    </tr>
                  </thead>
                  <tbody>
                    {item?.rowHeadings && item?.rowHeadings?.map((row,rowIndex)=>(
                      <tr key={rowIndex} >
                        <td>{row}</td>
                        {item?.columnHeadings && item?.columnHeadings?.map((col,colIndex)=><td style={{textAlign:"center"}} key={colIndex}><input type="radio" readOnly/></td>)}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )
          }
        }

        const removeUnwantedElements = (type) => {
          let parent = document.getElementById('test');
          let clonedParent = parent.cloneNode(true);
        
          const elementsToRemove = clonedParent.querySelectorAll(".remove, input[placeholder]");
          elementsToRemove.forEach((element) => {
            if (element.tagName === "DIV") {
              const children = Array.from(element.childNodes).map((node) =>
                node.cloneNode(true)
              );
              element.replaceWith(...children);
            } else if (element.tagName === "INPUT") {
              element.removeAttribute("placeholder");
              element.style.borderColor = "gray";
              element.style.borderWidth = "1px";
            } else {
              element.remove();
            }
          });
          const divsToRemove = clonedParent.querySelectorAll(".removediv");
          divsToRemove.forEach(div => {
            div.parentNode.removeChild(div);
          });
          if(type==="docx"){
            const inputsToReplace = clonedParent.querySelectorAll("input");
            inputsToReplace.forEach((element)=>{
              if(element.type==="radio"){
                const replacement = document.createElement('span');
                replacement.textContent = "   ( )  ";
                element.replaceWith(replacement);
              }if(element.type==="checkbox"){
                const replacement = document.createElement('span');
                replacement.textContent = "   [ ]  ";
                element.replaceWith(replacement);
              }if(element.type==="text"){
                if (element.classList.contains('replace')) {
                  const replacement = document.createElement('span');
                  replacement.textContent = "  __________________";
                  element.replaceWith(replacement);
                }else{
                  const replacement = document.createElement('p');
                  replacement.textContent = "_____________________________________________________________________________";
                  element.replaceWith(replacement);
                }
              }
            })
            return clonedParent;
          }
          return clonedParent;
        }

        const getQuestionNumber = (index, i) => {
          let number = i + 1;
          for (let n = 0; n < index; n++) {
              number += response.sections[n].questions.length;
          }
          return number;
        };

        const handleDragStart = (e, index) => {
          e.dataTransfer.effectAllowed = 'move';
          e.dataTransfer.setData('index', index)
          const dragElement = e.target.firstChild;
          dragElement.style = 'cursor: grabbing';
        }
        
        const handleSectionDrop = (e, dropIndex) => {
          e.preventDefault();
          if(!drag){
            const dragIndex = e.dataTransfer.getData('index');
            const clonedData = {...response};
            const clonedSection = clonedData.sections.splice(dragIndex,1)[0];
            clonedData.sections.splice(dropIndex,0,clonedSection);
            setResponse(clonedData)
          }
        };
        
        const handleQuestionDrag = (e,sectionIndex,questionIndex)=>{
          setDrag(true)
          e.dataTransfer.effectAllowed="move";
          const data = {
            dragSectionIndex: sectionIndex,
            dragQuestionIndex: questionIndex
          };
          e.dataTransfer.setData('data', JSON.stringify(data));
          const dragElement = e.target.firstChild;
          dragElement.style = 'cursor: grabbing';
        }
        
        const handleQuestionDrop = (e,sectionIndex,questionIndex)=>{
          e.preventDefault();
          if(drag){
            const {dragSectionIndex,dragQuestionIndex} =  JSON.parse(e.dataTransfer.getData('data'))
            const clonedData = {...response};
            const clonedQuestion = clonedData.sections[dragSectionIndex].questions.splice(dragQuestionIndex,1)[0];
            clonedData.sections[sectionIndex].questions.splice(questionIndex,0,clonedQuestion);
            setResponse(clonedData);
            setDrag(false)
          }
        }        

        const handleDragEnd = (e)=>{
          e.preventDefault()
          setDrag(false)
        }

        const handleDocDownload=async(type,fetchData)=>{
          setLoading(type)
          let content = removeUnwantedElements(type).innerHTML
          const response = await fetchData({content:content});
          const buffer = Buffer.from(response.data.data);
          const blob = new Blob([buffer], { type: applicationTypes[type] } );
          const date = new Date().valueOf();
          saveAs(blob,`GigAppsResponse-${date}.${type}`)
          setLoading(false)
        }

        const researchRequiredMessage = intl.formatMessage({
          id: 'OpenAIQuestionnaireForm.researchRequiredMessage',
        });
        const targetAudienceRequiredMessage = intl.formatMessage({
          id: 'OpenAIQuestionnaireForm.targetAudienceRequiredMessage',
        });
        const questionTypeRequiredMessage = intl.formatMessage({
          id: 'OpenAIQuestionnaireForm.questionTypeRequiredMessage',
        });

        return (
          <>
          <Form className={classNames(css.questionnaireForm,(values.step===4 && !response) && css.loadingdiv)} onSubmit={handleSubmit}>
            <div className={values.step===4 ? css.responseContainer : css.container}>
              <div className={css.closeButton} onClick={handleClose}>
                <span><FormattedMessage id="SelectedExpertsTab.close" /></span>
                <IconCard brand="crossSign" />
              </div>
              {values.step!==4 &&
                <>
                  <div className={css.progressBarContainer}>
                    <div className={css.progressBarBorder}>
                      <div className={css.progressBarFill} style={{width:`${values.step*33.3}%`}}>
                      </div>
                    </div>
                  </div>
                  <div className={css.briefGenerator}>
                    <span className={css.generatorTitle}><FormattedMessage id='OpenAIQuestionnaireForm.heading' /></span>
                  </div>
                  <div className={css.stepsContainer}>
                    <h3 className={css.steps}>Step {values.step} of 3</h3>
                  </div>
                </>
              }
              {values.step===1 &&
                <div className={css.row}>
                  <label><FormattedMessage id='OpenAIQuestionnaireForm.researchLabel' /></label>
                  <FieldTextInput
                    className={css.inputBox}
                    type="textarea"
                    id={'research'}
                    name="research"
                    placeholder={researchPlaceholder}
                    validate={composeValidators(required(researchRequiredMessage))}
                    vibrate={true}
                  />
                </div>
              }
              {values.step===2 &&
                <div className={css.row}>
                  <label><FormattedMessage id='OpenAIQuestionnaireForm.audienceLabel' /></label>
                  <FieldTextInput
                    className={css.inputBox}
                    type="input"
                    id={'audience'}
                    name="audience"
                    placeholder={audiencePlaceholder}
                    validate={composeValidators(required(targetAudienceRequiredMessage))}
                    vibrate={true}
                  />
                </div>
              }
              {values.step===3 &&
                <>
                  <div className={css.row}>
                    <label><FormattedMessage id='OpenAIQuestionnaireForm.questionTypeLabel' /></label>
                    <FieldSelect
                      id={'questionType'}
                      name={'questionType'}
                      className={css.fieldSelector}
                      validate={composeValidators(required(questionTypeRequiredMessage))}
                      vibrate={true}
                    >
                      <option value="" disabled={true}>{'Select option'}</option>
                      {questionType.map(({key, value}) => (
                        <option key={key} value={key}>{value}</option>
                      ))}
                    </FieldSelect>
                  </div>
                  <div className={css.row}>
                    <label><FormattedMessage id='OpenAIQuestionnaireForm.questionWordingLabel' /></label>
                    <FieldTextInput
                      className={css.inputBox}
                      type="input"
                      id={'questionWording'}
                      name="questionWording"
                      placeholder={questionWordingPlaceholder}
                    />
                  </div>
                  <div className={css.row}>
                    <label><FormattedMessage id='OpenAIQuestionnaireForm.questionLengthLabel' /></label>
                    <FieldTextInput
                      className={css.inputBox}
                      type="text"
                      id={'questionLength'}
                      name="questionLength"
                      placeholder={questionLengthPlaceholder}
                    />
                  </div>
                </>
              }
              {values.step!==4 && 
                <div className={css.actionButtons}>
                  <SecondaryButton 
                    type='button'
                    className={css.resetButton} 
                    onClick={()=>{
                      form.reset()
                      setStep(1)
                    }}
                    disabled={openaiRequest}
                    >
                    <FormattedMessage id='OpenAIContractForm.resetButtonText' />
                  </SecondaryButton>
                  <PrimaryButton
                    type='submit' 
                    className={classNames(submitDisabled ? css.disabledSubmitButton : css.submitButton )}
                    onClick={(e)=>{
                      if(!submitDisabled && ((values.step===1)||(values.step===2))){
                        e.preventDefault()
                        setStep(values.step+1)
                      }
                    }}
                    inProgress={openaiRequest}
                    >
                    <FormattedMessage id={(values.step===1 || values.step===2) ? 'OpenAIResearchInstrumentForm.nextButtonText' : 'OpenAIContractForm.submitButtonText'} />
                  </PrimaryButton>
                </div>
              }
              {values.step === 4 && (!response ? (
                <div className={css.loadingdivContainer}>
                  ...Loading
                </div>
              )  : (
                <div className={css.transparentBackground}>
                  <div className={classNames(css.briefGenerator)}>
                    <span className={classNames(css.generatorTitle,"remove")}><FormattedMessage id='OpenAIQuestionnaireForm.editorHeading' /></span>
                    <div className={css.exportButtons}>
                      <button 
                        disabled={loading === "docx"} 
                        onClick={(e)=> handleDocDownload("docx", handleConvertHTMLtoDoc)}
                      >
                        <Loader type='docx' id="TextEditor.exportDocButton" />
                      </button>
                      <button 
                        disabled={loading === "pdf"}
                        onClick={(e)=> handleDocDownload("pdf", handleConvertHTMLtoPdf)}
                      >
                        <Loader type='pdf' id="TextEditor.exportPdfButton" />
                      </button> 
                    </div>
                  </div>
                  <div id="test">
                    <div>
                      <label className="remove"><FormattedMessage id='OpenAIQuestionnaireForm.title' /></label>
                      <div style={{marginTop:"24px",pageBreakInside:'avoid'}}>
                        <div className={classNames(css.textContainer,'remove')} style={{backgroundColor:"#fff",border:"1px solid #E6E6E6", padding: "20px 30px", borderRadius:"4px", marginTop:"24px"}}>
                          <h3 style={{fontWeight:"600",margin:'0',position:'relative'}}>{response.title}</h3>
                          <OptionMenu className="removediv" response={response} setShowModal={setShowModal} name="titleMenu"/>
                        </div>
                      </div>
                    </div>
                    {response.subtitle && <div className={css.fieldTopMargin}>
                      <label style={{marginTop:'24px'}} className="remove"><FormattedMessage id='OpenAIQuestionnaireForm.introduction' /></label>
                      <div style={{marginTop:"24px",pageBreakInside:'avoid'}}>
                        <div className={classNames(css.textContainer,'remove')}  style={{backgroundColor:"#fff",border:"1px solid #E6E6E6", padding: "20px 30px", borderRadius:"4px", marginTop:"24px"}}>
                          <p className={css.introductionText} style={{fontWeight:"200",margin:"0",fontSize:"14px"}}>{response.subtitle}</p>
                        </div>
                      </div>
                    </div>}
                    <hr className={classNames(css.horizontalRule,"remove")}/>
                    <div>
                      {response?.sections?.map((item,index) => (
                        <div key={index} draggable={true} className={css.sectionDragComponent} style={{backgroundColor:"#f8f8f8"}}
                          onDragStart={(e) => handleDragStart(e, index)}
                          onDragOver={(e) => e.preventDefault()}
                          // onDragEnter={e => handleSectionDragEnter(e)}
                          // onDragLeave={e => handleSectionDragLeave(e)}
                          onDrop={(e) => handleSectionDrop(e, index)}
                        >
                          <div className={classNames(css.left,'removediv')}>
                            <span className={css.dragDots} >
                              <IconCard brand="dragDots" />
                            </span>
                          </div>
                          <div>
                            <h3 style={{fontWeight:"600",margin:'48px 0 0 0'}} className="remove">Section {index+1}</h3>
                            <div style={{marginTop:"24px",pageBreakInside:'avoid'}}>
                              <div className={classNames(css.textContainer,'remove')} style={{pageBreakInside:'avoid',backgroundColor:"#fff",border:"1px solid #E6E6E6", padding: "20px 30px", borderRadius:"4px", marginTop:"24px"}}>
                                <h3 style={{fontWeight:"600",margin:'0'}}>{item.sectionTitle}</h3>
                                <OptionMenu className="removediv" response={response} setShowModal={setShowModal} name="sectionMenu" sectionIndex={index}/>
                              </div>
                            </div>
                            {item.questions.map((ele,i)=>(
                              <div key={i} draggable={true} className={css.questionDragComponent}
                                onDragStart={(e)=>handleQuestionDrag(e,index,i)}
                                onDragOver={(e) => e.preventDefault()}
                                // onDragEnter={e => handleQuestionDragEnter(e)}
                                // onDragLeave={e => handleQuestionDragLeave(e)}
                                onDrop={(e)=>handleQuestionDrop(e,index,i)}
                                onDragEnd={(e)=>handleDragEnd(e)}
                              >
                                <div
                                  className={classNames(css.questionleft, "removediv")}
                                >
                                  <span className={css.dragDots}>
                                    <IconCard brand="dragDots" />
                                  </span>
                                </div>
                                <div className={css.menuContainer} >
                                  <div style={{marginTop:"24px",pageBreakInside:'avoid'}}>
                                    <div className={classNames(css.questionAnswerContainer,'remove')} style={{pageBreakInside:'avoid',backgroundColor:"#fff",border:"1px solid #E6E6E6",padding:"20px 30px",borderRadius:"4px",marginTop:"24px"}} key={i}>
                                      <div className={(css.questionContainer)} style={{display:"flex",gap:"4px",marginBottom:"8px"}}>
                                        <p className={css.questionText} style={{margin:"0",fontSize:"14px"}}>{getQuestionNumber(index,i)}.{"  " + ele.question}</p>
                                      </div>
                                      {optionsDiv(ele)}
                                    </div>
                                  </div>
                                  <OptionMenu className="removediv" response={response} setShowModal={setShowModal} name="questionMenu" sectionIndex={index} questionIndex={i}/>
                                </div>
                              </div>
                            ))}
                            <hr className={classNames(css.horizontalRule,"remove")}/>
                          </div>
                        </div>
                      ))}
                    </div>
                    <div className={css.fieldTopMargin}>
                      <label className="remove"><FormattedMessage id='OpenAIQuestionnaireForm.end' /></label>
                      <div style={{marginTop:"24px"}}>
                        <div className={classNames(css.textContainer,'remove')} style={{pageBreakInside:'avoid',backgroundColor:"#fff",border:"1px solid #E6E6E6", padding: "20px 30px", borderRadius:"4px", marginTop:"24px"}}>
                          <h3 style={{fontWeight:"600",margin:'0'}}>{response.endSectionTitle}</h3>
                          <p className={css.introductionText}>{response.endSectionSubTitle}</p>
                          <OptionMenu className="removediv" response={response} name="endSectionMenu" setShowModal={setShowModal}/>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </Form>
          <Modal
            id={`OpenAIQuestionnaireForm.editResponse`}
            isOpen={showModal.show}
            onManageDisableScrolling={onManageDisableScrolling}
            onClose={()=>setShowModal({show:false})}
          >
            {showModal.show && (
              <EditQuestionnaireModal 
                inputTypes={inputTypes} 
                response={response} 
                data={showModal} 
                closeModal={() => setShowModal({show:false})} 
                handleSave={handleSave} 
                intl={intl}
              />
            )}
          </Modal>
          <Modal
            id="openSuccessModal"
            className={css.disableModalBorder}
            contentClassName={css.containerClassName}
            isOpen={responseModal}
            removeCloseButton={true}
            onClose={() => {
              setResponseModal(false)
              handleRedirectToGigAppsPage()
            }}
            usePortal
            onManageDisableScrolling={onManageDisableScrolling}
          >
            <div className={css.alertModal}>
              <h2>
                <FormattedMessage id="OpenAiAppsPanel.successModalHeading" />
              </h2>
              <p><FormattedMessage id='OpenAIQuestionnaireForm.successModalContent' /></p>
              <PrimaryButton
                type='button'
                className={css.closeButton}
                onClick={() => {
                  setResponseModal(false)
                  handleRedirectToGigAppsPage()
                }}
                >
                <FormattedMessage id='OpenAIContentAnalysisForm.closeButtonLabel' />
              </PrimaryButton>
            </div>
          </Modal>
          </>
        );
      }}
    />
  )
}


OpenAIQuestionnaireForm.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  //   updateInProgress: bool.isRequired,
};

export default compose(injectIntl)(OpenAIQuestionnaireForm);