import React, { useEffect, useState } from 'react'
import css from './ZoomMeet.module.css';
// import { ZoomMtg } from '@zoomus/websdk';
// import './bootstrap.css';
// import './react-select.css';
import moment from 'moment';
import { useHistory } from 'react-router-dom'
import { getDefaultTimeZoneOnBrowser } from '../../util/dates';
import { ZoomMeetForm } from '../../forms';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import { GOOGLE_MEET_REDIRECT_PAGE, LISTING_PAGE, TRANSACTION_DETAILS_PAGE, ZOOM_MEETING_FORM } from '../../util/types';
import { FormattedMessage } from 'react-intl';
import { PrimaryButton } from '../Button/Button';
import { hasGoogleAccessToken } from '../../util/destructorHelpers';
import googleLoginIcon from '../../assets/btn_google_signin_light_normal_web@2x.png';
import Modal from '../Modal/Modal';
// ZoomMtg.setZoomJSLib('https://source.zoom.us/2.5.0/lib', '/av')


// ZoomMtg.preLoadWasm();
// ZoomMtg.prepareWebSDK();
// loads language files, also passes any error messages to the ui
// ZoomMtg.i18n.load('en-US');
// ZoomMtg.i18n.reload('en-US');


const ZoomMeetPanel = (props) => {
  const {
    onManageDisableScrolling,
    currentTransaction,
    handleCreateZoomMeeting,
    handleEnterMeeting,
    currentUser,
    onCreateZoomAuth,
    zoomMeetingInProgress,
    zoomMeetingError,
    closeZoomMeetingModel,
    userSlug,
    listingId,
    isOpen,
    currentAuthor,
    currentProvider,
    params,
    currentPage,
    setMeetingSuccessToaster,
    hasZoomCode,
  } = props;
  
  const [openGoogleMeetModal, setOpenGoogleMeetModal] = useState(false);
  const { privateData={} } = !!currentUser && currentUser.id && currentUser.attributes.profile;

  const zoomTokens = privateData && privateData.zoomTokens;
  const access_token = zoomTokens && zoomTokens.access_token;
  const expiresAt = zoomTokens && zoomTokens.expiresAt;
  const history = useHistory();
  let searchQuery = (new URL(document.location)).searchParams;

  let url;
  if (history.location.pathname.includes('/l/')) {
    url = `https://zoom.us/oauth/authorize?response_type=code&client_id=${process.env.REACT_APP_ZOOM_API_KEY}&redirect_uri=${process.env.REACT_APP_CANONICAL_ROOT_URL}/l/${userSlug}/${listingId}`
  } else {
    url = `https://zoom.us/oauth/authorize?response_type=code&client_id=${process.env.REACT_APP_ZOOM_API_KEY}&redirect_uri=${process.env.REACT_APP_CANONICAL_ROOT_URL}/t/${currentTransaction?.id?.uuid}/details`
  }

  const zoomAuthCode = searchQuery.get('code');;

  useEffect(() => {
    let param = {}
    if(zoomAuthCode){
      if (currentTransaction && currentTransaction.id) param = { code: zoomAuthCode, txId: currentTransaction.id?.uuid }
      else if (listingId) param = { code: zoomAuthCode, listingId: listingId, userSlug: userSlug }
      
      onCreateZoomAuth(param)
    }
  }, [zoomAuthCode, currentTransaction?.id]);

  const isTokenExpired = expiresAt && moment().isAfter(moment(String(expiresAt)))
  const collaborationMetaData = !!currentTransaction?.id && currentTransaction?.attributes?.metadata?.collaborationMetaData;
  const currentUserEmail = currentUser && currentUser?.attributes?.profile?.publicData?.email;
  const clientFullName = currentAuthor && currentAuthor.attributes?.profile?.publicData?.fullName;
  const clientEmail = currentAuthor && currentAuthor?.attributes?.profile?.publicData?.email;
  const providerFullName = currentProvider && currentProvider.attributes?.profile?.publicData?.fullName;
  const providerEmail = currentProvider && currentProvider?.attributes?.profile?.publicData?.email;
  const isCalenderAuthenticated = hasGoogleAccessToken(currentUser);
  
  const zoomMeetingHandler = async (values, form) => {
    const {
      title,
      description,
      startTime,
      endTime,
      collaborators,
      date
    } = values;
  
    const startDateTime = moment(`${date}T${startTime}`);
    const endDateTime = moment(`${date}T${endTime}`);
    const durationInMinutes = endDateTime.diff(startDateTime, 'minutes');
  
    const meetingInfo = await handleCreateZoomMeeting({
      start_time: startDateTime.toISOString(),
      end_time: endDateTime.toISOString(),
      topic: title,
      agenda: description,
      duration: durationInMinutes,
      timezone: getDefaultTimeZoneOnBrowser(),
      isTokenExpired,
      userEmails: collaborators ? [currentUserEmail, ...collaborators] : [clientEmail, providerEmail],
    });
  
    if(meetingInfo?.status === 'success'){
      closeZoomMeetingModel();
      setMeetingSuccessToaster(true);
      setTimeout(() => {
        setMeetingSuccessToaster(false);
      }, 10000);
  
      resetFormAndNavigate(form);
    }
  };
  
  const resetFormAndNavigate = (form) => {
    sessionStorage.removeItem('params');
    form.reset();
    closeZoomMeetingModel();
    if (currentPage === TRANSACTION_DETAILS_PAGE) {
      history.push(
        createResourceLocatorString(TRANSACTION_DETAILS_PAGE, routeConfiguration(), {
          id: currentTransaction.id.uuid,
        })
      );
    } else {
      history.push(
        createResourceLocatorString(LISTING_PAGE, routeConfiguration(), {
          id: listingId,
          slug: userSlug,
        })
      );
    }
  };
  

  return (
    <div className={css.ScheduleMeetingContainer}>
      <div className={css.zoom}>
        {access_token && isCalenderAuthenticated && !zoomMeetingError ?
          <ZoomMeetForm
            onSubmit={(values, form) => zoomMeetingHandler(values, form)}
            isOpen={isOpen}
            collaborationMetaData={collaborationMetaData}
            updateInProgress={zoomMeetingInProgress}
            zoomMeetingError={zoomMeetingError}
            currentUserEmail={currentUserEmail}
            clientFullName={clientFullName}
            providerFullName={providerFullName}
            clientEmail={clientEmail}
            providerEmail={providerEmail}
            onManageDisableScrolling={onManageDisableScrolling}
            history={history}
            params={params}
            currentUser={currentUser}
            currentTransaction={currentTransaction}
            closeZoomMeetingModel={closeZoomMeetingModel}
          />
          : access_token && !isCalenderAuthenticated ? (
            <div className={css.modalContainer}>
              <div className={css.heading}>
                <FormattedMessage id="ZoomMeetPanel.stepTwo" />
              </div>
              <p><FormattedMessage id="ZoomMeetPanel.stepTwoText" /></p>
              <div className={css.zoomButtons}>
                <span className={css.buttonCancel} onClick={() => closeZoomMeetingModel()}>
                  <FormattedMessage id="ZoomMeetPanel.cancelButton" />
                </span>
                <PrimaryButton
                  className={css.buttonSubmit}
                  onClick={()=> setOpenGoogleMeetModal(true)}
                >
                  <FormattedMessage id="ZoomMeetPanel.next" />
                </PrimaryButton>
              </div>
            </div>
          ) : (
          <div className={css.modalContainer}>
            <div className={css.heading}>
              <FormattedMessage id="ZoomMeetPanel.stepOne" />
            </div>
            <p><FormattedMessage id="ZoomMeetPanel.stepOneText" /></p>
            <div className={css.zoomButtons}>
              <span className={css.buttonCancel} onClick={() => closeZoomMeetingModel()}>
                <FormattedMessage id="ZoomMeetPanel.cancelButton" />
              </span>
              <PrimaryButton
                className={css.buttonSubmit}
                onClick={() => window.open(url, "_self")}
              >
                <FormattedMessage id="ZoomMeetPanel.next" />
              </PrimaryButton>
            </div>
          </div>
          )
        }
      </div>

      <Modal 
        isOpen={openGoogleMeetModal}
        onClose={()=> setOpenGoogleMeetModal(false)}
        onManageDisableScrolling={onManageDisableScrolling}
        className={css.googleModal}
      >
        <div className={css.googlePanel}>
          <img
            className={css.googleLoginIcon}
            src={googleLoginIcon}
            onClick={() => {
              typeof window !== 'undefined' &&  window.sessionStorage.setItem('params', JSON.stringify({...params, currentForm:ZOOM_MEETING_FORM}));
              history.push(
                createResourceLocatorString(GOOGLE_MEET_REDIRECT_PAGE, routeConfiguration(), {})
              );
            }} 
          />
        </div>
      </Modal>
    </div>
  )
}

export default ZoomMeetPanel