import React from 'react'
import css from './ViewApplicationModal.module.css'
import { types as sdkTypes } from '../../util/sdkLoader';
import { formatMoney } from '../../util/currency';
import { PrimaryButton } from '../Button/Button';
import { FormattedMessage, injectIntl } from 'react-intl';
import { compose } from 'redux';
const { Money } = sdkTypes;
const REJECT_MODAL = 'rejectModal';

function ViewApplicationModal(props) {
	const { 
		proposal, 
		isJobOfferSend, 
		isFirstInterview, 
		setOpenModal, 
		openInterviewModal, 
		intl,
    isFullTimeJob,
	} = props;
	
	const {reason, expectedSalary='', expectedSalaryCurrency, lastSalary='', lastSalaryCurrency, resume} = proposal || {};
	const lastSalaryMoney = proposal && new Money(Number(lastSalary.replace(/[^a-z0-9-]/g, ''))*100, lastSalaryCurrency);
  const expectedSalaryMoney = proposal && new Money(Number(expectedSalary.replace(/[^a-z0-9-]/g, ''))*100, expectedSalaryCurrency);

  return (
	  <div className={css.applicationModal}>
      <h2><FormattedMessage id='ViewApplicationModal.title' /></h2>
      <div className={css.row}>
        <label><FormattedMessage id='ViewApplicationModal.reasonLabel' /></label>
        <div className={css.reason}>{reason}</div>
      </div>
      {lastSalary && (
        <div className={css.row}>
          <label><FormattedMessage id='ViewApplicationModal.lastSalaryLabel' /></label>
          <p>{lastSalaryMoney && formatMoney(intl, lastSalaryMoney)}</p>
        </div>
      )}
      <div className={css.row}>
        <label>
          <FormattedMessage 
            id={isFullTimeJob ? 'ViewApplicationModal.expectedSalaryLabel' : 'JobDescriptionOverview.expectedSalaryMonthlyLabel'} 
          />
        </label>
        <p>{lastSalaryMoney && formatMoney(intl, expectedSalaryMoney)}</p>
      </div>
      <div className={css.row}>
        <label><FormattedMessage id='ViewApplicationModal.resumeLabel' /></label>
        <a href={resume?.link} target='_blank'><FormattedMessage id='ViewApplicationModal.downloadResumeLink' /></a>
      </div>
      {!isJobOfferSend && (
        <div className={css.buttonWrapper}>
          {isFirstInterview ? (
            <PrimaryButton className={css.buttonSubmit} onClick={()=> setOpenModal(REJECT_MODAL)}>
              <FormattedMessage id='ViewApplicationModal.rejectApplicantMessage' />
            </PrimaryButton>
            ) : (
            <>
              <span className={css.buttonCancel} onClick={() => setOpenModal(REJECT_MODAL)}>
                <FormattedMessage id='ViewApplicationModal.rejectApplicantMessage' />
              </span>
              <PrimaryButton 
                className={css.buttonSubmit} 
                onClick={()=> {
                  setOpenModal('')
                  openInterviewModal(1)
                }}
              >
                <FormattedMessage id='ViewApplicationModal.inviteInterviewMessage' />
              </PrimaryButton>
            </>
          )}
        </div>
      )}
    </div>
  )
}

export default compose(injectIntl)(ViewApplicationModal)