import React, { useEffect, useState } from 'react';
import css from './ManageSubscriptionPanel.module.css';
import { FormattedMessage } from '../../util/reactIntl';
import { useDispatch, useSelector } from 'react-redux';
import { ensureUser } from '../../util/data';
import moment from 'moment';
import Button from '../Button/Button';
import { createOfflineSubscription, createSubscription, getSubscriptions } from '../../containers/StripeSubscriptionPage/StripeSubscriptionPage.duck';
import { sendEmailUsingZepto, stripeCustomerPortal } from '../../util/api';
import { INR, INR_SYMBOL, INSIGHT_GIG_ONE_PLAN_MONTHLY, INSIGHT_GIG_ONE_PLAN_YEARLY, INVITE_STATUS_ACTIVE, OFFLINE_SUBSCRIPTION, SMALL_TEAMS_MONTHLY, SMALL_TEAMS_YEARLY, SUBSCRIPTION_STATUS_ACTIVE, USD, USD_SYMBOL } from '../../util/types';
import { firstLetterCapital, getUserDetails, isUserLinkedToFirm, offlineSubscriptionObject, 
  userSubscriptions as checkUserOnlineSubscription 
} from '../../util/destructorHelpers';
import NamedLink from '../NamedLink/NamedLink';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import classNames from 'classnames';
import { getUserShortName } from '../../util/typeHelpers';
import { Modal } from '..';
import { manageDisableScrolling } from '../../ducks/UI.duck';
import { fetchCurrentUser } from '../../ducks/user.duck';
import { getFirmListing } from '../../containers/SearchPage/SearchPage.duck';

const ManageSubscriptionPanel = ({ history }) => {
  const [userSubscriptions, setUserSubscriptions] = useState({});
  const [sessionUrlMessage, setSessionUrlMessage] = useState('');
  const [firmData, setFirmData] = useState(null);
  const [offlinePaymentModeModalOpen, setOfflinePaymentModeModalOpen] = useState(false);
  const [offlineSubscriptionToaster, setOfflineSubscriptionToaster] = useState(false);
  const dispatch = useDispatch();
  const state = useSelector(state => state);
  // const {userSubscriptions} = useSelector(state => state?.StripeSubscriptionPage);
  const currentUser = useSelector(state => state?.user?.currentUser);
  const ensuredCurrentUser = ensureUser(currentUser);
  const firmId = getUserDetails(ensuredCurrentUser)?.firmId;
  const firmName = getUserDetails(ensuredCurrentUser)?.firmName;
  const userIdArray = !!firmData?.length && firmData.map(user => user?.userId).filter(Boolean);
  const linkedToFirms = currentUser?.id && getUserDetails(currentUser)?.linkedToFirms;
  const userName = !!currentUser?.id && getUserDetails(currentUser)?.fullName;
  const userEmail = currentUser?.id && getUserDetails(currentUser)?.email;
  const firmListing = currentUser?.id && getUserDetails(currentUser)?.firmListing;
  const firmAuthorRole = currentUser?.id && getUserDetails(currentUser)?.firmAuthorRole;
  const isUserHasOnlineSubscription = checkUserOnlineSubscription(currentUser);
  const numOfActiveFirmMember = firmData?.filter(user => user.status === INVITE_STATUS_ACTIVE).length;
  const billingHistory = currentUser?.subscriptionData?.billingHistory

  const csmTooltipText = firmName
    ? `This user is a Customer Success Manager working on behalf of ${firmName} on the InsightGig platform.`
    : 'This user is a Customer Success Manager (CSM) on the InsightGig platform. CSMs work on behalf of their clients.';

  // Rest of your code that uses firmId

  const offlineSubscriptionData = currentUser?.id && (offlineSubscriptionObject(currentUser) ?? {});
  const isOfflineSubscription = !!currentUser?.id && !!(offlineSubscriptionObject(currentUser)?.planId);

  const { unitAmount, planName, currency: offlinePlanCurrency, requestedAt, planId, isOfflineSubscriptionPaid, numberOfMembers } = offlineSubscriptionData ?? {};

  const onlineSubscriptionData = userSubscriptions || {};
  const adminId = !!firmData?.length && firmData
    .filter(user => user?.isAdmin && !user?.isCsm && user.userId !== null && user.userId !== undefined)
    .map(user => String(user.userId))
    .join(', ');
  const userLinkedToFirm = currentUser?.id && isUserLinkedToFirm(currentUser);

  useEffect(() => {
    const fetchSubscriptions = async () => {
      const subscriptions = await dispatch(getSubscriptions({ adminId }));
      setUserSubscriptions(subscriptions);
    };

    if(isUserHasOnlineSubscription) fetchSubscriptions();

  }, [dispatch, adminId, firmData?.length, currentUser?.id, userSubscriptions?.id]);

  useEffect(() => {
    const getListing = async () => {
      const firmListing = await dispatch(getFirmListing(firmId));
      if (firmListing?.id) {
        setFirmData(firmListing.attributes?.metadata?.firmData ?? null);
      }
    };

    if (firmId) getListing();
  }, [firmId, dispatch]);

  const manageSubscription = async () => {
    try {
      const sessionUrl = await stripeCustomerPortal();
      typeof window !== 'undefined' && window.open(sessionUrl);
    } catch (err) {
      setSessionUrlMessage(err?.response?.data?.message);
      // toast.error(err.response.data.message);
    }
  };

  const manageOfflineSubscription = async () => {
    if (planId) {
      history.push(createResourceLocatorString('StripeSubscriptionPage', routeConfiguration(), {}))
    }
  };

  const currentSubscription = { ...userSubscriptions }
  const planData = currentSubscription && currentSubscription;
  const subData = currentSubscription;
  const isPlanCancelled = currentSubscription && currentSubscription?.cancel_at_period_end;
  const cancelAt = currentSubscription && moment.unix(currentSubscription?.cancel_at).format('MMM D, YYYY');

  // Adding a month
  const offlineSubscriptionNextMonthDate = moment(requestedAt).clone().add(1, 'month').format('MMM D, YYYY');
  // Adding a year
  const offlineSubscriptionNextYearDate = moment(requestedAt).clone().add(1, 'year').format('MMM D, YYYY');

  const renewalDate = planId
    && (INSIGHT_GIG_ONE_PLAN_MONTHLY === planName)
    ? offlineSubscriptionNextMonthDate
    : (INSIGHT_GIG_ONE_PLAN_YEARLY === planName)
      ? offlineSubscriptionNextYearDate
      : moment(subData?.currentPeriodEnd * 1000).format('MMM Do YYYY')

  function checkPlanExpiry(expireDateStr, planName) {
    const currentDate = moment();
    const expireDate = moment(expireDateStr, 'MMM DD, YYYY');
    
    if (!expireDate.isValid()) {
      return 'Invalid expiration date format';
    }
  
    let startThresholdDate, endThresholdDate;
    if (currentDate.isAfter(expireDate)) {
      return "Expired"
    }else{
      if (planName === INSIGHT_GIG_ONE_PLAN_YEARLY) {
        startThresholdDate = currentDate.clone();
        endThresholdDate = moment().add(1, 'month').endOf('day');
        if (expireDate.isBetween(startThresholdDate, endThresholdDate, null, '[]')) {
          return "Expiring soon"
        }
      } else if (planName === INSIGHT_GIG_ONE_PLAN_MONTHLY) {
        startThresholdDate = currentDate.clone();
        endThresholdDate = moment().add(1, 'week').endOf('day');
        if (expireDate.isBetween(startThresholdDate, endThresholdDate, null, '[]')) {
          return "Expiring soon"
        }
      } else {
        return 'Inactive';
      }
    }
  
    return 'Active';
  }

  function getSubscriptionStatusBadge(status){
    switch(status){
      case "Active":
        return css.stausActive
      case "Inactive":
        return css.stausInactive
      case "Expiring soon":
        return css.stausExpiringSoon
      case "Expired":
        return css.stausExpired
    }
  }

  const getStatus = () => {
    if (isOfflineSubscriptionPaid){
      return checkPlanExpiry(renewalDate,planName)
      //  return INVITE_STATUS_ACTIVE
    };

    if (currentSubscription?.status && !isPlanCancelled) return firstLetterCapital(currentSubscription.status);

    if (isPlanCancelled) return 'Cancelled';

    return 'Inactive';
  };
  const subscriptionStatus = getStatus();
  const isOnlineSubscriptionINR = onlineSubscriptionData?.metadata?.currency === INR;
  const isOfflinePlanINR = offlinePlanCurrency === INR;
  const amountInCents = (planData?.amount / 100 || unitAmount / 100)* (numberOfMembers ? numberOfMembers : (firmData?.length ? numOfActiveFirmMember : 1));
  // amount as per subscription plan
  const amountAsPerSubscriptionPlan = isOnlineSubscriptionINR || isOfflinePlanINR
    ? Math.round(amountInCents)
    : amountInCents;

  const formatAmountAsPerSubscriptionPlan = !!amountAsPerSubscriptionPlan && amountAsPerSubscriptionPlan.toLocaleString('en-US', {
    maximumFractionDigits: 0
  });

  //Handle payment mode
  const handleOnlinePlanSwitching = async () => {
    const isSmallTeamPlanUsed = [SMALL_TEAMS_MONTHLY, SMALL_TEAMS_YEARLY].includes(planName || planData?.nickname)
    const params = {
      userName,
      userEmail,
      priceID: planId ?? onlineSubscriptionData?.priceId,
      userIdArray,
      currency: offlinePlanCurrency,
      isUpgrade: isSmallTeamPlanUsed ?? false,
      // quantity: firmData?.length ? firmData.length + 1 : 1
    }
    if (planId) {
      const response = await dispatch(createSubscription(params));
      if (response) {
        window.open(response);
      }
    }
    else {
      setOfflinePaymentModeModalOpen(true)
    }
  }

  const handleOfflinePlanSwitching = async () => {
    const planObject = {
      planName: planName ?? onlineSubscriptionData?.planName,
      unitAmount: amountAsPerSubscriptionPlan,
      currency: offlinePlanCurrency,
      createdAt: moment().format('LLL'),
      userEmail,
      userName,
      firmName,
      planId: planId ?? onlineSubscriptionData?.priceId,
      userIdArray,
      adminId,
      firmAuthorRole,
      linkedToFirms,
      firmListing,
      firmId,
      step: OFFLINE_SUBSCRIPTION,
      subject: firmName
        ? `Alert!: ${userName} from ${firmName} has requested an offline subscription`
        : `Alert!: ${userName} has requested an offline subscription`,

    }
    const response = await dispatch(createOfflineSubscription(planObject));
    sendEmailUsingZepto(planObject)

    if (response) {
      await dispatch(fetchCurrentUser())
      setOfflinePaymentModeModalOpen(false)
      setTimeout(() => {
        setOfflineSubscriptionToaster(true)
      }, 3000)
    }
  }

  const formatPlanName = () => {
    if (planId && planName) {
      return planName
        .replace(/[-/]/g, ' ')
        .replace(/Teams/g, 'Team')
        .replace(/Yearly/, ' (Yearly)')
        .replace(/Monthly/, '(Monthly)');
    } else if (planData?.planName) {
      return planData.planName
        .replace(/[-/]/g, ' ')
        .replace(/Teams/g, 'Team')
        .replace(/Yearly/, ' (Yearly)')
        .replace(/Monthly/, '(Monthly)');
    } else {
      return '';
    }
  };

  return (
    <>
      {!(userSubscriptions?._id || offlineSubscriptionData?.planId) 
        ? (
          <div className={css.pageLoader}>Loading...</div>
        )
        : (
        <div className={css.manageSubscriptionPanel}>
          <div className={css.headingTopBox}>
            <div className={css.billingInformationTitle}>
              <FormattedMessage id="ManageSubscriptionPanel.billingInformation" />
            </div>
            {planId || !onlineSubscriptionData?._id || userLinkedToFirm ? null : <NamedLink name="StripeSubscriptionPage" className={css.subscriptionPageRedirectButton}>
              <FormattedMessage id="ManageSubscriptionPanel.subscriptionPageRedirectButton" />
            </NamedLink>}
          </div>
          <div className={css.planWrapper}>
            <h3 className={css.nameHeading}>
              {/* {planId ? <FormattedMessage id="ManageSubscriptionPanel.offlinePlanHeading" />
                : <FormattedMessage id="ManageSubscriptionPanel.onlinePlanHeading" />} */}
              <FormattedMessage id="ManageSubscriptionPanel.planDetailsHeading" />
  
            </h3>
            <div className={css.planCard}>
              <div className={css.wrapperBox}>
                <div className={css.mobilePlanHeading}>
                  {/* <div className={(!isOfflineSubscriptionPaid && !onlineSubscriptionData?._id) || isPlanCancelled ? css.inMobileInActiveBadge : css.mobileActive}>{subscriptionStatus}</div> */}
                  <div className={(!isOfflineSubscriptionPaid && !onlineSubscriptionData?._id) || isPlanCancelled ? css.inMobileInActiveBadge : classNames(getSubscriptionStatusBadge(subscriptionStatus), css.mobileActive)}>{subscriptionStatus}</div>
                  <div className={css.amountSub}>
                    <div className={css.planPrice}>
                      <span className={css.dollarIcon}>
                        <FormattedMessage 
                          id="ManageSubscriptionPanel.currency" 
                          values={{ currency: offlinePlanCurrency === USD ? USD_SYMBOL : INR_SYMBOL }} 
                        />
                      </span>
                      <span className={css.price}>
                        {formatAmountAsPerSubscriptionPlan}
                      </span>
                      <span className={css.planPeriod}>
                        {(planData?.nickname || planName) === INSIGHT_GIG_ONE_PLAN_MONTHLY ? 'per month' : 'per year'}
                      </span>
                    </div>
                  </div>
                </div>
                <div className={css.planHeading}>
                  <div className={css.planLeftSide}>
                    <div className={css.planHeadingBox}>
                      <h3>{formatPlanName()}</h3>
                      <span className={(!isOfflineSubscriptionPaid && !onlineSubscriptionData?._id) || isPlanCancelled ? css.inActiveBadge : classNames(getSubscriptionStatusBadge(subscriptionStatus), css.activeBadge)}>{subscriptionStatus}</span>
                    </div>
                    <p className={css.planDescription}>
                      One plan for all that InsightGig has to offer; Talent, Tools, and Tribe. 
                    </p>
                  </div>
                  <div className={css.planRightSide}>
                    <div className={css.planPrice}>
                      <span className={css.dollarIcon}>
                        <FormattedMessage 
                          id="ManageSubscriptionPanel.currency" 
                          values={{ currency: offlinePlanCurrency === USD ? USD_SYMBOL : INR_SYMBOL }} 
                        />
                      </span>
                      <span className={css.price}>
                        {formatAmountAsPerSubscriptionPlan}
                      </span>
                      <span className={css.planPeriod}>
                        {(planData?.nickname || planName) === INSIGHT_GIG_ONE_PLAN_MONTHLY ? 'per month' : 'per year'}
                      </span>
                    </div>
                  </div>
                </div>
                <div className={css.teamInformation}>
                  <div className={css.teamProfileIcon}>
                    {firmData?.map(user => {
                      if(user.status !== INVITE_STATUS_ACTIVE) return
                      const isCsm = user?.isCsm;
                      return (
                        <div className={classNames(isCsm && css.csmIconBox, css.iconBox)} key={user?.userId} data-tooltip={csmTooltipText}>
                          {user?.profileImage ? (
                            <img src={user?.profileImage} />
                          ) : (
                            <div className={css.profileImage}>
                              {getUserShortName(user?.fullName, user?.email)}
                            </div>
                          )}
                        </div>
                      )
                    })}
                  </div>
  
                  <div className={css.teamCount}>
                    <FormattedMessage id="ManageSubscriptionPanel.totalUsers" values={{ totalUsers: firmData?.length ? numOfActiveFirmMember : 0 }} />
                  </div>
                </div>
              </div>
              <div className={css.planBody}>
                <div className={css.planRow}>
                  <div className={css.planBodyLeft}>
                    <span className={css.planrenewText}>
                      <FormattedMessage id="ManageSubscriptionPanel.paymentMethod" />
                    </span>
                    <span className={css.planrenewDate}>
                      <FormattedMessage id="ManageSubscriptionPanel.paymentMode" values={{ paymentMode: planId ? 'Offline' : 'Online' }} />
                    </span>
                  </div>
                  {/* {!userLinkedToFirm && (
                    <div className={css.switchBilling} onClick={handleOnlinePlanSwitching}>
                      {planId ?
                        <FormattedMessage id="ManageSubscriptionPanel.switchOnlineBilling" /> :
                        <FormattedMessage id="ManageSubscriptionPanel.switchOfflineBilling" />
                      }
                    </div>
                  )} */}
                </div>
                <div className={css.planRow}>
                  <div className={css.planBodyLeft}>
                    <span className={css.planrenewText}>
                      {planId ?
                        <FormattedMessage id="ManageSubscriptionPanel.planExpiryDetails" /> :
                        isPlanCancelled ? <FormattedMessage id="ManageSubscriptionPanel.planCancelDetails" /> :
                          <FormattedMessage id="ManageSubscriptionPanel.planRenewDetails" />}
                    </span>
                    <span className={css.planrenewDate}>
                      {isPlanCancelled ? cancelAt : renewalDate}
                    </span>
                  </div>
                  {(planId || onlineSubscriptionData?._id) && !userLinkedToFirm ? <div className={css.planBodyRight}>
                    <Button
                      onClick={planId ? manageOfflineSubscription : manageSubscription}
                      className={css.subscriptionButton}
                    // disabled={true}
                    >
                      <FormattedMessage id="ManageSubscriptionPanel.manageSubscriptionButton" />
                    </Button>
                  </div> : null}
                </div>
              </div>
            </div>
          </div>
          <h3 className={css.nameHeading}><FormattedMessage id="ManageSubscriptionPanel.billingHistory" /></h3>
          <div className={css.tableBox}>
            <table className={css.tableStriped}>
              <thead>
                <tr className={css.subscriptionHeading}>
                  {/* <th className={css.leftTd}>
                    <FormattedMessage id="ManageSubscriptionPanel.planHeading" />
                  </th> */}
                  <th className={css.leftTd}>
                    <FormattedMessage id="ManageSubscriptionPanel.billingDetailsHeading" />
                  </th>
                  <th className={css.rightTd}>
                    <FormattedMessage id="ManageSubscriptionPanel.amountHeading" />
                  </th>
                  <th className={css.rightTd}>
                    <FormattedMessage id="ManageSubscriptionPanel.paymentMethod" />
                  </th>
                  <th className={css.rightTd}>
                    <FormattedMessage id="ManageSubscriptionPanel.status" />
                  </th>
                </tr>
              </thead>
              <tbody>
                {billingHistory && billingHistory.map((item,index)=>(
                  <tr key={index} className={css.subscriptionData}>
                    <td className={css.centerTd}>
                      <div className={css.subscriptionPlanBody}>
                        {item?.date ? moment(item?.date).format('MMM Do YYYY') : (renewalDate ? renewalDate : "-")}
                      </div>
                    </td>
                    <td className={css.rightTd}>
                      <div className={css.subscriptionPlanBody}>
                          {item?.amount}
                        </div>
                    </td>
                    <td  className={css.rightTd}>
                      <div className={css.subscriptionPlanBody}>
                        {item?.paymentMethod}
                      </div>
                    </td>
                    <td  className={css.rightTd}>
                      <div className={item?.status==="Paid" ? css.paidPayment : css.awaitingPayment}>
                        {item?.status}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}

      <Modal
        id="offlinePaymentModal"
        className={css.disableModalBorder}
        contentClassName={css.containerClassName}
        isOpen={offlinePaymentModeModalOpen}
        onClose={() => setOfflinePaymentModeModalOpen(false)}
        usePortal
        onManageDisableScrolling={(componentId, disableScrolling) =>
          dispatch(manageDisableScrolling(componentId, disableScrolling))
        }
        isManageSubscriptionOfflinePaymentModal={true}
      >
        <div className={css.offlineSubscriptionConfirmationHeading}>
          <FormattedMessage id="ManageSubscriptionPanel.offlineSubscriptionConfirmation" />
        </div>

        <div className={css.buttonsContainer}>
          <button
            type='button'
            onClick={() => setOfflinePaymentModeModalOpen(false)}
            className={css.cancelButton}>
            <FormattedMessage id="StripeSubscriptionPanel.cancelButtonText" />
          </button>
          <Button
            type="button"
            onClick={handleOfflinePlanSwitching}
            className={css.submitButton}
          >
            <FormattedMessage id="StripeSubscriptionPanel.continueButtonText" />

          </Button>
        </div>

      </Modal>
      < Modal
        id="offlinePaymentSuccessModal"
        className={css.disableModalBorder}
        contentClassName={css.containerClassName}
        isOpen={offlineSubscriptionToaster}
        onClose={() => setOfflineSubscriptionToaster(false)}
        usePortal
        onManageDisableScrolling={(componentId, disableScrolling) =>
          dispatch(manageDisableScrolling(componentId, disableScrolling))
        }
      >
        <div className={css.successMessage}>
          <div className={css.tosterHeading}>
            <FormattedMessage id="StripeSubscriptionPanel.toasterMessageHeading" />
          </div>
          <div className={css.tosterSubHeadig}>
            <p><FormattedMessage id="StripeSubscriptionPanel.toasterMessageSubHeading" /></p>
            <p>
              <FormattedMessage id="StripeSubscriptionPanel.tosterlinkText" />
              <a href='mailto:admin@insightgig.com'>
                <FormattedMessage id="StripeSubscriptionPanel.tosterEmail" />
              </a>
            </p>
          </div>
        </div>
      </Modal>
    </>

  );
};

export default ManageSubscriptionPanel;
