import { addMarketplaceEntities } from "../../ducks/marketplaceData.duck";
import { isScrollingDisabled } from "../../ducks/UI.duck";
import { fetchCurrentUser } from "../../ducks/user.duck";
import { updateListingMetadata } from "../../util/api";
import { storableError } from "../../util/errors";
import { types as sdkTypes } from '../../util/sdkLoader';
import { USER_ROLE_CLIENT } from "../../util/types";
import { showListing } from "../FirmPage/FirmPage.duck";
const { UUID } = sdkTypes;

export const RESULT_PAGE_SIZE = 5;
const resultIds = data => data.data.map(l => l.id);

//Action types

export const UPDATE_FIRM_REQUEST = 'app/AffiliatedFirmPage/UPDATE_FIRM_REQUEST';
export const UPDATE_FIRM_SUCCESS = 'app/AffiliatedFirmPage/UPDATE_FIRM_SUCCESS';
export const UPDATE_FIRM_ERROR = 'app/AffiliatedFirmPage/UPDATE_FIRM_ERROR';

export const QUERY_LISTINGS_REQUEST = 'app/AffiliatedFirmPage/QUERY_LISTINGS_REQUEST';
export const QUERY_LISTINGS_SUCCESS = 'app/AffiliatedFirmPage/QUERY_LISTINGS_SUCCESS';
export const QUERY_LISTINGS_ERROR = 'app/AffiliatedFirmPage/QUERY_LISTINGS_ERROR';



export const REMOVE_LISTING_IMAGE = 'app/FirmDashBoardPage/REMOVE_LISTING_IMAGE';
//InitialState
const initialState = {
  images: {},
  imageOrder: [],
  removedImageIds: [],
  uploadImageError: null,
  pagination: null,
  searchParams: null,
  currentPageResultIds: [],
  queryReviewsError: null,
  queryListingInProgress:false,
  queryListingsError: null,
  updateFirmInProgess: false,
  updateFirmError:null,
  updateFirmSuccess:false,

};


//reducer function
export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    
   case UPDATE_FIRM_REQUEST:
    return{
      ...state,
      updateFirmInProgess:true,
      updateFirmError:null,
    };
   case UPDATE_FIRM_SUCCESS:
    return{
      ...state,
      updateFirmInProgess:false,
      updateFirmError:null,
      updateFirmSuccess:payload
    };
   case UPDATE_FIRM_REQUEST:
    return{
      ...state,
      updateFirmInProgess:false,
      updateFirmError:payload,
      updateFirmSuccess:false
    };
    
    default:
      return state;
  }
}


//Selectors

export const checkScrollingDisabled = state => isScrollingDisabled(state);

// ================ Action creators ================ //

export const updateFirmRequest = () => ({
  type: UPDATE_FIRM_REQUEST,
});
export const updateFirmSuccess = (response) => ({
  type: UPDATE_FIRM_SUCCESS,
  payload: response,
});

export const updateFirmError = e => ({
  type: updateFirmError,
  error: true,
  payload: e,
});


export const updateFirmListing = params => async (dispatch,getState,sdk)=>{
  try {
    const {id,affiliatedListingIds} = params || {};
    dispatch(updateFirmRequest());
console.log(params,'parans104')
    const response = await updateListingMetadata({id,affiliatedListingIds})
    
  } catch (error) {
    dispatch(updateFirmError(storableError(e)));
  }
}

//LoadData
export const loadData = params =>  (dispatch, getState, sdk) => {

  try {
    const listingId = new UUID(params.id);
    return Promise.all([dispatch(fetchCurrentUser()),dispatch(showListing(listingId))]);
  } catch (e) {
    return;
  }
};