import React, { useEffect, useState, forwardRef } from 'react';
import { func } from 'prop-types';
import { compose } from 'redux';
import { Field, Form as FinalForm } from 'react-final-form';
// These relative imports need to point to correct directories
import {
  intlShape,
  injectIntl,
  FormattedMessage,
} from '../../util/reactIntl';
import {
  Form, FieldTextInput, PrimaryButton, SecondaryButton, IconCard, FieldCheckbox, FieldSelect, IconSpinner, Modal
} from '../../components';
import css from './OpenAIExtractQuestionsFromTranscriptsForm.module.css';
import { fetchQueryReportData, handleFileUpload } from '../../util/api';
import FileView from '../../components/FileView/FileView';
import classNames from 'classnames';
import { composeValidators, required } from '../../util/validators';
import useHandleFileExtensionError from '../../hooks/useHandleFileExtensionError';
import { useParams } from 'react-router-dom';
import FieldValidationErrorMessage from '../../components/FieldValidationErrorMessage/FieldValidationErrorMessage';

const ACCEPT_FILE = '.doc,.docx,application/msword,application/pdf';
const FILE_SIZE_LIMIT = 25 * 1000 * 1000;
const FILE_EXTENSION_ERROR_MESSAGE = 'Please select file in PDF or DOC format only.';
const SINGLE_FILE_SIZE_LIMIT = 25000000;
const TOTAL_FILE_SIZE_LIMIT = 750000000;

export const OpenAIExtractQuestionsFromTranscriptsForm = props => {
  return (
    <FinalForm
      {...props}
      render={formRenderProps => {
        const {
          disabled,
          handleSubmit,
          intl,
          pristine,
          updated,
          formId,
          updateError,
          values,
          form,
          openaiRequest,
          initialText,
          setInitialText,
          handleRedirectToGigAppsPage,
          manageDisableScrolling
        } = formRenderProps;

        const params = useParams();
        const [isLoading, setIsLoading] = useState(false);
        const [fetchDataLoader, setFetchDataLoader] = useState(false);
        const [error, setError] = useState(null);
        const [fileExtensionError, checkForFileExtension] = useHandleFileExtensionError({});
        const [discussionGuidefileExtensionError = fileExtensionError, discussionGuidecheckForFileExtension = checkForFileExtension] = useHandleFileExtensionError({});
        const submitDisabled = isLoading || !values?.transcriptDocuments || values?.transcriptDocuments?.length===0;

        useEffect(() => {
          if (initialText) {
            form.reset();
          }
        }, [initialText])

        const fetchData = async () => {
          setFetchDataLoader(true)
          const { data } = await fetchQueryReportData({ id: params?.id })
          setInitialText({
            isOpen: true,
            content: data?.data?.discussionGuide,
            isCompleted: true
          })
          setFetchDataLoader(false)
        }

        useEffect(() => {
          if (params.type) {
            fetchData()
          }
        }, [params])

        async function handleAttachment(files) {
          setIsLoading(true)
          try {
            let filesArray = Object.keys(files).map(index => files[index])
            const parsedFiles = await Promise.all(filesArray.map(async (file) => {
              let parsedResponse = await handleFileUpload('get-parsed-document', { file });
              if (parsedResponse?.data) {
                return {
                  file,
                  content: parsedResponse.data
                };
              }

              return null;
            }).filter(item => item !== null));

            form.change('transcriptDocuments', [...(values?.transcriptDocuments || []), ...(parsedFiles || [])])
            setIsLoading(false)
          }
          catch (error) {
            setIsLoading(false)
          }
        }

        const handleError = (message) => {
          setError(message)
          setTimeout(()=>{
            setError(null)
          },3000)
        }

        return (
          <Form className={css.contractForm} onSubmit={handleSubmit}>
            {params.type ? 
              (fetchDataLoader ? <div className={css.loadingdivContainer}>...Loading</div> : <></>)
            :
              <div className={css.container}>
                <div className={css.briefGenerator}>
                  <span className={css.generatorTitle}>
                    <FormattedMessage id="OpenAiAppsPage.extractQuestionsFromTranscriptsTitle" />
                  </span>
                </div>
                
                <div>
                  <label>
                    <FormattedMessage id="OpenAiExtractQuestionsFromTranscripts.transcriptDocuments" />
                  </label>
                </div>
                <div className={css.attachment}>
                  {(values?.transcriptDocuments?.length < 50 || !values?.transcriptDocuments) && (
                    <Field
                      id="transcriptDocuments"
                      name="transcriptDocuments"
                      accept={ACCEPT_FILE}
                      type="file"
                    >
                      {fieldprops => {
                        const { accept, input, disabled: fieldDisabled } = fieldprops;
                        const { name, type } = input;
                        const onChange = async e => {
                          const files = e.target.files;
                          const allowedExtensions = /(\.pdf|\.docx?)$/i
                          const filesObject = Object.values(files);
                          let extensionError = checkForFileExtension(allowedExtensions, files, FILE_EXTENSION_ERROR_MESSAGE)
                          const totalFileSize = [...files, ...(values?.transcriptDocuments || [])].reduce((acc, curr) => acc + curr.size, 0)
                          if(extensionError) return
                          if ((values?.transcriptDocuments?.length + files?.length) > 50) {
                            handleError('You can upload maximum 50 files!')
                            return
                          }
                          if (!!filesObject?.find(file => file.size > SINGLE_FILE_SIZE_LIMIT)) {
                            handleError('File size can not be greater than 25mb!')
                            return
                          }
                          if (totalFileSize > TOTAL_FILE_SIZE_LIMIT) {
                            handleError('Total size can not be greater than 750mb!')
                            return
                          }
                          // form.change('transcriptDocuments', [...(values?.transcriptDocuments || []), ...files])  
                          handleAttachment(files)
                        };

                        const inputProps = { accept, id: name, name, onChange, type };
                        return (
                          <div className={css.fileInputContainer}>
                            {fieldDisabled ? null : (
                              <input {...inputProps} className={css.addImageInput} multiple={true} />
                            )}
                            <label type='button' htmlFor={name} className={css.attachmentLabel}>
                              {isLoading ? <IconSpinner strokeColor='#0B96F5' /> : <IconCard brand="upload" />}
                              <span className={css.dragDrop}><FormattedMessage id="GigAppsPage.singleFileUpload" /></span>
                              <span className={css.docType}>
                                <FormattedMessage id="OpenAIResearchInstrumentForm.allowedResearchDocumentFormat" />
                              </span>
                            </label>
                            {fileExtensionError && <FieldValidationErrorMessage message={fileExtensionError}/>}
                            {error && <FieldValidationErrorMessage message={error}/>}
                          </div>
                        );
                      }}
                    </Field>
                  )}
                  {(values?.transcriptDocuments && values?.transcriptDocuments?.length > 0) && (
                    values?.transcriptDocuments?.map((item, index) => {
                      return (<div className={css.fileContainer}>
                        {values?.transcriptDocuments?.length> 3 && <input onChange={(e) => {
                          if (e.target.checked) {
                            form.change("selectedTranscriptsNames", [...(values.selectedTranscriptsNames || []), item?.file?.name])
                          } else {
                            let updatedTranscriptDocuments = values.selectedTranscriptsNames.filter(ele => {
                              return ele !== item?.file?.name
                            })
                            form.change("selectedTranscriptsNames", updatedTranscriptDocuments)
                          }
                        }} type="checkbox" disabled={!values?.selectedTranscriptsNames?.includes(item?.file?.name) && values?.selectedTranscriptsNames?.length >= 5} />}
                        <FileView file={item?.file}>
                          <span className={css.close} onClick={() => {
                            let updatedTranscriptDocuments = [...values.transcriptDocuments]
                            updatedTranscriptDocuments.splice(index, 1)
                            form.change('transcriptDocuments', updatedTranscriptDocuments)
                          }}>
                            <IconCard brand='cross' />
                          </span>
                        </FileView>
                      </div>)
                    })
                  )}
                </div>
                <div className={css.fieldLabel}>
                  <label>
                    <FormattedMessage id="OpenAiExtractQuestionsFromTranscripts.discussionGuideFormat" />
                  </label>
                </div>
                <div className={css.attachment}>
                  {values?.discussionGuideFormat ?
                    (
                      <div className={css.file}>
                        <FileView file={values?.discussionGuideFormat}>
                          <span className={css.close} onClick={() => {
                            form.change('discussionGuideFormat', null)
                          }}>
                            <IconCard brand='cross' />
                          </span>
                        </FileView>
                      </div>
                    )
                    :
                    (
                      <Field
                        id="discussionGuideFormat"
                        name="discussionGuideFormat"
                        accept={ACCEPT_FILE}
                        type="file"
                      >
                        {fieldprops => {
                          const { accept, input, disabled: fieldDisabled } = fieldprops;
                          const { name, type } = input;
                          const onChange = async e => {
                            const file = e.target.files[0];
                            const allowedExtensions = /(\.pdf|\.docx?)$/i
                            let extensionError = discussionGuidecheckForFileExtension(allowedExtensions, file, FILE_EXTENSION_ERROR_MESSAGE)
                            if (extensionError) return
                            if (file && file.name && file.size < FILE_SIZE_LIMIT) {
                              form.change('discussionGuideFormat', file)
                            }
                          };

                          const inputProps = { accept, id: name, name, onChange, type };
                          return (
                            <div className={css.fileInputContainer}>
                              {fieldDisabled ? null : (
                                <input {...inputProps} className={css.addImageInput} />
                              )}
                              <label type='button' htmlFor={name} className={css.attachmentLabel}>
                                <IconCard brand="upload" />
                                <span className={css.dragDrop}><FormattedMessage id="GigAppsPage.singleFileUpload" /></span>
                                <span className={css.docType}>
                                  <FormattedMessage id="OpenAIResearchInstrumentForm.allowedResearchDocumentFormat" />
                                </span>
                              </label>
                              {discussionGuidefileExtensionError && <span className={css.errorText}>{discussionGuidefileExtensionError}</span>}
                            </div>
                          );
                        }}
                      </Field>
                    )}
                </div>
                <div className={css.actionButtons}>
                  <SecondaryButton
                    type='button'
                    className={css.resetButton}
                    onClick={() => {
                      form.reset()
                    }}
                    disabled={openaiRequest}
                  >
                    <FormattedMessage id="OpenAIContractForm.resetButtonText" />
                  </SecondaryButton>
                  <PrimaryButton
                    type='submit'
                    className={classNames(submitDisabled ? css.disabledSubmitButton : css.submitButton)}
                    onClick={(e)=>{
                      if(submitDisabled){
                        e.preventDefault()
                        handleError("Please upload a Transcripts first")
                      }
                    }}
                    inProgress={openaiRequest}
                  >
                    <FormattedMessage id="OpenAIContractForm.submitButtonText" />
                  </PrimaryButton>
                </div>
              </div>
            }
            <Modal 
              id={`OpenAIResearchTranslatorForm.modal`} 
              isOpen={values?.success} 
              onClose={()=> {
                form.change('success',false)
                handleRedirectToGigAppsPage()
              }} 
              onManageDisableScrolling={manageDisableScrolling}
            >
              <div className={css.alertModal}>
                <h2><FormattedMessage id="OpenAiAppsPanel.successModalHeading" /></h2>
                <p><FormattedMessage id="OpenAiAppsPage.expansionContent"/></p>
                <PrimaryButton
                  type='button'
                  className={css.closeButton}
                  onClick={() => {
                    form.change('success',false)
                    handleRedirectToGigAppsPage()
                  }}
                >
                  <FormattedMessage id='OpenAIContentAnalysisForm.closeButtonLabel' />
                </PrimaryButton>
              </div>
            </Modal>
          </Form>
        );
      }}
    />
  )
}


OpenAIExtractQuestionsFromTranscriptsForm.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  //   updateInProgress: bool.isRequired,
};

export default compose(injectIntl)(OpenAIExtractQuestionsFromTranscriptsForm);