import React, { useCallback, useState } from 'react'
// import css from './EditListingFeaturesForm.module.css'
import classNames from 'classnames';
import { v4 as uuidv4 } from 'uuid';
import { handleClearClick, handleKeyPress, handleRemoveOption, handleSelectChanges, handleUSP, toggleDropdown } from './helperFunction';
import IconCard, { CROSS, VERTICAL_DOTS } from '../../components/IconCard/IconCard';
import { getSoftwareInsight } from '../../util/api';
import { Field } from 'react-final-form';
import { INDUSTRY_CATEGORY } from '../../util/types';
import { Menu, MenuContent, MenuItem, MenuLabel } from '../../components';

const IndustrySection = (props) => {
  const { values, form, intl, css } = props;
  const { searchIndustry = [] } = values || {};
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isUlVisible, setIsUlVisible] = useState(false);

  // HandleSearchChange
  const handleSearchChange = async (value) => {
    try {
      const response = await getSoftwareInsight({ value, category: INDUSTRY_CATEGORY });
      form.change('searchIndustry', response)
      setIsUlVisible(true);
    } catch (error) {
    }
  };
  const debounce = (func, delay) => {
    let timerId;
    return (...args) => {
      clearTimeout(timerId);
      timerId = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };
  const debouncedSearch = useCallback(debounce(handleSearchChange, 300), []);

  // sectionHeading  
  const sectionHeading = intl.formatMessage({
    id: "IndustrySection.industrySectionHeading",
  });

  // inputPlaceholder  
  const inputPlaceholder = intl.formatMessage({
    id: "IndustrySection.industrySectionPlaceholder"
  });
  // addButtonText  
  const addButtonText = intl.formatMessage({
    id: "IndustrySection.addIndustryButtonText"
  });
  // serviceDescription  
  const industrySectionDescription = intl.formatMessage({
    id: "IndustrySection.industrySectionDescription"
  });

  // removeServiceButtonText  
  const removeServiceButtonText = intl.formatMessage({
    id: "IndustrySection.removeServiceButtonText"
  });
  // markAsUSPText  
  const markAsUSPText = intl.formatMessage({
    id: "IndustrySection.markAsUSPText"
  });
  // noMoreRecords  
  const noMoreRecords = intl.formatMessage({
    id: "IndustrySection.noMoreRecords"
  });

  return (
    <div className={css.softwareInsightsContainer}>
      <div className={css.serviceHeading}>
        {sectionHeading}
      </div>
      <div className={css.releventService}>
        {industrySectionDescription}
      </div>
      <div className={classNames(css.inputSelectCard)}>
        {(values?.industry || [])?.map((item) => (
          <span 
            key={uuidv4()} 
            className={classNames(css.industrySelected, item?.isUSP && css.industryUspSelected)}
          >
            {item.label}
            <Menu className={css.moreOptionMenu}>
              <MenuLabel>
                <span className={css.menuLabel}>
                  <IconCard brand={VERTICAL_DOTS} />
                </span>
              </MenuLabel>
              <MenuContent className={css.menu}>
                <MenuItem key='remove'>
                  <div className={css.markAsUSPText}
                    onClick={() => handleRemoveOption({ item, values, form, category: INDUSTRY_CATEGORY })}>{removeServiceButtonText}</div>
                </MenuItem>
                <MenuItem key='usp'>
                  <div className={css.markAsUSPText} onClick={() => handleUSP({ form, values, item })}>
                    {markAsUSPText}
                  </div>
                </MenuItem>
              </MenuContent>
            </Menu>
          </span>
        ))}
      </div>
      <button type='button' onClick={() => toggleDropdown(setIsDropdownOpen)} className={css.addSoftwareButton}>
        {addButtonText}
      </button>
      {isDropdownOpen ? (
        // <OutsideClickHandler onOutsideClick={handleClearClick} className={css.languageOutsideContainer}>
        <div className={css.inputSelect}>
          <Field name="industrySearchKeyword">
            {({ input }) => (
              <div className={css.searchContainer}>
                {/* <IconCollection icon='SearchIcon' /> */}
                <input
                  {...input}
                  type="text"
                  autoComplete='off'
                  placeholder={inputPlaceholder}
                  onChange={(e) => {
                    input.onChange(e);
                    debouncedSearch(e.target.value);
                  }}
                  onKeyPress={(e) => handleKeyPress({ e, form, setIsUlVisible, setIsDropdownOpen, category: INDUSTRY_CATEGORY })}
                  autoFocus
                />

                <button
                  type="button"
                  className={css.clearSearchButton}
                  onClick={() => handleClearClick({
                    form,
                    setIsUlVisible,
                    setIsDropdownOpen
                  })}
                >
                  <IconCard brand={CROSS} />
                </button>
              </div>
            )}
          </Field>
          {isUlVisible && (
            <ul className={css.languagesDropdownBox}>
              {searchIndustry?.length > 0 ? searchIndustry?.map(item => (
                <li
                  className={values?.software?.some(val => val.label === item.name) ? css.selectedList : null}
                  key={item._id}
                  onClick={() =>
                    handleSelectChanges({ item: item.industry, form, setIsUlVisible, setIsDropdownOpen, category: INDUSTRY_CATEGORY })}
                >
                  {item?.industry}
                </li>
              )) : <li>{noMoreRecords}</li>}
            </ul>
          )}
        </div>
        // </OutsideClickHandler>
      ) : null}
    </div>
  )
}

export default IndustrySection