import React from "react";
import css from './CaptureRunNameField.module.css';
import FieldTextInput from "../FieldTextInput/FieldTextInput";
import { PrimaryButton } from "../Button/Button";
import * as validators from '../../util/validators';

const CaptureRunNameField = (props) => {
  const { step, form, values, children } = props;
  const maxCharLimit = validators.maxLength('You can enter maximum 50 characters', 50);

  if(step === 1){
    return(
      <div className={css.runNamedInputWrapper}>
        <h3>What would you like to name this run? *</h3>
        <FieldTextInput
          className={css.inputBox}
          type="input"
          name="runName"
          autoComplete="given-name"
          validate={maxCharLimit}
        />
        <div className={css.actionButtons}>
          <div></div>
          <PrimaryButton
            type='button'
            className={css.submitButton}
            disabled={!values?.runName}
            onClick={() => form.change('step', 2)}
          >
            Next
          </PrimaryButton>
        </div>
      </div>
    )
  }
  else return(
    <>
      {children}
    </>
  )
}

export default CaptureRunNameField