import React from 'react';
import { bool } from 'prop-types';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { types as sdkTypes } from '../../util/sdkLoader';
import { LINE_ITEM_SERVICE_FEE, propTypes, FREE_PLANS, PROFESSIONAL_INDVIDUAL_PLAN, SMALL_TEAM_PLAN } from '../../util/types';

import css from './BookingBreakdown.module.css';
import { freePlan, professionIndividualPlan, smallTeamPlan } from '../../util/destructorHelpers';

const { Money } = sdkTypes;

// Validate the assumption that the serviceFee exists and the amount
// is zero or negative.
const isValidCommission = commissionLineItem => {
  return commissionLineItem.lineTotal instanceof Money && commissionLineItem.lineTotal.amount <= 0;
};

const lineItemsTotal = (lineItemsParams) => {
  const {  transaction,
    serviceFeeLineItem,
     serviceFeePercentage,
     paymentTerms,isFinal} = lineItemsParams;
  const paymentFee = transaction?.attributes?.metadata?.proposal?.paymentFee?.amount;
  const paidAmount = serviceFeeLineItem.unitPrice.amount;
  const currency = serviceFeeLineItem.lineTotal?.currency || config.currency;
  const remainingAmount =( Number(paymentTerms) === 0 && isFinal) ? paymentFee  : paymentFee - paidAmount;
  return new Money((remainingAmount/100)*serviceFeePercentage, currency)
}

const LineItemServiceFee = props => {
  const { transaction, isProvider, intl, isFinal, currentUser ,paymentTerms} = props;
const isFreePlanUsed = !!currentUser?.id && freePlan(currentUser);
  const isProfessionalPlanUsed = !!currentUser?.id && professionIndividualPlan(currentUser);
  const isSmallTeamPlanUsed = !!currentUser?.id && smallTeamPlan(currentUser);  
  const currentSubscriptionPlan = isSmallTeamPlanUsed ? SMALL_TEAM_PLAN : isProfessionalPlanUsed ? PROFESSIONAL_INDVIDUAL_PLAN : FREE_PLANS;
  const planFeeMap = {
    free: 20,
    professional: 10,
    smallTeam: 0,
    };
    
    const selectedPlan = isFreePlanUsed ? 'free' : isProfessionalPlanUsed ? 'professional' : isSmallTeamPlanUsed ? 'smallTeam' : null;
    
    const customFeePercentage = planFeeMap[selectedPlan] ?? 0;
  
  const serviceFeeLineItem = transaction.attributes.lineItems.find(
    item => item.code === LINE_ITEM_SERVICE_FEE && !item.reversal
  );

  // If serviceFee is passed it will be shown as a fee already reduces from the total price
  let commissionItem = null;

  // Flex Template for Web is using the default transaction process (https://www.sharetribe.com/docs/background/transaction-process/#sharetribe-flex-default-transaction-process)
  // which containt provider commissions so by default the serviceFeeLineItem should exist.
  // If you are not using provider commisison you might want to remove this whole component from BookingBreakdown.js file.
  if (!isProvider && serviceFeeLineItem) {
    // if (!isValidCommission(serviceFeeLineItem)) {
    //   // eslint-disable-next-line no-console
    //   console.error('invalid serviceFee line item:', serviceFeeLineItem);
    //   throw new Error('serviceFee should be present and the value should be zero or negative');
    // }

    const serviceFeePercentage = serviceFeeLineItem.percentage.d[0];
    const lineItemTotalArgument = {
      transaction,
       serviceFeeLineItem,
        serviceFeePercentage,
        paymentTerms,
        isFinal
    }
    const serviceFee = isFinal ? lineItemsTotal(lineItemTotalArgument) : serviceFeeLineItem.lineTotal;
    const formattedServiceFee = serviceFee ? formatMoney(intl, serviceFee) : null;
    commissionItem = (
      <div className={css.lineItem}>
        <span className={css.itemLabel}>
          <FormattedMessage id="BookingBreakdown.insightFee" />
          <span className={css.tooltip}>
            <FormattedMessage 
              id="BookingBreakdown.insightFeeTooltipMessage" 
              values={{servicePercentage: customFeePercentage, currentSubscriptionPlan}}
            />
          </span>
        </span>
        <span className={css.itemValue}>{formattedServiceFee}</span>
      </div>
    );
  }

  return commissionItem;
};

LineItemServiceFee.propTypes = {
  transaction: propTypes.transaction.isRequired,
  isProvider: bool.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemServiceFee;
