import { Field, Form as FinalForm } from 'react-final-form';
import { Form, PrimaryButton, SecondaryButton, IconCard, CaptureRunNameField } from '../../components';
import css from './OpenAIQueryReportForm.module.css'
import { FormattedMessage } from 'react-intl';
import FileView from '../../components/FileView/FileView';
import { useEffect, useState } from 'react';
import useHandleFileExtensionError from '../../hooks/useHandleFileExtensionError';
import classNames from 'classnames';

const ACCEPT_FILE = 'application/pdf, .doc, .docx, .ppt, .pptx';
const SINGLE_FILE_SIZE_LIMIT = 50000000;
const TOTAL_FILE_SIZE_LIMIT = 150000000;
const FILE_EXTENSION_ERROR_MESSAGE = `Please select files in PDF, DOC, DOCX, PPT, or PPTX format only.`;

const OpenAIQueryReportForm = (props) => {

  return (
    <FinalForm
      {...props}
      render={formRenderProps => {
        const {
          disabled,
          handleSubmit,
          intl,
          pristine,
          values,
          form,
          openaiRequest,
          isError,
        } = formRenderProps;

        const { folders = [], step = 1 } = values || {};
        const [error, setError] = useState('');
        const [fileExtensionError, checkForFileExtension] = useHandleFileExtensionError();
        const [folderUpload, setFolderUpload] = useState("true");
        const submitDisabled = false

        useEffect(() => {
          if (!!isError) form.reset()
        }, [isError])

        function handleError(message) {
          setError(message)
          setTimeout(() => setError(false), 3000)
        }

        function calculateSize(data) {
          let totalSize = 0;
          function calculateObjectSize(obj) {
            for (let key in obj) {
              if (Array.isArray(obj[key])) {
                totalSize += obj[key].reduce((sum, file) => sum + (file.size || 0), 0);
              } else if (typeof obj[key] === 'object' && obj[key] !== null) {
                calculateObjectSize(obj[key]);
              }
            }
          }
          calculateObjectSize(data);
          return totalSize;
        }

        const filesLength = () => {
          let count = folders.reduce((acc,curr)=> acc + curr.folderFiles.length,0)
          return count
        }

        const filterValidFiles = (uploadedFiles) => {
          const allowedExtensions = /(\.pdf|\.doc|\.docx|\.ppt|\.pptx)$/i
          const filteredFiles = uploadedFiles.filter(file=>file.name !== ".DS_Store")
          const extensionError = checkForFileExtension(allowedExtensions, filteredFiles, FILE_EXTENSION_ERROR_MESSAGE)
          const totalFileSize = filteredFiles.reduce((acc, curr) => acc + curr.size, 0) + calculateSize(values.folders)

          if (extensionError) return []
          if ((filesLength() + filteredFiles?.length) > 50) {
            handleError('You can upload maximum 50 files!')
            return []
          }
          if (!!filteredFiles?.find(file => file.size > SINGLE_FILE_SIZE_LIMIT)) {
            handleError('File size can not be greater than 50mb!')
            return []
          }
          if (totalFileSize > TOTAL_FILE_SIZE_LIMIT) {
            handleError('Total size can not be greater than 750mb!')
            return []
          }
          return filteredFiles
        }

        const handleFiles = (e)=>{
          const uploadedFiles = Array.from(e.target.files);
          if(folderUpload==="true"){
            const filteredFiles = filterValidFiles(uploadedFiles);
            let filesFolder = [...(values.folders || [])]
            filteredFiles.map(file=>{
              const subFolderLength = file.webkitRelativePath.split('/')?.length;
              if(subFolderLength===2){
                form.change('isNested',true)
                let existingFolderIndex = filesFolder.findIndex(item=>item.folderName==="masterFolder")
                let existingFolder = existingFolderIndex>-1 ? filesFolder.splice(existingFolderIndex,1) : null
                if(existingFolder){
                  filesFolder = [...filesFolder, {folderName: existingFolder[0].folderName, folderFiles: [...(existingFolder[0].folderFiles || []),file]}]
                }else{
                  filesFolder = [...(filesFolder || []), {folderName: "masterFolder", folderFiles: [file]}]
                }
              }else if(subFolderLength===3){
                let subFolderName = file.webkitRelativePath.split('/')[1]
                let existingFolderIndex = filesFolder.findIndex(item=>item.folderName===subFolderName)
                let existingFolder = existingFolderIndex>-1 ? filesFolder.splice(existingFolderIndex,1) : null
                if(existingFolder){
                  filesFolder = [...filesFolder, {folderName: existingFolder[0].folderName, folderFiles: [...(existingFolder[0].folderFiles || []),file]}]
                }else{
                  filesFolder = [...(filesFolder || []), {folderName: subFolderName, folderFiles: [file]}]
                }
              }
            })
            // console.log(filesFolder)
            form.change('folders',filesFolder)
          }else{
            const filteredFiles = filterValidFiles(uploadedFiles);
            let masterFolderExists = folders.some(item=>item.folderName==="masterFolder")
            let filesData = []
            if(masterFolderExists){
              filesData = folders.map(item=>{
                if(item.folderName==="masterFolder"){
                  return {
                    folderName: "masterFolder", 
                    folderFiles : [...item.folderFiles, ...filteredFiles]
                  }
                }else return item
              })
            }else{
              filesData = [...folders, {folderName: "masterFolder", folderFiles : filteredFiles}]
            }
            form.change('folders',filesData)
          }
        }

        const handleToggle = () =>{
          if(folderUpload==="true"){
            setFolderUpload(false)
          }else{
            setFolderUpload("true")
          }
        }

        const removeFile = (index, folderName, filesLength) => {
          let updatedFiles
          if(filesLength===1){
            if(folderName==="masterFolder"){
              form.change('isNested', false)
            }
            updatedFiles = folders.filter(item=>item.folderName!==folderName)
          }else{
            updatedFiles = folders.map(item=>{
              if(item.folderName===folderName){
                return {
                  ...item,
                  folderFiles: item.folderFiles.filter((file,fileIndex)=>index!==fileIndex)
                }
              }else return item
            })
          }
          form.change("folders", updatedFiles)
        }

        const displayFiles = (files) => {
          let isMasterFolder = files?.some(item=>item.folderName==="masterFolder")
          let masterFolderFiles = isMasterFolder && files.find(item=>item.folderName==="masterFolder").folderFiles
          let isSubFolders = files?.length > (isMasterFolder ? 1 : 0)
          let subFolderFiles = isSubFolders && files.filter(item=>item.folderName!=="masterFolder")
          return (
            <div>
              {isMasterFolder && <h3>Master Folder</h3>}
              {isMasterFolder && masterFolderFiles.map((file,index) => (
                <div className={css.subFolderdiv} key={file.name}>
                  <span>
                    <FileView file={file}>
                      <span className={css.close} onClick={() => removeFile(index, "masterFolder", masterFolderFiles.length)}>
                        <IconCard brand="cross" />
                      </span>
                    </FileView>
                  </span>
                </div>
              ))}
              {isSubFolders && <h3>Sub folders</h3>}
              {isSubFolders && subFolderFiles.map((item) => (
                <div key={item.folderName} className={css.subFolderdiv}>
                  <p>{item.folderName}</p>
                  {item.folderFiles.map((file,index) => (
                    <span key={file.name}>
                      <FileView file={file}>
                        <span className={css.close} onClick={() => removeFile(index, item.folderName, item.folderFiles.length)}>
                          <IconCard brand="cross" />
                        </span>
                      </FileView>
                    </span>
                  ))}
                </div>
              ))}
            </div>
          );
        };

        return (
          <Form className={css.queryReportForm} onSubmit={handleSubmit}>
            <div className={css.container}>
              <div className={css.briefGenerator}>
                <span className={css.generatorTitle}>
                  <FormattedMessage id={"OpenAIQueryReportForm.heading"} />
                </span>
              </div>
              <CaptureRunNameField step={step} form={form} values={values}>
                <div className={css.attachment}>
                  <h3 className={css.firstFieldTitle}>
                    <span className={css.browserBack} onClick={() => form.change('step', 1)}>
                      <IconCard brand="backArrow" />
                    </span>
                    <FormattedMessage id={"OpenAIQueryReportForm.subHeading"} />
                  </h3>
                  <div className={css.documentSwitcher}>
                    <label><FormattedMessage id={"OpenAIQueryReportForm.uploadFolder"} /></label>
                    <div className={classNames(css.switcherWrapper, folderUpload==="true" && css.toggled)} onClick={handleToggle}>
                      <div className={css.ball}></div>
                    </div>
                  </div>
                  <p><FormattedMessage id='OpenAIQueryReportForm.subHeadingNote' /></p>
                  
                  {(filesLength() < 50) && (
                    <Field
                      id="file"
                      name="file"
                      accept={ACCEPT_FILE}
                      type="file"
                    >
                      {fieldprops => {
                        const { accept, input, disabled: fieldDisabled } = fieldprops;
                        const { name, type } = input;
                        const onChange = async e => handleFiles(e)
                        const inputProps = { accept, id: name, name, onChange, type };
                        return (
                          <div>
                            {fieldDisabled ? null : (
                              <input {...inputProps} className={css.addImageInput} multiple={true} webkitdirectory={folderUpload}/>
                            )}
                            <label type='button' htmlFor={name} className={css.attachmentLabel}>
                              <IconCard brand="upload" />
                              <span className={css.dragDrop}><FormattedMessage id={folderUpload ? "OpenAIQueryReportForm.uploadFolder" : "GigAppsPage.multipleFilesUpload"} /></span>
                              <span className={css.docType}>
                                <FormattedMessage id="JobListing.documentAllowList" />
                              </span>
                            </label>
                          </div>
                        );
                      }}
                    </Field>
                  )}
                </div>
                {!!fileExtensionError && (
                  <div>
                    <span className={css.error}>{fileExtensionError}</span>
                  </div>
                )}
                {!!error && (
                  <div>
                    <span className={css.error}>{error}</span>
                  </div>
                )}
                {displayFiles(values?.folders)}
                <div className={css.actionButtons}>
                  <SecondaryButton
                    type='button'
                    className={css.resetButton}
                    onClick={() => form.reset()}
                    disabled={openaiRequest}
                  >
                    <FormattedMessage id="OpenAIContractForm.resetButtonText" />
                  </SecondaryButton>
                  <PrimaryButton
                    type='submit'
                    onClick={(e)=>{
                      if(submitDisabled){
                        e.preventDefault()
                        !error && handleError('Please choose a file first')
                      }
                    }}
                    className={classNames(submitDisabled ? css.disabledSubmitButton : css.submitButton )}
                    inProgress={openaiRequest}
                  >
                    <FormattedMessage id="OpenAIContractForm.submitButtonText" />
                  </PrimaryButton>
                </div>
              </CaptureRunNameField>
            </div>
          </Form>
        )
      }}
    />
  )
}

export default OpenAIQueryReportForm