import classNames from 'classnames';
import React from 'react'
import css from '../GigAppTransactionPanel.module.css';
import IconBannedUser from '../../IconBannedUser/IconBannedUser';
import NamedLink from '../../NamedLink/NamedLink';
import { getUserFirmInfo } from '../../../util/destructorHelpers';
import { FormattedMessage } from 'react-intl';
import { USER_ROLE_CSM } from '../../../util/types';

function MemberCard(props) {
  const {index, member, userData, connectedUsers} = props;
  const firmId = getUserFirmInfo({ userData, email: member.email }).firmId;
  const firmName = getUserFirmInfo({ userData, email: member.email }).firmTitle;
  const role = getUserFirmInfo({ userData, email: member.email }).role;

  const csmTooltipText = firmName
    ? `This user is a Customer Success Manager working on behalf of ${firmName} on the InsightGig platform.`
    : 'This user is a Customer Success Manager (CSM) on the InsightGig platform. CSMs work on behalf of their clients.';

  const UserAvatar = ({ member, isCard, role, csmTooltipText }) => {
    const isCsm = role === USER_ROLE_CSM;
    const classess = classNames(
      isCard ? css.cardImage : css.imagesBox,
      isCsm && css.csmBadge,
      isCsm && csmTooltipText && css.csmTooltip
    );
    const isOnline = connectedUsers?.[member.id]?.userId || false;
    return (
      <span className={classess} data-tooltip={csmTooltipText}>
        {!member?.email ? (
          <IconBannedUser className={css.profileImage} />
        ) : member.profileImage ? (
          <img src={member.profileImage} />
        ) : (
          <span className={classNames(css.profileImage)}>
            {member.name
              .match(/(\b\S)?/g)
              .join('')
              .toUpperCase()}
          </span>
        )}
        <span className={isOnline ? css.onlineDot : css.offlineDot}></span>
      </span>
    );
  };

  return (
    <div className={css.memberTab} key={index}>
      <UserAvatar member={member} isCard={false} role={role} />
      <span className={css.titleName}>{member.name}</span>
      <div className={css.memberCard}>
        <div className={css.memberAvatar}>
          <UserAvatar
            member={member}
            isCard={true}
            role={role}
            csmTooltipText={csmTooltipText}
          />
          <div>
            <h3>{member.name}</h3>
            {firmId && firmName && (
              <NamedLink
                name="EditBecomeInsightGigPartnerPageDetails"
                params={{ id: firmId, slug: firmName }}
              >
                <h4>{firmName}</h4>
              </NamedLink>
            )}
          </div>
        </div>
        <span className={css.dividerLine}></span>
        <div className={css.memberDetails}>
          <h4>
            <FormattedMessage id="TransactionPanel.role" />
          </h4>
          <h4 style={{ color: '#B2B2B2' }}>{member.role}</h4>
        </div>
        <div className={css.memberDetails}>
          <h4>
            <FormattedMessage id="TransactionPanel.email" />
          </h4>
          <h4 style={{ color: '#B2B2B2' }}>{member.email}</h4>
        </div>
      </div>
    </div>
  );
}

export default MemberCard