import React from 'react';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';

import css from './ListingPage.module.css';
import { currencyFeeTemp } from "../../marketplace-custom-config";
import { BriefLocation } from '../../components';

const SectionAdditionalMaybe = props => {
  const { publicData } = props;
  const { maxPrice, minPrice, projectTimelines, currencyFee, locations: briefLocation } = publicData ?? {};
  const currencySymbol = currencyFeeTemp.filter(el => el.key === currencyFee)[0]?.sym || '$'
  const price = (currency) => currencySymbol + '' + new Intl.NumberFormat().format(currency)

  return (
    <>
      {projectTimelines &&
        <div className={css.shadowBox}>
          <div className={css.sectionDescription}>
            <h2 className={css.descriptionlabel}>
              <FormattedMessage id="ListingPage.timeline" />
            </h2>
            <p className={css.description}>
              {projectTimelines}
            </p>
          </div>
        </div>
      }
      {maxPrice &&
        <div className={css.shadowBox}>
          <div className={css.sectionDescription}>
            <h2 className={css.descriptionlabel}>
              <FormattedMessage id="ListingPage.budget" />
            </h2>
            <p className={css.description}>
              {price(minPrice)}<span className={css.divider}> -</span>{price(maxPrice)}
            </p>
          </div>
        </div>
      }
      {!!briefLocation?.length && (
        <div className={css.shadowBox}>
          <BriefLocation briefLocation={briefLocation} />
        </div>
      )}
    </>
  );
};

export default injectIntl(SectionAdditionalMaybe);
