import React, { useState } from 'react'
import css from './SendOfferForm.module.css'
import { FormattedMessage, injectIntl } from 'react-intl';
import FileView from '../../components/FileView/FileView';
import moment from 'moment';
import classNames from 'classnames';
import {Field, Form as FinalForm } from 'react-final-form';
import { Form, PrimaryButton, IconCard, ExternalLink, FieldTextInput, FieldDateInput, FieldSelect } from '../../components';
import { currencyFeeTemp } from '../../marketplace-custom-config';
import { ACCEPT_FILE } from '../../util/types';
import { compose } from 'redux';

const validFileFormat = ['pdf','doc','docx','ppt','pptx'];

function SendOfferForm(props) {
  return (
    <FinalForm
      {...props}
      render={formRenderProps => {
        const {
          className,
          ready,
          handleSubmit,
          pristine,
          submitProgress,
          values,
          form,
          closeModal,
          isFullTimeJob,
          intl,
        } = formRenderProps;
        
        const [error, setError]= useState('');
        const [monthCounter, setMonthCounter] = useState(1);
        const classes = classNames(css.root, className);
        const submitDisabled = pristine || !values?.salary || !values?.startDate || !values.currency;
        const selectedDate = values?.startDate?.date ?? moment();
        const endDate = moment(selectedDate).add(monthCounter, 'month');
        const isDayBlocked = day => values && values.startDate && moment(values.startDate.date).isSameOrAfter(moment(day));

        const jobOfferTitle = intl.formatMessage({ id: 'SendOfferForm.jobOfferTitle' });
        const secondmentOfferTitle = intl.formatMessage({ id: 'SendOfferForm.secondmentOfferTitle' });
        const jobSalaryLabel = intl.formatMessage({ id: 'SendOfferForm.jobSalaryLabel' });
        const secondmentSalaryLabel = intl.formatMessage({ id: 'SendOfferForm.secondmentSalaryLabel' });
        const startDateLabel = intl.formatMessage({ id: 'SendOfferForm.startDateLabel' });
        const endDateLabel = intl.formatMessage({ id: 'SendOfferForm.endDateLabel' });
        const attachmentLabel = intl.formatMessage({ id: 'SendOfferForm.attachmentLabel' });

        function handleMonthCounter(operation){
          if(operation === 'add') setMonthCounter(monthCounter + 1)
          else {
            if(monthCounter === 1) return
            setMonthCounter(monthCounter - 1)
          }
        }

        function handleError(errorMessage){
          setError(errorMessage)
          setTimeout(() => setError(''), 3000)
        }

        return(
          <Form className={classes} onSubmit={e => {
            e.preventDefault()
            form.change('secondmentMonth', monthCounter)
            handleSubmit(values)
          }}>
            <h2 className={css.title}>{isFullTimeJob ? jobOfferTitle : secondmentOfferTitle}</h2>
            <div className={css.currencyLabel}>
              <FieldSelect
                id='currency'
                name='currency'
                className={css.currencySelector}
                label={isFullTimeJob ? jobSalaryLabel : secondmentSalaryLabel}
              >
                <option >Select</option>
                {currencyFeeTemp.map(({ sym, key }) => (
                  <option key={key} value={key}>{` ${sym} - ${key}`}</option>
                ))}
              </FieldSelect>
              <FieldTextInput
                id="salary"
                name="salary"
                type="number"
                className={css.salaryInput}
                // placeholder='0.00'
              />
            </div>
            <div className={css.calenderWrapper}>
              <FieldDateInput
                id="startDate"
                name="startDate"
                className={css.fieldDateInput}
                label={startDateLabel}
                placeholderText={moment().format('DD/MM/YYYY')}
                // validate={required(startDateRequiredMessage)}
              />
              {isFullTimeJob ? (
                <FieldDateInput
                  id="endDate"
                  name="endDate"
                  className={css.fieldDateInput}
                  isDayBlocked={isDayBlocked}
                  label={endDateLabel}
                  placeholderText={moment().format('DD/MM/YYYY')}
                  // validate={required(deadlineRequiredMessage)}
                />
              ) : (
                <>
                  <div className={css.monthCounter}>
                    <label>Months</label>
                    <div>
                      <span onClick={() => handleMonthCounter('subtract')}>-</span>
                      <p>{monthCounter}</p>
                      <span onClick={() => handleMonthCounter('add')}>+</span>
                    </div>
                  </div>
                  <div className={css.endDateLabel}>
                    <label>End date</label>
                    <span>{endDate.format('DD/MM/YYYY')}</span>
                  </div>
                </>
              )}
            </div>
            <div className={css.attachmentLabel}>{attachmentLabel}</div>
            {!values?.file?.name  ? (
              <div className={css.inputSection}>
                <Field
                  id="offerAttachment"
                  name="offerAttachment"
                  accept={ACCEPT_FILE}
                  type="file"
                >
                {fieldprops => {
                  const { accept, input} = fieldprops;
                  const { name, type } = input;
                  const onChange = async (e) => {
                    const file = e.target.files[0];
                    const extension = file?.name?.split('.').at(-1);

                    if(!validFileFormat.includes(extension)){
                      handleError('Only PDF, DOC or PPT formats accepted.')
                      return
                    }
                    form.change('file', file)
                  };
                  const inputProps = { accept, id: name, name, onChange, type };
                  return <input {...inputProps} className={css.addImageInput} />
                }}
                </Field>
                <label htmlFor="offerAttachment" className={css.inputLabel}>
                  <IconCard brand="upload" />
                  <span className={css.dragDrop}><FormattedMessage id="ZohoSign.dragAndDropUpload" /></span>
                  <span className={css.docType}>
                    <FormattedMessage id="JobListing.documentAllowList" />
                  </span>
                </label>
                {error && <p className={css.errorMessage}>{error}</p>}
              </div>
            ) : (
              <FileView file={values?.file}>
                <span className={css.removeFile} onClick={() => form.change('file', {})}>
                  <FormattedMessage id="ZohoSignForm.remove"/>
                </span>
              </FileView>
            )}
            <div className={css.buttonWrapper}>
              <span className={css.buttonCancel} onClick={() => closeModal()}>
                Cancel
              </span>
              <PrimaryButton
                className={css.buttonSubmit}
                type='sumbit'
                disabled={submitDisabled}
                inProgress={submitProgress}
              >
                Send job offer
              </PrimaryButton>
            </div>
          </Form>
        )
      }}
    />
  )
}

export default compose(injectIntl)(SendOfferForm)