import classNames from "classnames";
import { FormattedMessage, injectIntl } from 'react-intl';
import { compose } from 'redux';
import {Field, Form as FinalForm } from 'react-final-form';
import { FieldCheckbox, FieldTextInput, Form, IconCard, PrimaryButton, } from '../../components';
import css from './SignContractForm.module.css'
import { FieldRadioButton } from "../../components";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TRANSITION_COMFIRM_CONTRACT_SIGNATURE, TRANSITION_REQUEST_CONTRACT_SIGNATURE } from "../../util/transaction";
import { actionInProgress, actionSuccess, fetchZohoDocument, handleInitOnlineContract, handleJobTransition, requestCaseStudy, updateTransactionMetaData} from "../../containers/TransactionPage/TransactionPage.duck";
import { ACCEPT_FILE, OFFLINE, ONLINE, SKIP_CONTRACT } from "../../util/types";
import FileView from "../../components/FileView/FileView";
import FormData from "form-data";
import { types as sdkTypes } from '../../util/sdkLoader';
import { storeContractSession } from "../../util/api";
const { UUID } = sdkTypes;

const SignContractForm = (props) => {
  const {
    txId, 
    closeModal, 
    clientName, 
    access_token, 
    clientEmail, 
    expertName, 
    expertEmail, 
    contractDetails, 
    isContractSigned, 
    isClient,
    transaction,
    openPaymentForm,
  } = props;
  const dispatch = useDispatch();
  const {actionError} = useSelector(state => state.TransactionPage);
  const {request_id} = contractDetails ?? {};

  //Save contract details in database
  async function handleSaveZohoContract(request_id){
    const params = {
      id: request_id,
      transactionId: txId,
      expert: expertEmail,
      client: clientEmail
    }
    const response = await storeContractSession(params);
  }

  async function handleOnlineContract(values){
    const {contractFile, note, signatories} = values;
    const clientSign = signatories?.includes("clientSign");

    const formData = new FormData();
    formData.append('data', JSON.stringify({
      clientSign,
      access_token,
      clientName,
      clientEmail,
      expertName,
      expertEmail,
      note,
      isJobContract: true
    }));
    formData.append("file1", contractFile);

    const request_id = await dispatch(handleInitOnlineContract({formData, transaction, clientSign}));
    if(request_id){
      handleSaveZohoContract(request_id)
      closeModal()
    };
  }

  async function handleOfflineContract(){
    const transition = TRANSITION_REQUEST_CONTRACT_SIGNATURE;
    const param = {
      contractDetails : {contractType: OFFLINE},
      zohoAccessToken: null
    }
    dispatch(requestCaseStudy({ transaction, param, isTransition: true, transition}))
    .then(response => {
      if(response?.data?.data?.id) closeModal();
    })
  }

  async function handleSkipContract(){
    dispatch(actionInProgress())
    const params = {
      txId: new UUID(txId),
      bodyParam: {
        isContractSkipped: true,
        zohoAccessToken: null
      },
    };
    dispatch(updateTransactionMetaData(params)).then(transaction => {
      if(transaction?.id){
        dispatch(actionSuccess())
        closeModal()
        openPaymentForm()
      };
    })
  }

  useEffect(() => {
    if(isClient && access_token && request_id && !isContractSigned){
      dispatch(fetchZohoDocument({ access_token, request_id})).then(res => {
        if(res.status === "success" && res.requests.sign_percentage === 100){
          const transition = TRANSITION_COMFIRM_CONTRACT_SIGNATURE;
          dispatch(handleJobTransition( transaction, transition));
        }
      })
    }
  }, [txId])
  
  return(
    <FinalForm 
      {...props}
      onSubmit={handleOnlineContract}
      render={formRenderProps => {
        const {
          className,
          ready,
          handleSubmit,
          pristine,
          submitProgress,
          form,
          values,
          closeModal,
          intl,
        } = formRenderProps;
        
        const classes = classNames(css.root, className);
        const [step, setStep] = useState('options');
        const [showObject, setShowObject] = useState(false);
        const [file, setFile]= useState({});
        const submitDisabled = pristine || !values?.note || !values?.contractFile;

        const title = intl.formatMessage({ id: 'SignContractForm.title' });
        const subTitle = intl.formatMessage({ id: 'SignContractForm.subTitle' });
        const clientSignLabel = intl.formatMessage({ id: 'ZohoSignForm.myself'});
        const candidate = intl.formatMessage({ id: 'ZohoSignForm.candidate'});
        const privateNotePlaceholderMessage = intl.formatMessage({ id: 'ZohoSignForm.privateNotePlaceholderMessage'});
        const privateNoteLabel = intl.formatMessage({ id: 'ZohoSignForm.privateNotes' });

        showObject && document.body.addEventListener('click', () => {
          setShowObject(false);
        })

        // console.log(values)
        return(
          <Form className={classes} onSubmit={handleSubmit}>
            {step === 'options' ? (
              <>
                <h2 className={css.title}>{title}</h2>
                <label>{subTitle}</label>
                <FieldRadioButton
                  className={css.radioButton}
                  name='contract'
                  label='Online'
                  id={ONLINE}
                  value={ONLINE}
                />
                <FieldRadioButton
                  className={css.radioButton}
                  name='contract'
                  label='Offline'
                  id={OFFLINE}
                  value={OFFLINE}
                />
                <FieldRadioButton
                  className={css.radioButton}
                  name='contract'
                  label='Skip this step'
                  id={SKIP_CONTRACT}
                  value={SKIP_CONTRACT}
                />
                <div className={css.buttonWrapper}>
                  <span className={css.buttonCancel} onClick={() => closeModal()}>
                    Cancel
                  </span>
                  <PrimaryButton
                    className={css.buttonSubmit}
                    type='button'
                    disabled={!values?.contract}
                    inProgress={submitProgress}
                    onClick={() => {
                      values?.contract === OFFLINE ? handleOfflineContract(values?.contract) 
                        : values?.contract === ONLINE ? setStep('zohoContract') 
                        : handleSkipContract()
                    }}
                  >
                    Submit
                  </PrimaryButton>
                </div>
              </>
            ) : step === 'zohoContract' && (
              <div className={css.contractForm}>
                <h2><FormattedMessage id="SignContractForm.sendContractLabel"/></h2>
                <h4><FormattedMessage id="ZohoSignForm.guidanceNotes"/></h4>
                <p><FormattedMessage id="ZohoSignForm.messageTwo"/></p>
                <ul>
                  <li><FormattedMessage id="SignContractForm.addTagInstructionOne" /></li>
                  <li><FormattedMessage id="SignContractForm.addTagInstructionTwo" /></li>
                  <li><FormattedMessage id="SignContractForm.addTagInstructionThree" /></li>
                </ul>
                <div className={css.unSureHeading}>
                  <FormattedMessage id="ZohoSign.stillUnsure" />
                  <a onClick={() => setShowObject(!showObject)}>
                    <FormattedMessage id="ZohoSign.seeExampleDocument" />
                  </a>
                </div>
                <div className={css.dividerLine} />
                {showObject &&
                  <object className={css.object} data={`${window.location.origin}/static/SampleAgreement.pdf`}></object>
                }
                {!file?.name ? (
                  <div className={css.inputSection}>
                    <Field
                      id="contractFile"
                      name="contractFile"
                      accept={ACCEPT_FILE}
                      type="file"
                    >
                      {fieldprops => {
                        const { accept, input} = fieldprops;
                        const { name, type } = input;
                        const onChange = async (e) => {
                          setFile(e.target.files[0])
                          form.change('contractFile', e.target.files[0])
                        };
                        const inputProps = { accept, id: name, name, onChange, type };
                        return <input {...inputProps} className={css.addImageInput} />
                      }}
                    </Field>
                      <label htmlFor="contractFile" className={css.inputLabel}>
                        <IconCard brand="upload" />
                        <span className={css.dragDrop}><FormattedMessage id="ZohoSign.dragAndDropUpload" /></span>
                        <span className={css.docType}>
                          <FormattedMessage id="ZohoSign.pdfDoc" />
                        </span>
                      </label>
                  </div>
                  ) : (
                  <FileView file={file}>
                    <span className={css.removeFile} onClick={() => {
                      setFile({})
                      form.change('contractFile', {})
                    }}>
                      <FormattedMessage id="ZohoSignForm.remove"/>
                    </span>
                  </FileView>
                )}
                <div className={css.clientSignCheck}>
                  <h4><FormattedMessage id="ZohoSignForm.signatories" /></h4>
                  <FieldCheckbox
                    className={css.checkbox} 
                    name={'signatories'} 
                    label={clientSignLabel} 
                    id={'clientSign'} 
                    value={'clientSign'} 
                  />
                  <FieldCheckbox 
                    className={css.checkbox}
                    name={'signatories'} 
                    label={candidate} 
                    id={'expertSign'} 
                    value={'expertSign'}
                    disabled 
                  />
                  <div className={css.dividerLine} />
                  <FieldTextInput
                    id="note"
                    name="note"
                    type="text"
                    label={privateNoteLabel}
                    placeholder={privateNotePlaceholderMessage}
                  />
                </div>
                {!!actionError?.message && (
                  <p className={css.error}><FormattedMessage id='ZohoSign.uploadDocumentError' /></p>
                )}
                <div className={css.buttonWrapper}>
                  <span className={css.buttonCancel} onClick={() => setStep('options')}>
                    Back
                  </span>
                  <PrimaryButton
                    className={css.buttonSubmit}
                    type='submit'
                    disabled={submitDisabled}
                    inProgress={submitProgress}
                  >
                    Next: Send for signature
                  </PrimaryButton>
                </div>
              </div>
            )}
            
          </Form>
        )
      }}
    />
  )
}

export default compose(injectIntl)(SignContractForm)