import React, { useEffect, useState } from 'react';
import { bool, func, shape, string } from 'prop-types';
import classNames from 'classnames';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import { findOptionsForSelectFilter } from '../../util/search';
import { propTypes } from '../../util/types';
import config from '../../config';
import { Button, FieldTextInput, FieldCheckboxGroup, Form, IconCard } from '../../components';
import { v4 as uuidv4 } from 'uuid'; 
import css from './EditListingBriefFeaturesForm.module.css';
import { storeKeyword } from '../../util/api';
import ServiceSection from '../EditListingFeaturesForm/ServiceSection';
import IndustrySection from '../EditListingFeaturesForm/IndustrySection';
import SoftwareInsights from '../EditListingFeaturesForm/SoftwareInsights';
import InsightTechniquesOrModel from '../EditListingFeaturesForm/InsightTechniquesOrModel';
// import { colourOptions } from '../data';
// import { ColourOption, colourOptions } from '../data';

const EditListingBriefFeaturesFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutators }}
    render={formRenderProps => {
      const {
        disabled,
        ready,
        rootClassName,
        className,
        handleSubmit,
        intl,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        filterConfig,
        values,
        form,
      } = formRenderProps;

      const classes = classNames(rootClassName || css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;

      const { updateListingError, showListingsError } = fetchErrors || {};
      const errorMessage = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingBriefFeaturesForm.updateFailed" />
        </p>
      ) : null;

      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingBriefFeaturesForm.showListingFailed" />
        </p>
      ) : null;

      const submitDisabled = disabled || submitInProgress;

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {errorMessage}
          {errorMessageShowListing}
          <div className={css.showListingFeatures}>
            <div className={css.selectedQueries}>
              <ServiceSection values={values} form={form} intl={intl} css={css}/>
            </div>
            <div className={css.selectedQueries}>
              <IndustrySection values={values} form={form} intl={intl} css={css}/>
            </div>
            <div className={css.selectedQueries}>
              <SoftwareInsights values={values} form={form} intl={intl} css={css}/>
            </div>
            <div className={css.selectedQueries}>
              <InsightTechniquesOrModel values={values} form={form} intl={intl} css={css}/>
            </div>
          </div>
          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button>
        </Form>
      );
    }}
  />
);

EditListingBriefFeaturesFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  fetchErrors: null,
  filterConfig: config.custom.filters,
};

EditListingBriefFeaturesFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  // name: string.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  filterConfig: propTypes.filterConfig,
};

export default compose(injectIntl)(EditListingBriefFeaturesFormComponent);
