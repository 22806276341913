import React, { useEffect, useState } from 'react';
import css from './OrganisationProfilePanel.module.css';
import noImage from '../../assets/no-image.jpg';
import { ExternalLink, IconCard, NamedLink } from '../../components';
import { FormattedMessage } from '../../util/reactIntl';
import { v4 as uuidv4 } from 'uuid';
import {
  calculateFirmDashboardUserRating,
  calculatePrivateProposalCounts,
  calculatePublicPostedProjects,
  calculateProjectUnderwayCounts,
  calculatePublicProposalCounts,
  getFirstChar,
  calculatePrivateSharedProjects,
} from '../../util/destructorHelpers';
import { useDispatch, useSelector } from 'react-redux';
import { getListingsById, getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { AFFILIATED_FIRM_INVITE_APPROVED, AVERAGE_RATING } from '../../util/types';
import classNames from 'classnames';
import { getUserShortName } from '../../util/typeHelpers';
import moment from 'moment';
import { ensureOwnListing } from '../../util/data';

const OrganisationProfilePanel = props => {
  const { firmData, firmName, ownFirm, isClient } = props;
  const state = useSelector(state => state);
  const { id } = state.FirmPage;
  const { currentPageResultIds, firm } = state.FirmDashBoardPage;
  
  const getListing = id => {
    const ref = { id, type: 'listing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };
  const currentListing = ensureOwnListing(getListing(id))

  const listings = getListingsById(state, currentPageResultIds);
  const { title = '', publicData = {} } = !!currentListing?.id && currentListing?.attributes;
  const {
    aboutUs,
    department,
    locations,
    firmSize,
    firmWebsite,
    industrySector,
    firmLogoId,
    firmBackgroundImageId,
    typeOfFirm,
  } = publicData;
 
  const listingCreatedAt = !!currentListing?.id && moment(currentListing?.attributes?.createdAt).format('MMM YYYY');
  const backgroundImage = currentListing?.images
    ?.filter(d => d?.id?.uuid === firmBackgroundImageId)
    ?.at(0)?.attributes?.variants?.['scaled-xlarge']?.url;
  const logo = currentListing?.images?.filter(d => d?.id?.uuid === firmLogoId)?.at(0)?.attributes
    ?.variants?.['scaled-small']?.url;
  const userDetails = !!firm?._id && firm?.newFirmUserDetails

  const { totalPrivateProposals } = !!firm?._id && calculatePrivateProposalCounts(userDetails);
  const { totalUnderwayProjects } = !!firm?._id && calculateProjectUnderwayCounts(userDetails);
  const { totalReviews, averageRating } = !!firm?._id && calculateFirmDashboardUserRating(userDetails);
  const { publiclyPostedProjects } = !!firm?._id && calculatePublicPostedProjects(userDetails);
  const { privatelySharedProjects } = !!firm?._id && calculatePrivateSharedProjects(userDetails);
  const { totalPublicProposals } = !!firm?._id && calculatePublicProposalCounts(userDetails);

  return (
    <div className={css.contentPanel}>
      <div className={css.coverImage}>
        {backgroundImage ? <img src={backgroundImage} /> : <img src={noImage} />}
        <span className={css.panelLogo}>
          {logo ? <img src={logo} /> : <span className={css.noImage}>{getFirstChar(title)}</span>}
        </span>
      </div>
      <div className={css.contentBody}>
        <div className={css.contentLeft}>
          <div className={css.heading}>
            <h2>{title}</h2>
            <div className={css.firmType}>{typeOfFirm}</div>
            <div className={css.listingCreatedTime}>
              On InsightGig since {listingCreatedAt}
            </div>
            <div className={css.projectRating}>
              <div className={css.projectRow}>
                {isClient ?
                  <>
                    <p><FormattedMessage id="OrganisationProfilePanel.numberOfProjectSharedHeading" /></p>
                    <div>{publiclyPostedProjects}</div>
                  </> :
                  <>
                    <p><FormattedMessage id="OrganisationProfilePanel.numberOfBidsProjectSharedHeading" /></p>
                    <div>{totalPublicProposals}</div>
                  </>
                }
              </div>
              <div className={css.projectRow}>
                {isClient ?
                  <>
                    <p><FormattedMessage id="OrganisationProfilePanel.numberOfPrivateProjectSharedHeading" /></p>
                    <div>{privatelySharedProjects}</div>
                  </> :
                  <>
                    <p><FormattedMessage id="OrganisationProfilePanel.numberOfBidsPrivateProjectSharedHeading" /></p>
                    <div>{totalPrivateProposals}</div>
                  </>
                }

              </div>
              <div className={css.projectRow}>
                <p><FormattedMessage id="OrganisationProfilePanel.numberOfProjectsUnderwayHeading" /></p>
                <div>{totalUnderwayProjects}</div>
              </div>
              <div className={css.projectRow}>
                <p><FormattedMessage id="OrganisationProfilePanel.averageFirmRatingHeading" /></p>
                <div className={css.countBox}>
                  <IconCard brand="star" />
                  <FormattedMessage
                    id="OrganisationProfilePanel.averageFirmRatingValue"
                    values={{ averageRating: averageRating?.toFixed(2) ?? 0 }}
                  />
                  <span className={css.reviewText}>
                    <FormattedMessage id="OrganisationProfilePanel.totalReviews" values={{ totalReviews: totalReviews ?? 0 }}/>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className={css.aboutSection}>
            <div className={css.aboutHeading}>
              <FormattedMessage id="OrganisationProfilePanel.aboutUsHeading" />
            </div>
            <div className={css.aboutDetail}>{aboutUs}</div>
          </div>
          <div className={css.locationBox}>
            <div className={css.locationHeading}>
              <FormattedMessage id="OrganisationProfilePanel.locationsHeading" />
            </div>
            <div className={css.locationList}>
              <ul>
                {locations?.map(({ Location: { selectedPlace: { address } } = {} } = {}) => (
                  <li key={uuidv4()}>{address ?? ''}</li>
                ))}
              </ul>
            </div>
          </div>
          <div className={css.websiteLink}>
            <div className={css.websiteHeading}>
              <FormattedMessage id="OrganisationProfilePanel.websiteHeading" />
            </div>
            <div className={css.linkWeb}>
              <ExternalLink href={firmWebsite}>{firmWebsite}</ExternalLink>
            </div>
          </div>
          <div className={css.domainExpertise}>
            <div className={css.domainHeading}>
              <FormattedMessage id="OrganisationProfilePanel.domainExpertiseHeading" />
            </div>
            <div className={css.domainCards}>
              {industrySector?.map(i => (
                <span key={uuidv4()}>{i}</span>
              ))}
            </div>
          </div>
          <div className={css.departmentBox}>
            <div className={css.departmentHeading}>
              <FormattedMessage id="OrganisationProfilePanel.departmentHeading" />
            </div>
            <div className={css.departmentCards}>{department}</div>
          </div>
          <div className={css.firmBox}>
            <div className={css.firmHeading}>
              <FormattedMessage id="OrganisationProfilePanel.firmSizeHeading" />
            </div>
            <div className={css.firmBody}>{firmSize}</div>
          </div>
        </div>
        <div className={css.contentRight}>
          <div className={css.cardBox}>
            <div className={css.titleText}>
              <FormattedMessage
                id="OrganisationProfilePanel.firmDataInfoHeading"
                values={{ count: userDetails?.length ?? 0, firmName }}
              />
            </div>
            <div className={css.cardBody}>
              <div className={css.userDetails}>
                {userDetails?.length && userDetails?.map(user => {
                  const isCsm = user?.isCsm;
                  const  csmTooltipText = isCsm 
                    ? `This user is a Customer Success Manager working on behalf of ${firmName} on the InsightGig platform.` 
                    : 'This user is a Customer Success Manager (CSM) on the InsightGig platform. CSMs work on behalf of their clients.';

                  return(
                    <div className={css.firmMembersDetails} key={user?.userId}>
                      <div className={classNames(isCsm && css.csmCardImage, css.cardImage)} data-tooltip={csmTooltipText}>
                        {user?.profilePicture ? (
                          <img src={user?.profilePicture} />
                        ) : (
                          <div className={css.profileImage}>
                            {getUserShortName(user?.name, user?.email)}
                          </div>
                        )}
                      </div>
                      <div className={css.descriptionText}>{user?.name}</div>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
          <div className={css.cardBox}>
            <div className={css.titleText}>
              <FormattedMessage id="OrganisationProfilePanel.affiliatedFirmsHeading" />

            </div>
            {ownFirm?._id && ownFirm?.affiliatedFirm?.map(firm => (

              firm?.status === AFFILIATED_FIRM_INVITE_APPROVED ? <div className={css.cardBody}>
                {firm?.firmLogo ? <div className={css.cardImage} key={firm?.firmId}>
                  <img src={firm?.firmLogo} />
                </div> : null}
                <div className={css.descriptionText}>{firm?.firmName}</div>
              </div> : null
            ))}


          </div>
          <div className={css.cardBox}>
            <div className={css.titleText}>
              <FormattedMessage id="OrganisationPanel.publicListingHeading" />
            </div>
            <div className={css.publiclyBox}>
              {listings?.length !==0 && listings?.map((l, index) => (
                <div className={css.publiclyProjectList} key={l?.id?.uuid} >
                  <div>{index+1}</div>
                  <NamedLink
                    className={css.projectLink}
                    name="ListingPage"
                    params={{ id: l?.id?.uuid, slug: l?.attributes?.title }}
                  >
                    <div className={css.projectText}>{l?.attributes?.title}</div>
                  </NamedLink>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrganisationProfilePanel;
