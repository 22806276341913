import { useState } from "react";
import { getAverageRatingChartData, getChartData, getUserChartData } from "../../util/destructorHelpers";
import Modal from "../Modal/Modal";
import css from './FirmDashboardPanel.module.css'
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, LineChart, Line, LabelList, ComposedChart, Tooltip } from 'recharts';
import { FormattedMessage } from "react-intl";
import classNames from "classnames";
import { APPLIED_PRIVATE_JOBS, APPLIED_PUBLIC_JOBS, APPS_COMPLETED, APPS_INITIATED, APPS_VIEWED, AVERAGERATING, COMMISSIONED_PROJECTS, COMPLETED_PROJECTS, COMPLETED_RECRUITMENTS, LOGINS, ONGOING_RECRUITMENTS, PRIVATELY_SHARED_JOB, PRIVATE_JOBS, PRIVATE_PROJECTS, PUBLIC_JOBS, PUBLIC_POSTED_JOB, PUBLIC_PROJECTS, UNDERWAY_PROJECTS, USERS } from "../../util/types";

export const LAST_YEAR = 'lastYear';
export const LAST_THREE_MONTHS = 'lastThreeMonths';
const LAST_30_DAYS = 'last30Days';

const CustomizedLabel = (props) => {
  const { x, y, stroke, value, index, lastIndex, modalType } = props;
  const isLastPoint = index === lastIndex;

  const textStyle = {
    fontSize: 10,
    textAnchor: 'middle',
    // fill: isLastPoint ? '#21ab30' : stroke, // Highlight color for last point
    fill: stroke, // Highlight color for last point
    background: 'red'
  };

  return (
    <text x={x} y={y - 10} style={textStyle}>
      {(modalType===AVERAGERATING && value) ? value.toFixed(1) : value}
    </text>
  );
};

const UserStatsModals = (props) => {
  const {modalType, setModalType, userDetails, isClient, selectedFilter} = props;
  const [tab, setTab] = useState(LAST_YEAR);
  const percentageTextId = tab === LAST_YEAR ? 'UserStatsModals.fromLastYear' : 
    tab === LAST_THREE_MONTHS ? 'UserStatsModals.fromLastQuater' : 'UserStatsModals.fromLast30Days';

  function StatsChart(){
    switch(modalType){
      case USERS: {
        const {count, data, percentage} = getUserChartData(userDetails, tab);
        const isPositive = percentage > 0 ? true : false;
        return (
          <>
            {/* <div className={css.count}>{count}</div> */}
            <div className={css.percentage}>
              {/* <span className={percentage === 0 ? css.neutral : isPositive ? css.positive : css.negative}>
                {percentage === 0 ? '-' : isPositive ? `+${percentage}%` : `${percentage}%`}
              </span>   */}
              {/* <p><FormattedMessage id={percentageTextId} /></p> */}
            </div>
            <BarChart
              width={700}
              height={500}
              data={data}
              margin={{top: 10,left: -15, bottom: 10}}
            >
              <CartesianGrid strokeDasharray="3" vertical={false}/>
              <XAxis dataKey="month" tickLine={false} axisLine={false} tick={{ fontSize: 11}} label={{ value: (tab==="last30Days") ? "Day" : "Month", position: 'insideBottom', offset: -10 }}/>
              <YAxis tickLine={false} axisLine={false} allowDecimals={false} tickCount={6} tickMargin={5} label={{ value: "Number", angle: -90, position: 'insideLeft', offset: 25, dy: 20 }}/>
              <Bar dataKey="count" fill="#FDF0D6" barSize={30}/>
            </BarChart>
          </>  
        )
      }
      case AVERAGERATING: {
        const {rating, data, percentage} = getAverageRatingChartData(userDetails, tab);
        const isPositive = percentage > 0 ? true : false;
        return (
          <>
            {/* <div className={css.count}>{rating}</div> */}
            <div className={css.percentage}>
              {/* <span className={percentage === 0 ? css.neutral : isPositive ? css.positive : css.negative}>
                {percentage === 0 ? '-' : isPositive ? `+${percentage}%` : `${percentage}%`}
              </span>   */}
              {/* <p><FormattedMessage id={percentageTextId} /></p> */}
            </div>
            <LineChart
              width={900}
              height={350}
              data={data}
              margin={{ left: -5, top: 20, right: 15}}
            >
              <CartesianGrid strokeDasharray="2" vertical={false}/>
              <XAxis dataKey="month" tickLine={false} height={60} axisLine={false} tick={{ fontSize: 11, dy: 10}} label={{ value: (tab==="last30Days") ? "Day" : "Month", position: 'insideBottom', offset: 15 }}/>
              <YAxis tickLine={false} axisLine={false} allowDecimals={false} tickCount={6} tickMargin={5} label={{ value: "Average Rating", angle: -90, position: 'insideLeft', offset: 15, dy: 50 }}/>
              <Line type="linear" dataKey="rating" stroke="#F6B430" >
              {/* <Line dataKey="label"> */}
                <LabelList content={<CustomizedLabel modalType={modalType}/>} />
              </Line>
            </LineChart>
          </>
        )
      }
      case LOGINS:
      case PUBLIC_PROJECTS:
      case PRIVATE_PROJECTS:
      case COMMISSIONED_PROJECTS:
      case COMPLETED_PROJECTS: 
      case PUBLIC_JOBS: 
      case PRIVATE_JOBS: 
      case UNDERWAY_PROJECTS:
      case ONGOING_RECRUITMENTS:
      case COMPLETED_RECRUITMENTS:
      case APPLIED_PUBLIC_JOBS:
      case APPLIED_PRIVATE_JOBS:
      case APPS_VIEWED:
      case APPS_INITIATED:
      case APPS_COMPLETED:
      {
        const {count, data, percentage} = getChartData(userDetails, tab, isClient, modalType, selectedFilter);
        const lastIndex = data.length - 1;
        const isPositive = percentage > 0 ? true : false;
        return (
          <>
            {/* <div className={css.count}>{count}</div> */}
            <div className={css.percentage}>
              {/* <span className={percentage === 0 ? css.neutral : isPositive ? css.positive : css.negative}>
                {percentage === 0 ? '-' : isPositive ? `+${percentage}%` : `${percentage}%`}
              </span>   */}
              {/* <p><FormattedMessage id={percentageTextId} /></p> */}
            </div>
            <LineChart
              width={900}
              height={350}
              data={data}
              margin={{ left: -5, top: 20, right: 15}}
            >
              <CartesianGrid strokeDasharray="2" vertical={false}/>
              <XAxis dataKey="xAxis" tickLine={false} height={60} axisLine={false} tick={{ fontSize: 11, dy: 10}} label={{ value: (tab==="last30Days") ? "Day" : "Month", position: 'insideBottom', offset: 15 }}/> 
              <YAxis tickLine={false} axisLine={false} allowDecimals={false} tickCount={6} tickMargin={5} label={{ value: "Number", angle: -90, position: 'insideLeft', offset: 15, dy: 20  }}/>
              <Line type="linear" dataKey="count" stroke="#F6B430" >
              {/* <Line dataKey="label"> */}
                <LabelList dataKey="count" lastIndex={lastIndex} content={<CustomizedLabel />} />
              </Line>
            </LineChart>
          </>
        )
      }
      default: 
        return <></>
    }
  } 

  return(
    <div>
      <Modal
        id="chart"
        isOpen={modalType ? true : false}
        onClose={() => {
          setModalType(null)
          setTab(LAST_YEAR)
        }}
        usePortal
        onManageDisableScrolling={() => {}}
      >
        <>
          <div className={css.modalHeader}>
            <div className={css.modalTitle}>{modalType}</div>
            <div className={css.tabSection}>
                <span 
                  className={classNames(css.tabs, tab === LAST_30_DAYS && css.selected)}
                  onClick={() => setTab(LAST_30_DAYS)}
                >
                  <FormattedMessage id="UserStatsModals.last30DaysMonths" />
                </span>     
              <span 
                className={classNames(css.tabs, tab === LAST_THREE_MONTHS && css.selected)}
                onClick={() => setTab(LAST_THREE_MONTHS)}
              >
                <FormattedMessage id="UserStatsModals.lastThreeMonths" />
              </span>     
              <span 
                className={classNames(css.tabs, tab === LAST_YEAR && css.selected)}
                onClick={() => setTab(LAST_YEAR)}
              >
                <FormattedMessage id="UserStatsModals.lastYear" />
              </span>     
            </div>
          </div>
          <StatsChart />
        </>
      </Modal>
      
    </div>
  )        
}

export default UserStatsModals