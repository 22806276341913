import React, { useEffect, useRef, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { SendMessageForm } from '../../forms';
import { withViewport } from '../../util/contextHelpers';
import css from './RealTimeChatPanel.module.css';
import { injectIntl } from '../../util/reactIntl';
import { compose } from 'redux';
import ActivityFeed from '../ActivityFeed/ActivityFeed';
import {
  CHAT_NOTIFICATION,
  INVITE_STATUS_ACTIVE,
  MESSAGE,
  TRANSACTION_CHAT_PAGE,
} from '../../util/types';
import { types as sdkTypes } from '../../util/sdkLoader';
import { sendChatNotificationEmail, storeNotificationInDb } from '../../containers/TransactionPage/TransactionPage.duck';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { ensureUser } from '../../util/data';
import { getUserDetails } from '../../util/destructorHelpers';
import { collaborator } from '../../ducks/user.duck';
const { UUID } = sdkTypes;

const EVERYONE = '@everyone';
const ONLINE = '@here';
const OFFLINE = '@nothere';

const RealTimeChatPanel = props => {
  const {
    intl,
    currentTransaction,
    isMobSaf,
    isReviewd,
    isChatExpired,
    currentUser,
    fetchMessagesInProgress,
    onOpenReviewModal,
    onShowMoreMessages,
    setClearForm,
    clearAttachmentForm,
    messages,
    activeTab,
    isJobListing,
    message,
    socket,
    unreadMessages,
    membersData,
    onManageDisableScrolling,
    isOnline
  } = props;

  const state = useSelector(state => state);
  const connectedUsers = state?.user?.connectedUsers;
  const dispatch = useDispatch();
  const [sendMessageFormFocused, setSendMessageFormFocused] = useState(false);

  // const [typingStatus, setTypingStatus] = useState('');
  const divRef = useRef();
  const currentUserEmail = currentUser?.id && getUserDetails(currentUser)?.email;
  const currentProvider = !!currentTransaction?.id && currentTransaction?.provider;
  const currentCustomer = !!currentTransaction?.id && currentTransaction?.customer;
  const ensuredCollaborator = collaborator(currentUser, currentTransaction);
  const currentAuthor =
    !!currentUser?.id?.uuid && currentUser?.id?.uuid === currentCustomer?.id?.uuid
      ? currentCustomer
      : currentUser?.id?.uuid === currentProvider?.id?.uuid
        ? currentProvider
        : !!ensuredCollaborator
          ? ensuredCollaborator
          : null;
  const ensuredAuthor = ensureUser(currentAuthor);
  const ensuredCustomer = ensureUser(currentCustomer);
  const ensuredProvider = ensureUser(currentProvider);
  const isCustomer = ensuredCustomer?.id?.uuid === currentUser?.id?.uuid;
  const isProvider = ensuredProvider?.id?.uuid === currentUser?.id?.uuid;
  const authorName = getUserDetails(ensuredAuthor)?.fullName;

  const authorEmail = ensuredAuthor?.id && ensuredAuthor?.attributes?.profile?.publicData?.email;
  const customerName = getUserDetails(ensuredCustomer)?.firstName;
  const customerEmail = getUserDetails(ensuredCustomer)?.email;
  const providerName = getUserDetails(ensuredProvider)?.firstName;
  const providerEmail = getUserDetails(ensuredProvider)?.email;
  const collaboratorName = getUserDetails(ensuredCollaborator)?.firstName;
  const userName =
    ensuredProvider?.id?.uuid === currentUser?.id?.uuid
      ? customerName
      : ensuredCustomer?.id?.uuid === currentUser?.id?.uuid
        ? providerName
        : collaboratorName;
  const currentPath = typeof window !== 'undefined' && window.location.href;
  const collaborationMetaData =
    currentTransaction?.id && currentTransaction?.attributes?.metadata?.collaborationMetaData
      ? currentTransaction?.attributes?.metadata?.collaborationMetaData
      : [];

  const groupMessages = [...messages, ...message];
  const chainedTransactionId =
    currentTransaction?.id && currentTransaction?.attributes?.protectedData?.chainedTransactionId;
  const scrollDivToBottom = divRef =>
    divRef.current !== null && divRef?.current?.scrollIntoView({ behaviour: 'smooth' });
  const customerId = ensuredCustomer.id && getUserDetails(ensuredCustomer)?.id;
  const providerId = ensuredProvider.id && getUserDetails(ensuredProvider)?.id;
  const activeCollaborators = collaborationMetaData?.filter(
    ({ email, status }) => email !== currentUserEmail && status === INVITE_STATUS_ACTIVE
  );
  const onSendMessageFormFocus = () => {
    setSendMessageFormFocused(true);
    if (isMobSaf) {
      // Scroll to bottom
      window.scroll({ top: document.body.scrollHeight, left: 0, behavior: 'smooth' });
    }
  };

  const onSendMessageFormBlur = () => {
    setSendMessageFormFocused(false);
  };
  const sendMessagePlaceholder = intl.formatMessage({
    id: 'TransactionPanel.sendMessagePlaceholder',
  });

  // const markMessagesAsRead = () => {
  //   // if (!message || !message.length) return;
  //   console.log(message,'message')
  //   const unreadMessages = message.filter(msg => {
  //     // Check if the user hasn't read the message and it's not sent by the current user
  //     return (
  //       !msg.isReadBy?.some(user => user.userId === currentUser.id.uuid) &&
  //       msg.sender.id.uuid !== currentUser.id.uuid
  //     );
  //   });
  //   console.log(unreadMessages,'unreadMessages')

  //   unreadMessages.forEach(unreadMsg => {
  //     socket.current.emit('messageRead', unreadMsg._id, currentUser.id.uuid);
  //   });
  // };

  // useEffect(() => {
  //   markMessagesAsRead();
  // }, [currentUser?.id, message, activeTab]);

  const sendNotification = async (message) => {
    const txId = currentTransaction?.id?.uuid;
    try {
      const notificationObject = {
        notificationType: CHAT_NOTIFICATION,
        transactionId: txId,
        notificationUrl: `${process.env.REACT_APP_CANONICAL_ROOT_URL}/t/${txId}/details`,
        content: message,
      };

      // Combine and filter customer and provider IDs
      const recipientIds = [
        customerId,
        providerId,
        ...(activeCollaborators || []).map(collaborator => collaborator.collaboratorID),
      ].filter(id => id !== currentUser.id.uuid);
      // Loop through recipient IDs
      for (const userId of recipientIds) {
        // Send notification with user-specific content
        await storeNotificationInDb({
          ...notificationObject,
          userId: userId,
        });
      }
    } catch (error) {
    }
  };
  const metadata = currentTransaction?.id && currentTransaction?.attributes?.metadata;
  const allAttachments = metadata && metadata.attachments ? JSON.parse(metadata.attachments) : [];
  const history = currentTransaction?.attributes?.metadata?.history || [];
  const boxFolder = metadata?.boxFolder;

  const onMessageSubmit = async (values, form) => {
    let { message, attachmentsUrls = [] } = values;
    const { link: url, name, date } = attachmentsUrls?.length && attachmentsUrls[0];
    let tagsEmails = [];
    const mentionRegex = /@(\w+)/g;
    const mentionedUsers = message?.match(mentionRegex);
    const onlineUsers = Object.values(connectedUsers || {})

    if (mentionedUsers?.length) {
      if (mentionedUsers.includes(`@everyone`)) {
        tagsEmails = mentionedUsers.length
          ? membersData
            .filter(member => member.email !== currentUserEmail)
            .map(member => member.email)
          : [];
      } else if (mentionedUsers.includes(`@here`)) {
        tagsEmails = onlineUsers?.length
          ? membersData
            ?.filter(member => {
              if (member.email === currentUserEmail) return;
              return onlineUsers?.find(user => user.email === member.email);
            })
            ?.map(member => member.email)
          : [];
      } else if (mentionedUsers.includes(`@nothere`)) {
        tagsEmails = onlineUsers?.length
          ? membersData
            .filter(member => !onlineUsers.some(user => user.email === member.email))
            .map(member => member.email)
          : [];
      } else {
        const emails = mentionedUsers.length
          ? membersData
            .filter(member => {
              if (member.email === currentUserEmail) return false;
              const fullName = member.fullName?.replace(/\s+/g, '_')?.toLowerCase();
              return mentionedUsers.some(user => user.substring(1) === fullName);
            })
            .map(member => member.email)
          : [];
        tagsEmails = [...new Set([...emails])];
      }
    }

    if (url) message = url;
    const messageText = message ? message.trim() : null;

    if (!messageText) return;
    const messageId = new UUID(uuidv4());
    socket?.current?.emit('message', {
      attributes: {
        content: message,
        createdAt: Date.now(),
        fileName: name,
      },
      id: messageId,
      transactionId: currentTransaction?.id?.uuid,
      chainedTransactionId: chainedTransactionId
        ? chainedTransactionId
        : currentTransaction?.id?.uuid,
      socketId: `${socket?.current?.id}${Math.random()}`,
      isReadBy: [],
      sender: {
        attributes: currentUser?.attributes,
        id: currentUser?.id,
        profileImage: currentUser?.profileImage,
      },
      type: MESSAGE,
    });
    form.reset();

    const filteredUsersEmail = [...new Set([...tagsEmails])];
    sendNotification(message);
    if (!!filteredUsersEmail?.length) {
      dispatch(
        sendChatNotificationEmail({
          authorName,
          authorEmail,
          userName,
          userEmail: filteredUsersEmail,
          currentPath,
          message: url ? { name, url } : { text: message },
          step: CHAT_NOTIFICATION,
        })
      );
    }
  };

  return (
    <div className={css.chatBoxWrapper}>
      <div className={css.chatContentMessage}>
        <ActivityFeed
          className={css.feed}
          messages={groupMessages}
          history={history}
          transaction={currentTransaction}
          currentUser={currentUser}
          hasOlderMessages={false}
          onOpenReviewModal={onOpenReviewModal}
          onShowOlderMessages={onShowMoreMessages}
          fetchMessagesInProgress={fetchMessagesInProgress}
          allAttachments={allAttachments}
          lastMessageRef={divRef}
          connectedUsers={connectedUsers}
          isOnline={isOnline}
          isCustomer={isCustomer}
          isProvider={isProvider}
          activeTab={activeTab}
          isJobListing={isJobListing}
          onManageDisableScrolling={onManageDisableScrolling}
          boxFolder={boxFolder}
        />
      </div>
      {activeTab === TRANSACTION_CHAT_PAGE && !isChatExpired && (
        <SendMessageForm
          formId={'TransactionPanel.SendMessageForm'}
          rootClassName={css.sendMessageForm}
          messagePlaceholder={sendMessagePlaceholder}
          inProgress={false}
          sendMessageError={null}
          onFocus={onSendMessageFormFocus}
          onBlur={onSendMessageFormBlur}
          onSubmit={(values, form) => onMessageSubmit(values, form)}
          setClearForm={setClearForm}
          clearAttachmentForm={clearAttachmentForm}
          currentTransaction={currentTransaction}
          isReviewd={isReviewd}
          isChatExpired={isChatExpired}
          // sendGif={onMessageSubmit}
          membersData={membersData}
          nonMemberData={[
            { fullName: EVERYONE, email: 'Notify all members of this team' },
            { fullName: ONLINE, email: 'Notify all online members of this team' },
            { fullName: OFFLINE, email: 'Notify all offline members of this team' },
          ]}
        />
      )}

      {/* <div className="message__status">
        <p>{typingStatus && typingStatus}</p>
      </div> */}
    </div>
  );
};

export default compose(withViewport, withRouter, injectIntl)(RealTimeChatPanel);
