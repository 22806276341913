import React, { useState } from 'react';
import { array, bool, func, object, string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { GENERATE_NOW, LISTING_STATE_DRAFT, UPLOAD_NOW, USER_ROLE_PARTNER } from '../../util/types';
import { EditListingPhotosForm, EditListingOpenAIForm } from '../../forms';
import { ensureOwnListing } from '../../util/data';
import { Button, EditListingHintTooltip, EditListingProgress, ListingLink, SecondaryButton } from '../../components';
import css from './EditListingPhotosPanel.module.css';
import { useSelector } from 'react-redux';
import { openAIImageDetails } from '../../util/destructorHelpers';
import ImageSourceForm from '../../forms/EditListingPhotosForm/ImageSourceForm';
import { chooseToFitSizes } from '../../marketplace-custom-config';

const EditListingPhotosPanel = (props) => {
  const {
    className,
    rootClassName,
    errors,
    disabled,
    ready,
    images,
    listing,
    onImageUpload,
    onUpdateImageOrder,
    submitButtonText,
    panelUpdated,
    onChange,
    onSubmit,
    onRemoveImage,
    completedTabsPer,
  } = props;
  const state = useSelector(state => state);
  const { chooseAFitForImage = '' } = listing?.id && listing?.attributes.publicData || {};

  const [fitValue, setFitValue] = useState(chooseAFitForImage ?? null);
  const { generateImageInProgess, generateImageError, generatedImage, updateInProgress } = state?.EditListingPage;
  const rootClass = rootClassName || css.root;
  const classes = classNames(rootClass, className);
  const currentListing = ensureOwnListing(listing);
  const { publicData = {} } = currentListing && currentListing.attributes;
  const imageUrl = openAIImageDetails(currentListing)?.imageURL ?? null;
  const imageText = openAIImageDetails(currentListing)?.imageText ?? null;
  const openAI_image = publicData?.openAIBackgroundImageDetails;
  const [selectOption, setSelectOption] = useState(openAI_image?.image_url ? GENERATE_NOW : UPLOAD_NOW);
  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const panelTitle = isPublished ? (
    <FormattedMessage
      id="EditListingPhotosPanel.title"
      values={{
        listingTitle: (
          <ListingLink listing={listing}>
            <FormattedMessage id="EditListingPhotosPanel.listingTitle" />
          </ListingLink>
        ),
      }}
    />
  ) : (
    <FormattedMessage id="EditListingPhotosPanel.createListingTitle" />
  );

  return (
    <div className={classes}>
      {/* <EditListingProgress progressCount={completedTabsPer} value={completedTabsPer} /> */}
      <h2 className={css.title}>
        <FormattedMessage id="EditListingPhotosPanel.createListingTitle" />
      </h2>
      <EditListingHintTooltip
        tooltipText={<FormattedMessage id="EditListingPhotosPanel.ThisImageAppearsBackground" />}
      />
      <p className={css.description}><FormattedMessage id='EditListingPhotosPanel.description' /></p>

      {/* <div className={css.switchSelector}>
        <div
          className={selectOption === UPLOAD_NOW ? css.optionActive : css.option}
          onClick={() => setSelectOption(UPLOAD_NOW)}
        >

          <FormattedMessage id="EditListingPhotosPanel.uploadNowButton" />

        </div>
        <div
          className={selectOption === GENERATE_NOW ? css.optionActive : css.option}
          onClick={() => setSelectOption(GENERATE_NOW)}
        >
          <FormattedMessage id="EditListingPhotosPanel.generateImageButton" />

        </div>

      </div> */}
      <ImageSourceForm
        initialValues={{ 'imageSource': openAI_image?.image_url ? 'openai' : 'gallery' }}
        setSelectOption={setSelectOption}
      />
      <div>
        <div>
          <label htmlFor="imageOption">
            <FormattedMessage id="EditListingPhotosPanel.chooseAFitLabel" />
          </label>
          <select
            id="imageOption"
            value={fitValue}
            onChange={(e) => setFitValue(e.target.value)}
          >
            {chooseToFitSizes.map(val => (
              <option key={val.value} value={val.value}>
                {val.label}
              </option>
            ))}
          </select>
          <p>
            <FormattedMessage id="EditListingPhotosPanel.chooseAFitDescription" values={{ fitValue }} />
          </p>
        </div>
      </div>
      {selectOption === UPLOAD_NOW ?
        <EditListingPhotosForm
          className={css.form}
          disabled={disabled}
          ready={ready}
          fetchErrors={errors}
          initialValues={{ images }}
          images={images}
          onImageUpload={onImageUpload}
          onSubmit={values => {
            const { addImage, ...updateValues } = values;
            onSubmit({
              images: updateValues.images,
              publicData: {
                type: USER_ROLE_PARTNER,
                chooseAFitForImage: fitValue,
                openAIBackgroundImageDetails: null
              }
            });
          }}
          onChange={onChange}
          onUpdateImageOrder={onUpdateImageOrder}
          onRemoveImage={onRemoveImage}
          saveActionMsg={submitButtonText}
          updated={panelUpdated}
          updateInProgress={updateInProgress}
        />
        : selectOption === GENERATE_NOW ?
          <EditListingOpenAIForm
            initialValues={{ image_url: imageUrl, imageText }}
            onSubmit={async (values) => {
              const { openAIBackgroundImageId, imageText } = values;
              const openAIBackgroundImageDetails = {
                openAIBackgroundImageId: openAIBackgroundImageId?.uuid,
                imageText
              }
              if (openAIBackgroundImageId) {
                await onSubmit({
                  images: [openAIBackgroundImageId],
                  publicData: {
                    type: USER_ROLE_PARTNER,
                    chooseAFitForImage: fitValue,
                    openAIBackgroundImageDetails
                  }
                })
              }
            }}
            updateInProgress={generateImageInProgess}
            handleSubmitInProgess={updateInProgress}
            generateImageError={generateImageError}
            isFirmPage={false}
          /> : null}
    </div>
  );
}

EditListingPhotosPanel.defaultProps = {
  className: null,
  rootClassName: null,
  errors: null,
  images: [],
  listing: null,
};

EditListingPhotosPanel.propTypes = {
  className: string,
  rootClassName: string,
  errors: object,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  images: array,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  onImageUpload: func.isRequired,
  onUpdateImageOrder: func.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  onRemoveImage: func.isRequired,
};

export default EditListingPhotosPanel;
