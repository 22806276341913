import React, { Component } from 'react';
import { bool, string } from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Field, Form as FinalForm } from 'react-final-form';
import isEqual from 'lodash/isEqual';
import classNames from 'classnames';
import { ensureCurrentUser } from '../../util/data';
import { propTypes, USER_ROLE_CLIENT, USER_ROLE_CSM } from '../../util/types';
import * as validators from '../../util/validators';
import { isChangeEmailWrongPassword, isTooManyEmailVerificationRequestsError, isUploadImageOverLimitError } from '../../util/errors';
import {
  Form,
  Avatar,
  Button,
  ImageFromFile,
  IconSpinner,
  FieldTextInput,
  FieldSelect,
  FieldTimeZoneSelect,
  FieldBirthdayInput,
  LocationAutocompleteInputField,
  IconCard,
  FieldPhoneNumberInput,
  Modal,
  PrimaryButton
} from '../../components';

import css from './ProfileSettingsForm.module.css';
import { typeCompanyArr } from "../../marketplace-custom-config";
import axios from 'axios';
import config from '../../config';
import cameraIcon from '../../assets/Camera.png';
import uploadIcon from '../../assets/uploadIcon.png';

const ACCEPT_IMAGES = 'image/*';
const UPLOAD_CHANGE_DELAY = 2000; // Show spinner so that browser has time to load img srcset
const identity = v => v;
const MIN_PROFILE_ACCOUNT_AGE = 18;
const FILE_SIZE_LIMIT = 20*1000*1000;
class ProfileSettingsFormComponent extends Component {
  constructor(props) {
    super(props);

    this.uploadDelayTimeoutId = null;
    this.state = { uploadDelay: false, showResetPasswordMessage: false, openUploadImageModal: false };
    this.submittedValues = {};
  }

  componentDidUpdate(prevProps) {
    // Upload delay is additional time window where Avatar is added to the DOM,
    // but not yet visible (time to load image URL from srcset)
    if (prevProps.uploadInProgress && !this.props.uploadInProgress) {
      this.setState({ uploadDelay: true });
      this.uploadDelayTimeoutId = window.setTimeout(() => {
        this.setState({ uploadDelay: false });
      }, UPLOAD_CHANGE_DELAY);
    }
  }

  componentWillUnmount() {
    window.clearTimeout(this.uploadDelayTimeoutId);
  }

  render() {
    return (
      <FinalForm
        {...this.props}
        render={fieldRenderProps => {
          const {
            className,
            currentUser,
            handleSubmit,
            intl,
            invalid,
            onImageUpload,
            pristine,
            profileImage,
            rootClassName,
            updateInProgress,
            updateProfileError,
            uploadImageError,
            uploadInProgress,
            form,
            values,
            typePartner,
            onRemoveImage,
            resetPasswordInProgress,
            onResetPassword,
            sendVerificationEmailInProgress,
            sendVerificationEmailError,
            isUserLinkedToFirm,
            onResendVerificationEmail,
            onManageDisableScrolling,
          } = fieldRenderProps;

          const user = ensureCurrentUser(currentUser);
          const { email: currentEmail, emailVerified, pendingEmail, profile } = user.attributes;
          const googleProfileImage = profile?.publicData?.picture;

                    const birthdayLabelDay = intl.formatMessage({ id: 'ProfileSettingsForm.dateTitle' });
          const birthdayLabelMonth = intl.formatMessage({ id: 'ProfileSettingsForm.monthTitle' });
          const birthdayLabelYear = intl.formatMessage({ id: 'ProfileSettingsForm.yearTitle' });
          // First name
          const firstNameLabel = intl.formatMessage({ id: 'ProfileSettingsForm.firstNameLabel' });
          const firstNamePlaceholder = intl.formatMessage({ id: 'ProfileSettingsForm.firstNamePlaceholder' });
          const firstNameRequiredMessage = intl.formatMessage({ id: 'ProfileSettingsForm.firstNameRequired' });
          const firstNameRequired = validators.required(firstNameRequiredMessage);

          // Last name
          const lastNameLabel = intl.formatMessage({ id: 'ProfileSettingsForm.lastNameLabel' });
          const lastNamePlaceholder = intl.formatMessage({ id: 'ProfileSettingsForm.lastNamePlaceholder' });
          const lastNameRequiredMessage = intl.formatMessage({ id: 'ProfileSettingsForm.lastNameRequired' });
          const lastNameRequired = validators.required(lastNameRequiredMessage);

          // timezone
          const timezone = intl.formatMessage({ id: 'ProfileSettingsForm.timezone' });
          const timezonePlaceholder = intl.formatMessage({ id: 'ProfileSettingsForm.timezonePlaceholder' });

          // companyName
          const companyName = intl.formatMessage({
            id: typePartner === USER_ROLE_CLIENT
              ? 'ProfileSettingsForm.companyNameForClient'
              : 'ProfileSettingsForm.companyNameForExpert',
          });
          const companyNamePlaceholder = intl.formatMessage({ id: 'ProfileSettingsForm.companyNamePlaceholder' });

          // typeCompany
          const typeCompany = intl.formatMessage({ id: 'ProfileSettingsForm.typeCompany' });
          const typeCompanyPlaceholder = intl.formatMessage({ id: 'ProfileSettingsForm.typeCompanyPlaceholder' });

          // Location
          const locationPlaceHolder = intl.formatMessage({ id: 'ProfileSettingsForm.locationPlaceholder' });
          const addressRequiredMessage = intl.formatMessage({ id: 'ProfileSettingsForm.addressRequired' });

          // DOB
          const birthdayRequired = validators.required(
            intl.formatMessage({ id: 'ProfileSettingsForm.birthdayRequired' })
          );
          const birthdayMinAge = validators.ageAtLeast(
            intl.formatMessage(
              { id: 'ProfileSettingsForm.birthdayMinAge' },
              { minAge: MIN_PROFILE_ACCOUNT_AGE }
            ),
            MIN_PROFILE_ACCOUNT_AGE
          );

          //email
          const emailChanged = currentEmail !== values?.email;
          const emailLabel = intl.formatMessage({ id: 'ContactDetailsForm.emailLabel' });
          const emailPlaceholder = currentEmail || '';
          const emailRequiredMessage = intl.formatMessage({ id: 'ContactDetailsForm.emailRequired' });
          const emailRequired = validators.required(emailRequiredMessage);
          const emailInvalidMessage = intl.formatMessage({ id: 'ContactDetailsForm.emailInvalid' });
          const emailValid = validators.emailFormatValid(emailInvalidMessage);

          // password
          const passwordLabel = intl.formatMessage({ id: 'ContactDetailsForm.passwordLabel' });
          const passwordPlaceholder = intl.formatMessage({ id: 'ContactDetailsForm.passwordPlaceholder' });
          const passwordRequiredMessage = intl.formatMessage({ id: 'ContactDetailsForm.passwordRequired' });
          const passwordRequired = validators.requiredStringNoTrim(passwordRequiredMessage);

          const passwordMinLengthMessage = intl.formatMessage(
            { id: 'ContactDetailsForm.passwordTooShort' },
            { minLength: validators.PASSWORD_MIN_LENGTH }
          );

          const passwordMinLength = validators.minLength(
            passwordMinLengthMessage,
            validators.PASSWORD_MIN_LENGTH
          );

          const passwordValidators = emailChanged
            ? validators.composeValidators(passwordRequired, passwordMinLength)
            : null;

          //Phone number
          const phonePlaceholder = intl.formatMessage({ id: 'ContactDetailsForm.phonePlaceholder' });
          const phoneLabel = intl.formatMessage({ id: 'ContactDetailsForm.phoneLabel' });

          const confirmClasses = classNames(css.confirmChangesSection, {
            [css.confirmChangesSectionVisible]: emailChanged,
          });

          const uploadingOverlay =
            uploadInProgress || this.state.uploadDelay ? (
              <div className={css.uploadingImageOverlay}>
                <IconSpinner />
              </div>
            ) : null;

          const hasUploadError = !!uploadImageError && !uploadInProgress;
          const errorClasses = classNames({ [css.avatarUploadError]: hasUploadError });
          const transientUserProfileImage = profileImage.uploadedImage || user.profileImage;
          const transientUser = { ...user, profileImage: transientUserProfileImage };

          // Ensure that file exists if imageFromFile is used
          const fileExists = !!profileImage.file;
          const fileUploadInProgress = uploadInProgress && fileExists;
          const delayAfterUpload = profileImage.imageId && this.state.uploadDelay;
          const imageFromFile =
            fileExists && (fileUploadInProgress || delayAfterUpload) ? (
              <ImageFromFile
                id={profileImage.id}
                className={errorClasses}
                rootClassName={css.uploadingImage}
                aspectRatioClassName={css.squareAspectRatio}
                file={profileImage.file}
              >
                {uploadingOverlay}
              </ImageFromFile>
            ) : null;

          // Avatar is rendered in hidden during the upload delay
          // Upload delay smoothes image change process:
          // responsive img has time to load srcset stuff before it is shown to user.
          const avatarClasses = classNames(errorClasses, css.avatar, {
            [css.avatarInvisible]: this.state.uploadDelay,
          });
          const avatarComponent =
            !fileUploadInProgress && profileImage.imageId ? (
              <Avatar
                className={avatarClasses}
                renderSizes="(max-width: 767px) 96px, 240px"
                user={transientUser}
                disableProfileLink
              />
            ) : null;

          const submitError = updateProfileError ? (
            <div className={css.error}>
              <FormattedMessage id="ProfileSettingsForm.updateProfileFailed" />
            </div>
          ) : null;

          const classes = classNames(rootClassName || css.root, className);
          const submitInProgress = updateInProgress;
          const submittedOnce = Object.keys(this.submittedValues).length > 0;
          const pristineSinceLastSubmit = submittedOnce && isEqual(values, this.submittedValues);
          const submitDisabled = pristine || pristineSinceLastSubmit || uploadInProgress || submitInProgress
            || Object.keys(values.dateOfBirth).length && invalid;

          const tooManyVerificationRequests = isTooManyEmailVerificationRequestsError(sendVerificationEmailError);

          let resendEmailMessage = null;
          if (tooManyVerificationRequests) {
            resendEmailMessage = (
              <span className={css.tooMany}>
                <FormattedMessage id="ContactDetailsForm.tooManyVerificationRequests" />
              </span>
            );
          } else if (
            (sendVerificationEmailInProgress || this.state.showVerificationEmailSentMessage)
          ) {
            resendEmailMessage = (
              <span className={css.emailSent}>
                <FormattedMessage id="ContactDetailsForm.emailSent" />
              </span>
            );
          } else {
            resendEmailMessage = (
              <span
                className={css.helperLink}
                onClick={() => onResendVerificationEmail()}
                role="button"
              >
                <FormattedMessage id="ContactDetailsForm.resendEmailVerificationText" />
              </span>
            );
          }

          // Email status info: unverified, verified and pending email (aka changed unverified email)
          let emailVerifiedInfo = null;
          if (emailVerified && !pendingEmail && !emailChanged) {
            // Current email is verified and there's no pending unverified email
            emailVerifiedInfo = (
              <span className={css.emailVerified}>
                <FormattedMessage id="ContactDetailsForm.emailVerified" />
              </span>
            );
          } else if (!emailVerified && !pendingEmail) {
            // Current email is unverified. This is the email given in sign up form
            emailVerifiedInfo = (
              <span className={css.emailUnverified}>
                <FormattedMessage
                  id="ContactDetailsForm.emailUnverified"
                  values={{ resendEmailMessage }}
                />
              </span>
            );
          } else if (pendingEmail) {
            // Current email has been tried to change, but the new address is not yet verified
            const pendingEmailStyled = <span className={css.emailStyle}>{pendingEmail}</span>;
            const pendingEmailCheckInbox = (
              <span className={css.checkInbox}>
                <FormattedMessage
                  id="ContactDetailsForm.pendingEmailCheckInbox"
                  values={{ pendingEmail: pendingEmailStyled }}
                />
              </span>
            );
            emailVerifiedInfo = (
              <span className={css.pendingEmailUnverified}>
                <FormattedMessage
                  id="ContactDetailsForm.pendingEmailUnverified"
                  values={{ pendingEmailCheckInbox, resendEmailMessage }}
                />
              </span>
            );
          }

          const handleResetPassword = () => {
            this.setState({ showResetPasswordMessage: true });
            onResetPassword(currentEmail);
          }

          const sendPasswordLink = (
            <span className={css.helperLink} onClick={handleResetPassword} role="button">
              <FormattedMessage id="ContactDetailsForm.resetPasswordLinkText" />
            </span>
          );

          const resendPasswordLink = (
            <span className={css.helperLink} onClick={handleResetPassword} role="button">
              <FormattedMessage id="ContactDetailsForm.resendPasswordLinkText" />
            </span>
          );

          const resetPasswordLink = this.state.showResetPasswordMessage || resetPasswordInProgress ? (
            <>
              <FormattedMessage
                id="ContactDetailsForm.resetPasswordLinkSent"
                values={{ email: <span className={css.emailStyle}>{currentUser.attributes.email}</span> }}
              />{' '}
              {resendPasswordLink}
            </>
          ) : (
            sendPasswordLink
          );

          async function getTimeZone({ lat, lng }) {
            const access_token = config.maps.mapboxAccessToken;
            const response = await axios.get(
              `https://api.mapbox.com/v4/examples.4ze9z6tv/tilequery/${lng},${lat}.json?access_token=${access_token}`,
            );
            if (response?.status === 200) {
              const userTimezone = response.data.features[0].properties.TZID;
              form.change('timezone', userTimezone);
            }
          }

          const handleRemoveImage = () => {
            onRemoveImage()
            form.change('profileImage', {})
          }

          const handleUploadProfileImage = async () => {
            const file = values?.image;
            const tempId = `${file.name}_${Date.now()}`;
            const res = await onImageUpload({ id: tempId, file });
            if (res?.status === 200){
              const uploadedImage = res.data.data;
              form.change('profileImage',  values?.image);
              this.setState({openUploadImageModal: false})
            }
          }
          const uploadImageSrc = values.image ? URL.createObjectURL(values.image) : '';

          const handleDrop = (event) => {
            event.preventDefault();
            const reader = new FileReader();
            const file = event.dataTransfer.files[0]
  
            reader.onload = () => {
              if (file.size < FILE_SIZE_LIMIT) {
                form.change('image', file);
              }
            };
            reader.readAsDataURL(file);
          };

          return (
            <Form
              className={classes}
              onSubmit={e => {
                this.submittedValues = values;
                handleSubmit(e);
              }}
            >
              <div className={css.sectionContainer}>
                <h3 className={css.sectionTitle}>
                  <FormattedMessage id="ProfileSettingsForm.yourProfilePicture" />
                </h3>
                <div className={css.uploadAvatarWrapper}>
                  {/* {profileImage.imageId && (
                    <div className={css.removeButton} onClick={handleRemoveImage}>
                      <IconCard brand='cross' />
                    </div>
                  )} */}
                  {profileImage.imageId || fileUploadInProgress ? (
                    <div className={css.avatarContainer} >
                      {imageFromFile}
                      {avatarComponent}
                      
                      <div className={css.uploadImage}>
                        <img src={cameraIcon} />
                      </div>
                    </div>
                  ) : (
                    <div className={css.avatarPlaceholder}>
                      {googleProfileImage ? (
                        <div className={css.googleDisplayImage}>
                          <div className={css.uploadImage}>
                            <img src={cameraIcon} />
                          </div>
                          <img src={googleProfileImage} alt='GoogleImage'/>
                        </div>
                      ) : (
                        <>
                          <div className={css.uploadImage}>
                            <img src={cameraIcon} />
                          </div>
                          <div className={css.uploadText}>
                            <div className={css.avatarPlaceholderText}>
                              <FormattedMessage id="ProfileSettingsForm.addYourProfilePicture" />
                            </div>
                            <div className={css.avatarPlaceholderTextMobile}>
                              <FormattedMessage id="ProfileSettingsForm.addYourProfilePictureMobile" />
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  )}
                  <div className={css.changeAvatar}>
                    <label className={css.changeAvatarTitle}  onClick={() => this.setState({openUploadImageModal: true})}>
                      <FormattedMessage id="ProfileSettingsForm.changeAvatar" />
                    </label>
                    <div 
                      className={classNames(css.revertGoogle, !profileImage.imageId && css.disable)} 
                      onClick={() => handleRemoveImage()}
                    >
                      <FormattedMessage id={googleProfileImage 
                          ? "ProfileSettingsForm.revertGoogleProfilePhoto" : "ProfileSettingsForm.removeProfilePhoto"} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={css.sectionContainer}>
                <h3 className={css.sectionTitle}>
                  <FormattedMessage id="ProfileSettingsForm.yourName" />
                </h3>
                <div className={css.nameContainer}>
                  <FieldTextInput
                    className={css.firstName}
                    type="text"
                    id="firstName"
                    name="firstName"
                    label={firstNameLabel}
                    placeholder={firstNamePlaceholder}
                    validate={firstNameRequired}
                  />
                  <FieldTextInput
                    className={css.lastName}
                    type="text"
                    id="lastName"
                    name="lastName"
                    label={lastNameLabel}
                    placeholder={lastNamePlaceholder}
                    validate={lastNameRequired}
                  />
                </div>
              </div>
              <FieldTextInput
                type="email"
                name="email"
                id='email'
                label={emailLabel}
                placeholder={emailPlaceholder}
                validate={validators.composeValidators(emailRequired, emailValid)}
                // customErrorText={emailTouched ? null : emailTakenErrorText}
                className={css.emailInput}
              />
              {emailVerifiedInfo}
              <FieldPhoneNumberInput
                className={css.phone}
                name="phoneNumber"
                id='phoneNumber'
                label={phoneLabel}
                placeholder={phonePlaceholder}
              />
              <FieldBirthdayInput
                id="dateOfBirth"
                name="dateOfBirth"
                className={css.dob}
                // disabled={disabled}
                label={birthdayLabelDay}
                labelForMonth={birthdayLabelMonth}
                labelForYear={birthdayLabelYear}
                format={identity}
                validate={Object.keys(values.dateOfBirth).length && validators.composeValidators(birthdayRequired, birthdayMinAge)}
                valueFromForm={values?.dateOfBirth ? values?.dateOfBirth : null}
              />
              {typePartner && [USER_ROLE_CLIENT, USER_ROLE_CSM].includes(typePartner) && (
                <LocationAutocompleteInputField
                  className={css.currencySelector}
                  inputClassName={css.locationAutocompleteInput}
                  iconClassName={css.locationAutocompleteInputIcon}
                  predictionsClassName={css.predictionsRoot}
                  validClassName={css.validLocation}
                  // autoFocus
                  name="location"
                  label={"Country"}
                  placeholder={locationPlaceHolder}
                  useDefaultPredictions={false}
                  format={identity}
                  valueFromForm={values.location ? values.location : {}}
                  validate={validators.composeValidators(
                    validators.autocompleteSearchRequired(addressRequiredMessage),
                    // autocompletePlaceSelected(addressNotRecognizedMessage)
                  )}
                  getLocation={getTimeZone}
                />
              )}
              <FieldTimeZoneSelect
                className={css.timezoneSelector}
                label={timezone}
                id="timezone"
                name="timezone"
                placeholder={timezonePlaceholder}
              />
              {!isUserLinkedToFirm && (
                <div className={classNames(css.sectionContainer, css.lastSection)}>
                  <h3 className={css.sectionTitle}>
                    <FormattedMessage id="ProfileSettingsForm.companyHeading" />
                  </h3>
                  <FieldTextInput
                    className={css.companyName}
                    type="text"
                    id="companyName"
                    name="companyName"
                    label={companyName}
                    placeholder={companyNamePlaceholder}
                    validate={typePartner === USER_ROLE_CLIENT && lastNameRequired}
                  />
                  <FieldSelect
                    className={className}
                    name="typeCompany"
                    id="typeCompany"
                    label={typeCompany}
                  >
                    <option disabled value="">
                      {typeCompanyPlaceholder}
                    </option>
                    {typeCompanyArr && typeCompanyArr.map(c => (
                      <option key={c.key} value={c.label}>
                        {c.label}
                      </option>
                    ))}
                  </FieldSelect>
                </div>
              )}
              {submitError}
              <div className={confirmClasses}>
                <h3 className={css.confirmChangesTitle}>
                  <FormattedMessage id="ContactDetailsForm.confirmChangesTitle" />
                </h3>
                <p className={css.confirmChangesInfo}>
                  <FormattedMessage id="ContactDetailsForm.confirmChangesInfo" />
                  <br />
                  <FormattedMessage id="ContactDetailsForm.resetPasswordInfo" values={{ resetPasswordLink }} />
                </p>

                <FieldTextInput
                  className={css.password}
                  type="password"
                  name="currentPassword"
                  id={'currentPassword'}
                  autoComplete="current-password"
                  label={passwordLabel}
                  placeholder={passwordPlaceholder}
                  validate={passwordValidators}
                // customErrorText={passwordTouched ? null : passwordErrorText}
                />
              </div>
              <Button
                className={css.submitButton}
                type="submit"
                inProgress={submitInProgress}
                disabled={submitDisabled}
                ready={pristineSinceLastSubmit}
              >
                <FormattedMessage id="ProfileSettingsForm.saveChanges" />
              </Button>

              <Modal
                id="uploadImageModal"
                className={css.disableModalBorder}
                contentClassName={css.containerClassName}
                isOpen={this.state.openUploadImageModal}
                onClose={() => this.setState({openUploadImageModal: false})}
                usePortal
                onManageDisableScrolling={onManageDisableScrolling}
              >
                <div className={css.uploadImageModal}>
                  <h2><FormattedMessage id='ProfileSettingsForm.uploadImageModalTitle'/></h2>
                  <Field
                    accept={ACCEPT_IMAGES}
                    id="profileImage"
                    name="profileImage"
                    type="file"
                    uploadImageError={uploadImageError}
                    disabled={uploadInProgress}
                  >
                    {fieldProps => {
                      const { accept, id, input, disabled, uploadImageError } = fieldProps;
                      const { name, type } = input;
                      const onChange = async e => {
                        const file = e.target.files[0];
                        form.blur('image');
                        if (file != null) {
                          form.change('image', file);
                        }
                      };

                      let error = null;

                      if (isUploadImageOverLimitError(uploadImageError)) {
                        error = (
                          <div className={css.error}>
                            <FormattedMessage id="ProfileSettingsForm.imageUploadFailedFileTooLarge" />
                          </div>
                        );
                      } else if (uploadImageError) {
                        error = (
                          <div className={css.error}>
                            <FormattedMessage id="ProfileSettingsForm.imageUploadFailed" />
                          </div>
                        );
                      }

                      return (
                        <div className={css.uploadAvatarWrapper} style={{width: '100%'}}>
                          <div className={css.dragContainer}>
                            {!!values.image ? (
                              <img className={css.uploadedImage} src={uploadImageSrc} />
                            ) : (
                              <label 
                                htmlFor={id}
                                className={css.dragCircle} 
                                onDrop={handleDrop}
                                onDragOver={(event) => event.preventDefault()}
                              >
                                <img src={uploadIcon}/>
                                <FormattedMessage id='ProfileSettingsForm.dragAndDropMessage'/>
                              </label>
                            )}
                          </div>
                          <input
                            accept={accept}
                            id={id}
                            name={name}
                            className={css.uploadAvatarInput}
                            disabled={disabled}
                            onChange={onChange}
                            type={type}
                          />
                          {error}
                        </div>
                      );
                    }}
                  </Field>
                 
                  <div className={css.tip}>
                    <FormattedMessage id="ProfileSettingsForm.tip" />
                  </div>
                  <div className={css.fileInfo}>
                    <FormattedMessage id="ProfileSettingsForm.fileInfo" />
                  </div>
                  <div className={css.buttonWrapper}>
                  <div onClick={() => {
                    this.setState({openUploadImageModal: false})
                    form.change('image', null)
                  }}>
                    <FormattedMessage id="TextEditor.cancelButton" />
                  </div>
                  <PrimaryButton 
                    type='button'
                    onClick={() => handleUploadProfileImage()} 
                    disabled={!values?.image}
                    inProgress={uploadInProgress}
                  >
                    <FormattedMessage id="ZohoSignForm.upload" />
                  </PrimaryButton>
                  </div>
                </div>
              </Modal>
            </Form>
          );
        }}
      />
    );
  }
}

ProfileSettingsFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  uploadImageError: null,
  updateProfileError: null,
  updateProfileReady: false,
};

ProfileSettingsFormComponent.propTypes = {
  rootClassName: string,
  className: string,

  uploadImageError: propTypes.error,
  uploadInProgress: bool.isRequired,
  updateInProgress: bool.isRequired,
  updateProfileError: propTypes.error,
  updateProfileReady: bool,

  // from injectIntl
  intl: intlShape.isRequired,
};

const ProfileSettingsForm = compose(injectIntl)(ProfileSettingsFormComponent);

ProfileSettingsForm.displayName = 'ProfileSettingsForm';

export default ProfileSettingsForm;
