import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { manageDisableScrolling } from '../../ducks/UI.duck';
import Modal from '../Modal/Modal';
import css from './CheckoutModal.module.css';
import {
  checkIfTransactionIncludesTransition,
  getPaymentTerms,
  getUserDetails,
  payinTotal,
  getPaymentIntent,
} from '../../util/destructorHelpers';
import { StripePaymentForm } from '../../forms';
import {
  ensureCurrentUser,
  ensurePaymentMethodCard,
  ensureStripeCustomer,
  ensureTransaction,
  ensureUser,
} from '../../util/data';
import { confirmCardPayment, retrievePaymentIntent } from '../../ducks/stripe.duck';
import {
  confirmCardPaymentStripeIn,
  confirmPayment,
  initiateOrder,
  stripeCustomer,
} from '../../containers/CheckoutPage/CheckoutPage.duck';
import { clearData } from '../../containers/CheckoutPage/CheckoutPageSessionHelpers';
import {
  IS_FINAL,
  ONETIME_PAYMENT,
  ONLINE,
  PAYMENT_INTENT_AUTHENTICATION_FAILURE,
  PAY_AND_SAVE_FOR_LATER_USE,
  PREPAYMENT_PAYMENT_STEP,
  PREPAYMENT_PAYMENT_SUBJECT,
  PROJECT_COMPLETED,
  STORAGE_KEY,
  STRIPE_PI_USER_ACTIONS_DONE_STATUSES,
  TRANSACTION_DETAILS_PAGE,
  USE_SAVED_CARD,
} from '../../util/types';
import CustomBreakdown from '../CustomBreakdown/CustomBreakdown';
import { savePaymentMethod } from '../../ducks/paymentMethods.duck';
import {
  makeRequestToCustomEndpoint,
  stripeRetrievePaymentIntentIndia,
  updateFirmUserDetails,
} from '../../util/api';
import { FINAL_PAYMENT_SUBJECT } from '../../util/types';
import { FINAL_PAYMENT_STEP } from '../../util/types';
import StripeIndiaPaymentPanel from '../StripeIndiaPaymentPanel/StripeIndiaPaymentPanel';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import { withRouter } from 'react-router-dom';
import Button from '../Button/Button';
import { FormattedMessage } from 'react-intl';

const CheckoutModal = props => {
  const {
    currentTransaction,
    onClose,
    isOpen,
    intl,
    isProvider,
    onlinePaymentState,
    location,
    history,
    isFinal,
    stripeConnectAccountId,
    isProviderIndianOrigin
  } = props;
  const userEmails = [
    getUserDetails(currentTransaction?.provider)?.email,
    getUserDetails(currentTransaction?.customer)?.email,
  ];

  const isStripeTransition = !!checkIfTransactionIncludesTransition(currentTransaction);
  const state = useSelector(state => state);
  const [submitting, setSubmitting] = useState(false);
  const [intentAuthenticationFailureMessage, setIntentAuthenticationFailureMessage] = useState(
    null
  );
  const [stripe, setStripe] = useState(null);
  const dispatch = useDispatch();

  const currentUser = state?.user?.currentUser;
  const currentProvider = !!currentTransaction?.id && currentTransaction?.provider;
  const currentCustomer = !!currentTransaction?.id && currentTransaction?.customer;
  const ensuredProvider = ensureUser(currentProvider);
  const ensuredCustomer = ensureUser(currentCustomer);
  const urlParams = new URLSearchParams(window.location.search);
  const paymentIntentId = urlParams.get('payment_intent');
  const encryptedPaymentIntentId = currentUser?.id && getPaymentIntent(currentUser);
  const userName =
    currentUser && currentUser.attributes
      ? `${currentUser.attributes.profile.firstName} ${currentUser.attributes.profile.lastName}`
      : null;
  const initalValuesForStripePayment = { name: userName };

  const lastTransition = currentTransaction?.id && currentTransaction?.attributes?.lastTransition;

  const paymentTerms = Number(getPaymentTerms(currentTransaction));
  const { initiateOrderError, confirmPaymentError, stripeCustomerFetched } = state.CheckoutPage;
  const { confirmCardPaymentError, paymentIntent, retrievePaymentIntentError } = state.stripe;
  const paymentFlow = (selectedPaymentMethod, saveAfterOnetimePayment) => {
    // Payment mode could be 'replaceCard', but without explicit saveAfterOnetimePayment flag,
    // we'll handle it as one-time payment
    return selectedPaymentMethod === 'defaultCard'
      ? USE_SAVED_CARD
      : saveAfterOnetimePayment
        ? PAY_AND_SAVE_FOR_LATER_USE
        : ONETIME_PAYMENT;
  };
  // const isPaymentExpired = checkIsPaymentExpired(existingTransaction);
  const hasDefaultPaymentMethod = !!(
    stripeCustomerFetched &&
    currentUser?.id &&
    ensureStripeCustomer(currentUser.stripeCustomer).attributes.stripeCustomerId &&
    ensurePaymentMethodCard(currentUser.stripeCustomer.defaultPaymentMethod).id
  );
  const onStripeInitialized = stripe => {
    setStripe(stripe);
    const tx = currentTransaction ? currentTransaction : null;
    // We need to get up to date PI, if booking is created but payment is not expired.
    const shouldFetchPaymentIntent = stripe && !paymentIntent && tx && tx.id;

    // && txIsPaymentPending(tx) &&
    // !checkIsPaymentExpired(tx);
    if (shouldFetchPaymentIntent) {
      const { stripePaymentIntentClientSecret } =
        tx.attributes.protectedData && tx.attributes.protectedData.stripePaymentIntents
          ? tx.attributes.protectedData.stripePaymentIntents.default
          : {};

      // Fetch up to date PaymentIntent from Stripe
      //  dispatch(retrievePaymentIntent({ stripe, stripePaymentIntentClientSecret }));
    }
  };
  const emailParams = {
    customerEmail: currentUser?.attributes?.email,
    customerName: currentUser?.attributes?.profile?.firstName,
    projectTitle: currentTransaction?.listing?.attributes?.title,
    step: isFinal ? FINAL_PAYMENT_STEP : PREPAYMENT_PAYMENT_STEP,
    subject: isFinal ? FINAL_PAYMENT_SUBJECT : PREPAYMENT_PAYMENT_SUBJECT,
  };
  const handlePaymentIntent = handlePaymentParams => {
    const {
      pageData,
      speculatedTransaction,
      message,
      paymentIntent,
      selectedPaymentMethod,
      saveAfterOnetimePayment,
      paymentMethodId,
      card,
      billingDetails,
      stripe,
    } = handlePaymentParams;
    const storedTx = ensureTransaction(currentTransaction);

    const ensuredCurrentUser = ensureCurrentUser(currentUser);
    const ensuredStripeCustomer = ensureStripeCustomer(ensuredCurrentUser.stripeCustomer);
    const ensuredDefaultPaymentMethod = ensurePaymentMethodCard(
      ensuredStripeCustomer.defaultPaymentMethod
    );

    let createdPaymentIntent = null;

    const hasDefaultPaymentMethod = !!(
      stripeCustomerFetched &&
      ensuredStripeCustomer.attributes.stripeCustomerId &&
      ensuredDefaultPaymentMethod.id
    );
    const stripePaymentMethodId = hasDefaultPaymentMethod
      ? ensuredDefaultPaymentMethod.attributes.stripePaymentMethodId
      : null;

    const selectedPaymentFlow = paymentFlow(selectedPaymentMethod, saveAfterOnetimePayment);
    // Step 1: initiate order by requesting payment from Marketplace API
    const fnRequestPayment = fnParams => {
      // fnParams should be { listingId, bookingStart, bookingEnd }
      const hasPaymentIntents =
        storedTx.attributes.protectedData && storedTx.attributes.protectedData.stripePaymentIntents;
      // If paymentIntent exists, order has been initiated previously.
      return hasPaymentIntents
        ? Promise.resolve(storedTx)
        : dispatch(initiateOrder(fnParams, storedTx.id, isFinal));
    };

    // Step 2: pay using Stripe SDK
    const fnConfirmCardPayment = fnParams => {
      // fnParams should be returned transaction entity

      const order = ensureTransaction(fnParams);
      // if (order.id) {
      //     // Store order.
      //     const { bookingData, bookingDates, listing } = pageData;
      //     storeData(bookingData, bookingDates, listing, order, STORAGE_KEY);
      //     this.setState({ pageData: { ...pageData, transaction: order } });
      // }

      const hasPaymentIntents =
        order.attributes.protectedData && order.attributes.protectedData.stripePaymentIntents;

      if (!hasPaymentIntents) {
        throw new Error(
          `Missing StripePaymentIntents key in transaction's protectedData. Check that your transaction process is configured to use payment intents.`
        );
      }

      const { stripePaymentIntentClientSecret } = hasPaymentIntents
        ? order.attributes.protectedData.stripePaymentIntents.default
        : null;

      const { stripe, card, billingDetails, paymentIntent } = handlePaymentParams;
      const stripeElementMaybe = selectedPaymentFlow !== USE_SAVED_CARD ? { card } : {};

      // Note: payment_method could be set here for USE_SAVED_CARD flow.
      // { payment_method: stripePaymentMethodId }
      // However, we have set it already on API side, when PaymentIntent was created.
      const paymentParams = !stripePaymentMethodId
        ? {
          payment_method: {
            billing_details: billingDetails,
            card: card,
          },
        }
        : { payment_method: stripePaymentMethodId };

      const params = {
        stripePaymentIntentClientSecret,
        orderId: order.id,
        stripe,
        ...stripeElementMaybe,
        paymentParams,
      };

      // If paymentIntent status is not waiting user action,
      // confirmCardPayment has been called previously.
      const hasPaymentIntentUserActionsDone =
        paymentIntent && STRIPE_PI_USER_ACTIONS_DONE_STATUSES.includes(paymentIntent.status);
      return hasPaymentIntentUserActionsDone
        ? Promise.resolve({ transactionId: order.id, paymentIntent })
        : dispatch(confirmCardPayment(params));
    };
    // Step 3: complete order by confirming payment to Marketplace API
    // Parameter should contain { paymentIntent, transactionId } returned in step 2
    const fnConfirmPayment = fnParams => {
      createdPaymentIntent = fnParams.paymentIntent;

      // makeRequestToCustomEndpoint('POST', '/api/emails/send-email-using-zepto', emailParams);
      return dispatch(confirmPayment({ ...fnParams, txId: storedTx?.id, isFinal, paymentTerms }));
    };

    // // Step 4: send initial message
    // const fnSendMessage = fnParams => {
    //   return  dispatch(sendMessage({ ...fnParams, message }));
    // };

    // Step 5: optionally save card as defaultPaymentMethod
    const fnSavePaymentMethod = fnParams => {
      const pi = createdPaymentIntent || paymentIntent;

      if (selectedPaymentFlow === PAY_AND_SAVE_FOR_LATER_USE) {
        return dispatch(savePaymentMethod(ensuredStripeCustomer, pi.payment_method))
          .then(response => {
            if (response.errors) {
              return { ...fnParams, paymentMethodSaved: false };
            }
            return { ...fnParams, paymentMethodSaved: true };
          })
          .catch(e => {
            // Real error cases are catched already in paymentMethods page.
            return { ...fnParams, paymentMethodSaved: false };
          });
      } else {
        return Promise.resolve({ ...fnParams, paymentMethodSaved: true });
      }
    };

    // Here we create promise calls in sequence
    // This is pretty much the same as:
    // fnRequestPayment({...initialParams})
    //   .then(result => fnConfirmCardPayment({...result}))
    //   .then(result => fnConfirmPayment({...result}))
    const applyAsync = (acc, val) => acc.then(val);
    const composeAsync = (...funcs) => x => funcs.reduce(applyAsync, Promise.resolve(x));
    const handlePaymentIntentCreation = composeAsync(
      fnRequestPayment,
      fnConfirmCardPayment,
      fnConfirmPayment,
      // fnSendMessage,
      fnSavePaymentMethod
    );

    // Create order aka transaction
    // NOTE: if unit type is line-item/units, quantity needs to be added.
    // The way to pass it to checkout page is through pageData.bookingData
    const tx = speculatedTransaction ? speculatedTransaction : storedTx;

    // Note: optionalPaymentParams contains Stripe paymentMethod,
    // but that can also be passed on Step 2
    // stripe.confirmCardPayment(stripe, { payment_method: stripePaymentMethodId })
    const optionalPaymentParams =
      selectedPaymentFlow === USE_SAVED_CARD && hasDefaultPaymentMethod
        ? { paymentMethod: stripePaymentMethodId }
        : selectedPaymentFlow === PAY_AND_SAVE_FOR_LATER_USE
          ? { setupPaymentMethodForSaving: true }
          : {};
    const orderParams = {
      listingId: tx?.listing?.id,
      bookingStart: new Date(tx?.attributes?.metadata?.proposal?.startDate),
      bookingEnd: new Date(tx?.attributes?.metadata?.proposal?.deadline),
      paymentFee: tx?.attributes?.metadata?.proposal?.paymentFee,
      customerId: tx?.customer?.id?.uuid,
      quantity: null,
      paymentTerms,
      isFinal,
      ...optionalPaymentParams,
    };

    return handlePaymentIntentCreation(orderParams);
  };

  // If paymentIntent status is not waiting user action,
  // confirmCardPayment has been called previously.

  const hasPaymentIntentUserActionsDone =
    paymentIntent && STRIPE_PI_USER_ACTIONS_DONE_STATUSES.includes(paymentIntent.status);
  const handleSubmit = values => {
    if (submitting) {
      return;
    }
    setSubmitting(true);
    // payment_mho //paymentMethod
    // const { history, speculatedTransaction, currentUser, paymentIntent, dispatch } = props;
    const { card, message, paymentMethod, formValues } = values;
    const {
      name,
      addressLine1,
      addressLine2,
      postal,
      city,
      state,
      country,
      saveAfterOnetimePayment,
    } = formValues;

    // Billing address is recommended.
    // However, let's not assume that <StripePaymentAddress> data is among formValues.
    // Read more about this from Stripe's docs
    // https://stripe.com/docs/stripe-js/reference#stripe-handle-card-payment-no-element
    const addressMaybe =
      addressLine1 && postal
        ? {
          address: {
            city: city,
            country: country,
            line1: addressLine1,
            line2: addressLine2,
            postal_code: postal,
            state: state,
          },
        }
        : {};
    const billingDetails = {
      name,
      email: ensureCurrentUser(currentUser).attributes.email,
      ...addressMaybe,
    };

    const requestPaymentParams = {
      // pageData: state?.pageData,
      // speculatedTransaction,
      stripe,
      card,
      billingDetails,
      message,
      paymentIntent,
      selectedPaymentMethod: paymentMethod,
      saveAfterOnetimePayment: !!saveAfterOnetimePayment,
    };

    handlePaymentIntent(requestPaymentParams)
      .then(res => {
        if (res) {
          setSubmitting(false);
          onlinePaymentState(ONLINE);
          onClose();
        }

        clearData(STORAGE_KEY);
      })
      .catch(err => {
        // console.error(err);
        // this.setState({ submitting: false });
        setSubmitting(false);
      });
  };
  const orderParams = {
    listingId: currentTransaction?.listing?.id,
    bookingStart: new Date(currentTransaction?.attributes?.metadata?.proposal?.startDate),
    bookingEnd: new Date(currentTransaction?.attributes?.metadata?.proposal?.deadline),
    paymentFee: currentTransaction?.attributes?.metadata?.proposal?.paymentFee,
    customerId: currentTransaction?.customer?.id?.uuid,
    quantity: null,
    paymentTerms,
    isFinal,
    isProviderIndianOrigin,
    txId: currentTransaction?.id,
  };

  useEffect(() => {
    if (!isOpen) {
      setIntentAuthenticationFailureMessage(null);
    }
    if (paymentIntentId && currentTransaction?.id) {
      const paymentStatus = async () => {
        const { message, status } = await stripeRetrievePaymentIntentIndia({
          encryptedPaymentIntentId,
          stripeConnectAccountId,
        });
        if (status === "succeeded") {
          dispatch(confirmCardPaymentStripeIn({ ...orderParams, ...emailParams }));
          history.push(
            createResourceLocatorString(TRANSACTION_DETAILS_PAGE, routeConfiguration(), {
              id: currentTransaction?.id?.uuid,
            })
          );
          onClose();
        } else {
          setIntentAuthenticationFailureMessage(message);
        }
      };
      paymentStatus();
    }
  }, [paymentIntentId, encryptedPaymentIntentId, stripeConnectAccountId, isOpen]);
  // const timeZone = currentTransaction?.listing?.attributes?.availabilityPlan?.timezone
  //   ? currentTransaction?.listing?.attributes?.availabilityPlan?.timezone
  //   : 'Etc/UTC';

  const handleFinalPayment = async () => {
    const response = await dispatch(confirmPayment(orderParams));
    if (response) {
      for (let email of userEmails) {
        updateFirmUserDetails({ userEmail: email, action: PROJECT_COMPLETED });
      }
    }
    onClose();
  };

  return (
    <div>
      <Modal
        id="CheckoutModal"
        className={css.disableModalBorder}
        contentClassName={css.containerClassName}
        isOpen={isOpen || paymentIntentId}
        onClose={onClose}
        usePortal
        onManageDisableScrolling={(componentId, disableScrolling) =>
          dispatch(manageDisableScrolling(componentId, disableScrolling))
        }
      >
        {(isFinal ? paymentTerms === 1 : paymentTerms === 0) ? (
          <h3 className={css.paymentHeading}>
            <FormattedMessage id="CheckoutModal.noPaymentDueHeading" />
          </h3>
        ) : (
          !!payinTotal(currentTransaction) && (
            <CustomBreakdown
              transaction={currentTransaction}
              intl={intl}
              paymentTerms={paymentTerms}
              isProvider={isProvider}
              isStripeTransition={isStripeTransition}
              isFinal={isFinal}
              isProviderIndianOrigin={isProviderIndianOrigin}
            />
          )
        )}

        {isProviderIndianOrigin ? (
          <StripeIndiaPaymentPanel
            currentTransaction={currentTransaction}
            isFinal={isFinal}
            onClose={onClose}
            currentUser={currentUser}
            intentAuthenticationFailureMessage={intentAuthenticationFailureMessage}
            isOpen={isOpen || paymentIntentId}
            orderParams={orderParams}
            emailParams={emailParams}
            handlePaymentState={onlinePaymentState}
            paymentTerms={paymentTerms}
            stripeConnectAccountId={stripeConnectAccountId}
            isProviderIndianOrigin={isProviderIndianOrigin}
          />
        ) : (isFinal ? paymentTerms === 1 : paymentTerms === 0) ? (
          <Button onClick={handleFinalPayment} className={css.checkoutButton}>
            <FormattedMessage id="CheckoutModal.continueButton" />
          </Button>
        ) : (
          <StripePaymentForm
            className={css.paymentForm}
            onSubmit={handleSubmit}
            inProgress={submitting}
            formId="CheckoutPagePaymentForm"
            // paymentInfo={intl.formatMessage({ id: 'CheckoutPage.paymentInfo' })}
            authorDisplayName={currentUser?.attributes.profile?.displayName}
            // showInitialMessageInput={showInitialMessageInput}
            initialValues={initalValuesForStripePayment}
            initiateOrderError={initiateOrderError}
            confirmCardPaymentError={confirmCardPaymentError}
            confirmPaymentError={confirmPaymentError}
            hasHandledCardPayment={hasPaymentIntentUserActionsDone}
            // loadingData={!stripeCustomerFetched}
            defaultPaymentMethod={
              hasDefaultPaymentMethod ? currentUser.stripeCustomer.defaultPaymentMethod : null
            }
            paymentIntent={paymentIntent}
            onStripeInitialized={onStripeInitialized}
          />
        )}
      </Modal>
    </div>
  );
};

export default withRouter(CheckoutModal);
