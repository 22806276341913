import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import { Form, PrimaryButton, FieldTextInput, NamedLink, IconCard } from '../../components';
import * as validators from '../../util/validators';

import css from './LoginForm.module.css';
import { BACK_ARROW } from '../../components/IconCard/IconCard';

const LoginFormComponent = props => (
  <FinalForm
    {...props}
    render={fieldRenderProps => {
      const {
        rootClassName,
        className,
        formId,
        handleSubmit,
        inProgress,
        intl,
        invalid,
        showPassword,
        togglePasswordVisibility,
        handleNextStep,
        handlePreviousStep,
        currentStep,
        values,
        form
      } = fieldRenderProps;

      const loginFormHeadingWithSignupText = intl.formatMessage({
        id: 'LoginForm.loginFormHeadingWithSignupText',
      });
      const loginFormHeading = intl.formatMessage({
        id: 'LoginForm.loginFormHeading',
      });
      const welcomeMessage = intl.formatMessage({
        id: 'LoginForm.welcomeMessage',
      });
      const hidePasswordText = intl.formatMessage({
        id: 'LoginForm.hidePasswordText',
      });
      const showPasswordText = intl.formatMessage({
        id: 'LoginForm.showPasswordText',
      });
      const emailLabel = intl.formatMessage({
        id: 'LoginForm.emailLabel',
      });
      const emailPlaceholder = intl.formatMessage({
        id: 'LoginForm.emailPlaceholder',
      });
      const emailRequiredMessage = intl.formatMessage({
        id: 'LoginForm.emailRequired',
      });
      const emailRequired = validators.required(emailRequiredMessage);
      const emailInvalidMessage = intl.formatMessage({
        id: 'LoginForm.emailInvalid',
      });
      const emailValid = validators.emailFormatValid(emailInvalidMessage);

      // password
      const passwordLabel = intl.formatMessage({
        id: 'LoginForm.passwordLabel',
      });
      const passwordPlaceholder = intl.formatMessage({
        id: 'LoginForm.passwordPlaceholder',
      });
      const passwordRequiredMessage = intl.formatMessage({
        id: 'LoginForm.passwordRequired',
      });
      const passwordRequired = validators.requiredStringNoTrim(passwordRequiredMessage);

      const classes = classNames(rootClassName || css.root, className);
      const submitInProgress = inProgress;
      const submitDisabled = invalid || submitInProgress;


      return (
        <Form className={classes} onSubmit={(e) => {
          e.preventDefault()
          currentStep === 0 ? handleNextStep() : handleSubmit()
        }}>
          <div>
            <div className={css.loginTopbar}>
              {currentStep > 0 ? <div className={css.backArrow} onClick={() => handlePreviousStep(form)}>
                <IconCard brand={BACK_ARROW} />
              </div> : null}
              <div className={css.loginTitle}>{currentStep === 0 ? loginFormHeadingWithSignupText : loginFormHeading}</div>
            </div>
            <h1 className={css.brandName}>
              {welcomeMessage}
            </h1>
            <p className={css.subHeading}>
              <FormattedMessage id='LoginForm.subHeading' />
            </p>
            {currentStep === 0 ?
              <FieldTextInput
                className={css.emailInput}
                type="email"
                id={formId ? `${formId}.email` : 'email'}
                name="email"
                // autoComplete="email"
                label={emailLabel}
                placeholder={emailPlaceholder}
                validate={validators.composeValidators(emailRequired, emailValid)}
              />
              :
              currentStep === 1 ?
                <div className={css.passwordInput}>
                  <FieldTextInput
                    className={css.password}
                    type={showPassword ? 'text' : 'password'}
                    id={formId ? `${formId}.password` : 'password'}
                    name="password"
                    label={passwordLabel}
                    placeholder={passwordPlaceholder}
                    validate={passwordRequired}
                  />
                  {values?.password ? <label
                    className={css.showHide}
                    onClick={togglePasswordVisibility}
                  >
                    {showPassword ? hidePasswordText : showPasswordText}
                  </label> : null}
                </div>
                : null}
          </div>
          <div className={css.bottomWrapper}>
            <PrimaryButton type='button' inProgress={submitInProgress} disabled={submitDisabled} onClick={() => currentStep === 0 ? handleNextStep() : handleSubmit()}>
              <FormattedMessage id="LoginForm.logIn" />
            </PrimaryButton>
          </div>
        </Form>
      );
    }}
  />
);

LoginFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  form: null,
  inProgress: false,
};

const { string, bool } = PropTypes;

LoginFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  form: string,
  inProgress: bool,
  intl: intlShape.isRequired,
};

const LoginForm = compose(injectIntl)(LoginFormComponent);
LoginForm.displayName = 'LoginForm';

export default LoginForm;
