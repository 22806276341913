import { useEffect, useRef, useState } from "react";

const useTypeWriterEffect = () => {
  const [currentText, setCurrentText] = useState("");
  const questionIndex = useRef(0);
  const charIndex = useRef(0);
  const isRunningRef = useRef('');

  function handleTypeWriter(questions){
    let text = '';

    const typeWriter = () => {
      if (!isRunningRef.current) return;
  
      if (charIndex.current < questions[questionIndex.current].length) {
        text += questions[questionIndex.current][charIndex.current];
        setCurrentText(text);
        charIndex.current++;
        setTimeout(typeWriter, 35);
      } else {
        text = ''
        charIndex.current = 0;
        questionIndex.current = (questionIndex.current + 1) % questions.length;
        setTimeout(() => {
          setCurrentText('');
          typeWriter();
        }, 2000);
      }
    };
    
    if(questions?.length) typeWriter();
  }

  return [currentText, setCurrentText, isRunningRef, questionIndex, charIndex, handleTypeWriter]
}

export default useTypeWriterEffect