import React, { useEffect, useState } from 'react';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
// These relative imports need to point to correct directories
import { injectIntl, FormattedMessage } from '../../util/reactIntl';
import { Form, FieldTextInput, PrimaryButton, StripePaymentAddress } from '../../components';
// Create this file using EditListingFeaturesForm.module.css
// as a template.
import css from './StripeIndiaPaymentForm.module.css';
import { withRouter } from 'react-router-dom';

export const StripeIndiaPaymentForm = props => {
  return (
    <FinalForm
      {...props}
      mutators={{
        ...arrayMutators,
      }}
      render={formRenderProps => {
        const {
          className,
          handleSubmit,
          intl,
          pristine,
          updateError,
          updateInProgress,
          values,
          isOpen,
          form,
          submitInProgress,
          formId,
          stripe
        } = formRenderProps;
        useEffect(()=>{
          if(!isOpen) {
            form.reset();
          }
        },[isOpen])
        const [cardElement, setCardElement] = useState(null);

        const submitDisabled = submitInProgress || !values?.cardHolderName || !values?.city || !values?.country  || !values?.postal || !values?.addressLine1;
        const handleCardValueChange = (event) => {
          const postalCode = event.value.postalCode;
          if (form) {
            form.change('postal', postalCode);
          }
        };
        useEffect(() => {
          const elements = stripe.elements();
          const card = elements.create('card',{hidePostalCode: true});
          card.mount('#card-element');
          card.addEventListener('change', handleCardValueChange)
          setCardElement(card);
          form.change('cardElement', card);

          // EventListener is the only way to simulate breakpoints with Stripe.

        }, []);
        return (
          <Form className={css.form} onSubmit={handleSubmit}>
            <h3 className={css.modalHeadingName}>
              <FormattedMessage id="StripeIndiaPaymentForm.heading" />
            </h3>
            <h3 className={css.modalHeadingName}>
              <FormattedMessage id="StripeIndiaPaymentForm.cardHeading" />
            </h3>
            <div id="card-element"></div>
            <div>
              <h3>
                <FormattedMessage id="StripeIndiaPaymentForm.billingDetailsHeading" />

              </h3>
            </div>
            <FieldTextInput
              className={css.firstNameRoot}
              type="text"
              id={formId ? `${formId}.cardHolderName` : 'cardHolderName'}
              name="cardHolderName"
              label={'Cardholder Name'}
              placeholder={'Cardholder Name'}
            // validate={firmRequired}
            />
            <StripePaymentAddress intl={intl} form={form} fieldId={formId} card={cardElement} />

            <PrimaryButton
              type="button"
              inProgress={submitInProgress}
              disabled={submitDisabled}
              className={css.primaryButton}
              onClick={handleSubmit}
            >
              <FormattedMessage id="StripeIndiaPaymentForm.payNowButton" />
            </PrimaryButton>
          </Form>
        );
      }}
    />
  );
};



export default compose(injectIntl, withRouter)(StripeIndiaPaymentForm);
