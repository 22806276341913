import React, { useEffect, useState } from 'react';
import css from './GoogleMeetPanel.module.css';
import { GoogleMeetForm } from '../../forms';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import { createSlug } from '../../util/urlHelpers';
import { getDefaultTimeZoneOnBrowser } from '../../util/dates';
import moment from 'moment';
import googleLoginIcon from '../../assets/btn_google_signin_light_normal_web@2x.png';
import { GOOGLE_MEET, LISTING_PAGE, TRANSACTION_DETAILS_PAGE } from '../../util/types';
const GoogleMeetPanel = props => {
  const {
    onGetGoogleAccessToken,
    onScheduleGoogleMeet,
    currentUser,
    currentAuthor,
    history,
    params,
    meetingInProgress,
    closeGoogleMeetingModel,
    isOpen,
    meetingCreated,
    currentPath,
    meetingError,
    currentTransaction,
    currentProvider,
    setMeetingSuccessToaster,
    userEmailsForGoogleMeet,
    isTransactionPage
  } = props;

  const [showGoogleSignInButton, setShowGoogleSignInButton] = useState(false);

  useEffect(() => {
    if (!isOpen) setShowGoogleSignInButton(false);
  }, [isOpen]);


  // useEffect(() => {
  //   if (meetingCreated && currentPath?.includes('/details')) {
  //     history.push(
  //       createResourceLocatorString(TRANSACTION_DETAILS_PAGE, routeConfiguration(), {
  //         id: currentPath.split('/')[2],
  //         // slug:createSlug(params.slug)
  //       })
  //     );
  //   } else if (meetingCreated) {
  //     history.push(
  //       createResourceLocatorString(LISTING_PAGE, routeConfiguration(), {
  //         id: params.id,
  //         slug: createSlug(params.slug),
  //       })
  //     );
  //   }
  //   closeGoogleMeetingModel();
  // }, [meetingCreated]);

  const collaborationMetaData = !!currentTransaction?.id && currentTransaction?.attributes?.metadata?.collaborationMetaData;
  const currentUserEmail = currentUser && currentUser.id && currentUser.attributes.email;
  const currentAuthorId = !!currentAuthor && currentAuthor?.id && currentAuthor?.id.uuid;
  const isGoogleAccessToken =
    !!currentUser &&
    !!currentUser.id &&
    !!currentUser.attributes?.profile?.protectedData?.googleMeetTokens?.access_token;


  const handleGoogleMeet = async (values,form) => {
    const {
      summary,
      description,
      date,
      endTime,
      startTime,
      collaborators,
    } = values;

    const collaboratorsEmails = collaborators?.map((e) => ({ email: e }));
    const startDateTime = new Date(`${date}T${startTime}`);
    const endDateTime = new Date(`${date}T${endTime}`);
    const meet = await onScheduleGoogleMeet({
      id: currentTransaction?.id?.uuid,
      summary,
      description,
      startDateTime: startDateTime.toISOString(),
      endDateTime: endDateTime.toISOString(),
      clientEmail: currentUserEmail,
      currentAuthorId,
      timezone: getDefaultTimeZoneOnBrowser() || 'Etc/UTC',
      emails: collaborators ? collaboratorsEmails : userEmailsForGoogleMeet,
      currentUser,
      currentTransaction,
      isTransactionPage
    });

    if (meet?.message) {
      sessionStorage.removeItem('params')
      setMeetingSuccessToaster(true)
      setTimeout(() => {
        setMeetingSuccessToaster(false)
      }, 3000)
      closeGoogleMeetingModel()
    }

  };

  return (
    <div className={css.panelContainer}>
      {isGoogleAccessToken && !meetingError ? (
        <GoogleMeetForm
          onSubmit={(values,form) => handleGoogleMeet(values,form)}
          updateInProgress={meetingInProgress}
          isOpen={isOpen}
          collaborationMetaData={collaborationMetaData}
          currentAuthor={currentAuthor}
          currentProvider={currentProvider}
          currentUserEmail={currentUserEmail}
        />
      ) : (
        <div className={css.panelHeading}>
          <img
            className={css.googleLoginIcon}
            src={googleLoginIcon}
            onClick={() => {
              typeof window !== 'undefined' && window.sessionStorage.setItem('params', JSON.stringify({ ...params, currentForm: GOOGLE_MEET }));
              history.push(
                createResourceLocatorString('GoogleMeetRedirectPage', routeConfiguration(), {})
              );
            }}
          />
        </div>
      )}

    </div>
  );
};

export default GoogleMeetPanel;
