import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './CancellationPolicy.module.css';

const CancellationPolicy = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  const emailLink = <a href='mailto:admin@insightgig.com'>admin@insightgig.com</a>;

  return (
    <div className={classes}>
      <p>Effective Date: 1st October 2023</p>
      <p>
        Welcome to InsightGig, the global platform for insights professionals. We strive to provide a seamless and efficient experience for our clients and experts. This Cancellation and Refunds Policy outlines our platform's cancellations and refunds guidelines. By using InsightGig, you agree to comply with this Cancellation and Refunds Policy.
      </p>

      <p>
        InsightGig currently charges fees and commission to ‘Clients’, i.e. those who commission ad hoc research projects or hire ‘Experts’ in the market research, customer experience, data analytics, and associated domains over the short or long term on contract or on their payrolls. InsightGig does not charge Experts any fees for using its platform. Therefore, the cancellation and refunds policy is only applicable to Clients. 
      </p>

      <p>The fees charged by InsightGig are as follows.</p>
      <ol>
        <li>
          <strong>Subscription fees:</strong> Clients can pay a monthly or annual subscription fee to avail certain additional features or services or get higher access to certain features. InsightGig also offers a free account for Clients with no subscription fees.
        </li>
        <li>
          <strong>Commission on ad hoc projects:</strong> InsightGig charges a percentage fee on the value of the ad hoc project commissioned on the InsightGig platform. The value of a particular project to be delivered by an Expert is determined by them independently. InsightGig has no role in the cost estimates provided by Experts. The percentage fee applicable on a project varies based on the subscription plan taken by the Client, and can be viewed on the Subscription page on the platform. This percentage fee is payable at the same schedule as the payments to be made to the Expert. For instance, if the payment terms are 50% on commissioning and 50% on delivery then the InsightGig commission would also be split in the same ratio and payable along with the fees paid to the Expert.
        </li>
        <li>
          <strong>Commission on hiring talent on secondment or full-time employment:</strong> InsightGig charges a percentage fee on the value of the total contract value in the case of a Secondment or a percentage fee on the annual CTC (Cost to Company) in the case of full-time employment. Once again, InsightGig has no role in determining the fees or salary to be paid to the Expert, it is entirely negotiated between the Client and the Expert. This percentage fee varies based on the subscription plan taken by the Client, and can be viewed on the Subscriptions page on the platform. This percentage fee is payable at the same schedule as the payments to be made to the Expert in case of Secondment and in advance in the case of full-time employment.
        </li>
      </ol>
      <p>Now, let's look at the cancellation and refunds policy for each of the above fees/charges.</p>

      <h2 className={css.subTitle}>Cancellation & Refund on Subscription Fees</h2>
      <p>Clients can opt for a monthly or an annual subscription.</p>
      <p>
        Clients can cancel their monthly or annual subscriptions at any time. Upon cancellation, the client will not be charged for the subsequent month or year, as the case may be, and their access to subscription-based features will continue until the end of the current billing period. Prorated refunds will not be provided for the current billing cycle.
      </p>
      <p>
        For new Clients (first time subscribers) choosing an annual subscription, InsightGig offers a 15 day no obligation cancellation policy. The Client can contact InsightGig on {emailLink} within 15 days to initiate a cancellation.
      </p>
      <p>
        Monthly or annual subscriptions paid online using a Credit Card will renew automatically at the end of the billing period. Clients wanting to cancel an online subscription can do so online before the end of the billing cycle on the platform. For offline monthly or annual subscriptions, InsightGig will contact the Client 10 to 15 days before the end of the billing cycle to check the intent of the Client to renew.  
      </p>

      <h2 className={css.subTitle}>Refund on Commission on Ad Hoc Projects</h2>
      <p>
        InsightGig is not liable for any refunds for the fees charged by the Expert on a particular project. On a case to case basis, InsightGig may consider a refund for the commission charged by it for a project under the following circumstances.  
      </p>
      <ol>
        <li>
          Client decides to terminate a project after commissioning but before execution starts. In such cases, please reach out to us at {emailLink} to consider your request.
        </li>
        <li>
          Client is extremely dissatisfied with the execution of a project after time and resources have been spent on it by an Expert. For this case, refer ‘Dispute Resolution Process’ below.
        </li>
      </ol>
      <p>
        Any refund provided by InsightGig would be capped to the commission being charged by InsightGig on the project. Further, under no circumstance, would a refund on an ad hoc project be adjusted against subscription fees.   
      </p>

      <h2 className={css.subTitle}>Refund on Commission on Secondment or Full-Time Employment</h2>
      <p>
        Once an Expert signs a contract with a Client for full-time employment, the commission to InsightGig becomes due. No refunds or claims are applicable once a contract has been signed between the Expert and the Client for a secondment or a full-time employment contract. On a case to case basis, InsightGig may consider a refund for the commission charged by it under the following circumstances.   
      </p>
      <ol>
        <li>
          Client or Expert decides to terminate the agreement after signing the contract but before the date of starting work in case of Secondment and before the day of joining in the case of full-time employment. In such cases, please reach out to us at {emailLink} to consider your request. In such cases, InsightGig may consider forgoing 50% of its fee. InsightGig would also offer to provide alternative Experts at no extra cost if the Client is still interested in hiring Expert for the role. 
        </li>
      </ol>
      <p>
        Any refund provided by InsightGig would be capped to the commission being charged by InsightGig on the project. Further, under no circumstance, would a refund on an ad hoc project be adjusted against subscription fees.   
      </p>

      <h2 className={css.subTitle}>Dispute Resolution Process</h2>
      <p>
        Before initiating a dispute, Clients are encouraged to communicate with the assigned expert through the InsightGig platform. Open communication allows for resolving concerns and issues before pursuing a cancellation.
      </p>
      <p>
        The Dispute Resolution Process can be triggered by the Client by sending an email to {emailLink}. On receiving this email, InsightGig will form a dispute resolution panel depending on the value of the ad hoc project. 
      </p>
      <ul>
        <li>{`Projects of value < USD 1,000: One of the founders of InsightGig.`}</li>
        <li>{`Projects of value between USD 1,000 to 10,000: Both founders of InsightGig.`}</li>
        <li>{`Projects of value > USD 10,000: Both founders of InsightGig AND an independent person with at least 10 years of experience in the research, insights, and analytics industry. The person will be appointed by InsightGig and would have no commercial link or conflict of interest with InsightGig, or the other parties involved. The independent person will be paid for their services by InsightGig.`}</li>
      </ul>
      <p>
        The panel would ask for further details from both parties through email and calls. The panel would gather further information to understand the full background of the issue from both sides and find the root cause. The panel would also suggest an amicable resolution to move forward, without closing the Project. The TAT for resolving disputes would be as follows.
      </p>
      <ul>
        <li>{`Projects of value < USD 1,000: 5 Working Days`}</li>
        <li>{`Projects of value USD 1,000 to 10,000: 10 Working Days`}</li>
        <li>{`Projects of value > USD 10,000: 15 Working Days`}</li>
      </ul>
      <p>
        Once the resolution is acceptable to all parties, the case will be closed. If the resolution provided is not acceptable to either party, another attempt will be made for an alternate mutually acceptable resolution that keeps the Project live. If the second proposed resolution is also not acceptable to all parties (hopefully this never happens!), then the Project would be closed. Even in this rare case, both parties would have to agree on a mutually acceptable fee for the work done by the Expert till the dispute was raised. The InsightGig commission will be a percentage of this renegotiated fee, depending on the subscription plan of the Client. 
      </p>

      <h2 className={css.subTitle}>Compliance to Laws & Restrictions on Payment Gateway</h2>
      <p>
        All refunds would be subject to international regulations and limitations applicable on our Payment Gateway (Stripe). 
      </p>

      <h2 className={css.subTitle}>Changes to this Policy</h2>
      <p>
        InsightGig reserves the right to modify or update this Cancellation and Refunds Policy at any time. We will notify users of any changes by posting the revised policy on our website.
      </p>

      <h2 className={css.subTitle}>Contact Us</h2>
      <p>
        If you have any questions or need further assistance regarding our Cancellation and Refunds Policy, please contact us at {emailLink}.
      </p>
    </div>
  );
};

CancellationPolicy.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

CancellationPolicy.propTypes = {
  rootClassName: string,
  className: string,
};

export default CancellationPolicy;
