import React from 'react';
import PropTypes from 'prop-types';

import css from './TosterMessage.module.css';
import IconCard from '../IconCard/IconCard';
import classNames from 'classnames';

const TosterMessage = props => {
    const { message, onClose, isClass } = props;

    return (
        <div className={classNames( isClass ? css.tosterMessage: css.memberCard)}>
            {message}
            <span className={css.closeToster} onClick={onClose}>
                CLOSE <IconCard brand="close" />
            </span>
        </div>
    );
};

const { node, string } = PropTypes;

TosterMessage.defaultProps = {
    className: null,
    rootClassName: null,
    navRootClassName: null,
    tabRootClassName: null,
};

TosterMessage.propTypes = {
    children: node.isRequired,
    className: string,
    rootClassName: string,
    navRootClassName: string,
    tabRootClassName: string,
};

export default TosterMessage;
