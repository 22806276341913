import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { ensureOwnListing } from '../../util/data';
import { EditListingHintTooltip, EditListingProgress, ListingLink, Modal } from '../../components';
import { EditListingEducationForm } from '../../forms';

import css from './EditListingEducationPanel.module.css';
import ContentEducation from "../../forms/EditListingEducationForm/ContentEducation";

const EditListingEducationPanel = props => {
  const {
    className,
    rootClassName,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    onManageDisableScrolling,
    errors,
    completedTabsPer,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);
  const { publicData } = currentListing.attributes;

  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const panelTitle = isPublished ? (
    <FormattedMessage
      id="EditListingEducationPanel.titlePanel"
      values={{ listingTitle: <ListingLink listing={listing} /> }}
    />
  ) : (
    <FormattedMessage id="EditListingEducationPanel.titlePanel" />
  );
  const initValue = publicData.Education || []
  const [openModal, SetOpenModal] = useState(false)
  const [experienceList, SetExperienceList] = useState(initValue)
  const [initialValues, SetInitialValues] = useState({})
  const [notReady, SetNotReady] = useState(true)
  const checkedButton = type => notReady ? type : notReady
  const noRedirect = 'noRedirect'
  const submitModal = newExp => {
    let Education = []
    if (newExp.type) {
      let filterArr = experienceList.filter(el => el.number !== newExp.type)
      Education.push(...filterArr)
    }
    if (!newExp.type && !newExp.values.number) {
      let number = experienceList.map(el => el.number)[experienceList.length - 1] + 1 || 1
      Education.push(...experienceList, { number: number, ...newExp.values })
    }
    if (!newExp.type && newExp.values.number) {
      let filterArr = experienceList.filter(el => el.number !== newExp.values.number)
      Education.push(...filterArr, newExp.values)
    }
    SetExperienceList(Education.sort((a, b) => a.number - b.number))
    closeModal()
    SetNotReady(false)
    return onSubmit({ publicData: { Education }, noRedirect });
  }

  const closeModal = () => {
    SetInitialValues({})
    SetOpenModal(false)
  }
  return (
    <div className={classes}>
      {/* <EditListingProgress progressCount={completedTabsPer} value={completedTabsPer} /> */}
      <h2 className={css.title}>{panelTitle}</h2>
      <EditListingHintTooltip
        tooltipText={<FormattedMessage id="EditListingEducationPanel.EnterDetailsEducational" />}
      />
      <Modal
        id="MissingInformationReminder"
        className={css.disableModalBorder}
        contentClassName={css.containerClassName}
        isOpen={openModal}
        onClose={closeModal}
        usePortal
        onManageDisableScrolling={onManageDisableScrolling}
      >
        <ContentEducation
          initialValues={initialValues}
          SetInitialValues={SetInitialValues}
          experienceList={experienceList}
          SetExperienceList={SetExperienceList}
          onSubmit={submitModal}
          onClose={SetOpenModal}
        />
      </Modal>

      <EditListingEducationForm
        SetInitialValues={SetInitialValues}
        SetOpenModal={SetOpenModal}
        experienceList={experienceList}
        initialValues={{ Education: experienceList }}
        onSubmit={values => {
          SetNotReady(true)
          const { Education = [] } = values;

          const updatedValues = {
            publicData: {
              Education,
            }
          }

          onSubmit(updatedValues)
        }}
        onChange={onChange}
        disabled={disabled}
        ready={checkedButton(ready)}
        saveActionMsg={submitButtonText}
        updated={checkedButton(panelUpdated)}
        updateInProgress={updateInProgress}
        onManageDisableScrolling={onManageDisableScrolling}
        fetchErrors={errors}
      />
    </div>
  );
};

const { func, object, string, bool } = PropTypes;

EditListingEducationPanel.defaultProps = {
  className: null,
  rootClassName: null,
  listing: null,
};

EditListingEducationPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingEducationPanel;
