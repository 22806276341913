import React, { useState } from 'react';
import Modal from './../../Modal/Modal';
import css from './../../OpenAIReportSuccessModal/OpenAIReportSuccessModal.module.css'
import { FormattedMessage } from 'react-intl';
import { manageDisableScrolling } from '../../../ducks/UI.duck';

const SessionInProgressModal = props => {
  const { openSuccessModal, setOpenSuccessModal, history, dispatch } = props || {};
  return (
    <>
      <Modal
        id="sessionInProgressModal"
        className={css.disableModalBorder}
        contentClassName={css.containerClassName}
        isOpen={openSuccessModal}
        onClose={() => {
          setOpenSuccessModal(false);
          // history.goBack();
        }}
        usePortal
        onManageDisableScrolling={(componentId, disableScrolling) =>
          dispatch(manageDisableScrolling(componentId, disableScrolling))
        }
      >
        <div className={css.successModalContainer}>
          <h2>
            <FormattedMessage id="OpenAiAppsPanel.successModalHeading" />
          </h2>
          <p>
            <FormattedMessage id="GigAppHomePageSection.transcriptionInProgressMessage" />  
          </p>
        </div>
      </Modal>
    </>
  );
};

export default SessionInProgressModal;
