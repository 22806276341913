import React from 'react'
import { INDIA, OTHERS } from '../../util/types';
import css from './StripePayoutSwitcherPanel.module.css';
import { FormattedMessage } from 'react-intl';
import Button from '../Button/Button';
const StripePayoutSwitcherPanel = (props) => {
    const {
        isStripeInConnected,
        stripeConnected,
        selectedOption,
        setSelectedOption,
        currentOption,
        setCurrentOption
    } = props;
    if (isStripeInConnected || stripeConnected || currentOption) {
        return null;
      }
    
      return (
        <div>
          <h3 className={css.countryHeading}>
            <FormattedMessage id="StripePayoutPage.CountryHeading" />
          </h3>
    
          <div className={css.radioSelect}>
            <label>
              <input
                type="radio"
                value={INDIA}
                checked={selectedOption === INDIA}
                onChange={() => setSelectedOption(INDIA)}
                className={css.radioSelectedOption}
              />
              {INDIA}
            </label>
    
            <label>
              <input
                type="radio"
                value={OTHERS}
                checked={selectedOption === OTHERS}
                onChange={() => setSelectedOption(OTHERS)}
                className={css.radioSelectedOption}
              />
              {OTHERS}
            </label>
          </div>
    
          <Button
            className={css.nextStepButton}
            disabled={!selectedOption}
            onClick={() => setCurrentOption(selectedOption)}
          >
            <FormattedMessage id="StripePayoutPage.nextStepButtonText" />
          </Button>
        </div>
      );
    };

export default StripePayoutSwitcherPanel