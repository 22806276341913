import moment from 'moment';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { fetchCurrentUser } from '../../ducks/user.duck';
import { apiBaseUrl } from '../../util/api';
import { storableError } from '../../util/errors';
import { types as sdkTypes } from '../../util/sdkLoader';
import { getCsmBannerTime } from '../../util/destructorHelpers';
import { approvedUserStatus } from '../../util/userRole';
import { updateProfile } from '../ProfileSettingsPage/ProfileSettingsPage.duck';
const { UUID } = sdkTypes;

//Action types

export const SHOW_LISTING_REQUEST = 'app/LandingPage/SHOW_LISTING_REQUEST';
export const SHOW_LISTING_ERROR = 'app/LandingPage/SHOW_LISTING_ERROR';

//InitialState
const initialState = {
  id: null,
};

//reducer function
export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SHOW_LISTING_REQUEST:
      return { ...state, id: payload.id, showListingError: null };
    case SHOW_LISTING_ERROR:
      return { ...state, showListingError: payload };
    default:
      return state;
  }
}

//Selectors

export const checkScrollingDisabled = state => isScrollingDisabled(state);

// ================ Action creators ================ //
export const showListingRequest = id => ({
  type: SHOW_LISTING_REQUEST,
  payload: { id },
});

export const showListingError = e => ({
  type: SHOW_LISTING_ERROR,
  error: true,
  payload: e,
});

// SDK method: sdk.currentUser.updateProfile


// ================ Thunk ================ //

// export const showListing = (listingId,isOwn = false) => async (dispatch, getState, sdk) => {
//   try {
//   dispatch(showListingRequest(listingId));
//   dispatch(fetchCurrentUser());
//   const params = {
//     id: listingId,
//     include: ['author', 'author.profileImage', 'images'],
//   };

//   const response =  isOwn ? await  sdk.ownListings.show(params) : await sdk.listings.show(params);

//   dispatch(addMarketplaceEntities(response));
//   return response;
// } catch (e) {
//     dispatch(showListingError(storableError(e)));
// }}


//LoadData
export const loadData = () => async (dispatch, getState, sdk) => {
  try {
    await dispatch(fetchCurrentUser());
    const { currentUser = {} } = getState().user || {};
    const csmBannerTime = (currentUser?.id && getCsmBannerTime(currentUser)) || {};
    const isApprovedUser = currentUser?.id && approvedUserStatus(currentUser);
    
    if (currentUser?.id && !csmBannerTime.landingPageTime && isApprovedUser) {
      // The date and time at which the banner on the landing page should be hidden.
      const landingPageCsmBannerTime = moment().add(30, 'days').unix(); 

      dispatch(updateProfile({
        publicData: {
          csmBannerTimeObject: {
            ...csmBannerTime,
            landingPageTime: landingPageCsmBannerTime,
          },
        },
      }));
    }
    if (currentUser?.id?.uuid) {
      let response = await axios.post(`${apiBaseUrl()}/api/get-discourse-sso-user/${currentUser?.id?.uuid}`)
    }

  } catch (e) {
    return;
  }
};