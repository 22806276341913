import React, { useRef, useState } from 'react'
import css from './LandingPageTabsPanel.module.css'
import { FormattedMessage } from 'react-intl';
import NamedLink from '../NamedLink/NamedLink';
import CsmSupportBanner from '../CsmSupportBanner/CsmSupportBanner';
import { getUserRole } from '../../util/userRole';
import { ACCESS_LONG_TERM_INSIGHTS_TALENT, AD_HOC_INSIGHT_PROJECTS, CONNECT_WITH_PEERS, EXECUTIVE_INSIGHT_PROJECTS, GET_STUFF_DONE, HOMEPAGE_NOTIFICATION, LONG_TERM_INSIGHT_OPPORTUNITY, TWEAK_YOUR_PROFILE, USER_ROLE_CLIENT, USER_ROLE_CSM, USER_ROLE_PARTNER, } from '../../util/types';
import { v4 as uuidv4 } from 'uuid';
import collaborateIcon from '../../assets/collaborateicon.png';
import { Button, Modal } from '..';
import { manageDisableScrolling } from '../../ducks/UI.duck';
import { useDispatch } from 'react-redux';
import { getCsmBannerTime, getUserDetails } from '../../util/destructorHelpers';
import LandingPageFirstTab from './Tabs/LandingPageFirstTab';
import LandingPageSecondTab from './Tabs/LandingPageSecondTab';
import LandingPageThirdTab from './Tabs/LandingPageThirdTab';
import LandingPageFourthTab from './Tabs/LandingPageFourthTab';
import LandingPageFifthTab from './Tabs/LandingPageFifthTab';
import { sendEmailUsingZepto } from '../../util/api';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import moment from 'moment';
import { IconCardHomePage } from "../../components";
import classNames from 'classnames';
import config from "../../config"

const LandingPageTabsPanel = (props) => {
	const { currentUser, location, history, intl, params,isApprovedUser } = props;
	const { pathname = '' } = location || {};
	const {canonicalRootURL} = config;
	const [activeTabData, setActiveTabData] = useState({});
	const [isAdminEmailModalOpen, setIsAdminEmailModalOpen] = useState(false);
	const [openSearchModal, setOpenSearchModal] = useState(false);
	const [activeTab, setActiveTab] = useState(false);
	const dispatch = useDispatch();
	const userRole = currentUser?.id && getUserRole(currentUser);
	const userEmail = currentUser?.id && getUserDetails(currentUser)?.email;
	const userName = currentUser?.id && getUserDetails(currentUser)?.fullName;
	// const match = pathname.match(/\/home\/(.+)/);
	// const extractedString = match ? match[1] : null;
	const firmId = !!currentUser?.id && getUserDetails(currentUser)?.firmId;
	const firmName = !!currentUser?.id && getUserDetails(currentUser)?.firmName;
	const csmBannerTime = (currentUser?.id && getCsmBannerTime(currentUser)) || {};
	const { landingPageTime = null, gigAppsPageTime = null, firmPageTime = null } = csmBannerTime || {};
	const checkName = (name)=> currentUser ? name : "LoginPage";
	const searchInputRef = useRef();

	const handleAdminEmailModal = (params) => {
		if (!currentUser?.id) {
			const state = {
				from: `${location.pathname}${location.search}${location.hash}`,
				// extractedString
			};
			history.push(
			createResourceLocatorString('LoginPage', routeConfiguration(), {}, {}),
			state
			);
		}
		else {
			setIsAdminEmailModalOpen(!isAdminEmailModalOpen)
			setActiveTabData({ userEmail, userName, userRole, step: HOMEPAGE_NOTIFICATION, ...params })
		}
	}

	const handleAdminEmail = async () => {
		const response = await sendEmailUsingZepto(activeTabData)
		if (response?.message === 'Success') {
			setIsAdminEmailModalOpen(false)
		}
	}

	const tabs = [
		{
			icon: <IconCardHomePage type="search" />,
			text: userRole === USER_ROLE_PARTNER ? 'Explore Opportunities' : 'Explore Talent'
		},
		{
			icon: <IconCardHomePage type="toolkit" />,
			text: 'Insights Toolkit'
		},
		{
			icon: <IconCardHomePage type="assistant" />,
			text: 'AI Assistant'
		},
		// {
		// 	icon: <IconCardHomePage type="connect" />,
		// 	text: 'Connect with Peers'
		// },
		{
			icon: <IconCardHomePage type="profile" />,
			text: 'Update your Profile'
		},
	]

	const shouldRenderTabsData = () => {
		// Define a mapping of component keys to their properties
		const componentMapping = {
			[1]: {
				Component: LandingPageSecondTab,
				props: {
					userRole,
					handleAdminEmailModal,
					history,
					intl,
					isApprovedUser,
					currentUser,
					canonicalRootURL,
					checkName
				},
			},
			[2]: {
				Component: LandingPageThirdTab,
				props: {
					handleAdminEmailModal,
					isApprovedUser,
					currentUser,
					canonicalRootURL
				},
			},
			[3]: {
				Component: LandingPageFirstTab,
				props: {
					handleAdminEmailModal,
					intl,
					isApprovedUser,
					currentUser,
					canonicalRootURL
				},
			},
			// [4]: {
			// 	Component: LandingPageFourthTab,
			// 	props: {
			// 		currentUser,
			// 		isApprovedUser,
			// 		canonicalRootURL
			// 	},
			// },
			[4]: {
				Component: LandingPageFifthTab,
				props: {
					userRole,
					firmId,
					firmName,
					currentUser,
					handleAdminEmailModal,
					isApprovedUser,
					canonicalRootURL,
					checkName
				},
			},
		};

		if (!componentMapping[activeTab]) {
			const tabSequence = moment().unix() > landingPageTime ? [3, 2, 4, 1] : [1, 2, 4, 3];
			const allComponents = tabSequence.map(key => {
				const componentObject = componentMapping[key];
				const { Component, props } = componentObject;
				return <Component key={key} {...props} />
			})

			return allComponents;
		}

		const { Component, props } = componentMapping[activeTab];

		if (activeTab === 3) {
			return (
				<Component {...props} />
			)
		}

		return (
			<>
				<div className={css.csmSupportBanner}>
					<CsmSupportBanner
						isLandingPage={true}
						intl={intl}
						csmBannerTime={csmBannerTime}
					/>
				</div>
				<Component {...props} />
				{moment().unix() > landingPageTime && (
					<LandingPageFirstTab handleAdminEmailModal={handleAdminEmailModal} intl={intl} />
				)}
			</>
		);
	};

	function handleTabClick(value) {
		if(isApprovedUser){
			if(value === 3){
				setOpenSearchModal(true)
				return
			}
			if (activeTab === value) {
				setActiveTab(false)
			}
			else setActiveTab(value)
		}
	}

	async function handleSearch(){
		const searchValue = searchInputRef.current.value;
		const array = [...Array(4).keys()].map(item => {
			const random = Math.floor((Math.random() * 21))
			return random
		})
		history.push('/gig-apps-results', {apps: array})
	}

	return (
		<div className={css.mainWrapper}>
			<div className={css.welcomeTabs}>
				<div className={css.tabButtons}>
					{tabs.map((tab, index) => {
						return (
							<button
								key={index}
								onClick={() => handleTabClick(index + 1)}
								className={classNames(activeTab === (index + 1) ? css.active : css.inActive, index === 2 && css.disabled)}
							>
								{index === 2 && <div className={css.ribbon}><span className={css.ribbonContent}>Coming soon</span></div>}
								<div>
									<div className={css.tabIcon}>
										{tab.icon}
									</div>
									<div className={css.tabName}>
										{tab.text}
									</div>
								</div>
							</button>
						)
					})}
				</div>
			</div>
			<div className={css.tabContainer}>
				{shouldRenderTabsData()}
			</div>

			<Modal
				id="adminEmailModal"
				className={css.disableModalBorder}
				contentClassName={css.containerClassName}
				isOpen={isAdminEmailModalOpen}
				onClose={() => setIsAdminEmailModalOpen(false)}
				usePortal
				onManageDisableScrolling={(componentId, disableScrolling) =>
					dispatch(manageDisableScrolling(componentId, disableScrolling))
				}
				isAdminEmailModal={true}
			>
				<h2 className={css.emailModalHeading}>
					<FormattedMessage id="LandingPageTabsPanel.adminEmailModalHeading" />
				</h2>

				<div className={css.emailModalActionButtons}>
					<button onClick={() => setIsAdminEmailModalOpen(false)}>
						<FormattedMessage id="LandingPageTabsPanel.adminEmailModalClostButtonText" />
					</button>
					<Button onClick={() => handleAdminEmail()}>
						<FormattedMessage id="LandingPageTabsPanel.adminEmailModalSenEmailButtonText" />
					</Button>
				</div>
			</Modal>

			<Modal
				id="aiSearchModal"
				className={css.disableModalBorder}
				contentClassName={css.containerClassName}
				isOpen={openSearchModal}
				onClose={() => setOpenSearchModal(false)}
				usePortal
				onManageDisableScrolling={(componentId, disableScrolling) =>
					dispatch(manageDisableScrolling(componentId, disableScrolling))
				}
				removeCloseButton={true}
			>
				<div className={css.searchModal}>
					<h3> Identify the right GigApp for you. </h3>
					<input 
						ref={searchInputRef} 
						// value={searchInputRef.current.value}
						onChange={e => searchInputRef.current.value = e.target.value}
						placeholder='What would you like to do today?'
					/>
					<span className={css.tip}>
						Tip: Provide at least 1 to 2 lines instead of a word for better results.
					</span>
					<div className={css.emailModalActionButtons}>
						<button onClick={() => setOpenSearchModal(false)}>
							<FormattedMessage id="LandingPageTabsPanel.adminEmailModalClostButtonText" />
						</button>
						<Button onClick={handleSearch}>
							Search
						</Button>
					</div>
				</div>
			</Modal>
		</div>
	)
}

export default LandingPageTabsPanel