import React from 'react'
import { FormattedMessage } from 'react-intl'
import css from '../GigAppTransactionPanel.module.css'
import { AI_TRANSCRIPTION } from '../../../util/types';
import OpenAIAppDescriptionPanel from '../../OpenAIAppsPanel/OpenAIAppDescriptionPanel';
import { appsData } from '../../GigAppsPanel/appsData';
import OpenAIFeaturesPanel from '../../OpenAIAppsPanel/OpenAIFeaturesPanel';
import OpenAICategoryPanel from '../../OpenAIAppsPanel/OpenAICategoryPanel';
import ExternalLink from '../../ExternalLink/ExternalLink';

const OverviewSection = (props) => {
  const { currentTransaction = {} } = props;
  const { listing = {} } = currentTransaction || {};
  const { title, publicData = {} } = listing?.attributes || {};
  const { appRoute } = publicData || {};
  const currentAppData = appsData?.find(app => app.route === appRoute);
  const { tags, category, description, name, logo, pricing } = currentAppData || {};

  const handleTransactionApps = () => {
    switch (appRoute) {
      case AI_TRANSCRIPTION:
        return (
          <div className={css.descriptionDetail}>

          </div>
        );
    }
  };
  return (
    <div className={css.overviewSection}>
      <h3 className={css.overviewSectionHeading}>
        {title}
      </h3>
      <p className={css.overviewSectionDescription}>
        {description}
      </p>
      <div>
        <OpenAICategoryPanel tags={tags} category={category} appRoute={appRoute} pricing={pricing} />
      </div>
      <div className={css.imageContainer}>
        <img src={logo} alt={title} />
      </div>
      <div >
        {OpenAIAppDescriptionPanel({ appRoute })}
      </div>
      <div className={css.featureBlock}>
        <h3 className={css.titleHeading}>
          <FormattedMessage id="OpenAiAppsPanel.featureLabel" />
        </h3>
        <div className={css.featurePanel}>
          <OpenAIFeaturesPanel appRoute={appRoute} />
        </div>
      </div>
      <div className={css.permissionsBlock}>
        <div className={css.featureBlock}>
        <h3 className={css.titleHeading}>
            <FormattedMessage id="OpenAiAppsPanel.permissionsLabel" />
          </h3>
          <p>
            <FormattedMessage
              id="OpenAiAppsPanel.permissionsMessage1"
              values={{
                link: (
                  <a href="https://openai.com/policies/api-data-usage-policies" target="_blank">
                    here.
                  </a>
                ),
              }}
            />
          </p>
          <p>
            <FormattedMessage id="OpenAiAppsPanel.permissionsMessage2" />
          </p>
          <p>
            <FormattedMessage id="OpenAiAppsPanel.permissionsMessage3" />
          </p>
        </div>
        <div className={css.featureBlock}>
        <h3 className={css.titleHeading}>
            <FormattedMessage id="OpenAiAppsPanel.securityLabel" />
          </h3>
          <p>
            <FormattedMessage
              id="OpenAiAppsPanel.securityMessage1"
              values={{
                link: (
                  <a href="https://openai.com/security" target="_blank">
                    {' '}
                    here.
                  </a>
                ),
              }}
            />
          </p>
          <p>
            <FormattedMessage
              id="OpenAiAppsPanel.securityMessage2"
              values={{
                link: (
                  <a href="https://app.insightgig.com/terms-of-use" target="_blank">
                    here.
                  </a>
                ),
              }}
            />
          </p>
        </div>
        <div className={css.summaryBlock}>
        <h3 className={css.titleHeading}>
            <FormattedMessage id="OpenAiAppsPanel.informationLabel" />
          </h3>
          <div className={css.informationBlock}>
            <div>
              <label>
                <FormattedMessage id="OpenAiAppsPanel.companyLabel" />
              </label>
              <p>
                <FormattedMessage id="OpenAiAppsPanel.insightgigText" />
              </p>
            </div>
            <div>
              <label>
                <FormattedMessage id="OpenAiAppsPanel.websiteLabel" />
              </label>
              <ExternalLink href={'https://www.insightgig.com/'} className={css.link}>
                https://www.insightgig.com
              </ExternalLink>
            </div>
          </div>
          <div className={css.informationBlock}>
            <div>
              <label>
                <FormattedMessage id="OpenAiAppsPanel.emailLabel" />
              </label>
              <ExternalLink href={'mailto:admin@insightgig.com'}>
                admin@insightgig.com
              </ExternalLink>
            </div>
            <div>
              <label>
                <FormattedMessage id="OpenAiAppsPanel.developerLabel" />
              </label>
              <p>
                <FormattedMessage id="OpenAiAppsPanel.insightgigText" />
              </p>
            </div>
          </div>
          <div className={css.informationBlock}>
            <div>
            <label>
                <FormattedMessage id="OpenAiAppsPanel.locationLabel" />
              </label>
              <p>
                <FormattedMessage id="OpenAiAppsPanel.locationText" />
              </p>
            </div>
          </div>
          <ExternalLink href={'https://app.insightgig.com/privacy-policy'} className={css.link}>
            <FormattedMessage id="OpenAiAppsPanel.privacyPolicyLabel" />
          </ExternalLink>
        </div>
      </div>
    </div>
  )
}

export default OverviewSection