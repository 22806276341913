import React, { useState } from 'react';
import { array, string, func } from 'prop-types';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { FULL_TIME_EMPLOYMENT, JOB, PROJECT_BASED, SECONDMENT, SECONDMENT_KEY, propTypes } from '../../util/types';
import { formatMoney } from '../../util/currency';
import { ensureListing } from '../../util/data';
import { richText } from '../../util/richText';
import { findOptionsForSelectFilter } from '../../util/search';
import { createSlug } from '../../util/urlHelpers';
import config from '../../config';
import { types as sdkTypes } from '../../util/sdkLoader';
import { ListingLink, NamedLink } from '../../components';

const { Money } = sdkTypes;

import css from './ListingCardClient.module.css';

const MIN_LENGTH_FOR_LONG_WORDS = 10;

const getCertificateInfo = (certificateOptions, key) => {
  return certificateOptions.find(c => c.key === key);
};

export const ListingCardComponentClient = props => {
  const {
    className,
    rootClassName,
    intl,
    listing,
    filtersConfig,
    countWords,
    setActiveListing,
    top3Listings,
    
  } = props;
  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);
  const id = currentListing.id.uuid;
  const { title = '', description = '', publicData } = currentListing.attributes;

  const slug = createSlug(title);

  const typeListing = currentListing.attributes.publicData.listingType
  const isJobListing = typeListing === JOB;
  const domainExpertise = findOptionsForSelectFilter('domainExpertise', filtersConfig);
  const industrySector = findOptionsForSelectFilter('industry', filtersConfig);
  const selectCountry = findOptionsForSelectFilter('country', filtersConfig);

  const { minPrice, maxPrice, currencyFee, ctc = {}, employmentLength, experience, contractType, proposalReceivedCount = 0 } = publicData;
  const domainExpertiseOptions = publicData.domainExpertise
  const industrySectorOptions = publicData.industrySector
  const timeline = publicData.projectTimelines;
  const employementType = isJobListing ? contractType === SECONDMENT_KEY ? SECONDMENT : FULL_TIME_EMPLOYMENT : PROJECT_BASED;

  const minBudget = isJobListing ? Number(ctc.minPrice.replace(/[^a-z0-9-]/g, '')) : minPrice;
  const maxBudget = isJobListing ? Number(ctc.maxPrice.replace(/[^a-z0-9-]/g, '')) : maxPrice;
  const currency = isJobListing ? ctc.currency : currencyFee;
  const employmentLengthText = employmentLength === 'Not applicable' ? 'N/A' : `${employmentLength} months`;
  const experienceText = experience ? `${experience} years` : 'N/A';

  const priceData = (price, currencyFee) => {
    price = new Money(price * 100, currencyFee)
    const formattedPrice = formatMoney(intl, price).split('.')[0];
    return { formattedPrice, priceTitle: formattedPrice };
  };

  const Description = ({ text }) => {
    const [showMore, SetShowMore] = useState(true)
    const showMoreText = e => {
      e.preventDefault()
      SetShowMore(false)
    }
    let sliced = !!text && text?.slice(0, countWords);
    if (sliced?.length < text?.length && showMore) {
      return (
        <div className={css.textListingCard} onClick={showMoreText}>
          <span>{sliced}... </span>
          <span className={css.showMoreDescription}>more</span>
        </div>
      )
    } else return <span className={css.textListingCard}>{text}</span>
  }

  const CheckBoxOption = ({ options, classNameElement, classNameTitle }) => {
    if (!options || !options.length) return null;
    return (
      <ul className={classNames(css.checkBoxBlock, isJobListing && css.industryTag)}>
        {options?.length && options.map(el => {
          const temp = domainExpertise && domainExpertise.find(e => e && e.key === el) ||
            industrySector && industrySector.find(e => e && e.key === el);

          const label = temp ? temp.label : el.label ?? el
          return (
            <li className={classNameElement} key={el}>
              <label className={classNameTitle}>{label}</label>
            </li>
          )
        }
        )}
      </ul>
    )
  }

  // const CountryBoxOption = ({ country }) => {
  //   if (!country) return null
  //   return (
  //     <div>
  //       <span className={css.priceTitle}><FormattedMessage id="ListingPage.acceptingCountry" /></span>
  //       {selectCountry && selectCountry.filter(p => country.includes(p.key)).map((el, i) => {
  //         if (!i) return <span className={css.textListingCard} key={el.key}>{el && el.label}</span>
  //         else return <span className={css.textListingCard} key={el.key}> and {el && el.label}</span>
  //       })}
  //     </div>
  //   )
  // }

  const PriceBoxOption = ({ minPrice, maxPrice, currencyFee }) => {
    if (!maxPrice) return null
    const min = priceData(minPrice, currencyFee);
    const max = priceData(maxPrice, currencyFee);
    return (
      <div className={css.priceBox}>
        <span className={css.priceTitle}><FormattedMessage id="ListingPage.titleBudget" /></span>
        <div className={css.priceTitleCount} title={min.priceTitle}>
          {min.formattedPrice && min.formattedPrice}
        </div>
        <span className={css.priceMargin}> - </span>
        <div className={css.priceTitleCount} title={max.priceTitle}>
          {max.formattedPrice && max.formattedPrice.substr(1)}
        </div>
      </div>
    )
  }

  return (
    <NamedLink className={classes} name="ListingPage" params={{ id, slug }}>
      <div className={css.info}>
        <div className={css.mainInfo}>
          <div className={css.title}>
            {richText(title, {
              longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
              longWordClass: css.longWord,
            })}
            {top3Listings?.some(listing => listing?.id?.uuid === id) &&
              <div className={css.trendingBadge}>
                <FormattedMessage id="ListingCardClient.trendingLabel" />
              </div>
            }
          </div>
          <Description text={description} />

          <div className={css.categoryBoxCard}>
            <CheckBoxOption
              options={domainExpertiseOptions}
              classNameElement={classNames(css.styleElement, css.borderStyleDomainExpertise)}
              classNameTitle={classNames(css.styleTitleCategory, css.colorStyleDomainExpertise)}
            />
            <CheckBoxOption
              options={industrySectorOptions}
              classNameElement={classNames(css.styleElement, css.borderStyleIndustrySector)}
              classNameTitle={classNames(css.styleTitleCategory, css.colorStyleIndustrySector)}
            />
          </div>

          <div className={isJobListing ? css.jobListingFooterBox : css.footerBoxCard}>
            <PriceBoxOption
              minPrice={minBudget}
              maxPrice={maxBudget}
              currencyFee={currency}
            />
            {/* <CountryBoxOption country={publicData.country}/> */}
            <div className={css.priceBox}>
              <span className={css.priceTitle}><FormattedMessage id='ListingCardClient.employementType' /></span>
              <span className={css.textListingCard}>{employementType}</span>
            </div>
            {isJobListing ? (
              <>
                <div className={css.priceBox}>
                  <span className={css.priceTitle}><FormattedMessage id='ListingCardClient.employementLength' /></span>
                  <span className={css.textListingCard}>{employmentLengthText}</span>
                </div>
                <div className={css.priceBox}>
                  <span className={css.priceTitle}><FormattedMessage id='ListingCardClient.experienceLabel' /></span>
                  <span className={css.textListingCard}>{experienceText}</span>
                </div>
              </>
            ) : (
              <div className={css.priceBox}>
                <span className={css.priceTitle}><FormattedMessage id='ListingCardClient.Timeline' /></span>
                <span className={css.textListingCard}>{timeline}</span>
              </div>
            )}
            <div className={css.priceBox}>
              <span className={css.priceTitle}><FormattedMessage id='ListingCardClient.proposalReceivedText' /></span>
              <span className={css.textListingCard}>{proposalReceivedCount}</span>
            </div>
          </div>

        </div>
      </div>
    </NamedLink>
  );
};

ListingCardComponentClient.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
  filtersConfig: config.custom.filters,
  setActiveListing: () => null,
};

ListingCardComponentClient.propTypes = {
  className: string,
  rootClassName: string,
  filtersConfig: array,
  intl: intlShape.isRequired,
  // listing: propTypes.listing.isRequired,

  // Responsive image sizes hint
  renderSizes: string,

  setActiveListing: func,
};

export default injectIntl(ListingCardComponentClient);
