import React, { useState } from 'react'
import { Field, Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FieldCheckboxGroup, FieldSelect, Form, IconCard, IconSpinner, Modal, PrimaryButton, SecondaryButton } from '../../components';
import css from './OpenAIResearchInstrumentTranslatorForm.module.css'
import { FormattedMessage, injectIntl } from 'react-intl';
import FileView from '../../components/FileView/FileView';
import { AI_OPEN_ENDED_RESPONSE_TRANSLATOR, AI_RESEARCH_INSTRUMENT_TRANSLATOR, AI_TRANSLATE_TRANSCRIPT } from '../../util/types';
import { composeValidators, required } from '../../util/validators';
import { intlShape } from '../../util/reactIntl';
import { func } from 'prop-types';
import { compose } from 'redux';
import useHandleFileExtensionError from '../../hooks/useHandleFileExtensionError';
import classNames from 'classnames';
import FieldValidationErrorMessage from '../../components/FieldValidationErrorMessage/FieldValidationErrorMessage';

const ACCEPT_DOC_PDF_FILE = ".doc, .docx, .pdf";
const ACCEPT_XLSX_FILE = ".csv, .xlsx, .xls";
const FILE_SIZE_LIMIT = 25 * 1000 * 1000;
const FILES_ERROR_MESSAGE = 'Please choose a file first'
const LANGUAGE_ERROR_MESSAGE = 'Select output language'

const languagesArray = [
  {key:"English",label:"English"},
  {key:"Spanish",label:"Spanish"},
  {key:"French",label:"French"},
  {key:"German",label:"German"},
  {key:"Italian",label:"Italian"},
  {key:"Portuguese",label:"Portuguese"},
  {key:"Dutch",label:"Dutch"},
  {key:"Russian",label:"Russian"},
  {key:"Mandarin",label:"Mandarin"},
  {key:"Japanese",label:"Japanese"},
  {key:"Korean",label:"Korean"},
  {key:"Arabic",label:"Arabic"},
  {key:"Hindi",label:"Hindi"},
  {key:"Bengali",label:"Bengali"},
  {key:"Tamil",label:"Tamil"},
]

const OpenAIResearchTranslatorForm = (props) => {
  return (
    <FinalForm
      {...props}
      mutators={{ ...arrayMutators }}
      render={formRenderProps => {
        const {
          disabled,
          handleSubmit,
          intl,
          pristine,
          updated,
          formId,
          updateError,
          values,
          form,
          openaiRequest,
          handleRedirectToGigAppsPage,
          manageDisableScrolling,
          appRoute,
          isResponseTranslatorApp,
          isTranslateTranscriptApp,
        } = formRenderProps;

        const {files = []} = values || {};
        const [error, setError] = useState(null)
        const [fileExtensionError, checkForFileExtension] = useHandleFileExtensionError();
        const submitDisabled = files?.length === 0 || !values?.language || values?.language?.length===0
        const isResearchInstrumentTranslatorApp = !isResponseTranslatorApp && !isTranslateTranscriptApp
        const handleFilesError = () => {
          setError(true)
          setTimeout(() => {
            setError(false);
          }, 3000);
        };

        const MAX_FILE_COUNT = isTranslateTranscriptApp ? 50 : 5;

        function getAppTextLabel(){
          switch(appRoute){
            case AI_RESEARCH_INSTRUMENT_TRANSLATOR: 
              return {title: "OpenAIResearchTranslatorForm.heading", attachmentLabel: "OpenAIResearchTranslatorForm.attachmentLabel"}
            case AI_OPEN_ENDED_RESPONSE_TRANSLATOR: 
              return {title: "OpenAIResponseTranslatorForm.heading", attachmentLabel: "OpenAIResponseTranslatorForm.attachmentLabel"}
            case AI_TRANSLATE_TRANSCRIPT: 
              return {title: "OpenAITranslateTranscriptForm.heading", attachmentLabel: "OpenAITranslateTranscriptForm.attachmentLabel"}
            default: 
              return {title: '', attachmentLabel: ''}
          }
        }

        async function handleFileUpload(e){
          const filesCount = e.target.files.length;
          let filesObject = Object.values(e.target.files);

          const allowedExtensions = isResponseTranslatorApp ? /(\.csv|\.xlsx?)$/i :  /(\.pdf|\.doc|\.docx)$/i ;
          let fileExtensionErrorMessage = `Please select files in ${isResponseTranslatorApp ? 'XLSX, or XLS' : 'PDF, DOC, or DOCX'} format only.`
          let extensionError = checkForFileExtension(allowedExtensions, filesObject, fileExtensionErrorMessage)
          filesObject = filesObject.filter(file => allowedExtensions.test(file.name))
          if (filesCount > MAX_FILE_COUNT) {
            handleFilesError(`You can select maximum of ${MAX_FILE_COUNT} documents.`)
            return
          } else {
            if(extensionError) return
            for (let i = 0; i < filesCount; i++) {
              const file = filesObject[i]
              if (file && file.name && file.size > FILE_SIZE_LIMIT) {
                setError(`You can select files with maximum size of ${FILE_SIZE_LIMIT / 1000000}MB.`)
                return
              }
            }
              
            form.change('files', [...files,...filesObject])
            form.change('gigAppInputFiles', [...files,...filesObject])
          }
        }

        const languageRequiredMessage = intl.formatMessage({
          id: 'OpenAIResearchTranslatorForm.languageRequiredMessage',
        });

        return (
          <>
            <Form className={css.researchTranslatorForm} onSubmit={handleSubmit}>
              <div className={css.container}>
                <div className={css.generator}>
                  <span className={css.generatorTitle}>
                    <FormattedMessage id={getAppTextLabel().title} />
                  </span>
                </div>
                <div className={css.attachment}>
                  <label> 
                    <FormattedMessage id={getAppTextLabel().attachmentLabel} />
                  </label>
                  {(files && files.length < MAX_FILE_COUNT) && (
                    <>
                      <Field
                        id="file"
                        name="file"
                        accept={isResponseTranslatorApp ? ACCEPT_XLSX_FILE : ACCEPT_DOC_PDF_FILE}
                        type="file"
                      >
                        {fieldprops => {
                          const { accept, input, disabled: fieldDisabled } = fieldprops;
                          const { name, type } = input;

                          const inputProps = { accept, id: name, name, onChange: handleFileUpload, type };
                          return (
                            <>
                              {fieldDisabled ? null : (
                                <input {...inputProps} className={css.addImageInput} multiple={!isResponseTranslatorApp} />
                              )}
                              <label type="button" htmlFor={name} className={css.attachmentLabel}>
                                <IconCard brand="upload" />
                                <span className={css.dragDrop}>
                                  <FormattedMessage id="GigAppsPage.multipleFilesUpload" />
                                </span>
                                <span className={css.docType}>
                                  <FormattedMessage id={isResponseTranslatorApp ? "OpenAITextPreProcessingForm.supportedFilesType" : "OpenAIResearchInstrumentTranslatorForm.acceptDocFile" } />
                                </span>
                              </label>
                            </>
                          );
                        }}
                      </Field>
                    </>
                  )}
                  {(error && (!files || files.length==0)) ? (
                    <div className={css.filesErrorContainer}>
                      <FieldValidationErrorMessage  message={FILES_ERROR_MESSAGE}/>
                    </div>
                  ) : (
                    ''
                  )}
                  {fileExtensionError ? (
                    <p className={css.fileSizeError}>
                      {fileExtensionError}
                    </p>
                  ) : (
                    ''
                  )}
                  {(files && files.length > 0) && (
                    <div className={css.filesErrorContainer}>
                      {files?.map((item, index) => (
                        <FileView key={index} file={item}>
                          <span
                            className={css.close}
                            onClick={() => {
                              const modifiedFiles = [...files]
                              modifiedFiles.splice(index, 1)
                              form.change('files', modifiedFiles)
                              form.change('gigAppInputFiles', modifiedFiles)
                            }}
                            >
                            <IconCard brand="cross" />
                          </span>
                        </FileView>
                      ))}
                    </div>
                  )}
                  <label>
                    <FormattedMessage id={`OpenAIResearchTranslatorForm.outputLanguages`} />
                  </label>
                  <div className={css.checkBoxGroup}>
                    {(isResponseTranslatorApp || isTranslateTranscriptApp) ? (
                      <FieldSelect id="language"  name="language" validate={composeValidators(required(languageRequiredMessage))} vibrate={true}>
                        <option disabled></option>
                        {languagesArray.map(language => (
                          <option key={language.key} value={language.key}>{language.label}</option>
                        ))}
                      </FieldSelect>
                    ) : (
                      <FieldCheckboxGroup id={`OpenAIResearchTranslatorForm.selectLanguages`} name='language' options={languagesArray}/>
                    )}
                  </div>
                  {(isResearchInstrumentTranslatorApp && error && !values?.language || values?.language?.length===0) && (
                    <div className={css.filesErrorContainer}>
                      <FieldValidationErrorMessage  message={LANGUAGE_ERROR_MESSAGE} vibrate={isResponseTranslatorApp && true}/>
                    </div>
                  )}
                </div>
                <div className={css.actionButtons}>
                  <SecondaryButton
                    type="button"
                    className={css.resetButton}
                    onClick={() => {
                      form.reset()
                    }}
                    disabled={openaiRequest}
                  >
                    <FormattedMessage id="OpenAIResearchInstrumentForm.resetButtonText" />
                  </SecondaryButton>
                  <PrimaryButton
                    type="submit"
                    className={classNames(submitDisabled ? css.disabledSubmitButton : css.submitButton )}
                    onClick={(e)=>{
                      if(submitDisabled){
                        if(!isResponseTranslatorApp && !isTranslateTranscriptApp){
                          e.preventDefault()
                        }
                        handleFilesError()
                      }
                    }}
                    inProgress={openaiRequest}
                  >
                    <FormattedMessage
                      id={'OpenAIResearchInstrumentForm.submitButtonText'}
                    />
                  </PrimaryButton>
                </div>
              </div>
            </Form>
            <Modal 
              id={`OpenAIResearchTranslatorForm.modal`} 
              isOpen={values?.success} 
              onClose={()=> {
                form.change('success',false)
                handleRedirectToGigAppsPage()
              }} 
              onManageDisableScrolling={manageDisableScrolling}
            >
              <div className={css.alertModal}>
                <h2><FormattedMessage id="OpenAiAppsPanel.successModalHeading" /></h2>
                <p><FormattedMessage id="OpenAiAppsPage.expansionContent"/></p>
                <PrimaryButton
                  type='button'
                  className={css.closeButton}
                  onClick={() => {
                    form.change('success',false)
                    handleRedirectToGigAppsPage()
                  }}
                >
                  <FormattedMessage id='OpenAIContentAnalysisForm.closeButtonLabel' />
                </PrimaryButton>
              </div>
            </Modal>
          </>

        )
      }}
    />
  )
}

OpenAIResearchTranslatorForm.propTypes = {
  intl: intlShape.isRequired,
  //   updateInProgress: bool.isRequired,
};

export default compose(injectIntl)(OpenAIResearchTranslatorForm)