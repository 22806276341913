import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { fetchCurrentUser } from '../../ducks/user.duck';
import {
  fetchFirmMember,
  updateFirmAuthorProfile,
  updatedFirmMemberProfile,
  updateListingMetadata,
  updateTransactionMetadata,
  updateUserProfile,
  getUserData,
  updateListingPublicData,
  closeUserListing,
  updateSubscriptionWithStripe,
  deleteAffiliatedFirm,
  deleteFirm,
  sendEmailUsingZepto,
} from '../../util/api';
import { storableError } from '../../util/errors';
import { types as sdkTypes } from '../../util/sdkLoader';
import { NOT_FOUND } from '../../util/types';
import { clearFirmData} from '../BecomeInsightGigPartnerPage/BecomeInsightGigPartnerPage.duck';
const { UUID } = sdkTypes;

//Action types

export const SHOW_LISTING_REQUEST = 'app/FirmPage/SHOW_LISTING_REQUEST';
export const SHOW_LISTING_SUCCESS = 'app/FirmPage/SHOW_LISTING_SUCCESS';
export const SHOW_LISTING_ERROR = 'app/FirmPage/SHOW_LISTING_ERROR';

export const SEND_FIRM_INVITE_REQUEST = 'app/FirmPage/SEND_FIRM_INVITE_REQUEST';
export const SEND_FIRM_INVITE_SUCCESS = 'app/FirmPage/SEND_FIRM_INVITE_SUCCESS';
export const SEND_FIRM_INVITE_ERROR = 'app/FirmPage/SEND_FIRM_INVITE_ERROR';
export const SEND_FIRM_INVITE_CLEAR_SUCCESS_TOASTER =
  'app/FirmPage/SEND_FIRM_INVITE_CLEAR_SUCCESS_TOASTER';

export const OPEN_LISTING_REQUEST = 'app/FirmPage/OPEN_LISTING_REQUEST';
export const OPEN_LISTING_SUCCESS = 'app/FirmPage/OPEN_LISTING_SUCCESS';
export const OPEN_LISTING_ERROR = 'app/FirmPage/OPEN_LISTING_ERROR';
export const CLOSE_LISTING_REQUEST = 'app/FirmPage/CLOSE_LISTING_REQUEST';
export const CLOSE_LISTING_SUCCESS = 'app/FirmPage/CLOSE_LISTING_SUCCESS';
export const CLOSE_LISTING_ERROR = 'app/FirmPage/CLOSE_LISTING_ERROR';

export const ADD_NEW_AUTHOR_PENDING = 'app/FirmPage/ADD_NEW_AUTHOR_REQUEST';
export const ADD_NEW_AUTHOR_SUCCESS = 'app/FirmPage/ADD_NEW_AUTHOR_SUCCESS';

//InitialState
const initialState = {
  id: null,
  showListingError: null,
  sendFirmInviteInProgress: false,
  sendFirmInviteError: null,
  invitationSent: false,
  showListngInProgress: false,
  openingListingInProgress: null,
  openingListingError: null,
  closingListingInProgress: null,
  closingListingError: null,
  addNewAuthorInProgress: false,
  addNewAuthorSuccess: null,
};

//reducer function
export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SHOW_LISTING_REQUEST:
      return { ...state, showListngInProgress: true, showListingError: null };
    case SHOW_LISTING_SUCCESS:
      return { ...state, id: payload.id, showListngInProgress: false, showListingError: null };
    case SHOW_LISTING_ERROR:
      return { ...state, showListngInProgress: false, showListingError: payload };
    case SEND_FIRM_INVITE_REQUEST:
      return { ...state, sendFirmInviteInProgress: true, sendFirmInviteError: null };
    case SEND_FIRM_INVITE_SUCCESS:
      return {
        ...state,
        sendFirmInviteInProgress: false,
        invitationSent: true,
        sendFirmInviteError: null,
      };
    case SEND_FIRM_INVITE_ERROR:
      return { ...state, sendFirmInviteInProgress: false, sendFirmInviteError: payload };
    case SEND_FIRM_INVITE_CLEAR_SUCCESS_TOASTER:
      return {
        ...state,
        sendFirmInviteInProgress: false,
        invitationSent: false,
        sendFirmInviteError: null,
      };

    case OPEN_LISTING_REQUEST:
      return { ...state, openingListingInProgress: true, openingListingError: null };
    case OPEN_LISTING_SUCCESS:
      return { ...state, id: payload.id, openingListingInProgress: false, openingListingError: null };
    case OPEN_LISTING_ERROR:
      return { ...state, openingListingInProgress: false, openingListingError: payload };

    case CLOSE_LISTING_REQUEST:
      return { ...state, closingListingInProgress: true, closingListingError: null };
    case CLOSE_LISTING_SUCCESS:
      return { ...state, id: payload.id, closingListingInProgress: false, closingListingError: null };
    case CLOSE_LISTING_ERROR:
      return { ...state, closingListingInProgress: false, closingListingError: payload };
    case ADD_NEW_AUTHOR_PENDING:
      return { ...state, addNewAuthorInProgress: true, addNewAuthorSuccess: false };
    case ADD_NEW_AUTHOR_SUCCESS:
      return { ...state, addNewAuthorInProgress: false, addNewAuthorSuccess: true };

    default:
      return state;
  }
}

//Selectors

export const checkScrollingDisabled = state => isScrollingDisabled(state);

// ================ Action creators ================ //

export const showListingRequest = () => ({
  type: SHOW_LISTING_REQUEST,
});
export const showListingSuccess = id => ({
  type: SHOW_LISTING_SUCCESS,
  payload: { id },
});

export const showListingError = e => ({
  type: SHOW_LISTING_ERROR,
  error: true,
  payload: e,
});

export const sendFirmInviteRequest = () => ({
  type: SEND_FIRM_INVITE_REQUEST,
});
export const sendFirmInviteSuccess = () => ({
  type: SEND_FIRM_INVITE_SUCCESS,
});
export const sendFirmInviteClearSuccessToaster = () => ({
  type: SEND_FIRM_INVITE_CLEAR_SUCCESS_TOASTER,
});
export const sendFirmInviteError = e => ({
  type: SEND_FIRM_INVITE_ERROR,
  error: true,
  payload: e,
});

export const openListingRequest = () => ({
  type: OPEN_LISTING_REQUEST,
});

export const openListingSuccess = response => ({
  type: OPEN_LISTING_SUCCESS,
  payload: response.data,
});

export const openListingError = e => ({
  type: OPEN_LISTING_ERROR,
  error: true,
  payload: e,
});

export const closeListingRequest = () => ({
  type: CLOSE_LISTING_REQUEST,
});

export const closeListingSuccess = response => ({
  type: CLOSE_LISTING_SUCCESS,
  payload: response.data,
});

export const closeListingError = e => ({
  type: CLOSE_LISTING_ERROR,
  error: true,
  payload: e,
});

export const addNewAuthorPending = () => ({
  type: ADD_NEW_AUTHOR_PENDING,
})

export const addNewAuthorSuccess = () => ({
  type: ADD_NEW_AUTHOR_SUCCESS,
})
// ================ Thunk ================ //

export const showListing = (listingId, isOwn = false) => async (dispatch, getState, sdk) => {
  dispatch(showListingRequest());
  try {
    if (listingId) {
      dispatch(showListingSuccess(listingId));
    }
    dispatch(fetchCurrentUser());
    const params = {
      id: listingId,
      include: ['author', 'currentUser', 'author.publicData', 'author.profileImage', 'author.protectedData', 'images'],
      'fields.image': [
        // Listing page
        'variants.landscape-crop',
        'variants.landscape-crop2x',
        'variants.landscape-crop4x',
        'variants.landscape-crop6x',

        // Social media
        'variants.facebook',
        'variants.twitter',

        // Image carousel
        'variants.scaled-small',
        'variants.scaled-medium',
        'variants.scaled-large',
        'variants.scaled-xlarge',

        // Avatars
        'variants.square-small',
        'variants.square-small2x',
      ],
    };

    const response = isOwn ? await sdk.ownListings.show(params) : await sdk.listings.show(params);

    dispatch(addMarketplaceEntities(response));
    return response;
  } catch (e) {
    dispatch(showListingError(storableError(e)));
  }
};

export const sendFirmInvite = (params, firmData, id) => async (dispatch, getState, sdk) => {
  dispatch(sendFirmInviteRequest());
  try {
    const sendInvite = await sendEmailUsingZepto(params)
    const showUserDetails = await fetchFirmMember({ userEmail: params?.userEmail });
    const filteredArray = firmData.map(u =>
      u.email === showUserDetails.email
        ? { profileImage: showUserDetails.profileImage, fullName: showUserDetails.fullName, ...u }
        : { ...u }
    );
    const updateMetadata = await updateListingMetadata({ firmData: filteredArray, id });
    dispatch(sendFirmInviteSuccess());
    dispatch(showListing(id, true));

  } catch (e) {
    if (e.statusText === NOT_FOUND) {
      const filteredArray = firmData.map(u => u);
      const updateMetadata = await updateListingMetadata({ firmData: filteredArray, id });
    }
    dispatch(sendFirmInviteError(storableError(e)));
  }
};
export const updateMetadata = (firmData, id) => async (dispatch, getState, sdk) => {
  try {
    await dispatch(fetchCurrentUser());
    const currentUser = getState().user?.currentUser;
    const data = {
      id,
      include: ['author', 'author.profileImage', 'images'],
    };
    const updateMetadata = await updateListingMetadata({ firmData, id });
    const currentAuthor = await sdk.listings.show(data);
    const isOwnListing = currentUser?.id.uuid === currentAuthor?.data?.data?.relationships?.author?.data?.id?.uuid;

    if (updateMetadata) {
      isOwnListing ? dispatch(showListing(id, true)) : dispatch(showListing(id));
      return true;
    }
  } catch (error) {
    return;
    // console.log(error,'error')
  }
};
export const updateProfile = params => async (dispatch, getState, sdk) => {
  const res = await sdk.currentUser.updateProfile(params);
  return res;
}

export const fetchFirmMemberDetails = params => async (dispatch, getState, sdk) => {
  try {
    const data = await fetchFirmMember({ userEmail: params });
    // console.log(data,'data');
    return data;
  } catch (error) {
    return;

    // console.log(error,'error')
  }
};
export const updateFirmMemberProfile = (params, emailParams) => async (dispatch, getState, sdk) => {
  try {
    updatedFirmMemberProfile({ params, emailParams });
    
    const response = await sdk.listings.query({ 
      authorId: params.userId,
      pub_listingType: 'partner',
      pub_type: 'partner',
    });
    if(response.status === 200 && response.data.data.length){
      const listingId = response.data.data[0].id.uuid;
      updateListingPublicData({
        id: listingId,
        publicData: {
          firmLogoUrl: null
        }
      })
    }

    const removeMemberEmail = await sendEmailUsingZepto(emailParams)
  } catch (error) {
    throw error
  }
};
export const closeListing = (listingId, ownerNumber) => async (dispatch, getState, sdk) => {
  dispatch(closeListingRequest(listingId));
  const params = {
    publicData: {
      firmId: null,
      firmListing: null
    }
  }
  if (ownerNumber === 1) {
    return sdk.ownListings
      .close({ id: listingId }, { expand: true })
      .then(async response => {
        if (response.status === 200) {
          const firmId = listingId.uuid;
          const res = await dispatch(updateProfile(params));
          await dispatch(fetchCurrentUser());
          dispatch(closeListingSuccess(response));
          dispatch(clearFirmData());
          await deleteAffiliatedFirm({ firmId })
          await deleteFirm({ firmId })
          // dispatch(showListing(listingId, true));
          return res;
        }
      })
      .catch(e => {
        dispatch(closeListingError(storableError(e)));
      });
  } else {
    const response = await closeUserListing({ firmId: listingId })
    if (response.status === 200) {
      const res = await dispatch(updateProfile(params));
      const firmId = listingId.uuid;
      dispatch(closeListingSuccess(response));
      // dispatch(showListing(listingId, true));
      dispatch(clearFirmData());
      await deleteAffiliatedFirm({ firmId })
      await deleteFirm({ firmId })
      return res;
    }
  }
};

// export const openListing = listingId => (dispatch, getState, sdk) => {
//   dispatch(openListingRequest(listingId));

//   return sdk.ownListings
//     .open({ id: listingId }, { expand: true })
//     .then(response => {
//       dispatch(openListingSuccess(response));
//        dispatch(showListing(listingId, true));

//       return response;
//     })
//     .catch(e => {
//       dispatch(openListingError(storableError(e)));
//     });
// };

export const addNewAuthor = async (params) => {
  const { firmData, id, newAuthor, newOwnerNumber } = params
  const updateMetadata = await updateListingMetadata({ firmData, id });

  const publicData = {
    newAuthor,
    ownerType: `${newOwnerNumber} Owner`
  }

  const updatePubcliData = await updateListingPublicData({ id, publicData });

  if (updateMetadata && updatePubcliData) {
    return { status: true }
  }
}

export const transferFirmRole = params => async (dispatch, getState, sdk) => {
  try {
    dispatch(addNewAuthorPending());
    const { firmUser, firmId, firmData, firmTitle, newOwnerNumber, authorName, ensuredCurrentUser } = params;
    const selectedUserId = firmUser.userId;
  
    //Update currentUser with linktofirm data
    const param = {
      publicData: {
        firmId: null,
        firmListing: null,
        linkedToFirms: [{
          firmId: firmId.uuid,
          firmName: firmTitle,
          firmOwner: authorName,
          authorId: selectedUserId,
        }]
      }
    }
    const response = await dispatch(updateProfile(param))

    if (response) {
      //Update selected user profile with firmid and firmListing
      const params = {
        id: new UUID(selectedUserId),
        publicData: {
          linkedToFirms: null,
          firmId: firmId.uuid,
          firmListing: {
            firmTitle: firmTitle,
            firmId: firmId.uuid
          }
        }
      }
      const updatedProfile = await updateUserProfile({ data: params });

      const { data: { data } } = await getUserData({ id: selectedUserId });
      if (updatedProfile && data) {
        const filteredFirmData = firmData.filter(firmUser => firmUser.userId != selectedUserId);
        ensuredCurrentUser && filteredFirmData.push({
          email: ensuredCurrentUser?.attributes?.email,
          fullName: ensuredCurrentUser?.attributes?.profile?.displayName,
          profileImage: ensuredCurrentUser?.profileImage && ensuredCurrentUser?.profileImage?.attributes?.variants?.['square-small2x']?.url,
          status: Active,
          userId: ensuredCurrentUser?.id?.uuid
        })
        const newAuthor = {
          attributes: data.attributes,
          id: data.id.uuid,
          profileImage: firmUser.profileImage
        }
        const response = addNewAuthor({ firmData: filteredFirmData, id: firmId, newAuthor: newAuthor, newOwnerNumber });
        if (response) {
          dispatch(addNewAuthorSuccess());
          return response;
        }
      }
    }
  } catch (e) {
    return;
    // console.log(e)
  }
}

export const updateFirmAuthorData = params => async (dispatch, getState, sdk) => {
  try {
    const response = await updateFirmAuthorProfile(params);
  } catch (error) {
    console.log(error, 'error');
    return;
  }
}

//LoadData
export const loadData = params => async (dispatch, getState, sdk) => {
  await dispatch(fetchCurrentUser());

  try {
    const currentUser = getState().user?.currentUser;
    const listingId = new UUID(params.id);
    const data = {
      id: listingId,
      include: ['author', 'author.profileImage', 'images'],
    };

    const response = await sdk.listings.show(data);
    const isOwnListing = currentUser?.id.uuid === response?.data?.data?.relationships?.author?.data?.id?.uuid;
    return dispatch(showListing(listingId, isOwnListing));
  } catch (e) {
    dispatch(showListingError(e));
    return;
  }
};
