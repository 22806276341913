import config from '../../config';
import routeConfiguration from '../../routeConfiguration';
import { createGigAppBoxFolder, initiatePrivileged } from '../../util/api';
import { storableError } from '../../util/errors';
import { createResourceLocatorString } from '../../util/routes';
import { TRANSITION_INITIATED_ASSISTED_FLOW } from '../../util/transaction';
import { GIG_APPS_TRANSACTION_PAGE } from '../../util/types';

// ================ Action types ================ //

export const INITIATE_ASSISTED_FLOW_REQUEST = 'app/OpenAIAppsPage/INITIATE_ASSISTED_FLOW_REQUEST';
export const INITIATE_ASSISTED_FLOW_SUCCESS = 'app/OpenAIAppsPage/INITIATE_ASSISTED_FLOW_SUCCESS';
export const INITIATE_ASSISTED_FLOW_ERROR = 'app/OpenAIAppsPage/INITIATE_ASSISTED_FLOW_ERROR';

export const CHANGE_PASSWORD_CLEAR = 'app/OpenAIAppsPage/CHANGE_PASSWORD_CLEAR';


// ================ Reducer ================ //

const initialState = {
  assistedFlowError: null,
  assistedFlowInProgress: false,
  passwordChanged: false,
  resetPasswordInProgress: false,
  assistedFlowError: null,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case INITIATE_ASSISTED_FLOW_REQUEST:
      return {
        ...state,
        assistedFlowInProgress: true,
        assistedFlowError: null,
        passwordChanged: false,
      };
    case INITIATE_ASSISTED_FLOW_SUCCESS:
      return { ...state, assistedFlowInProgress: false, };
    case INITIATE_ASSISTED_FLOW_ERROR:
      return { ...state, assistedFlowInProgress: false, assistedFlowError: payload };


    default:
      return state;
  }
}

// ================ Action creators ================ //

export const assistedFlowRequest = () => ({ type: INITIATE_ASSISTED_FLOW_REQUEST });
export const assistedFlowSuccess = () => ({ type: INITIATE_ASSISTED_FLOW_SUCCESS });
export const assistedFlowError = error => ({
  type: INITIATE_ASSISTED_FLOW_ERROR,
  payload: error,
  error: true,
});

// ================ Thunks ================ //

export const initiateAssistedTransaction = params => async (dispatch, getState, sdk) => {
  dispatch(assistedFlowRequest());

  try {
    const { emails, tab, history, ...filteredParams } = params;
    const queryParams = {
      include: ['customer', 'provider'],
      expand: true,
    };
    const bodyParams = {
      transition: TRANSITION_INITIATED_ASSISTED_FLOW,
      processAlias: config.gigAppProcess,
      params: filteredParams,
    };
    const response = await initiatePrivileged({ bodyParams, queryParams });
    const { data } = response?.data || {};
    const transactionId = data?.id?.uuid;
    if (transactionId) {
      const response = await createGigAppBoxFolder({ txId: transactionId, emails });
      dispatch(assistedFlowSuccess());
      history.push(
        createResourceLocatorString(
          GIG_APPS_TRANSACTION_PAGE,
          routeConfiguration(),
          { id: transactionId, tab },
          {}
        )
      );
      return transactionId;
    } else {
      // Handle the case where the response doesn't contain the expected data
      throw new Error("Invalid response structure");
    }
  } catch (error) {
    // Log the detailed error for debugging purposes

    dispatch(assistedFlowError(storableError(error)));
    // You might want to rethrow the error here if you want it to be handled by the calling code
  }
};
