import React from 'react';
import { string } from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import { twitterPageURL } from '../../util/urlHelpers';
import config from '../../config';
import {
  IconSocialMediaFacebook,
  IconSocialMediaInstagram,
  IconSocialMediaTwitter,
  Logo,
  ExternalLink,
  NamedLink, IconSocialMediaLinkedIn, IconSocialMediaYouTube,
} from '../../components';

import css from './Footer.module.css';
import { DOCUMENTATION_PAGE, SUPPORT_PAGE } from '../../util/types';

const renderSocialMediaLinks = intl => {
  const { siteFacebookPage, siteInstagramPage, siteTwitterHandle, siteLinkedInPage, siteYouTubePage } = config;
  const siteTwitterPage = twitterPageURL(siteTwitterHandle);

  const goToFb = intl.formatMessage({ id: 'Footer.goToFacebook' });
  const goToInsta = intl.formatMessage({ id: 'Footer.goToInstagram' });
  const goToTwitter = intl.formatMessage({ id: 'Footer.goToTwitter' });
  const goToLinkedin = intl.formatMessage({ id: 'Footer.goToLinkedin' });
  const goToYouTube = intl.formatMessage({ id: 'Footer.goToYouTube' });


  const fbLink = siteFacebookPage ? (
    <ExternalLink
      key="linkToFacebook"
      href={siteFacebookPage}
      className={css.icon}
      title={goToFb}>
      <IconSocialMediaFacebook />
    </ExternalLink>
  ) : null;

  const twitterLink = siteTwitterPage ? (
    <ExternalLink
      key="linkToTwitter"
      href={siteTwitterPage}
      className={css.icon}
      title={goToTwitter}
    >
      <IconSocialMediaTwitter />
    </ExternalLink>
  ) : null;

  const instragramLink = siteInstagramPage ? (
    <ExternalLink
      key="linkToInstagram"
      href={siteInstagramPage}
      className={css.icon}
      title={goToInsta}
    >
      <IconSocialMediaInstagram />
    </ExternalLink>
  ) : null;

  const siteLinkedInLink = siteLinkedInPage ? (
    <ExternalLink
      key="linkToLinkedIn"
      href={siteLinkedInPage}
      className={css.icon}
      title={goToLinkedin}
    >
      <IconSocialMediaLinkedIn />
    </ExternalLink>
  ) : null;

  const siteYouTubeLink = siteYouTubePage ? (
    <ExternalLink
      key="linkToYouTube"
      href={siteYouTubePage}
      className={css.icon}
      title={goToYouTube}
    >
      <IconSocialMediaYouTube />
    </ExternalLink>
  ) : null;

  return [fbLink,  instragramLink,  siteYouTubeLink,siteLinkedInLink].filter(v =>  v != null);
};

const Footer = props => {
  const { rootClassName, className, intl } = props;
  const socialMediaLinks = renderSocialMediaLinks(intl);
  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div className={css.topBorderWrapper}>
        <div className={css.content}>
          <div className={css.someLiksMobile}>
            {socialMediaLinks}
            {/* <div className={css.tosAndPrivacyMobile}>
              <NamedLink name="PrivacyPolicyPage" className={css.privacy}>
                <FormattedMessage id="Footer.privacy" />
              </NamedLink>
              <NamedLink name="TermsOfUsePage" className={css.terms}>
                <FormattedMessage id="Footer.terms" />
              </NamedLink>
            </div> */}
          </div>
          <div className={css.links}>
            <div className={css.organization} id="organization">
              <div className={css.logoLink}>
                <span className={css.logo}>
                  <Logo format="desktop" />
                </span>
              </div>
            </div>
            <div className={css.infoLinks}>
              <ul className={css.list}>
                {/* <li className={css.listItem}>
                  <NamedLink name="NewListingPage" className={css.link}>
                    <FormattedMessage id="Footer.toNewListingPage" />
                  </NamedLink>
                </li> */}
                <li className={css.listItem}>
                  <ExternalLink href='https://insightgig.com/' className={css.link}>
                    <FormattedMessage id="Footer.toAboutPage" />
                  </ExternalLink>
                </li>
                <li className={css.listItem}>
                  <NamedLink name="TermsOfUsePage" className={css.link}>
                    <FormattedMessage id="Footer.termsOfUse" />
                  </NamedLink>
                </li>
                <li className={css.listItem}>
                  <NamedLink name="PrivacyPolicyPage" className={css.link}>
                    <FormattedMessage id="Footer.privacyPolicy" />
                  </NamedLink>
                </li>
                {/* <li className={css.listItem}>
                  <NamedLink name="SupportPage" className={css.link}
                    to={{ state: { from: SUPPORT_PAGE } }} >
                    <FormattedMessage id="Footer.support" />
                  </NamedLink>
                </li> */}
                <li className={css.listItem}>
                  <ExternalLink href="https://docs.insightgig.com/" className={css.link}

                  >
                    <FormattedMessage id="Footer.docs" />
                  </ExternalLink>
                </li>
                <li className={css.listItem}>
                  <NamedLink name="CancellationPolicyPage" className={css.link}>
                    <FormattedMessage id="Footer.cancellationPolicy" />
                  </NamedLink>
                </li>
                {/* <li className={css.listItem}>
                  <NamedLink name="AboutPage" to={{ hash: '#contact' }} className={css.link}>
                    <FormattedMessage id="Footer.toContactPage" />
                  </NamedLink>
                </li> */}
              </ul>
            </div>
            {/* <div className={css.searches}> */}
            {/* <ul className={css.list}>
                <li className={css.listItem}>
                  <NamedLink
                    name="SearchPage"
                    to={{
                      search:
                        '?pub_domainExpertise=has_any%3AendToEndResearch',
                    }}
                    className={css.link}
                  >
                    <FormattedMessage id="Footer.marketResearch" />
                  </NamedLink>
                </li>

                <li className={css.listItem}>
                  <NamedLink
                    name="SearchPage"
                    to={{
                      search:
                        '?pub_domainExpertise=has_any%3AcustomerExperienceManagement',
                    }}
                    className={css.link}
                  >
                    <FormattedMessage id="Footer.cxManagement" />
                  </NamedLink>
                </li>

                <li className={css.listItem}>
                  <NamedLink
                    name="SearchPage"
                    to={{
                      search:
                        '?pub_domainExpertise=has_any%3AdataAnalytics',
                    }}
                    className={css.link}
                  >
                    <FormattedMessage id="Footer.dataAnalytics" />
                  </NamedLink>
                </li>
                <li className={css.listItem}>
                  <NamedLink
                    name="SearchPage"
                    to={{
                      search:
                        '?pub_domainExpertise=has_any%3Amoderation',
                    }}
                    className={css.link}
                  >
                    <FormattedMessage id="Footer.moderation" />
                  </NamedLink>
                </li>
              </ul> */}
            {/* </div> */}
            {/* <div className={css.searchesExtra}> */}
            {/* <ul className={css.list}>
                <li className={css.listItem}>
                  <NamedLink
                    name="SearchPage"
                    to={{
                      search:
                        '?pub_domainExpertise=has_any%3Afieldwork',
                    }}
                    className={css.link}
                  >
                    <FormattedMessage id="Footer.fieldwork" />
                  </NamedLink>
                </li>
                <li className={css.listItem}>
                  <NamedLink
                    name="SearchPage"
                    to={{
                      search:
                        '?pub_domainExpertise=has_any%3ArespondentRecruitment',
                    }}
                    className={css.link}
                  >
                    <FormattedMessage id="Footer.respondentRecruitment" />
                  </NamedLink>
                </li>
                <li className={css.listItem}>
                  <NamedLink
                    name="SearchPage"
                    to={{
                      search:
                        '?pub_domainExpertise=has_any%3AonlinePanels',
                    }}
                    className={css.link}
                  >
                    <FormattedMessage id="Footer.onlinePanels" />
                  </NamedLink>
                </li>
                <li className={css.listItem}>
                  <NamedLink
                    name="SearchPage"
                    to={{
                      search:
                        '?pub_domainExpertise=has_any%3AqualitativeResearch',
                    }}
                    className={css.link}
                  >
                    <FormattedMessage id="Footer.qualitativeResearch" />
                  </NamedLink>
                </li>
              </ul> */}
            {/* </div> */}
            <div className={css.extraLinks}>
              <div className={css.someLinks}>{socialMediaLinks}</div>
              {/* <div className={css.legalMatters}>
                <ul className={css.tosAndPrivacy}>
                  <li>
                    <NamedLink name="TermsOfUsePage" className={css.legalLink}>
                      <FormattedMessage id="Footer.termsOfUse" />
                    </NamedLink>
                  </li>
                  <li>
                    <NamedLink name="PrivacyPolicyPage" className={css.legalLink}>
                      <FormattedMessage id="Footer.privacyPolicy" />
                    </NamedLink>
                  </li>
                </ul>
              </div> */}
            </div>
          </div>
          <div className={css.organizationCopyright}>
            <div className={css.copyrightLink}>
              <FormattedMessage id="Footer.copyright" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Footer.defaultProps = {
  rootClassName: null,
  className: null,
};

Footer.propTypes = {
  rootClassName: string,
  className: string,
  intl: intlShape.isRequired,
};

export default injectIntl(Footer);
