import React, { useEffect } from 'react'
import { FormattedMessage } from '../../util/reactIntl';
import css from './StripeConnectAccountStatusBoxIn.module.css';
import { getInStripeAccountData, getInStripeAccountId } from '../../util/destructorHelpers';
import { ONBOARD_USER_SUCCESS, PENDING_VERIFICATION } from '../../util/types';
import IconEdit from '../IconEdit/IconEdit';
import { InlineTextButton } from '../Button/Button';
import classNames from 'classnames';
import IconSuccess from '../IconSuccess/IconSuccess';
import { stripeCreateAccountLinkIn, stripeRetrieveIndianUser } from '../../util/api';
import ExternalLink from '../ExternalLink/ExternalLink';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import { retrieveStripeInConnectUser } from '../../containers/StripePayoutPage/StripePayoutPage.duck';
import { useDispatch } from 'react-redux';
const StripeConnectAccountStatusBoxIn = (props) => {
    const dispatch = useDispatch()
    const { currentUser, stripeConnected, isStripeInConnected, getStripeCreateAccountLinkIn, history, location } = props;
    const { country, external_accounts, requirements } = getInStripeAccountData(currentUser) ?? {};
    const last4Digits = external_accounts?.data?.at(0)?.last4;
    const isPendingVerification = external_accounts?.data?.at(0)?.requirements?.[PENDING_VERIFICATION]?.length > 0 || requirements?.currently_due?.length > 0;
    const stripeConnectedUserIdIn = currentUser?.id && getInStripeAccountId(currentUser);
    const pathname = location?.pathname;
    const stripeConnectedAccountTermsLink = (
        <ExternalLink href="https://stripe.com/connect-account/legal" className={css.termsLink}>
            <FormattedMessage id="StripeConnectAccountStatusBoxIn.stripeConnectedAccountTermsLink" />
        </ExternalLink>
    );

    useEffect(() => {
        const retrieveUser = async () => {
            await dispatch(retrieveStripeInConnectUser(stripeConnectedUserIdIn));
            if (pathname?.includes(ONBOARD_USER_SUCCESS)) {
                history.push(
                    createResourceLocatorString('StripePayoutPage', routeConfiguration(), {})
                );
            }
        }
        retrieveUser()
    }, [pathname, isPendingVerification,history])
    return (
        <div className={css.stripeConnectAccountStatusBoxIn}>
            <h3>
                <FormattedMessage id='StripeConnectAccountStatusBoxIn.countryHeading' />
            </h3>
            <h3>
                <FormattedMessage id='StripeConnectAccountStatusBoxIn.countryValue' values={{ country }} />
            </h3>
            <h3>
                <FormattedMessage id='StripeConnectAccountStatusBoxIn.bankAccountHeading' />
            </h3>
            <h3>
                <FormattedMessage id='StripeConnectAccountStatusBoxIn.bankAccount' values={{ last4Digits }} />
            </h3>
            <div className={classNames(css.verificiationBox, css.verficiationSuccessBox)}>
                <div
                    className={classNames(
                        css.verificiationBoxTextWrapper,
                        css.verificationBoxSuccessTextWrapper
                    )}
                >
                    <div className={css.verificationBoxTitle}>
                        {isPendingVerification ?
                            <div className={css.verificiationBoxTextWrapper}>
                                <div className={css.verificationBoxTitle}>
                                    <FormattedMessage id="StripeConnectAccountStatusBoxIn.verificationFailedTitle" />
                                </div>
                                <div className={css.verificationBoxText}>
                                    <FormattedMessage id="StripeConnectAccountStatusBoxIn.verificationNeededText" />
                                </div>
                            </div>
                            : <>
                                <IconSuccess className={css.icon} />
                                <FormattedMessage id="StripeConnectAccountStatusBoxIn.verificationSuccessTitle" />
                            </>
                        }
                    </div>
                </div>

                <InlineTextButton
                    className={css.editVerificationButton}
                    spinnerClassName={css.spinner}
                    type="button"
                    inProgress={false}
                    onClick={getStripeCreateAccountLinkIn}
                >
                    <IconEdit className={css.icon} pencilClassName={css.iconEditPencil} />
                    <FormattedMessage id="StripeConnectAccountStatusBoxIn.editAccountButton" />
                </InlineTextButton>

            </div>
            {stripeConnected || isStripeInConnected ?
                <p className={css.termsText}>
                    <FormattedMessage
                        id="StripeConnectAccountStatusBoxIn.stripeToSText"
                        values={{ stripeConnectedAccountTermsLink }}
                    />
                </p> : null}
        </div>
    )
}

export default StripeConnectAccountStatusBoxIn