import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './PrivacyPolicy.module.css';

const PrivacyPolicy = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <p>
        {' '}
        <strong>INSIGHTGIG TECHNOLOGIES PRIVATE LIMITED</strong> (hereinafter referred to as{' '}
        <strong>“Company”, “InsightGig”, “we”, “us”, “our”</strong> and terms of similar meaning)
        owns and operates web-based applications including the website www.insightgig.com and all
        affiliated websites, applications, mobile websites, and mobile application (collectively
        referred to as the <strong>“Platform”</strong>).
      </p>

      <p>
        By accessing the Platform (either as a Client, Insight Partner or a visitor/viewer), you
        (hereinafter referred to as <strong>“you”, “your”, “User”,</strong> as applicable) expressly
        consent to our collection, storage, use and disclosure of the Information (as defined
        hereunder) in accordance with the terms of this Privacy Policy, as amended from time to time{' '}
        <strong>(“Privacy Policy”).</strong>
      </p>
      <p>
        This Privacy Policy forms part and parcel of the User Agreement and Company’s Terms of Use
        (collectively <strong>“Terms”</strong>) and is included therein by this reference. The
        capitalized terms which have been used herein but are not specifically defined shall have
        the same meaning as attributed to such term in the Terms. In the event of any inconsistency
        between the Terms and this Privacy Policy, the Privacy Policy shall prevail solely in
        respect of the content contained herein and not in respect of any other clause, term or
        provision contained in the Terms. In the event you are located in the European Union{' '}
        <strong>(“EU”)</strong> or European Economic Area <strong>(“EEA”)</strong>, you will be
        asked to provide clear and affirmative consent to the collection, processing, and sharing of
        your Personal Information (as defined hereinafter), subject to withdrawal of your consent at
        any time on request. We will not share any User information with any third party without
        your consent, except as outlined in this Privacy Policy or as permitted by law.
      </p>
      <p>
        As we update, improve, and expand the Platform, this Privacy Policy may change at our sole
        discretion without notice, so please refer to the Privacy Policy periodically as you will be
        deemed to be governed by the terms of such modified Privacy Policy, as it comes into effect
        from time to time.
      </p>
      <p>
        This document is an electronic record in terms of Information Technology Act, 2000 and rules
        there under as applicable and the amended provisions pertaining to electronic records in
        various statutes as amended by the Information Technology Act, 2000 (together with any
        statutory amendments or modifications thereof). This electronic record is generated by a
        computer system and does not require any physical or digital signatures.
      </p>
      <p>
        This Privacy Policy is intended to comply with Regulation (EU) 2016/679 of the European
        Parliament and of the Council of 27 April 2016 on the Protection of Natural Persons with
        regard to the Processing of Personal Data and on the Free Movement of such Data (hereinafter
        referred to as the <strong>“General Data Protection Regulation” or “GDPR”</strong>) in so
        far as our Services result in collection and/or processing of Personal Information (as
        defined hereinafter) of Users who are in the EU and EEA to provide appropriate protection
        and care with respect to the treatment of such information in accordance with the GDPR.
      </p>

      <p>
        <strong>TYPES OF INFORMATION COLLECTED</strong>
      </p>
      <p>
        To access/utilise the Platform, the Users are required to provide certain personal,
        non-personal and other information, as indicated below (such information and correspondences
        hereinafter collectively referred to as <strong>“Information”</strong>):
      </p>

      <ol>
        <li>
          <em>Personal Information:</em> In order for you to access/utilize the Platform and/or as
          part of the registration process on the Platform, we may require you to provide us with
          certain information that personally identifies you{' '}
          <strong>(“Personal Information”).</strong> Personal Information shall include but not be
          limited to the following categories of information:
          <ul className={css.listStyleNone}>
            <li>
              (i) name, email address, password, country, city, contact number and
              company/organization that you are associated with, company address, billing address,
              details relating to work experience, skills and expertise and details of professional
              certifications and educational qualifications, when you sign up on the Platform or
              when you post requirements, get proposals or commission Insight Partners;
            </li>
            <li>
              (ii) we may collect your Personal Information such as name, age, contact details,
              preferences, etc. through surveys and forms, when you choose to participate in these
              surveys etc.;
            </li>
            <li>
              (iii) we may collect Personal Information such as a photograph of your face, a selfie,
              or data about your interactions with the Service to verify your identity and to detect
              fraud, identity theft, or other misuse of your account through the use of facial
              recognition and other technologies. We may request documents to verify this
              information, such as a copy of your government-issued identification. The biometric
              identifiers or information collected are used only for identity verification and
              platform security and use integrity purposes.
            </li>
            <li>
              (iv) we may also collect information about whether you are eligible for any
              affirmative action programmes or policies, if you opt to provide such information;
            </li>
            <li>
              (v) to the extent permitted by law, the Company may record and monitor your
              communications with us to ensure compliance with our legal and regulatory obligations
              and our internal policies which may include the recording of telephone conversations;
              and
            </li>
            <li>
              (vi) if you choose to sign in with your social media account to access the Platform,
              or otherwise connect your social media account with the Services of the Platform, you
              consent to our collection, storage, and use, in accordance with this Privacy Policy,
              of the information that you make available to us through the social media interface.
              Please see your social media provider’s privacy policy and help centre for more
              information about how they share information when you choose to connect your social
              media account to our Platform.
            </li>
            <li>
              (vii) InsightGig <a href = "https://www.insightgig.com/">(https://www.insightgig.com)</a> is an online collaboration platform
              connecting customers (Clients) requiring insight related services with individuals and
              boutique firms (Experts) that provide them. Currently, the platform functions as a
              reverse marketplace, where Clients can post enquiries and Experts can bid and take up
              those projects. One of the features of the platform is for Clients and Experts to be able to communicate with each other seamlessly during the course of an insight project. One of the communication features that we would like to provision for users is the ability to schedule online meetings through Google Meet from within the InsightGig platform. Our users have indicated the usefulness of such a feature, so that they do not have to set up such calls from outside the platform. For this purpose, we have set up an integration that allows anyone with a Google Account on InsightGig platform to set up meetings via Google Calendar API from within the InsightGig platform seamlessly. For the above purpose, we are using this scope https://www.googleapis.com/auth/calendar.events. With this scope we are giving users the ability to create a meeting through the platform where they can view and edit events on the Google calendar.
            </li>
          </ul>
          <p>
            Please note that the above list is not an exhaustive list and is only an illustrative
            list and is without prejudice to our right to seek further Personal Information, as may
            be required for the provision of the Services to you and under applicable laws.
          </p>
          <p>
            We will collect Personal Information about you only as part of a voluntary registration
            process, online survey or any combination thereof.
          </p>
          <p>
            Any Personal Information provided by you will not be considered as sensitive if it is
            freely available and/or accessible in the public domain. Further, any comments,
            messages, blogs, etc., posted/ communicated by you on the public or comments sections of
            the Platform becomes published content and is not considered personally identifiable
            information subject to this Privacy Policy. We will not be responsible for the accuracy
            or filtration of any of the content posted on our Platform by any person, however, we
            will be entitled to remove any content posted by any person on our Platform which we, in
            our sole discretion consider offensive, discriminatory, racist, defamatory or
            inappropriate in nature.
          </p>
          <p>
            When browsing our Platform, you are not required to provide any Personal Information
            unless and until you choose to avail a Service or sign up for one of our e-mail
            newsletters or other Services as available on the Platform.
          </p>
        </li>
        <li>
          <em>Non-Personal Information:</em> When you visit the Platform, we may collect certain
          non-personal information such as your internet protocol address, operating system, browser
          type, internet service provider, information about the pages on the Platform you
          visit/access, the links you click on the Platform, the number of times you access the page
          and any such browsing information. This type of information does not identify you
          personally. We may also collect information about the Services that you use and how you
          use them, including log information and location information, when you are a User of the
          Services through the Platform.
        </li>
        <li>
          <em>Automatic Information:</em> To improve the responsiveness of the Platform for you, we
          may use “cookies”, or similar electronic tools to collect information to understand the
          User’s individual interests. These server logs may include information such as your web
          request, internet protocol address, browser type, browser language, the date and time of
          your request and one or more cookies that may uniquely identify your browser. We use
          cookies primarily for User authentication but may also use them to improve the quality of
          our Service by storing User preferences and tracking User trends.
        </li>
        <li>
          <em>Information Collected on the Platform:</em> When you use the Platform (website/
          application) through your mobile, we may receive information about your location and your
          mobile device, including a unique identifier number for your device. We may use this
          information to provide you with suggestions in respect of Services, if required. If you
          choose to post a job requirement/listing (including but not limited to information such as
          name of the organization or brand, scope and objective of the project, budget, location,
          expertise requested, and timing of project performance) via the Service as a Client, the
          contents of such listing will be viewable publicly, unless you designate the listing as
          only viewable through the Service or as private using the settings available on the
          Platform. As part of the Service, when enabled by an Insight Partner, we collect
          information about an Insight Partner’s work on a project for a Client, which information
          may be shared by us with relevant Client. We may also use such information for statistical
          analysis, product development, marketing and research.
        </li>
        <li>
          <em>User communications:</em> When you send emails or other communications to us, we may
          retain those communications in order to process your inquiries, respond to your requests
          and improve our Services to you.
        </li>
        <li>
          <em>Information on behalf of Children:</em> If you have reason to believe that a minor
          child has provided Personal Information to Us through the usage of Our Platform, please
          contact the Grievance Redressal Officer and we will endeavour to delete that information
          and terminate the minor’s account from our databases.
        </li>
      </ol>
      <p>
        In case you choose to decline to submit any Information, we may not be able to provide you
        access to the Platform or to our Services or certain aspects of the Platform or our
        Services. We will not be liable and/or responsible for the denial of providing access to the
        Platform to you on account of inadequacy of Information provided by you.
      </p>
      <p>
        When you register with the Platform, we may contact you from time to time about updating of
        Information, to provide you such features that we believe may benefit/interest you.
      </p>
      <h2 className={css.subTitle}>USE OF INFORMATION</h2>
      <strong>
        We may process the Information you provide in any one or more of the following manners, in
        accordance with this Privacy Policy and as permitted by applicable laws:
      </strong>
      <ol>
        <li>
          To provide our Services to you including providing suitable suggestions with respect to
          the Insight Partners, relevant search results, and other social media communication
          facilities;
        </li>
        <li>
          To process and fulfil your request for Services, to provide personalized features to you
          on the Platform and promotional offers to you through the Platform and other channels;
        </li>
        <li>
          We may disclose your Personal Information to Service Professionals and/or other registered
          business partners (as applicable). We or our Service Professionals/registered business
          partners may present marketing material and customised messages to you, when necessary to
          provide Services requested by you;
        </li>
        <li>To improve our Services and offerings, to contact you as a survey respondent;</li>
        <li>To enforce our Terms;</li>
        <li>
          To protect the security or integrity of the Platform, our business, Services, and other
          users;
        </li>
        <li>To publish any testimonials or reviews that you may have provided on the Platform;</li>
        <li>
          Preventing, detecting, investigating and taking action against crimes (including but not
          limited to fraud and other financial crimes), any other illegal activities, suspected
          fraud, or violations of Terms in any jurisdiction;
        </li>
        <li>
          To the extent required for identity verification, government sanctions screening and due
          diligence checks;
        </li>
        <li>
          To analyse your usage patterns in an aggregated and anonymized manner to understand your
          usage of the Platform and provide personalized recommendations and service
        </li>
        <li>
          Establishing, exercising or defending legal rights in connection with legal proceedings
          (including any prospective legal proceedings) and seeking professional or legal advice in
          relation to such legal proceedings; and
        </li>
        <li>
          To serve various promotional and advertising materials to you via display advertisements
          through the Google Ad network on third party websites. You can opt out of Google Analytics
          for Display Advertising and customize Google Display network ads using the Ads Preferences
          Manager.
        </li>
      </ol>
      <h2 className={css.subTitle}>DISCLOSURE OF INFORMATION OBTAINED</h2>
      <ol>
        <li>
          We will not use your financial information for any purpose other than to complete a
          transaction with you.
        </li>
        <li>
          Except as expressly stated herein, we do not rent, sell or share your Personal Information
          and will not disclose any of your Personal Information to third parties. In cases where it
          has your permission to provide Services (as defined in the Terms) that you have requested
          and such Information is necessary to provide these Services, the Information may be shared
          with our business associates including the Clients/Insight Partners, as applicable,
          business partners and other third party service suppliers. We may, however, share consumer
          information on an aggregate with partners or third parties where it deems necessary. In
          addition, we may use this Information for promotional offers.
        </li>
        <li>
          We may disclose Information, in good faith, wherein the access, use, preservation or
          disclosure of such Information, including preservation of transaction history is
          reasonably necessary to (i) satisfy any applicable law, regulation, legal process or
          enforceable governmental request; (ii) enforce applicable Terms, including investigation
          of potential violations thereof; (iii) detect, prevent, or otherwise address fraud,
          security or technical issues; (iv) protect against imminent harm to our rights, property
          or safety, our users or the public as required or permitted by law; or (v) to defend
          against legal claims; special circumstances such as compliance with subpoenas, court
          orders, requests/order from legal authorities or law enforcement agencies requiring such
          disclosure
        </li>
        <li>
          Additionally, in the event of an audit, reorganization, merger, sale, joint venture,
          assignment, transfer or other disposition of all or any portion of our business, assets or
          stock (including in connection with any bankruptcy or similar proceedings), we may
          share/transfer/assign the Information we have collected to the relevant third party.
        </li>
      </ol>
      <h2 className={css.subTitle}>DATA RETENTION</h2>
      <p>
        We shall be entitled to retain your Information for our records for such period of time as
        may be required by applicable laws, after the deletion of your Account on the Platform
        and/or completion of Services and such Information shall be used by us only in consonance
        with this Privacy Policy, including for the purpose of compliance with legal obligations,
        resolving of disputes and enforcing any agreements executed between us.
      </p>
      <h2 className={css.subTitle}>ACCESSING AND UPDATING YOUR INFORMATION</h2>
      <p>
        As a registered User of the Platform, you can update or modify the Personal Information you
        have included in your profile or change your username by logging in and accessing your
        Account. Though you may delete your Account, you acknowledge that it may be impossible to
        remove your Account without some residual information being retained by the Platform in
        accordance with this Privacy Policy.
      </p>
      <p>
        In the event you are a User in EU or EEA, you will be asked to provide clear and affirmative
        consent to the collection, processing, and sharing of your Personal Information, subject to
        withdrawal of your consent at any time on request. Under GDPR, EU/EEA residents have the
        following rights:
      </p>
      <ul className={css.pointed}>
        <li>
          to obtain a copy of your personal information together with information about how and on
          what basis that personal information is processed;
        </li>
        <li>to know the purpose to process the Personal Information;</li>
        <li>to know the status of Personal Information processed;</li>
        <li>access the Personal Information that is processed;</li>
        <li>to get erased or rectified the Personal Information;</li>
        <li>restrict the processing of the Personal Information;</li>
        <li>data portability of the Personal Information provided in respect of the Services;</li>
        <li>to object to decisions which are based solely on automated processing or profiling;</li>
        <li>
          where you have provided your Personal Information to us with your consent, to ask us for a
          copy of this data in a structured, machine-readable format and to ask us to share (port)
          this data to another data controller;
        </li>
        <li>
          to obtain a copy of or access to safeguards under which your Personal Information is
          transferred outside of the EU/EEA;
        </li>
        <li>
          opt-out option from processing the Personal Information (unless the Company has otherwise
          compelling legitimate grounds).
        </li>
      </ul>
      <p>
        The abovementioned rights can be exercised by EU/EEA residents by contacting the Grievance
        Redressal Officer. For the purposes of the GDPR, our Grievance Redressal Officer is also our
        Data Protection Officer (DPO).
      </p>
      <h2 className={css.subTitle}>SECURITY</h2>
      <p>
        We use reasonable security measures to protect Information from unauthorized access,
        maintain data accuracy and help ensure the appropriate use/disclosure of Information. We
        host the Platform in a secure server environment that uses firewalls and other advanced
        technology to prevent interference or access from outside intruders. Although we will
        endeavour to safeguard the confidentiality of your Personal Information, transmissions made
        by means of the internet cannot be made absolutely secure. By using this Platform, you agree
        that we will have no liability for disclosure of your Information due to errors in
        transmission or unauthorized acts of third parties.
      </p>
      <p>
        You are responsible for maintaining the security and confidentiality of your Account, user
        ids and passwords while accessing the Platform as a User. You acknowledge that neither us
        nor any of our representatives shall be liable to you under any circumstances for any
        direct, indirect, punitive, incidental, special or consequential damages that result from or
        arise out of, or a breach or compromise of registration of your Account on the Platform
        and/or your ability or inability to use such Account.
      </p>
      <p>
        We use third-party payment processors (“Payment Processors”) for any payments made on the
        Platform. The processing of payments will be subject to the terms, conditions and privacy
        policies of the Payment Processors in addition to the Terms and this Privacy Policy. You
        acknowledge that neither us nor any of our representatives shall be liable to you under any
        circumstances for any direct, indirect, punitive, incidental, special or consequential
        damages that result from or arise out of your use of the Payment Processors, your payment
        through the Payment Processors or your ability or inability to complete the payment.
      </p>
      <p>
        Sensitive details like Credit/Debit Card and Net Banking information are primarily collected
        by the Payment Processors and banks and not by Us. However, if this information is stored on
        our Platform, it remains completely unshared and safe.
      </p>
      <h2 className={css.subTitle}>COOKIES</h2>
      <p>
        The Company uses cookies to personalize your experience on the Platform and to display
        advertisements or send you emails of offers of your interest (provided you have not opted
        out of receiving such emails). Cookies are small pieces of information that are stored by
        your browser on your device’s hard drive. Cookies allow you to easily access our Platform,
        by logging you in without having to type your login name each time.
      </p>
      <p>
        By using this Platform, you agree that we can place cookies on your computer or device as
        explained above. However, you can control and manage cookies in various ways. Removing or
        blocking cookies can impact user experience and parts of this Platform may no longer be
        fully accessible.
      </p>
      <p>
        Most browsers will allow you to delete them on an individual basis or block cookies from
        particular or all websites. In the event of you deleting all cookies, you will lose any and
        all preference you have set including your preference to opt-out from cookies.
      </p>
      <h2 className={css.subTitle}>LINKS TO THIRD PARTY SITES</h2>
      <p>
        The Platform may include links to third party applications or websites (“Third Party
        Sites”). Such Third Party Sites are governed by their respective privacy policies, which are
        beyond our control. This Privacy Policy is applicable exclusively to the Platform. Once you
        leave our Platform, use of any information you provide is governed by the privacy policy of
        the operator of the Third Party Site you are visiting and we recommend you to review the
        respective privacy policies of such Third Party Sites with whom you interact. That policy
        may differ from ours. If you cannot find the privacy policy of any of these Third Party
        Sites, you should contact the relevant Third Party Site directly for more information.
      </p>
      <p>
        We do not provide any Personal Information or personally identifiable information to third
        party websites / advertisers / ad-servers without your consent.
      </p>
      <h2 className={css.subTitle}>YOUR CONSENT</h2>
      <p>
        By using the Platform and/ or by providing your Information, you consent to the collection
        and use of such Information in accordance with this Privacy Policy, including but not
        limited to your consent for sharing your Information as per this Privacy Policy. You
        specifically agree and consent to us collecting, storing, processing, transferring and
        sharing Information (including Personal Information) related to you with third parties,
        service professionals or to registered vendors/users for the purposes as set out in this
        Privacy Policy.
      </p>
      <h2 className={css.subTitle}>GRIEVANCE REDRESSAL OFFICER</h2>
      <p>
        Any complaints, abuse or concerns with regard to content and/or comment and/or breach of
        this Privacy Policy shall be immediately informed to the designated Grievance Redressal
        Officer as mentioned below via writing or through email:
      </p>
      <p>
        <em>Details of the Grievance Redressal Officer: </em>
      </p>
      <p>
        Designation: Co-Founder <br />
        Email: vidya@insightgig.com <br />
        Address: Chennai, India <br />
      </p>
    </div>
  );
};

PrivacyPolicy.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

PrivacyPolicy.propTypes = {
  rootClassName: string,
  className: string,
};

export default PrivacyPolicy;
