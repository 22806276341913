import React from 'react';
import { func } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
// These relative imports need to point to correct directories
import {
  intlShape,
  injectIntl,
  FormattedMessage,
} from '../../util/reactIntl';
import { Form, Button, FieldSelect } from '../../components';

// as a template.
import css from './ModalSendCsmTransactionInviteForm.module.css';


export const ModalSendCsmTransactionInviteForm = props => {
  return (
    <FinalForm
      {...props}
      mutators={{
        ...arrayMutators,
      }}
      render={formRenderProps => {
        const {
          disabled,
          handleSubmit,
          intl,
          invalid,
          pristine,
          updated,
          formId,
          updateError,
          updateInProgress,
          firmData,
          values,
          currentUser,
        } = formRenderProps;

        //   inviteFirmMemberLabel 
        const inviteFirmMemberLabel = intl.formatMessage({
          id: "ModalSendCsmTransactionInviteForm.inviteFirmMemberLabel",
        });
        // inviteFirmMemberPlace
        const inviteFirmMemberPlaceholderText = intl.formatMessage({
          id: "ModalSendCsmTransactionInviteForm.inviteFirmMemberPlaceholderText",
        });
       
       



        const inviteFirmMemberButtonText = intl.formatMessage({
          id: "ModalSendCsmTransactionInviteForm.inviteFirmMemberButtonText",
        });

        const submitReady = updated && pristine;
        const submitInProgress = updateInProgress;
        const submitDisabled = invalid || disabled || submitInProgress;

        return (
          <Form className={css.packagesForm} onSubmit={handleSubmit} >
            {/* {errorMessage} */}
            <div className={css.packageBox}>
              <FieldSelect
                  name="firmMemberEmail"
                  id="firmMemberEmail"
                  className={css.inputBox}
                  label={inviteFirmMemberLabel}
                // validate={typeOfFirmRequired}
                >
                  <option disabled value="">
                    {inviteFirmMemberPlaceholderText}
                  </option>
                  {firmData && firmData?.filter(firm=> firm?.userId !== currentUser?.id?.uuid).map(firm => (
                    <option key={firm.userId} value={firm.email} className={css.memberOptions}>
                      {firm.fullName}
                    </option>
                  ))}
                </FieldSelect>

            <Button
              className={css.submitButton}
              type="submit"
              inProgress={submitInProgress}
              disabled={submitDisabled}
              ready={submitReady}
              >
              {inviteFirmMemberButtonText}
            </Button>
              </div>
          </Form>
        );
      }}
    />
  )
}

ModalSendCsmTransactionInviteForm.defaultProps = {
  selectedPlace: null,
  updateError: null,
};

ModalSendCsmTransactionInviteForm.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  //   updateInProgress: bool.isRequired,
};

export default compose(injectIntl)(ModalSendCsmTransactionInviteForm);