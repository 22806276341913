import React from 'react';
import CollaborationPanel from '../../CollaborationPanel/CollaborationPanel';
import css from '../GigAppTransactionPanel.module.css';

const CollaboratorSection = props => {
  const {
    currentTransaction,
    connectedUsers,
    lastTransition,
    isOnline,
    typePartner,
    processName,
  } = props;
  return (
    <div className={css.collaboratorSection}>
      {currentTransaction?.id ? (
        <CollaborationPanel
          currentTransaction={currentTransaction}
          connectedUsers={connectedUsers}
          lastTransition={lastTransition}
          isOnline={isOnline}
          typePartner={typePartner}
          processName={processName}
          isGigAppTransaction={true} 
        />
      ) : null}
    </div>
  );
};

export default CollaboratorSection;
