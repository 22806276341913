import React from 'react'
import { FormattedMessage, injectIntl } from 'react-intl';
import css from './ViewJobOfferModal.module.css'
import { PrimaryButton } from '../Button/Button';
import ExternalLink from '../ExternalLink/ExternalLink';
import { types as sdkTypes } from '../../util/sdkLoader';
import { formatMoney } from '../../util/currency';
import { compose } from 'redux';
import moment from 'moment';
import { handleJobTransition } from '../../containers/TransactionPage/TransactionPage.duck';
import { useDispatch } from 'react-redux';
import { TRANSITION_ACCEPT_JOB_OFFER, TRANSITION_DECLINE_JOB_OFFER } from '../../util/transaction';
import { updateRecruitment } from '../../util/api';
const { Money } = sdkTypes;

function ViewJobOfferModal(props) {
  const {
    isFullTimeJob, 
    closeModal,
    jobOfferDetails,  
    intl,
    txId,
    actionInProgress,
    isJobOfferAccepted,
    transaction,
    setAlertModal,
  } = props;

  const dispatch = useDispatch();
  const {salary, startDate, endDate, offerAttachment, currency = 'INR'} = jobOfferDetails ?? {};
  const formattedSalary = new Money(Number(salary), currency);

  async function handleDeclineOffer(){
    const transition = TRANSITION_DECLINE_JOB_OFFER;
    const response = await dispatch(handleJobTransition(transaction, transition))
    if(response?.data?.data?.id){
      closeModal()
      setAlertModal({})
    };
  }

  async function handleAcceptOffer(){
    const jobId = transaction.id.uuid
    const userEmail = transaction.customer.attributes.profile.publicData.email

    const transition = TRANSITION_ACCEPT_JOB_OFFER;
    const response = await dispatch(handleJobTransition(transaction, transition))
    if(response?.data?.data?.id) {
      updateRecruitment({
        jobId,
        userEmail
      })
      closeModal()
    };
  }

  return (
    <div className={css.contentWrapper}>
      <h2>
        <FormattedMessage id={isFullTimeJob ? 'ViewJobOfferModal.jobOffer' : 'ViewJobOfferModal.secondmentOffer'} />
      </h2>
      <div className={css.row}>
        <label><FormattedMessage id='ViewJobOfferModal.annualSalaryLabel' /></label>
        <p>{salary && formatMoney(intl, formattedSalary)}</p>
      </div>
      <div className={css.row}>
        <label><FormattedMessage id='ViewJobOfferModal.startDateLabel' /></label>
        <p>{startDate && moment(startDate).format('Do MMMM YYYY')}</p>
      </div>
      <div className={css.row}>
        <label><FormattedMessage id='ViewJobOfferModal.attachmentLabel' /></label>
        {offerAttachment?.name ? (
          <ExternalLink href={offerAttachment?.link}>
            <FormattedMessage id='ViewJobOfferModal.downloadAttachmentLink' values={{name: offerAttachment?.name}}/>
          </ExternalLink>
          ) : '-'
        }
      </div>
      {!isJobOfferAccepted && (
        <div className={css.buttonWrapper}>
          <span className={css.buttonCancel} onClick={() => {
            closeModal()
            setAlertModal({
              isOpen: true,
              message: 'JobDescription.declineJobOfferMessage',
              warning: 'JobDescription.declineJobOfferWarning',
              eventHandler: () => handleDeclineOffer()
            })
          }}>
            <FormattedMessage id='ViewJobOfferModal.declineOffer' />
          </span>
          <PrimaryButton 
            className={css.buttonSubmit} 
            onClick={handleAcceptOffer}
            inProgress={actionInProgress}
          >
            <FormattedMessage id='ViewJobOfferModal.acceptOffer' />
          </PrimaryButton>
        </div>
      )}
    </div>
  )
}

export default compose(injectIntl)(ViewJobOfferModal)