import React, { Component } from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { LISTING_STATE_DRAFT, PRIVATE_BRIEF_BUTTON, LISTING_TYPE_PUBLIC, PUBLIC_BRIEF_BUTTON, PUBLIC_POSTED_PROJECT, LISTING_STATE_PUBLISHED, USER_ROLE_CSM } from '../../util/types';
import { EditListingAdditionalForm } from '../../forms';
import { ensureOwnListing } from '../../util/data';
import { ListingLink } from '../../components';
import css from './EditListingAdditionalPanel.module.css';
import config from '../../config';
import { findOptionsForSelectFilter } from '../../util/search';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { checkIfUserSubscribed, freePlan, getCountry, getListingType, getUserDetails, sameMonthListings } from '../../util/destructorHelpers';
import { updateFirmUserDetails, updateSubscriptionData } from '../../util/api';
import { FREE_PLAN } from '../../util/types';
import moment from 'moment';
import { getUserRole } from '../../util/userRole';


class EditListingAdditionalPanel extends Component {
  render() {
    const {
      className,
      rootClassName,
      errors,
      disabled,
      ready,
      listing,
      onUpdateImageOrder,
      submitButtonText,
      panelUpdated,
      updateInProgress,
      updateInProgressPrivate,
      onChange,
      onSubmit,
      onRemoveImage,
      uploadInProgress,
      uploadError,
      documents,
      makePrivateText,
      makePublicText,
      listingType,
      isFreePlanUsed,
      currentUser,
      onUpdateProfile
      // history,
    } = this.props;

    const rootClass = rootClassName || css.root;
    const classes = classNames(rootClass, className);
    const currentListing = ensureOwnListing(listing);
    const {
      minPrice,
      maxPrice,
      currencyFee,
      country,
      projectTimelines,
      attachmentsUrls,
      userLocation,
      isLocationSelected,
      locations,
      packageKeyword =''
    } = currentListing.attributes.publicData;

    const title = currentListing?.attributes?.title;
    const { publicData = {} } = currentListing.attributes;
    const listingId = currentListing && currentListing.id && currentListing.id.uuid;
    const countryDropDown = findOptionsForSelectFilter('country', config.custom.filters);
    const isPublished = currentListing.id && currentListing.attributes.state === LISTING_STATE_PUBLISHED;
    const firmTitle = getUserDetails(currentUser)?.firmName;
    const linkedToFirms = !!currentUser?.id && !!currentUser?.attributes?.profile?.publicData?.linkedToFirms;
    const isCsm = !!currentUser?.id && getUserRole(currentUser) === USER_ROLE_CSM;
    const isUserSubscribed = checkIfUserSubscribed(currentUser);

    const panelTitle = isPublished ? (
      <FormattedMessage
        id="EditListingAdditionalPanel.title"
        values={{
          listingTitle: (
            <ListingLink listing={listing}>
              <FormattedMessage id="EditListingAdditionalPanel.listingTitle" />
            </ListingLink>
          ),
        }}
      />
    ) : (
      <FormattedMessage id="EditListingAdditionalPanel.createListingTitle" />
    );

    const handleSubmit = values => {
      const {
        minPrice,
        maxPrice,
        currencyFee,
        projectTimelines,
        type = LISTING_TYPE_PUBLIC,
        attachmentsUrls,
        isLocationSelected,
        locations,
      } = values;

      const location = locations?.length && locations.map(item => item.Location.selectedPlace.address);
      // const allLocations = Object.keys(values).filter(item => item.includes('location'));
      const combinedCountries = location && location.map(item => item && getCountry(item));
      const updateValues = {
        publicData: {
          minPrice: Number(minPrice.replace(/\,/g, '')),
          maxPrice: Number(maxPrice.replace(/\,/g, '')),
          currencyFee,
          country: combinedCountries,
          countryKeyword: location?.length && location.join(''),
          userLocation: null,
          locations,
          projectTimelines,
          type,
          attachmentsUrls: []?.concat(
            typeof attachmentsUrls !== 'undefined' ? attachmentsUrls : []
          ),
          isLocationSelected,
          packageKeyword: packageKeyword.concat(firmTitle),
          updatedAt:  moment().unix(),
        },
      };

      if(type === LISTING_TYPE_PUBLIC && !isPublished){
        
        //Update user posted brief details in firm details
        updateFirmUserDetails({ 
          action: PUBLIC_POSTED_PROJECT, 
          userEmail: getUserDetails(currentUser)?.email, 
          listingId: currentListing?.id 
        })

        //Update user posted brief details only when subscribed
        if(isUserSubscribed){
          const params = {
            email: getUserDetails(currentUser)?.email,
            type: 'publicBriefs', 
            data: {name: title}
          }
          updateSubscriptionData(params)
        }
      }
      onSubmit(updateValues);
    }

    return (
      <div className={classes}>
        <h1 className={css.title}>{panelTitle}</h1>
        <EditListingAdditionalForm
          className={css.form}
          disabled={disabled}
          ready={ready}
          fetchErrors={errors}
          initialValues={{
            projectTimelines,
            minPrice,
            maxPrice,
            currencyFee,
            country,
            attachmentsUrls,
            isLocationSelected,
            locations,
          }}
          onSubmit={values => handleSubmit(values)}
          onChange={onChange}
          onUpdateImageOrder={onUpdateImageOrder}
          onRemoveImage={onRemoveImage}
          saveActionMsg={submitButtonText}
          updated={panelUpdated}
          updateInProgress={updateInProgress}
          updateInProgressPrivate={updateInProgressPrivate}
          uploadInProgress={uploadInProgress}
          uploadError={uploadError}
          documents={documents}
          listingId={listingId}
          makePrivateText={makePrivateText}
          makePublicText={makePublicText}
          attachmentsUrls={attachmentsUrls}
          publicData={publicData}
          countryDropDown={countryDropDown}
          userLocation={userLocation}
          listingType={listingType}
          isFreePlanUsed={isFreePlanUsed}
          currentUser={currentUser}
          linkedToFirms={linkedToFirms}
          isCsm={isCsm}
          isPublished={isPublished}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { isAuthenticated } = state.Auth;
  const {  currentUser, currentUserBriefs } = state.user;
  const listingType = getListingType(sameMonthListings(currentUserBriefs));
  const isFreePlanUsed = !!currentUser?.id && freePlan(currentUser);

  return {
    isAuthenticated,
    currentUser,
    listingType,
    isFreePlanUsed,
  };
};
EditListingAdditionalPanel.defaultProps = {
  className: null,
  rootClassName: null,
  errors: null,
  listing: null,
  makePrivateText: PRIVATE_BRIEF_BUTTON,
  makePublicText: PUBLIC_BRIEF_BUTTON,
};

export default compose(connect(mapStateToProps))(EditListingAdditionalPanel);
